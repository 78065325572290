import React from "react";
import Axios from "axios";
const moment = require("moment");
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import LeaderboardTabing from "../../_components/user/LeaderBoard/LeaderboardTabing";
//import CollapsibleTable from '../../_components/usertable';
import $ from "jquery";
import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails } from "../../_helpers/Utility";
import { history } from "../../_helpers";

class LeaderBoardPage extends React.Component {
  constructor(props) {
    super(props);
    // Calculate FromDate: Current date minus 1 month
    const date13 = new Date(moment().subtract(1, "month"));
    const year13 = date13.getFullYear();
    const month13 = String(date13.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
    const day13 = String(date13.getDate()).padStart(2, "0");

    // Current date
    const date = new Date(moment());
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    this.state = {
      FromDate: `${year13}-${month13}-${day13}`,
      ToDate: `${year}-${month}-${day}`,
      IncludeOldClient: true,
    };
    this.SaveBtn = this.SaveBtn.bind(this);
    this.IsOldClient = this.IsOldClient.bind(this);
    this.daterefect = React.createRef();
    this.daterefect2 = React.createRef();
  }

  componentDidMount() {
    document.title = `LeaderBoard | SalesHive`;
    // Get today's date
    // Get today's date
    var today = new Date();

    // Subtract one month from today's date
    var oneMonthBefore = new Date(today);
    oneMonthBefore.setMonth(today.getMonth() - 1);

    // Check if the subtraction resulted in an invalid date (e.g., subtracting a month from January)
    if (oneMonthBefore.getMonth() === today.getMonth() - 1) {
      oneMonthBefore.setDate(today.getDate());
    }

    // Convert to UTC date components
    const utcYear = oneMonthBefore.getUTCFullYear();
    const utcMonth = String(oneMonthBefore.getUTCMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
    const utcDay = String(oneMonthBefore.getUTCDate()).padStart(2, "0");

    // Format the date
    const formattedDate = `${utcMonth}/${utcDay}/${utcYear}`;

    // Set the value of the "from-date" input field
    document.getElementById("from-date").value = formattedDate;
    const $datepickerElement = $(this.daterefect.current);
    $datepickerElement.datetimepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      container: "#app",
      todayBtn: true,
      todayHighlight: true,
      minView: "2",
    });
    document.getElementById("to-date").value = new Date().toLocaleDateString(
      "en-US",
      { month: "2-digit", day: "2-digit", year: "numeric" }
    );
    const $datepickerElement2 = $(this.daterefect2.current);
    $datepickerElement2.datetimepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      container: "#app",
      todayBtn: true,
      todayHighlight: true,
      minView: "2",
    });

    var FromDate = moment
      .utc(document.getElementById("from-date").value)
      .format("YYYY-MM-DD");
    var ToDate = moment
      .utc(document.getElementById("to-date").value)
      .format("YYYY-MM-DD");

    let IncludeOldClient = document.getElementById("IsOldClient").checked;
    this.setState({ IncludeOldClient: IncludeOldClient });
    this.setState({
      FromDate: FromDate,
      ToDate: ToDate,
      IncludeOldClient: IncludeOldClient,
    });
  }

  IsOldClient() {
    let IncludeOldClient = document.getElementById("IsOldClient").checked;
    this.setState({ IncludeOldClient: IncludeOldClient });
  }
  SaveBtn() {
    var FromDate = moment
      .utc(document.getElementById("from-date").value)
      .format("YYYY-MM-DD");
    var ToDate = moment
      .utc(document.getElementById("to-date").value)
      .format("YYYY-MM-DD");
    this.setState({ FromDate: FromDate, ToDate: ToDate });
  }
  render() {
    return (
      <>
        <div className="adminmain">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="headertitlebd lg-headertitle pl-0 mb-0">LeaderBoard</h4>
              </div>
              <div className="col padright"></div>
            </div>

            <div className="row borderbtnm whiterow mx-0 pb-3">
              <div className="col px-4">
                <div className="mt-3">
                  <label class="check_bx">
                    <input
                      type="checkbox"
                      onClick={this.IsOldClient}
                      id="IsOldClient"
                      defaultChecked
                    />
                    <span className="checkmark"></span>
                    Include Old Clients
                  </label>
                </div>
              </div>
              <div className="col px-4 mt-3">
                <div className="listing-li float-right">
                  <div className="btn-group datepicker-hed">
                    <div className="col-lg-6 timedatepikker date-input">
                      <input
                        className="form-control m-input"
                        type="text"
                        id="from-date"
                        ref={this.daterefect}
                      />
                    </div>
                    <div className="col-lg-6 timedatepikker date-input">
                      <input
                        className="form-control m-input"
                        type="text"
                        id="to-date"
                        ref={this.daterefect2}
                      />
                    </div>
                    <button
                      onClick={this.SaveBtn}
                      id="aCompanyState"
                      class="btn btn-primary btnColor m-btn brrb72"
                      autocomplete="off"
                    >
                      <i class="la flaticon-diagram"></i>
                      <span> Compare</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col padd-0">
              <LeaderboardTabing
                ToDate={this.state.ToDate}
                FromDate={this.state.FromDate}
                IsOldClientss={this.state.IncludeOldClient}
              />
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedLeaderBoardPage = connect(
  mapState,
  actionCreators
)(LeaderBoardPage);
export { connectedLeaderBoardPage as LeaderBoardPage };
