import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//import CallHistory from '../_components/coldcalling/callhistory';
//import CollapsibleTable from '../_components/usertable';
import ReactApexchart from "react-apexcharts";
import { LeakAddTwoTone } from "@material-ui/icons";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";

toast.configure();

class AddDefaultTrackingDomainPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      fields: {},
      errors: {},
      ClientID: Details.ClientID,
      UserID: Details.ChildUserID,
      PUserID: Details.ParentUserID,
      IsExist: false,
      IsValid:true,
      BtnDisabled:false
    };
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {}


async FormValidation() {
    let formIsValid = true;
      let errors = {};
    var Domain = document.getElementById("Domain").value;
    let IsExist = await this.CheckExistDomain();

      if (Domain.length<=0) {
        formIsValid = false;
        errors["Domain"] = "Please enter Domain";
      }
      if (IsExist == true) {
        formIsValid = false;
        errors["Domain"] = "Domain is already exists!";
      }

      if(!this.state.IsValid){
        formIsValid = false;
        errors["Domain"] = "Invalid domain";
      }

      this.setState({ errors: errors });
    
    return formIsValid;
}

  //Domain Check 
  DomainCheck(Domain){
   let regexp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
  
    if (regexp.test(Domain))
      {
        return true;
      }
    else
      {
        return false;
      }
  }

  // check exit domain
async CheckExistDomain() {
    var Domain = document.getElementById("Domain").value;
    var InputParameter = {
      Domain: Domain.trim(),
      UserID: this.state.PUserID
    };
 let resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/default_tracking_domain/CheckDomainExist",
      method: "POST",
      data: InputParameter,
    })
      let errors = {};
      if (resdata.data.Data > 0) {
        let fields = this.state.fields;
        if (!fields["Domain"]) {
                errors["Domain"] = "Domain is already exists!";
        }
        this.setState({ IsExist: true });
        this.setState({ errors: errors }); 
        return true;
      }
      this.setState({ errors: errors }); 
      if(Domain.length>0){
        if(!this.DomainCheck(Domain)){
          let errors = {};
          let fields = this.state.fields;
          if (!fields["Domain"]) {
                  errors["Domain"] = "Invalid domain";
                  this.setState({ IsValid: false });
                  this.setState({ errors: errors }); 
          }else{
          this.setState({ IsValid: true });

          }
        }else{
          this.setState({ IsValid: true });
        }
      }
    
  }

  backbtn() {
    history.push("/defaulttrackingdomain");
  }
 
  async savebtn(e) {
    e.preventDefault();
    e.currentTarget.disabled = true;
    var Final_flag =  await this.FormValidation()
    this.setState({BtnDisabled:true})
    if(Final_flag == true){
    var Domain = document.getElementById("Domain").value;
      var data = {
        Domain: Domain,
        TotalCount: 0,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
        IsWorking:true
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/default_tracking_domain/DefaultEmailTrackingDomainAdd",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize" >Default Tracking Domains Add<br/>Default Tracking Domains added successfully.</div>);
          history.push("/defaulttrackingdomain");
          this.setState({BtnDisabled:false})
        } else {
          toast.error(res.data.Message);
          this.setState({BtnDisabled:false})
        }
      });
    }else{
      document.getElementById("submit").disabled = false;
      this.setState({BtnDisabled:false})
    }
    
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad lg-headertitle float-left">Add Default Tracking Domain</h4>
                </div>
              </div>

            <div class="bg-white p-3">
              <div className="row">  
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet pt-0">Domain</label>
                    <div class="col-lg-8">
                      <input class="form-control m-input" 
                      onBlur={this.CheckExistDomain.bind(this,'Domain')} 
                      id="Domain" placeholder="Enter Domain" type="text" />
                    <span style={{ color: "red" }}>{this.state.errors["Domain"]}</span>

                    </div>
                  </div>
                </div>  
              </div>
            </div>

              <div class="row my-4">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    disabled={this.state.BtnDisabled}
                    onClick={this.savebtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.backbtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>

          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddDefaultTrackingDomainPage = connect(
  mapState,
  actionCreators
)(AddDefaultTrackingDomainPage);
export { connectedAddDefaultTrackingDomainPage as AddDefaultTrackingDomainPage };
