import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import EmailWarmupTab from '../../_components/user/EmailWarmup/EmailWarmupTabing';
//import CollapsibleTable from '../_components/usertable';



class EmailWarmUpPage extends React.Component {
   
    componentDidMount(){
        document.title="Email Warm Up | SalesHive"
    }

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row align-items-center">
                        <div className="col">
                            <h4 className="headertitlenop float-left lg-headertitle py-4">Email Warm Up</h4> 
                        </div>
                    </div>  
                        <div className="row">
                            <div className="col"> 
                                <EmailWarmupTab /> 
                            </div>
                        </div>      
 
                    </div> 

                     

                </div>
                <Footer/>


            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedEmailWarmUpPage = connect(mapState, actionCreators)(EmailWarmUpPage);
export { connectedEmailWarmUpPage as EmailWarmUpPage };