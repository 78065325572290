import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
const moment = require("moment");

import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

toast.configure();

class EditClientResearchPage extends React.Component {
  constructor(props) {
    super(props);
    var Details = GetUserDetails();
    this.state = {
      ID: this.props.location.state,

      pager: {},
      pageOfItems: [],
      files: [],
      list: [],
      columns: [],
      reset: false,
      csvData: [],
      code: "",
      fields: {},
      errors: {},
      NameAvailable: null,

      Typelist: [],
      DocTypeID: [],
      Typelist1: [],
      resteam: [],
      totalcost: [],
      contactfound: null,
      hour: null,
      CustomVariable: {},
      IsExist: false,

      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      ProjectStatusList: [],
      ResearchTeamList: [],
      Hours: 0,
      ContactsFound: 0,
      ResTeamID: 0,
      CostPerHours: 0,
      CostPerContact: 0,
      IsButtonDisableds: false,
      ClientData: [],
      ClientTypeID: null,
      loading: true, 
    };
    // this.GetProjectStatusDropDown();
    // this.GetResearchTeamGetDropDown();
    // this.GetResearchDetails();

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = "Edit Research | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
    }
    this.ClientDropDown(
      Details.ChildUserID,
      Details.ParentUserID,
      Details.Role);
    this.GetProjectStatusDropDown();
    this.GetResearchTeamGetDropDown(Details.ClientID, Details.ParentUserID);
    this.GetResearchDetails();
  }

  //Get list of Project Status Drop Down
  GetProjectStatusDropDown() {
    Axios({
      url: CommonConstants.MOL_APIURL + "/research/ProjectStatusGet",
      method: "POST",
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        const data = res.data.Data;
        const option = data.map((d) => ({
          _id: d.FieldID,
          FieldName: d.FieldName,
        }));
        this.setState({ ProjectStatusList: option });
      } else {
        toast.error(res.data.Message);
      }
    });
  }
  // Get Research Team drop done
  GetResearchTeamGetDropDown(CID, UID) {
    const InputParameters = {
      ClientID: CID,
      UserID: UID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/research/ResearchTeamGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        const data = res.data.Data;
        const option = data.map((value) => ({
          _id: value._id,
          Name: value.Name,
        }));
        this.setState({ ResearchTeamList: option });
      } else {
        toast.error(res.data.Message);
      }
    });
  }

  // Get Research Team drop done
  // ClientGet() {
  //   this.setState({ loading: true }); // Show loader
  
  //   Axios({
  //     url: CommonConstants.MOL_APIURL + "/research/ClientGet",
  //     method: "GET",
  //   }).then((res) => {
  //     if (res.data.StatusMessage == "SUCCESS") {   
  //       this.setState({ ClientData: res.data?.Data });
  //     } else {
  //       toast.error(res.data.Message);
  //     }
  //     this.setState({ loading: false }); // Hide loader
  //   });
  // }
      // Client Drop Down
      ClientDropDown(CUID, PUID, ROLE) {
        this.setState({IsLoading:true})
    
        var ObjData = {
          Role: ROLE,
          ChildUserID: CUID,
          ParentUserID: PUID,
        };
        const rows1 = Axios({
          url: CommonConstants.MOL_APIURL + "/client/GetClientListForTopDropDown",
          method: "POST",
          data: ObjData,
        });
        rows1.then((result) => {
          if (result.data.StatusMessage == "SUCCESS") {
            const ClientData = result.data.Data;
            const option = ClientData.map((d) => ({
              _id: d.ClientID,
              Name: d.Name,
            }));
            this.setState({ ClientData: option });
            this.setState({IsLoading:false})
          }else{
            this.setState({IsLoading:false})
          }
        });
      }
  

  // Edit Research NAME
  GetResearchDetails() {
    var InputParameter = {
      ID: this.props.location.state,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/research/ResearchGetByID",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        var CompleteDate = moment(res.data.Data.CompletedBy).format(
          "YYYY-MM-DD"
        );
        var ReqDate = moment(res.data.Data.RequestedDate).format("YYYY-MM-DD");
        var FinishDate = moment(res.data.Data.FinishedDate).format(
          "YYYY-MM-DD"
        );
        document.getElementById("ProjectName").value =
          res.data.Data.ProjectName;
        document.getElementById("CompletedBy").value = CompleteDate;
        document.getElementById("RequestedDate").value = ReqDate;
        document.getElementById("FinishedDate").value = FinishDate;
        document.getElementById("Hours").value = res.data.Data.Hours;
        document.getElementById("ProjectStatusID").value =
          res.data.Data.ProjectStatusID;
        document.getElementById("ContactsFound").value =
          res.data.Data.ContactsFound;
        document.getElementById("ResearchTeamID").value =
          res.data.Data.ResearchTeamID;
        document.getElementById("Notes").value = res.data.Data.Notes;
        document.getElementById("ClientResearchID").value =
          res.data.Data?.ClientID;
        this.setState({
          Hours: res.data.Data.Hours,
          ContactsFound: res.data.Data.ContactsFound,
        });
        this.setState({ DocTypeID: res.data.Data.ProjectStatusID });
        this.setState({ ResTeamID: res.data.Data.ResearchTeamID });
        this.setState({ ClientTypeID: res.data.Data?.ClientID });
        const InputResearchID = {
          ResearchTeamID: res.data.Data.ResearchTeamID,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/research/CostPerHourResearchTeam",
          method: "POST",
          data: InputResearchID,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            this.setState({ CostPerHours: res.data.Data?.CostPerHour });
            var ContactsFound = this.state.ContactsFound;
            var Hours = this.state.Hours;
            var HoursPerCost = this.state.CostPerHours;
            let CalculationOfCost = (Hours * HoursPerCost) / ContactsFound;
            this.setState({ CostPerContact: CalculationOfCost });
          } else {
            toast.error(res.data.Message);
          }
        });
        this.setState({ loading: false })
      } else {
        toast.error(res.data.Message);
    
      }
      this.setState({ loading: false })
    });
  }

  FormValidation() {
    let formIsValid = true;
    let ProjectNameValid = true;
    let RequestedDateValid = true;
    let ProjectStatusIDValid = true;
    let CostPerHrsValid = true;
    let ContactsFoundValid = true;
    let ClientResearchIDValid = true;
    let HoursValid = true;
    var ProjectName = document.getElementById("ProjectName").value;
    var RequestedDate = document.getElementById("RequestedDate").value;
    var ProjectStatusID = document.getElementById("ProjectStatusID").value;
    var ClientResearchID = document.getElementById("ClientResearchID");
    if (Object.entries(this.state.fields).length >= 0) {
      let fields = this.state.fields;
      let errors = {};

      if (ProjectName == "") {
        ProjectNameValid = false;
        errors["ProjectName"] = "Please enter project name";
      }
      if (RequestedDate == "") {
        RequestedDateValid = false;
        errors["RequestedDate"] = "Please select requested date";
      }
      if (ProjectStatusID <= 0) {
        ProjectStatusIDValid = false;
        errors["ProjectStatusID"] = "Please select project status";
      }

      if(ProjectStatusID === "0"){
        ProjectStatusIDValid = false;
        errors["ProjectStatusID"] = "Please select project status";
      }

      if (ClientResearchID == "") {
        ClientResearchIDValid = false;
        errors["ClientResearchID"] = "Please select client name";
      }
      if (this.state.CostPerHours == 0) {
        CostPerHrsValid = false;
        errors["ResearchTeamID"] = "Please select research team";
      }
      if (this.state.ContactsFound <= 0) {
        ContactsFoundValid = false;
        errors["ContactsFound"] =
          "Please enter a value greater than or equal to 1.";
      }
      if (this.state.Hours <= 0) {
        HoursValid = false;
        errors["Hours"] = "Please enter a value greater than or equal to 1.";
      }
      if (
        ProjectNameValid &&
        RequestedDateValid &&
        ProjectStatusIDValid &&
        CostPerHrsValid &&
        ContactsFoundValid &&
        HoursValid &&
        ClientResearchIDValid
      ) {
        formIsValid = true;
      } else {
        formIsValid = false;
      }
      this.setState({ errors: errors });
    }
    return formIsValid;
  }

  //ResearchTeam set state

  ResearchTeamState(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    let ResearchTeamID = document.getElementById("ResearchTeamID").value;
    this.setState({ ResTeamID: ResearchTeamID });
    this.setState({ CostPerHours: fields.ResearchTeamID });
    if (fields.ResearchTeamID == 0) {
      this.setState({ CostPerHours: 0 });
      this.state.errors.ResearchTeamID  = "Please select research team";
    } else {
      if (fields.ResearchTeamID != "") {
        this.state.errors.ResearchTeamID = null;
        let ResearchTeamID = document.getElementById("ResearchTeamID").value;
        if (ResearchTeamID.length > 0 && ResearchTeamID != 0) {
          const InputParameters = {
            ResearchTeamID: ResearchTeamID,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL + "/research/CostPerHourResearchTeam",
            method: "POST",
            data: InputParameters,
          }).then((res) => {
            if (res.data.StatusMessage == "SUCCESS") {
              this.setState({ CostPerHours: res.data.Data?.CostPerHour });
              let ContactsFound =
                document.getElementById("ContactsFound").value;
              let hours = document.getElementById("Hours").value;
              ContactsFound = ContactsFound <= 0 ? 0 : ContactsFound;
              let CostPerHour = this.state.CostPerHours;
              let CalculationOfCost = (hours * CostPerHour) / ContactsFound;
              CalculationOfCost = isFinite(Number(CalculationOfCost))
                ? CalculationOfCost
                : 0;
              this.setState({ CostPerContact: CalculationOfCost });
            } else {
              toast.error(res.data.Message);
            }
          });
          this.state.errors.ResearchTeamID = null;
        } else {
          this.state.errors.ResearchTeamID = null;
        }
      } else {
        this.state.errors.ResearchTeamID = null;
      }
    }
  }

  // HandleChange
  HandleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });

    if (fields.RequestedDate != "") {
      this.state.errors.RequestedDate = null;
    }

    if (fields.ContactsFound != "") {
      let ContactsFound = document.getElementById("ContactsFound").value;
      let hours = document.getElementById("Hours").value;
      ContactsFound = ContactsFound <= 0 ? 0 : ContactsFound;
      let CostPerHour = this.state.CostPerHours;
      let CalculationOfCost = (hours * CostPerHour) / ContactsFound;
      CalculationOfCost = isFinite(Number(CalculationOfCost))
        ? CalculationOfCost
        : 0;
      this.setState({ CostPerContact: CalculationOfCost });
    }
    if (fields.Hours != "") {
      let ContactsFound = document.getElementById("ContactsFound").value;
      let hours = document.getElementById("Hours").value;
      ContactsFound = ContactsFound <= 0 ? 0 : ContactsFound;
      let CostPerHour = this.state.CostPerHours;
      let CalculationOfCost = (hours * CostPerHour) / ContactsFound;
      CalculationOfCost = isFinite(Number(CalculationOfCost))
        ? CalculationOfCost
        : 0;
      this.setState({ CostPerContact: CalculationOfCost });
    }
  }

  HandleChangeProjectStatus(field, e){
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    let errors = {};
    if(fields.ProjectStatusID == "" || fields.ProjectStatusID == "--Select--"){
      this.state.errors.ProjectStatusID  = "Please select project status";
    }else if(fields.ProjectStatusID === "2" || fields.ProjectStatusID === "1"){
      errors["FinishedDate"] = "";
      errors["Hours"] = "";
      errors["ContactFound"] = "";
      this.state.errors.ProjectStatusID = null;
      this.state.errors.FinishedDate = null;
      this.state.errors.Hours = null;
      this.state.errors.ContactFound = null;
    }else{
      errors["FinishedDate"] = "";
      errors["Hours"] = "";
      errors["ContactFound"] = "";
      this.state.errors.ProjectStatusID = null;
      this.state.errors.FinishedDate = null;
      this.state.errors.Hours = null;
      this.state.errors.ContactFound = null;
    }
  }

  HandleChangeProjectName(field, e){
    let fields = this.state.fields;
    fields[field] = e.target.value;

    this.setState({ fields });
    let errors = {};


    if(fields.ProjectName != ""){
      errors["ProjectName"] = "";
      this.state.errors.ProjectName = null
    }else if (fields.ProjectName === undefined){
      this.state.errors.ProjectName  = "Please enter project name";
      errors["ProjectName"] = "Please enter project name";
    }else if (fields.ProjectName === ""){
      this.state.errors.ProjectName  = "Please enter project name";
      errors["ProjectName"] = "Please enter project name";
    }
  }

  ContactsFoundHandler(e) {
    let Errors = this.state.errors;
    if (e.target.value < 0) {
      Errors["ContactsFound"] =
        "Please enter a value greater than or equal to 1.";
    } else if (e.target.value == 0) {
      Errors["ContactsFound"] =
        "Please enter a value greater than or equal to 1.";
    } else {
      Errors["ContactsFound"] = null;
    }
    this.setState({ ContactsFound: e.target.value });

    this.setState({ errors: Errors });
  }

  HoursHandler(e) {
    let Errors = this.state.errors;
    this.setState({ Hours: e.target.value });
    if (e.target.value <= 0) {
      Errors["Hours"] = "Please enter a value greater than or equal to 1.";
    } else {
      Errors["Hours"] = null;
    }
    this.setState({ errors: Errors });
  }
  BackBtn() {
    history.push("/clientresearch");
  }

  SaveBtn() {
    if (this.FormValidation()) {
      this.setState({ IsButtonDisableds: true });
      var ClientIDs = document.getElementById("ClientResearchID").value;
      var ProjectName = document.getElementById("ProjectName").value;
      var CompletedBy = document.getElementById("CompletedBy").value;
      var RequestedDate = document.getElementById("RequestedDate").value;
      var FinishedDate = document.getElementById("FinishedDate").value;
      var Hours = document.getElementById("Hours").value;
      var ProjectStatusID = document.getElementById("ProjectStatusID").value;
      var ContactsFound = document.getElementById("ContactsFound").value;
      var ResearchTeamID = document.getElementById("ResearchTeamID").value;
      var Notes = document.getElementById("Notes").value;
      if(new Date(FinishedDate) < new Date(RequestedDate)){
        this.setState({ IsButtonDisableds: false });
        toast.error(<div className="taostsize">Finish date must be equal or greater than requested selected date.</div>)
      }else{
      var InputParameter = {
        ID: this.state.ID,
        ClientID: ClientIDs,
        ProjectName: ProjectName,
        CompletedBy: CompletedBy,
        RequestedDate: RequestedDate,
        FinishedDate: FinishedDate,
        Hours: Hours,
        ProjectStatusID: ProjectStatusID,
        ContactsFound: ContactsFound,
        ResearchTeamID: ResearchTeamID,
        Notes: Notes,
        LastUpdatedBy: this.state.CUserID,
        LastUpdatedDate: new Date(),
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/research/ResearchUpdate",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div>
              Research Edit
              <br />
              Research updated successfully.
            </div>
          );
          history.push("/clientresearch");
        } else {
          this.setState({ IsButtonDisableds: false });
          toast.error(res.data.Message);
        }
      });
    }
    }
  }

  // handle dropdown change contact owner
  HandleDropdownChangeClientId(e) {
    const selectedValue = e.target.value; // Get the selected value from the dropdown
  
    // Set the selected value in the state
    this.setState({
      ClientTypeID: selectedValue,
    });
  
    // Check if the selected value is empty or "--Select--"
    if (!selectedValue || selectedValue === "--Select--" || selectedValue === "0") {
      // If it's empty or "--Select--", show the error message
      this.setState({
        errors: {
          ...this.state.errors,
          ClientResearchID: "Please select a client",
        },
      });
    } else {
      // If a client is selected, clear the error message
      this.setState({
        errors: {
          ...this.state.errors,
          ClientResearchID: "",
        },
      });
    }
  }
  
  

  render() {
    return (
      <>
        {this.state.loading ? (
      <div id="hideloding1" className="loding-display">
      <img src={loadingicon} />
    </div>
) : null}
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left pt-4">
                    Edit Research{" "}
                  </h4>
                </div>
              </div>

            <div className="bg-white p-3 ">
              <div className="row">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 px-4 view-lable-meet">Client</label>
                    <div className="col-lg-8">
                      <select
                        className="form-control "
                        id="ClientResearchID"
                        name="ClientResearchID"
                        value={this.state.ClientTypeID}
                        onChange={(e) => this.HandleDropdownChangeClientId(e)}
                      >
                        <option value="0">--Select--</option>

                        {this.state.ClientData.map((val) => (
                          <option key={val._id} value={val._id}>{val.Name}</option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClientResearchID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group"></div>
                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Project Name</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="ProjectName"
                        name="ProjectName"
                        placeholder="Enter Project Name"
                        type="text"
                        onChange={this.HandleChangeProjectName.bind(this, "ProjectName")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["ProjectName"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Needs to be Completed <br /> By
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="CompletedBy"
                        name="CompletedBy"
                        placeholder=""
                        type="date"
                        onChange={this.HandleChange.bind(this, "CompletedBy")}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Date Requested
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="RequestedDate"
                        name="RequestedDate"
                        placeholder=""
                        type="date"
                        onChange={this.HandleChange.bind(this, "RequestedDate")}
                        value={this.state.fields["RequestedDate"]}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["RequestedDate"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Date Finished
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="FinishedDate"
                        name="FinishedDate"
                        placeholder=""
                        type="date"
                        onChange={this.HandleChange.bind(this, "FinishedDate")}
                        value={this.state.fields["FinishedDate"]}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Project Status
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field  must be a number."
                        id="ProjectStatusID"
                        name="ProjectStatusID"
                        // value={this.state.DocTypeID}

                        onChange={this.HandleChangeProjectStatus.bind(
                          this,
                          "ProjectStatusID"
                        )}
                      >
                        <option value="0">--Select--</option>
                        {this.state.ProjectStatusList.map((val) => (
                          <option key={val._id} value={val._id}>{val.FieldName}</option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ProjectStatusID"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Research Team
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field  must be a number."
                        id="ResearchTeamID"
                        name="ResearchTeamID"
                        value={this.state.ResTeamID}
                        onChange={this.ResearchTeamState.bind(
                          this,
                          "ResearchTeamID"
                        )}
                      >
                        <option value="0">--Select--</option>
                        {this.state.ResearchTeamList.map((val) => (
                          <option key={val._id} value={val._id}>{val.Name}</option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ResearchTeamID"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Hours</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="Hours"
                        name="Hours"
                        placeholder="Enter Spend"
                        type="number"
                        min="0"
                        onChange={this.HandleChange.bind(this, "Hours")}
                        value={this.state.fields["Hours"]}
                        onBlur={(e) => {
                          this.HoursHandler(e);
                        }}
                        defaultValue={this.state.fields["Hours"]}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Hours"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Contacts Found
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="ContactsFound"
                        name="ContactsFound"
                        placeholder=""
                        type="number"
                        min="0"
                        onChange={this.HandleChange.bind(this, "ContactsFound")}
                        onBlur={(e) => {
                          this.ContactsFoundHandler(e);
                        }}
                        value={this.state.fields["ContactsFound"]}
                        defaultValue={this.state.fields["ContactsFound"]}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["ContactsFound"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="align-items-baseline d-flex max-cols row">
                    <label className="col-lg-4 view-lable-meet">
                      Cost per Contact
                    </label>
                    <div className="col-lg-8">
                      <label id="costpercount">
                        {Number.isNaN(this.state.CostPerContact)
                          ? 0
                          : this.state.CostPerContact.toFixed(2)}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 border-top pt-3 border-bottom pb-3 mt-4">
                  <h4 className="headertitlenop xs-headertitle float-left">Notes</h4>
                </div>

                <div className="col-lg-12 boxsinput_group px-0 pt-4">
                  <div className="col-lg-12 pt-2">
                    <textarea
                      className="form-control m-input"
                      type="text"
                      id="Notes"
                      onChange={this.HandleChange.bind(this, "Notes")}
                      value={this.state.fields["Notes"]}
                    ></textarea>
                  </div>
                </div>
              </div>

            </div>
              <div className="row pt-4 pb-4">
                <div className="col-lg-12 pull-left">
                  <button
                    //id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.IsButtonDisableds}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditClientResearchPage = connect(
  mapState,
  actionCreators
)(EditClientResearchPage);
export { connectedEditClientResearchPage as EditClientResearchPage };
