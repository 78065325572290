import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import Axios from "axios";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class AddClientResearchPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      fields: {},
      errors: {},
      ProjectStatusList: [],
      ResearchTeamList: [],
      ClientData: [],
      CostPerHours: 0,
      CostPerContact: 0,
      IsButtonDisabled: false,
      IsLoading:true,
    };
    this.ClientDropDown(
      Details.ChildUserID,
      Details.ParentUserID,
      Details.Role
    );
    this.GetResearchTeamGetDropDown();
    this.GetProjectStatusDropDown();
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = "Client Add Research | SalesHive";
  }

  //Get list of Project Status Drop Down
  GetProjectStatusDropDown() {
    Axios({
      url: CommonConstants.MOL_APIURL + "/research/ProjectStatusGet",
      method: "POST",
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        const data = res.data.Data;
        const option = data.map((d) => ({
          _id: d.FieldID,
          FieldName: d.FieldName,
        }));
        this.setState({ ProjectStatusList: option });
      } else {
        toast.error(res.data.Message);
      }
    });
  }

    // Client Drop Down
    ClientDropDown(CUID, PUID, ROLE) {
      this.setState({IsLoading:true})
  
      var ObjData = {
        Role: ROLE,
        ChildUserID: CUID,
        ParentUserID: PUID,
      };
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/client/GetClientListForTopDropDown",
        method: "POST",
        data: ObjData,
      });
      rows1.then((result) => {
        if (result.data.StatusMessage == "SUCCESS") {
          const ClientData = result.data.Data;
          const option = ClientData.map((d) => ({
            _id: d.ClientID,
            Name: d.Name,
          }));
          this.setState({ ClientData: option });
          this.setState({IsLoading:false})
        }else{
          this.setState({IsLoading:false})
        }
      });
    }

  // Get Research Team drop done
  GetResearchTeamGetDropDown() {
    const InputParameters = {
      ClientID: this.state.ClientID,
      UserID: this.state.UserID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/research/ResearchTeamGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        const data = res.data?.Data;
        const option = data.map((value) => ({
          _id: value._id,
          Name: value.Name,
        }));
        this.setState({ ResearchTeamList: option });
      } else {
        toast.error(res.data.Message);
      }
    });
  }

  // Get Research Team drop done
  // ClientGet() {
  //   Axios({
  //     url: CommonConstants.MOL_APIURL + "/research/ClientGet",
  //     method: "GET",
  //   }).then((res) => {
  //     if (res.data.StatusMessage == "SUCCESS") {
  //       this.setState({ ClientData: res.data.Data });
  //     } else {
  //       toast.error(res.data.Message);
  //     }
  //   });
  // }

  async FormValidation() {
    
    let formIsValid = true;
    let ProjectStatusID = document.getElementById("ProjectStatusID").value;
    let fields = this.state.fields;
    let errors = {};
    console.log(fields["FinishedDate"],"fields")
    if (Object.entries(this.state.fields).length >= 0) {
      if (
        !fields["ProjectName"] ||
        fields["ProjectName"] == "" ||
        fields["ProjectName"].indexOf(" ") == 0
      ) {
        formIsValid = false;
        errors["ProjectName"] = "Please enter project name";
      }
      if (!fields["RequestedDate"]) {
        formIsValid = false;
        errors["RequestedDate"] = "Please select requested date";
      }
      if (!fields["ProjectStatusID"] || ProjectStatusID <= 0) {
        formIsValid = false;
        errors["ProjectStatusID"] = "Please select project status";
      }else if(fields["ProjectStatusID"] === "3" && !fields["FinishedDate"] && !fields["Hours"]  && !fields["ContactFound"]){
        formIsValid = false;
        errors["FinishedDate"] = "Please select finished date";
        errors["Hours"] = "Please enter hours";
        errors["ContactFound"] = "Please enter contacts found";
        
      }
      // else if(fields["ProjectStatusID"] === "3" && !fields["FinishedDate"]){
      //   formIsValid = false;
      //   errors["FinishedDate"] = "Please select finished date";
      // }else if(fields["ProjectStatusID"] === "3" && !fields["Hours"]  && !fields["ContactFound"]){
      //   formIsValid = false;
      //   errors["Hours"] = "Please enter hours";
      //   errors["ContactFound"] = "Please enter contacts found";
        
      // }
      // else if(fields["ProjectStatusID"] === "3"  && !fields["ContactFound"]){
      //   formIsValid = false;
      //   errors["ContactFound"] = "Please enter contacts found";

      // } else if(fields["ProjectStatusID"] === "3"  && !fields["Hours"]){
      //   formIsValid = false;
      //   errors["Hours"] = "Please enter hours";
      // }else if(fields["ProjectStatusID"] === "3" && !fields["FinishedDate"] && !fields["Hours"]){
      //   formIsValid = false;
      //   errors["FinishedDate"] = "Please select finished date";
      //   errors["Hours"] = "Please enter hours";     
      // }else if(fields["ProjectStatusID"] === "3" && !fields["FinishedDate"] && !fields["ContactFound"]){
      //   formIsValid = false;
      //   errors["FinishedDate"] = "Please select finished date";
      //   errors["ContactFound"] = "Please enter contacts found";
        
      // }
      // else if(fields["ProjectStatusID"] === "3" && !fields["Hours"]  && !fields["ContactFound"]){
      //   formIsValid = false;
   
      //   errors["Hours"] = "Please enter hours";
      //   errors["ContactFound"] = "Please enter contacts found";
        
      // }
      if (!fields["ResearchTeamID"] || this.state.CostPerHours == 0) {
        formIsValid = false;
        errors["ResearchTeamID"] = "Please select research team";
      }
      if (!fields["ClientResearchID"] || fields["ClientResearchID"] == "") {
        formIsValid = false;
        errors["ClientResearchID"] = "Please select client";
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

 
  //ResearchTeam set state
  ResearchTeamState(field, e) {

    let fields = this.state.fields;
    fields[field] = e.target.value;
   
    this.setState({ fields });
    this.setState({ CostPerHours: fields.ResearchTeamID });
    
    if (fields.ResearchTeamID == 0) {
      this.setState({ CostPerHours: 0 });
      this.state.errors.ResearchTeamID  = "Please select research team";
    }else {
      if (fields.ResearchTeamID != "") {
        this.state.errors.ResearchTeamID = null;
        let ResearchTeamID = document.getElementById("ResearchTeamID").value;
        if (ResearchTeamID.length > 0 || ResearchTeamID != 0) {
          const InputParameters = {
            ResearchTeamID: ResearchTeamID,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL + "/research/CostPerHourResearchTeam",
            method: "POST",
            data: InputParameters,
          }).then((res) => {
            if (res.data.StatusMessage == "SUCCESS") {
              this.setState({ CostPerHours: res.data.Data?.CostPerHour });
              let ContactsFound =
                document.getElementById("ContactsFound").value;
              let hours = document.getElementById("Hours").value;
              ContactsFound = ContactsFound <= 0 ? 0 : ContactsFound;
              let CostPerHour = this.state.CostPerHours;
              let CalculationOfCost = (hours * CostPerHour) / ContactsFound;
              CalculationOfCost = isFinite(Number(CalculationOfCost))
                ? CalculationOfCost
                : 0;
              this.setState({ CostPerContact: CalculationOfCost });
            } else {
              toast.error(res.data.Message);
            }
          });
          this.state.errors.ResearchTeamID = null;
        }

      } else{     
        this.state.errors.ResearchTeamID = null;
      }
    }
  }


  // HandleChange
  HandleChange(field, e) {

    let fields = this.state.fields;
    fields[field] = e.target.value;

    this.setState({ fields });
    let errors = {};
    if (fields.ContactsFound != "") {
      let ContactsFound = document.getElementById("ContactsFound").value;
      let hours = document.getElementById("Hours").value;
      ContactsFound = ContactsFound <= 0 ? 0 : ContactsFound;
      let CostPerHour = this.state.CostPerHours;
      let CalculationOfCost = (hours * CostPerHour) / ContactsFound;
      CalculationOfCost = isFinite(Number(CalculationOfCost))
        ? CalculationOfCost
        : 0;
      this.setState({ CostPerContact: CalculationOfCost });
    }


// Compare the requestedDate with today's date
if (fields.RequestedDate != "") {
  errors["RequestedDate"] = "";
  this.state.errors.RequestedDate = null
  // console.log("Please select requested date");
} else if (fields.RequestedDate == "") {
  errors["RequestedDate"] = "Please select requested date";
  this.state.errors.RequestedDate = "Please select requested date"
} else {
  errors["RequestedDate"] = "";
  this.state.errors.RequestedDate = null
}


  }
  FinishDateHandleChage (field, e) {

    let fields = this.state.fields;
    fields[field] = e.target.value;

    this.setState({ fields });
    let errors = {};
// Compare the requestedDate with today's date
if (fields.FinishedDate != "") {
  errors["FinishedDate"] = "";
  this.state.errors.FinishedDate = null
  // console.log("Please select requested date");
}else {
  errors["FinishedDate"] = "";
  this.state.errors.FinishedDate = null
}


  }
  HandleChangeProjectName(field, e){
    let fields = this.state.fields;
    fields[field] = e.target.value;

    this.setState({ fields });
    let errors = {};


    if(fields.ProjectName != ""){
      errors["ProjectName"] = "";
      this.state.errors.ProjectName = null
    }else if (fields.ProjectName === undefined){
      this.state.errors.ProjectName  = "Please enter project name";
      errors["ProjectName"] = "Please enter project name";
    }else if (fields.ProjectName === ""){
      this.state.errors.ProjectName  = "Please enter project name";
      errors["ProjectName"] = "Please enter project name";
    }
  }
  HandleChangeDropdown(field, e) {
    
    let fields = this.state.fields;
    fields[field] = e.target.value;

    this.setState({ fields });

    let errors = {};
    if(fields.ClientResearchID != ""){
      errors["ClientResearchID"] = "";
      this.state.errors.ClientResearchID = null
    }else if (fields.ClientResearchID == "" || fields.ClientResearchID == "select"){
      this.state.errors.ClientResearchID  = "Please select client";
    }

  }

  HandleChangeProjectStatus(field, e){
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    let errors = {};
    if(fields.ProjectStatusID == "" || fields.ProjectStatusID == "select"){
      this.state.errors.ProjectStatusID  = "Please select project status";
    }else if(fields.ProjectStatusID === "2" || fields.ProjectStatusID === "1"){
      errors["FinishedDate"] = "";
      errors["Hours"] = "";
      errors["ContactFound"] = "";
      this.state.errors.ProjectStatusID = null;
      this.state.errors.FinishedDate = null;
      this.state.errors.Hours = null;
      this.state.errors.ContactFound = null;
    }else{
      errors["FinishedDate"] = "";
      errors["Hours"] = "";
      errors["ContactFound"] = "";
      this.state.errors.ProjectStatusID = null;
      this.state.errors.FinishedDate = null;
      this.state.errors.Hours = null;
      this.state.errors.ContactFound = null;
    }
  }
  BackBtn() {
    history.push("/clientresearch");
  }

  ContactFoundHandler(e) {
    let Errors = this.state.errors;
    if (e.target.value < 0) {
      Errors["ContactFound"] =
        "Please enter a value greater than or equal to 1.";
    } else if (e.target.value == 0) {
      Errors["ContactFound"] = null;
    } else {
      Errors["ContactFound"] = null;
    }
    this.setState({ ContactsFound: e.target.value });

    this.setState({ errors: Errors });
  }

  HoursHandler(e) {
    let Errors = this.state.errors;
    this.setState({ Hours: e.target.value });
    if (e.target.value <= 0) {
      Errors["Hours"] = "Please enter a value greater than or equal to 1.";
    } else {
      Errors["Hours"] = null;
    }
    this.setState({ errors: Errors });
  }

  // Save Data
  async SaveBtn(e) {
    
    e.preventDefault();
    this.setState({ IsButtonDisabled: true });
    var validation = await this.FormValidation();
    if (validation === true) {
      
      var RequestedDate = document.getElementById("RequestedDate").value;
      var FinishedDate = document.getElementById("FinishedDate").value;
      var ProjectName = document.getElementById("ProjectName").value;
      var CompletedBy = document.getElementById("CompletedBy").value;
      var Hours = document.getElementById("Hours").value;
      var ProjectStatusID = document.getElementById("ProjectStatusID").value;
      var ContactsFound = document.getElementById("ContactsFound").value;
      var ResearchTeamID = document.getElementById("ResearchTeamID").value;
      var Notes = document.getElementById("Notes").value;
      var ClientResearchID = document.getElementById("ClientResearchID").value;
      if(new Date(FinishedDate) < new Date(RequestedDate)){
        this.setState({ IsButtonDisabled: false });
        toast.error(<div className="taostsize">Finish date must be equal or greater than requested selected date.</div>)
      }else{
      var InputParameter = {
        ClientID: ClientResearchID,
        UserID: this.state.UserID,
        ProjectName: ProjectName,
        CompletedBy: CompletedBy,
        RequestedDate: RequestedDate,
        FinishedDate: FinishedDate,
        Hours: Hours,
        ProjectStatusID: ProjectStatusID,
        ContactsFound: ContactsFound,
        ResearchTeamID: ResearchTeamID,
        IsDeleted: false,
        Notes: Notes,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/research/ClientResearchAdd",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div>
              Add Clinet Research 
              <br />
              Add Client Research successfully.
            </div>
          );
          history.push("/clientresearch");
        } else {
          this.setState({ IsButtonDisabled: false });
          toast.error(res.data.Message);
        }
      });
    }
    } else {
      this.setState({ IsButtonDisabled: false });
      e.currentTarget.disabled = false;
    }
  }

  render() {
    return (
      <>
              {
        this.state.IsLoading === true ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> :<></>
      }
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad lg-headertitle float-left">
                    Add Client Research
                  </h4>
                </div>
              </div>

            <div className="bg-white p-3">
              <div className="row">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Client</label>
                    <div className="col-lg-8">
                      <select
                        className="form-control "
                        id="ClientResearchID"
                        name="ClientResearchID"
                        onChange={this.HandleChangeDropdown.bind(
                          this,
                          "ClientResearchID"
                        )}
                        // onChange={this.HandleChangeDropdown.bind(
                        //   this,
                        //   "ClientResearchID"
                        // )}
                      >
                        <option value="">--Select--</option>

                        {this.state.ClientData.map((Value) => (
                          <option key={Value._id} value={Value._id}>{Value.Name}</option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClientResearchID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group"></div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Project Name</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        type="text"
                        id="ProjectName"
                        onBlur={this.HandleChangeProjectName.bind(this, "ProjectName")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["ProjectName"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Needs to be Completed By
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="CompletedBy"
                        name="CompletedBy"
                        placeholder=""
                        type="date"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Date Requested
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="RequestedDate"
                        name="RequestedDate"
                        placeholder=""
                        type="date"
                        onChange={this.HandleChange.bind(this, "RequestedDate")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["RequestedDate"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Date Finished
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="FinishedDate"
                        name="FinishedDate"
                        placeholder=""
                        onChange={this.FinishDateHandleChage.bind(this, "FinishedDate")}
                        type="date"
                      />
                               <span style={{ color: "red" }}>
                        {this.state.errors["FinishedDate"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Project Status
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ProjectStatusID"
                        name="ProjectStatusID"
                        onChange={this.HandleChangeProjectStatus.bind(
                          this,
                          "ProjectStatusID"
                        )}
                      >
                         <option value="0">--Select--</option>
                        {this.state.ProjectStatusList?.map((Value) => (
                          <option key={Value._id} value={Value._id}>{Value?.FieldName}</option>
                        ))}
                      </select>

                      <span style={{ color: "red" }}>
                        {this.state.errors["ProjectStatusID"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Research Team
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ResearchTeamID"
                        name="ResearchTeamID"
                        onChange={this.ResearchTeamState.bind(
                          this,
                          "ResearchTeamID"
                        )}
                      >
                         <option value="0">--Select--</option>
                        {this.state.ResearchTeamList?.map((Value) => (
                          <option key={Value._id} value={Value._id}>{Value?.Name}</option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ResearchTeamID"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Hours</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="Hours"
                        name="Hours"
                        placeholder="Enter hours"
                        type="number"
                        min="0"
                        onChange={this.HandleChange.bind(this, "Hours")}
                        onBlur={(e) => {
                          this.HoursHandler(e);
                        }}
                      />
                       <span style={{ color: "red" }}>
                        {this.state.errors["Hours"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Contacts Found
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="ContactsFound"
                        name="ContactsFound"
                        placeholder="Enter contacts found"
                        type="number"
                        min="0"
                        onChange={this.HandleChange.bind(this, "ContactsFound")}
                        onBlur={(e) => {
                          this.ContactFoundHandler(e);
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["ContactFound"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Cost per Contact
                    </label>
                    <div className="col-lg-8">
                      {this.state.CostPerContact.toFixed(2)}
                      {/* <input
                        className="form-control m-input"
                        id="Name"
                        name="Name"
                        placeholder="Enter Quantity"
                        type="text"
                      /> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 border-top border-bottom my-3">
                  <h4 className="headertitlepad xs-headertitle float-left">Note</h4>
                </div>

                <div className="col-lg-12 boxsinput_group">
                  <textarea
                    id="Notes"
                    className="form-control m-input"
                    type="text"
                  ></textarea>
                </div>
              </div>
              </div>

              <div className="row py-4">
                <div className="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.IsButtonDisabled}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddClientResearchPage = connect(
  mapState,
  actionCreators
)(AddClientResearchPage);
export { connectedAddClientResearchPage as AddClientResearchPage };
