import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import MeetingsSeniority from './MeetingsSeniority';
import MeetingsMonth from './MeetingsMonth';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="tabdes-2 mt-4">
      <div>
        <AppBar position="static" className='p-0'>
          <Tabs value={value} onChange={handleChange} variant="scrollable" aria-label="simple tabs example">
            <Tab label="Meetings by Seniority" {...a11yProps(0)} />
            <Tab label="Meetings by Month" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone bg-white" index={0}>
        <div className='px-3'>
          <div className="row pb-3">
            <div className="col pt-3">
              <h4 className="headertitlenop xs-headertitle float-left ">Meetings by Seniority</h4>
            </div>
          </div>

        <div class="row">
          <div className="col padd-0 border-top">
            <MeetingsSeniority />
          </div>
        </div>
        </div>

        </TabPanel>
        <TabPanel className="bg-white" value={value} index={1}>
        <div className='px-3'>
          

        <div class="row pb-3">
          <div class="col pt-3 ">
            <h4 class="headertitlenop xs-headertitle float-left">Meetings by Month</h4>
          </div>
        </div>

      <div class="row">
        <div className="col padd-0">
          <MeetingsMonth />
        </div>
      </div>
      </div>
        </TabPanel>
      </div>
    </div>
  );
}