import React, { useEffect } from "react";
const moment = require("moment");
import Axios from "axios";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails, GetClientDetails } from "../../../_helpers/Utility";
import { CSVDownloader } from "react-papaparse";
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../StickyHeader/StickyHeader";

toast.configure();

import Paper from "@material-ui/core/Paper";

const SortingIcons = ({SortedBy,sortedColumn,ColumnName}) => {
  return (
    <span className="shorting">
          <ArrowUpward className={SortedBy === 1 && sortedColumn === ColumnName? "active": null}/>
          <ArrowDownward className={SortedBy === -1 && sortedColumn === ColumnName? "active": null}/>
    </span>
  )
}

export default function AccountAllTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Name");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [UserID, SetUserID] = React.useState(0);
  const [ExportData, SetExportData] = React.useState([]);
  const [HeaderDate, SetHeaderDate] = React.useState([]);
  const [ClientID, SetClientID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);
  const [sortedColumn, setSortedColumn] = React.useState("Name");
  const [ClientName, SetClientName] = React.useState("")
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    let Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    GetClientDetails(Details.ClientID).then((result) => {
      SetClientName(result[0].Name)
    });
    OpenRateReportingDomainsGet(Details?.ParentUserID);
    let InputData = {
      UserID: Details?.ParentUserID,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/open_rate_reporting/OpenRateReportingDomainsExports",
      method: "POST",
      data: InputData,
    }).then((res) => {
      SetExportData(res.data.Data);
    });
  }, [Search, Page, RowsPerPage]);
  // Domains open rate account get
  const OpenRateReportingDomainsGet = (Uid) => {
    SetIsLoading(true);
    let InputParameterData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      UserID: Uid,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/open_rate_reporting/OpenRateReportingDomainsGet",
      method: "POST",
      data: InputParameterData,
    });
    rows1.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetHeaderDate(result.data.HeaderDates);
      SetIsLoading(false);
      setIsDataLoaded(true);

    });
  };
  // search details
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  // Export csv
  const ExportCsv = () => {
    toast.success(<div>Data exported successfully</div>);
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true)
    setSortedColumn(Field)
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");

    let InputParameterData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: SearchedVal,
      Type: "User",
      UserID: UserID,
    };

    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/open_rate_reporting/OpenRateReportingDomainsGet",
      method: "POST",
      data: InputParameterData,
    });
    rows1.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetIsLoading(false);
    });

    // let SearchedVal = document.getElementById("search").value;
    // let SerchBox;
    // if (SearchedVal == "") {
    //   SerchBox = false;
    //   SetSflag(false);
    //   let SortField = Field;
    //   let SortBy;
    //   if (SortedBy == 1) {
    //     SortBy = -1;
    //     SetSortedBy(-1);
    //   } else {
    //     SortBy = 1;
    //     SetSortedBy(1);
    //   }
    //   SetSortField(Field);
    //   let InputParameterData = {
    //     Page: Page,
    //     RowsPerPage: RowsPerPage,
    //     Sort: true,
    //     Field: SortField,
    //     SortBy: SortedBy,
    //     Search: SearchedVal,
    //     Type: "User",
    //     UserID: UserID,
    //   };

    //   const rows1 = Axios({
    //     url:
    //       CommonConstants.MOL_APIURL +
    //       "/open_rate_reporting/OpenRateReportingDomainsGet",
    //     method: "POST",
    //     data: InputParameterData,
    //   });
    //   rows1.then((result) => {
    //     SetData(result.data.PageData);
    //     SetRows(result.data.PageData);
    //     SetRlen(result.data.TotalCount);
    //     SetFlen(result.data.TotalCount);
    //     SetCountPage(result.data.PageCount);
    //     SetIsLoading(false);
    //   });
    // } else {
    //   SerchBox = true;
    //   SetSflag(true);
    //   let SortField = Field;
    //   let SortBy;
    //   if (SortedBy == 1) {
    //     SortBy = -1;
    //     SetSortedBy(-1);
    //   } else {
    //     SortBy = 1;
    //     SetSortedBy(1);
    //   }
    //   SetSortField(Field);
    //   let InputParameterData = {
    //     Page: Page,
    //     RowsPerPage: RowsPerPage,
    //     Sort: true,
    //     Field: SortField,
    //     SortBy: SortedBy,
    //     Search: SearchedVal,
    //     Type: "User",
    //     UserID: UserID,
    //   };

    //   const rows1 = Axios({
    //     url:
    //       CommonConstants.MOL_APIURL +
    //       "/open_rate_reporting/OpenRateReportingDomainsGet",
    //     method: "POST",
    //     data: InputParameterData,
    //   });
    //   rows1.then((result) => {
    //     SetData(result.data.PageData);
    //     SetRows(result.data.PageData);
    //     SetRlen(result.data.TotalCount);
    //     SetFlen(result.data.TotalCount);
    //     SetCountPage(result.data.PageCount);
    //     SetIsLoading(false);
    //   });
    // }
  };

  return (
    <>
      <div className="row py-3 align-items-center border-bottom ">
      {IsLoading ? (
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div>
        ) : (
          <></>
        )}

        <div className="col">
          <h4 className="headertitlenop xs-headertitle float-left">Domains</h4>
        </div>
        <div className="col">
          <div className="listing-li float-right">
          <ul class='my-0'>
          <li>
                <CSVDownloader
                  className="px-0"
                  data={ExportData}
                  filename={`${ClientName}-OpenRateReporting_Domains`}
                  bom={true}
                >
                  <a
                    onClick={ExportCsv}
                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                  >
                    <i class="la la-icon-font-size-13 la-download"></i>
                    <span> Export</span>
                  </a>
                </CSVDownloader>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row padt-25 px-0">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              class="form-control form-control-sm ml-2"
              placeholder=""
              onKeyPress={RequestSearch}
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper} className="table-bordered">
        <StyleHeader isDataLoaded={isDataLoaded} />
          <Table className="table-ref" aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell onClick={() => {SortData("Name");}}>
                    Clients
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Name"} />
                </TableCell>
                <TableCell  onClick={() => { SortData("Domains");}}>
                    Domains
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Domains"} />
                </TableCell>
                {HeaderDate.map((item) => {
                  return (
                    <TableCell>
                      <a
                        onClick={() => {
                          SortData("");
                        }}
                      >
                        {item}
                      </a>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows.map((row) => (
                  <TableRow key={row?._id}>
                    <TableCell>{row.ClientDetails?.Name}</TableCell>
                    <TableCell>{row?.Domain}</TableCell>
                    <TableCell>{Math.round(row?.TrackDay1OpenRate)}%</TableCell>
                    <TableCell>{Math.round(row?.TrackDay2OpenRate)}%</TableCell>
                    <TableCell>{Math.round(row?.TrackDay3OpenRate)}%</TableCell>
                    <TableCell>{Math.round(row?.TrackDay4OpenRate)}%</TableCell>
                    <TableCell>{Math.round(row?.TrackDay5OpenRate)}%</TableCell>
                    <TableCell>{Math.round(row?.TrackDay6OpenRate)}%</TableCell>
                    <TableCell>{Math.round(row?.TrackDay7OpenRate)}%</TableCell>
                    <TableCell>{Math.round(row?.TrackDay8OpenRate)}%</TableCell>
                    <TableCell>{Math.round(row?.TrackDay9OpenRate)}%</TableCell>
                    <TableCell>{Math.round(row?.TrackDay10OpenRate)}%</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
    </>
  );
}
