import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Pagination from "@material-ui/lab/Pagination";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
import { CSVReader, CSVDownloader } from "react-papaparse";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import StyleHeader from "../StickyHeader/StickyHeader";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const SortingIcons = ({SortedBy,sortedColumn,ColumnName}) => {
  return (
    <span className="shorting">
      <ArrowUpward className={SortedBy === 1 && sortedColumn === ColumnName? "active": null}/>
      <ArrowDownward className={SortedBy === -1 && sortedColumn === ColumnName? "active": null}/>
    </span>
  )
}

export default function AccountAllTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Name");
  const [sortedColumn, setSortedColumn] = React.useState("Name");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsOldClient, SetIsOldClient] = React.useState(false);
  const [DeleteArray, SetDeleteArray] = React.useState([]);
  const [ExportData, SetExportData] = React.useState([]);
  const [ClientName, SetClientName] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(true);
  const [CUserID, SetCUserID] = React.useState(0);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  const imgstyle = {
    height: "40px",
    width: "40px",
    borderradius: "50%",
  };

  useEffect(() => {
    document.title = "Clients | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    FetchDetailMethod(
      Details.ClientID,
      Details.ParentUserID,
      Details.Role,
      IsOldClient
    );
  }, [Search, Page, RowsPerPage, IsOldClient]);

  // Get Clients list
  const FetchDetailMethod = (CID, UID, URole, IsOldClients) => {
    SetIsLoading(true);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      IsOldClient: IsOldClients,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/clients/ClientsGet",
      method: "POST",
      data: InputParameter,
    });
    rows1.then((result) => {
      if (result.data.PageData === null) {
        SetData([]);
        SetRows([]);
        SetRlen(0);
        SetFlen(0);
        SetCountPage(0);
      } else {
        SetData(result.data.PageData);
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
        setIsDataLoaded(true);
      }
      SetIsLoading(false); 
    });
  };

  //export Client details
  const ExportMethod = async (UID, CID, Role) => {
    const InputParameters = {
      UserID: UID,
      ClientID: CID,
      IsOldClient: IsOldClient,
    };
    const rows1 = await Axios({
      url: CommonConstants.MOL_APIURL + "/clients/ClientsExport",
      method: "POST",
      data: InputParameters,
    });
    if (rows1.data.StatusMessage === "SUCCESS") {
      return rows1.data;
    } else {
      return rows1.data;
    }
  };

  const exportcsv = async () => {
    try {
      SetIsLoading(true);
      const result = await ExportMethod(UserID, ClientID, Role);
      if (result.StatusMessage === "SUCCESS") {
        const data = result.Data;
        if (data.length > 0) {
          SetExportData(data);
          const downloader = document.createElement("a");
          const csvContent =
            "data:text/csv;charset=utf-8," +
            encodeURIComponent(convertToCSV(data));
          downloader.setAttribute("href", csvContent);
          downloader.setAttribute(
            "download",
            result.ClientName.Name + "-Client.csv"
          );
          downloader.click();
          toast.success(<div>Data exported successfully.</div>);
          SetIsLoading(false);
        } else {
          toast.error("No data available for export.");
          SetIsLoading(false);
        }
      } else {
        toast.error("Failed to export data. Please try again.");
        SetIsLoading(false);
      }
    } catch (error) {
      SetIsLoading(false);
      toast.error("An error occurred while exporting data.");
    }
  };

  function convertToCSV(data) {
    const headers = Object.keys(data[0]);

    const csvRows = [];
    csvRows.push(headers.join(","));

    data.forEach((item) => {
      const values = headers.map((header) => {
        // Check if the value is a string before attempting to replace characters
        const value =
          typeof item[header] === "string"
            ? item[header]
            : String(item[header]);
        const escapedValue = value.replace(/"/g, '""'); // Escape double quotes
        return `"${escapedValue}"`; // Enclose value in double quotes
      });
      csvRows.push(values.join(","));
    });

    return csvRows.join("\n");
  }

  const ExportCsv = () => {
    toast.success(<div>Data exported successfully.</div>);
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal.trim());
      SetPage(1);
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };
  //get sort field data
  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Search: SearchedVal,
      Sort: true,
      Field: Field,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
      IsOldClient: IsOldClient,
    };
    const ApiResponses = Axios({
      url: CommonConstants.MOL_APIURL + "/clients/ClientsGet",
      method: "POST",
      data: InputParameter,
    });
    ApiResponses.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false);
    });

    // setSortedColumn(Field);
    // SetIsLoading(true);
    // setSortedColumn(Field);
    // var SearchedVal = document.getElementById("search").value?.trim();
    // var SerchBox;
    // if (SearchedVal == "") {
    //   SerchBox = false;
    //   SetSflag(false);
    //   var SortField = Field;
    //   var SortBy;
    //   if (SortedBy == 1) {
    //     SortBy = -1;
    //     SetSortedBy(-1);
    //   } else {
    //     SortBy = 1;
    //     SetSortedBy(1);
    //   }
    //   SetSortField(Field);
    //   var InputParameter = {
    //     Page: Page,
    //     RowsPerPage: RowsPerPage,
    //     SerchBox: false,
    //     Archive: false,
    //     Sort: true,
    //     Field: SortField,
    //     SortBy: SortBy,
    //     Type: "User",
    //     ClientID: ClientID,
    //     UserID: UserID,
    //     Role: Role,
    //     IsOldClient: IsOldClient,
    //     Search: SearchedVal,
    //   };
    //   const AccountCategoriesList = Axios({
    //     url: CommonConstants.MOL_APIURL + "/clients/ClientsGet",
    //     method: "POST",
    //     data: InputParameter,
    //   });
    //   AccountCategoriesList.then((Result) => {
    //     SetRows(Result.data.PageData);
    //     SetRlen(Result.data.TotalCount);
    //     SetFlen(Result.data.TotalCount);
    //     SetCountPage(Result.data.PageCount);
    //     SetIsLoading(false);
    //   });
    // } else {
    //   SetIsLoading(true);

    //   SerchBox = true;
    //   SetSflag(true);
    //   var SortField = Field;
    //   var SortBy;
    //   if (SortedBy == 1) {
    //     SortBy = -1;
    //     SetSortedBy(-1);
    //   } else {
    //     SortBy = 1;
    //     SetSortedBy(1);
    //   }
    //   SetSortField(Field);
    //   var InputParameter = {
    //     Page: Page,
    //     RowsPerPage: RowsPerPage,
    //     Search: SearchedVal,
    //     SerchBox: SerchBox,
    //     Archive: false,
    //     Sort: true,
    //     Field: SortField,
    //     SortBy: SortBy,
    //     Type: "User",
    //     ClientID: ClientID,
    //     UserID: UserID,
    //     Role: Role,
    //     IsOldClient: IsOldClient,
    //   };
    //   const AccountCategoriesList = Axios({
    //     url: CommonConstants.MOL_APIURL + "/clients/ClientsGet",
    //     method: "POST",
    //     data: InputParameter,
    //   });
    //   AccountCategoriesList.then((Result) => {
    //     SetRows(Result.data.PageData);
    //     SetRlen(Result.data.TotalCount);
    //     SetFlen(Result.data.TotalCount);
    //     SetCountPage(Result.data.PageCount);
    //     SetIsLoading(false);
    //   });
    // }
  };

  // Advance sorting
  const AdvanceSearch = () => {
    let IsOldClients = document.getElementById("IsOldClients").checked;
    document.getElementById("search").value = "";
    SetSearch("");
    SetIsOldClient(IsOldClients);
  };

  // all check handler
  const CheckHandler = (e) => {
    {
      Rows?.map((val) => {
        if (e.target.checked) {
          document.getElementById(val._id).checked = true;
          var temArray = DeleteArray;
          temArray.push(val._id);
          SetDeleteArray(temArray);
        } else if (!e.target.checked) {
          document.getElementById(val._id).checked = false;
          DeleteArray.splice(val, 1);
        }
      });
    }
  };

  //Add list contact
  const CheckBoxAdd = (e, ClientID) => {
    if (e.target.checked) {
      var temArray = DeleteArray;
      temArray.push(ClientID);
      SetDeleteArray(temArray);
    } else if (!e.target.checked) {
      DeleteArray.splice(DeleteArray.indexOf(ClientID), 1);
    }
  };

  //Edit Button
  const EditBtn = (id) => {
    // history.push("/editclients", id);
    history.push({
      pathname: "/editclients",
      state: { data: id, Pagename: "" },
    });
  };

  //AddPage
  const AddPage = () => {
    history.push("/addclients");
  };

  //Bulk Delete
  const BulkDelete = () => {
    if (DeleteArray.length != 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete a selected client.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          SetIsLoading(true);

          const InputParameter = {
            ClientIDs: DeleteArray,
            LastUpdatedDt: new Date(),
            LastUpdatedBy: CUserID,
          };

          Axios({
            url: CommonConstants.MOL_APIURL + "/clients/ClientDelete",
            method: "POST",
            data: InputParameter,
          }).then((Res) => {
            if ((Res.statusText = "Ok")) {
              FetchDetailMethod(ClientID, UserID, Role, IsOldClient);
              Swal.fire("Deleted!", "Client deleted successfully.", "success");
              DeleteArray.forEach((el) => {
                document.getElementById(el).checked = false;
              });
              //history.push('/clients');
              SetDeleteArray([]);
              SetIsLoading(false);
              window.location.reload();
            } else {
              toast.error(Res.data.Message);
              SetIsLoading(false);
            }
            SetIsLoading(false);
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
          SetIsLoading(false);
        }
      });
    } else {
      toast.error(
        <div className="toastsize">
          Please select at least one client from list.
        </div>
      );
      SetIsLoading(false);
    }
  };

  // Add this function somewhere in your component or scope
  const handleCheckboxChangeEmod = async (ClientId, Flag) => {
    var Str_in = {
      IsAllowEmod: !Flag,
      ClientID: ClientId,
    };
    const rows1 = await Axios({
      url: CommonConstants.MOL_APIURL + "/clients/ClientEmodUpdate",
      method: "POST",
      data: Str_in,
    });
    if (rows1.data.StatusMessage == "SUCCESS") {
      toast.success(
        <div>
          Client
          <br />
          Client eMod {Str_in.IsAllowEmod ? "activated" : "deactivated"}{" "}
          successfully
        </div>
      );
      FetchDetailMethod(ClientID, UserID, Role, IsOldClient);
    }
  };

  // function seo update related
  const handleCheckboxChangeSEOFlag = async (ClientId, Flag) => {
    var Str_in = {
      IsSeoReporting: !Flag,
      ClientID: ClientId,
    };
    console.log(Str_in, "Str_in");
    const rows1 = await Axios({
      url: CommonConstants.MOL_APIURL + "/clients/ClientSEOUpdate",
      method: "POST",
      data: Str_in,
    });
    if (rows1.data.StatusMessage == "SUCCESS") {
      toast.success(
        <div>
          Client
          <br />
          Client SEO {Str_in.IsSeoReporting ? "activated" : "deactivated"}{" "}
          successfully
        </div>
      );
      FetchDetailMethod(ClientID, UserID, Role, IsOldClient);
    }
  };

  return (
    <>
      {IsLoading === true ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row align-items-center">
        <div className="col">
          <h4 className="headertitle lg-headertitle float-left pl-0">Clients</h4>
        </div>
        <div className="col">
          <div className="listing-li float-right padb-15">
            <ul>
              <li>
                <a
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                  onClick={exportcsv}
                >
                  <i className="la la-icon-font-size-13 la-download"></i>
                  <span>Export</span>
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    BulkDelete();
                  }}
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                >
                  <i className="la flaticon-delete-1"></i>
                  <span>Delete</span>
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    AddPage();
                  }}
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                >
                  <i className="la la-icon-font-size-13 la-plus"></i>
                  <span>Add</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-white p-3  px-3">
      <div className="row">
        <div className="d-flex ml-auto ">
          <div className="full-right px-4 pt-0 pb-3 d-flex right-texts">
            <div className="row">
              <div className="col col-lg-8">
                <div className="boxborders">
                  <label className="check_bx">
                    <input type="checkbox" id="IsOldClients" />
                    <span className="checkmark"></span>
                    Include Old Clients
                  </label>
                </div>
              </div>

              <div className="col col-lg-4">
                <a
                  href="javascript:void(0);"
                  onClick={() => {
                    AdvanceSearch();
                  }}
                  className="btn btn-primary btnColor"
                >
                  <i className="la flaticon-search mr-1"></i>Search
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row ">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer className='table-left-sticky' component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} stickyIndex= '5' />
          <Table className="table-ref" aria-label="collapsible table" >
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>eMod</TableCell>
                <TableCell>SEO</TableCell>
                <TableCell  onClick={() => {SortData("checkbox");}}>
                    <input
                      name="select_all_contacts"
                      type="checkbox"
                      onChange={(e) => CheckHandler(e)}
                    />
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"checkbox"}/>
                </TableCell>
                <TableCell onClick={() => {SortData("Name");}}>
                    Name
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"Name"}/>
                </TableCell>
                <TableCell onClick={() => {SortData("ResponderString"); }}>
                    Responder
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"ResponderString"}/>
                </TableCell>
                <TableCell onClick={() => {SortData("SalesHackerString");}}>
                    Sales Strategist
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"SalesHackerString"}/>
                </TableCell>
                <TableCell className="wrap"  onClick={() => { SortData("ColdCallersString"); }}>
                    Cold Caller
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"ColdCallersString"}/>
                </TableCell>
                <TableCell  onClick={() => {SortData("ClientStatus");}}>
                    Client Status
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"ClientStatus"}/>
                </TableCell>
                <TableCell onClick={() => {SortData("ClientBillingMethod");}}>
                    Client Billing Method
                      <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"ClientBillingMethod"}/>
                </TableCell>
                <TableCell  onClick={() => {SortData("CreatedDt");}}>
                    Created Date
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"CreatedDt"}/>
                </TableCell>
                <TableCell onClick={() => {SortData("NumberOfAccounts"); }}>
                    No. of Accounts
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"NumberOfAccounts"}/>
                </TableCell>
                <TableCell  onClick={() => {SortData("ClientStartDate");}}>
                    Client Start Date
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"ClientStartDate"}/>
                </TableCell>
                <TableCell onClick={() => {SortData("ClientEndDate");}}>
                    Client End Date
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"ClientEndDate"}/>
                </TableCell>
                <TableCell onClick={() => {SortData("BillingContact");}}>
                    Client Billing Contact
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"BillingContact"}/>
                </TableCell>
                <TableCell  onClick={() => {SortData("BillingEmail");}}>
                    Client Billing Email
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"BillingEmail"}/>
                </TableCell>
                <TableCell onClick={() => { SortData("BillingAddress");}}>
                    Client Billing Address
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"BillingAddress"}/>
                </TableCell>
                <TableCell  onClick={() => {SortData("TargetAccountsperQtr");}}>
                    Target Accounts per Qtr
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"TargetAccountsperQtr"}/>
                </TableCell>
                <TableCell onClick={() => { SortData("MeetingValue");}}>
                    Default Contract Value
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"MeetingValue"}/>
                </TableCell>
                <TableCell onClick={() => {SortData("Website");}}>
                    Website
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"Website"}/>
                </TableCell>
                <TableCell onClick={() => {SortData("ReasonLostName");}}>
                    Reason Lost
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"ReasonLostName"}/>
                </TableCell>
                <TableCell onClick={() => { SortData("LogoApproval");}}>
                    Logo Approval
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"LogoApproval"}/>
                </TableCell>
                <TableCell onClick={() => {SortData("ISAddMeetingsToBlacklist");}}>
                    Add Meetings To Blacklist
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"ISAddMeetingsToBlacklist"}/>
                </TableCell>
                <TableCell  onClick={() => { SortData("ClientBillingStatus");}}>
                    Billing Status
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"ClientBillingStatus"}/>
                </TableCell>
                <TableCell onClick={() => {SortData("BillingAmount");}}>
                    Billing Amount
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"BillingAmount"}/>
                </TableCell>
                <TableCell onClick={() => {SortData("ClientBillingPeriod");}}>
                    Billing Period
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"ClientBillingPeriod"}/>
                </TableCell>
                <TableCell onClick={() => {SortData("AvgMonthlyRevenue");}}>
                    Avg Monthly Revenue
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"AvgMonthlyRevenue"}/>
                </TableCell>
                <TableCell onClick={() => {SortData("PPMEstMonths");}}>
                    PPM Est Months
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"PPMEstMonths"}/>
                </TableCell>
                <TableCell  onClick={() => {SortData("BillingDate");}}>
                    Billing Date
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"BillingDate"}/>
                </TableCell>
                <TableCell  onClick={() => { SortData("LastLoginDate");}}>
                    Last Login Date
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"LastLoginDate"}/>
                </TableCell>
                <TableCell onClick={() => { SortData("TrackDomain"); }}>
                    Track Domain
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"TrackDomain"}/>
                </TableCell>
                <TableCell onClick={() => {SortData("BCCEmail");}}>
                    BCC Email
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"BCCEmail"}/>
                </TableCell>
                <TableCell  onClick={() => {SortData("IsTrackDomainWorking");}}>
                    Track Domain Working
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"IsTrackDomainWorking"}/>
                </TableCell>
                <TableCell onClick={() => {SortData("SalesRepName");}}>
                    Closed By
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"IsTrackDomainWorking"}/>
                </TableCell>
                <TableCell onClick={() => {SortData("SlackChannelInternal");}}>
                    Slack Channel Internal
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"SlackChannelInternal"}/>
                </TableCell>
                <TableCell onClick={() => {SortData("SlackChannelExternal");}}>
                    Slack Channel External
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"SlackChannelExternal"}/>
                </TableCell>
                <TableCell onClick={() => {SortData("IsSentMeetingRescheduleNotification");}}> 
                    Is Sent Meeting Reschedule Notification
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"IsSentMeetingRescheduleNotification"}/>
                </TableCell>
                <TableCell onClick={() => { SortData("IsSentMeetingCreateNotification");}}>
                    Is Sent Meeting Create Notification
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"IsSentMeetingCreateNotification"}/>
                </TableCell>
                <TableCell onClick={()=>{SortData("ServerNumber")}}>
                    Server Number
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"ServerNumber"}/>
                </TableCell>
                <TableCell onClick={()=>{SortData("LimitEmailsPerMonthPerClient")}}>
                    Limit Email Sent Per Month
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"LimitEmailsPerMonthPerClient"}/>
                </TableCell>
                <TableCell onClick={()=>{SortData("TotalEmailsPerMonthPerClient")}}>
                    Total Email Sent Per Month
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"TotalEmailsPerMonthPerClient"}/>
                </TableCell>
                <TableCell onClick={()=>{SortData("LimitEmailsEmodPerMonthPerClient")}}>
                   Limit Emails eMod Per Month 
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"LimitEmailsEmodPerMonthPerClient"}/>
                </TableCell>
                <TableCell onClick={()=>{SortData("TotalEmailsEmodPerMonthPerClient")}}>
                    Total Emails Sent eMod Per Month 
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"TotalEmailsEmodPerMonthPerClient"}/>
                </TableCell>
                <TableCell onClick={()=>{SortData("LimitUsersPerClient")}}>
                    Limit Users 
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"LimitUsersPerClient"}/>
                </TableCell>
                <TableCell onClick={()=>{SortData("TotalClientUser")}}>
                    Total Client Users
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"TotalClientUser"}/>
                </TableCell>
                <TableCell onClick={()=>{SortData("TotalClientUserInvite")}}>
                    Total Client User Invited
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"TotalClientUserInvite"}/>
                </TableCell>
                <TableCell onClick={()=>{SortData("ZoomInfoContactLimitType")}}>
                    Zoom Info Contact Limit Type
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"ZoomInfoContactLimitType"}/>
                </TableCell>
                <TableCell onClick={()=>{SortData("ZoomInfoContactLimit")}}>
                    Zoom Info Contact Limit
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"ZoomInfoContactLimit"}/>
                </TableCell>
                <TableCell onClick={()=>{SortData("LimitTotalContactsPerClient")}}>
                    Limit Total Contacts 
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"LimitTotalContactsPerClient"}/>
                </TableCell>
                <TableCell onClick={()=>{SortData("CurrentTotalContactsPerClient")}}>
                    Total Contacts 
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"CurrentTotalContactsPerClient"}/>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow key={row._id} row={row}>
                    <TableCell>
                      <a
                        title="Edit"
                        onClick={() => {
                          EditBtn(row._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i className="la flaticon-edit-1 edit-icon"></i>
                      </a>
                    </TableCell>
                    <TableCell>
                      <div className="toggleswich">
                        <input
                          type="checkbox"
                          className="checktogle"
                          id="IsUpdateExistContacts"
                          checked={row.IsAllowEmod || false}
                          onChange={() =>
                            handleCheckboxChangeEmod(row._id, row.IsAllowEmod)
                          }
                        />
                        <b className="switch">
                          <span className="checkion"></span>
                          <span className="uncheckion"></span>
                        </b>
                        <b className="track"></b>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="toggleswich">
                        <input
                          type="checkbox"
                          className="checktogle"
                          id="IsUpdateExistContactsSEO"
                          checked={row.IsSeoReporting || false}
                          onChange={() =>
                            handleCheckboxChangeSEOFlag(
                              row._id,
                              row.IsSeoReporting
                            )
                          }
                        />
                        <b className="switch">
                          <span className="checkion"></span>
                          <span className="uncheckion"></span>
                        </b>
                        <b className="track"></b>
                      </div>
                    </TableCell>
                    <TableCell>
                      <input
                        type="checkbox"
                        id={row._id}
                        onChange={(e) => CheckBoxAdd(e, row._id)}
                      />
                    </TableCell>
                    <TableCell
                      className="labeltds d-flex align-items-center"
                      scope="row"
                    >
                      <div className="carduser_pic">
                        <img
                          style={imgstyle}
                          src={row.ProfileImage == ""? CommonConstants.Image_url + "default.png": CommonConstants.Image_url + row.ProfileImage}
                        ></img>
                      </div>
                      {row.Name}
                    </TableCell>
                    <TableCell> {row?.ResponderString}</TableCell>
                    <TableCell>{row?.SalesHackerString}</TableCell>
                    <TableCell className="wrap">{row?.ColdCallersString}</TableCell>
                    <TableCell>{row.ClientStatus}</TableCell>
                    <TableCell>{row.ClientBillingMethod}</TableCell>
                    <TableCell>{row.CreatedDt != null? moment(new Date(row.CreatedDt).toDateString()).format("MM/DD/YYYY"): ""}</TableCell>
                    <TableCell>{row.NumberOfAccounts}</TableCell>
                    <TableCell>{row.ClientStartDate != null? moment(new Date(row.ClientStartDate).toDateString()).format("MM/DD/YYYY"): ""}</TableCell>
                    <TableCell>{row.ClientEndDate != null? moment(new Date(row.ClientEndDate).toDateString()).format("MM/DD/YYYY"): ""}</TableCell>
                    <TableCell>{row.BillingContact}</TableCell>
                    <TableCell>{row.BillingEmail}</TableCell>
                    <TableCell>{row.BillingAddress}</TableCell>
                    <TableCell>{row.TargetAccountsperQtr}</TableCell>
                    <TableCell>{row.MeetingValue}</TableCell>
                    <TableCell>{row.Website}</TableCell>
                    <TableCell>{row.ReasonLostName}</TableCell>
                    <TableCell>{row.LogoApproval}</TableCell>
                    <TableCell>{row.ISAddMeetingsToBlacklist ? "Yes" : "No"}</TableCell>
                    <TableCell>{row.ClientBillingStatus}</TableCell>
                    <TableCell>{row.BillingAmount}</TableCell>
                    <TableCell>{row.ClientBillingPeriod}</TableCell>
                    <TableCell>{row.AvgMonthlyRevenue}</TableCell>
                    <TableCell>{row.PPMEstMonths}</TableCell>
                    <TableCell>{row.BillingDate != null? moment(new Date(row.BillingDate).toDateString()).format("MM/DD/YYYY"): ""}</TableCell>
                    <TableCell>{row.LastLoginDate != null? moment(new Date(row.LastLoginDate).toDateString()).format("MM/DD/YYYY"): ""}</TableCell>
                    <TableCell>{row.TrackDomain}</TableCell>
                    <TableCell>{row.BCCEmail}</TableCell>
                    <TableCell><span className={ row.IsTrackDomainWorking ? "btn-yes" : "btn-no"}>{row.IsTrackDomainWorking ? "Yes" : "No"}</span></TableCell>
                    <TableCell>{row.SalesRepName}</TableCell>
                    <TableCell>{row.SlackChannelInternal}</TableCell>
                    <TableCell>{row.SlackChannelExternal}</TableCell>
                    <TableCell>{row.IsSentMeetingRescheduleNotification ? "Yes" : "No"}</TableCell>
                    <TableCell>{row.IsSentMeetingCreateNotification ? "Yes" : "No"}</TableCell>
                    <TableCell>{row.ServerNumber}</TableCell>
                    <TableCell>{row.LimitEmailsPerMonthPerClient}</TableCell>
                    <TableCell>{row.TotalEmailsPerMonthPerClient}</TableCell>
                    <TableCell>{row.LimitEmailsEmodPerMonthPerClient}</TableCell>
                    <TableCell>{row.TotalEmailsEmodPerMonthPerClient}</TableCell>
                    <TableCell>{row.LimitUsersPerClient}</TableCell>
                    <TableCell>{row.TotalClientUser}</TableCell>
                    <TableCell>{row.TotalClientUserInvite}</TableCell>
                    <TableCell>{row.ZoomInfoContactLimitType}</TableCell>
                    <TableCell>{row.ZoomInfoContactLimit}</TableCell>
                    <TableCell>{row.LimitTotalContactsPerClient}</TableCell>
                    <TableCell>{row.CurrentTotalContactsPerClient}</TableCell>
                    <TableCell>
                      <a
                        title="Edit"
                        onClick={() => {
                          EditBtn(row._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i className="la flaticon-edit-1 edit-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className="row ">
        <div className="col dataTables_info">
          <p>
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries
          </p>
        </div>
        <div className="col pageright">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
      </div>
    </>
  );
}
