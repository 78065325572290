import React from "react";
import { connect } from "react-redux";
import Popupone from "reactjs-popup";
import Axios from "axios";
var CryptoJS = require("crypto-js");
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import ColdcallersTabing from "../../../_components/user/ColdCallers/ColdcallersTabing.js";
const EmailTemplete = require("../../../_helpers/email-templete");
import { CommonConstants } from "../../../_constants/common.constants";
import{GetUserDetails} from '../../../_helpers/Utility'
import loadingicon from "../../../images/loading.gif";
const REACT_APP_ENCRYPTION_SALT =  process.env.REACT_APP_ENCRYPTION_SALT

class ColdCallersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PopUpVal: true,
      InvitePopup: false,
      UserID:null,
      ParentUserID:null,
      ClientID:null,
      Message:"",
      IsExist:false,
      ListUpdate:false,
      FormData:{
        Email:""
      },
      errors:{},
      IsLoading:false,
      IsSendingBtn: false,
    };
    this.sendButtonRef = React.createRef();
    this.OpenInvitePopup = this.OpenInvitePopup.bind(this);
    this.CloseInvitePopup = this.CloseInvitePopup.bind(this);
    this.SubmitInvitePopup = this.SubmitInvitePopup.bind(this);
  }

  componentDidMount(){
    document.title = "Sales Development Reps | SalesHive"
    var Details = GetUserDetails();
    if(Details != null)
    {
        this.state.UserID= (Details.ChildUserID);
        this.state.ParentUserID=(Details.ParentUserID);
        this.state.ClientID=(Details.ClientID);
    }
  }

  OpenInvitePopup() {
    this.setState({ InvitePopup: true });
    this.setState({errors:{},FormData:{Email:""}});
  }
  
  CloseInvitePopup() {
    this.setState({ InvitePopup: false });
    this.setState({errors:{},FormData:{Email:""}});
  }



  async  CheckAdminInvite(Email){
    this.setState({IsLoading:true})
      var InputParameters = {
        ParentUserID: this.state.ParentUserID,
        UserID: this.state.UserID,
        Email: Email,
        ClientID:this.state.ClientID
  
      };
    let resdata = await  Axios({
        url: CommonConstants.MOL_APIURL + "/AdminUser/InviteValidation",
        method: "POST",
        data: InputParameters,
      });
      console.log(resdata.data)
        if (resdata.data.StatusMessage == "SUCCESS") {
            if(resdata.data.Flag){
                this.setState({Message:resdata.data.Message});
                this.setState({IsLoading:false})
              return false;
  
            }else{
              this.setState({Message:""});
              this.setState({IsLoading:false})
  
              return true;
            }
  
            this.setState({IsLoading:false}) 
        }
     
  }

     // Whole form validation
     async FormValidation() {
      let formIsValid = true;
      let Email = document.getElementById("EmailInvite").value;
      let IsExists = await this.CheckAdminInvite(Email);
  
      if(IsExists){
        formIsValid = true;
      }else{
        formIsValid = false;
      }
      if(Email.trim().length == 0){
        this.setState({Message:"Please enter email."})
        formIsValid = false;

      }
      return formIsValid;
    }

//send email
 async SubmitInvitePopup(e) {
  debugger
    e.preventDefault();
    e.currentTarget.disabled = true;
    var Final_flag =  await this.FormValidation();
    // document.getElementById("hidelod").style.display = "block";
    if(Final_flag==true){
    var email = document.getElementById("EmailInvite").value;

    var re =
    /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  var EmailFlg = re.test(email);
  if (EmailFlg == true) {
    var FinalEmail = CryptoJS.AES.encrypt(
      JSON.stringify(email),
      REACT_APP_ENCRYPTION_SALT
    ).toString();
    let VerificationCodeChar = (Math.random() + 1)
    .toString(36)
    .substring(7);
    var UID = CryptoJS.AES.encrypt(
      JSON.stringify(this.state.ParentUserID),
      REACT_APP_ENCRYPTION_SALT
    ).toString();
    
    var VC = CryptoJS.AES.encrypt(
      JSON.stringify(VerificationCodeChar),
      REACT_APP_ENCRYPTION_SALT
    ).toString();
    var EmailData = EmailTemplete.ResponderUserEmailInvite(
      FinalEmail,
      UID,
      UID,
      VC,
      CommonConstants.coldcallers_Invitation
    );
      var str_mail = {
        Email: email,
        html: EmailData,
        subject: "SalesHive User Account",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/user/profileemail",
        method: "POST",
        data: str_mail,
      }).then((res) => {
        if(res.statusText="OK"){
          this.setState({ InvitePopup: false });
          const InputParameters = {
            UserID:this.state.UserID,
            Email:email,
            CreatedDate:new Date(),
            VerificationCode:VerificationCodeChar
          }
          Axios({
            url: CommonConstants.MOL_APIURL + "/cold_callers_profile/ColdCallersInvitationAdd",
            method: "POST",
            data: InputParameters,
          }).then((res) => {
            toast.success(<div>Sales Development Reps<br/>
            Invitation mail sent successfully.</div>)
            this.setState({ListUpdate:!this.state.ListUpdate})
            document.getElementById("hidelod").style.display = "none";

          });

        }
      });
    } else {
    this.setState({Message:"Invalid email"});
    document.getElementById("m_emailInvite_submit").disabled = false;

    }
    }else{
      document.getElementById("m_emailInvite_submit").disabled = false;
      document.getElementById("hidelod").style.display = "none";
    }
  

  }


  // Function to validate individual form field
async ValidateField(fieldName, value) {
  const newErrors = { ...this.state.errors };
  
  switch (fieldName) {
    case "Email":
      const trimmedValue = value.trim();
      newErrors.Email = !trimmedValue ? "Please enter email." : /^\S+@\S+\.\S+$/.test(trimmedValue) ? "" : "Invalid email.";
  
      // Check if the email already exists
      if (newErrors.Email === "") {
        const emailExists = await this.CheckAdminInvite(trimmedValue);
        if (!emailExists) {
          newErrors.Email = this.state.Message;
        }
      }
  
      break;
  
    default:
      break;
  }
  
  this.setState({ errors: newErrors });
  }
  
  //Update OnChange Handler
  HandleInputChange = (e) => {
  const { name, value, type, checked } = e.target;
  this.setState((prevState) => {
    let updatedFormData = { ...prevState.FormData };
  
    if (type === "checkbox") {
      // For the checkbox, store the boolean value directly
      updatedFormData[name] = checked;
    } else {
      // For other fields, store their values
      updatedFormData[name] = value;
    }
  
    return {
      FormData: updatedFormData,
    };
  });
  };
  
  //Update OnBlur Handler
  // HandleBlur = (e) => {
  // const { name, value } = e.target;
  
  // this.ValidateField(name, value);
  // };
  HandleBlur = (e) => {
    if (this.sendButtonRef.current && this.sendButtonRef.current.contains(e.relatedTarget)) {
      return;
    }
  
    const { name, value } = e.target;
    this.ValidateField(name, value);
  };
  
  //Send Invite
  HandleSubmit = async (e) => {
  e.preventDefault();
  this.setState({ IsSendingBtn: true,ButtonDisabled: true,IsLoading:false });
  
  const { FormData } = this.state;
  const fieldNames = Object.keys(FormData);
  let isValid = true;
  
  // fieldNames.forEach((fieldName) => {
  //   this.ValidateField(fieldName, FormData[fieldName]);
  //   if (this.state.errors[fieldName]) {
  //     isValid = false;
  //   }
  // });
    // Validate each field manually
    for (let fieldName of fieldNames) {
      const value = FormData[fieldName];
      await this.ValidateField(fieldName, value);
      if (this.state.errors[fieldName]) {
        isValid = false;
      }
    }
  
  if (isValid && this.state.FormData.Email?.trim() !== "") {
  
  let Email = this.state.FormData.Email;
  var FinalEmail = CryptoJS.AES.encrypt(JSON.stringify(Email),REACT_APP_ENCRYPTION_SALT).toString();
  let VerificationCodeChar = (Math.random() + 1).toString(36).substring(7);
  var UID = CryptoJS.AES.encrypt(JSON.stringify(this.state.ParentUserID),REACT_APP_ENCRYPTION_SALT).toString();
  var VC = CryptoJS.AES.encrypt(JSON.stringify(VerificationCodeChar),REACT_APP_ENCRYPTION_SALT).toString();
  var EmailData = EmailTemplete.ResponderUserEmailInvite(FinalEmail,UID,UID,VC,CommonConstants.coldcallers_Invitation);
    var str_mail = {
      Email: Email,
      html: EmailData,
      subject: "SalesHive User Account",
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/user/profileemail",
      method: "POST",
      data: str_mail,
    }).then((res) => {
      if(res.statusText="OK"){
        this.setState({ InvitePopup: false });
        const InputParameters = {
          UserID:this.state.ParentUserID,
          Email:Email,
          CreatedDate:new Date(),
          VerificationCode:VerificationCodeChar
        }
        Axios({
          url: CommonConstants.MOL_APIURL + "/cold_callers_profile/ColdCallersInvitationAdd",
          method: "POST",
          data: InputParameters,
        }).then((res) => {
          this.setState({ IsSendingBtn: false });             
          this.setState({ ButtonDisabled: false,IsLoading:false });
          toast.success(<div>Sales Development Reps<br/>
          Invitation mail sent successfully.</div>)
          this.setState({ListUpdate:!this.state.ListUpdate})

        });

      }
    });
                       
                      
 
    // You can submit the form data to your server or perform other actions here
  } else {
    this.setState({ ButtonDisabled: false,IsLoading:false });
    this.setState({ IsSendingBtn: false });
  }

  };


  render() {
    const { FormData, errors } = this.state;
    return (
      <>
        <Popupone open={this.state.InvitePopup} modal>
          <div className="modal-black"></div>
          <div className="filterPopup bigpopupcontent">
            <div className="paddingboxTerms">
              <div className="modal-header">
                <h5>Send Mail</h5>
                <a className="close-ion" onClick={this.CloseInvitePopup}>
                  <span aria-hidden="true" class="la la-remove"></span>
                </a>
              </div>
              <div className="modal-content bordernone p-4">
                <div class="row max-cols">
                  <label class="col-lg-4 view-lable-meet">Email</label>
                  <div class="col-lg-8">
                    <input
                      class="form-control m-input"
                      id="EmailInvite"
                      name="Email"
                      placeholder="Enter Email"
                      type="text"
                      value={FormData.Email}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                      />
                      {errors.Email && (
                        <div className="errorinput">{errors.Email}</div>
                      )}
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  onClick={this.CloseInvitePopup}
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  id="m_emailInvite_submit"
                  ref={this.sendButtonRef}
                  onClick={this.HandleSubmit}
                  disabled={this.state.ButtonDisabled}
                  class="btn btn-primary btnColor"
                >
                  Send{" "}
                </button>
              </div>
            </div>
          </div>
        </Popupone>
        
        {
          this.state.IsLoading ? <div id="hidelod" className="loding-display">
          <img src={loadingicon} />
        </div> : <></>
        }

        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col padd-0">
                <h4 className="headertitlebd lg-headertitle">Sales Development Reps</h4>
              </div>
              <div className="col  pt-0">
                <div className="listing-li float-right">
                  <ul>
                    <li>
                      <a
                        onClick={this.OpenInvitePopup}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Invite</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>  

          <div className="row">
            <div className="col">
              <ColdcallersTabing listupdate={this.state.ListUpdate}/>
            </div>
          </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedColdCallersPage = connect(
  mapState,
  actionCreators
)(ColdCallersPage);
export { connectedColdCallersPage as ColdCallersPage };
