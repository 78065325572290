import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LastdaysTabing from '../Kpis/LastdaysTabing';

import ClientContactsAllTable from './ClientContactsAllTable';
import ProspectsTable from './ClientProspectsTable';
import CurrentClientsTable from './ClientCurrentClientsTable';
import PreviousClientsTable from './ClientPreviousClientsTable';
import CompetitorsTable from './ClientCompetitorsTable';
import InvestorsTable from './ClientInvestorsTable';
import PartnersTable from './ClientPartnersTable';
import UnqualifiedTable from './ClientUnqualifiedTable';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="tabdes">
      
      <div>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} variant="scrollable" aria-label="simple tabs example">
            <Tab label="All" {...a11yProps(0)}>Overviewasdasd</Tab>
            <Tab label="Prospects" {...a11yProps(1)} />
            <Tab label="Current Clients" {...a11yProps(2)} />
            <Tab label="Previous Clients" {...a11yProps(3)} />
            <Tab label="Competitors" {...a11yProps(4)} />
            <Tab label="Investors" {...a11yProps(5)} />
            <Tab label="Partners" {...a11yProps(6)} />
            <Tab label="Unqualified" {...a11yProps(7)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone inpt-0 whitebg" index={0}>


            <div className="row bg-white">
            <div className="col"> 
                <ClientContactsAllTable 
                  AccountTypeForTab={null}
                  AccountTypeName={"All"}
                /> 
            </div>
          </div>

        </TabPanel>
        <TabPanel value={value} className="tagpaddnone inpt-0 whitebg" index={1}> 
     

            <div className="row bg-white">
            <div className="col">
              <ClientContactsAllTable 
                    AccountTypeForTab={1}
                  AccountTypeName={"Prospects"}
                />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone inpt-0 whitebg"  index={2}> 
 

            <div className="row bg-white">
            <div className="col"> 
              <ClientContactsAllTable 
                 AccountTypeForTab={2}
                  AccountTypeName={"Current Clients"}
                /> 
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone inpt-0 whitebg"  index={3}> 


            <div className="row bg-white">
            <div className="col"> 
              <ClientContactsAllTable 
                    AccountTypeForTab={3}
                  AccountTypeName={"Previous Clients"}
                /> 
            </div>
          </div>

        </TabPanel>
        <TabPanel value={value} className="tagpaddnone inpt-0 whitebg"  index={4}> 
            <div className="row bg-white">
            <div className="col"> 
              <ClientContactsAllTable 
       AccountTypeForTab={4}
                  AccountTypeName={"Competitors"}
                />
              </div> 
          </div>
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone inpt-0 whitebg"  index={5}> 
   

            <div className="row bg-white">
            <div className="col"> 
              <ClientContactsAllTable 
                  AccountTypeForTab={5}
                  AccountTypeName={"Investors"}
                />
              </div> 
          </div>
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone inpt-0 whitebg"  index={6}>  
            <div className="row bg-white">
            <div className="col"> 
              <ClientContactsAllTable 
                 AccountTypeForTab={6}
                  AccountTypeName={"Partners"}
                />
              </div> 
          </div>
        </TabPanel>

        <TabPanel value={value} className="tagpaddnone inpt-0 whitebg"  index={7}> 
            <div className="row bg-white">
            <div className="col"> 
              <ClientContactsAllTable 
                  AccountTypeForTab={7}
                  AccountTypeName={"Unqualified"}
                />
              </div> 
          </div>
        </TabPanel>

      </div> 
    </div>
  );
}