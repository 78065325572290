import React, { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
const moment = require("moment");
import { makeStyles } from "@material-ui/core/styles";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import StyleHeader from "../StickyHeader/StickyHeader";

import loadingicon from "../../../images/loading.gif";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unSet",
    },
  },
});

export default function MeertingOwnerTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("ContentStatus");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID, SetCUserID] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [sortedColumn, setSortedColumn] = React.useState("ContentStatus");
  const [ContentPipeDropDown, SetContentPipeDropDown] = React.useState([]);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    document.title = `Content Calendar | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    ContectPipeLineGet(Details.ClientID, Details.ParentUserID, Details.Role);
    OwContentDropDown(Details.Role);
  }, [Search, Page, RowsPerPage]);

  const imgstyle = {
    height: "40px",
    width: "40px",
    borderradius: "50%",
  };

  // meeting owner list
  const ContectPipeLineGet = (CID, UID, URole) => {
    SetIsLoading(true);
    var MeetingOwnerData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      Sortby: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };

    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/ContentPipeline/ContectPipeLineGet",
      method: "POST",
      data: MeetingOwnerData,
    });
    rows1.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetIsLoading(false);
      setIsDataLoaded(true);

    }).catch((error) => {
      SetIsLoading(false);
      setIsDataLoaded(true);

    })
  };

  // delete btn
  const DeleteBtn = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a Content calendar.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          var data = {
            _id: id,
            LastUpdatedDate: new Date(),
            LastUpdatedBy: CUserID,
            Role: Role,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/ContentPipeline/ContentPipeLineDelete",
            method: "POST",
            data: data,
          }).then((res) => {
            if (res) {
              if ((res.statusText = "Ok")) {
                Swal.fire(
                  "Deleted!",
                  "Content Calendar deleted successfully.",
                  "success"
                );
                SetPage(1);
                SetSearch("");
                document.getElementById("search").value = "";
                ContectPipeLineGet(ClientID, UserID, Role);
              } else {
              }
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
  };
  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  // meeting owner page
  const MeetingOwnerEditPage = (id) => {
    history.push({ pathname: "/editcontentpipeline", state: { data: id } });
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("search").value;
    SetIsLoading(true);
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Search: SearchedVal,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/ContentPipeline/ContectPipeLineGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      }).catch((error) => {
        SetIsLoading(false);
      })
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/ContentPipeline/ContectPipeLineGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      }).catch((error) => {
        SetIsLoading(false);
      })
    }
  };

  //Content Drop down list
  const OwContentDropDown = (URole) => {
    var InputParameters = {
      Role: URole,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL + "/ContentPipeline/GetDropDownStatuslist",
      method: "POST",
      data: InputParameters,
    });
    rows1
      .then((Result) => {
        SetContentPipeDropDown(Result.data?.Data);
      })
      .catch((error) => {
        SetIsLoading(false);
      });
  };

  const ContentStatusSelect = (event, IDs) => {
    SetIsLoading(true);
    let DataBackLink = {
      ID: IDs,
      ContentStatusID: event.target.value,
      Role: Role,
      LastUpdatedBy: CUserID,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/ContentPipeline/UpdateContentLineStatus",
      method: "POST",
      data: DataBackLink,
    })
      .then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          ContectPipeLineGet(ClientID, UserID, Role);
          toast.success(<div>Content calendar successfully changed.</div>);
        } else {
          toast.error(res.data.Message);
          SetIsLoading(false);
        }
      })
      .catch((error) => {
       // toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
  };

  return (
    <div>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row">
        <div className="col padd-0 "></div>
      </div>
      <div className="bg-white p-3">
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
          <Table className="table-ref" aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell
                  onClick={() => {
                    SortData("ContentStatus");
                    setSortedColumn("ContentStatus");
                  }}
                >
                  Status
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "ContentStatus"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "ContentStatus"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("DueDate");
                    setSortedColumn("DueDate");
                  }}
                >
                  {" "}
                  Start Date
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "DueDate"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "DueDate"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("Title");
                    setSortedColumn("Title");
                  }}
                >
                  Title
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "Title"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "Title"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("PrimaryKeyword");
                    setSortedColumn("PrimaryKeyword");
                  }}
                >
                  Primary Keyword
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "PrimaryKeyword"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "PrimaryKeyword"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell className="wrap"
                  onClick={() => {
                    SortData("OutlineURL");
                    setSortedColumn("OutlineURL");
                  }}
                >
                  Outline URL
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "OutlineURL"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "OutlineURL"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell className="wrap"
                  onClick={() => {
                    SortData("EditedArticleURL");
                    setSortedColumn("EditedArticleURL");
                  }}
                >
                  Edited Article URL
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "EditedArticleURL"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "EditedArticleURL"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell className="wrap"
                  onClick={() => {
                    SortData("FinishedArticleURL");
                    setSortedColumn("FinishedArticleURL");
                  }}
                >
                  Finished Article URL
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "FinishedArticleURL"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "FinishedArticleURL"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell className="wrap"
                  onClick={() => {
                    SortData("LiveURL");
                    setSortedColumn("LiveURL");
                  }}
                >
                  Live URL
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "LiveURL"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "LiveURL"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("ReviewStatus");
                    setSortedColumn("ReviewStatus");
                  }}
                >
                  Review Status
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "ReviewStatus"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "ReviewStatus"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("RevisionStatus");
                    setSortedColumn("RevisionStatus");
                  }}
                >
                  Revision Status
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "RevisionStatus"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "RevisionStatus"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("RevisionDueDate");
                    setSortedColumn("RevisionDueDate");
                  }}
                >
                  Revision Due Date
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "RevisionDueDate"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "RevisionDueDate"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("OnPageOptimization");
                    setSortedColumn("OnPageOptimization");
                  }}
                >
                  On Page Optimization
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "OnPageOptimization"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "OnPageOptimization"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("InternallinksStatus");
                    setSortedColumn("InternallinksStatus");
                  }}
                >
                  Internal Links Status
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "InternallinksStatus"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 &&
                        sortedColumn === "InternallinksStatus"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("AuthorName");
                    setSortedColumn("AuthorName");
                  }}
                >
                  Author Name
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "AuthorName"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "AuthorName"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("EditorName");
                    setSortedColumn("EditorName");
                  }}
                >
                  Editor Name
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "EditorName"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "EditorName"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("CreateDate");
                    setSortedColumn("CreateDate");
                  }}
                >
                  Create Date
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "CreateDate"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "CreateDate"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("LiveDate");
                    setSortedColumn("LiveDate");
                  }}
                >
                  Live Date
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "LiveDate"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "LiveDate"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow>
                    <TableCell>
                      <select
                        className="form-control  m-input w-auto"
                        id="BacklinksStatusID"
                        name="BacklinksStatusID"
                        autocomplete="off"
                        value={row?.ContentStatusID}
                        onChange={(e) => {
                          ContentStatusSelect(e, row._id);
                        }}
                      >
                        <option value="">--select--</option>
                        {ContentPipeDropDown?.map((value, ind) => (
                          <option key={ind} value={value?.FieldID}>
                            {value?.FieldName}
                          </option>
                        ))}
                      </select>
                    </TableCell>
                    <TableCell>
                      {" "}
                      {row?.DueDate?moment(row?.DueDate).format("MM/DD/YYYY"):""}
                    </TableCell>
                    <TableCell>{row?.Title}</TableCell>
                    <TableCell>{row?.PrimaryKeyword}</TableCell>
                    <TableCell className="wrap">{row?.OutlineURL}</TableCell>
                    <TableCell className="wrap">{row?.EditedArticleURL}</TableCell>
                    <TableCell className="wrap">{row?.FinishedArticleURL}</TableCell>
                    <TableCell className="wrap">{row?.LiveURL}</TableCell>
                    <TableCell>{row?.ReviewStatus}</TableCell>
                    <TableCell>{row?.RevisionStatus}</TableCell>
                    <TableCell>
                      {" "}
                      {row?.RevisionDueDate?moment(row?.RevisionDueDate).format("MM/DD/YYYY"):""}

                    </TableCell>
                    <TableCell>{row?.OnPageOptimization}</TableCell>
                    <TableCell>{row?.InternallinksStatus}</TableCell>
                    <TableCell>{row?.AuthorName}</TableCell>
                    <TableCell>{row?.EditorName}</TableCell>
                    <TableCell>
                      {" "}
                      {row?.CreatedDate?moment(row?.CreatedDate).format("MM/DD/YYYY"):""}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {row?.LiveDate?moment(row?.LiveDate).format("MM/DD/YYYY"):""}

                    </TableCell>
                    <TableCell>
                      <a
                        onClick={() => {
                          MeetingOwnerEditPage(row._id);
                        }}
                        className="btn-eyesicon"
                        title="Edit"
                      >
                        <i class="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      <a
                        onClick={() => {
                          DeleteBtn(row._id);
                        }}
                        className="btn-eyesicon"
                        title="Delete"
                      >
                        <i class="la flaticon-delete-1 delete-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
      </div>
  
    </div>
  );
}
