import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import { CSVReader, CSVDownloader } from "react-papaparse";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import Rescheduling from "../../_components/user/Rescheduling/Rescheduling";
//import CollapsibleTable from '../_components/usertable';

import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import ReactApexChart from "react-apexcharts";

import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails } from "../../_helpers/Utility";
import loadingicons from "../../images/loading.gif";
import { history } from "../../_helpers";
import { GetClientDetails } from "../../_helpers/Utility";

charts(FusionCharts);

class ReschedulingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UserID: null,
      ClientID: null,
      CUserID: null,
      ExportData: [],
      GraphData: [],
      SourceData: {},
      TotalReschedules: 0,
      Role:null,
      CName:"",
      IsLoadExport:false
    };
   
  }

  componentDidMount() {
    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ ClientID: Details.ClientID });
      this.setState({ UserID: Details.ParentUserID });
      this.setState({ CUserID: Details.ChildUserID });
      this.setState({ UserID: Details.Role });
    }
    GetClientDetails(Details.ClientID).then((result) => {
      this.setState({ CName: result[0].Name });
    });
    this.GraphGet(Details.ClientID);
  }
  //Export CSV
  Exportdetails=async(CID)=> {
    var Details = GetUserDetails();
    const InputParameters = {
      ClientID: CID,
      Role:Details.Role
    };
    try {
      const rows1 = await Axios({
      url: CommonConstants.MOL_APIURL + "/reschedules/MeetingReschedulesExport",
      method: "POST",
      data: InputParameters,
    })
    if (rows1.data.StatusMessage === 'SUCCESS') {
      return rows1.data;
    }
  } catch (error) {
    console.error('Exportdetails error:', error);
    throw error;
  }
  }

  exportcsv = async () => {
    try {
     // document.getElementById('hidelodings').style.display = 'block';
     this.setState({IsLoadExport:true})
      const result = await this.Exportdetails(this.state.ClientID);

      if (result.StatusMessage === 'SUCCESS') {
        const data = result.Data;

        if (data.length > 0) {
          this.setState({ exportData: data });

          const downloader = document.createElement('a');
          const csvContent =
            'data:text/csv;charset=utf-8,' +
            encodeURIComponent(this.convertToCSV(data));

          downloader.setAttribute('href', csvContent);
          downloader.setAttribute(
            'download',
            this.state.CName + '-Reschedules.csv'
          );
          downloader.click();
          toast.success(
            <div>
              Data exported successfully.
            </div>
          );
          //document.getElementById('hidelodings').style.display = 'none';
          this.setState({IsLoadExport:false})
        } else {
          toast.error('No data available for export.');
          //document.getElementById('hidelodings').style.display = 'none';
          this.setState({IsLoadExport:false})
        }
      } else {
        toast.error('Failed to export data. Please try again.');
        this.setState({IsLoadExport:false})
       // document.getElementById('hidelodings').style.display = 'none';
      }
    } catch (error) {
      console.error('exportcsv error:', error);
      this.setState({IsLoadExport:false})
      toast.error('An error occurred while exporting data.');
    }
  };

  convertToCSV(data) {
    const headers = Object.keys(data[0]);
    const csvRows = [];
    csvRows.push(headers.join(','));

    data.forEach(item => {
      const values = headers.map(header => {
        const value = typeof item[header] === 'string' ? item[header] : String(item[header]);
        const escapedValue = value.replace(/"/g, '""');
        return `"${escapedValue}"`;
      });
      csvRows.push(values.join(','));
    });

    return csvRows.join('\n');
  }


  // Graph Get
  GraphGet(CID) {
    const InputParameters = {
      ClientID: CID,
      Role:this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/reschedules/GraphGet",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      this.setState({ GraphData: Res.data?.data });
      //this.setState({ TotalReschedules: Res.data?.TotalmeetingCount ===undefined?0:Res.data?.TotalmeetingCount });
      var dataSource = {
        chart: {
          // caption: "Meeting Source",
          //subcaption: "For a net-worth of $1M",
          theme: "fusion",
          subCaption: " ",
          formatnumberscale: "0",
          showLegend: "1",
          showLabels: "0",
          showValues: "1",
          valuePosition: "inside",
          numbersuffix: "%",
          plothighlighteffect: "false",
          legendcaptionbold: "1",
          legendcaptionfontsize: "15",
          legendCaptionFontColor: "#000",
          showPercentValues: "0",
          showPercentInToolTip: "0",
          enableSlicing: "1",
          legendPosition: "bottom",
          //legendCaption: "Meeting Status: ",
          legendBgColor: "#FFF",
          legendBorderColor: "#ffF",
          legendShadow: "0",
          legendItemFontSize: "14",
          legendWidth: "300", 
        },
        data: Res.data?.data,
      };
      this.setState({ TotalReschedules: Res.data?.TotalmeetingCount ===undefined?0:Res.data?.TotalmeetingCount });
      this.setState({ SourceData: dataSource });
      // document.getElementById("hidelodings").style.display = "none";
    });
  }


    //Reload page while delete perform
    UpdateFromChild = (value) => {
      if (value == true) {
        this.componentDidMount();
      }
    };
  render() {
    return (
      <>
      {
        this.state.IsLoadExport==true?(
        <>      
        <div id="hidelodings" className="loding-display">
          <img src={loadingicons} />
        </div></>):(<></>)
      }
        <div className="adminmain">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitlenop lg-headertitle px-1 py-4 pl-0">Reschedules</h4>
              </div>

            </div>

            <div className="w-100 whiterow py-4 px-4">
              <div className="row px-3">
                <div className="col px-0">
                  <div className="bg-light-sky align-center">
                    <div class="line-title text-center d-block">
                      <span class="clr-sky box-main-title">
                        {this.state.TotalReschedules}
                      </span>
                    </div>
                    <p class="clr-sky">Total Reschedules</p>
                  </div>
                </div>
              </div>
            </div>
            
            {/* <div className="row">
              <h5 className="headertitle float-left pl-3">Meeting Source</h5>
            </div> */}
            <div className="row mt-3">
              <div className="col-xl-6">
              <div className="whiterow">
                <h6 className="chartheading ">Meeting Source</h6>
                <ReactFusioncharts
                key={JSON.stringify(this.state.SourceData)}
                  type="pie3d"
                  width="100%"
                  height="400"
                  dataFormat="JSON"
                  dataSource={this.state.SourceData}
                />
              </div>
              </div>
              <div className="col-xl-6 px-0"></div> 
            </div>

            <div className="row">
              <div className="col"> 
                <h4 className="headertitle lg-headertitle float-left">
                  Reschedules
                </h4>
              </div> 
              <div className="col pt-2">
                <div className="listing-li float-right padb-15">
                  <ul className="pr-1">
                  <li>
                    {
                      this.state.TotalReschedules>0?(<a
                        className="btn btngroup m-btn m-btn--custom"
                        onClick={this.exportcsv}
                      >
                          <i class="la la-icon-font-size-13 la-download"></i>
                          Export
                      </a>):(<></>)
                    }
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="whiterow paddcols px-3 pt-0">
              <div className="row">
                <div className="col"> 
                    <Rescheduling updateFromChild={this.UpdateFromChild} /> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedReschedulingPage = connect(
  mapState,
  actionCreators
)(ReschedulingPage);
export { connectedReschedulingPage as ReschedulingPage };
