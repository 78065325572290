import React from 'react';
import { CommonConstants } from '../_constants/common.constants';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import { withTranslation, Trans, useTranslation } from 'react-i18next';
import i18n from '../i18n';
import Axios from 'axios';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import logo from '../images/SalesHive.png';
import logobgs from '../images/Loginvector.svg';
var CryptoJS = require("crypto-js");
import Popup from "reactjs-popup";

import { DropzoneArea } from 'material-ui-dropzone';

import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const Fileupload = require('../_helpers/fileupload');

/*
const options = [
    { value: 'sp', label: 'Espanol' },
    { value: 'en', label: 'English' }
];
const optionsSeleccionar = [
    { value: 'sa', label: 'Comida y Restaurantes' },
    { value: 'fd', label: 'Comida y Restaurantes new' }
];*/

class RegisterPage extends React.Component {

    constructor(props) {
        super(props);

        // reset login status
        //    this.props.logout();

        this.state = {
            files: [],
            stepTwoImagedata: [],
            FinalstepFiledata: [],
            filename: {},
            fileMenuname: {},
            filename_field: {},
            filename_menu: {},
            fields: {},
            file: '',
            finalimg: '',
            logoImageName: "",
            setFile: '',
            firstname:'',
            lastname:'',
            confirmpassword:'',
            openContinuePopup: false,
            open: false,
            username: '',
            password: '',
            pflag: false,
            submitted: false,
            openTermsConditionPopup: false,
            SelectedLang: [{ value: 'en', label: 'English' }],
            showPassword: false,
            //PreviousLang: [],
            //_Lang: 'en',
        };

        this.handleUpload = this.handleUpload.bind(this);
        this.submitdata = this.submitdata.bind(this);
        this.checkpass = this.checkpass.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    componentDidMount(){
        firstname_e.style.display = "none";
        lastname_e.style.display = "none";
        username_e.style.display = "none";
        password_e.style.display = "none";
        confirmpassword_e.style.display = "none";
        notpassword_e.style.display = "none";
        coverimage_e.style.display = "none";
        password_e_valid.style.display = "none";
        user_e.style.display = "none";
        register_u.style.display = "none";

    }

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
      };
      
    handleClose(){
        this.setState({ open: false });
    }

    _Lang = (newLang) => {
        const { value, label } = newLang;
        console.log(newLang);
        //this.setState({ _Lang: value });        
        //this.setState({ PreviousLang: this.state.SelectedLang });        
        this.setState({ SelectedLang: newLang });
        //this.openTermsConditionModal();
        i18n.changeLanguage(value);
    }

    checkpass(){
        var pass = document.getElementById('password').value;
        var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
        var tmp = re.test(pass);
        console.log(tmp);
        this.setState({ pflag : tmp });
    }
    handleUpload(field, e) {
        
        if (Fileupload.checkMimeType(e.target.files[0]) && Fileupload.checkFileSize(e.target.files[0])) {
            var temp = URL.createObjectURL(e.target.files[0])
            this.setState({
                finalimg: e.target.files[0]
            });
            document.getElementById("imagePreview").innerHTML = '<img style="width: 145px;height: 145px;position: relative;border-radius: 50%;overflow: hidden;display: flex;justify-content: center;align-items: center;border: no" className="avatar-view container2" src='+temp+' id="avtar-preview" />';
        }
    }
    submitdata(){
        
        firstname_e.style.display = "none";
        lastname_e.style.display = "none";
        username_e.style.display = "none";
        password_e.style.display = "none";
        confirmpassword_e.style.display = "none";
        notpassword_e.style.display = "none";
        coverimage_e.style.display = "none";
        password_e_valid.style.display = "none";
        user_e.style.display = "none";
        var firstname = document.getElementById('firstname').value;
        var lastname = document.getElementById('lastname').value;
        var username = document.getElementById('username').value;
        var tmppass = document.getElementById('password').value;
        // var password = CryptoJS.MD5(document.getElementById('password').value).toString();
        var password = CryptoJS.AES.encrypt(JSON.stringify(document.getElementById('password').value),"my-secret-key@123").toString();
        var confirmpassword = document.getElementById('confirmpassword').value;
        console.log(tmppass);
        console.log(confirmpassword);

        if(firstname == "" || lastname == "" || username == "" || password == "" || confirmpassword == "" || tmppass != confirmpassword || this.state.finalimg == "" || this.state.pflag == false){
            if(firstname == ""){
                firstname_e.style.display = "block";
            }
            if(lastname == ""){
                lastname_e.style.display = "block";
            }
            if(username == ""){
                username_e.style.display = "block";
            }
            if(password == ""){
                password_e.style.display = "block";
            }
            if(confirmpassword == ""){
                confirmpassword_e.style.display = "block";
            }
            if(tmppass != confirmpassword){
                notpassword_e.style.display = "block";
            }
            if(this.state.finalimg == ""){
                coverimage_e.style.display = "block";
            }
            if(this.state.pflag == false){
                password_e_valid.style.display = "block";
            }
        }else{
            console.log(this.state.finalimg);
            var name = this.state.finalimg.name;
            var filetype = name.split('.');
            console.log(filetype);
            var file_Name;
            if (/\s/g.test(filetype[0])) {
                file_Name = filetype[0].replaceAll(/\s/g, '') + new Date().getTime() + "." + filetype[1] + "";
            }
            else {
                file_Name = filetype[0] + new Date().getTime() + "." + filetype[1] + "";
            }
            

            
            Fileupload.getBase64(this.state.finalimg, (result) => {
                
                var rs = result;
                var splirs = rs.split('base64,');
                var _base64 = splirs[1];
                    var myresult = {
                        "fields": "Coverimg",
                        "path":"./Content/UploadedFiles/UserProfilePic",
                        "file": _base64,
                        "imageName": file_Name
                    };
                    console.log(myresult);
                    var str_in = {
                        "Username": username,
                    };
                    Axios({
                        url: CommonConstants.MOL_APIURL + "/user/finduser",
                        method: "POST",
                        data: str_in
                    }).then(res => {
                        console.log(res.data);
                        var udata = res.data;
                        if(udata == ""){
                            Axios({
                                url: CommonConstants.MOL_APIURL + "/newdocumentUpload/save",
                                method: "POST",
                                data: myresult,
                                
                            }).then(res => {
                                console.log("resp");
                                console.log(res);
                                this.setState({ logoImageName: file_Name });
                                if(res){
                                    var data={
                                        "Firstname":firstname,
                                        "Lastname":lastname,
                                        "Username":username,
                                        "Password":password,
                                        "RoleId":2,
                                        "CoverImg":file_Name,
                                        "IsActive":true,
                                        "IsDeleted":false,
                                        "CreatedDate": new Date(),
                                        "LastUpdatedBy":null,
                                        "LastUpdatedDate":null
                        
                                    };
                                    Axios({
                                        url: CommonConstants.MOL_APIURL + "/user/create",
                                        method: "POST",
                                        data: data
                                    }).then(res => {
                                        console.log(res);
                                        if(res){
                                            register_u.style.display = "block";
                                            window.location = "/";
                                        }
                                    });
                                }
                            });
                        }else{
                            this.setState({
                                open: true
                            });
                        }
                    });
                        
                });

            
            
        }

    }


    render() {
        const { showTemplate, showSaveAlert } = this.state;
        const { file, username, password, submitted, radio, SelectedLang } = this.state;
        const customStylesLeg = {
            option: (provided, state) => ({
                ...provided,
                paddingLeft: 20,
                color: state.isSelected ? '#5c0f8b' : '#5c0f8b',
                fontWeight: state.isSelected ? '900' : '400'
            })
        }
        const mainTtile = CommonConstants.Title;
        document.title = 'Register'+ mainTtile;
        return (
            <div className="login-main">
                <div className="row h-100">
                    <div className="col-xs-12 col-md-6 logo-bgbod padding-btn">
                        <img src={logobgs} className="login-vector" />

                        <div className="m-login__logo">
                            <a href="/">
                                <img src={logo} className="main-logo" />
                            </a>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6 d-flex">
                    <Alert id="register_u" variant="filled" severity="success" className="erorrheader">You register succesfull.</Alert>
                    <Snackbar  className="erorrheader" anchorOrigin={{ vertical:'top' ,horizontal: 'center' }} open={this.state.open} autoHideDuration={6000} onClose={this.handleClose}>
                        <Alert variant="filled" severity="error" className="erorrheader">Already register user. try with other username.</Alert>
                    </Snackbar>
                        <div className="full-hegiht">
                            <div className="centerheigh ">
                            
                                <div className="heading">
                                    <h1 className="login_title">Sign Up</h1>
                                </div>
                                <form name="register" className="form">


                                    <div className="avatar-upload">
                                        <div className="avatar-edit">
                                            <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" name="avatar" onChange={this.handleUpload.bind(this, "coverimage")} className="{{ $errors->has('email') ? 'alert alert-danger' : '' }}" />
                                            <label htmlFor="imageUpload"></label>
                                        </div>
                                        <div className="avatar-preview container2">
                                            <div id="imagePreview">
                                            </div>
                                        </div>
                                        <div>
                                            <p id="coverimage_e" className="errorinput"> Please select file </p>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col">
                                            <div className="input-control">
                                                <label htmlFor="email" className="input-label" hidden>First Name</label>
                                                <input type="text" name="firstname" id="firstname"  className="input-field" placeholder="First Name" />
                                            </div>
                                            <div>
                                            <p id="firstname_e" className="errorinput"> Please enter Firstname </p>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="input-control">
                                                <label htmlFor="email" className="input-label" hidden>Last Name</label>
                                                <input type="email" name="lastname" id="lastname" className="input-field" placeholder="Last Name" />
                                            </div>
                                            <div>
                                            <p id="lastname_e" className="errorinput"> Please enter Lastname </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-control">
                                        <label htmlFor="email" className="input-label" hidden>Username</label>
                                        <input type="email" name="username" id="username" className="input-field" placeholder="Username (e.g.:example@demo.com)" />
                                        <div>
                                            <p id="username_e" className="errorinput"> Please enter Username </p>
                                        </div>
                                    </div>

                                    <div className="input-control">
                                        <label htmlFor="password" className="input-label" hidden>Password</label>
                                        <div className='position-relative d-flex align-items-center'>
                                        <input type={this.state.showPassword ? 'text' : 'password'} name="password" id="password" onChange={this.checkpass} className="input-field" placeholder="Password" />
                                        <IconButton
                      disableRipple
                      onClick={this.handleClickShowPassword}
                      className='position-absolute rigterpin'
                    >
                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                                        </div>
                                        <div>
                                            <p id="password_e" className="errorinput"> Please enter Password </p>
                                            <p id="password_e_valid" className="errorinput">Password should be 8 character and should include capital letter and spacial latter and number. </p>
                                        </div>
                                    </div>

                                    <div className="input-control">
                                        <label htmlFor="password" className="input-label" hidden>Confirm Password</label>
                                        <input type="password" name="confirmpassword" id="confirmpassword" className="input-field" placeholder="Confirm Password" />
                                        <div>
                                            <p id="confirmpassword_e" className="errorinput"> Please enter ConfirmPassword </p>
                                        </div>
                                        <div>
                                            <p id="notpassword_e" className="errorinput" > Do not match password and confirm password </p>
                                        </div>
                                    </div>
                                    <div className="input-control btn-center">
                                        <input type="button" name="submit" className="input-submit" onClick={this.submitdata} value="Sign Up" />
                                        <p id="user_e" className="errorinput"> Already register user. try with other username.</p>
                                    </div>
                                    <div className="row m-login__form-sub">
                                        <div className="col w-100 center-texts">
                                            <a href="login" className=" text text-links">
                                                Already have an account? Login
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </div>



                        </div>
                    </div>

                </div>
            </div>

        );
    }
}


function mapState(state) {
    return state;
}

const actionCreators = {
}

const connectedRegisterPage = connect(mapState, actionCreators)(RegisterPage);
export { connectedRegisterPage as RegisterPage };