import React,{useEffect,useState} from "react";
import Axios from "axios";

import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";

import "froala-editor/js/plugins.pkgd.min.js";
import FroalaEditor from "react-froala-wysiwyg";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import Froala from "froala-editor";
import { toast } from "react-toastify";

export default function SlackEditor({HeadingName,IsRescheduling}) {
    const [ClientID,SetClientID] = useState("");
    const [UserID,SetUserID] = useState("");
    const [ChildUserID,SetChildUserID] = useState("");
    const [Role,SetRole] = useState("");
    const [DataExists,SetDataExists] = useState(true)
    const [IsLoading,SetIsLoading] = useState(false)
    const [FormData,SetFormData] = useState({
            _id: "",
            UserID:"",
            ClientID: "",
            SlackText:"",
            Subject: "",
            ChannelID: "",
            IsDeleted: false,
            CreatedDate: new Date(),
            LastUpdatedDate:new Date(),
            IsRescheduling: IsRescheduling,
        
    });
    const [PreviewData,SetPerviewData] = useState({
      SubjectPerview:"",
      BodyPerview:"",
    });
    const [ChannelID,SetChannelID] = React.useState('');
    const [IsChannelExists,SetIsChannelExists] = React.useState(false)
    const Variables ={
    "<b>Firstname:</b> (Meeting.FIRSTNAME)":"Meeting.FIRSTNAME",
    "<b>Lastname:</b> (Meeting.LASTNAME)":"Meeting.LASTNAME",
    "<b>Title:</b> (Meeting.TITLE)":"Meeting.TITLE",
    "<b>Company:</b> (Meeting.COMPANY)":"Meeting.COMPANY",
    "<b>Email:</b> (Meeting.EMAIL)":"Meeting.EMAIL",
    "<b>Linkedinurl:</b> (Meeting.LINKEDINURL)":"Meeting.LINKEDINURL",
    "<b>Directphone:</b> (Meeting.DIRECTPHONE)":"Meeting.DIRECTPHONE",
    "<b>Directphoneext:</b> (Meeting.DIRECTPHONEEXT)":"Meeting.DIRECTPHONEEXT",
    "<b>Companyphone:</b> (Meeting.COMPANYPHONE)":"Meeting.COMPANYPHONE",
    "<b>Website:</b> (Meeting.WEBSITE)":"Meeting.WEBSITE",
    "<b>Meetingstatus:</b> (Meeting.MEETINGSTATUS)":"Meeting.MEETINGSTATUS",
    "<b>Meetingsource:</b> (Meeting.MEETINGSOURCE)":"Meeting.MEETINGSOURCE",
    "<b>Meetingsetdate:</b> (Meeting.MEETINGSETDATE)":"Meeting.MEETINGSETDATE",
    "<b>Meetingsetby:</b> (Meeting.MEETINGSETBY)":"Meeting.MEETINGSETBY",
    "<b>Meetingdate:</b> (Meeting.MEETINGDATE)":"Meeting.MEETINGDATE",
    "<b>Meetingtimezone:</b> (Meeting.MEETINGTIMEZONE)":"Meeting.MEETINGTIMEZONE",
    "<b>Owner:</b> (Meeting.OWNER)":"Meeting.OWNER",
    "<b>Reminderemailaccount:</b> (Meeting.REMINDEREMAILACCOUNT)":"Meeting.REMINDEREMAILACCOUNT",
    "<b>Contractvalue:</b> (Meeting.CONTRACTVALUE)":"Meeting.CONTRACTVALUE",
    "<b>Industry:</b> (Meeting.INDUSTRY)":"Meeting.INDUSTRY",
    "<b>Contactcategory:</b> (CONTACT.CONTACTCATEGORY)":"CONTACT.CONTACTCATEGORY",
    "<b>Accountcategory:</b> (CONTACT.ACCOUNTCATEGORY)":"CONTACT.ACCOUNTCATEGORY",
    "<b>Companynamelong:</b> (CONTACT.COMPANYNAMELONG)":"CONTACT.COMPANYNAMELONG",
    "<b>Address1:</b> (CONTACT.ADDRESS1)":"CONTACT.ADDRESS1",
    "<b>Address2:</b> (CONTACT.ADDRESS2)":"CONTACT.ADDRESS2",
    "<b>City:</b> (CONTACT.CITY)":"CONTACT.CITY",
    "<b>State:</b> (CONTACT.STATE)":"CONTACT.STATE",
    "<b>Country:</b> (CONTACT.COUNTRY)":"CONTACT.COUNTRY",
    "<b>Zip:</b> (CONTACT.ZIP)":"CONTACT.ZIP"
    }
    useEffect(() => {
        document.title = `Slack Notifications | SalesHive`;
        var Details = GetUserDetails();
        if (Details != null) {
          SetClientID(Details.ClientID);
          SetUserID(Details.ParentUserID);
          SetChildUserID(Details.ChildUserID);
          SetRole(Details.Role);
        }
    
        SlackNotificationsGet(Details.ClientID, Details.ParentUserID, Details.Role,IsRescheduling);
        Froala.RegisterCommand("Variable", {
            title: "Advanced options",
            type: "dropdown",
            focus: false,
            undo: false,
            refreshAfterCallback: true,
            options: Variables,
            callback: function (cmd, val) {
              var editorInstance = this;
              editorInstance.html.insert(val);
            },
            // Callback on refresh.
            refresh: function ($btn) {
              //
            },
            // Callback on dropdown show.
            refreshOnShow: function ($btn, $dropdown) {
              //
            },
          });
      }, [IsRescheduling]);

      let config = {
        placeholderText: "Type something!",
        charCounterCount: false,
        toolbarButtons: [
          "bold",
          "italic",
          "html",
          "Variable",
        ],
        //shortcutsEnabled: ["insertTemplateButton"],
        imageUploadURL:
          CommonConstants.MOL_APIURL + "/meetingremindersetting/upload_image",
        imageUploadRemoteUrls: false,
        enter: Froala.ENTER_BR,
        key:CommonConstants.EditorValidationKey,
        height: 300,
        pastePlain: true,
        attribution: false
      };

      // Getting Slack Notifications details
  const SlackNotificationsGet = (CID, UID, URole,IsRescheduling) => {
    SetIsLoading(true)
    var MeetingGoalData = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
      IsRescheduling:IsRescheduling
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/slacknotification/GetSlackNotificationDetails",
      method: "POST",
      data: MeetingGoalData,
    });
    rows1.then((Result) => {
      if(Result.data?.Data.length > 0){
        let ObjectData = Result.data?.Data[0];
        let StateData = {...FormData,
         _id:ObjectData?._id,
         UserID:ObjectData?.UserID,
         ClientID: ObjectData?.ClientID,
         SlackText:ObjectData?.SlackText,
         Subject: ObjectData?.Subject,
         ChannelID: ObjectData?.ChannelID,
     }
     PreviewSlacknBody(UID,CID,ObjectData?.Subject,ObjectData?.SlackText)
     SetFormData(StateData)
     SetDataExists(true)
    SetIsLoading(false)

      }else{
        SetDataExists(false)
    SetIsLoading(false)

      }
      
    });
  };

  //Changing Floral Editor Body method
  const SlackTextChange = (model) => {
    let StateData = {...FormData};
    let Obj = {...StateData,SlackText:model}
    SetFormData(Obj)
  };

    //Update OnChange Handler
  const HandleInputChange = (e) => {
        const { name, value } = e.target;
        SetFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
  };

  const SaveSlackNotfication = (e) => {
    e.preventDefault()
    SetIsLoading(true)

    let Data= {...FormData,DataExists};
    if(Data.SlackText.trim() === ""){
      toast.error((<div className="toastsize">Edit Slack Setting <br/>
      Slack text is empty, fill it!</div>))
    SetIsLoading(false)

    }else if(Data.Subject.trim() === ""){
      toast.error((<div className="toastsize">Edit Slack Setting<br/>
      Slack subject is empty, fill it!</div>))
    SetIsLoading(false)

    }else{
      console.log(Data)
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/slacknotification/UpdateAddSlackNotifications",
        method: "POST",
        data: Data,
      });
      rows1.then((Result) => {
        if(Result.data.StatusMessage === "SUCCESS"){
          SlackNotificationsGet(ClientID, UserID, Role,IsRescheduling)
          PreviewSlacknBody(ClientID, UserID, Data.Subject,Data.SlackText)
    SetIsLoading(false)

          toast.success(<div className="toastsize">Edit Slack Notifications <br/>
            Slack notifications updated successfully!</div>)

        }
      })
    }
   
  }

  //Preview Slack body and text
  const PreviewSlacknBody = (UserID,ClientID,Subject,SlackBody) => {
    SetIsLoading(true)

    const InputParameters = {
      UserID,
      ClientID,
      Subject,
      SlackBody
    }
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/slacknotification/SlackNotificationPerview",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((Result) => {
      if(Result.data.StatusMessage === "SUCCESS"){
        let {BodyPerview,SubjectPerview} = Result.data
          console.log({BodyPerview,SubjectPerview})
          SetPerviewData({BodyPerview,SubjectPerview})
      }
    SetIsLoading(false)

    })
  }

// Getting Slack ChannelID and name
const SlackChannelMethod = async (value) => {
  try {
    const InputParameters = {
      ChannelName: value,
    };

    const response = await Axios({
      url: CommonConstants.MOL_APIURL + "/Utility/CheckSlackChannelExists",
      method: "POST",
      data: InputParameters,
    });

    console.log(response);

    SetChannelID(response.data.ChannelID);
    SetIsChannelExists(response.data.IsChannelExists);

    if (IsRescheduling === false && response.data.IsChannelExists === false) {
      toast.error(
        <div className="toastsize">
          Slack Notifications <br /> Please check client slack configuration
        </div>
      );
    } else if (IsRescheduling === true && response.data.IsChannelExists === false) {
      toast.error(
        <div className="toastsize">
          Slack Notifications <br /> Please check client slack configuration
        </div>
      );
    } else if (IsRescheduling === false && response.data.IsChannelExists === true) {
      await SendMessageSlack(response.data.ChannelID);
    } else if (IsRescheduling === true && response.data.IsChannelExists === true) {
      await SendMessageSlack(response.data.ChannelID);
    }
  } catch (error) {
    console.error("Error in SlackChannelMethod:", error.message);
  }
};

// Send Message in slack
const SendMessageSlack = async (ID) => {
  try {
    const InputParameters = {
      ChannelID: ID,
      Subject: `<strong>${PreviewData.SubjectPerview}</strong>`,
      SlackText: PreviewData.BodyPerview,
      // SlackText: 'http://www.example.com|This message *is* a link>',
    };

    const res = await Axios.post(
      CommonConstants.MOL_APIURL + "/Utility/SendMessageInSlack",
      InputParameters
    );

    if (res.data.Status === "SUCCESS") {
      toast.success(<div>Test Message sent!</div>);
    } else {
      toast.error(<div>Something went wrong!</div>);
    }
  } catch (error) {
    console.error("Error sending Slack message:", error.message);
  }
};


  //Send Test
  const SendTest = async () => {
    SetIsLoading(true)
    const InputParameters = {
        ClientID,
        Role
    };

    try {
        const response = await Axios({
            url: CommonConstants.MOL_APIURL + "/slacknotification/GetClientSlackChannel",
            method: "POST",
            data: InputParameters,
        });
        const Result = response.data.Data;
        const { ExternalChannelID, InternelChannelID, SlackChannelExternal, SlackChannelInternal } = Result;
        console.log({ ExternalChannelID, InternelChannelID, SlackChannelExternal, SlackChannelInternal });
          if(parseInt(FormData.ChannelID)==2)
          {
            if(SlackChannelExternal !== null && SlackChannelExternal.trim() !== ""){
              await SlackChannelMethod(SlackChannelExternal);
            }else{
              toast.error(<div className="toastsize">Slack Notifications <br/> Please check client slack configuration</div>)
            }

          }
          else
          {
            if(SlackChannelInternal !== null && SlackChannelInternal.trim() !== "" ){
            await SlackChannelMethod(SlackChannelInternal);
            console.log(ChannelID)
            
          }else{
            toast.error(<div className="toastsize">Slack Notifications <br/> Please check client slack configuration</div>)
          }

          }
        // if(IsRescheduling === false){
        //   if(SlackChannelInternal !== null && SlackChannelInternal.trim() !== "" ){
        //     await SlackChannelMethod(SlackChannelInternal);
        //     console.log(ChannelID)
            
        //   }else{
        //     toast.error(<div className="toastsize">Slack Notifications <br/> Please check client slack configuration</div>)
        //   }
        // }else if(IsRescheduling === true){
        //   if(SlackChannelExternal !== null && SlackChannelExternal.trim() !== ""){
        //     await SlackChannelMethod(SlackChannelExternal);
        //   }else{
        //     toast.error(<div className="toastsize">Slack Notifications <br/> Please check client slack configuration</div>)
        //   }
        // }
    } catch (error) {
        console.error('Error fetching client Slack channels:', error.message);
    }
    SetIsLoading(false)
};

  return (
    <div className="tabdes">
      {
        IsLoading ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>
      }
       
      <div className="row mx-0 border-bottom justify-content-between align-items-center">
        <div className="">
          <h4 className="headertitle xs-headertitle float-left">{HeadingName}</h4>
        </div>
        <div className="mx-2">
          <div className="listing-li float-right">
          <button
                  onClick={SendTest}
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                >
                  <i className="la la-send la-icon-font-size-13"></i>
                  <span>Send Test</span>
                </button>
                <button className="btn btn-primary btn-lightgreen mr-1" onClick={SaveSlackNotfication}>
            <i className="la la-save"></i> Save
          </button>
          </div>
        </div>
      </div>

    

      <div className="row mx-0 border-bottom pt-4 px-2 pr-4">
        <div className="col-lg-12 boxsinput_group">
          <div className="row align-items-center">
            <label className="col-lg-3 mb-0  align-right">Channel</label>
            <div className="col-lg-3">
              <select className="form-control m-input valid"
                value={FormData.ChannelID}
                onChange={HandleInputChange}
                name="ChannelID"
              >
                <option value="">----Select----</option>
                <option value="1">Slack Channel Internal</option>
                <option value="2">Slack Channel External</option>
              </select>
            </div>
          </div>
        </div>

        <div className="col-lg-12 boxsinput_group">
          <div className="row">
            <label className="col-lg-3 mb-0 align-right">Subject</label>
            <div className="col-lg-9">
              <input
                className="form-control m-input"
                id="Name"
                name="Subject"
                value={FormData.Subject}
                onChange={HandleInputChange}

                type="text"
              />
            </div>
          </div>
        </div>

        <div className="col-lg-12 boxsinput_group">
          <div className="row">
            <label className="col-lg-3 mb-0 align-right">Slack Text</label>
            <div className="col-lg-9">
              <FroalaEditor 
              id="signature"
                    config={config}
                    onModelChange={SlackTextChange}
                    model={FormData.SlackText}
               />
            </div>
          </div>
        </div>
      </div>



      <div className="row mx-0 border-bottom align-items-center">
        <div className="col">
          <h4 className="headertitle xs-headertitle float-left pl-0">Preview</h4>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col px-5">
          <p>
            <b>Subject : {PreviewData.SubjectPerview}</b>
            <br/>
            <br/>
            <div dangerouslySetInnerHTML={{ __html: PreviewData.BodyPerview }} />
           
          </p>
        </div>
      </div>
    </div>
  );
}
