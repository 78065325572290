import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Axios from "axios";

import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class EditGlobalContactBlacklistPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      fields: {},
      errors: {},
      ClientID: Details.ClientID,
      UserID: Details.ChildUserID,
      PUserID: Details.ParentUserID,
      IsExist: false,
      PrevEmail:null,
      IsValid:true,
      IsLoading:false,
      BtnDisabled:false
    };
    this.GetBlackListEmail();
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {}

  GetBlackListEmail() {
    this.setState({IsLoading:true})
    const InputParameters={
      ID: this.props.location.state,
    }
    Axios({
      url: CommonConstants.MOL_APIURL + "/globalcontactblacklist/GlobalContactBlacklistGetByID",
      method: "POST",
      data:InputParameters
    }).then((res) => {
     
     document.getElementById('Email').value=res.data.Data.Email
     this.setState({PrevEmail:res.data.Data.Email})
     this.setState({IsLoading:false})
    });
  }

  // Form on blur method for validation

  async FormBlur(){
    let formIsValid = true;
    let errors = {};
    var Email = document.getElementById("Email").value.trim();
    let EmailValidation = await this.ValidateEmail(Email);
    let IsExists = await this.CheckExistEmail(Email);
    if (Email.length==0) {
      formIsValid = false;
      errors["Email"] = "Please enter email";
    }else  if (IsExists == true) {
      formIsValid = false;
      errors["Email"] = "Email is already exists!";
    }else if(EmailValidation == false){
      formIsValid = false;
      errors["Email"] = "Invalid Email";
    }
   
    this.setState({ errors: errors });
  }

 async FormValidation() {
    let formIsValid = true;
      let errors = {};
      var Email = document.getElementById("Email").value.trim();
      let EmailValidation = await this.ValidateEmail(Email);
      let IsExists = await this.CheckExistEmail(Email);
      if (Email.length==0) {
        formIsValid = false;
        errors["Email"] = "Please enter email";
      }else  if (IsExists == true) {
        formIsValid = false;
        errors["Email"] = "Email is already exists!";
      }else if(EmailValidation == false){
        formIsValid = false;
        errors["Email"] = "Invalid Email !";
      }
     
      this.setState({ errors: errors });
    
    return formIsValid;
  }

  //Email validation
 async ValidateEmail(input) {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (input.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  }
  
  // check exit Email
 async CheckExistEmail() {
    var Email = document.getElementById("Email").value;
    var InputParameter = {
      Email: Email,
      UserID:this.state.PUserID
    };
   let resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/globalcontactblacklist/GlobalContactBlacklistExists",
      method: "POST",
      data: InputParameter,
    })
      let errors = {};
      
      if (resdata.data.Data > 0 && this.state.PrevEmail != Email) {
        let fields = this.state.fields;
        if (!fields["Email"]) {
                errors["Email"] = "Email is already exists!";
        }
        this.setState({ IsExist: true });
        this.setState({ errors: errors }); 
        return true;
      }else{
        this.setState({ IsExist: false });
        this.setState({ errors: errors }); 
        return false;
      }
  }

  backbtn() {
    history.push({pathname:"/globalblacklist",state:{page:"contact"}});
  }
 async savebtn() {
  this.setState({IsLoading:true,BtnDisabled:true})
    var Email = document.getElementById("Email").value;
    let FormValidation = await this.FormValidation();

    if (FormValidation == true && this.state.IsExist == false){
      var data = {
        ID:this.props.location.state,
        Email:Email,
        UserID: this.state.UserID,
        LastUpdatedBy: this.state.UserID,
        LastUpdatedDate: new Date(),
      };
     let res = await Axios({
        url: CommonConstants.MOL_APIURL + "/globalcontactblacklist/GlobalContactBlacklistUpdate",
        method: "POST",
        data: data,
      })
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize">Global Contact Blacklist <br/>Global contact blacklist updated successfully.</div>);
          history.push({pathname:"/globalblacklist",state:{page:"contact"}});
          
        } else {
          this.setState({IsLoading:false,BtnDisabled:false})
          toast.error(res.data.Message);
        }
      
    }else{
      this.setState({IsLoading:false,BtnDisabled:false})

    }
  }

  render() {
    return (
      <>
      {
        this.state.IsLoading ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>
      }
      
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad lg-headertitle float-left">Edit </h4>
                </div>
              </div>

            <div className="bg-white p-3">
              <div className="row">  
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Email</label>
                    <div className="col-lg-8">
                      <input className="form-control m-input"onBlur={()=>{this.FormBlur()}} id="Email" placeholder="Enter Email" type="text" />
                    <span style={{ color: "red" }}>{this.state.errors["Email"]}</span>

                    </div>
                  </div>
                </div>  
              </div>
            </div>

              <div className="row mb-5 mt-3">
                <div className="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.savebtn}
                    disabled={this.state.BtnDisabled}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.backbtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>

          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditGlobalContactBlacklistPage = connect(
  mapState,
  actionCreators
)(EditGlobalContactBlacklistPage);
export { connectedEditGlobalContactBlacklistPage as EditGlobalContactBlacklistPage };
