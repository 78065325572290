import { HomePage } from '../user/HomePage';
import { EditMyProfilePage } from '../user/MyProfile';
import { ResetPasswordPage } from '../user/ResetPasswordPage';
import { KPIsPage } from '../user/KPIsPage';
import { AccountsPage } from '../user/AccountsPage';
import { AddAccountPage } from '../user/AccountsPage/AddAccountPage';
import { ViewAccountPage } from '../user/AccountsPage/ViewAccountPage/ViewAccountPage';
import { EditAccountsPage } from '../user/AccountsPage/EditAccountsPage/EditAccountsPage';
import { ContactsPage } from '../user/ContactsPage';
import { AddContactPage } from '../user/ContactsPage/AddContactPage/AddContactPage';
import { EditContactPage } from '../user/ContactsPage/EditContactPage/EditContactPage';
import { ViewContactPage } from '../user/ContactsPage/ViewContactPage/ViewContactPage';
import { PotentialMeetingsPage } from '../user/PotentialMeetingsPage/PotentialMeetingsPage';
import { BookedMeetingsPage } from '../user/BookedMeetingsPage/BookedMeetingsPage';
import { AddBookedMeetingPage } from '../user/BookedMeetingsPage/AddBookedMeetingPage/AddBookedMeetingPage';
import { ViewBookedMeetingPage } from '../user/BookedMeetingsPage/ViewBookedMeetingPage/ViewBookedMeetingPage';
import { EditBookedMeetingPage } from '../user/BookedMeetingsPage/EditBookedMeetingPage/EditBookedMeetingPage'
import { AddPotentialMeetingsPage } from '../user/PotentialMeetingsPage/AddPotentialMeetingsPage';
import { EditPotentialMeetingsPage } from '../user/PotentialMeetingsPage/EditPotentialMeetingsPage';
import { ViewPotentialMeetingsPage } from '../user/PotentialMeetingsPage/ViewPotentialMeetingsPage';
import { OpportunitiesPage } from '../user/OpportunitiesPages/OpportunitiesPage';
import { ViewOpportunitiesPage } from '../user/OpportunitiesPages/ViewOpportunitiesPage'
import { AddOpportunitiesPage } from '../user/OpportunitiesPages/AddOpportunitiesPage'
import { EditOpportunitiesPage } from '../user/OpportunitiesPages/EditOpportunitiesPage'
import { LinkedInFollowUpPage } from '../user/LinkedInFollowUpPages/LinkedInFollowUpPage';
import { ReschedulingPage } from '../user/ReschedulingPage';
import { ColdCallingContactPage } from '../user/ColdCallingContactPage';
import { AddColdCallingContactPage } from '../user/ColdCallingContactPage/AddColdCallingContactPage';
import { CallRecordingsPage } from '../user/CallRecordingsPage';
import { CallHistoryPage } from '../user/CallHistoryPage';
import { ColdCallingReportingPage } from '../user/ColdCallingReportingPage';
import { AddSalesHiveContactPage } from '../user/AddSalesHiveContactPage';
import { AccountCustomColumnPage } from '../user/AccountCustomColumnPage';
import { ResponderPage } from '../user/Responder/ResponderPage';
import { Listspage } from '../user/Listspage';
import { AddListspage } from '../user/Listspage/AddListspage/AddListspage';
import { EditListspage } from '../user/Listspage/EditListspage/EditListspage';
import { EditResponderPage } from '../user/Responder/EditResponderPage';
import { AddResponderPage } from '../user/Responder/AddResponderPage';
import { UpdateEmailResponderPage } from '../user/Responder/UpdateEmailResponderPage';
import { SeoPage } from '../user/SeoUserPage/SeoPage';
import { EditSeoPage } from '../user/SeoUserPage/EditSeoPage';
import { AddSeoPage } from '../user/SeoUserPage/AddSeoPage';
import { UpdateEmailSeoPage } from '../user/SeoUserPage/UpdateEmailSeoPage';
import { UpdateEmailColdCallersPage } from '../user/ColdCallers/UpdateEmailColdCallersPage'
import { AddColdCallersPage } from '../user/ColdCallers/AddColdCallersPage';
import { ColdCallersPage } from '../user/ColdCallers/ColdCallersPage';
import { EditColdCallersPage } from '../user/ColdCallers/EditColdCallersPage';
import { ColdCallerTeamsPage } from '../user/ColdCallerTeam/ColdCallerTeamsPage';
import { SalesStrategistsPage } from '../user/SalesStrategists/SalesStrategistsPage';
import { EditSalesStrategistsPage } from '../user/SalesStrategists/EditSalesStrategistsPage';
import { AddSalesStrategistsPage } from '../user/SalesStrategists/AddSalesStrategistsPage';
import { UpdateEmailSalesStrategistsPage } from '../user/SalesStrategists/UpdateEmailSalesStrategistsPage';
import { AdminUserPage } from '../user/AdminUser/AdminUserPage';
import { AddAdminUserPage } from '../user/AdminUser/AddAdminUserPage';
import { EditAdminUserPage } from '../user/AdminUser/EditAdminUserPage';
import { UpdateEmailAdminUserPage } from '../user/AdminUser/UpdateEmailAdminUserPage';
import { AccountCategoryPage } from '../user/AccountCategory/AccountCategoryPage';
import { AddAccountsPage } from '../user/AccountCategory/AddAccountPage/AddAccountPage';
import { AddAccountCategoryPage } from '../user/AccountCategory/AddAccountCategoryPage';
import { EditAccountCategoryPage } from '../user/AccountCategory/EditAccountCategoryPage';
import { AccountClientStatusPage } from '../user/AccountClientStatus/AccountClientStatusPage';
import { AddAccountClientStatusPage } from '../user/AccountClientStatus/AddAccountClientStatusPage';
import { EditAccountClientStatusPage } from '../user/AccountClientStatus/EditAccountClientStatusPage';
import { AccountClientStatusOrderPage } from '../user/AccountClientStatus/AccountClientStatusOrderPage';
import { MeetingGoalPage } from '../user/MeetingGoal/MeetingGoalPage';
import { AddMeetingGoalPage } from '../user/MeetingGoal/AddMeetingGoalPage';
import { EditMeetingGoalPage } from '../user/MeetingGoal/EditMeetingGoalPage';
import { VariablePage } from '../user/VariablePage';
import { CustomVariablePage } from '../user/CustomVariablePage/CustomVariablePage';
import { AddCustomVariablePage } from '../user/CustomVariablePage/AddCustomVariablePage';
import { EditCustomVariablePage } from '../user/CustomVariablePage/EditCustomVariablePage';
import { CallToActionPage } from '../user/CallToActionPage';
import { CustomVariableSetPage } from '../user/CustomVariableSetPage';
import { EmailHistoryPage } from '../user/EmailHistoryPage';
import { EmailResponcesPage } from '../user/EmailResponces';
import { MeetingStatusesPage } from '../user/MeetingStatuses/MeetingStatusesPage';
import { AddMeetingStatusesPage } from '../user/MeetingStatuses/AddMeetingStatusesPage';
import { EditMeetingStatusesPage } from '../user/MeetingStatuses/EditMeetingStatusesPage';
import { MeetingStatusesOrderPage } from '../user/MeetingStatuses/MeetingStatusesOrderPage';
import { MeetingOwnerPage } from '../user/MeetingOwner/MeetingOwnerPage';
import { AddMeetingOwnerPage } from '../user/MeetingOwner/AddMeetingOwnerPage';
import { EditMeetingOwnerPage } from '../user/MeetingOwner/EditMeetingOwnerPage';
import { ActionItemsPage } from '../user/ActionItemsPage';
import { AddActionItemsPage } from '../user/ActionItemsPage/AddActionItemsPage';
import { EditActionItemsPage } from '../user/ActionItemsPage/EditActionItemsPage';
import { DocumentsPage } from '../user/DocumentsPage';
import { AddDocumentsPage } from '../user/DocumentsPage/AddDocumentsPage';
import { EditDocumentsPage } from '../user/DocumentsPage/EditDocumentsPage';
import { DirectMailPage } from '../user/DirectMailPage';
import { AddDirectMailPage } from '../user/DirectMailPage/AddDirectMailPage';
import { EditDirectMailPage } from '../user/DirectMailPage/EditDirectMailPage';
import { ResearchPage } from '../user/ResearchPage';
import { AddResearchPage } from '../user/ResearchPage/AddResearchPage';
import { EditResearchPage } from '../user/ResearchPage/EditResearchPage';
import { InsightsPage } from '../user/InsightsPage';
import { CampaignSummaryPage } from '../user/CampaignSummaryPage';
import { AutomatorPage } from '../user/AutomatorPage';
import { DespammerPage } from '../user/DespammerPage';
import { EmailValidationPage } from '../user/EmailValidationPage';
import { DeliverabilityTestingPage } from '../user/DeliverabilityTestingPage';
import { AddDeliverabilityTestingPage } from '../user/DeliverabilityTestingPage/AddDeliverabilityTestingPage';
import { EditDeliverabilityTestingPage } from '../user/DeliverabilityTestingPage/EditDeliverabilityTestingPage';
import { OpenRateReportingPage } from '../user/OpenRateReportingPage';
import { ClickTrackReportingPage } from '../user/ClickTrackReportingPage';
import { GlobalBouncePage } from '../user/GlobalBouncePage';
import { EmailWarmUpPage } from '../user/EmailWarmUpPage';
import { EditEmailWarmUpPage } from '../user/EmailWarmUpPage/EditEmailWarmUpPage';
import { EmailWarmUpHistoryPage } from '../user/EmailWarmUpPage/EmailWarmUpHistoryPage';
import { ContactBulkImportRequestPage } from '../user/ContactBulkImportRequestPage';
import { ContactBulkExportRequestPage } from '../user/ContactBulkExportRequestPage';
import { ObjectionsPage } from '../user/ObjectionsPage';
import { AddObjectionsPage } from '../user/ObjectionsPage/AddObjectionsPage';
import { EditObjectionsPage } from '../user/ObjectionsPage/EditObjectionsPage';
import { UsersPerClientPage } from '../user/UsersPerClientPage';
import { EditUsersPerClientPage } from '../user/UsersPerClientPage/EditUsersPerClientPage';
import { AddUsersPerClientPage } from '../user/UsersPerClientPage/AddUsersPerClientPage';
import { UpdateUsersPerClientPage } from '../user/UsersPerClientPage/UpdateUsersPerClientPage';
import { ClientpocPage } from '../user/ClientpocPages/ClientpocPage';
import { AddClientPocPage } from '../user/ClientpocPages/AddClientpocPage';
import { EditClientPocPage } from '../user/ClientpocPages/EditClientpocPage';
import { ClientPocSetOrder } from '../user/ClientpocPages/SetOrderClientpocPage';
import { ContactSourcePage } from '../user/ContactSourcePage/ContactSource/ContactSourcePage';
import { AddContactSourcePage } from '../user/ContactSourcePage/AddContactSourcePage/AddContactSourcePage'
import { EditContactSourcePage } from '../user/ContactSourcePage/EditContactSourcePage/EditContactSourcePage'
import { ContactSourceSetOrder } from '../user/ContactSourcePage/ContactSourceSetOrderPage/ContactSourceSetOrder'
import { TagPage } from '../user/TagPage';
import { AddTagPage } from '../user/TagPage/AddTagPage';
import { BlacklistsPage } from '../user/BlacklistsPage';
import { EmailAccountsPage } from '../user/EmailAccountsPage';
import { AddEmailAccountsPage } from '../user/EmailAccountsPage/AddEmailAccountsPage';
import { EditEmailAccountsPage } from '../user/EmailAccountsPage/EditEmailAccountsPage';
import { IndexCampaignCategoriesPage } from '../user/CampaignCategories/IndexCampaignCategoriesPage';
import { AddCampaignCategoriesPage } from '../user/CampaignCategories/AddCampaignCategoriesPage';
import { AddCampaignInCategoriesPage } from '../user/CampaignCategories/AddCampaignInCategoriesPage';
import { EditCampaignCategoriesPage } from '../user/CampaignCategories/EditCampaignCategoriesPage';
import { IndexSendingSchedularPage } from '../user/SendingSchedular/IndexSendingSchedularPage';
import { AddSendingSchedularPage } from '../user/SendingSchedular/AddSendingSchedularPage';
import { EditSendingSchedularPage } from '../user/SendingSchedular/EditSendingSchedularPage';
import { EmailSignaturePage } from '../user/EmailSignaturePage';
import { CountryBlacklistPage } from '../user/CountryBlacklistPage';
import { IndexEngagementStatusPage } from '../user/EngagementStatus/IndexEngagementStatusPage';
import { AddEngagementStatusPage } from '../user/EngagementStatus/AddEngagementStatusPage';
import { EditEngagementStatusPage } from '../user/EngagementStatus/EditEngagementStatusPage';
import { EngagementStatusOrderPage } from '../user/EngagementStatus/EngagementStatusOrderPage';
import { AddCountryBlacklistPage } from '../user/CountryBlacklistPage/AddCountryBlacklistPage';
import { EditCountryBlacklistPage } from '../user/CountryBlacklistPage/EditCountryBlacklistPage';
// import { IndexCountryBlacklistPage } from '../user/CountryBlacklistPage/IndexCountryBlacklistPage';
import { MeetingCustomFieldPage } from '../user/MeetingCustomField/MeetingCustomFieldPage';
import { AddMeetingCustomFieldPage } from '../user/MeetingCustomField/AddMeetingCustomFieldPage';
import { EditMeetingCustomFieldPage } from '../user/MeetingCustomField/EditMeetingCustomFieldPage';
import { FirstViewApiKeyPage } from '../user/ApiKeyPages/FirstViewApiKeyPage';
import { ApikeyPage } from '../user/ApiKeyPages/ApikeyPage';
import { EditApiKeyPage } from '../user/ApiKeyPages/EditApiKeyPage';
import { ViewApiKeyPage } from '../user/ApiKeyPages/ViewApiKeyPage';
import { ClientsPage } from '../user/ClientsPage';
import { AddClientsPage } from '../user/ClientsPage/AddClientsPage';
import { EditClientsPage } from '../user/ClientsPage/EditClientsPage';
import { ClientAccountPage } from '../user/ClientAccountPage';
import { ClientContactPage } from '../user/ClientContactPage';
import { ClientCampaignsPage } from '../user/ClientCampaignsPage';
import { ClientResponsesPage } from '../user/ClientResponsesPage';
import { ClientLinkedInFollowUpPage } from '../user/ClientLinkedInFollowUpPage';
import { ClientEmailAccountsPage } from '../user/ClientEmailAccountsPage/ClientEmailAccountsPage';
import { CampaignsPage } from '../user/CampaignsPage';
import { AddCampaignsPage } from '../user/CampaignsPage/AddCampaignsPage';
import { EditCampaignsPage } from '../user/CampaignsPage/EditCampaignsPage/EditCampaignsPage';
import { ClientMeetingsPage } from '../user/ClientMeetingsPage';
import { ClientMeetingReminderErrorsPage } from '../user/ClientMeetingReminderErrorsPage'
import { AddClientMeetingsPage } from '../user/ClientMeetingsPage/AddClientMeetingsPage';
import { ClientReschedulingPage } from '../user/ClientReschedulingPage';
import { ClientPotentialMeetingsPage } from '../user/ClientPotentialMeetingsPage';
import { AddClientPotentialMeetingsPage } from '../user/ClientPotentialMeetingsPage/AddClientPotentialMeetingsPage/AddClientPotentialMeetingsPage';
import { ViewClientPotentialMeetingsPage } from '../user/ClientPotentialMeetingsPage/ViewClientPotentialMeetingsPage/ViewClientPotentialMeetingsPage';
import { EditClientPotentialMeetingsPage } from '../user/ClientPotentialMeetingsPage/EditClientPotentialMeetingsPage/EditClientPotentialMeetingsPage';
import { ClientKPIsPage } from '../user/ClientKPIsPage';
import { LeaderBoardPage } from '../user/LeaderBoardPage';
import { PendingInvitesPage } from '../user/PendingInvitesPage/PendingInvitesPage';
import { VariableReportingPage } from '../user/VariableReportingPage/VariableReportingPage';
import { UserPerClientsPage } from '../user/UserPerClientsPage/UserPerClientsPage';
import { ClientActionItemsPage } from '../user/ClientActionItemsPage/ClientActionItemsPage';
import { AddClientActionItemsPage } from '../user/ClientActionItemsPage/AddClientActionItemsPage';
import { EditClientActionItemsPage } from '../user/ClientActionItemsPage/EditClientActionItemsPage';
import { ClientDirectMailPage } from '../user/ClientDirectMailPage/ClientDirectMailPage';
import { AddClientDirectMailPage } from '../user/ClientDirectMailPage/AddClientDirectMailPage';
import { EditClientDirectMailPage } from '../user/ClientDirectMailPage/EditClientDirectMailPage';
import { ClientResearchPage } from '../user/ClientResearchPage/ClientResearchPage';
import { AddClientResearchPage } from '../user/ClientResearchPage/AddClientResearchPage/AddClientResearchPage';
import { EditClientResearchPage } from '../user/ClientResearchPage/EditClientResearchPage/EditClientResearchPage';
import { ClientLoginPage } from '../user/ClientLoginPage/ClientLoginPage';
import { EditClientLoginPage } from '../user/ClientLoginPage/EditClientLoginPage/EditClientLoginPage';
// import { ClientAPIKeysPage } from '../user/ClientAPIKeysPage/ClientAPIKeysPage';
import { ClientStatusesPage } from '../user/ClientStatusesPage/ClientStatus/ClientStatusesPage';
import { AddClientStatusPage } from '../user/ClientStatusesPage/AddClientStatusPage/AddClientStatusPage'
import { EditClientStatusPage } from '../user/ClientStatusesPage/EditClientStatusPage/EditClientStatusPage'
import { ClientStatusSetOrderPage } from '../user/ClientStatusesPage/ClientStatusSetOrderPage/ClientStatusSetOrderPage'
import { ResearchTeamsPage } from '../user/ResearchTeamsPage/ResearchTeamsPage';
import { AddResearchTeamsPage } from '../user/ResearchTeamsPage/AddResearchTeamsPage/AddResearchTeamsPage';
import { EditResearchTeamsPage } from '../user/ResearchTeamsPage/EditResearchTeamsPage/EditResearchTeamsPage';
import { ContactTagPage } from '../user/ContactTag/ContactTags';
import { AddContactTagPage } from '../user/ContactTag/AddContactTag';
import { EditContactTagPage } from '../user/ContactTag/EditContactTag';
import { CoreWebVitalsPage } from '../user/CoreWebVitalsPage/CoreWebVitalsPage';
import { BacklinksPage } from '../user/BacklinksPage/BacklinksPage';
import { KeywordsPage } from '../user/KeywordsPage/KeywordsPage';
import { BacklinksPipelinePage } from '../user/BacklinksPipeline/BacklinksPipeline';
import { AddBacklinksPipeline } from '../user/BacklinksPipeline/AddBacklinksPipeline/AddBacklinksPipeline.jsx';
import { EditBacklinksPipeline } from '../user/BacklinksPipeline/EditBacklinksPipeline/EditBacklinksPipeline.jsx';
import { ContentPipelinePage } from '../user/ContentPipeline/ContentPipeline';
import { AddContentPipeline } from '../user/ContentPipeline/AddContentPipeline/AddContentPipeline.jsx';
import { EditContentPipeline } from '../user/ContentPipeline/EditContentPipeline/EditContentPipeline.jsx';
import { SeoOverviewPage } from '../user/SeoOverview';
import { DefaultVariablesPage } from '../user/DefaultVariablesPage/DefaultVariablesPage';
import { GlobalBounceListPage } from '../user/GlobalBounceListPage/GlobalBounceListPage';
import { AddGlobalBounceListPage } from '../user/GlobalBounceListPage/AddGlobalBounceListPage';
import { EditGlobalBounceListPage } from '../user/GlobalBounceListPage/EditGlobalBounceListPage';
import { GenericEmailDomainPage } from '../user/GenericEmailDomainPage/GenericEmailDomainPage';
import { AddGenericEmailDomainPage } from '../user/GenericEmailDomainPage/AddGenericEmailDomainPage';
import { EditGenericEmailDomainPage } from '../user/GenericEmailDomainPage/EditGenericEmailDomainPage';
import { DefaultObjectionsPage } from '../user/DefaultObjectionsPage/DefaultObjectionsPage';
import { AddDefaultObjectionsPage } from '../user/DefaultObjectionsPage/AddDefaultObjectionsPage';
import { EditDefaultObjectionsPage } from '../user/DefaultObjectionsPage/EditDefaultObjectionsPage';
import { DefaultTrackingDomainPage } from '../user/DefaultTrackingDomainPage/DefaultTrackingDomainPage';
import { AddDefaultTrackingDomainPage } from '../user/DefaultTrackingDomainPage/AddDefaultTrackingDomainPage';
import { EditDefaultTrackingDomainPage } from '../user/DefaultTrackingDomainPage/EditDefaultTrackingDomainPage';
import { DeliverabilityTrackingPage } from '../user/DeliverabilityTrackingPage';
import { SalesRepPage } from '../user/SalesRepPage/SalesRepPage';
import { AddSalesRepPage } from '../user/SalesRepPage/AddSalesRepPage';
import { EditSalesRepPage } from '../user/SalesRepPage/EditSalesRepPage';
import { TriggerPhrasesPage } from '../user/TriggerPhrasesPage/TriggerPhrasesPage';
import { SmartVariablePage } from '../user/SmartVariablePage/SmartVariablePage';
import { GlobalBlacklistPage } from '../user/GlobalBlacklistPage/GlobalBlacklistPage';
import { AddGlobalBlacklistPage } from '../user/GlobalBlacklistPage/AddGlobalBlacklistPage';
import { EditGlobalBlacklistPage } from '../user/GlobalBlacklistPage/EditGlobalBlacklistPage';
import { AddGlobalContactBlacklistPage } from '../user/GlobalBlacklistContactPage/AddGlobalContactBlacklistPage';
import { EditGlobalContactBlacklistPage } from '../user/GlobalBlacklistContactPage/EditGlobalContactBlacklistPage';
import { DefaultCountryBlacklistPage } from '../user/DefaultCountryBlacklistPage/DefaultCountryBlacklistPage';
import { AddDefaultCountryBlacklistPage } from '../user/DefaultCountryBlacklistPage/AddDefaultCountryBlacklistPage';
import { EditDefaultCountryBlacklistPage } from '../user/DefaultCountryBlacklistPage/EditDefaultCountryBlacklistPage';
import { ColdCallerPhoneBurnerLoginPage } from '../user/ColdCallerPhoneBurnerLoginPage/ColdCallerPhoneBurnerLoginPage';
import { DefaultSlackNotificationPage } from '../user/DefaultSlackNotificationPage/DefaultSlackNotificationPage';
import { MeetingSlackNotificationPage } from '../user/MeetingSlackNotificationPage/MeetingSlackNotificationPage';
import { CustomMeetingPage } from '../user/CustomMeetingPage/CustomMeetingPage';
import { AddCustomMeetingPage } from '../user/CustomMeetingPage/AddCustomMeetingPage/AddCustomMeetingPage';
import { EditCustomMeetingPage } from '../user/CustomMeetingPage/EditCustomMeetingPage/EditCustomMeetingPage';
import { EditClientPage } from '../user/EditClientPage/EditClientPage';
import { MeetingSourcesPage } from '../user/MeetingSources/MeetingSourcesrPage/MeetingSourcesPage'
import { AddMeetingSourcesPage } from '../user/MeetingSources/AddMeetingSourcesPage/AddMeetingSourcesPage'
import { EditMeetingSourcesPage } from '../user/MeetingSources/EditMeetingSourcesPage/EditMeetingSourcesPage'
import { MeetingReminderPage } from '../user/MeetingReminderPage/MeetingReminderPage'
import { MeetingReminderDefaultPage } from '../user/MeetingReminderDefaultPage/MeetingReminderDefaultPage'
import { PotentialMeetingStatusPage } from '../user/PotentialMeetingStatusPage/PotentialMeetingStatusPage/PotentialMeetingStatusPage.jsx'
import { AddPotentialMeetingStatusPage } from '../user/PotentialMeetingStatusPage/AddPotentialMeetingStatusPage/AddPotentialMeetingStatusPage'
import { EditPotentialMeetingStatusPage } from '../user/PotentialMeetingStatusPage/EditPotentialMeetingStatusPage/EditPotentialMeetingStatusPage'
import { CoReasonUnqualifiedPage } from '../user/ContactReasonUnqualified/ContactReasonUnqualifiedPage/CoReasonUnqualifiedPage'
import { AddContactReasonUnqualifiedPage } from '../user/ContactReasonUnqualified/AddContactReasonUnqualifiedPage/AddContactReasonUnqualifiedPage'
import { EditContactReasonUnqualifiedPage } from '../user/ContactReasonUnqualified/EditContactReasonUnqualifiedPage/EditContactReasonUnqualifiedPage'
import { ContactReasonSetOrderPage } from '../user/ContactReasonUnqualified/ContactReasonSetOrderPage/ContactReasonSetOrderPage'
import { PotentialMeetingStatusOrderPage } from '../user/PotentialMeetingStatusPage/PotentialMeetingStatusOrderPage/PotentialMeetingStatusOrderPage'
import { AccountReasonUnqualifiedPage } from '../user/AccountReasonUnqualified/AccountReasonUnqualifiedPage/AccountReasonUnqualifiedPage'
import { AddAccountReasonUnqualified } from '../user/AccountReasonUnqualified/AddAccountReasonUnqualified/AddAccountReasonUnqualified'
import { AccountReasonSetOrderPage } from '../user/AccountReasonUnqualified/AccountReasonSetOrder/AccountReasonSetOrderPage'
import { EditAccountReasonUnqualified } from '../user/AccountReasonUnqualified/EditAccountReasonUnqualified/EditAccountReasonUnqualified'
import { Accountcustomfield } from '../user/AccountCustomfield/Accountcustomfield/Accountcustomfield'
import { AddAccountcustomfield } from '../user/AccountCustomfield/AddAccountCustomField/AddAccountcustomfield'
import { EditAccountCustomfield } from '../user/AccountCustomfield/EditAccountCustomField/EditAccountCustomfield'
import { ContactCustomfieldPage } from '../user/ContactCustomfield/ContactCustomfieldPage/ContactCustomfieldPage'
import { AddContactCustomPage } from '../user/ContactCustomfield/AddContactCustomfieldPage/AddContactCustomPage'
import { EditContactCustomPage } from '../user/ContactCustomfield/EditContactCustomfieldPage/EditContactCustomPage'
import { ContactCustomColumnPage } from '../user/ContactCustomColumnPage/ContactCustomColumnPage'
import { BookedMeetingCustomColumnPage } from '../user/BookedMeetingCustomColumnPage/BookedMeetingCustomColumnPage'
import { AddColdCallerTeam } from '../user/ColdCallerTeam/AddColdCallerTeam/AddColdCallerTeam'
import { EditColdCallerTeam } from '../user/ColdCallerTeam/EditColdCallerTeam/EditColdCallerTeam'
import { HiveAiPage } from '../user/HiveAiPage/HiveAiPage'
import { EmodProfilePage } from '../user/EmodProfilePage/EmodProfilePage'
import { AddEmodProfilePage } from '../user/EmodProfilePage/AddEmodProfilePage/AddEmodProfilePage'
import { EditEmodProfilePage } from '../user/EmodProfilePage/EditEmodProfilePage/EditEmodProfilePage'
import  {TwilioPage}  from '../user/TwilioPage';


export const userRoutes = [
    { path: '/home', component: HomePage },
    { path: '/editmyprofile', component: EditMyProfilePage },
    { path: '/kpis', component: KPIsPage },
    { path: '/accounts', component: AccountsPage },
    { path: '/addaccount', component: AddAccountPage },
    { path: '/viewaccount', component: ViewAccountPage },
    { path: '/editaccount', component: EditAccountsPage },
    { path: '/contacts', component: ContactsPage },
    { path: '/addcontacts', component: AddContactPage },
    { path: '/editcontacts', component: EditContactPage },
    { path: '/viewcontacts', component: ViewContactPage },
    { path: '/potentialmeeting', component: PotentialMeetingsPage },
    { path: '/bookedmeetings', component: BookedMeetingsPage },
    { path: '/addbookedmeeting', component: AddBookedMeetingPage },
    { path: '/viewbookedmeeting', component: ViewBookedMeetingPage },
    { path: '/editbookedmeeting', component: EditBookedMeetingPage },
    { path: '/addpotentialmeeting', component: AddPotentialMeetingsPage },
    { path: '/editpotentialmeeting', component: EditPotentialMeetingsPage },
    { path: '/viewpotentialmeeting', component: ViewPotentialMeetingsPage },
    { path: '/opportunities', component: OpportunitiesPage },
    { path: '/addoppotunities', component: AddOpportunitiesPage },
    { path: '/viewoppotunities', component: ViewOpportunitiesPage },
    { path: '/editoppotunities', component: EditOpportunitiesPage },
    { path: '/linkedinfollowup', component: LinkedInFollowUpPage },
    { path: '/reschedules', component: ReschedulingPage },
    { path: '/list', component: Listspage },
    { path: '/addlist', component: AddListspage },
    { path: '/editlist', component: EditListspage },
    { path: '/coldcallingContact', component: ColdCallingContactPage },
    { path: '/addcoldcallingContact', component: AddColdCallingContactPage },
    { path: '/callrecordings', component: CallRecordingsPage },
    { path: '/callhistory', component: CallHistoryPage },
    { path: '/coldcallingreporting', component: ColdCallingReportingPage },
    { path: '/addsalesHivecontact', component: AddSalesHiveContactPage },
    { path: '/accountcustomcolumn', component: AccountCustomColumnPage },
    { path: '/responder', component: ResponderPage },
    { path: '/editresponder', component: EditResponderPage },
    { path: '/addresponder', component: AddResponderPage },
    { path: '/updateemailresponder', component: UpdateEmailResponderPage },
    { path: '/coldcallers', component: ColdCallersPage },
    { path: '/editcoldcallers', component: EditColdCallersPage },
    { path: '/addcoldcallers', component: AddColdCallersPage },
    { path: '/coldcallerteams', component: ColdCallerTeamsPage },
    { path: '/addcoldcallerteams', component: AddColdCallerTeam },
    { path: '/editcoldcallerteams', component: EditColdCallerTeam },
    { path: '/updateemailcoldcallers', component: UpdateEmailColdCallersPage },
    { path: '/salesstrategists', component: SalesStrategistsPage },
    { path: '/editsalesstrategists', component: EditSalesStrategistsPage },
    { path: '/addsalesstrategists', component: AddSalesStrategistsPage },
    { path: '/updateemailstrategists', component: UpdateEmailSalesStrategistsPage },
    { path: '/adminuser', component: AdminUserPage },
    { path: '/addadmin', component: AddAdminUserPage },
    { path: '/editadmin', component: EditAdminUserPage },
    { path: '/updateemailadmin', component: UpdateEmailAdminUserPage },
    { path: '/seo', component: SeoPage },
    { path: '/editseo', component: EditSeoPage },
    { path: '/addseo', component: AddSeoPage },
    { path: '/updateemailseo', component: UpdateEmailSeoPage },
    { path: '/resetpassword', component: ResetPasswordPage },
    { path: '/accountcategory', component: AccountCategoryPage },
    { path: '/addaccountspage', component: AddAccountsPage },
    { path: '/addaccountcategory', component: AddAccountCategoryPage },
    { path: '/editaccountcategory', component: EditAccountCategoryPage },
    { path: '/accountclientstatus', component: AccountClientStatusPage },
    { path: '/addaccountclientstatus', component: AddAccountClientStatusPage },
    { path: '/editaccountclientstatus', component: EditAccountClientStatusPage },
    { path: '/meetinggoal', component: MeetingGoalPage },
    { path: '/addmeetinggoal', component: AddMeetingGoalPage },
    { path: '/editmeetinggoal', component: EditMeetingGoalPage },
    { path: '/variable', component: VariablePage },
    { path: '/customvariable', component: CustomVariablePage },
    { path: '/addcustomvariable', component: AddCustomVariablePage },
    { path: '/editcustomvariable', component: EditCustomVariablePage },
    { path: '/calltoaction', component: CallToActionPage },
    { path: '/emailhistory', component: EmailHistoryPage },
    { path: '/emailresponses', component: EmailResponcesPage },
    { path: '/meetingstatuses', component: MeetingStatusesPage },
    { path: '/addmeetingstatuses', component: AddMeetingStatusesPage },
    { path: '/editmeetingstatuses', component: EditMeetingStatusesPage },
    { path: '/meetingstatusesorder', component: MeetingStatusesOrderPage },
    { path: '/meetingowner', component: MeetingOwnerPage },
    { path: '/addmeetingowner', component: AddMeetingOwnerPage },
    { path: '/editmeetingowner', component: EditMeetingOwnerPage },
    { path: '/actionitems', component: ActionItemsPage },
    { path: '/addactionitems', component: AddActionItemsPage },
    { path: '/editactionitems', component: EditActionItemsPage },
    { path: '/documents', component: DocumentsPage },
    { path: '/adddocuments', component: AddDocumentsPage },
    { path: '/editdocuments', component: EditDocumentsPage },
    { path: '/directmail', component: DirectMailPage },
    { path: '/adddirectmail', component: AddDirectMailPage },
    { path: '/editdirectmail', component: EditDirectMailPage },
    { path: '/research', component: ResearchPage },
    { path: '/addresearch', component: AddResearchPage },
    { path: '/editresearch', component: EditResearchPage },
    { path: '/insights', component: InsightsPage },
    { path: '/campaignsummary', component: CampaignSummaryPage },
    { path: '/automator', component: AutomatorPage },
    { path: '/despammer', component: DespammerPage },
    { path: '/emailvalidation', component: EmailValidationPage },
    { path: '/deliverabilitytesting', component: DeliverabilityTestingPage },
    { path: '/adddeliverabilitytesting', component: AddDeliverabilityTestingPage },
    { path: '/editdeliverabilitytesting', component: EditDeliverabilityTestingPage },
    { path: '/deliverabilitytracking', component: DeliverabilityTrackingPage },
    { path: '/openratereporting', component: OpenRateReportingPage },
    { path: '/clicktrackreporting', component: ClickTrackReportingPage },
    { path: '/globalbounce', component: GlobalBouncePage },
    { path: '/emailwarmup', component: EmailWarmUpPage },
    { path: '/emailwarmuphistory', component: EmailWarmUpHistoryPage },
    { path: '/editemailwarmup', component: EditEmailWarmUpPage },
    { path: '/ContactBulkImportRequest', component: ContactBulkImportRequestPage },
    { path: '/ContactBulkExportRequest', component: ContactBulkExportRequestPage },
    { path: '/objections', component: ObjectionsPage },
    { path: '/addobjections', component: AddObjectionsPage },
    { path: '/editobjections', component: EditObjectionsPage },
    { path: '/usersperclient', component: UsersPerClientPage },
    { path: '/addclientusers', component: AddUsersPerClientPage },
    { path: '/editusersperclient', component: EditUsersPerClientPage },
    { path: '/updateusersperclient', component: UpdateUsersPerClientPage },
    { path: '/clientpoc', component: ClientpocPage },
    { path: '/addclientpoc', component: AddClientPocPage },
    { path: '/editclientpoc', component: EditClientPocPage },
    { path: '/setorderclientpoc', component: ClientPocSetOrder },
    { path: '/contactsource', component: ContactSourcePage },
    { path: '/addcontactsource', component: AddContactSourcePage },
    { path: '/editcontactsource', component: EditContactSourcePage },
    { path: '/addclientstatus', component: AddClientStatusPage },
    { path: '/editclientstatus', component: EditClientStatusPage },
    { path: '/clients', component: ClientsPage },
    { path: '/editclient', component: EditClientPage },
    { path: '/contactsourcesetOrder', component: ContactSourceSetOrder },
    { path: '/tag', component: TagPage },
    { path: '/addtag', component: AddTagPage },
    { path: '/blacklists', component: BlacklistsPage },
    { path: '/emailaccounts', component: EmailAccountsPage },
    { path: '/addemailaccounts', component: AddEmailAccountsPage },
    { path: '/editemailaccounts', component: EditEmailAccountsPage },
    { path: '/campaigncategories', component: IndexCampaignCategoriesPage },
    { path: '/addcampaigncategories', component: AddCampaignCategoriesPage },
    { path: '/AddCampaigninCategories', component: AddCampaignInCategoriesPage },
    { path: '/editcampaigncategories', component: EditCampaignCategoriesPage },
    { path: '/sendingschedular', component: IndexSendingSchedularPage },
    { path: '/addsendingschedular', component: AddSendingSchedularPage },
    { path: '/editsendingschedular', component: EditSendingSchedularPage },
    { path: '/emailsignature', component: EmailSignaturePage },
    { path: '/countryblacklist', component: CountryBlacklistPage },
    { path: '/addcountryblacklist', component: AddCountryBlacklistPage },
    { path: '/editcountryblacklist', component: EditCountryBlacklistPage },
    { path: '/engagementstatus', component: IndexEngagementStatusPage },
    { path: '/addengagementstatus', component: AddEngagementStatusPage },
    { path: '/editengagementstatus', component: EditEngagementStatusPage },
    { path: '/engagementstatusorder', component: EngagementStatusOrderPage },
    { path: '/meetingcustomfield', component: MeetingCustomFieldPage },
    { path: '/addmeetingcustomfield', component: AddMeetingCustomFieldPage },
    { path: '/editmeetingcustomfield', component: EditMeetingCustomFieldPage },
    { path: '/viewapiKeys', component: FirstViewApiKeyPage },
    { path: '/apiKey', component: ApikeyPage },
    { path: '/editapiKey', component: EditApiKeyPage },
    { path: '/viewapiKey', component: ViewApiKeyPage },
    { path: '/clients', component: ClientsPage },
    { path: '/addclients', component: AddClientsPage },
    { path: '/editclients', component: EditClientsPage },
    { path: '/clientaccount', component: ClientAccountPage },
    { path: '/clientcontact', component: ClientContactPage },
    { path: '/clientcampaigns', component: ClientCampaignsPage },
    { path: '/clientresponses', component: ClientResponsesPage },
    { path: '/clientlinkedinfollowUp', component: ClientLinkedInFollowUpPage },
    { path: '/clientemailaccounts', component: ClientEmailAccountsPage },
    { path: '/campaigns', component: CampaignsPage },
    { path: '/addcampaigns', component: AddCampaignsPage },
    { path: '/editcampaigns', component: EditCampaignsPage },
    { path: '/clientmeetingremindererros', component: ClientMeetingReminderErrorsPage },
    { path: '/clientmeetings', component: ClientMeetingsPage },
    { path: '/addclientmeetings', component: AddClientMeetingsPage },
    { path: '/clientrescheduling', component: ClientReschedulingPage },
    { path: '/clientpotentialmeetings', component: ClientPotentialMeetingsPage },
    { path: '/addclientpotentialmeetings', component: AddClientPotentialMeetingsPage },
    { path: '/viewclientpotentialmeetings', component: ViewClientPotentialMeetingsPage },
    { path: '/editclientpotentialmeetings', component: EditClientPotentialMeetingsPage },
    { path: '/clientkpis', component: ClientKPIsPage },
    { path: '/leaderboard', component: LeaderBoardPage },
    { path: '/pendinginvites', component: PendingInvitesPage },
    { path: '/variablereporting', component: VariableReportingPage },
    { path: '/userperclients', component: UserPerClientsPage },
    { path: '/clientactionitems', component: ClientActionItemsPage },
    { path: '/addclientactionitems', component: AddClientActionItemsPage },
    { path: '/editclientactionitems', component: EditClientActionItemsPage },
    { path: '/clientdirectmail', component: ClientDirectMailPage },
    { path: '/addclientdirectmail', component: AddClientDirectMailPage },
    { path: '/editclientdirectmail', component: EditClientDirectMailPage },
    { path: '/clientresearch', component: ClientResearchPage },
    { path: '/addclientresearch', component: AddClientResearchPage },
    { path: '/editclientresearch', component: EditClientResearchPage },
    { path: '/clientlogin', component: ClientLoginPage },
    { path: '/editclientlogin', component: EditClientLoginPage },
    { path: '/clientapikeys', component: ApikeyPage },
    { path: '/clientstatuses', component: ClientStatusesPage },
    { path: '/addclientstatuses', component: AddClientStatusPage },
    { path: '/editclientstatuses', component: EditClientStatusPage },
    { path: '/setorderclientstatuses', component: ClientStatusSetOrderPage },
    { path: '/ResearchTeam', component: ResearchTeamsPage },
    { path: '/addResearchTeam', component: AddResearchTeamsPage },
    { path: '/editResearchTeam', component: EditResearchTeamsPage },
    { path: '/defaultvariables', component: DefaultVariablesPage },
    { path: '/globalbouncelist', component: GlobalBounceListPage },
    { path: '/addglobalbouncelist', component: AddGlobalBounceListPage },
    { path: '/editglobalbouncelist', component: EditGlobalBounceListPage },
    { path: '/genericemaildomain', component: GenericEmailDomainPage },
    { path: '/addgenericemaildomain', component: AddGenericEmailDomainPage },
    { path: '/editgenericemaildomain', component: EditGenericEmailDomainPage },
    { path: '/defaultobjections', component: DefaultObjectionsPage },
    { path: '/adddefaultobjections', component: AddDefaultObjectionsPage },
    { path: '/editdefaultobjections', component: EditDefaultObjectionsPage },
    { path: '/defaulttrackingdomain', component: DefaultTrackingDomainPage },
    { path: '/adddefaulttrackingdomain', component: AddDefaultTrackingDomainPage },
    { path: '/editdefaulttrackingdomain', component: EditDefaultTrackingDomainPage },
    { path: '/salesrep', component: SalesRepPage },
    { path: '/addsalesrep', component: AddSalesRepPage },
    { path: '/editsalesrep', component: EditSalesRepPage },
    { path: '/triggerphrases', component: TriggerPhrasesPage },
    { path: '/smartvariable', component: SmartVariablePage },
    { path: '/globalblacklist', component: GlobalBlacklistPage },
    { path: '/addglobalblacklist', component: AddGlobalBlacklistPage },
    { path: '/editglobalblacklist', component: EditGlobalBlacklistPage },
    { path: '/addglobalblacklistcontact', component: AddGlobalContactBlacklistPage },
    { path: '/editglobalblacklistcontact', component: EditGlobalContactBlacklistPage },
    { path: '/defaultcountryblacklist', component: DefaultCountryBlacklistPage },
    { path: '/adddefaultcountryblacklist', component: AddDefaultCountryBlacklistPage },
    { path: '/editdefaultcountryblacklist', component: EditDefaultCountryBlacklistPage },
    { path: '/coldcallerphoneburnerlogin', component: ColdCallerPhoneBurnerLoginPage },
    { path: '/defaultslacknotification', component: DefaultSlackNotificationPage },
    { path: '/meetingslacknotification', component: MeetingSlackNotificationPage },
    { path: '/custommeeting', component: CustomMeetingPage },
    { path: '/addcustommeeting', component: AddCustomMeetingPage },
    { path: '/editcustommeeting', component: EditCustomMeetingPage },
    { path: '/editclient', component: EditClientPage },
    { path: '/contacttag', component: ContactTagPage },
    { path: '/addcontacttag', component: AddContactTagPage },
    { path: '/editcontacttag', component: EditContactTagPage },
    { path: '/corewebvitals', component: CoreWebVitalsPage },
    { path: '/backlinks', component: BacklinksPage },
    { path: '/keywords', component: KeywordsPage },
    { path: '/backlinkspipeline', component: BacklinksPipelinePage },
    { path: '/addbacklinkspipeline', component: AddBacklinksPipeline },
    { path: '/editbacklinkspipeline', component: EditBacklinksPipeline },
    { path: '/contentpipeline', component: ContentPipelinePage },
    { path: '/addcontentpipeline', component: AddContentPipeline },
    { path: '/editcontentpipeline', component: EditContentPipeline },
    { path: '/seooverview', component: SeoOverviewPage },
    { path: '/meetingsource', component: MeetingSourcesPage },
    { path: '/addmeetingsource', component: AddMeetingSourcesPage },
    { path: '/editmeetingsource', component: EditMeetingSourcesPage },
    { path: '/meetingreminder', component: MeetingReminderPage },
    { path: '/clientmeetingreminderdefault', component: MeetingReminderDefaultPage },
    { path: '/potentialmeetingstatus', component: PotentialMeetingStatusPage },
    { path: '/addpotentialmeetingstatus', component: AddPotentialMeetingStatusPage },
    { path: '/editpotentialmeetingstatus', component: EditPotentialMeetingStatusPage },
    { path: '/setorder', component: PotentialMeetingStatusOrderPage },
    { path: '/accountreasonunqualified', component: AccountReasonUnqualifiedPage },
    { path: '/addaccountreasonunqualified', component: AddAccountReasonUnqualified },
    { path: '/setorderaccount', component: AccountReasonSetOrderPage },
    { path: '/editaccountresonunqalified', component: EditAccountReasonUnqualified },
    { path: '/HiveAi', component: HiveAiPage },
    { path: '/emodprofile', component: EmodProfilePage },
    { path: '/addemodprofile', component: AddEmodProfilePage },
    { path: '/editemodprofile', component: EditEmodProfilePage },
    { path: '/bookedmeetingcustomcolumn', component: BookedMeetingCustomColumnPage },
    { path: '/contactreasonunqualified', component: CoReasonUnqualifiedPage },
    { path: '/addcontactreasonunqualified', component: AddContactReasonUnqualifiedPage },
    { path: '/editcontactreasonunqualified', component: EditContactReasonUnqualifiedPage },
    { path: '/setcontactreasonunqualified', component: ContactReasonSetOrderPage },
    { path: '/customaccountfield', component: Accountcustomfield },
    { path: '/addcustoaccount', component: AddAccountcustomfield },
    { path: '/editcustomaccount', component: EditAccountCustomfield },
    { path: '/contactcustomfield', component: ContactCustomfieldPage },
    { path: '/addcontactcustomfield', component: AddContactCustomPage },
    { path: '/editcontactcustomfield', component: EditContactCustomPage },
    { path: '/contactcustomcolumn', component: ContactCustomColumnPage },
    { path: '/accountclientstatusorder', component: AccountClientStatusOrderPage },
    { path: '/customvariableset', component: CustomVariableSetPage },
    { path: '/twilio', component: TwilioPage },
];
