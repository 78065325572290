import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import CampaignsTabing from '../../_components/clients/campaigns/campaignsTabing';
import $ from "jquery";
import { getFirstDayOfQuarter } from "../../_helpers/Utility";
const moment = require('moment');
import { GetUserDetails } from "../../_helpers/Utility";
import Axios from 'axios';
import { CommonConstants } from "../../_constants/common.constants";
import loadingthreedotsicon from "../../../src/images/three-dots.svg";


class CCampaignsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      FromDate: "",
      ToDate: "",
      IsAnalyze: false,
      TotalUncontacted: 0,
      TotalActiveContact: 0,
      TotalCompletedContacts: 0,
      TotalEmailedContacts: 0,
      TotalRemovedContacts: 0,
      TotalActiveCampaigns: 0,
      IsLoadings: false,
      IsLoadings1: false
    };
    this.SaveBtn = this.SaveBtn.bind(this);
    this.daterefect = React.createRef()
    this.daterefect2 = React.createRef()
  }


  componentDidMount() {
    var fromdate = getFirstDayOfQuarter()

    const previousMonthDate = new Date(fromdate);

    // Convert to UTC date components
    const utcYear = previousMonthDate.getUTCFullYear();
    const utcMonth = String(previousMonthDate.getUTCMonth() + 1).padStart(2, '0');
    const utcDay = String(previousMonthDate.getUTCDate()).padStart(2, '0');

    // Format the date
    const formattedDate = `${utcMonth}/${utcDay}/${utcYear}`;



    // Set the value of the "from-date" input field
    document.getElementById("from-date").value = formattedDate;
    const $datepickerElement = $(this.daterefect.current);
    $datepickerElement.datetimepicker({
      format: 'mm/dd/yyyy',
      autoclose: true,
      container: '#app',
      todayBtn: true,
      todayHighlight: true,
      minView: '2',
    });
    document.getElementById("to-date").value = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
    const $datepickerElement2 = $(this.daterefect2.current);
    $datepickerElement2.datetimepicker({
      format: 'mm/dd/yyyy',
      autoclose: true,
      container: '#app',
      todayBtn: true,
      todayHighlight: true,
      minView: '2',
    });

    var FromDate = moment.utc(document.getElementById("from-date").value).format("YYYY-MM-DD");
    var ToDate = moment.utc(document.getElementById("to-date").value).format("YYYY-MM-DD");
    this.setState({ FromDate: FromDate, ToDate: ToDate, IsAnalyze: true });

    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.CampaignKeyMatric(Details.ClientID)
      this.CampaignContactEmailKeyMatric(Details.ClientID, FromDate, ToDate)
    }


  }
  CampaignKeyMatric(ClientID) {
    this.setState({ IsLoadings: true });
    var InputParameter = {
      ClientID: ClientID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/Campaign/CampaignKeymatric",
      method: "POST",
      data: InputParameter,
    }).then((Res) => {
      if (Res.data.StatusMessage == "SUCCESS") {
        if (Res.data.PageData.length > 0) {
          var item = Res.data.PageData[0]
          this.setState({ TotalUncontacted: item.TotalUncontacted });
          this.setState({ TotalActiveContact: item.TotalActiveContact });
          this.setState({ TotalRemovedContacts: item.TotalRemovedContacts });
          this.setState({ TotalActiveCampaigns: item.TotalActiveCampaigns });
          this.setState({ IsLoadings: false });

        } else {
          this.setState({ TotalUncontacted: 0 });
          this.setState({ TotalActiveContact: 0 });
          this.setState({ TotalRemovedContacts: 0 });
          this.setState({ TotalActiveCampaigns: 0 });
          this.setState({ IsLoadings: false });
        }
      } else {
        this.setState({ TotalUncontacted: 0 });
        this.setState({ TotalActiveContact: 0 });
        this.setState({ TotalRemovedContacts: 0 });
        this.setState({ TotalActiveCampaigns: 0 });
        this.setState({ IsLoadings: false });
      }

    });
  }
  CampaignContactEmailKeyMatric(ClientID, FromDate, ToDate) {
    this.setState({ IsLoadings1: true });
    var InputParameter = {
      ClientID: ClientID,
      FromDate: FromDate,
      ToDate: ToDate
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/Campaign/CampaignKeymatricWithDateRange",
      method: "POST",
      data: InputParameter,
    }).then((Res) => {
      if (Res.data.StatusMessage == "SUCCESS") {
        if (Res.data.PageData.length > 0) {
          var item = Res.data.PageData[0]
          this.setState({ TotalCompletedContacts: item.CompletedContacts });
          this.setState({ TotalEmailedContacts: item.ContactsEmailed });
          this.setState({ IsLoadings1: false });
        } else {
          this.setState({ TotalCompletedContacts: 0 });
          this.setState({ TotalEmailedContacts: 0 });
          this.setState({ IsLoadings1: false });
        }
      } else {
        this.setState({ TotalCompletedContacts: 0 });
        this.setState({ TotalEmailedContacts: 0 });
        this.setState({ IsLoadings1: false });
      }

    });
  }

  SaveBtn() {
    var FromDate = moment.utc(document.getElementById("from-date").value).format("YYYY-MM-DD");
    var ToDate = moment.utc(document.getElementById("to-date").value).format("YYYY-MM-DD");

    this.setState({ FromDate: FromDate, ToDate: ToDate, IsAnalyze: true });
  }
  ChartDataByClientID(CID, UID, URole) {
    SetIsloadingEss(true);
    var Str_In = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
    }
    const rows2 = Axios({
      url: CommonConstants.MOL_APIURL + "/campaign/CampaignSendChartData",
      method: "POST",
      data: Str_In,
    });
    if (rows2.data.StatusMessage === "SUCCESS") {
      SetChartDate(rows2.data.Data.Date);
      SetChartCountData(rows2.data.Data.Count);
      SetIsloadingEss(false);
    }
  }


  render() {
    return (
      <>
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row">
              <div className="col-md-6">
                <h4 className="headertitlenop float-left py-4 pl-0">Campaigns</h4>
              </div>
              <div className="col padright">
                <div className="btn-group datepicker-hed">
                  <div className="col-lg-6 timedatepikker date-input">
                    <input className="form-control m-input"
                      type='text'
                      id='from-date'
                      ref={this.daterefect}
                    />

                  </div>
                  <div className="col-lg-6 timedatepikker date-input">
                    <input className="form-control m-input"
                      type='text'
                      id='to-date'
                      ref={this.daterefect2}
                    />
                  </div>
                  <button
                    onClick={this.SaveBtn}
                    id="aCompanyState"
                    class="btn btn-primary btnColor m-btn brrb72"
                    autocomplete="off"
                  >
                    <i class="la flaticon-diagram"></i>
                    <span> Analyze</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <div className="bg-white boxcardcounter">
                  <div className="line-title">
                    {
                      this.state.IsLoadings == true ?
                        <div>
                          <img src={loadingthreedotsicon} width={40} />
                        </div> :

                        <span className="clr-sky box-main-title">
                          {this.state.TotalActiveContact}
                        </span>
                    }
                  </div>
                  <div className="box-inner-title">
                    <string className="clr-sky">Active Contacts</string>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="bg-white boxcardcounter">
                  <div className="line-title">
                    {
                      this.state.IsLoadings == true ?
                        <div>
                          <img src={loadingthreedotsicon} width={40} />
                        </div> :

                        <span className="clr-sky box-main-title">
                          {this.state.TotalUncontacted}
                        </span>
                    }
                  </div>
                  <div className="box-inner-title">
                    <string className="clr-sky">Uncontacted Contacts</string>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="bg-white boxcardcounter">
                  <div className="line-title">
                    {
                      this.state.IsLoadings1 == true ?
                        <div>
                          <img src={loadingthreedotsicon} width={40} />
                        </div> :

                        <span className="clr-sky box-main-title">
                          {this.state.TotalCompletedContacts}
                        </span>
                    }
                  </div>
                  <div className="box-inner-title">
                    <string className="clr-sky">Completed Contacts</string>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="bg-white boxcardcounter">
                  <div className="line-title">
                    {
                      this.state.IsLoadings1 == true ?
                        <div>
                          <img src={loadingthreedotsicon} width={40} />
                        </div> :

                        <span className="clr-sky box-main-title">
                          {this.state.TotalEmailedContacts}
                        </span>
                    }
                  </div>
                  <div className="box-inner-title">
                    <string className="clr-sky">Contacts Emailed</string>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="bg-white boxcardcounter">
                  <div className="line-title">
                    {
                      this.state.IsLoadings == true ?
                        <div>
                          <img src={loadingthreedotsicon} width={40} />
                        </div> :

                        <span className="clr-sky box-main-title">
                          {this.state.TotalRemovedContacts}
                        </span>
                    }
                  </div>
                  <div className="box-inner-title">
                    <string className="clr-sky">Contacts Removed</string>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="bg-white boxcardcounter">
                  <div className="line-title">
                    {
                      this.state.IsLoadings == true ?
                        <div>
                          <img src={loadingthreedotsicon} width={40} />
                        </div> :

                        <span className="clr-sky box-main-title">
                          {this.state.TotalActiveCampaigns}
                        </span>
                    }
                  </div>
                  <div className="box-inner-title">
                    <string className="clr-sky">Active Campaigns</string>
                  </div>
                </div>
              </div>

            </div>
            <div className="row">
              <div className="col">
                <CampaignsTabing FromDates={this.state.FromDate} ToDates={this.state.ToDate} IsAnalyze={this.state.IsAnalyze} />
              </div>
            </div>

          </div>
        </div>


        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout
};

const connectedCCampaignsPage = connect(mapState, actionCreators)(CCampaignsPage);
export { connectedCCampaignsPage as CCampaignsPage };