import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import Axios from "axios";
import CryptoJS from "crypto-js";
import { history } from "../../../_helpers";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class EditClientLoginPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      fields: {},
      errors: {},
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      Message: "",
      OldEmail: "",
    };
    this.ClientLoginDetails();
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
    this.ValidatePassword = this.ValidatePassword.bind(this);
  }
  componentDidMount() {
    document.title = "Edit Client Login | SalesHive";
  }

  ClientLoginDetails() {
    const InputPamaters = {
      UserID: this.props.location.state,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/client_login/ClientLoginGetByID",
      method: "POST",
      data: InputPamaters,
    }).then((res) => {
      this.setState({ OldEmail: res.data.Data[0].Username });
      document.getElementById("Username").value = res.data.Data[0]?.Username;
      document.getElementById("Password").value =           
        CryptoJS.AES.decrypt(
          res.data.Data[0]?.Password.split(" ").join("+"),
          "my-secret-key@123"
        ).toString(CryptoJS.enc.Utf8)
      
    });
  }

  // Check email exists or not
  async CheckEmailExits() {
    let Username = document.getElementById("Username").value.trim();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (Username != "") {
      if (emailRegex.test(Username)) {
        if (Username == this.state.OldEmail) {
          this.setState({ Message: "" });
          return false;
        } else {
          let InputParameters = {
            Email: Username,
          };
          let ResData = await Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/client_login/ClientEmailValidation",
            method: "POST",
            data: InputParameters,
          });

          if (ResData.data.StatusMessage == "SUCCESS") {
            if (ResData.data.Flag) {
              this.setState({ Message: ResData.data.Message });
              return true;
            } else {
              this.setState({ Message: "" });
              return false;
            }
          }
        }
      } else {
        this.setState({ Message: "Invalid email." });
      }
    } else {
      this.setState({ Message: "Please enter email." });
    }
  }

  async FormValidation() {
    let Username = document.getElementById("Username").value;
    let Password = document.getElementById("Password").value;
    let IsExists = await this.CheckEmailExits();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let formIsValid = true;
    let errors = {};

    if (Username.length <= 0) {
      formIsValid = false;
      this.setState({ Message: "Please enter email." });
    } else if (!emailRegex.test(Username)) {
      formIsValid = false;
      this.setState({ Message: "Invalid email." });
    } else if (IsExists) {
      formIsValid = false;
      this.setState({ Message: "email already exist." });
    }
    if (Password.length <= 0) {
      formIsValid = false;
      errors["password"] = "Please enter password";
    } else if (Password.length < 6) {
      formIsValid = false;
      errors["password"] = "The password must be at least 6 characters long.";
    }
    this.setState({ errors: errors });

    return formIsValid;
  }
  ValidatePassword() {
    let errors = {};
    let Password = document.getElementById("Password").value;

    if (Password.trim().length <= 0) {
      errors["password"] = "Please enter password.";
    } else if (Password.trim().length < 6) {
      errors["password"] = "The password must be at least 6 characters long.";
    } else {
      errors["password"] = "";
    }
    this.setState({ errors: errors });
  }
  backbtn() {
    history.push("/clientlogin");
  }
  async savebtn(e) {
    e.preventDefault();
    e.currentTarget.disabled = true;
    var Final_flag = await this.FormValidation();
    if (Final_flag == true) {
      let Username = document.getElementById("Username").value;
      let Password = document.getElementById("Password").value;
      var InputParameters = {
        ID: this.props.location.state,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Username: Username,
        Password: CryptoJS.AES.encrypt(
          Password,
          "my-secret-key@123"
        ).toString(),
        LastUpdatedBy: this.state.CUserID,
        LastUpdatedDate: new Date(),
      };
      console.log(InputParameters,"InputParameters")
      Axios({
        url: CommonConstants.MOL_APIURL + "/client_login/ClientLoginUpdate",
        method: "POST",
        data: InputParameters,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div>
              Edit Client Login <br /> Client login detail updated successfully.
            </div>
          );
          history.push("/clientlogin");
        } else {
          toast.error(res.data.Message);
        }
      });
    } else {
      document.getElementById("m_emailInvite_submit").disabled = false;
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad lg-headertitle float-left pl-0">
                    Edit Client Login
                  </h4>
                </div>
              </div>
            <div class="bg-white p-3"> 
              <div className="row pt-2">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">User Name</label>
                    <div class="col-lg-8">
                      <input
                        type="text"
                        id="Username"
                        class="form-control"
                        placeholder=""
                        onBlur={this.CheckEmailExits.bind(this, "Email")}
                      />
                      <span style={{ color: "red" }}>{this.state.Message}</span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Password</label>
                    <div class="col-lg-8">
                      <input
                        type="text"
                        id="Password"
                        class="form-control"
                        placeholder=""
                        onBlur={this.ValidatePassword}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["password"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row mt-3 mb-5">
                <div class="col-lg-12 pull-left">
                  <button
                    id="m_emailInvite_submit"
                    onClick={this.savebtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="usersperclient"
                    onClick={this.backbtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditClientLoginPage = connect(
  mapState,
  actionCreators
)(EditClientLoginPage);
export { connectedEditClientLoginPage as EditClientLoginPage };
