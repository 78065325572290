import React , { useEffect }from 'react';
import PropTypes from 'prop-types';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import KpisAllTable from './ClientKpisAllTable';
import ClientLeaderboardTabing from './ClientLeaderboardTabing';
import ClientKipisOverviewTable from './ClientKpisOverviewTable'
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function SimpleTabs({ FromDates, ToDates, IsIncludeOldClient }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);
  
  const [UserID, SetUserID] = React.useState(0);
  // const [SourceData, SetSourceData] = React.useState();
  // const [TotalMeeting, SetTotalMeeting] = React.useState(0);
  // const [AverageResponseRate, SetAverageResponseRate] = React.useState(0);
  // const [AverageResponsetoMeetingRate, SetAverageResponsetoMeetingRate] = React.useState(0);
  // const [AverageMeetingRate, SetAverageMeetingRate] = React.useState(0);
  // const [ReplyRate, SetReplyRate] = React.useState(0);
  // const [ToatlResponsetoMeetingRate, SetToatlResponsetoMeetingRate] = React.useState(0);
  const [FromDate, SetFromDate] = React.useState("");
  const [ToDate, SetToDate] = React.useState("");
  const [IsIncludeOldClients, SetIsIncludeOldClients] = React.useState(false);
  // const [IsLoading,SetIsLoading] = React.useState(false)
  const [CUserID,SetCUserID] = React.useState(0)

  
  useEffect(() => {
    SetFromDate(FromDates)
    SetToDate(ToDates)

    FromDates = FromDates.replace(/-/g, '/');
    ToDates = ToDates.replace(/-/g, '/');
    SetIsIncludeOldClients(IsIncludeOldClient)
  }, [FromDates, ToDates, IsIncludeOldClient]);

  
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
   
  return (
    <div className="tabdes"> 
     <div> 
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} variant="scrollable" aria-label="simple tabs example">
            <Tab label="Overview" {...a11yProps(0)}>Overviewasdasd</Tab>
            <Tab label="All Clients" {...a11yProps(1)} />
            <Tab label="Leaderboard" {...a11yProps(2)} /> 
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone whitebg" index={0}>
        <div className="row mx-0">
            <ClientKipisOverviewTable FromDates={FromDate} ToDates={ToDate} IsIncludeOldClient={IsIncludeOldClients}/>
            </div>
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone whitebg" index={1}> 
            <div className="row mx-0">
            <div className="col px-0">
                <KpisAllTable FromDates={FromDate} ToDates={ToDate} IsIncludeOldClient={IsIncludeOldClients}/> 
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone whitebg" index={2}>  
            <div className="row mx-0">
            <div className="col px-0"> 
                <ClientLeaderboardTabing FromDates={FromDate} ToDates={ToDate} IsIncludeOldClient={IsIncludeOldClients} /> 
            </div>
          </div>
        </TabPanel> 

      </div> 

    </div>
  );
}