import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Pagination from "@material-ui/lab/Pagination";
import { CSVDownloader } from "react-papaparse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import loadingicon from "../../../images/loading.gif";
import { toast } from "react-toastify";
import { CommonConstants, COLORS } from "../../../_constants/common.constants";
import { GetUserDetails,GetClientDetails } from "../../../_helpers/Utility";
import ReactApexchart from "react-apexcharts";


export default function AccountAllTable({ CampaignIDforEmail,FromDates,ToDates }) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CampaignName");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [CUserID,SetCUserID] = React.useState(0)
  const [FromDate,SetFromDate] = React.useState("01/01/2000")
  const [ToDate,SetToDate] = React.useState("12/31/2023")
  const [CampaignID,SetCampaignID] = React.useState("")
  const [Counts,SetCounts] = React.useState({});
  const [ExportData,SetExportData] = React.useState([]);
  const [ClientName,SetClientName] = React.useState("");
  const [CountGraph,SetCountGraph] = React.useState([])

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    GetClientDetails(Details.ClientID).then((result) => {
      SetClientName(result[0].Name)
    });
    AccountReportingGet(Details.ClientID, Details.ParentUserID, Details.Role,FromDates,ToDates,CampaignIDforEmail);
    ExportAccountReporting(Details.ClientID, Details.ParentUserID, Details.Role,FromDates,ToDates,CampaignIDforEmail);
    ReportingAccountMatrixGraphGet(Details.ClientID, Details.ParentUserID, Details.Role,FromDates,ToDates);

  }, [Search, Page, RowsPerPage,FromDates,ToDates]);

  // Account reporting list
  const AccountReportingGet = (CID, UID, URole,FromDates,ToDates,CampaignIDforEmail) => {
    SetIsLoading(true);
    var InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      CampaignID:CampaignIDforEmail,
      FromDate:FromDates,
      ToDate:ToDates,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/campaign/GetReportingAccount",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((result) => {
      
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetCounts(result.data.Counts);
      SetIsLoading(false);
    });
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("search").value;
    SetIsLoading(true);
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: Search,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        CampaignID:CampaignIDforEmail,
        FromDate:FromDates,
        ToDate:ToDates,
      };
      const ApiResponse = Axios({
        url: CommonConstants.MOL_APIURL + "/campaign/GetReportingAccount",
        method: "POST",
        data: InputParameter,
      });
      ApiResponse.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetCounts(Result.data.Counts);
        SetIsLoading(false);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        CampaignID:CampaignIDforEmail,
        FromDate:FromDates,
        ToDate:ToDates,
      };
      const ApiResponse = Axios({
        url: CommonConstants.MOL_APIURL + "/campaign/GetReportingAccount",
        method: "POST",
        data: InputParameter,
      });
      ApiResponse.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetCounts(Result.data.Counts);
        SetIsLoading(false);
      });
    }
  };

  //Export Account reporting
  const ExportAccountReporting = (CID,UID,URole,FromDates,ToDates,CampaignIDforEmail)=>{
      var InputParameters = {
        ClientID: CID,
        UserID: UID,
        Role: URole,
        CampaignID:CampaignIDforEmail,
        FromDate:FromDates,
        ToDate:ToDates,
      };
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/campaign/ExportReportingAccount",
        method: "POST",
        data: InputParameters,
      });
      rows1.then((result) => {
        SetExportData(result.data.Data);
      });
  }

  const ExportCsv = () => {
    toast.success(
      <div className="toastsize">Data exported successfully.
       
      </div>
    );
  }

    // Account Reporting MatrixGraph
    const ReportingAccountMatrixGraphGet = (CID, UID, URole,Fromdate,Todate) => {
      var InputParameters = {
        ClientID: CID,
        UserID: UID,
        Role: URole,
        CampaignID: CampaignIDforEmail,
        FromDate: Fromdate,
        ToDate: Todate,
      };
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/campaign/ReportingAccountMatrixGraphGet",
        method: "POST",
        data: InputParameters,
      });
      rows1.then((result) => {
        SetCountGraph(result.data);
      });
    };


const AccountState = {
  series: [
    {
      name: "Open",
      color: COLORS.secondary,
      data: CountGraph.map((item) => item.Open),
    },
    {
      name: "Sent",
      color: COLORS.primary,
      data: CountGraph.map((item) => item.Sent),
    },
    {
      name: "Replies",
      color: COLORS.brown,
      data: CountGraph.map((item) => item.Reply),
    },
    {
      name: "Bounces",
      color: COLORS.emeraldGreen,
      data: CountGraph.map((item) => item.Bounce),
    }
   
  ],
  options: {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
          show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: CountGraph.map((item) => item.dateRange),
      title: {
        text: "Weeks",
      },
    },
    legend: {
      position: 'right',
      onItemClick: {
        toggleDataSeries: false
    },
  },
    yaxis: {
      title: {
        text: "Count",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      enabled: true,
      intersect: false,
      x: {
          show: true,
      },
      y: {
          formatter: function(val) {
              return val;
          },
      },
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
      
        const data = CountGraph[dataPointIndex];
           
        return (
          `<div style="background-color: #fff; border: 1px solid #ccc; border-radius: 8px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); max-width: 300px;">
          <div style="font-weight: bold;background-color:#e1e1e1;padding: 8px; margin-bottom: 8px;"><small>${data.dateRange}</small></div>
          <div style="display: flex; align-items: center;padding-inline: 8px; margin-bottom: 4px;">
              <div style="width: 12px; height: 12px; border-radius: 50%; margin-right: 8px; background-color: ${AccountState.series[0].color};"></div>
              <div><small>${AccountState.series[0].name}: ${data.Open}</small></div>
          </div>
          <div style="display: flex; align-items: center;padding-inline: 8px; margin-bottom: 4px;">
              <div style="width: 12px; height: 12px; border-radius: 50%; margin-right: 8px; background-color: ${AccountState.series[1].color};"></div>
              <div><small>${AccountState.series[1].name}: ${data.Sent}</small></div>
          </div>
          <div style="display: flex; align-items: center;padding-inline: 8px; margin-bottom: 4px;">
              <div style="width: 12px; height: 12px; border-radius: 50%; margin-right: 8px; background-color: ${AccountState.series[2].color};"></div>
              <div><small>${AccountState.series[2].name}: ${data.Reply}</small></div>
          </div>
          <div style="display: flex; align-items: center;padding-inline: 8px;">
              <div style="width: 12px; height: 12px; border-radius: 50%; margin-right: 8px; background-color: ${AccountState.series[3].color};"></div>
              <div><small>${AccountState.series[3].name}: ${data.Bounce}</small></div>
          </div>
      </div>
  `
        );
    },

  },
  },
};

const handleHeaderCheckboxChange = (isChecked) => {
  const updatedData = Rows.map((item) => ({
    ...item,
    selected: isChecked,
  }));
  SetRows(updatedData);
};

const handleRowCheckboxChange = (itemId) => {
  const updatedData = Rows.map((item) => {
    if (item.ID === itemId) {
      return {
        ...item,
        selected: !item.selected,
      };
    }
    return item;
  });
  SetRows(updatedData);
};
  return (
    <>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}

        <div className="row colfive">
            <div className="col-xl-2">
              <div class="bg-light-sky boxcardcounter align-center">
                <div class="d-flex">
                  <div className="contectboxes w-100">
                    <div class="line-title text-center d-block">
                      <span class="clr-sky box-main-title">{isNaN(Counts?.TotalSentMail) ? 0 : Counts?.TotalSentMail}</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Sent</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div class="bg-light-sky boxcardcounter align-center">
                <div class="d-flex">
                  <div className="contectboxes w-100">
                    <div class="line-title text-center d-block">
                      <span class="clr-sky box-main-title">{isNaN(Counts?.TotalReply) ? 0 : Counts?.TotalReply}</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Reply</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div class="bg-light-sky boxcardcounter align-center">
                <div class="d-flex">
                  <div className="contectboxes w-100">
                    <div class="line-title text-center d-block">
                      <span class="clr-sky box-main-title">{isNaN(Counts?.TotalOpenPercentage) ? 0 : Counts?.TotalOpenPercentage}%</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Open %</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div class="bg-light-sky boxcardcounter align-center">
                <div class="d-flex">
                  <div className="contectboxes w-100">
                    <div class="line-title text-center d-block">
                      <span class="clr-sky box-main-title">{isNaN(Counts?.TotalReplyPercentage) ? 0 : Counts?.TotalReplyPercentage}%</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Reply %</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div class="bg-light-sky boxcardcounter align-center">
                <div class="d-flex">
                  <div className="contectboxes w-100">
                    <div class="line-title text-center d-block">
                      <span class="clr-sky box-main-title">{isNaN(Counts?.TotalBouncePercentage) ? 0 : Counts?.TotalBouncePercentage}%</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Bounce %</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row padtop-50">
            <div class="col-xl-12">
              <div id="chart chart-max450">
                <ReactApexchart
                options={AccountState.options}
                series={AccountState.series}
                type="line"
                height={450}
              />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col ">
              <h4 className="headertitle float-left pl-0">Accounts Details</h4>
            </div> 
            <div className="col full-right pt-2">
            <CSVDownloader
                            className="px-0"
                            data={ExportData}
                            filename={`${ClientName}-Account`}
                            bom={true}
                          >
                            <a
                              onClick={ExportCsv}
                              className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                            >
                              <i className="la la-icon-font-size-13 la-download"></i>
                              <span>Export</span>
                            </a>
                          </CSVDownloader>
            </div>
          </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>
                <input
                type="checkbox"
                onChange={(e) => handleHeaderCheckboxChange(e.target.checked)}
              />
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("CampaignName");
                    }}
                  >
                    Campaign Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Domain");
                    }}
                  >
                    Domain
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("TotalSentMail");
                    }}
                  >
                    Sent
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("TotalReply");
                    }}
                  >
                    Reply
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("TotalOpen");
                    }}
                  >
                    Open
                  </a>
                </TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row,i) => (
                  <TableRow key={row.ID}>
                    <TableCell> <input
                  type="checkbox"
                  checked={row.selected}
                  onChange={() => handleRowCheckboxChange(row.ID)}
                /></TableCell>
                    <TableCell>{row.CampaignName}</TableCell>
                    <TableCell>{row.Domain}</TableCell>
                    <TableCell>{row.TotalSentMail}</TableCell>
                    <TableCell>{row.TotalReply}</TableCell>
                    <TableCell>{row.TotalOpen}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
