import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import BlacklistsTabing from '../../_components/user/BlackLists/BlackListsTabing';
import CollapsibleTable from '../../_components/usertable';



class BlacklistsPage extends React.Component {
    constructor(props) {
        super(props);
      }

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome px-0">
                    <div className="row align-items-center">
                        <div className="col">
                            <h4 className="headertitlebd lg-headertitle pl-3">Blacklists</h4>
                        </div>
                    </div> 
                    <div className="col">
                        <BlacklistsTabing />
                    </div>
                </div>  
                </div>
        <Footer/>
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedBlacklistsPage = connect(mapState, actionCreators)(BlacklistsPage);
export { connectedBlacklistsPage as BlacklistsPage };