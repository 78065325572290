import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

class CAddPotentialMeetingsPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      fields: {},
      errors: {},
      statusAvailable: null,
      EmailAvailable: null,
      meetingsource: [],
      meetingaccount: [],
      meetingsetbyResponder: [],
      meetingsetbyColdCallers: [],
      potentialowner: [],
      potentialstatus: [],
      accountemail: [],
      ClientData: [],
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      ClientPotential: false,
      IsValid: false,
      Role: Details.Role,
      IsButtonDisabled: false,
      ResponderName: "",
      IsLoading:true,
      IsContactLimintation:false
    };
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
    this.HandleChangedropdownBlur = this.HandleChangedropdownBlur.bind(this);
    this.HandleChangeotherFieldBlur =
      this.HandleChangeotherFieldBlur.bind(this);
  }
  componentDidMount() {
    document.title = `Add Potential Meetings | SalesHive`;
    if (this.props.location.state["Pagename"] == "Contact") {
      document.getElementById("FirstName").value =
        this.props.location.state[0]?.FirstName;
      document.getElementById("LastName").value =
        this.props.location.state[0]?.LastName;
      document.getElementById("Title").value =
        this.props.location.state[0]?.Title;
      document.getElementById("Company").value =
        this.props.location.state[0]?.Company;
      document.getElementById("Email").value =
        this.props.location.state[0]?.Email;
      document.getElementById("LinkedInURL").value =
        this.props.location.state[0]?.LinkedInURL;
      document.getElementById("DirectPhone").value =
        this.props.location.state[0]?.DirectPhone;
      document.getElementById("DirectPhoneExt").value =
        this.props.location.state[0]?.DirectPhoneExt;
      document.getElementById("CompanyPhone").value =
        this.props.location.state[0]?.CompanyPhone;
    }
    if (this.props.location.state["Pagename"] == "clientPotenial") {
      this.setState({ ClientPotential: true });
    }
    this.GetPotentialmeeting();
   // this.GetCheckClientlimition();
  }

  GetPotentialmeeting() {
    try{
      var Details = GetUserDetails();
      var str_in = {
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        Role: Details.Role,
      };
      const rows = Axios({
        url:
          CommonConstants.MOL_APIURL + "/potentialmeeting/PotentialAllStatusGet",
        method: "POST",
        data: str_in,
      });
      rows.then((result) => {
        
        var RArr= result.data?.meetingsetby[0]?.ResponderDetails || [];
        var CArr= result.data?.meetingsetby[0]?.ColdCallersDetails || [];
        
        RArr.forEach(RArr1 => {
          RArr1.value = "R"+ RArr1.value;
      });
      CArr.forEach(CArr1 => {
        CArr1.value = "C"+ CArr1.value;
    });
        const combinedArray = [...RArr, ...CArr];
        combinedArray.sort((a, b) => a.label.localeCompare(b.label));


        this.setState({
          meetingsource: result.data?.meetingsource,
          potentialowner: result.data?.potentialowner,
          potentialstatus: result.data?.potentialstatus,
          meetingaccount: result.data?.meetingaccount,
          meetingsetbyResponder: combinedArray,
          meetingsetbyColdCallers:
            result.data.meetingsetby[0]?.ColdCallersDetails,
          ClientData: result.data?.ClientData,
        });
        this.setState({ IsLoading: false });
      })
      .catch((error) => {
        //toast.error("An error occurred while getting. Please try again.");
        this.setState({ IsLoading: false });
      });
    }catch(error){
      toast.error("An error occurred while getting. Please try again.");
      this.setState({ IsLoading: false });
    }
  }

  async GetCheckClientlimition(){
    var ClientLimitParadata = {
      ClientID: this.state.ClientID,
      Role :this.state.Role
    };
    let ClientLimitParadataCall = await Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/CheckContactsPerClientLimit",
      method: "POST",
      data: ClientLimitParadata,
    });
    
    if (ClientLimitParadataCall.data.StatusMessage === "SUCCESS") {
      if(ClientLimitParadataCall.data.Data.IsExhaustLimit === true){
      //  this.setState({IsContactLimintation:true})
        return true;
      }else{
     //   this.setState({IsContactLimintation:false})
        return false;
      }
    }else{
     // this.setState({IsContactLimintation:true})
      return true;
    }
  }

  async FromValidation() {
    try {
    let formIsValid = true;
    let fields = this.state.fields;
    this.setState({ IsValid: false });
    let errors = {};
    var FirstName = document.getElementById("FirstName").value.trim();
    var LastName = document.getElementById("LastName").value.trim();
    var Title = document.getElementById("Title").value.trim();
    var Company = document.getElementById("Company").value.trim();
    var Email = document.getElementById("Email").value.trim();
    var MeetingSourceID = document.getElementById("MeetingSourceID").value;
    var MeetingOwnerID = document.getElementById("MeetingOwnerID").value;
    var EngagementStatusID =
      document.getElementById("EngagementStatusID").value;
    var ReminderEmailAccount = document.getElementById("ClientPOCID").value;
    let IsExist = await this.CheckExistPotentialMeetingEmail(Email);
    var ResponderName = document.getElementById("MeetingSetbyID").value;
    var CompanyPhones = document.getElementById("CompanyPhone").value;

    var DirectPhones = document.getElementById("DirectPhone").value.trim();
    var DirectPhoneExts = document.getElementById("DirectPhoneExt").value.trim();

    if (isNaN(DirectPhones)) {
      formIsValid = false;
      errors["DirectPhone"] = "Invalid direct phone";
    }
    if (isNaN(DirectPhoneExts)) {
      formIsValid = false;
      errors["DirectPhoneExt"] = "Invalid direct phone ext";
    }

    if (FirstName == "") {
      formIsValid = false;
      errors["FirstName"] = "Please enter first name";
    }
    if (LastName == "") {
      formIsValid = false;
      errors["LastName"] = "Please enter last name";
    }
    if (Title == "") {
      formIsValid = false;
      errors["Title"] = "Please enter Title";
    }
    if (Company == "") {
      formIsValid = false;
      errors["Company"] = "Please enter Company";
    }
    if (Email == "") {
      formIsValid = false;
      errors["Email"] = "Please enter Email";
    }
    if (MeetingSourceID == "") {
      formIsValid = false;
      errors["MeetingSourceID"] = "Please select Meeting Source";
    }
    if (ReminderEmailAccount == "") {
      formIsValid = false;
      errors["ClientPOCID"] = "Please select reminder email account";
    }
    if (MeetingOwnerID == "") {
      formIsValid = false;
      errors["MeetingOwnerID"] = "Please select owner";
    }
    if (EngagementStatusID == "") {
      formIsValid = false;
      errors["EngagementStatusID"] = "Please select status";
    }
    if (ResponderName == "") {
      formIsValid = false;
      errors["ResponderName"] = "Please select Meeting set By";
    }
    if (IsExist == "InvalidEmail") {
      formIsValid = false;
      errors["Email"] = "invalid email.";
    }
    if (IsExist == "ExistEmail") {
      formIsValid = false;
      errors["Email"] = "Email Already Exist";
    }

    if (IsExist == "BlankData" && Email == "") {
      formIsValid = false;
      errors["Email"] = "Please enter email";
    }

    if (this.state.IsValid == true) {
      formIsValid = false;
      errors["Email"] = "Invalid from email.";
    }

    if (CompanyPhones?.trim() === "") {
      errors["CompanyPhone"] = null; // Blank is considered fine
    } else if (!/^\d+$/.test(CompanyPhones?.trim())) {
      errors["CompanyPhone"] = "Invalid company phone";
      formIsValid = false;
    } else {
      errors["CompanyPhone"] = null; // Valid phone number
    }

    if (this.state.ClientPotential == true) {
      var ClientPotentialID =
        document.getElementById("ClientPotentialID").value;
      if (ClientPotentialID == "") {
        formIsValid = false;
        errors["ClientPotentialID"] = "Please select client";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  } catch (error) {
    toast.error("An unexpected error occurred. Please try again.");
  }
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.Email != "") {
      this.state.errors.Email = null;
      this.state.EmailAvailable = null;
      this.CheckExistPotentialMeetingEmail(e.target.value);
    } else {
      this.state.EmailAvailable = null;
      this.state.errors.Email = null;
    }
  }

  async HandleChangeBlur() {
    try {
    let errors = { ...this.state.errors };
    var Email = document.getElementById("Email").value.trim();
    if (Email == "") {
      errors["Email"] = "Please enter Email";
    } else {
      let IsExist = await this.CheckExistPotentialMeetingEmail(Email);
      if (IsExist == "InvalidEmail") {
        errors["Email"] = "invalid email.";
      }
      if (IsExist == "ExistEmail") {
        errors["Email"] = "Email Already Exist";
      }
      if (IsExist == "AllDone") {
        errors["Email"] = null;
      }
    }

    this.setState({ errors: errors });
  } catch (error) {
    toast.error("An unexpected error occurred. Please try again.");
  }
  }

  async HandleChangedropdownBlur(Name) {
    try {

    
    let errors = { ...this.state.errors };
    var MeetingSourceID = document.getElementById("MeetingSourceID").value;
    var MeetingOwnerID = document.getElementById("MeetingOwnerID").value;
    var EngagementStatusID =
      document.getElementById("EngagementStatusID").value;
    var ReminderEmailAccount = document.getElementById("ClientPOCID").value;
    var MeetingSetbyID = document.getElementById("MeetingSetbyID").value;

    if (this.state.ClientPotential == true) {
      if (Name == "ClientName") {
        var ClientPotentialID =
          document.getElementById("ClientPotentialID").value;
        if (ClientPotentialID == "") {
          errors["ClientPotentialID"] = "Please select client";
        } else {
          errors["ClientPotentialID"] = null;
        }
      }
    }
    if (Name == "MeetingSource") {
      if (MeetingSourceID == "") {
        errors["MeetingSourceID"] = "Please select Meeting Source";
      } else {
        errors["MeetingSourceID"] = null;
      }
    }
    if (Name == "ReminderEmailAccount") {
      if (ReminderEmailAccount == "") {
        errors["ClientPOCID"] = "Please select reminder email account";
      } else {
        errors["ClientPOCID"] = null;
      }
    }
    if (Name == "MeetingOwner") {
      if (MeetingOwnerID == "") {
        errors["MeetingOwnerID"] = "Please select owner";
      } else {
        errors["MeetingOwnerID"] = null;
      }
    }
    if (Name == "EngagementStatus") {
      if (EngagementStatusID == "") {
        errors["EngagementStatusID"] = "Please select status";
      } else {
        errors["EngagementStatusID"] = null;
      }
    }
    if (Name == "ResponderName") {
      if (MeetingSetbyID == "") {
        errors["ResponderName"] = "Please select Meeting Set by";
      } else {
        errors["ResponderName"] = null;
      }
    }
    this.setState({ errors: errors });
          
  } catch (error) {
    toast.error("An unexpected error occurred. Please try again.");
  }
  }

  async HandleChangeotherFieldBlur(Name) {
    try {
    
    let errors = { ...this.state.errors };
    var FirstName = document.getElementById("FirstName").value.trim();
    var LastName = document.getElementById("LastName").value.trim();
    var Title = document.getElementById("Title").value.trim();
    var Company = document.getElementById("Company").value.trim();
    var CompanyPhones = document.getElementById("CompanyPhone").value.trim();
    var DirectPhones = document.getElementById("DirectPhone").value.trim();
    var DirectPhoneExts = document.getElementById("DirectPhoneExt").value.trim();

    if (Name == "DirectPhone") {
      if (isNaN(DirectPhones)) {
        errors["DirectPhone"] = "Invalid direct phone";
      } else {
        errors["DirectPhone"] = null;
      }
    }

    if (Name == "DirectPhoneExt") {
      if (isNaN(DirectPhoneExts)) {
        errors["DirectPhoneExt"] = "Invalid direct phone ext";
      } else {
        errors["DirectPhoneExt"] = null;
      }
    }

    if (Name == "FirstName") {
      if (FirstName == "") {
        errors["FirstName"] = "Please enter first name";
      } else {
        errors["FirstName"] = null;
      }
    }
    if (Name == "LastName") {
      if (LastName == "") {
        errors["LastName"] = "Please enter last name";
      } else {
        errors["LastName"] = null;
      }
    }
    if (Name == "Title") {
      if (Title == "") {
        errors["Title"] = "Please enter Title";
      } else {
        errors["Title"] = null;
      }
    }
    if (Name == "Company") {
      if (Company == "") {
        errors["Company"] = "Please enter Company";
      } else {
        errors["Company"] = null;
      }
    }

    if (Name === "CompanyPhone") {
      if (CompanyPhones?.trim() === "") {
        errors["CompanyPhone"] = null; // Blank is considered fine
      } else if (!/^\d+$/.test(CompanyPhones?.trim())) {
        errors["CompanyPhone"] = "Invalid company phone";
      } else {
        errors["CompanyPhone"] = null; // Valid phone number
      }
    }

    this.setState({ errors: errors });
  } catch (error) {
    toast.error("An unexpected error occurred. Please try again.");
  }
  }

  // check exit Email
  async CheckExistPotentialMeetingEmail(Email) {
    try {
    let regexp =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (Email != "") {
      if (!regexp.test(Email)) {
        return "InvalidEmail";
      } else {
        // this.setState({ IsExist: false });
        var str_in = {
          ClientID: this.state.ClientID,
          Email: Email,
          Role: this.state.Role,
        };
        let resdata = await Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/potentialmeeting/PotentialMeetingCheckExists",
          method: "POST",
          data: str_in,
        });
        if (resdata.data.StatusMessage == "SUCCESS") {
          if (resdata.data.Data.length > 0) {
            this.setState({ EmailAvailable: resdata.data.Data[0].Email });
            return "ExistEmail";
          } else {
            return "AllDone";
          }
        }
      }
    } else {
      return "BlankData";
    }
  }catch (error) {
      // Handle any unexpected errors here
      toast.error("An error occurred:", error);
    }
  }

  backbtn() {
    if (this.props.location.state["Pagename"] == "Contact") {
      history.push({
        pathname: "/cviewcontacts",
        state: {
          data: this.props.location.state[0]?._id,
          EmailData: this.props.location.state[0]?.Email,
        },
      });
    } else if (this.props.location.state["Pagename"] == "clientPotenial") {
      history.push("/clientpotentialmeetings");
    } else if (this.props.location.state["Pagename"] == "AccountPotential") {
      history.push({
        pathname: "/cviewaccount",
        state: { data: this.props.location.state.data },
      });
    } else {
      history.push("/cpotentialmeetings");
    }
  }

  async savebtn(e) {
    try{
    e.preventDefault();
    let IsContactLimintations = true;
    IsContactLimintations= await this.GetCheckClientlimition();

    this.setState({ IsButtonDisabled: true });
    //e.currentTarget.disabled = true;
    var Final_flag = await this.FromValidation();
    if (Final_flag == true) {
      this.setState({ IsLoading: true});

      var EmailProspect = document.getElementById("Email").value.trim();
      //Check Email exist
      var ClientLimitParadataEmailContact = {
        EmailContact:EmailProspect,
        ClientID: this.state.ClientID,
        Role :this.state.Role
      };

      let ClientLimitParadataCallEmailContact = await Axios({
        url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetContactLimitationStatus",
        method: "POST",
        data: ClientLimitParadataEmailContact,
      });

      if(ClientLimitParadataCallEmailContact.data.Data.length>0 || IsContactLimintations === false){

      var FirstName = document.getElementById("FirstName").value.trim();
      var LastName = document.getElementById("LastName").value.trim();
      var Title = document.getElementById("Title").value.trim();
      var Company = document.getElementById("Company").value.trim();
      var Email = document.getElementById("Email").value.trim();
      var LinkedInURL = document.getElementById("LinkedInURL").value.trim();
      var DirectPhone = document.getElementById("DirectPhone").value.trim();
      var DirectPhoneExt = document.getElementById("DirectPhoneExt").value.trim();
      var CompanyPhone = document.getElementById("CompanyPhone").value.trim();
      var MeetingSourceID = document.getElementById("MeetingSourceID").value;
      var MeetingOwnerID = document.getElementById("MeetingOwnerID").value;
      var EngagementStatusID =
        document.getElementById("EngagementStatusID").value;
      var ReminderEmailAccount = document.getElementById("ClientPOCID").value;
      var MeetingSetbyID = document.getElementById("MeetingSetbyID").value;
      var ResponderName = this.state.ResponderName;

      if (this.state.ClientPotential == true) {
        var ClientPotentialID =
          document.getElementById("ClientPotentialID").value;
        var data = {
          FirstName: FirstName,
          LastName: LastName,
          Email: Email,
          Title: Title,
          Company: Company,
          LinkedInUrl: LinkedInURL,
          DirectPhone: DirectPhone,
          DirectPhoneExt: DirectPhoneExt,
          CompanyPhone: CompanyPhone,
          EngagamentStatusID: EngagementStatusID,
          MeetingSourceID: MeetingSourceID,
          MeetingOwnerID: MeetingOwnerID,
          ResponderName: ResponderName,
          ResponderColdCallersType: MeetingSetbyID.charAt(0),
          ResponderColdCallersID: MeetingSetbyID,
          ReminderEmailAccountID: ReminderEmailAccount,
          Domain: Email.substring(Email.lastIndexOf("@") + 1),
          SalesReply_MessageID: null,
          ConversionCampaignID: null,
          ProspectLastReply: null,
          IsDeleted: false,
          ClientID: ClientPotentialID,
          UserID: this.state.UserID,
          CreatedBy: this.state.CUserID,
          Role: this.state.Role,
          CreatedDate: new Date(),
          LastUpdatedBy: null,
          LastUpdatedDate: null,
        };
      } else {
        var data = {
          FirstName: FirstName,
          LastName: LastName,
          Email: Email,
          Title: Title,
          Company: Company,
          LinkedInUrl: LinkedInURL,
          DirectPhone: DirectPhone,
          DirectPhoneExt: DirectPhoneExt,
          CompanyPhone: CompanyPhone,
          EngagamentStatusID: EngagementStatusID,
          MeetingSourceID: MeetingSourceID,
          MeetingOwnerID: MeetingOwnerID,
          ResponderColdCallersType: MeetingSetbyID.charAt(0),
          ResponderColdCallersID: MeetingSetbyID,
          ReminderEmailAccountID: ReminderEmailAccount,
          Domain: Email.substring(Email.lastIndexOf("@") + 1),
          SalesReply_MessageID: null,
          ConversionCampaignID: null,
          ProspectLastReply: null,
          IsDeleted: false,
          ClientID: this.state.ClientID,
          UserID: this.state.UserID,
          CreatedBy: this.state.CUserID,
          Role: this.state.Role,
          CreatedDate: new Date(),
          LastUpdatedBy: null,
          LastUpdatedDate: null,
        };
      }
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/potentialmeeting/PotentialMeetingAdd",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          if (this.props.location.state["Pagename"] == "Contact") {
            history.push({
              pathname: "/cviewcontacts",
              state: { data: this.props.location.state[0]?._id },
            });
          } else if (
            this.props.location.state["Pagename"] == "clientPotenial"
          ) {
            history.push("/clientpotentialmeetings");
          } else if (
            this.props.location.state["Pagename"] == "AccountPotential"
          ) {
            history.push({
              pathname: "/cviewaccount",
              state: { data: this.props.location.state.data },
            });
          } else {
            toast.success(
              <div className="toastsize">
                Potential meeting Add
                <br />
                Potential meeting added successfully.
              </div>
            );
            history.push({
              pathname: "/cviewpotentialmeetings",
              state: { data: res.data.data._id, Pagename: "" },
            });
          }
        } else {
          toast.error(res.data.Message);
          this.setState({ IsButtonDisabled: false });
          this.setState({ IsLoading: false });
        }
      }).catch((error) => {
        toast.error("An error occurred while saving. Please try again.");
        this.setState({ IsButtonDisabled: false });
        this.setState({ IsLoading: false });
      });

      }else{
        toast.error(<div className="toastsize">You have exceeded contact limits</div>)
        this.setState({ IsButtonDisabled: false });
        this.setState({ IsLoading: false });
      }
    } else {
      this.setState({ IsButtonDisabled: false });
      this.setState({ IsLoading: false });
    }
  }catch (error) {
    toast.error("An unexpected error occurred. Please try again.");
    this.setState({ IsButtonDisabled: false });
    this.setState({ IsLoading: false });
  }
  }

  render() {
    return (
      <>
        {
          this.state.IsLoading ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : null
        }

        <div className="adminmain">
          <Sidebar className="" />
          <div className="bodyhome px-3">
            <div className="row pt-2">
              <div className="col">
                <h3 className=" float-left py-3 lg-headertitle addpotential_header">
                  Add Potential Meeting
                </h3>
              </div>
            </div>

            <div className="bg-white">
              <div className="row">
                {this.state.ClientPotential == true ? (
                  <>
                    <div className="col-lg-6 boxsinput_group px-4">
                      <div className="row max-cols d-flex align-items-center pl-2">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Client
                        </label>
                        <div className="col-lg-7">
                          <select
                            className="form-control  m-input"
                            data-val="true"
                            data-val-number="The field ContactSourceID must be a number."
                            id="ClientPotentialID"
                            name="MeetingSourceID"
                            onChange={() =>
                              this.HandleChangedropdownBlur("ClientName")
                            }
                          >
                            <option value="">--Select--</option>
                            {this.state.ClientData.map((value) => (
                              <option value={value._id}>{value.Name}</option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>
                            {this.state.errors["ClientPotentialID"]}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group"></div>
                  </>
                ) : (
                  <></>
                )}

                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols  d-flex align-items-center pl-2">
                    <label className="col-lg-4 view-lable-meet potential_text ">
                      First Name
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="FirstName"
                        name="FirstName"
                        type="text"
                        onBlur={() =>
                          this.HandleChangeotherFieldBlur("FirstName")
                        }
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["FirstName"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Last Name
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="LastName"
                        name="LastName"
                        type="text"
                        onBlur={() =>
                          this.HandleChangeotherFieldBlur("LastName")
                        }
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["LastName"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols d-flex align-items-center pl-2">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Title
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Title"
                        name="Title"
                        type="text"
                        onBlur={() => this.HandleChangeotherFieldBlur("Title")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Title"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Company"
                        name="Company"
                        type="text"
                        onBlur={() =>
                          this.HandleChangeotherFieldBlur("Company")
                        }
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Company"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols d-flex align-items-center pl-2">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Email
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid email."
                        data-val-remote="Email already exist."
                        data-val-remote-additionalfields="*.Email,*.PageName,*.CampaignID,*.ClientID,*.UserID"
                        data-val-remote-url="/Utility/IsProspectEmailExistsAndBlacklist"
                        data-val-required="Please enter email."
                        id="Email"
                        name="Email"
                        type="text"
                        onBlur={this.HandleChangeBlur}
                      />

                      <span style={{ color: "red" }}>
                        {this.state.errors["Email"]}
                      </span>
                      {/* {this.state.EmailAvailable && (
                        <span style={{ color: "red" }}>
                          Email already exist.
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      LinkedIn URL
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="LinkedInURL"
                        name="LinkedInURL"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols d-flex align-items-center pl-2">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Meeting Source
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field ContactSourceID must be a number."
                        id="MeetingSourceID"
                        name="MeetingSourceID"
                        onChange={() =>
                          this.HandleChangedropdownBlur("MeetingSource")
                        }
                      >
                        <option value="">--Select--</option>
                        {this.state.meetingsource.map((value) => (
                          <option value={value._id}>{value.Name}</option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["MeetingSourceID"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Owner
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field MeetingOwnerID must be a number."
                        id="MeetingOwnerID"
                        name="MeetingOwnerID"
                        onChange={() =>
                          this.HandleChangedropdownBlur("MeetingOwner")
                        }
                      >
                        <option value="">--Select--</option>
                        {this.state.potentialowner.map((value) => (
                          <option value={value._id}>{value.Name}</option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["MeetingOwnerID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols d-flex align-items-center pl-2">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Reminder Email Account
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field ClientPOCID must be a number."
                        id="ClientPOCID"
                        name="ClientPOCID"
                        onChange={() =>
                          this.HandleChangedropdownBlur("ReminderEmailAccount")
                        }
                      >
                        <option value="">--Select--</option>
                        {this.state.meetingaccount.map((value) => (
                          <option value={value._id}>
                            {value.SMTPFromEmail}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClientPOCID"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Engagement Status
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field EngagementStatusID must be a number."
                        id="EngagementStatusID"
                        name="EngagementStatusID"
                        onChange={() =>
                          this.HandleChangedropdownBlur("EngagementStatus")
                        }
                      >
                        <option value="">--Select--</option>
                        {this.state.potentialstatus.map((value) => (
                          <option value={value._id}>{value.Status}</option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["EngagementStatusID"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group px-4">
                  <div className="row max-cols d-flex align-items-center pl-2">
                    <label className="col-lg-4 view-lable-meet potential_text ">
                      Direct Phone
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid direct phone"
                        data-val-regex-pattern="^[0-9]*$"
                        id="DirectPhone"
                        name="DirectPhone"
                        type="text"
                        onBlur={() =>
                          this.HandleChangeotherFieldBlur("DirectPhone")
                        }
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["DirectPhone"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Direct Phone Ext
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid direct phone ext"
                        data-val-regex-pattern="^[0-9]*$"
                        id="DirectPhoneExt"
                        name="DirectPhoneExt"
                        type="text"
                        onBlur={() =>
                          this.HandleChangeotherFieldBlur("DirectPhoneExt")
                        }
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["DirectPhoneExt"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group  px-4">
                  <div className="row max-cols d-flex align-items-center pl-2">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company Phone
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid company phone"
                        data-val-regex-pattern="^[0-9]*$"
                        id="CompanyPhone"
                        name="CompanyPhone"
                        type="text"
                        onBlur={() =>
                          this.HandleChangeotherFieldBlur("CompanyPhone")
                        }
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["CompanyPhone"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Meeting Set By
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field SalesRepID must be a number."
                        id="MeetingSetbyID"
                        name="MeetingSetbyID"
                        onChange={(e) => {
                          const selectedOption =
                            e.target.options[e.target.selectedIndex];
                          if (selectedOption) {
                            const label = selectedOption.label;
                            this.HandleChangedropdownBlur("ResponderName");
                            this.setState({ ResponderName: label }, () => {});
                          }
                        }}
                      >
                        <option value="">--Select--</option>
                        {this.state.meetingsetbyResponder &&
                        this.state.meetingsetbyResponder.length > 0
                          ? this.state.meetingsetbyResponder.map((value) => (
                              <option value={value.value}>
                                {value.label}
                              </option>
                            ))
                          : ""}
                        {/* {this.state.meetingsetbyColdCallers &&
                        this.state.meetingsetbyColdCallers.length > 0
                          ? this.state.meetingsetbyColdCallers.map((value) => (
                              <option value={"C" + value.value}>
                                {value.label}
                              </option>
                            ))
                          : ""} */}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ResponderName"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="borderbtnm mt-4 mb-4"></div>

              <div className="row pb-4 px-4">
                <div className="col-lg-12 pull-left px-2">
                  <button
                    id="submit"
                    className="btn btn-primary btn-lightgreen mr-1"
                    value="Save"
                    onClick={this.savebtn}
                    disabled={this.state.IsButtonDisabled}
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    className="btn btn-secondary"
                    value="Save"
                    onClick={this.backbtn}
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddSalesHiveContactPage = connect(
  mapState,
  actionCreators
)(CAddPotentialMeetingsPage);
export { connectedAddSalesHiveContactPage as CAddPotentialMeetingsPage };
