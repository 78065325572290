import React from "react";
import Axios from "axios";
let CryptoJS = require("crypto-js");
import { connect } from "react-redux";

import logo from "../../../images/SalesHive.png";
import logobgs from "../../../images/Loginvector.svg";

import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
const Uvalidation = require("../../../_helpers/user-validation");
const EmailTemplete = require("../../../_helpers/email-templete");
const Fileupload = require("../../../_helpers/fileupload");
import{GetUserDetails} from '../../../_helpers/Utility'

import ProfileImageUpload from '../../../_components/ProfileUpload';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const REACT_APP_ENCRYPTION_SALT = process.env.REACT_APP_ENCRYPTION_SALT

class AddUsersPerClientPage extends React.Component {
  constructor(props) {
    super(props);
    this.childRef = React.createRef();
    this.state = {
      Email: "",
      Open: false,
      FileObjects: [],
      FinalImg: "",
      LogoImageName: "",
      ClientID: "",
      UserID: "",
      CUserID: "",
      Errors: {},
      IsExist: false,
      AlreadyExists: false,
      Role: null,
      BtnDisabled: false,
      FormData: {
        Email: "",
        FirstName: "",
        LastName: "",
        PassWord: "",
        IsTwoStepVerification: false,
      },
      showPassword: false,
      errors: {},
      ButtonDisabled: false,
      IsLoading: false,
      DisplayAlreadyExistsMessage: false,
      DisplayUserAddedMessage: false,
      IsLinkExpire: false,
    };
    this.BackButton = this.BackButton.bind(this);
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  DecryptorMethod = (data) => {
    let FinalTmp = decodeURIComponent(data).split(" ").join("+");
    let bytes = CryptoJS.AES.decrypt(FinalTmp, REACT_APP_ENCRYPTION_SALT);
    let EncryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return EncryptedData;
  };

  componentDidMount() {
    document.title = "Add Users Per Client | SalesHive";

    const QueryParams = new URLSearchParams(window.location.search);
    const Tmp = QueryParams.get("email");
    const TmpCID = QueryParams.get("CID");
    const TmpUID = QueryParams.get("UID");

    let Email = this.DecryptorMethod(Tmp);
    let CID = this.DecryptorMethod(TmpCID);
    let UID = this.DecryptorMethod(TmpUID);

    // Check link expire or not
    this.CheckUserInviteLinkExpire(UID, CID, Email);

    this.setState({ Email: Email });
    this.setState({ UserID: UID });
    this.setState({ ClientID: CID });

    this.setState((prevState) => ({
      FormData: {
        ...prevState.FormData,
        Email: Email,
        UserID: UID,
        CreatedDt: new Date(),
        UserID: UID,
        ClientID: CID,
        Role: this.state.Role,
      },
    }));

    const InputParameters = {
      Email: Email,
      Page: "UserPerClient",
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/AdminUser/ValidationAddUser",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        if (res.data.Data.length > 0) {
          this.setState({ AlreadyExists: true });
        } else {
          this.setState({ AlreadyExists: false });
        }
      }
    });
  }

  // Check invite link expire or not
  CheckUserInviteLinkExpire = async (UID, CID, Email) => {
    try {
      let RequestBody = {
        UserID: UID,
        Client: CID,
        Email: Email,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/users_per_client/CheckLinkExpireOrNot",
        method: "POST",
        data: RequestBody,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          this.setState({ IsLinkExpire: res.data.Data?.LinkExpire });
        }
      });
    } catch (err) {}
  };

  //Check user per client exist
  CheckUserPerClient(Email) {
    let Details = GetUserDetails();
    let InputParameters = {
      ClientID: this.state.ClientID,
      UserID: this.state.UserID,
      Email: Email,
      Role: Details.Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/users_per_client/UsersPerClientExist",
      method: "POST",
      data: InputParameters,
    }).then((resdata) => {
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          this.setState({ IsExist: true });
          let errors = {};
          errors["Email"] = "Link expire";
          this.setState({ Errors: errors });
        } else {
          this.setState({ IsExist: false });
        }
      }
    });
  }

  //back button
  BackButton() {
    history.push("/bookedmeetings");
  }

  // Function to validate individual form field
  ValidateField = (fieldName, value) => {
    let Flag = true;
    this.setState((prevState) => {
      const newErrors = { ...prevState.errors };

      if (fieldName === "FirstName") {
        if (value.trim() === "") {
          newErrors.FirstName = "Please enter first name.";
          Flag = false;
        } else {
          newErrors.FirstName = "";
          Flag = true;
        }
      } else if (fieldName === "PassWord") {
        newErrors.PassWord = "";
        if (value.trim() === "") {
          Flag = false;

          newErrors.PassWord = "Please enter password.";
        } else if (value.length < 6 || value.length > 20) {
          Flag = false;

          newErrors.PassWord = "Password must be 6 to 20 chars long.";
        } else {
          Flag = true;
        }
      }
      return { errors: newErrors };
    });

    return Flag;
  };

  //Update OnChange Handler
  HandleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    this.setState((prevState) => {
      let updatedFormData = { ...prevState.FormData };
      // Check if the input is a checkbox
      if (type === "checkbox") {
        // Convert "on" and "off" to true and false
        updatedFormData[name] = checked;
      } else {
        updatedFormData[name] = value;
      }
      this.ValidateField(name, value);
      return {
        FormData: updatedFormData,
      };
    });
  };

  //Update OnBlur Handler
  HandleBlur = (e) => {
    const { name, value } = e.target;
    this.ValidateField(name, value);
  };

  //Validation field method
  ValidateMethod = (fieldName, value) => {
    let Flag = true;
    if (fieldName === "FirstName") {
      if (value.trim() === "") {
        Flag = false;
      } else {
        Flag = true;
      }
    } else if (fieldName === "PassWord") {
      if (value.trim() === "") {
        Flag = false;
      } else if (value.length < 6 || value.length > 20) {
        Flag = false;
      } else {
        Flag = true;
      }
    }
    return Flag;
  };

  //Update User Profile Data
  HandleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ ButtonDisabled: true });

    const { FormData } = this.state;

    const fieldNames = Object.keys(FormData);
    let arr = [];
    // Iterate through fields and validate
    fieldNames.forEach(async (fieldName) => {
      this.ValidateField(fieldName, FormData[fieldName]);
      arr.push(this.ValidateMethod(fieldName, FormData[fieldName]));
    });
    let isValid = arr.includes(false);

    // Check if isValid is still true
    if (!isValid && this.state.AlreadyExists === false && this.state.IsLinkExpire === false) {
      let Image = await this.childRef.current.SubmitData();
      let ImageName = Image.FileName;
      this.setState({ FinalImg: ImageName });
      FormData["Password"] = CryptoJS.AES.encrypt(
        FormData.PassWord,
        REACT_APP_ENCRYPTION_SALT
      ).toString();
      FormData["ProfileImage"] = ImageName;
      this.setState({ DisplayAlreadyExistsMessage: false });

      Axios({
        url: CommonConstants.MOL_APIURL + "/users_per_client/UserPerClientAdd",
        method: "POST",
        data: FormData,
      }).then((res) => {
        if (res) {
          this.setState({ DisplayUserAddedMessage: true });
          this.setState({ ButtonDisabled: false });
          localStorage.removeItem("LoginData");
          localStorage.removeItem("id");
          history.push("/");
        }
      });
    } else if (isValid) {
      this.setState({
        DisplayAlreadyExistsMessage: false,
        DisplayUserAddedMessage: false,
      });
      this.setState({ ButtonDisabled: false });
    } else {
      this.setState({ DisplayAlreadyExistsMessage: true });
      this.setState({ DisplayUserAddedMessage: false });
      this.setState({ ButtonDisabled: false });
    }
  };

  // handleFocus = (e) => {
  //   // Remove readOnly when the input is focused
  //   e.target.removeAttribute("readonly");
  // };

  render() {
    const { FormData, errors } = this.state;

    const customStylesLeg = {
      option: (provided, state) => ({
        ...provided,
        paddingLeft: 20,
        color: state.isSelected ? "#5c0f8b" : "#5c0f8b",
        fontWeight: state.isSelected ? "900" : "400",
      }),
    };
    return (
      <div className="login-main">
        <div className="row h-100">
          <div className="col-xs-12 col-md-6 logo-bgbod padding-btn">
            <img src={logobgs} className="login-vector" />

            <div className="m-login__logo">
              <a href="/">
                <img src={logo} className="main-logo" />
              </a>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 d-flex">
            <div className="full-hegiht">
              <div id="login" className="centerheigh ">
                <div className="heading">
                  <h1 className="lefter_title">Add Client Users</h1>
                </div>
                {
                  this.state.IsLinkExpire && <p id="UserExists_e" className="errorinput">
                   The client user has already been created.
                  </p>
                }

                {/* {this.state.DisplayAlreadyExistsMessage ? (
                  <p id="UserExists_e" className="errorinput">
                    {" "}
                    Client Users already exists{" "}
                  </p>
                ) : (
                  <></>
                )} */}

                {this.state.DisplayUserAddedMessage ? (
                  <p id="UserAdd_e" className="font-success">
                    {" "}
                    Client Users added successfully{" "}
                  </p>
                ) : (
                  <></>
                )}
                <form name="login" className="form" autoComplete="off">
                  <div className="input-control">
                    <label htmlFor="FirstName" className="input-label" hidden>
                      First Name
                    </label>
                    <input
                      type="text"
                      id="FirstName"
                      name="FirstName"
                      className="input-field"
                      placeholder="First Name"
                      autoComplete="off"
                      value={FormData.FirstName}
                      disabled={this.state.IsLinkExpire}
                      onChange={this.HandleInputChange}
                      onBlur={this.HandleBlur}
                    />
                    {errors.FirstName && (
                      <div className="errorinput">{errors.FirstName}</div>
                    )}
                  </div>
                  <div className="input-control">
                    <label htmlFor="LastName" className="input-label" hidden>
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="LastName"
                      name="LastName"
                      className="input-field"
                      placeholder="Last Name"
                      autoComplete="off"
                      disabled={this.state.IsLinkExpire}
                      value={FormData.LastName}
                      onChange={this.HandleInputChange}
                      onBlur={this.HandleBlur}
                      onFocus={this.handleFocus}
                    />
                  </div>
                  <div className="input-control">
                    <label htmlFor="Email" className="input-label" hidden>
                      Email Address
                    </label>
                    <input
                      type="Email"
                      id="Email"
                      name="Email"
                      className="input-field"
                      placeholder="Email Address"
                      value={FormData.Email}
                      onChange={this.HandleInputChange}
                      onBlur={this.HandleBlur}
                      readOnly={true}
                      disabled
                    />
                  </div>
                  <div className="input-control">
                    <label htmlFor="PassWord" className="input-label" hidden>
                      PassWord
                    </label>
                    <div className='position-relative d-flex align-items-center'>
                    <input
                      type={this.state.showPassword ? 'text' : 'password'}
                      id="PassWord"
                      name="PassWord"
                      className="input-field"
                      placeholder="Password"
                      autoComplete="off"
                      value={FormData.PassWord}
                      onChange={this.HandleInputChange}
                      onBlur={this.HandleBlur}
                      disabled={this.state.IsLinkExpire}
                    />
                       <IconButton
                      disableRipple
                      onClick={this.handleClickShowPassword}
                      className='position-absolute rigterpin'
                    >
                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    </div>
                    {errors.PassWord && (
                      <div className="errorinput">{errors.PassWord}</div>
                    )}
                  </div>
                  <div className="row boxsinput_group px-4">
                    <div className="toggleswich mr-2">
                      <input
                        type="checkbox"
                        className="checktogle"
                        id="IsTwoStepVerification"
                        name="IsTwoStepVerification"
                        disabled={this.state.IsLinkExpire}
                        checked={FormData.IsTwoStepVerification}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                      />
                      <b className="switch">
                        <span className="checkion"></span>
                        <span className="uncheckion"></span>
                      </b>
                      <b className="track"></b>
                    </div>
                    <label>Is Two Step Verification</label>
                  </div>
                  <ProfileImageUpload
                    profileImage={this.state.FinalImg}
                    ref={this.childRef}
                    NewForm={true}
                  />

                  <div className="input-control btn-center">
                    <button
                      type="button"
                      name="submit"
                      id="submit"
                      className="input-submit"
                      value="Save"
                      disabled={this.state.ButtonDisabled || this.state.IsLinkExpire}
                      onClick={this.HandleSubmit}
                    >
                      <i className="la la-save"></i> Save
                    </button>
                    <a
                      type="button"
                      name="submit"
                      className="input-submit"
                      value="Save"
                      onClick={() => {
                        this.BackButton();
                      }}
                    >
                      <i className="la la-arrow-circle-left"></i> Back
                    </a>
                    {/* <p id="err_user">
                      {" "}
                      Please enter Correct Email or PassWord{" "}
                    </p> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  return state;
}

const actionCreators = {};

const connectedAddUsersPerClientPage = connect(
  mapState,
  actionCreators
)(AddUsersPerClientPage);
export { connectedAddUsersPerClientPage as AddUsersPerClientPage };
