import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
var CryptoJS = require("crypto-js");
const moment = require("moment");

import logo from "../../images/SalesHive.png";
import logobgs from "../../images/Loginvector.svg";

import i18n from "../../i18n";
const EmailTemplete = require("../../_helpers/email-templete");
import { CommonConstants } from "../../_constants/common.constants";
import { history } from "../../_helpers";
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const REACT_APP_ENCRYPTION_SALT = process.env.REACT_APP_ENCRYPTION_SALT


class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Files: [],
      File: "",
      setFile: "",
      UserName: "",
      Email: "",
      Password: "",
      ConfirmPassword: "",
      Code: "",
      Open: false,
      User: [],
      User_Role: [],
      User_Web_Role: [],
      Submitted: false,
      showPassword: false,
      OpenTermsConditionPopup: false,
      SelectedLang: [{ value: "en", label: "English" }],
      Errors:{},
      //PreviousLang: [],
      //_Lang: 'en',
      IsPsswordUpdated:false,
      UserID:null,
      FormData:{
        Password:"",
        ConfirmPassword:""
      },
      errors:{},
      DisplayIsPasswordUpdated:false


    };
    this.CancleForgot = this.CancleForgot.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose() {
    this.setState({ Open: false });
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  _Lang = (newLang) => {
    const { value, label } = newLang;
    //this.setState({ _Lang: value });
    //this.setState({ PreviousLang: this.state.SelectedLang });
    this.setState({ SelectedLang: newLang });
    //this.OpenTermsConditionModal();
    i18n.changeLanguage(value);
  };
  async componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const Code = queryParams.get("code");
    const TEmail = queryParams.get('email');
    const Email = TEmail.replace(' ','+');
    const ReCode = Code.split(' ').join('+');
    this.setState({ Code: ReCode });
    this.setState({ Email: Email });
    var InputParameters = {
      ForgotToken: ReCode,
      Email:Email
    };
 
    Axios({
      url: CommonConstants.MOL_APIURL + "/login/findUserCode",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      
      
      if (res.data.StatusMessage == "SUCCESS") {
        this.setState({IsPsswordUpdated:res.data.data[0]?.IsPsswordUpdated})
        this.setState({UserID:res.data.data[0]?._id})
        var lastupdate;
        var UserName;
        
        res.data.data.map((u_data) => {
          lastupdate = u_data.LastPasswordUpdateDate;
          UserName = u_data.Username;
        });
        this.setState({ Email: UserName });
      }
    });
  }

  //cancle
  CancleForgot() {
    window.location = CommonConstants.home;
  }

   // Function to validate individual form field
   ValidateField = (fieldName, value) => {
    let isValid = true;
  
    this.setState((prevState) => {
      const newErrors = { ...prevState.errors };
  
      if (fieldName === "Password") {
        newErrors.Password = "";
        if (value.trim() === "") {
          isValid = false;
          newErrors.Password = "Please enter password.";
        } else if (value.length < 6 || value.length > 20) {
          isValid = false;
          newErrors.Password = "The password must be between 6 and 20 characters long.";
        } else {
          isValid = true; // Password is valid
        }
      }
  
      if (fieldName === 'ConfirmPassword') {
        newErrors.ConfirmPassword = "";
        if (value.trim() === "") {
          isValid = false;
          newErrors.ConfirmPassword = "Please enter confirm password.";
        } else if (value.trim() !== prevState.FormData.Password) {
          isValid = false;
          newErrors.ConfirmPassword = "Password and Confirmation Password must match.";
        } else {
          isValid = true; // ConfirmPassword matches Password
        }
      }
  
      return { errors: newErrors };
    });
  
    return isValid;
  };
  

    ValidateAllFields = () => {
      const { FormData } = this.state;
      const fieldNames = Object.keys(FormData);
      fieldNames.forEach((fieldName) => {
        const fieldIsValid = this.ValidateField(fieldName, FormData[fieldName]);
      });
    };
  
  //Update OnChange Handler
  HandleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    this.setState((prevState) => {
      let updatedFormData = { ...prevState.FormData };
        updatedFormData[name] = value;
      this.ValidateField(name, value);
      return {
        FormData: updatedFormData,
      };
    });
  };

  //Update OnBlur Handler
  HandleBlur = (e) => {
    const { name, value } = e.target;
    this.ValidateField(name, value);
  };

  //Validation field method
  // ValidateMethod = (fieldName,value) => {
  //   let Flag = true;
  //  if (fieldName === "Password") {
  //     if (value.trim() === "") {
  //       Flag = false;
  //     } else if (value.length < 6 || value.length > 20) {
  //       Flag = false;
  //     }else{
  //       Flag = true;

  //     }
  //  }
  //  if(fieldName === "ConfirmPassword"){
  //   if (value.trim() === "") {
  //     Flag = false;
  //   } else if (value !== this.state.FormData.ConfirmPassword) {
  //     Flag = false;
  //   }else{
  //     Flag = true;

  //   }
  //  }
  //   return Flag;
  // }
  
  //Update User Profile Data
  HandleSubmit = async (e) => { 
    e.preventDefault();
    this.setState({ButtonDisabled: true });
    const { FormData } = this.state;

   
    const fieldNames = Object.keys(FormData);
    let isValid;
    let arr = []
    this.ValidateAllFields()
    // // Iterate through fields and validate
    // fieldNames.forEach(async(fieldName) => {

    //   arr.push(this.ValidateMethod(fieldName, FormData[fieldName]))
    // });
    //  isValid = arr.includes(false)
  let ConfirmPasswordAndPassWordFlag;
  let CPWD = document.getElementById("ConfirmPassword").value;
  let PWD = document.getElementById("Password").value;
if(CPWD && PWD && (CPWD.length >= 6 && CPWD.length <= 20) && (PWD.length >= 6 && PWD.length <= 20)){
{
  if(CPWD === PWD){
    ConfirmPasswordAndPassWordFlag = true
  }else if(CPWD !== PWD){
    ConfirmPasswordAndPassWordFlag = false 
  }
}
}
    // Check if isValid is still true
    if (ConfirmPasswordAndPassWordFlag && this.state.IsPsswordUpdated === false) {
            var f_Password = CryptoJS.AES.encrypt(FormData.Password,REACT_APP_ENCRYPTION_SALT).toString();
            var str_in = {
              UserID: this.state.UserID,
              Password: f_Password,
              LastPasswordUpdateDate:new Date(),
              IsPsswordUpdated:true
            };
           
            Axios({
              url: CommonConstants.MOL_APIURL + "/login/Passwordupdate",
              method: "POST",
              data: str_in,
            }).then(async(res) => {
              if (res.data.StatusMessage == "SUCCESS") {
                  var emailData = EmailTemplete.userPasswordUpdate();
                  try {
                    // Use await to wait for the SendEmail function to complete
                    let IsMailSend = await this.SendEmail(this.state.Email, emailData, "Password Update");
                    
                    history.push('/')
                    
                } catch (error) {
                    console.error("Error sending email:", error);
                }
                }
            }); 
    } else if(ConfirmPasswordAndPassWordFlag){
       this.setState({DisplayIsPasswordUpdated:false})
      this.setState({ ButtonDisabled: false });
    }else{
      // this.setState({DisplayIsPasswordUpdated:true});
      // this.setState({DisplayUserAddedMessage:false});
      this.setState({ ButtonDisabled: false });
    }
  };

   //Email Send method
   SendEmail = (Username, EmailTemplate, Subject) => {
    return new Promise((resolve, reject) => {
        const InputParameters = {
            email: Username,
            html: EmailTemplate,
            subject: Subject,
        };
        Axios({
            url: CommonConstants.MOL_APIURL + "/user/emailsend",
            method: "POST",
            data: InputParameters,
        })
        .then((res) => { 
            if (res.data.StatusMessage === "SUCCESS") {
                resolve(true); // Resolve the promise with true
            } else {
                resolve(false); // Resolve the promise with false
            }
        })
        .catch((error) => {
            console.error("Error sending email:", error);
            reject(error); // Reject the promise in case of an error
        });
    });
  }



  render() {
    const { FormData, errors } = this.state;

    const { showTemplate, showSaveAlert } = this.state;
    const { File, UserName, Password, Submitted, radio, SelectedLang } =
      this.state;
    const customStylesLeg = {
      option: (provided, state) => ({
        ...provided,
        paddingLeft: 20,
        color: state.isSelected ? "#5c0f8b" : "#5c0f8b",
        fontWeight: state.isSelected ? "900" : "400",
      }),
    };
    const mainTtile = CommonConstants.Title;
    document.title = "Reset Password" + mainTtile;
    return (
      <div className="login-main">
        <div className="row h-100">
          <div className="col-xs-12 col-md-6 logo-bgbod padding-btn">
            <img src={logobgs} className="login-vector" />

            <div className="m-login__logo">
              <a href="/">
                <img src={logo} className="main-logo" />
              </a>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 d-flex">
            <div className="full-hegiht">
              <div id="login" className="centerheigh ">
                
                <div className="heading">
                  <h1 className="login_title">Reset Your Password</h1>
                </div>
                {
                  this.state.DisplayIsPasswordUpdated ? <p id="UserExists_e" className="errorinput">
                  {" "}Link is already used.{" "}
                </p> :<></>
                                }
                <form name="login" className="form">
                 
                  <div className="input-control">
                    <label htmlFor="Password" className="input-label" hidden>
                      Password
                    </label>
                    <div className='position-relative d-flex align-items-center'>

                    <input
                      type={this.state.showPassword ? 'text' : 'password'}
                      id="Password"
                      name="Password"
                      className="input-field"
                      placeholder="Password"
                      value={FormData.Password}
                      onChange={this.HandleInputChange}
                      onBlur={this.HandleBlur}
                    />
                       <IconButton
                      disableRipple
                      onClick={this.handleClickShowPassword}
                      className='position-absolute rigterpin'
                    >
                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>

                    </div>
                      {errors.Password && (
                          <div className="errorinput">{errors.Password}</div>
                        )}
                  </div>
                  <div className="input-control">
                    <label htmlFor="Password" className="input-label" hidden>
                      Confirm Password
                    </label>
                    <input
                      type="Password"
                      id="ConfirmPassword"
                      name="ConfirmPassword"
                      className="input-field"
                      placeholder="Confirm Password"
                      value={FormData.ConfirmPassword}
                      onChange={this.HandleInputChange}
                      onBlur={this.HandleBlur}
                    />
                      {errors.ConfirmPassword && (
                          <div className="errorinput">{errors.ConfirmPassword}</div>
                        )}
                  </div>
                  <div className="input-control btn-center">
                    <input
                      type="button"
                      name="Request"
                      onClick={this.HandleSubmit}
                      className="input-submit"
                      value="Request"
                    />
                    <input
                      type="button"
                      name="Cancel"
                      onClick={this.CancleForgot}
                      className="input-submit"
                      value="Cancel"
                    />
                  </div>
                </form>
              </div>

              {/* <div id="err_link">
                <div className="heading">
                  <h1 className="login_title">Reset Your Password</h1>
                </div>
                <div className="input-control">
                  <p>URL link is Expired. So please, Try again.</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  return state;
}

const actionCreators = {};

const connectedLResetPasswordPage = connect(
  mapState,
  actionCreators
)(ResetPasswordPage);
export { connectedLResetPasswordPage as ResetPasswordPage };
