import React, { useState, useEffect } from 'react';
//import { CSVReader, CSVDownloader } from "react-papaparse";
import { toast } from "react-toastify";
import Axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LastdaysTabing from '../Kpis/LastdaysTabing';
import Swal from 'sweetalert2';
import ReactApexchart from "react-apexcharts";

import CampaignsTable from './CampaignsTable';
import ResponsesTable from './ResponsesTable';  
 
import ImageUploading from 'react-images-uploading';
import Popup from "reactjs-popup";

import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.css';

import 'froala-editor/js/plugins.pkgd.min.js';
import Froalaeditor from 'froala-editor'
import FroalaEditor from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility"; 
import axios from 'axios';
import { history } from '../../../_helpers';
import contactlistloadingicon from "../../../images/loading.gif";

import EmailReporting from "./EmailReporting";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function SimpleTabs({ FromDates, ToDates, IsAnalyze }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [popupval,setpopup] = React.useState(false);
  const [GlobalTemplate, SetGlobalTemplate] = React.useState(false);
  const [ClientTemplate, SetClientTemplate] = React.useState(false);
  const [AddPopup, SetAddPopup] = React.useState(false);
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [Role, setRole] = React.useState("");
  const [ClientTemplateData, SetClientTemplateData] = React.useState([]);
  const [GlobalTemplateData, SetGlobalTemplateData] = React.useState([]);
  const [CampaignTemplateID, SetCampaignTemplateID] = React.useState("");
  const [ExportData, SetExportData] = React.useState([]);
  const [ClientName,SetClientName]=React.useState("");
  const [Archive, SetArchive] = React.useState(false);
  const [Search, SetSearch] = React.useState("");
  const [CUserID,SetCUserID] = React.useState(0)
  const [IsloadingEss,SetIsloadingEss] = React.useState(false);
  const [Isloading,SetIsloading] = React.useState(false);
  const [ChartDate, SetChartDate] = React.useState([]);
  const [ChartCountData, SetChartCountData] = React.useState([]);
  const [ToDateContact, SetToDateContact] = React.useState(ToDates);
  const [FromDateContact, SetFromDateContact] = React.useState(FromDates);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  useEffect(() => {
    document.title = "Campaign | SalesHive";
    var getaccountcatelist = GetUserDetails();
    if (getaccountcatelist != null) {
      setClientID(getaccountcatelist.ClientID);
      setUserID(getaccountcatelist.ParentUserID);
      SetCUserID(getaccountcatelist.ChildUserID);
      setRole(getaccountcatelist.Role);
      ChartDataByClientID(getaccountcatelist.ClientID,getaccountcatelist.ParentUserID,getaccountcatelist.Role);
    }
  }, [Search,Archive,ExportData,ClientName]);

  const ChartDataByClientID = async (CID,UID,URole) => {
    SetIsloading(true);
    var Str_In = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
    }
    const rows2 = await Axios({
      url:CommonConstants.MOL_APIURL + "/campaign/CampaignSendChartData",
      method: "POST",
      data: Str_In,
    });
    if (rows2.data.StatusMessage === "SUCCESS") {
      SetChartDate(rows2.data.Data.Date);
      SetChartCountData(rows2.data.Data.Count);
      SetIsloading(false);
    }  
  }

  const ExportCampaignList = async (CID,UID,URole) => {
    
    var str_in = {
      page: 1,
      rowsPerPage: 15,
      sort: true,
      field: "Name",
      sortby: 1,
      search: Search,
      type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      Archive:Archive
    };
    const rows1 = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/campaign/CampaignExport",
      method: "POST",
      data: str_in,
    });
    if(rows1.data.StatusMessage === "SUCCESS"){
      return rows1.data;
    }
  };
  const UpdateFromChild = async (value,Search) =>{
    
    SetArchive(value);
    SetSearch(Search);
  }
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  }; 
  const openTermsConditionPopup = () => {
    //console.log("--it's true");
    setpopup(true);
  };

  const closeTermsConditionModal = () => {
    //console.log("--it's false");
    setpopup(false);
  };

  const SubmitTermsConditionModal = () => {
    setpopup(false);
  };


  document.addEventListener('mouseup', function(e) {
      console.log("--popup value--");
      //console.log(popupval);
      if(popupval){
        setpopup(false);
      }
  });
    //const openTermsConditionPopup = false;

    const AddPopupHandle = async () =>{
      if(AddPopup === true){
        SetAddPopup(false);
      }else{
        SetAddPopup(true);
      }
    }  

  const GlobalTemplateHandle = async () =>{
    if(GlobalTemplate === true){
      //document.getElementById(CampaignTemplateID).style.border = "none";
      SetCampaignTemplateID("");
      SetGlobalTemplate(false)
    }else{
      SetGlobalTemplate(true)
      GetCampaignTemplateList("Global")
    }
  }
  
  const ClientTemplateHandle = async () =>{
    if(ClientTemplate === true){
      SetClientTemplate(false)
    }else{
      SetClientTemplate(true)
      GetCampaignTemplateList("Client")
    }
  }

  const GetCampaignTemplateList = async (Page) =>{
    SetIsloadingEss(true)
    var StrIn = {
      ClientID:ClientID,
      UserID:UserID,
      Role:Role,
      TemplateType:Page
    }
    var ClientCampaignTemplate = await axios({
      url:
        CommonConstants.MOL_APIURL + "/campaigntemplate/GetCampaignTemplate",
      method: "POST",
      data: StrIn,
    });
    console.log(ClientCampaignTemplate, "--->Data")
    if(ClientCampaignTemplate.data.StatusMessage === "SUCCESS"){
      SetGlobalTemplateData(ClientCampaignTemplate.data.Data)
      SetIsloadingEss(false)
    }
    SetIsloadingEss(false)
  }
  const DeleteTemplate =  (CampaignTemplateID,Page) =>{
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a campaign global template.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText:"No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        SetIsloadingEss(true)
        var StrIn = {
          CampaignTemplateID:CampaignTemplateID,
          ClientID:ClientID,
          UserID:CUserID,
          Role:Role
        } 
        axios({
          url:
            CommonConstants.MOL_APIURL + "/campaigntemplate/DeleteCampaignTemplate",
          method: "POST",
          data: StrIn,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Campaign global template deleted successfully.",
                "success"
              );
              GetCampaignTemplateList(Page)
              SetCampaignTemplateID("")
              SetIsloadingEss(false)
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
    SetIsloadingEss(false)
  };
  // const DeleteTemplate = async (CampaignTemplateID,Page) =>{
  //   console.log(CampaignTemplateID)
  //   var StrIn = {
  //     CampaignTemplateID:CampaignTemplateID,
  //     ClientID:ClientID,
  //     UserID:UserID,
  //     Role:Role
  //   }
  //   var ClientCampaignTemplate = await axios({
  //     url:
  //       CommonConstants.MOL_APIURL + "/campaigntemplate/DeleteCampaignTemplate",
  //     method: "POST",
  //     data: StrIn,
  //   });
  //   console.log(ClientCampaignTemplate, "--->Data")
  //   if(ClientCampaignTemplate.data.StatusMessage === "SUCCESS"){
      
  //       GetCampaignTemplateList(Page)
  //       SetCampaignTemplateID("")
  //   }
  // }

  const SelectedCampaignTemplate = async (TemplateID) => {
    
    console.log(TemplateID)
    if(CampaignTemplateID === ""){
      document.getElementById(TemplateID).style.border = "1px solid skyblue";
      SetCampaignTemplateID(TemplateID)
    }else{
    document.getElementById(TemplateID).style.border = "1px solid skyblue";
    document.getElementById(CampaignTemplateID).style.border = "none";
    SetCampaignTemplateID(TemplateID)
    }
  }

  const GlobalTemplateCreate = async () =>{
    if(CampaignTemplateID != ""){
      console.log(CampaignTemplateID)
      history.push({ pathname: "/addCampaigns", state: { data: CampaignTemplateID } });
    }else{
      toast.error("Please select at least one global template from list.");

    }
  }
  const TemplateCreate = async () =>{
    if(CampaignTemplateID != ""){
      console.log(CampaignTemplateID)
      history.push({ pathname: "/addCampaigns", state: { data: CampaignTemplateID } });
    }else{
      toast.error("Please select at least one template from list.");

    }
  }


  //Export CSV
const ExportCsv=async()=>{
  try {
    SetIsloadingEss(true)
    const result = await ExportCampaignList(ClientID, UserID, Role);
    if (result.StatusMessage === "SUCCESS") {
      const data = result.Data;

      if (data.length > 0) {
        SetExportData(data);
        SetClientName()
        const downloader = document.createElement("a");
        const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(convertToCSV(data));
        downloader.setAttribute("href", csvContent);
        downloader.setAttribute("download", result.ClientName.Name+"-Campaign.csv");
        downloader.click();
        toast.success(<div>Campaign <br/>Data exported successfully.</div>);
        SetIsloadingEss(false)
      } else {
        toast.error("No data available for export.");
        SetIsloadingEss(false)
      }
    } else {
      toast.error("Failed to export data. Please try again.");
      SetIsloadingEss(false)
    }
  } catch (error) {
    console.error("ExportCsv error:", error);
    toast.error("An error occurred while exporting data.");
  }
}

const state = {
  series: [
    {
      name: "Sent",
      color: "#360947",
      data: ChartCountData,
    }
  ],
  options: {
    chart: {
      type: "line",
      height: 450,
      width:600,
      toolbar: {
        show: false,  
      } 
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [2, 3, 4, 2],
      curve: 'smooth'
    },
    title: {
      text: 'Emails Sent Per Day (Last 90 Days)',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    xaxis: {
      title: {
        text: "Date",
      },
      categories: ChartDate,
    },
    yaxis: {
      title: {
        text: "Count",
      },
    },
    markers: {
      size: 4,
      strokeWidth: 1.5,
      hover: {
        size: 6 // Size of the marker when hovered
      }
    }
  },
};

const convertToCSV = (data) => {
  // Convert array of objects to CSV string
  const header = Object.keys(data[0]).join(",");
  const body = data.map((row) => Object.values(row).join(",")).join("\n");
  return `${header}\n${body}`;
};

  return (<>
            {IsloadingEss ? <div id="hideloding" className="loding-display">
        <img src={contactlistloadingicon} />
      </div> : null} 
      <div> 
        {/* <AppBar position="static" className=''>
          <Tabs value={value} onChange={handleChange} variant="scrollable" aria-label="simple tabs example"> 
            {/* <Tab label="Campaigns" {...a11yProps(0)}>Overviewasdasd</Tab> 
            {/* <Tab className='mx-1' label="Responses" {...a11yProps(1)}  /> 
          </Tabs>
        </AppBar> */}  
        <div className='bg-white p-3'>
      <div className="row">
          <div class="col-xl-12">
          {
              Isloading === true ? <div id="hideloding" className="loadingchart">
                <img src={contactlistloadingicon} />
              </div> : null
            }
            <div className="w-100 relative">
              <div id="chart chart-max450">
                <ReactApexchart
                  options={state.options}
                  series={state.series}
                  type="line"
                  height={450}
                />
              </div>
            </div>
          </div>
        </div> 
        </div>
        <div className="whiterow px-3 my-4">
            <div className="row">
              <div className="col px-0">
                <EmailReporting FromDate={FromDates} ToDate={ToDates} IsAnalyze={IsAnalyze}/>
              </div>
            </div>
          </div>
          <div className="whiterow px-3 mt-4 pb-2">
        <div className='row'>
            <div class="col-md-6">
              <h4 class="xs-headertitle pl-0 py-3">Managed Campaigns </h4>
            </div> 
            <div className="col-md-6">
                  <div className="listing-li float-right">
                      <ul>
                        <li onClick={AddPopupHandle}>
                        <a className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                <i class="la la-plus la-icon-font-size-13"></i>
                                <span>Add</span>
                            </a> 
                          <Popup open={AddPopup}>
                              <div>
                            <div className="modal-black"></div>
                              <div className="filterPopup maxpopupfull">
                                <div className="paddingboxTerms">
                                  <div className="modal-header py-4 px-3">
                                    <div className='w-100 d-flex px-3'>
                                        <h5 className="mb-0">Create a campaign</h5>
                                        <button className="close" onClick={AddPopupHandle}>
                                          <span aria-hidden="true" class="la la-remove"></span>
                                        </button>
                                    </div> 
                                  </div> 
                                  <div className="modal-body"> 
                                    <div className="px-4">    
                                      <div className="row padt-25">
                                          <div className='col-lg-4 text-center clercolum'>
                                            <i class="flaticon-list clr-purple f-50"></i>
                                              <button class="btn btn-md btngroup mt-2 mb-3" onClick={GlobalTemplateHandle}>
                                                      From global template
                                                    </button> 
                                            <Popup open={GlobalTemplate}>
                                                      <div>
                                                    <div className="modal-black"></div>
                                                      <div className="filterPopup maxpopupfull">
                                                        <div className="modal-header py-4 px-3">
                                                          <div className='w-100 d-flex px-3'>
                                                              <h5 className="mb-0">Select a campaign global template</h5>
                                                              <button className="close" onClick={GlobalTemplateHandle}>
                                                                <span aria-hidden="true" class="la la-remove"></span>
                                                              </button>
                                                          </div> 
                                                        </div> 

                                                        <div className="paddingboxTerms"> 
                                                          <div className="modal-body cardtempls"> 
                                                          {GlobalTemplateData?.map((row)=>{
                                                               return (
                                                                <a onClick={()=>SelectedCampaignTemplate(row._id)}>
                                                                <div className='row'>
                                                               <div id = {row._id} className='card col-lg-12 mb-3'>
                                                                 <div className=''>
                                                                     <h5>{row.Name}</h5>
                                                                     <a onClick={()=>{DeleteTemplate(row._id,"Global")}} class="rightdeleted" title="Delete">
                                                                       <i class="la flaticon-delete-1 delete-icon"></i>
                                                                     </a>
                                                                   </div>
                                                                   <p>{row.Notes}</p>
                                                               </div>
                                                             </div>
                                                             </a>
                                                               )
                                                            })}
                                                              

                                                          </div>   
                                                        </div>

                                                        <div class="modal-footer">
                                                          <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={GlobalTemplateHandle}>Cancel</button>
                                                            <button id="lnkCreateCampaignFromTemplate" class="btn btn-primary btngroup" onClick={GlobalTemplateCreate}><i class="la la-save"></i> Create</button>
                                                        </div>

                                                      </div>
                                                    </div>
                                                </Popup>

                                            <p>Create a campaign from a global template.</p>
                                          </div>
                                          <div className='col-lg-4 text-center clercolum'>
                                            <i class="flaticon-list clr-purple f-50"></i>
                                            <button class="btn btn-md btngroup mt-2 mb-3" onClick={ClientTemplateHandle}>
                                                      From template
                                                    </button> 
                                            <Popup open={ClientTemplate}>
                                                      <div>
                                                    <div className="modal-black"></div>
                                                      <div className="filterPopup maxpopupfull">
                                                        <div className="modal-header py-4 px-3">
                                                          <div className='w-100 d-flex px-3'>
                                                              <h5 className="mb-0">Select a campaign form template</h5>
                                                              <button className="close" onClick={ClientTemplateHandle}>
                                                                <span aria-hidden="true" class="la la-remove"></span>
                                                              </button>
                                                          </div> 
                                                        </div> 

                                                        <div className="paddingboxTerms"> 
                                                          <div className="modal-body cardtempls">
                                                            {GlobalTemplateData?.map((row)=>{
                                                               return (
                                                                <a onClick={()=>SelectedCampaignTemplate(row._id)}>
                                                                <div className='row'>
                                                               <div id = {row._id} className='card col-lg-12 mb-3'>
                                                                 <div className=''>
                                                                     <h5>{row.Name}</h5>
                                                                     <a onClick={()=>{DeleteTemplate(row._id,"Client")}} class="rightdeleted" title="Delete">
                                                                       <i class="la flaticon-delete-1 delete-icon"></i>
                                                                     </a>
                                                                   </div>
                                                                   <p>{row.Notes}</p>
                                                               </div>
                                                             </div>
                                                             </a>
                                                               )
                                                            })}
                                                             

                                                              {/* <div className='row'>
                                                                <div className='card col-lg-12 mb-3'>
                                                                  <div className=''>
                                                                      <h5>09-10-Dev Test - Template</h5>
                                                                      <a href="javascript:void(0);" class="rightdeleted" title="Delete">
                                                                        <i class="la flaticon-delete-1 delete-icon"></i>
                                                                      </a>
                                                                    </div>
                                                                    <p>Testing purpose</p>
                                                                </div>
                                                              </div>

                                                              <div className='row'>
                                                                <div className='card col-lg-12 mb-3'>
                                                                  <div className=''>
                                                                      <h5>09-10-Dev Test - Template</h5>
                                                                      <a href="javascript:void(0);" class="rightdeleted" title="Delete">
                                                                        <i class="la flaticon-delete-1 delete-icon"></i>
                                                                      </a>
                                                                    </div>
                                                                    <p>Testing purpose</p>
                                                                </div>
                                                              </div>                                                               */}

                                                          </div>   
                                                        </div>

                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-secondary" onClick={ClientTemplateHandle} data-dismiss="modal">Cancel</button>
                                                            <a id="lnkCreateCampaignFromTemplate" onClick={TemplateCreate} class="btn btn-primary btngroup"><i class="la la-save"></i> Create</a>
                                                        </div>
                                                        
                                                      </div>
                                                    </div>
                                                </Popup>
                                            <p>Create a campaign from a template.</p>
                                          </div>
                                          <div className='col-lg-4 text-center clercolum'>
                                            <i class="flaticon-file-1 clr-purple f-50"></i>
                                            <a href='/addCampaigns' class="btn btn-md btngroup mt-2 mb-3">
                                            From scratch
                                            </a>
                                            <p>Create a campaign from the scratch. Specify steps, emails templates, and add people.</p>
                                          </div>
                                      </div> 
 
                                    </div> 
                                  </div>   
                                </div>
                              </div>
                            </div>
                        </Popup>

                          </li>
                          <li className='pr-0'>
                          <a onClick={ExportCsv} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                              <i class="la la-icon-font-size-13 la-download"></i>
                              <span>Export</span>
                          </a>
                          </li>
                      </ul>
                  </div>
              </div>
        </div>                                                      
        <div className="row">
          <div className="col"> 
              <CampaignsTable updateChild={UpdateFromChild}/> 
          </div>
        </div>  
        {/* <TabPanel value={value} className="tagpaddnone pxinnerno bg-white" index={1}> 
            <div className="row">
            <div className="col"> 
                <ResponsesTable /> 
            </div>
          </div> 
        </TabPanel> */}
 </div>
      </div> 
      </>
  );
}