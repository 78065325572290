import React from "react";
import { connect } from "react-redux";
import Axios from "axios";

import { history } from "../../_helpers";
import { userActions } from "../../_actions";
import { GetUserDetails } from "../../_helpers/Utility";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { CommonConstants } from "../../_constants/common.constants";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import GenericEmailDomain from "../../_components/user/GenericeMailDomain/GenericEmailDomainTable";
import { GetClientDetails } from "../../_helpers/Utility";

class GenericEmailDomainPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      FileName: "",
      ExportData: [],
      CName: "",
    };
    this.AddGenericEmailDomain = this.AddGenericEmailDomain.bind(this);
  }

  componentDidMount() {
    
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
    }

    GetClientDetails(Details.ClientID).then((result) => {
      this.setState({ CName: result[0].Name });
    });

    var InputParameter = {
      UserID: Details.ParentUserID,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/genericemaildomain/GenericEmailDomainExport",
      method: "POST",
      data: InputParameter,
    }).then((Res) => {
      this.setState({ ExportData: Res.data });
    });
  }

  //Add function
  AddGenericEmailDomain() {
    history.push("/addgenericemaildomain");
  }
  //Reload page while delete perform
  UpdateFromChild = (value) => {
    if (value == true) {
      this.componentDidMount();
    }
  };
  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitle lg-headertitle float-left">Generic Email Domain</h4>
                <div className="listing-li float-right padb-15"> 
                  <ul>
                    <li>
                      <a
                        onClick={this.AddGenericEmailDomain}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i><span>Add</span>
                      </a>
                    </li>
                    <li>
                    <CSVDownloader className="px-0"
                          data={this.state.ExportData}
                          filename={this.state.CName + " Genaric-Domain"}
                          bom={true}
                        >
                      <a className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                        <i class="la la-icon-font-size-13 la-download"></i>&nbsp;Export
                      </a>
                      </CSVDownloader>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="">
              <div className="row">
                <div className="col">
                  <GenericEmailDomain updateFromChild={this.UpdateFromChild} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedGenericEmailDomainPage = connect(
  mapState,
  actionCreators
)(GenericEmailDomainPage);
export { connectedGenericEmailDomainPage as GenericEmailDomainPage };
