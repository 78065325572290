import React, { useEffect, useState } from 'react';
import 'bootstrap-datetime-picker/js/bootstrap-datetimepicker.js'; // Import datetime picker JS
import 'bootstrap-datetime-picker/css/bootstrap-datetimepicker.css'; // Import datetime picker CSS
import Axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
import Popup from "reactjs-popup";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@material-ui/core/Box';
import DialSessionSetting from "./DialSessionSetting1";
import ReactDOM from 'react-dom';
//import './Twilio/twilio.css';
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});





export default function TwilioPage() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [AllColdCallingCondetail, SetAllColdCallingContacts] = useState({})
  const [AddBurnerModel, SetAddBurnerModel] = useState(false)
  const [checkeddata, setChecked] = React.useState({ "IsMobilePhone": false, "IsCompanyPhone": false, "IsDirectPhone": false, "IsOtherPhone1": false, "IsOtherPhone2": false });
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [CUserID, SetCUserID] = React.useState(0);
  const [ColdCallingContactsDetails, SetColdCallingContactsDetails] = useState([]);
  useEffect(() => {
    const userDetails = GetUserDetails();
    if (userDetails) {
      const { ClientID, ParentUserID, ChildUserID, Role, Username } = userDetails;
      SetClientID(ClientID);
      SetUserID(ParentUserID);
      SetCUserID(ChildUserID);
    }

    SetRows(
      [
        {
          ID: "1",
          Name: "John Doe",
          Title: "Software Engineer",
          MobilePhone: "+18884431789",
          DirectPhone: "+919974345795",
          CompanyPhone: "+919033960996",
          OtherPhone1: "123-456-7888",
          OtherPhone2: "123-456-7877",
          Company: "Tech Solutions Inc.",
          LastCallResult: "Successful",
          LastCallDate: "2024-08-25",
          Email: "john.doe@example.com",
          CallNotes:"-- 09/10/2024 @ 7:07 AM CDT by Brittany Campbell - re:fab -- Email sent: Marilyn meeting with re:fab?-- 09/10/2024 @ 7:07 AM CDT by Brittany Campbell - re:fab -- (802) 863-1055 -- Email Only (No Voicemail)-- 09/05/2024 @ 8:02 AM CDT by Brittany Campbell - re:fab -- (802) 863-1055 -- Voicemail 2-- 09/05/2024 @ 8:02 AM CDT by Brittany Campbell - re:fab -- (802) 863-1055 -- Voicemail left.-- 08/28/2024 @ 8:15 AM CDT by Brittany Campbell - re:fab -- (802) 233-4812 -- Bad Data-- 08/28/2024 @ 8:15 AM CDT by Brittany Campbell - re:fab -- Mobile not in service -- 08/28/2024 @ 8:14 AM CDT by Brittany Campbell - re:fab -- (802) 863-1055 -- Left Voicemail 1"
        },
        {
          ID: "2",
          Name: "Jane Smith",
          Title: "Project Manager",
          MobilePhone: "9974345795",
          DirectPhone: "9974345795",
          CompanyPhone: "9974345795",
          OtherPhone1: "987-654-3222",
          OtherPhone2: "987-654-3233",
          Company: "Innovate Corp.",
          LastCallResult: "Left Voicemail",
          LastCallDate: "2024-08-22",
          Email: "jane.smith@example.com",
          CallNotes:""
        },
        {
          ID: "3",
          Name: "Alice Johnson",
          Title: "Marketing Director",
          MobilePhone: "9974345795",
          DirectPhone: "9974345795",
          CompanyPhone: "9974345795",
          OtherPhone1: "555-123-4565",
          OtherPhone2: "555-123-4564",
          Company: "Marketing Pros LLC",
          LastCallResult: "No Answer",
          LastCallDate: "2024-08-20",
          Email: "alice.johnson@example.com",
          CallNotes:""
        },
        {
          ID: "4",
          Name: "Bob Brown",
          Title: "Sales Manager",
          MobilePhone: "9974345795",
          DirectPhone: "9974345795",
          CompanyPhone: "9974345795",
          OtherPhone1: "444-789-0121",
          OtherPhone2: "444-789-0120",
          Company: "Salesforce Solutions",
          LastCallResult: "Follow-Up Required",
          LastCallDate: "2024-08-18",
          Email: "bob.brown@example.com",
          CallNotes:""
        },
        {
          ID: "5",
          Name: "Emily Davis",
          Title: "HR Specialist",
          MobilePhone: "9974345795",
          DirectPhone: "9974345795",
          CompanyPhone: "9974345795",
          OtherPhone1: "333-654-7888",
          OtherPhone2: "333-654-7887",
          Company: "HR Experts Co.",
          LastCallResult: "Successful",
          LastCallDate: "2024-08-15",
          Email: "emily.davis@example.com",
          CallNotes:""
        }
      ])

  }, []);
  const handleChange = async (event, phoneType) => {
    await setChecked({ ...checkeddata, [phoneType]: event.target.checked });
    // 
  };
  const SelectAll = async () => {
    setSelectedIds(Rows?.map((el) => el?.ID));
    SetColdCallingContactsDetails(Rows)
  }

  //Unselect all records
  const UnSelectAll = () => {
    setSelectedIds([]);
  }
  const handleRowCheckboxChange = async (data) => {
    await SetAllColdCallingContacts(data)
    if (selectedIds.includes(data.ID)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== data.ID));
    } else {
      setSelectedIds([...selectedIds, data.ID]);

    }
  };

  const MultipleCallDial = async () => {
    let Objects = {
      IsCompanyPhone: false, IsDirectPhone: false, IsMobilePhone: false, IsOtherPhone1: false, IsOtherPhone2: false
    }
    setChecked(Objects)
    if (selectedIds.length > 0) {
      SetAddBurnerModel(true)
    }
    else {
      toast.error(<div className="toastsize">Start Dial Session<br />Please select at least one cold calling contact from list.</div>)
    }

  }

  const CallBtn = async () => {
    try {

      if (checkeddata.IsCompanyPhone || checkeddata.IsDirectPhone || checkeddata.IsMobilePhone || checkeddata.IsOtherPhone1 || checkeddata.IsOtherPhone2) {
        let ColdCallingContacts = [];
        ColdCallingContacts.push(AllColdCallingCondetail)
        let InputParameter = {
          UserID: UserID,
          ClientID: ClientID,
          ColdCallerUserID: CUserID,
          AllColdCallingContacts: ColdCallingContactsDetails.length > 0 ? ColdCallingContactsDetails : ColdCallingContacts,
        };
        const response = Axios({
          url: CommonConstants.MOL_APIURL + "/twilio/GetTwilioToken",
          method: "POST",
          data: InputParameter,
        });
        response.then((Result) => {
          
          if (Result.data.StatusMessage == "SUCCESS") {
            var DialSessionData = Result.data
            const windowFeatures = 'width=1000,height=1000,resizable,scrollbars';
            const newWindow = window.open('', '_blank', windowFeatures);
            if (newWindow) {
              newWindow.document.write('<div id="popup-root"></div>');
              newWindow.document.close();
              const link = newWindow.document.createElement('link');
              link.rel = 'stylesheet';
              link.href = '/assets/css/twilio.css';
              newWindow.document.head.appendChild(link);
              ReactDOM.render(<DialSessionSetting windowObject={newWindow} DialSessionData={DialSessionData} checkeddata={checkeddata} />, newWindow.document.getElementById('popup-root'));
            }
          }
        });

        


      } else {
        toast.error(<div className='toastsize'>Start Dial Session <br />Please select at least one phone number.</div>)
      }
    } catch (error) {

    }
  }
  const handleChange1 = async (event) => {
    await setChecked({ ...checkeddata, ["IsMobilePhone"]: event.target.checked, ["IsCompanyPhone"]: event.target.checked, ["IsDirectPhone"]: event.target.checked, ["IsOtherPhone1"]: event.target.checked, ["IsOtherPhone2"]: event.target.checked });
  };

  const CancelCallBtn = async () => {
    SetAddBurnerModel(false)

    setChecked({ "IsMobilePhone": false, "IsCompanyPhone": false, "IsDirectPhone": false, "IsOtherPhone1": false, "IsOtherPhone2": false });
  }

  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'row', ml: 3, flexWrap: 'wrap' }}>
      <FormControlLabel
        label="Direct Phone"
        control={<Checkbox checked={checkeddata["IsDirectPhone"]} onChange={(e) => handleChange(e, "IsDirectPhone")} />}
      />
      <FormControlLabel sx={{ fontSize: '10px' }}
        label="Mobile Phone"
        control={<Checkbox checked={checkeddata["IsMobilePhone"]} onChange={(e) => handleChange(e, "IsMobilePhone")} />}
      />
      <FormControlLabel
        label="Company Phone"
        control={<Checkbox checked={checkeddata["IsCompanyPhone"]} onChange={(e) => handleChange(e, "IsCompanyPhone")} />}
      />

      <FormControlLabel
        label="Other Phone1"
        control={<Checkbox checked={checkeddata["IsOtherPhone1"]} onChange={(e) => handleChange(e, "IsOtherPhone1")} />}
      />
      <FormControlLabel
        label="Other Phone2"
        control={<Checkbox checked={checkeddata["IsOtherPhone2"]} onChange={(e) => handleChange(e, "IsOtherPhone2")} />}
      />
    </Box>
  );

  return (
    <>

      <Popup open={AddBurnerModel}>
        <div className="modal-black"></div>
        <div className="filterPopup largerPopup">
          <div className="paddingboxTerms">
            <div className="modal-header py-4 px-3">
              <div className="w-100 d-flex px-3">
                <h5 className="mb-0">Phone Number</h5>
                <button className="close"
                  onClick={CancelCallBtn}
                >
                  <span aria-hidden="true" class="la la-remove"></span>
                </button>
              </div>
            </div>
            <div className='p-3'>
              <div className="modal-body">
                <div className='col-sm-12 px-0'>
                  <div class="d-flex mb-3 alllabeset">
                    <FormControlLabel
                      label="All"
                      control={
                        <Checkbox
                          checked={checkeddata["IsMobilePhone"] && checkeddata["IsCompanyPhone"] && checkeddata["IsDirectPhone"] && checkeddata["IsOtherPhone1"] && checkeddata["IsOtherPhone2"]}
                          onChange={handleChange1}
                        />
                      }
                    />
                    {children}

                  </div>
                </div>
              </div>
              <div className="border-top modal-content text-right">
                <div class="row">
                  <div class="col-lg-12 pull-right">

                    <a id="backtolist" class="btn btn-secondary mr-2"
                      onClick={CancelCallBtn}
                    >
                      Cancel
                    </a>
                    <a id="submit"
                      onClick={() => { CallBtn() }}
                      class="btn btn-primary btn-lightgreen mr-1">
                      call
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>

      <div className="row align-items-center py-1">
        <div class="col-md-4">
          <h4 class="lg-headertitle px-0 my-0">GVM Technologies LLP</h4>
        </div>
        <div className="col-md-8">

          <div className="listing-li float-right padb-15 iconbox-pad">
            <ul>
              <li><Tooltip title="Call">
                <a onClick={() => { MultipleCallDial() }} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                  <i class="la-icon-font-size-13 fa fa-phone-square"></i>
                </a>
              </Tooltip>
              </li>


            </ul>
          </div>
        </div>
      </div>

      <div className='bg-border-box p-3'>

        <div className="row">
          <div className="col">
            <div class="paddcols pt-0">


              <div className="row mb-2">
                <div className="col-sm-12 col-md-6"></div>
                <div className="col-sm-12 col-md-12 full-right">
                  {

                    selectedIds.length === 0 ? <a onClick={SelectAll} className='select-all'>Select All</a> : <a onClick={UnSelectAll} className='select-all'>Unselect All</a>

                  }

                </div>


              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <label className="textlabelte">
                    Show
                    <select
                      name="tbl_meeting_length"
                      aria-controls="tbl_meeting"
                      className="form-control form-control-sm"
                      value={RowsPerPage}

                    >
                      {CommonConstants.show_rows.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                  <label className="textlabelte">
                    Search:{" "}
                    <input
                      type="search"
                      id="search"
                      className="form-control form-control-sm ml-2"
                      placeholder=""
                      aria-controls="tbl_meeting"
                    />
                  </label>
                </div>
              </div>
              <div className="table-bordered">
                <TableContainer component={Paper}>

                  <Table className="table-ref" aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell><input name="select_all" type="checkbox" /></TableCell>
                        <TableCell>Name  </TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Mobile Phone</TableCell>
                        <TableCell>Direct Phone</TableCell>
                        <TableCell>Company Phone</TableCell>
                        <TableCell >Other Phone 1</TableCell>
                        <TableCell>Other Phone 2</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Last Call Result</TableCell>
                        <TableCell >Last Call Date</TableCell>
                        <TableCell >Email</TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Rows.length === 0 ? (<p className="text-center">No data available in table</p>) : (
                        Rows.map((row) => (
                          <>
                            <TableRow key={row.ID} row={row} >

                              <TableCell><input name="select" type='checkbox' checked={selectedIds.includes(row.ID)} onChange={() => handleRowCheckboxChange(row)} /></TableCell>
                              <TableCell>{row.Name}</TableCell>
                              <TableCell>{row.Title}</TableCell>
                              <TableCell>{row.MobilePhone}</TableCell>
                              <TableCell>{row.DirectPhone}</TableCell>
                              <TableCell>{row.CompanyPhone}</TableCell>
                              <TableCell>{row.OtherPhone1}</TableCell>
                              <TableCell>{row.OtherPhone2}</TableCell>
                              <TableCell>{row.Company}</TableCell>
                              <TableCell>{row.LastCallResult}</TableCell>
                              <TableCell>{row.LastCallDate1}</TableCell>
                              <TableCell>{row.Email}</TableCell>

                            </TableRow>
                          </>
                        )))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              <div className="row">
                <div className="col dataTables_info">
                  <p>
                    Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                    {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                    {Rlen} entries
                  </p>
                </div>
                <div className="col pageright">
                  <Pagination
                    component="div"
                    count={CountPage}
                    //onChange={HandleChangePage}
                    page={Page}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}
