import React, { useEffect, useState } from "react";
import Axios from "axios";
import Popupone from "reactjs-popup";
let CryptoJS = require("crypto-js");
import Popup from "reactjs-popup";
import Swal from "sweetalert2";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

import { CommonConstants } from "../../../_constants/common.constants";
const EmailTemplete = require("../../../_helpers/email-templete");
import loadingicon from "../../../images/loading.gif";
import{GetUserDetails} from '../../../_helpers/Utility'
import StyleHeader from "../StickyHeader/StickyHeader";

import Pagenations from "../../Pagenations";
const REACT_APP_ENCRYPTION_SALT =  process.env.REACT_APP_ENCRYPTION_SALT;

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';


const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable({listupdate}) {


  const [Search, SetSearch] = React.useState("");
  const [ClientID, SetClientID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Flen, SetFlen] = useState(0);
  const [Sflag, SetSflag] = useState(false);
  const [Rlen, SetRlen] = useState(0);
  const [CountPage, SetCountPage] = useState(0);
  const [Rows, SetRows] = useState([]);
  const [Page, SetPage] = useState(1);
  const [RowsPerPage, SetRowsPerPage] = useState(100);
  const [Email, setEmail] = useState("");
  const [InvitePopup, SetInvitePopup] = useState(false);
  const [RID, SetRID] = useState("");
  const [UserID, SetUserID] = useState(null);
  const [CUserID, SetCUserID] = useState(null);
  const [SortField, SetSortField] = React.useState("Email");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [sortedColumn, setSortedColumn] = React.useState(null);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    document.title="Sales Strategists | SalesHive";
    let GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    SalesStrategistInviteGet(GetUserData.ClientID,GetUserData.ParentUserID,GetUserData.Role);
  }, [Search, Page, RowsPerPage,listupdate]);

  //get Invite Sales Strategist list
  const SalesStrategistInviteGet = (CID,UID,URole) => {
    SetIsLoading(true)
    let InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const AdminListAPI = Axios({
      url:
        CommonConstants.MOL_APIURL +  "/sales_strategists_profile/SalesStrategistsInviteGet",
      method: "POST",
      data: InputParameter,
    });
    AdminListAPI.then((Result) => {
      
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
      setIsDataLoaded(true);

    });
  };

 
  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      let SearchedVal = document.getElementById("Search").value?.trim();
      if(SearchedVal==Search){
        SetSearch(SearchedVal);
        SetPage(1);
      }else{
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if(NewPage==Page){
      SetPage(NewPage);
    }else{
        SetPage(NewPage);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
    SalesStrategistInviteGet();
  };

  //get sort field data
  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("Search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");
    let InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Search:SearchedVal,
      Sort: true,
      Field: Field,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
    };
    const AdminListAPI = Axios({
      url:CommonConstants.MOL_APIURL +"/sales_strategists_profile/SalesStrategistsInviteGet",
      method: "POST",
      data: InputParameter,
    });
    AdminListAPI.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetPage(1)
      SetIsLoading(false)
    });
  };

  //open invite popup
  const OpenReInvitePopup = (Emaildata, ID) => {
    setEmail(Emaildata);
    SetRID(ID);
    SetInvitePopup(true);
  };

  //close invite
  const CloseReInvitePopup = () => {
    SetInvitePopup(false);
    setEmail("");
  };

  //Send email
  const SubmitReInvitePopup = () => {
    let Email = document.getElementById("change_mail").value;
    let re =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    let Emailflg = re.test(Email);
    if (Emailflg == true) {
      let FinalEmail = CryptoJS.AES.encrypt(
        JSON.stringify(Email),
        REACT_APP_ENCRYPTION_SALT
      ).toString();
      let VerificationCodeChar = (Math.random() + 1)
      .toString(36)
      .substring(7);
      let UID = CryptoJS.AES.encrypt(
        JSON.stringify(UserID),
        REACT_APP_ENCRYPTION_SALT
      ).toString();
      
      let VC = CryptoJS.AES.encrypt(
        JSON.stringify(VerificationCodeChar),
        REACT_APP_ENCRYPTION_SALT
      ).toString();
      let EmailData = EmailTemplete.salesStrategistsInvitation(
        FinalEmail,
        UID,
        VC,
        CommonConstants.new_sales_strategists_url
      );
      let EmailCheck12 = {
        ID:RID,
        VerificationCode:VerificationCodeChar
      };
      
      Axios({
        url:CommonConstants.MOL_APIURL +"/sales_strategists_profile/InviteResend",
        method: "POST",
        data: EmailCheck12,
      }).then((res) => {
        
      let str_mail = {
        Email: Email,
        html: EmailData,
        subject: "SalesHive User Account",
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/sales_strategists_profile/EmailSend",
        method: "POST",
        data: str_mail,
      }).then((res) => {
        SetInvitePopup(false);
        SetRID("");
        setEmail("");
        // SetAlertBox(true);
        toast.success(<div className="toastsize">Sales Strategists <br/>
                               Invitation mail sent successfully.</div>)
      });
    });
    } else {
    }
  };

  const Reinvite = () => {
    SetIsLoading(true)
    let Email = document.getElementById("change_mail").value;
    const RequestBody = {
      ID: RID,
      Email: Email,
      UserID:UserID,
      LastUpdatedBy:CUserID,
      LastUpdatedDate:new Date(),
      Navigation:CommonConstants.new_sales_strategists_url
    }
    Axios({
      url:CommonConstants.MOL_APIURL +"/sales_strategists_profile/InviteResend",
      method: "POST",
      data: RequestBody,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
       
        toast.success(
          <div className="toastsize">
            Sales strategists  <br />
            Invitation mail sent successfully.
          </div>
        );
        SetInvitePopup(false);
        SetRID("");
        setEmail("");
        SetIsLoading(false)

      } else {
        toast.error(res.data.Message);
        SetIsLoading(false)
   
      
      }
    });
  }

const DeleteBtn = (ID) => {
  Swal.fire({
    title: "Are you sure?",
    text: "you want to delete a sales strategists invitation.",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#34bfa3",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, cancel!",
    reverseButtons: true,
  }).then((Result) => {
    if (Result.isConfirmed) {
      let data = {
        uID: ID,
        LastUpdatedDate: new Date(),
        LastUpdatedBy: UserID,
        Role: Role,
      };
      Axios({
        url:CommonConstants.MOL_APIURL +"/sales_strategists_profile/InviteDelete",
        method: "POST",
        data: data,
      }).then((Res) => {
        if (Res) {
          if ((Res.statusText = "Ok")) {
            Swal.fire(
              "Deleted!",
              "Sales strategists invitation deleted successfully.",
              "success"
            );
            SalesStrategistInviteGet(ClientID,UserID,Role);
            SetPage(1)
            SetSearch("")
            document.getElementById("Search").value = ""
         
           
          } else {
          }
        }
      });
    } else if (Result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled", "Your data is safe :)", "error");
    }
  });
};

  return (
    <div>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}

      <Popupone open={InvitePopup} modal>
        <div className="modal-black"></div>
        <div className="filterPopup bigpopupcontent">
          <div className="paddingboxTerms">
            <div className="modal-header">
              <h5>Send Mail</h5>
              <a className="close-ion" onClick={CloseReInvitePopup}>
                <span aria-hidden="true" className="la la-remove"></span>
              </a>
            </div>
            <div className="modal-content bordernone">
              <div className="row max-cols">
                <label className="col-lg-4 view-lable-meet">Email</label>
                <div className="col-lg-8">
                  <input
                    className="form-control m-input"
                    data-val="true"
                    data-val-required="Please enter first name"
                    id="change_mail"
                    name="FirstName"
                    placeholder="First name"
                    type="text"
                    value={Email}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={CloseReInvitePopup}
                data-dismiss="modal"
              >
                Cancel
              </button>
              <a
                id="m_EmailInvite_submit"
                onClick={Reinvite}
                href="javascript:void(0);"
                className="btn btn-primary btnColor"
              >
                Send{" "}
              </a>
            </div>
          </div>
        </div>
      </Popupone>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="Search"
              id="Search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <StyleHeader isDataLoaded={isDataLoaded} />

          <Table className="table-ref" aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell
                  onClick={() => {
                    SortData("Email");
                    setSortedColumn("Email");
                  }}
                >
                  Email
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "Email"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "Email"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length == 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow key={row?._id}>
                    <TableCell>{row.Email}</TableCell>
                    <TableCell>
                      <a
                        className="btn btn-sm btn-primary btnColor m-btn whitetext mr-3"
                        onClick={() => {
                          OpenReInvitePopup(row.Email, row._id);
                        }}
                        title="Reinvite"
                      >
                        Reinvite
                      </a>
                      <a
                        onClick={() => {
                          DeleteBtn(row._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i className="la flaticon-delete-1 delete-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Pagenations
        Sflag={Sflag}
        CountPage={CountPage}
        HandleChangePage={HandleChangePage}
        Rlen={Rlen}
        Page={Page}
        RowsPerPage={RowsPerPage}
        Flen={Flen}
      />
    </div>
  );
}
