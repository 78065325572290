import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingis from "../../../images/loading.gif";

import { toast } from "react-toastify";
toast.configure();

class AddContentPipeline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      MeetingEmailAvailable: null,
      BacklinkDropdown: [],
      ClientID: null,
      UserID: null,
      CUserID: null,
      Role: null,
      IsExist: false,
      IsValid: true,
      IsButtonDisabled: false,
      ContentDropdown: [],
      InternallinksStatusDropdown: [],
      ReviewStatusDropdown: [],
      OnPageOptimizationDropdown: [],
      RevisionStatusDropdown: [],
      isLoading: false,
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
    this.dueDateRef = React.createRef();
    this.liveDateRef = React.createRef();
    this.revisionDueDateRef = React.createRef();
  }
  componentDidMount() {
    document.title = `Add Content Calendar | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }

    const $dueDateElement = $(this.dueDateRef.current);
    $dueDateElement.datetimepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      container: "#app",
      todayBtn: true,
      todayHighlight: true,
      minView: "2",
    });

    const $liveDateElement = $(this.liveDateRef.current);
    $liveDateElement.datetimepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      container: "#app",
      todayBtn: true,
      todayHighlight: true,
      minView: "2",
    });

    const $revisionDueDateElement = $(this.revisionDueDateRef.current);
    $revisionDueDateElement.datetimepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      container: "#app",
      todayBtn: true,
      todayHighlight: true,
      minView: "2",
    });

    //Notes
    var EditSample = {
      Role: this.state.Role,
    };
    this.setState({ isLoading: true });
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/ContentPipeline/GetDropDownStatuslist",
      method: "POST",
      data: EditSample,
    })
      .then((res) => {
        this.setState({
          ContentDropdown: res.data.Data || [],
          InternallinksStatusDropdown: res.data.InternallinksStatusData || [],
          ReviewStatusDropdown: res.data.ReviewStatusData || [],
          OnPageOptimizationDropdown: res.data.OnPageOptimizationData || [],
          RevisionStatusDropdown: res.data.RevisionStatusData || [],
        });
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }

  // form validation
  async FromValidation() {
    let FormIsValid = true;
    let Fields = this.state.Fields;
    let Errors = {};
    var BacklinkStatus = document
      .getElementById("ContentStatusID")
      .value.trim();

    if (BacklinkStatus == "") {
      FormIsValid = false;
      Errors["Status"] = "Please enter Status";
    }

    this.setState({ Errors: Errors });

    return FormIsValid;
  }

  // form validation
  HandleChangeBlur() {
    let Errors = { ...this.state.Errors };
    var BacklinkStatus = document
      .getElementById("ContentStatusID")
      .value.trim();

    if (BacklinkStatus == "") {
      Errors["Status"] = "Please enter Status";
    } else {
      Errors["Status"] = "";
    }
    this.setState({ Errors: Errors });
  }

  // handle change
  //   HandleChange(Field, e) {
  //     let Fields = this.state.Fields;
  //     Fields[Field] = e.target.value;
  //     this.setState({ Fields });
  //     var Email = document.getElementById("Email").value.trim();
  //     if (Email != "") {
  //       this.state.Errors.Email = null;
  //       this.state.MeetingEmailAvailable = null;
  //       this.MeetingOwnersCheckExist(Email);
  //     } else {
  //       this.state.MeetingEmailAvailable = null;
  //       this.state.Errors.Email = null;
  //     }
  //   }

  // find exits meeting owner Email
  //   async MeetingOwnersCheckExist(Email) {
  //     let regexp =
  //       /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  //     let Errors = {};
  //     if (Email != "") {
  //       if (!regexp.test(Email)) {
  //         return "InvalidEmail";
  //       } else {
  //         var MeetingSource = {
  //           ClientID: this.state.ClientID,
  //           Email: Email,
  //           Role: this.state.Role,
  //         };
  //         var resdata = await Axios({
  //           url: CommonConstants.MOL_APIURL + "/meetingowner/MeetingOwnerExists",
  //           method: "POST",
  //           data: MeetingSource,
  //         });
  //         if (resdata.data.StatusMessage == "SUCCESS") {
  //           if (resdata.data.Data.length > 0) {
  //             this.setState({ MeetingEmailAvailable: resdata.data.Data[0].Name });
  //             return "ExistEmail";
  //           } else {
  //             return "AllDone";
  //           }
  //         }
  //       }
  //     } else {
  //       return "BlankData";
  //     }
  //   }

  // back to button
  BackBtn() {
    history.push("/contentpipeline");
  }

  // meeting owner add
  async SaveBtn(e) {
    e.preventDefault(); // Prevent default form submission

    // Disable the button to prevent multiple submissions
    this.setState({ IsButtonDisabled: true });

    // Perform form validation
    var Final_flag = await this.FromValidation();

    if (Final_flag === true) {
            // Additional fields as per your form
      var ContentStatusID = document.getElementById("ContentStatusID").value;
      var LiveDate = document.getElementById("LiveDate").value;
      var DueDate = document.getElementById("DueDate").value;
      var Title = document.getElementById("Title").value;
      var PrimaryKeyword = document.getElementById("PrimaryKeyword").value;
      var OutlineURL = document.getElementById("OutlineURL").value;
      var EditedArticleURL = document.getElementById("EditedArticleURL").value;
      var FinishedArticleURL =
        document.getElementById("FinishedArticleURL").value;
      var LiveURL = document.getElementById("LiveURL").value;
      var ReviewStatus = document.getElementById("ReviewStatus").value;
      var RevisionStatus = document.getElementById("RevisionStatus").value;
      var RevisionDueDate = document.getElementById("RevisionDueDate").value;
      var OnPageOptimization =
        document.getElementById("OnPageOptimization").value;
      var InternalLinksStatus = document.getElementById(
        "InternalLinksStatus"
      ).value;
      var AuthorName = document.getElementById("AuthorName").value;
      var EditorName = document.getElementById("EditorName").value;

      // Construct the object to send in the POST request
      var MeetingOwnerAdd = {
        ClientID: this.state.ClientID,
        ContentStatusID: ContentStatusID,
        CreatedBy: this.state.CUserID,
        UserID: this.state.UserID,
        Role: this.state.Role,
        DueDate: DueDate,
        Title: Title,
        PrimaryKeyword: PrimaryKeyword,
        OutlineURL: OutlineURL,
        EditedArticleURL: EditedArticleURL,
        FinishedArticleURL: FinishedArticleURL,
        LiveURL: LiveURL,
        ReviewStatus: ReviewStatus,
        RevisionStatus: RevisionStatus,
        RevisionDueDate: RevisionDueDate,
        OnPageOptimization: OnPageOptimization,
        InternalLinksStatus: InternalLinksStatus,
        AuthorName: AuthorName,
        EditorName: EditorName,
        LiveDate: LiveDate,
      };

      // Make a POST request using Axios
      Axios.post(
        CommonConstants.MOL_APIURL + "/ContentPipeline/ContectPipeLineAdd",
        MeetingOwnerAdd
      )
        .then((res) => {
          if (res.data.StatusMessage === "SUCCESS") {
            // Handle success scenario
            toast.success(<div>Content Calendar added successfully.</div>);
            history.push("/contentpipeline"); // Redirect to the list page
          } else {
            // Handle error scenario
            toast.error(res.data.Message);
            this.setState({ IsButtonDisabled: false }); // Enable the button again
          }
        })
        .catch((error) => {
          // Handle network or other errors
          toast.error("Failed to save. Please try again later.");
          this.setState({ IsButtonDisabled: false }); // Enable the button again
        });
    } else {
      // If validation fails, enable the button again
      this.setState({ IsButtonDisabled: false });
    }
  }

  render() {
    return (
      <>
              {this.state.isLoading && (
          <div id="hidelodings" className="loding-display">
            <img src={loadingis} />
          </div>
        )}
        <div className="adminmain mheight">
          <Sidebar className="" />

          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad lg-headertitle float-left">
                  Add Content Calendar
                </h4>
              </div>
            </div>

            <div class="paddcols">
              <div className="row py-3 mx-0 bg-white">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Status</label>
                    <div class="col-lg-8">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field ContactSourceID must be a number."
                        id="ContentStatusID"
                        name="MeetingSourceID"
                        onChange={this.HandleChangeBlur}
                      >
                        <option value="">--Select--</option>
                        {this.state.ContentDropdown.map((value) => (
                          <option value={value?.FieldID}>
                            {value?.FieldName}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Status"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Start Date</label>
                    <div className="col-lg-8 timedatepikker date-input">
                      <input
                        className="form-control m-input"
                        type="text"
                        id="DueDate"
                        ref={this.dueDateRef}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Title</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="Title"
                        name="Title"
                        placeholder="Enter Title"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Primary Keyword
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="PrimaryKeyword"
                        name="PrimaryKeyword"
                        placeholder="Enter Primary Keyword"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Outline URL
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="OutlineURL"
                        name="OutlineURL"
                        placeholder="Enter Outline URL"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Author Name
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="AuthorName"
                        name="AuthorName"
                        placeholder="Enter Author Name"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Finished Article URL
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="FinishedArticleURL"
                        name="FinishedArticleURL"
                        placeholder="Enter Finished Article URL"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Editor Name
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="EditorName"
                        name="EditorName"
                        placeholder="Enter Editor Name"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Review Status
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control m-input"
                        id="ReviewStatus"
                        name="ReviewStatus"
                      >
                        {/* <option value="">--Select--</option> */}
                        {this.state.ReviewStatusDropdown.map((value) => (
                          <option value={value?.FieldID}>
                            {value?.FieldName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Revision Status
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control m-input"
                        id="RevisionStatus"
                        name="RevisionStatus"
                      >
                        {/* <option value="">--Select--</option> */}
                        {this.state.RevisionStatusDropdown.map((value) => (
                          <option value={value?.FieldID}>
                            {value?.FieldName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Revision Due Date
                    </label>
                    <div className="col-lg-8 timedatepikker date-input">
                      <input
                        className="form-control m-input"
                        type="text"
                        id="RevisionDueDate"
                        ref={this.revisionDueDateRef}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Edited Article URL
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="EditedArticleURL"
                        name="EditedArticleURL"
                        placeholder="Enter Edited Article URL"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      On Page Optimization
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control m-input"
                        id="OnPageOptimization"
                        name="OnPageOptimization"
                      >
                        {/* <option value="">--Select--</option> */}
                        {this.state.OnPageOptimizationDropdown.map((value) => (
                          <option value={value?.FieldID}>
                            {value?.FieldName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Internal Links Status
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control m-input"
                        id="InternalLinksStatus"
                        name="InternalLinksStatus"
                      >
                        {/* <option value="">--Select--</option> */}
                        {this.state.InternallinksStatusDropdown.map((value) => (
                          <option value={value?.FieldID}>
                            {value?.FieldName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Live Date
                    </label>
                    <div className="col-lg-8 timedatepikker date-input">
                      <input
                        className="form-control m-input"
                        type="text"
                        id="LiveDate"
                        ref={this.liveDateRef}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Live URL</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="LiveURL"
                        name="LiveURL"
                        placeholder="Enter Live URL"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="row mb-3 mt-3">
              <div class="col-lg-12 pull-left">
                <button
                  id="submit"
                  onClick={this.SaveBtn}
                  disabled={this.state.IsButtonDisabled}
                  class="btn btn-primary btn-lightgreen mr-1"
                >
                  <i class="la la-save"></i> Save
                </button>
                <button
                  id="backtolist"
                  onClick={this.BackBtn}
                  class="btn btn-secondary"
                >
                  <i class="la la-arrow-circle-left"></i> Back
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddContentPipeline = connect(
  mapState,
  actionCreators
)(AddContentPipeline);
export { connectedAddContentPipeline as AddContentPipeline };
