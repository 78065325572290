import React from "react";
import Axios from "axios";
const Moment = require("moment");

import ReactFusioncharts from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";

import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

toast.configure();
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable({
  FromDates,
  ToDates,
  IsIncludeOldClient,
}) {
  const classes = useRowStyles();
  const [value, setValue] = React.useState(1);
  const [ClientID, SetClientID] = React.useState("");
  const [Role, SetRole] = React.useState("");
  const [UserID, SetUserID] = React.useState(0);
  const [SourceData, SetSourceData] = React.useState();
  const [TotalMeeting, SetTotalMeeting] = React.useState(0);
  const [AverageResponseRate, SetAverageResponseRate] = React.useState(0);
  const [AverageResponsetoMeetingRate, SetAverageResponsetoMeetingRate] =React.useState(0);
  const [TotalMeetingRate, SetTotalMeetingRate] =React.useState(0);
  const [AverageMeetingRate, SetAverageMeetingRate] = React.useState(0);
  const [ReplyRate, SetReplyRate] = React.useState(0);
  const [ToatlResponsetoMeetingRate, SetToatlResponsetoMeetingRate] =
    React.useState(0);
  const [FromDate, SetFromDate] = React.useState("");
  const [ToDate, SetToDate] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);
  const [IsOldClient, SetIsOldClient] = React.useState(false);

  React.useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    SetFromDate(FromDates);
    SetToDate(ToDates);
    SetIsOldClient(IsIncludeOldClient);
    GraphSourceGet(
      Details.ParentUserID,
      FromDates,
      ToDates,
      IsIncludeOldClient
    );
    OverViewTopKeyMatricsGet(
      Details.ParentUserID,
      FromDates,
      ToDates,
      IsIncludeOldClient
    );
  }, [FromDates, ToDates, IsIncludeOldClient]);

  const OverViewTopKeyMatricsGet = (
    UserID,
    FromDates,
    ToDates,
    IsIncludeOldClient
  ) => {
    SetIsLoading(true);
    const InputParameters = {
      UserID: UserID,
      ToDate: ToDates,
      FromDate: FromDates,
      IncludeOldClient: IsIncludeOldClient,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/allclientkpi/AllClientsKpiOverview",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      if (Res.data.StatusMessage == "SUCCESS") {
        SetTotalMeeting(Res.data.Data.TotalMeeting);
        SetAverageResponseRate(Res.data.Data.AverageResponseRate);
        SetAverageResponsetoMeetingRate(Res.data.Data.AverageResponsetoMeetingRate);
        SetAverageMeetingRate(Res.data.Data.AverageMeetingRate);
        SetReplyRate(Res.data.Data.ReplyRate);
        SetToatlResponsetoMeetingRate(Res.data.Data.ToatlResponsetoMeetingRate);
        SetTotalMeetingRate(Res.data.Data.TotalMeetingRate);
        SetIsLoading(false);
      } else {
        SetIsLoading(false);
      }
    }).catch((error) => {
      toast.error("Error fetching data: ", error);
      SetIsLoading(false);
    });
  };

  const GraphSourceGet = (UserID, FromDates, ToDates, IsIncludeOldClient) => {
    const InputParameters = {
      UserID: UserID,
      ToDate: ToDates,
      FromDate: FromDates,
      IncludeOldClient: IsIncludeOldClient,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/allclientkpi/AllClientKpiOverVieGraph",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      if (Res.data.StatusMessage == "SUCCESS") {
        if (Res.data.Data.length > 0) {
          var dataStatus = {
            chart: {
              //caption: "Meeting Status",
              theme: "fusion",
              subCaption: " ",
              formatnumberscale: "0",
              showLegend: "1",
              showLabels: "0",
              // showValues: "1",
              valuePosition: "inside",
              numbersuffix: "%",
              showPercentValues: "0",
              showPercentInToolTip: "0",
              enableSlicing: "1",
              legendPosition: "bottom",
              legendBgColor: "#fff",
              legendBorderColor: "#fff",
              legendShadow: 0,
              legendItemFontSize: "13",
              legendNumColumns: "2",
              plothighlighteffect: "fadeout|color=#111C43, alpha=100",
              legendScrollBgColor: "#fff",
              minimiseWrappingInLegend: "1",
              alignLegendWithCanvas: "1",
            },
            data: Res.data.Data,
          };
          SetSourceData(dataStatus);
        }
      }
    }).catch((error) => {
      toast.error("Error fetching data: ", error);
    });
  };

  charts(FusionCharts);

  return (
    <>
      {IsLoading === true ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="w-100 pb-3">
        <div className="row">
          <div className="col">
            <div className="bg-light-sky boxcardcounter align-center">
              <div class="line-title text-center d-block">
                <span class="clr-sky box-main-title">{TotalMeeting}</span>
              </div>
              <p class="clr-sky">Total Meetings</p>
            </div>
          </div>
          <div className="col">
            <div className="bg-light-sky boxcardcounter align-center">
              <div class="line-title text-center d-block">
                <span class="clr-sky box-main-title">
                  {AverageResponseRate}%
                </span>
              </div>
              <p class="clr-sky">Average Response %</p>
            </div>
          </div>
          <div className="col">
            <div className="bg-light-sky boxcardcounter align-center">
              <div class="line-title text-center d-block">
                <span class="clr-sky box-main-title">
                  {AverageResponsetoMeetingRate}%
                </span>
              </div>
              <p class="clr-sky">Average Response to Meeting %</p>
            </div>
          </div>
          <div className="col">
            <div className="bg-light-sky boxcardcounter align-center">
              <div class="line-title text-center d-block">
                <span class="clr-sky box-main-title">
                  {AverageMeetingRate}%
                </span>
              </div>
              <p class="clr-sky">Average Meeting %</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-100 row">
        <div className="col-sm-8 pt-4">
          <div className="whiterow px-4">
          <h3 className="smheadtext">Meeting Status</h3>
          <ReactFusioncharts
            key={JSON.stringify(SourceData)}
            type="pie3d"
            width="100%"
            height="100%"
            dataFormat="JSON"
            dataSource={SourceData}
          />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="col-lg-11 pt-4">
            <div className="row">
              <div className="col">
                <div className="bg-light-sky boxcardcounter align-center">
                  <div class="line-title text-center d-block">
                    <span class="clr-sky box-main-title">{ReplyRate}%</span>
                  </div>
                  <p class="clr-sky">Response %</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="bg-light-sky boxcardcounter align-center">
                  <div class="line-title text-center d-block">
                    <span class="clr-sky box-main-title">
                      {ToatlResponsetoMeetingRate}%
                    </span>
                  </div>
                  <p class="clr-sky">Response-to-Meeting %</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="bg-light-sky boxcardcounter align-center">
                  <div class="line-title text-center d-block">
                    <span class="clr-sky box-main-title">{TotalMeetingRate}%</span>
                  </div>
                  <p class="clr-sky">Meeting %</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
