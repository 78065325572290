import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
const moment = require("moment");

import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

toast.configure();

class EditEmailWarmUpPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Code: "",
      Fields: {},
      Errors: {},
      NameAvailable: null,
      ClientID: null,
      UserID: null,
      CUserID: null,
      OldName: "",
      IsButtonDisabled:false,
      FormData:{
        ClientName:"",
        FullName:"",
        Email:"",
        StartingVolume:0,
        EndingVolume:0,
        EmailDelay:0,
        ReplyPercentage:0,
        RampPercentage:0,
        SendingScheduleID:"",
        IsIncludeWarmUp:false
      },
      SendingSchedul:[],
      IsLoading:false
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = 'Edit | SalesHive';
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
    this.SendingSchedulingTimeGet()
    this.EditEmailWarmUpDetails();
  }

    //update account category
  EditEmailWarmUpDetails=()=> {
    this.setState({IsLoading:true})
    const id = this.props.location.state.ID;
    var EditFields = {
      ID: id,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +"/email_warm_up/EmailWarmUpGetID",
      method: "POST",
      data: EditFields,
    }).then((res) => {
        
      if (res.data.StatusMessage == "SUCCESS") {
        this.setState({
            FormData:{
                ClientName:res.data.Data.ClientName,
                FullName:res.data.Data.AccountName,
                Email:res.data.Data.Email,
                StartingVolume:res.data.Data.StartingVolume,
                EndingVolume:res.data.Data.EndingVolume,
                EmailDelay:res.data.Data.EmailDelay,
                ReplyPercentage:res.data.Data.ReplyPercentage,
                RampPercentage:res.data.Data.RampIncrement,
                SendingScheduleID:res.data.Data.SendingSchedularID,
                IsIncludeWarmUp:res.data.Data?.A?.IsEmailWarmingSending
            }
            
        })
        

        this.setState({IsLoading:false})

      } else {
        toast.error(res.data?.Message);
    this.setState({IsLoading:false})

      }
    });
  }

    // sending scheduling time get
    async SendingSchedulingTimeGet() {
        var SendingSchedulTimeData = {
          ClientID: this.props.location.state.ClientIDForClient,
          Role:this.state.Role
    
        };
        const rows = await Axios({
          url: CommonConstants.MOL_APIURL + "/email_warm_up/SendingschedulingGet",
          method: "POST",
          data: SendingSchedulTimeData,
        });
        this.setState({
          SendingSchedul: rows?.data?.Data,
        });
      }
      
      validateFields = (value, field) => {
        const { StartingVolume, EndingVolume, EmailDelay, RampPercentage, ReplyPercentage } = this.state.FormData;
        const Error = { ...this.state.Errors }; // Start with the existing errors

        const validateField = (fieldName, value, emptyMessage, zeroMessage) => {
          if (parseInt(value) === 0) {
            Error[fieldName] = zeroMessage;
          } else if (value === "") {
            Error[fieldName] = emptyMessage;
          } 
          else {
            delete Error[fieldName]; // Remove the error if conditions are met
          }
        };
      
        switch (field) {
          case "StartingVolume":
            
            if (value > 200) {
              Error['StartingVolume'] = "Please enter a value less than or equal to 200.";
            } else {
              validateField('StartingVolume', value, "Please enter Starting Volume.", "Please enter a value greater than or equal to 1.");
            }
            if (value != 0 && EndingVolume != 0 && value > EndingVolume) {
              if(value<=200 && EndingVolume<=200){
    
                Error['StartingVolume'] = "Please enter a value less than or equal to EndingVolume.";
                Error['EndingVolume'] = "Please enter a value greater than or equal to StartingVolume.";
              }
              if(EndingVolume<value){
                Error['StartingVolume'] = "Please enter a value less than or equal to EndingVolume.";

              }
            }
            if (value == EndingVolume && EndingVolume <= 200) {

              Error["StartingVolume"] = "";
              Error["EndingVolume"] = "";
            }
            break;
      
          case "EndingVolume":
            if (value > 200) {
              Error['EndingVolume'] = "Please enter a value less than or equal to 200.";
            } else {
              validateField('EndingVolume', value, "Please enter Ending Volume.", "Please enter a value greater than or equal to 1.");
            }

            if (StartingVolume != 0 && value != 0 && StartingVolume > value) {
              if(StartingVolume<=200 && value<=200){
    
                Error['StartingVolume'] = "Please enter a value less than or equal to EndingVolume.";
                Error['EndingVolume'] = "Please enter a value greater than or equal to StartingVolume.";
              }
              if(StartingVolume>value){
                Error['EndingVolume'] = "Please enter a value greater than or equal to StartingVolume.";

              }
            }
            if (value == StartingVolume && StartingVolume <= 200) {

              Error["EndingVolume"] = "";
              Error["StartingVolume"] = "";
            }
            break;
      
          case "EmailDelay":
            validateField('EmailDelay', value, "Please enter email Delay.", "Please enter a value greater than or equal to 1.");
            break;
      
          case "RampPercentage":
            if (value >100) {
           
              Error['RampPercentage'] = "Please enter a value less than or equal to 100.";
            }else{

              validateField('RampPercentage', value, "Please enter Ramp Percentage.", "Please enter a value greater than or equal to 1.");
            }
            break;
      
          case "ReplyPercentage":
            if(value>100){
              Error['ReplyPercentage'] = "Please enter a value less than or equal to 100.";
            }else{

              validateField('ReplyPercentage', value, "Please enter Reply Percentage.", "Please enter a value greater than or equal to 1.");
            }
            break;
      
          default:
            break;
        }
        this.setState({ Errors: Error });

      };

      FormvalidateFields = (value, field) => {
        const { StartingVolume, EndingVolume, EmailDelay, RampPercentage, ReplyPercentage } = this.state.FormData;
        const Error = {};
        if (StartingVolume === 0) {
          Error['StartingVolume'] = "Please enter a value greater than or equal to 1.";
        } else if (StartingVolume == "") {
          Error['StartingVolume'] = "Please enter starting volume";
        }
        
        if (EndingVolume == 0) {
          Error['EndingVolume'] = "Please enter a value greater than or equal to 1.";
        } else if (EndingVolume == "") {
          Error['EndingVolume'] = "Please enter ending volume";
        }
      
        if (EmailDelay == "") {
          Error['EmailDelay'] = "Please enter email delay";
        }
      
      
        if (RampPercentage == 0) {
            Error['RampPercentage'] = "Please enter a value greater than or equal to 1.";
          } else if (RampPercentage == "") {
            Error['RampPercentage'] = "Please enter ramp percentage";
          }else if (RampPercentage >100) {
           
              Error['RampPercentage'] = "Please enter a value less than or equal to 100.";
            }

          if (ReplyPercentage === 0) {
            Error['ReplyPercentage'] = "Please enter a value greater than or equal to 1.";
          } else if (ReplyPercentage == "") {
            Error['ReplyPercentage'] = "Please enter reply percentage";
          }
          else if (ReplyPercentage >100) {
           
            Error['ReplyPercentage'] = "Please enter a value less than or equal to 100.";
          }
          if(StartingVolume > 200 || EndingVolume > 200){

            if (StartingVolume > 200) {
              Error['StartingVolume'] = "Please enter a value less than or equal to 200.";
            }
            if (EndingVolume > 200) {
              Error['EndingVolume'] = "Please enter a value less than or equal to 200.";
            }
          }else{

            if (StartingVolume != 0 && EndingVolume !=0 && StartingVolume>EndingVolume) {
              Error['StartingVolume'] = "Please enter a value less than or equal to EndingVolume.";
              Error['EndingVolume'] = "Please enter a value greater than or equal to StartingVolume.";
  
            } 
          }
      
        // Cross-field validation
        if (StartingVolume !== "0" && EndingVolume !== "0" && StartingVolume > EndingVolume) {
          Error['StartingVolume'] = "Please enter a value less than or equal to EndingVolume.";
          Error['EndingVolume'] = "Please enter a value greater than or equal to StartingVolume.";
        }
      
        this.setState({ Errors: Error });
        return Error;
      };


//   //back to email warmup
  BackBtn() {
    history.push("/emailwarmup");
  }

//   //update email warmup
  async SaveBtn(e) {
    this.setState({BtnDisabled:true})
    this.setState({IsLoading:true})
    e.preventDefault(); // Prevents the default form submission behavior

  // Call the validation function
  const Errorcheck=this.FormvalidateFields();

  // Check if there are any errors
  const { Errors } = this.state;
  const hasErrors = Object.values(Errors).some((errorMessage) => errorMessage !== "");

  if ( Object.values(Errorcheck).length>0 ) {
    this.setState({BtnDisabled:false})
    this.setState({IsLoading:false})
  } else {
    const ID = this.props.location.state;
    const { ClientName,
        FullName,
        Email,
        StartingVolume,
        EndingVolume,
        EmailDelay,
        ReplyPercentage,
        RampPercentage,
        SendingScheduleID,
        IsIncludeWarmUp} =  this.state.FormData;
    let UpdateObject = {
        ID,
        ClientName,
        FullName,
        Email,
        StartingVolume,
        EndingVolume,
        EmailDelay,
        ReplyPercentage,
        RampPercentage,
        SendingScheduleID,
        IsIncludeWarmUp,
        UserID:this.state.UserID,
        LastUpdatedDate:new Date()
    }
    try {
        const res = await Axios.post(CommonConstants.MOL_APIURL + "/email_warm_up/EmailWarmUpUpdate", UpdateObject);
        
        if(res.data.StatusMessage=="SUCCESS"){

          toast.success(<div className="toastsize">Email WarmUp Edit <br />Email WarmUp updated successfully.</div>);
          history.push('/emailwarmup');
        }else{
          toast.error(<div className="toastsize">Email WarmUp Edit <br />Please try again.</div>);

        }
      } catch (err) {
        this.setState({ BtnDisabled: false, IsLoading: false });
        toast.error(err.response?.data?.Message);
      }

  }
  }

  render() {
    return (
      <>
       {this.state.IsLoading === true ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : <></>} 
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col px-4">
                  <h4 className="headertitlepad float-left mb-0">
                    Edit
                  </h4>
                </div>
              </div>

            <div className="paddcols bg-white px-3">
              <div className="row py-2 px-2">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Client</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter Client"
                     value={this.state.FormData.ClientName}
                        id="Client"
                        name="Client"
                        placeholder="Enter Client"
                        type="text"
                        disabled={true}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Client"]}
                      </span>
                     
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Full Name</label>
                    <div className="col-lg-8">
                    <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter Full name"
                        value={this.state.FormData.FullName}
                        disabled={true}

                        id="FullName"
                        name="FullName"
                        placeholder="Enter FullName"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["FullName"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Email</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter Email"
                        id="Email"
                        name="Email"
                        value={this.state.FormData.Email}
                        disabled={true}
                        placeholder="Enter Email"
                        type="email"
                      />
                     <span style={{ color: "red" }}>
                        {this.state.Errors["Email"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                    Starting Volume
                    </label>
                    <div className="col-lg-8">
                    <input
                        className="form-control m-input"
                        id="StartingVolume"
                        name="StartingVolume"
                        value={this.state.FormData.StartingVolume}
                        placeholder="Enter Starting Volume"
                        type="number"
                       
                          onChange={(e) => {
                            const { value } = e.target;
                            this.setState((prevState) => ({
                              FormData: { ...prevState.FormData, StartingVolume:  parseInt(value) }
                            }));
                            this.validateFields(value,"StartingVolume");
                          }}
                        
                      />
                     <span style={{ color: "red" }}>
                     <small>
                        {this.state.Errors["StartingVolume"]}
                        </small>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                    Ending Volume
                    </label>
                    <div className="col-lg-8">
                    <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter Ending Volume"
                        id="EndingVolume"
                        name="EndingVolume"
                        placeholder="Enter Ending Volume"
                        value={this.state.FormData.EndingVolume}
                        
                          onChange={(e) => {
                            const { value } = e.target;
                            this.setState((prevState) => ({
                              FormData: { ...prevState.FormData, EndingVolume:  parseInt(value) }
                            }));
                            this.validateFields(value,"EndingVolume");
                          }}
                        type="number"
                      />
                     <span style={{ color: "red" }}>
                     <small>
                        {this.state.Errors["EndingVolume"]}
                        </small>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                    Email Delay
                    </label>
                    <div className="col-lg-8">
                    <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter Email Delay"
                        id="EmailDelay"
                        name="EmailDelay"
                         type="number"
                        placeholder="Enter Email Delay"
                        value={this.state.FormData.EmailDelay}
                       
                          onChange={(e) => {
                            const { value } = e.target;
                            this.setState((prevState) => ({
                              FormData: { ...prevState.FormData, EmailDelay: parseInt(value) }
                            }));
                            this.validateFields(value,"EmailDelay");
                          }}
                       
                      />
                     <span style={{ color: "red" }}>
                     <small>
                        {this.state.Errors["EmailDelay"]}
                        </small>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                    Reply Percentage(%)
                    </label>
                    <div className="col-lg-8">
                    <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter Reply Percentage(%)"
                        id="ReplyPercentage"
                        name="ReplyPercentage"
                        placeholder="Enter Reply Percentage(%)"
                        value={this.state.FormData.ReplyPercentage}

                          onChange={(e) => {
                            const { value } = e.target;
                            this.setState((prevState) => ({
                              FormData: { ...prevState.FormData, ReplyPercentage:  parseInt(value) }
                            }));
                            this.validateFields(value,"ReplyPercentage");
                          }}
                        type="number"
                      />
                     <span style={{ color: "red" }}>
                     <small>
                        {this.state.Errors["ReplyPercentage"]}
                        </small>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                    Ramp Percentage(%)
                    </label>
                    <div className="col-lg-8">
                    <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter Ramp Percentage"
                        id="RampPercentage"
                        name="RampPercentage"
                        placeholder="Enter Ramp Percentage"
                        value={this.state.FormData.RampPercentage}
                       
                          onChange={(e) => {
                            const { value } = e.target;
                            this.setState((prevState) => ({
                              FormData: { ...prevState.FormData, RampPercentage:  parseInt(value) }
                            }));
                            this.validateFields(value, 'RampPercentage');
                          }}
                        type="number"
                      />
                     <span style={{ color: "red" }}>
                     <small>
                        {this.state.Errors["RampPercentage"]}
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                    Sending Schedule
                    </label>
                    <div className="col-lg-8">
                    <select
                        className="form-control  m-input"
                        id="SendingSchedularID"
                        data-val="true"
                        data-val-number="The field IMAPSSLID must be a number."
                        value={this.state.FormData.SendingScheduleID}
                        onChange={(e) => {
                            const { value } = e.target;
                            
                            this.setState((prevState) => ({
                              FormData: { ...prevState.FormData, SendingScheduleID: value }
                            }));
                          }}
                      >
                        {this.state.SendingSchedul?.map((value) => (
                          <option value={value._id}>
                           {value?.ScheduleStartTime!=null?moment(value?.ScheduleStartTime,'HH:mm').format('h:mm A'):("")}To{" "}
                            {value?.ScheduleEndTime!=null?moment(value?.ScheduleEndTime,'HH:mm').format('h:mm A'):("")}
                          </option>
                        ))}
                      </select>
                     <span style={{ color: "red" }}>
                        {this.state.Errors["SendingSchedule"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 my-3">
                      <div className="m-checkbox-inline">
                        <label className="check_bx">
                          <input
                            type="checkbox"
                            id="IsIncludeWarmUp"
                            name="IsIncludeWarmUp"
                            onChange={(e) => {
                                const { checked } = e.target;
                                this.setState((prevState) => ({
                                  FormData: { ...prevState.FormData, IsIncludeWarmUp: checked }
                                }));
                              }}
                            checked={this.state.FormData.IsIncludeWarmUp}
                          />
                          <span className="checkmark"></span>
                          Is Include WarmUp
                        </label>
                      </div>
                    </div> 
              </div>
            </div>

              <div className="row my-3">
                <div className="col-lg-12 pull-left">
                  <button
                    id="submit"
                    disabled={this.state.BtnDisabled}
                    onClick={this.SaveBtn}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditEmailWarmUpPage = connect(
  mapState,
  actionCreators
)(EditEmailWarmUpPage);
export { connectedEditEmailWarmUpPage as EditEmailWarmUpPage };
