import React, { useEffect } from "react";
import Axios from "axios";
const Moment = require("moment");
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDt");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [sortedColumn, setSortedColumn] = React.useState(null);
  // const [open, setOpen] = React.useState(false);
  const [Notes, SetNotes] = React.useState([]);
  const [OwnerData, SetOwnerData] = React.useState([]);
  const [StatusData, SetStatusData] = React.useState([]);
  const [OwnerID, SetOwnerID] = React.useState("");
  const [Open, SetOpen] = React.useState(false);
  const [HeaderList, SetHeaderlist] = React.useState([]);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);

  const classes = useRowStyles();

  useEffect(() => {
    document.title = `Opportunities | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    ClientOpportunitiesGet(
      Details.ClientID,
      Details.ParentUserID,
      Details.Role
    );
    OwnerDropDown(Details.ClientID);
  }, [Search, Page, RowsPerPage, OwnerID]);

  // Opportunities get list
  const ClientOpportunitiesGet = (CID, UID, URole) => {
    SetIsLoading(true);
    var InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      MeetingOwnerID: OwnerID,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL + "/opportunities/MeetingOpportunitiesGet",
      method: "POST",
      data: InputParameters,
    });
    rows1
      .then((Result) => {
        SetRows(Result.data?.PageData);
        SetRlen(Result.data?.TotalCount);
        SetFlen(Result.data?.TotalCount);
        SetCountPage(Result.data?.PageCount);
        SetIsLoading(false);
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
  };

  //OwnerData Drop down list
  const OwnerDropDown = (CId) => {
    var InputParameters = {
      ClientID: CId,
      Role: Role,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/reschedules/MeetingOwnerGet",
      method: "POST",
      data: InputParameters,
    });
    rows1
      .then((Result) => {
        const filteredData = Result.data.meetingheader.filter((obj) => {
          return !(
            // obj.ColumnName === "Company" ||
            (
              obj.ColumnName === "Website" ||
              obj.ColumnName === "Direct Phone Ext" ||
              obj.ColumnName === "Last Email Step" ||
              obj.ColumnName === "Contact Source" ||
              obj.ColumnName === "Last Email Campaign"||
              obj.ColumnName === "Reminder Email Account"
            )
          );
        });
        SetOwnerData(Result.data.Data);
        SetStatusData(Result.data.DataStatusGet);
        SetNotes(Result.data.notesdetails);
        SetHeaderlist(filteredData);
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
  };

  //search for record
  const RequestSearch = (Event) => {
    try {
      if (Event.key === "Enter") {
        var SearchedVal = document.getElementById("search").value;
        if (SearchedVal == Search) {
          SetSearch(SearchedVal);
          SetPage(1);
        } else {
          SetSearch(SearchedVal);
          SetPage(1);
          //document.getElementById("hideloding").style.display = "block";
        }
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    document.body.scrollTop = 0;
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      //document.getElementById("hideloding").style.display = "block";
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    //document.getElementById("hideloding").style.display = "block";
  };

  //get sort field data
  const SortData = (FieldName) => {
    try {
      let Field 
      if(FieldName=="Title"){
        Field="JobTitle"
      }else if(FieldName=="MeetingSetDate"){
        Field="EventCreatedDt"
      }else if(FieldName=="MeetingDate"){
        Field="EventStartDt"
      } else if(FieldName=="CreatedDate"){
        Field="CreatedDt"
       } else if(FieldName=="MeetingStatus"){
        Field="Status"
       } else if(FieldName=="Owner"){
        Field="MeetingOwner"
       } else if(FieldName=="SendReminder"){
        Field="IsSendMeetingReminder"
       }else if(FieldName=="QualifiedDate"){
        Field="QulifiedDate"
       } else if(FieldName=="MeetingSetBy"){
        Field="ResponserName"
       }else{
        Field=FieldName
       }
      var SearchedVal = document.getElementById("search").value;
      SetIsLoading(true);
      // document.getElementById("hideloding").style.display = "block";
      var SerchBox;
      if (SearchedVal == "") {
        SerchBox = false;
        SetSflag(false);
        var SortField = Field;
        var SortBy;
        if (SortedBy == 1) {
          SortBy = -1;
          SetSortedBy(-1);
        } else {
          SortBy = 1;
          SetSortedBy(1);
        }
        SetSortField(Field);
        var InputParameter = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          SerchBox: false,
          Archive: false,
          Search: Search,
          Sort: true,
          Field: SortField,
          SortBy: SortBy,
          Type: "User",
          ClientID: ClientID,
          UserID: UserID,
          Role: Role,
          MeetingOwnerID: OwnerID,
        };
        const AccountCategoriesList = Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/opportunities/MeetingOpportunitiesGet",
          method: "POST",
          data: InputParameter,
        });
        AccountCategoriesList.then((Result) => {
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
          SetIsLoading(false);
          // document.getElementById("hideloding").style.display = "none";
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");
          SetIsLoading(false);
        });
      } else {
        SerchBox = true;
        SetSflag(true);
        var SortField = Field;
        var SortBy;
        if (SortedBy == 1) {
          SortBy = -1;
          SetSortedBy(-1);
        } else {
          SortBy = 1;
          SetSortedBy(1);
        }
        SetSortField(Field);
        var InputParameter = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          Search: SearchedVal,
          SerchBox: SerchBox,
          Archive: false,
          Sort: true,
          Field: SortField,
          SortBy: SortBy,
          Type: "User",
          ClientID: ClientID,
          UserID: UserID,
          Role: Role,
          MeetingOwnerID: OwnerID,
        };
        const AccountCategoriesList = Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/opportunities/MeetingOpportunitiesGet",
          method: "POST",
          data: InputParameter,
        });
        AccountCategoriesList.then((Result) => {
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
          SetIsLoading(false);
          //document.getElementById("hideloding").style.display = "none";
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");
          SetIsLoading(false);
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    }
  };

  // Reset Filter
  const ResetFilter = () => {
    const DropDownOwner = document.getElementById("OwnerID").value;
    if (DropDownOwner == "") {
      document.getElementById("OwnerID").value = "";
      SetPage(1);
    } else {
      SetOwnerID("");
      document.getElementById("OwnerID").value = "";
      SetPage(1);
      //document.getElementById("hideloding").style.display = "block";
    }
  };

  //  Advance Filter
  const FilterResponder = () => {
    let OwnerDetails = document.getElementById("OwnerID").value;
    SetOwnerID(OwnerDetails);
    SetPage(1);
    //document.getElementById("hideloding").style.display = "block";
    //ClientOpportunitiesGet(ClientID, UserID, Role);
  };

  //Get Notes
  const GetNotes = (ID) => {
    var InputParameter = {
      EventID: ID,
    };
    const AccountCategoriesList = Axios({
      url: CommonConstants.MOL_APIURL + "/opportunities/MeetingNoteGetByID",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      SetNotes(Result.data.Data);
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    });
  };

  const Bookedstatusselect = (event, EventID, UserID, StatusIDs,EmailID) => {
    try {
      SetIsLoading(true);
      let MeetingIDs = null;
      MeetingIDs = event.target.value;

      //find the meeting status name by id
      var data = {
        ClientID: ClientID,
        MeetingStatusID: event.target.value,
        Role: Role,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/bookedmeeting/findBookedMeetingStatus",
        method: "POST",
        data: data,
      })
        .then((res) => {
          var data = {
            // _id: id,
            EventID: EventID,
            ClientID: ClientID,
            UserID: UserID,
            MeetingStatusID: res.data.BookedStatus[0]._id,
            OldMeetingStatusID: StatusIDs,
            Status: res.data.BookedStatus[0].Status,
            Role: Role,
            StatusName: document.getElementById(`MeetingStatusID${EventID}`)
              .options[
              document.getElementById(`MeetingStatusID${EventID}`).selectedIndex
            ].textContent,
            Email:EmailID,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/bookedmeeting/UpdateBookedMeetingStatusHistory",
            method: "POST",
            data: data,
          }).then((res) => {
            if (res.data.StatusMessage == "SUCCESS") {
              // document.getElementById("hideloding").style.display = "block";
              ClientOpportunitiesGet(ClientID, UserID, Role);
              toast.success(
                <div>
                  Meeting <br />
                  Status successfully changed.
                </div>
              );
            } else {
              toast.error(res.data.Message);
              SetIsLoading(false);
            }
          });
        })
        .catch((error) => {
          toast.error("An error occurred. Please try again.");
          SetIsLoading(false);
        });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    }
  };

  const ViewPage = (id) => {
    // history.push("/viewbookedmeeting", id);
    history.push({
      pathname: "/cviewbookedmeetings",
      state: {
        data: id,
        Pagename: "coppotunities",
        BackPage: "/copportunities",
      },
    });
  };

  //Delete rescheduling meeting
  const DeleteBtn = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete booked meeting.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
      cancelButtonText: "No, cancel!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          var data = {
            _id: id,
            LastUpdatedDate: new Date(),
            LastUpdatedBy: CUserID,
            Role: Role,
          };

          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/opportunities/DeleteOpportunitiesMeeting",
            method: "POST",
            data: data,
          }).then((res) => {
            if (res) {
              if ((res.statusText = "Ok")) {
                Swal.fire(
                  "Deleted!",
                  "Opportunities meeting deleted successfully.",
                  "success"
                );
                //document.getElementById("hideloding").style.display = "block";
                ClientOpportunitiesGet(ClientID, UserID, Role);
                history.push("/copportunities");
                SetPage(1);
                SetSearch("");
                document.getElementById("search").value = "";
              } else {
              }
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
  };

  const EditAccountHandle = (id) => {
    if (id !== undefined && id !== null && id !== "000000000000000000000000") {
    history.push({
      pathname: "/cviewaccounts",
      state: { data: id, Pagename: "OpportunitiesMeeting" },
    });
  }
  };

  const EditContactHandle = (id, Email) => {
    if (id !== undefined && id !== null && id !== "000000000000000000000000") {
    history.push({
      pathname: "/cviewcontacts",
      state: { data: id, EmailData: Email, Pagename: "OpportunitiesMeeting" },
    });
  }
  };

  return (
    <>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row px-0 pb-2">
        <div className="col col-lg-1 d-flex justify-content-between align-items-center">
          {" "}
          Owner{" "}
        </div>

        <div className="col col-lg-4">
          <select
            class="form-control  m-input"
            id="OwnerID"
            name="OwnerID"
            autocomplete="off"
          >
            <option value="">--Select--</option>
            {OwnerData?.map((value) => (
              <option key={value?.Name || value?.FirstName} value={value?.Name || value?.FirstName}>
                {value?.Name || value?.FirstName}
              </option>
            ))}
          </select>
        </div>
        <div className="col col-lg-4">
          <a
            href="javascript:void(0);"
            onClick={FilterResponder}
            class="btn btn-primary btnColor"
          >
            <i class="la la-search"></i>Search
          </a>
          <a
            href="javascript:void(0);"
            onClick={ResetFilter}
            class="ml-3 btn btn-secondary"
          >
            <i class="la la-close"></i> Reset
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Notes</TableCell>
                <TableCell>Action</TableCell>
                {HeaderList?.map((row) => (
                  <TableCell className={row.ColumnName ? row.ColumnName : ''}  key={row._id} onClick={() => {
                    SortData(row.ColumnName.replace(/\s+/g, ""));
                    setSortedColumn(row.ColumnName); // Set the sorted column
                  }}>
                    {row.ColumnName}
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === row.ColumnName ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === row.ColumnName ? "active" : null} />
                    </span>
                  </TableCell>
                ))}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((value1) => (
                  <>
                    <TableRow>
                      <TableCell align="center">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() =>
                            SetOpen((prev) => ({
                              ...prev,
                              [value1._id]: !prev[value1._id],
                            }))
                          }
                        >
                          {Open[value1._id] ? (
                            <i
                              className="fa fa-minus-circle minusl"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className="fa fa-plus-circle plusbl"
                              aria-hidden="true"
                            ></i>
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <a
                         href={`/cviewbookedmeetings?ID=${value1?._id}&pagename=${'oppotunities'}`}
                         onClick={(e) => {
                           e.preventDefault();
                           ViewPage(value1?._id);
                         }}
                          className="btn-eyesicon"
                          title="Edit"
                        >
                          <i className="la flaticon-eye edit-icon"></i>
                        </a>
                      </TableCell>
                      {HeaderList.map((row) => {
                        if (row.ColumnName == "Title") {
                          return <TableCell>{value1?.Title}</TableCell>;
                        } else if (row.ColumnName == "Meeting Date") {
                          return (
                            <TableCell>
                              {value1?.EventStartDt != null
                                ? value1?.EventStartDt
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Domain") {
                          return (
                            <TableCell>
                              <a
                                href={`http://${value1?.Email?.substring(
                                  value1?.Email?.lastIndexOf("@") + 1
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  textDecoration: "underline",
                                  color: "inherit",
                                }}
                              >
                                <u>
                                  {" "}
                                  {value1?.Email?.substring(
                                    value1?.Email?.lastIndexOf("@") + 1
                                  )}
                                </u>
                              </a>
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Opportunity Date") {
                          return (
                            <TableCell>
                              {value1?.OpportunityDate != null
                                ? Moment(
                                    value1?.OpportunityDate
                                  ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Meeting Set Date") {
                          return (
                            <TableCell>
                              {value1?.EventCreatedDt != null
                                ? value1?.EventCreatedDt
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Created Date") {
                          return (
                            <TableCell>
                              {value1?.CreatedDt != null
                                ? Moment(value1?.CreatedDt).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "LinkedIn Url") {
                          return <TableCell>{value1?.LinkedInUrl}</TableCell>;
                        } else if (row.ColumnName == "Company") {
                          return <TableCell>{value1?.Company}</TableCell>;
                        } else if (
                          row.ColumnName == "Last Status Change Date"
                        ) {
                          return (
                            <TableCell>
                            {value1?.LastStatusChangeDate != null
                              ? Moment(
                                  value1?.LastStatusChangeDate
                                ).format("MM/DD/YYYY")
                              : ""}
                          </TableCell>
                          );
                        } else if (row.ColumnName == "Industry") {
                          return <TableCell>{value1?.Industry}</TableCell>;
                        } else if (row.ColumnName == "Direct Phone") {
                          return <TableCell>{value1?.DirectPhone}</TableCell>;
                        } else if (row.ColumnName == "Meeting Set By") {
                          return (
                            <TableCell>
                              {value1?.ResponderColdCallersType == "R"
                                ? value1?.ResponserName
                                : value1?.ResponserName}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Meeting Status") {
                          return (
                            <TableCell>
                              {
                                <select
                                  onChange={(e) => {
                                    Bookedstatusselect(
                                      e,
                                      value1?._id,
                                      value1?.UserID,
                                      value1?.StatusID,
                                      value1?.Email
                                    );
                                  }}
                                  class="form-control  m-input"
                                  id={`MeetingStatusID${value1?._id}`}
                                  name="MeetingStatusID"
                                  autocomplete="off"
                                  value={value1?.StatusID}
                                >
                                  {StatusData.map((value) => (
                                    <option value={value?._id}>
                                      {value?.Status}
                                    </option>
                                  ))}
                                </select>
                              }
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Contact Name") {
                          return (
                            <TableCell>
                              {" "}
                              <a
                                href={`/cviewcontacts?id=${value1?.Prospectid}&email=${encodeURIComponent(value1?.Email)}&pagename=OpportunitiesMeeting`}
                                onClick={(e) => {
                                  // If it's a left-click, prevent the default behavior and call EditContactHandle
                                  // if (e.button === 0 && !(e.metaKey || e.ctrlKey || e.shiftKey)) {
                                    e.preventDefault();
                                    EditContactHandle(value1?.Prospectid, value1?.Email);
                                  // }
                                  // Otherwise, allow the default behavior (e.g., right-click, middle-click, Ctrl+Click, etc.)
                                }}
                              >
                                <u>{value1?.ContactName || value1?.FirstName+" "+value1?.LastName}</u>
                              </a>
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Days In Stage") {
                          return (
                            <TableCell>
                              {value1?.DaysInStage &&
                                Math.floor(value1.DaysInStage)}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Company Phone") {
                          return <TableCell>{value1?.CompanyPhone}</TableCell>;
                        } else if (row.ColumnName == "Closed Won Date") {
                          return (
                            <TableCell>
                              {value1?.ClosedWonDate != null
                                ? Moment(
                                    value1?.ClosedWonDate
                                  ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Unqualified Date") {
                          return (
                            <TableCell>
                              {value1?.UnqualifiedDate != null
                                ? Moment(
                                    value1?.UnqualifiedDate
                                  ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Contract Value") {
                          return <TableCell>{value1?.ContractValue}</TableCell>;
                        } else if (row.ColumnName == "Email") {
                          return <TableCell>{value1?.Email}</TableCell>;
                        } else if (row.ColumnName == "Meeting Status Type") {
                          return <TableCell>{value1?.MeetingStatusType}</TableCell>;
                        } else if (row.ColumnName == "Send Reminder") {
                          return (
                            <TableCell>
                              {value1?.IsSendMeetingReminder == true
                                ? "yes"
                                : "no"}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Seniority") {
                          return <TableCell>{value1?.Seniority}</TableCell>;
                        } else if (row.ColumnName == "Meeting Source") {
                          return <TableCell>{value1?.MeetingSource}</TableCell>;
                        } else if (row.ColumnName == "Account Name") {
                          return (
                            <TableCell>
                              <a
                                href={`/cviewaccounts?id=${value1?.AccountNameID}&pagename=OpportunitiesMeeting`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  EditAccountHandle(value1?.AccountNameID);
                                }}
                              >
                                <u>
                                  {value1?.AccountName == undefined || value1?.AccountName == ""
                                    ? ""
                                    : value1?.AccountName}
                                </u>
                              </a>
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Owner") {
                          return <TableCell>{value1?.MeetingOwner}</TableCell>;
                        } else if (row.ColumnName == "Qualified Date") {
                          return (
                            <TableCell>
                              {value1?.QulifiedDate != null
                                ? Moment(value1?.QulifiedDate).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Closed Lost Date") {
                          return (
                            <TableCell>
                              {value1?.ClosedLostDate != null
                                ? Moment(
                                    value1?.ClosedLostDate
                                  ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Last Email Step") {
                          return (
                            <TableCell>
                              {row?.CampaignDls?.CampaignDetails?.LastEmailStep}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Last Email Campaign") {
                          return (
                            <TableCell>
                              {row?.CampaignDls?.CampaignDetails?.Name}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Direct Phone Ext") {
                          return (
                            <TableCell>{value1?.DirectPhoneExt}</TableCell>
                          );
                        } else if (row.ColumnName == "Website") {
                          return <TableCell><a href={`http://${value1?.Website}`} target="_blank" rel="noopener noreferrer"><u>{value1?.Website}</u></a></TableCell>
                        } else if (
                          row.CustomFieldID != null &&
                          row.CustomFieldID != ""
                        ) {
                          let valcust = value1.CustomField?.filter(
                            (item) => item.CustomFieldID == row?.CustomFieldID
                          );
                          if (valcust?.length > 0) {
                            return (
                              <TableCell>{valcust[0]?.Value}</TableCell>
                            );
                          } else {
                            return <TableCell></TableCell>;
                          }
                        } else {
                          return <TableCell></TableCell>;
                        }
                      })}

                      <TableCell>
                        <a
                         href={`/cviewbookedmeetings?ID=${value1?._id}&pagename=${'oppotunities'}`}
                         onClick={(e) => {
                           e.preventDefault();
                           ViewPage(value1?._id);
                         }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-eye edit-icon"></i>
                        </a>
                        <a
                          onClick={() => {
                            DeleteBtn(value1?._id);
                          }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-delete-1 delete-icon"></i>
                        </a>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse
                          in={Open[value1?._id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {Open[value1?._id] && (
                            <Box margin={1} className="innertables">
                              <Table size="small" aria-label="purchases">
                                <TableHead></TableHead>
                                {Notes.map((note) =>
                                  note?.MeetingID == value1?._id ? (
                                    <TableRow key={note._id}>
                                      <TableCell className="font-weight-bold">
                                        {note?.Title}
                                      </TableCell>
                                      <TableCell className="labeltds font-weight-bold">
                                        {Moment(
                                          new Date(
                                            note?.CreatedDate
                                          ).toDateString()
                                        ).format("MM/DD/YYYY")}
                                      </TableCell>
                                      <TableCell className="notes" scope="row">
                                        {note?.Note}
                                      </TableCell>
                                    </TableRow>
                                  ) : null
                                )}

                                {!Notes.some(
                                  (note) => note?.MeetingID == value1?._id
                                ) && (
                                  <TableRow>
                                    <TableCell colSpan={4}>
                                      No data available
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Table>
                            </Box>
                          )}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
