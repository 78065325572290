import React from "react";
import Axios from "axios";

import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../../_helpers/Utility";

toast.configure();

class AddContactReasonUnqualifiedPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Fields: {},
      Errors: {},
      TitleNotNull: false,
      IsExist: false,
      StatusAvailable: null,
      ClientID: null,
      UserID: null,
      CUserID: null,
      Role: null,
      IsButtonDisabled:false
    };
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }

  componentDidMount() {
    var Details = GetUserDetails();
    document.title = 'Add | SalesHive';

    if (Details != null) {
      this.setState({ ClientID: Details.ClientID });
      this.setState({ UserID: Details.ParentUserID });
      this.setState({ CUserID: Details.ChildUserID });
      this.setState({ Role: Details.Role });
    }
   
  }

  // form validation
  async FromValidation() {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("Name").value.trim();
    var IsExists = await this.ContactReasonUnqualifiedCheckExist(Name); // Change 'Country' to 'Name'
    
    if (IsExists == "BlankData" && Name === "") { // Change 'Country' to 'Name'
      FormIsValid = false;
      Errors["Name"] = "Please enter name"; // Change 'Country' to 'Name'
    }
  
    if (IsExists === "ExistCountry") { // Change 'Country' to 'Name'
      FormIsValid = false;
      Errors["Name"] = "Name already exists."; // Change 'Country' to 'Name'
    }
  
    this.setState({ Errors: Errors });
    return FormIsValid;
  }
  

    // handle change
    async HandleChange(field, e) {
      let Errors = { ...this.state.Errors };
      const value = e.target.value.trim();
      let IsExists;
    
      if (field === "Country") {
        IsExists = await this.CoutryNameCheckExist(value);
        if (IsExists === "BlankData" && value === "") {
          Errors.Country = "Please enter name";
        } else if (IsExists === "ExistCountry") {
          Errors.Country = "Name already exists.";
        } else if (IsExists === "AllDone") {
          Errors.Country = null;
        }
      }
    
      this.setState({ Errors });
    }
    


  // contact reason unqualified reason check exits
  async ContactReasonUnqualifiedCheckExist(Reason) {
    if (Reason !== "") {
      var CheckExistsData = {
        ClientID: this.state.ClientID,
        Reason: Reason,
        Role: this.state.Role,
      };
      let resdata = await Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/contactreasonunqualified/ContactReasonUnqualifiedExists",
        method: "POST",
        data: CheckExistsData,
      });
      
      if (resdata.data.Data.length > 0) {
        this.setState({ StatusAvailable: resdata.data.Data[0]?.Reason });
        return "ExistCountry";
      } else {
        return "AllDone";
      }
    } else {
      return "BlankData";
    }
  }
  

  async SaveBtn() {

    this.setState({IsButtonDisabled:true});
    var Final_flag = await this.FromValidation();
    if (Final_flag == true) { 
      var Reason = document.getElementById("Name").value.trim();
      var data = {
        Reason: Reason,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date().toISOString(),
        OrderBy: 4,
        type: "User",
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/contactreasonunqualified/ContactReasonUnqualifiedAdd",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              contact reson unqualified
              <br />
              contact reson unqualified added successlly.
            </div>
          );
          history.push("/contactreasonunqualified");
        } else {
          toast.error(res.data.Message);
          this.setState({IsButtonDisabled:false});
        }
      });
    }else{
      this.setState({IsButtonDisabled:false});
    }
  }
  
  BackBtn() {
    history.push("/contactreasonunqualified");
  }

  render() {
    return (
      <>
      <div className="adminmain mheight">
        <Sidebar className="" />
        <div className="bodyhome"> 
          <div className="row pt-4 pb-3">
            <div className="col px-3">
              <h4 className="headertitlenop  lg-headertitle float-left px-1">
                Add
              </h4>
            </div>
          </div>

          <div class="whiterow">
            <div className="row pt-3 pb-3 px-3">
              <div class="col-lg-6 boxsinput_group px-3">
                <div class="row max-cols px-1">
                  <label class="col-lg-3 view-lable-meet">Name</label>
                  <div class="col-lg-8">
                    <input
                      class="form-control m-input"
                      data-val="true"
                      data-val-required="Please enter Name"
                      id="Name"
                      onBlur={this.HandleChange.bind(this, "Name")}
                      name="Name"
                      placeholder="Enter name"
                      type="text"
                    />
                   
                    <span style={{ color: "red" }}>
                      {this.state.Errors["Name"]}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

            <div class="row my-3">
              <div class="col-lg-12 pull-left px-3 mx-1">
                <button
                  id="submit"
                  onClick={this.SaveBtn}
                  disabled={this.state.IsButtonDisabled}
                  class="btn btn-primary btn-lightgreen mr-1"
                >
                  <i class="la la-save"></i> Save
                </button>
                <a
                  id="backtolist"
                  onClick={this.BackBtn}
                  class="btn btn-secondary"
                >
                  <i class="la la-arrow-circle-left"></i> Back
                </a>
              </div>
            </div>
        </div>
      </div>
      <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedcontactreasonunqualified = connect(
  mapState,
  actionCreators
)(AddContactReasonUnqualifiedPage);
export { connectedcontactreasonunqualified as AddContactReasonUnqualifiedPage };
