import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import loadingicon from "../../../images/loading.gif";

toast.configure();

class EditMeetingCustomFieldPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);
    this.state = {
      Fields: {},
      Errors: {},
      IsExist: false,
      NameAvailable: null,
      ClientID: null,
      UserID: null,
      CUserID: null,
      Role:Details.Role,
      IsButtonDisabled:false,
      OldName:""
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = `Edit Custom Meeting Field | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
    this.CustomMeetingFieldGetById();
  }

//form validation


  async FromValidation() {
    let FormIsValid = true;
    let Errors = {};
    var FieldName = document.getElementById("FieldName").value.trim();
    var IsExist = await this.CheckExistMeetingCustomFieldName(FieldName);
  
    if(FieldName == ""){
      FormIsValid = false;
      Errors["FieldName"] = "Please enter fieldname";
    }
    if(IsExist == true){
      FormIsValid = false;
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

//handle chnage
HandleChange(field, e) {     
  let Fields = this.state.Fields;
  Fields[field] = e.target.value;
  this.setState({ Fields });
  if (Fields.FieldName != "" && Fields.FieldName.indexOf(" ") != 0) {
    this.state.Errors.FieldName = null;
    this.state.NameAvailable = null;
    this.CheckExistMeetingCustomFieldName(e.target.value);
  } else {
    this.state.NameAvailable = null;
    this.state.Errors.FieldName = null;
  }
}

//Check exist meeting custom field
async CheckExistMeetingCustomFieldName(FieldName) {
    var str_in = {
      ClientID: this.state.ClientID,
      FieldName: FieldName,
      Role:this.state.Role
    };
    var resdata = await  Axios({
      url: CommonConstants.MOL_APIURL + "/meetingcustom/MeetingCustomExists",
      method: "POST",
      data: str_in,
    })
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.data.length > 0) {
        if(this.state.OldName == FieldName){
          this.setState({ IsExist: false });
          return false;
        }else{
          this.setState({ NameAvailable: resdata.data.data[0]?.FieldName });
          this.setState({ IsExist: true });
          return true;
        }
        
      } else {
        this.setState({ IsExist: false });
        return false;
      }
    }
  }

//Get meeting custom by id
  CustomMeetingFieldGetById() {
    const id = this.props.location.state;
    this.setState({ id: id });
    var str_in = {
      id: id,
      Role:this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/meetingcustom/MeetingcustomfiledByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById("FieldName").value = res.data.data[0].FieldName;
        this.setState({OldName:res.data.data[0].FieldName})
        document.getElementById("hideloding").style.display = "none";
      } else {
        toast.error(res.data?.Message);
        document.getElementById("hideloding").style.display = "none";
      }
    });
  }

//Update custom meeting
async SaveBtn(e) {
  e.preventDefault();
    // this.state.Errors.FieldName = null;
    // this.state.NameAvailable = null;
    this.setState({IsButtonDisabled:true});
    // e.currentTarget.disabled = true;
    var FinalValidation = await this.FromValidation()
    if (FinalValidation == true) {
      var FieldName = document.getElementById("FieldName").value;
      var data = {
        _id: this.state.id,
        FieldName: FieldName,
        LastUpdatedBy: this.state.CUserID,
        LastUpdatedDate: new Date(),
        Role:this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/meetingcustom/MeetingCustomUpdate",
        method: "POST",
        data: data,
      }).then((ResUpdate) => {
        if (ResUpdate.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div>
              Custom Meeting Field Edit <br/>
              Custom meeting field updated successfully.
            </div>
          );
          history.push("/meetingcustomfield");
        } else {
          toast.error(ResUpdate.data.Message);
          this.setState({IsButtonDisabled:false});
        }
      });
    } else {
      this.setState({IsButtonDisabled:false});
    }
  }

//back to page
  BackBtn() {
    history.push("/meetingcustomfield");
  }

  render() {
    return (
      <>
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad lg-headertitle  float-left pl-0 mb-0">
                    Edit Custom Meeting Field
                  </h4>
                </div>
              </div>

            <div className="bg-white p-3 mb-3">
              <div className="row py-2">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">FieldName</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter  field name"
                        id="FieldName"
                        onBlur={this.HandleChange.bind(this, "FieldName")}
                        name="FieldName"
                        placeholder="FieldName"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["FieldName"]}
                      </span>
                      {this.state.NameAvailable && (
                        <span style={{ color: "red" }}>
                          Field already exist.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div className="row mb-4">
                <div className="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    className="btn btn-primary btn-lightgreen mr-1"
                    disabled={this.state.IsButtonDisabled}
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditMeetingCustomFieldPage = connect(
  mapState,
  actionCreators
)(EditMeetingCustomFieldPage);
export { connectedEditMeetingCustomFieldPage as EditMeetingCustomFieldPage };
