import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Popup from "reactjs-popup";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import StyleHeader from "../StickyHeader/StickyHeader";

const EmailTemplete = require("../../../_helpers/email-templete");

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Name");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID, SetCUserID] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [sortedColumn, setSortedColumn] = React.useState("Name");
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);


  useEffect(() => {
    document.title = `Pending Invites | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    PendingInvites(Details.ClientID, Details.ParentUserID, Details.Role);
  }, [Search, Page, RowsPerPage]);

  // Pending Invites get list
  const PendingInvites = (CID, UID, URole) => {
    SetIsLoading(true);
    var InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/pending_invites/PendingInvitationGet",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false);
      setIsDataLoaded(true);

    }).catch((error) => {
      SetIsLoading(false);
      setIsDataLoaded(true);

    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  // Pending invitation delete
  const DeleteBtn = (id, UserType) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a pending invitation..",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          ID: id,
          UserType: UserType,
          LastUpdatedDt: new Date(),
          LastUpdatedBy: CUserID,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/pending_invites/PendingInvitationDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "pending invitation deleted successfully.",
                "success"
              );
              PendingInvites(ClientID, UserID, Role);
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("search").value;
    // SetPage(1);
    SetIsLoading(true);
    // SetRowsPerPage(10);
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL + "/pending_invites/PendingInvitationGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      }).catch((error) => {
        SetIsLoading(false);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL + "/pending_invites/PendingInvitationGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      }).catch((error) => {
        SetIsLoading(false);
      });
    }
  };

  //Admin Reinvite
  const AdminInvite = (InviteID, Email) => {
    let VerificationCodeChar = (Math.random() + 1).toString(36).substring(7);

    var re =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    var EmailFlg = re.test(Email);
    if (EmailFlg == true) {
      var FinalEmail = CryptoJS.AES.encrypt(
        JSON.stringify(Email),
        "my-secret-key@123"
      ).toString();
      var UID = CryptoJS.AES.encrypt(
        JSON.stringify(UserID),
        "my-secret-key@123"
      ).toString();
      var AUVID = CryptoJS.AES.encrypt(
        JSON.stringify(InviteID),
        "my-secret-key@123"
      ).toString();
      var VC = CryptoJS.AES.encrypt(
        JSON.stringify(VerificationCodeChar),
        "my-secret-key@123"
      ).toString();

      var EmailData = EmailTemplete.AdminUserEmailInvite(
        FinalEmail,
        UID,
        AUVID,
        VC,
        CommonConstants.admin_user
      );

      var InputParameters = {
        Email: Email,
        html: EmailData,
        subject: "SalesHive User Account",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/user/profileemail",
        method: "POST",
        data: InputParameters,
      }).then((res) => {
        if (res) {
          var InputParameters = {
            ID: InviteID,
            VC: VerificationCodeChar,
          };
          Axios({
            url: CommonConstants.MOL_APIURL + "/AdminUser/ResendInvitation",
            method: "POST",
            data: InputParameters,
          }).then((res) => {
            toast.success(
              <div className="toastsize">
                Sales Strategists <br />
                Invitation mail sent successfully.
              </div>
            );
          });
        }
      });
    }
  };

  //Cold caller Reinvite
  const ColdCallerInvite = (InviteID, Email) => {
    var re =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    var emailflg = re.test(Email);
    var FinalEmail = CryptoJS.AES.encrypt(
      JSON.stringify(Email),
      "my-secret-key@123"
    ).toString();
    let VerificationCodeChar = (Math.random() + 1).toString(36).substring(7);
    var UID = CryptoJS.AES.encrypt(
      JSON.stringify(UserID),
      "my-secret-key@123"
    ).toString();

    var VC = CryptoJS.AES.encrypt(
      JSON.stringify(VerificationCodeChar),
      "my-secret-key@123"
    ).toString();
    var EmailData = EmailTemplete.ResponderUserEmailInvite(
      FinalEmail,
      UID,
      UID,
      VC,
      CommonConstants.coldcallers_Invitation
    );
    var InputParameters = {
      email: Email,
      html: EmailData,
      subject: "SalesHive User Account",
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/user/profileemail",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      const InputParameters = {
        VerificationCode: VerificationCodeChar,
        ID: InviteID,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/cold_callers_profile/InviteResend",
        method: "POST",
        data: InputParameters,
      }).then((res) => {
        toast.success(
          <div className="toastsize">
            Sales Strategists <br />
            Invitation mail sent successfully.
          </div>
        );
      });
    });
  };

  //Sales strategists Reinvite
  const SalesStrategistsInvite = (InviteID, Email) => {
    var re =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    var Emailflg = re.test(Email);
    if (Emailflg == true) {
      var FinalEmail = CryptoJS.AES.encrypt(
        JSON.stringify(Email),
        "my-secret-key@123"
      ).toString();
      let VerificationCodeChar = (Math.random() + 1).toString(36).substring(7);
      var UID = CryptoJS.AES.encrypt(
        JSON.stringify(UserID),
        "my-secret-key@123"
      ).toString();

      var VC = CryptoJS.AES.encrypt(
        JSON.stringify(VerificationCodeChar),
        "my-secret-key@123"
      ).toString();
      var EmailData = EmailTemplete.salesStrategistsInvitation(
        FinalEmail,
        UID,
        VC
      );
      var InputParameters = {
        Email: Email,
        html: EmailData,
        subject: "SalesHive User Account",
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/sales_strategists_profile/EmailSend",
        method: "POST",
        data: InputParameters,
      }).then((res) => {
        var InputParameters = {
          uID: InviteID,
          Invite_Token: VerificationCodeChar,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/sales_strategists_profile/InviteResend",
          method: "POST",
          data: InputParameters,
        }).then((res) => {
          toast.success(
            <div className="toastsize">
              Sales Strategists <br />
              Invitation mail sent successfully.
            </div>
          );
        });
      });
    } else {
    }
  };

  //Responder Reinvite
  const ResponderInvite = (InviteID, Email) => {
    var re =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    var Emailflg = re.test(Email);
    if (Emailflg == true) {
      var FinalEmail = CryptoJS.AES.encrypt(
        JSON.stringify(Email),
        "my-secret-key@123"
      ).toString();
      let VerificationCodeChar = (Math.random() + 1).toString(36).substring(7);
      var UID = CryptoJS.AES.encrypt(
        JSON.stringify(UserID),
        "my-secret-key@123"
      ).toString();

      var VC = CryptoJS.AES.encrypt(
        JSON.stringify(VerificationCodeChar),
        "my-secret-key@123"
      ).toString();
      var EmailData = EmailTemplete.ResponderUserEmailInvite(
        FinalEmail,
        UID,
        UID,
        VC,
        CommonConstants.responder_Invitation
      );
      var InputParameters = {
        Email: Email,
        html: EmailData,
        subject: "SalesHive User Account",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/user/profileEmail",
        method: "POST",
        data: InputParameters,
      }).then((res) => {
        var InputParameters = {
          uID: InviteID,
          Invite_Token: VC,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/user/InviteResend",
          method: "POST",
          data: InputParameters,
        }).then((res) => {
          toast.success(
            <div className="toastsize">
              Sales Strategists <br />
              Invitation mail sent successfully.
            </div>
          );
        });
      });
    } else {
    }
  };

  //Users Per Client  Reinvite
  const UsersPerClientInvite = (InviteID, Email) => {
    let VerificationCodeChar = (Math.random() + 1).toString(36).substring(7);

    var re =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    var EmailFlg = re.test(Email);
    if (EmailFlg == true) {
      var FinalEmail = CryptoJS.AES.encrypt(
        JSON.stringify(Email),
        "my-secret-key@123"
      ).toString();
      var UID = CryptoJS.AES.encrypt(
        JSON.stringify(UserID),
        "my-secret-key@123"
      ).toString();
      var CID = CryptoJS.AES.encrypt(
        JSON.stringify(ClientID),
        "my-secret-key@123"
      ).toString();

      var EmailData = EmailTemplete.userEmailInvite(
        FinalEmail,
        CID,
        UID,
        CommonConstants.usersperclient_invitation
      );

      var InputParameters = {
        Email: Email,
        html: EmailData,
        subject: "SalesHive User Account",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/user/profileemail",
        method: "POST",
        data: InputParameters,
      }).then((res) => {
        var InputParameters = {
          ID: InviteID,
          VerificationCode: VerificationCodeChar,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/users_per_client/InviteResend",
          method: "POST",
          data: InputParameters,
        }).then((res) => {
          toast.success(
            <div className="toastsize">
              Sales Strategists <br />
              Invitation mail sent successfully.
            </div>
          );
        });
      });
    } else {
    }
  };

  //Reinvite User
  const ReInvite = (ID, UserType) => {
    let Email = document.getElementById("Email").value;
    if (UserType == "Admins") {
      AdminInvite(ID, Email);
    } else if (UserType == "Cold Callers") {
      ColdCallerInvite(ID, Email);
    } else if (UserType == "Responders") {
      ResponderInvite(ID, Email);
    } else if (UserType == "Sales Strategists") {
      SalesStrategistsInvite(ID, Email);
    } else if (UserType == "Users Per Client") {
      UsersPerClientInvite(ID, Email);
    }
  };

  return (
    <>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row">
        <div className="col padd-0"></div>
      </div>

      <div class='bg-white p-3'>
      <div className="row pt-2">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>

      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />

<Table className="table-ref"  aria-label="collapsible table">           
   <TableHead>
              <TableRow>
              <TableCell
                  onClick={() => {
                    SortData("Name");
                    setSortedColumn("Name");
                  }}
                >
                  Client Name
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "Name"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "Name"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("UserType");
                    setSortedColumn("UserType");
                  }}
                >
                  User Type
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "UserType"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "UserType"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("Email");
                    setSortedColumn("Email");
                  }}
                >
                  Email
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "Email"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "Email"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length == 0 ? (
                <p className="text-center">No data in Available</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow key={row.name} row={row}>
                    <TableCell>{row.Name}</TableCell>
                    <TableCell>{row.UserType}</TableCell>
                    <TableCell>{row.Email}</TableCell>
                    <TableCell>
                      <Popup
                        trigger={
                          <a
                            class="btn btn-sm btn-primary btnColor m-btn "
                            title="Reinvite"
                          >
                            Reinvite
                          </a>
                        }
                        modal
                        nested
                      >
                        {(close) => (
                          <div>
                            <div className="modal-black"></div>
                            <div className="filterPopup largerPopup">
                              <div className="paddingboxTerms">
                                <div className="modal-header py-4 px-3">
                                  <div className="w-100 d-flex px-3">
                                    <h5 className="mb-0">Send Mail</h5>
                                    <button className="close" onClick={close}>
                                      <span
                                        aria-hidden="true"
                                        class="la la-remove"
                                      ></span>
                                    </button>
                                  </div>
                                </div>
                                <div className="p-3">
                                  <div className="modal-body">
                                    <div className="col-sm-10 px-0">
                                      <div class="row mb-3">
                                        <label class="col-lg-5 col-form-label text-left">
                                          Email
                                        </label>
                                        <div class="col-lg-7">
                                          <input
                                            class="form-control m-input"
                                            id="Email"
                                            type="email"
                                            value={row.Email}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="border-top modal-content text-right">
                                    <div class="row">
                                      <div class="col-lg-12 pull-right">
                                        <a
                                          id="backtolist"
                                          class="btn btn-secondary mr-2"
                                          onClick={() => {
                                            close();
                                          }}
                                        >
                                          cancel
                                        </a>
                                        <a
                                          id="submit"
                                          onClick={() => {
                                            ReInvite(row._id, row.UserType);
                                            close();
                                          }}
                                          class="btn btn-primary btn-lightgreen mr-1"
                                        >
                                          Save
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Popup>

                      <a
                        onClick={() => {
                          DeleteBtn(row._id, row.UserType);
                        }}
                        className="btn-eyesicon"
                      >
                        <i class="la flaticon-delete-1 delete-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row ">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row ">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
      </div>
   
    </>
  );
}
