import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../../_constants/common.constants";
import { GetUserDetails } from "../../../../_helpers/Utility";
import { history } from "../../../../_helpers";
import loadingicon from "../../../../images/loading.gif";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../../StickyHeader/StickyHeader";

export default function ContactTagTable(props) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID,SetCUserID] = React.useState(0);
  const [IsLoading,SetIsLoading] = React.useState(true);
  const [sortedColumn, setSortedColumn] = React.useState("CreatedDate");
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    document.title = 'Tag | SalesHive';
    let Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    ContactTagGet(Details.ClientID,Details.ParentUserID,Details.Role);
  }, [Search, Page, RowsPerPage]);

  // cotact tag get list
  const ContactTagGet = (CID,UID,URole) => {
    let ContactTagData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const ApiResponse = Axios({
      url: CommonConstants.MOL_APIURL + "/contacttag/ContactTagGet",
      method: "POST",
      data: ContactTagData,
    });
    ApiResponse.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetIsLoading(false);
      setIsDataLoaded(true);

    });
  };

  // Search details
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value;
      if(SearchedVal==Search){
        SetSearch(SearchedVal);
        SetPage(1);
      }else{
        SetSearch(SearchedVal);
        SetPage(1);
        SetIsLoading(true);
      }
    }
  };
  // Delete function
  const DeleteBtn = (id,Tag) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a tag.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let ContactTagData = {
          _id: id,
          LastUpdatedBy: CUserID,
          LastUpdatedDate: new Date(),
          Role: Role,
          ClientID:ClientID,
          UserID:UserID
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/contacttag/ContactTagDelete",
          method: "POST",
          data: ContactTagData,
        }).then((res) => {
          
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Tag deleted successfully.",
                "success"
              );
              SetIsLoading(true);
              history.push("/contacttag")    
              ContactTagGet(ClientID,UserID,Role);
              SetPage(1)
              SetSearch("")
              document.getElementById("search").value = ""
              props.updateFromChild(true)
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // change page
  const HandleChangePage = (event,NewPage) => {
    if(NewPage==Page){
      SetPage(NewPage);
    }else{
      SetPage(NewPage);
       SetIsLoading(true);
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    SetIsLoading(true);
  };

  // edit button
  const EditBtn = (id) => {
    history.push({ pathname: "/editcontacttag", state: { data: id } });
  };

     //get sort field data
const SortData = (Field) => {
  setSortedColumn(Field);
  SetIsLoading(true);
  const SearchedVal = document.getElementById("search").value?.trim();
  const isNewSortField = Field !== SortField;
  let SortBy = SortedBy === 1 ? -1 : 1;

  if (isNewSortField) {
    SetSortedBy(1);
    SortBy = 1;
  } else {
    SetSortedBy(SortBy);
  }

  SetSortField(Field);
  SetSflag(SearchedVal !== "");

  let InputParameter = {
    Page: Page,
    RowsPerPage: RowsPerPage,
    Search: SearchedVal,
    Sort: true,
    Field: Field,
    SortBy: SortBy,
    Type: "User",
    ClientID: ClientID,
    UserID: UserID,
    Role: Role,
  };
  
  const ApiResponse = Axios({
    url:CommonConstants.MOL_APIURL +"/contacttag/ContactTagGet",
    method: "POST",
    data: InputParameter,
  });
  ApiResponse.then((Result) => {
    SetData(Result.data.PageData);
    SetRows(Result.data.PageData);
    SetRlen(Result.data.TotalCount);
    SetFlen(Result.data.TotalCount);
    SetCountPage(Result.data.PageCount);
    SetPage(1)
    SetIsLoading(false);
  });


  // let SearchedVal = document.getElementById("search").value;

  // let SerchBox;
  // if (SearchedVal == "") {
  //   SetIsLoading(true);
  //   SerchBox = false;
  //   SetSflag(false);
  //   let SortField = Field;
  //   let SortBy;
  //   if (SortedBy == 1) {
  //     SortBy = -1;
  //     SetSortedBy(-1);
  //   } else {
  //     SortBy = 1;
  //     SetSortedBy(1);
  //   }
  //   SetSortField(Field);
  //   let InputParameter = {
  //     Page: Page,
  //     RowsPerPage: RowsPerPage,
  //     SerchBox: false,
  //     Archive: false,
  //     Sort: true,
  //     Search: SearchedVal,
  //     Field: SortField,
  //     SortBy: SortBy,
  //     Type: "User",
  //     ClientID: ClientID,
  //     UserID: UserID,
  //     Role: Role,
  //   };
    
  //   const ApiResponse = Axios({
  //     url:CommonConstants.MOL_APIURL +"/contacttag/ContactTagGet",
  //     method: "POST",
  //     data: InputParameter,
  //   });
  //   ApiResponse.then((Result) => {
      
  //     SetData(Result.data.PageData);
  //     SetRows(Result.data.PageData);
  //     SetRlen(Result.data.TotalCount);
  //     SetFlen(Result.data.TotalCount);
  //     SetCountPage(Result.data.PageCount);
  //     SetIsLoading(false);
  //   });
  // } else {
  //   SetIsLoading(true);
  //   SerchBox = true;
  //   SetSflag(true);
  //   let SortField = Field;
  //   let SortBy;
  //   if (SortedBy == 1) {
  //     SortBy = -1;
  //     SetSortedBy(-1);
  //   } else {
  //     SortBy = 1;
  //     SetSortedBy(1);
  //   }
  //   SetSortField(Field);
  //   let InputParameter = {
  //     Page: Page,
  //     RowsPerPage: RowsPerPage,
  //     Search: SearchedVal,
  //     SerchBox: SerchBox,
  //     Archive: false,
  //     Sort: true,
  //     Field: SortField,
  //     SortBy: SortBy,
  //     Type: "User",
  //     ClientID: ClientID,
  //     UserID: UserID,
  //     Role: Role,
  //   };
    
  //   const ApiResponse = Axios({
  //     url:CommonConstants.MOL_APIURL +"/contacttag/ContactTagGet",
  //     method: "POST",
  //     data: InputParameter,
  //   });
  //   ApiResponse.then((Result) => {
  //     SetData(Result.data.PageData);
  //     SetRows(Result.data.PageData);
  //     SetRlen(Result.data.TotalCount);
  //     SetFlen(Result.data.TotalCount);
  //     SetCountPage(Result.data.PageCount);
  //     SetIsLoading(false);
  //   });
  // }
};

  return (
    <div>
       {IsLoading ? (
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div>
        ) : (
          <></>
        )}
  <div class='bg-white p-3'>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row pt-2">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
        <Table className="table-ref" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell onClick={() => {
                      SortData("Tag");
                      setSortedColumn("Tag");
                    }}>
                Tag
                    <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "Tag" ? "active" : null} />
                          <ArrowDownward  className={SortedBy === -1 && sortedColumn === "Tag"  ? "active" : null} />
                          </span>
                </TableCell>
                <TableCell  onClick={() => {
                      SortData("CreatedDate");
                      setSortedColumn("CreatedDate");
                    }}>
                Created Date
                    <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "CreatedDate" ? "active" : null} />
                          <ArrowDownward  className={SortedBy === -1 && sortedColumn === "CreatedDate"  ? "active" : null} />
                          </span></TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
              Rows?.map((row) => (
                <TableRow key={row?._id}>
                  <TableCell>{row.Tag}</TableCell>
                  <TableCell>
                    {moment(new Date(row.CreatedDate).toDateString()).format(
                      "MM/DD/YYYY"
                    )}
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        EditBtn(row._id);
                      }}
                      className="btn-eyesicon"
                      title="Edit"
                    >
                      <i className="la flaticon-edit-1 edit-icon"></i>
                    </a>
                    <a
                      onClick={() => {
                        DeleteBtn(row._id,row.Tag);
                      }}
                      className="btn-eyesicon"
                      title="Delete"
                    >
                      <i className="la flaticon-delete-1 delete-icon"></i>
                    </a>
                  </TableCell>
                </TableRow>
              )))}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
     
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
        </div>
    </div>
  );
}
