import React from "react";
const moment = require("moment");
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import Axios from "axios";
import { history } from "../../../_helpers";
//import CallHistory from '../_components/coldcalling/callhistory';
//import CollapsibleTable from '../_components/usertable';

import ReactApexchart from "react-apexcharts";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
import loadingicon from "../../../images/loading.gif";

class EditClientActionItemsPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();

    super(props);

    this.state = {
      fields: {},
      errors: {},
      ClientID: Details.ClientID,
      UserID: Details.ChildUserID,
      ParentUserID: Details.ParentUserID,
      Role: Details.Role,
      WeeklyMeetingStatusId: null,
      ActionItmesList: [],
      ClientList: [],
      SelectedClientID: 0,
      DisableBtn: false,
    };
    this.ClientDropDown(
      Details.ChildUserID,
      Details.ParentUserID,
      Details.Role
    );
    this.GetActionStatusDropDown();
    this.ClientActionItemData();

    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {
    document.title = "Edit Client Action Item | SalesHive";
  }

  //Get Client Action Item Data
  ClientActionItemData() {
    const ID = this.props.location.state;
    var InputParameter = {
      ID: ID,
      Role: this.state.Role,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/client_action_items/ClientActionItemsGetByID",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        this.setState({ SelectedClientID: res.data.Data.ClientID });
        document.getElementById("Clients").value = res.data.Data.ClientID;
        document.getElementById("ActionItemDate").valueAsDate = new Date(
          res.data.Data.MeetingDate
        );
        document.getElementById("ActionItemStatus").value =
          res.data.Data.WeeklyMeetingStatusId;
        if (res.data.Data.DueDate != null) {
          document.getElementById("DueDate").valueAsDate = new Date(
            res.data.Data.DueDate
          );
        }
        document.getElementById("Notes").value = res.data.Data.Notes;
        document.getElementById("ToDoItems").value = res.data.Data.ToDoItems;
      } else {
        toast.error(res.data.Message);
      }
    });
  }

  ClientDropDown(CUID, PUID, Role) {
    var ObjData = {
      ChildUserID: CUID,
      ParentUserID: PUID,
      Role: Role,
      // "ChildUserID": 17,
      // "ParentUserID": 17,
    };
    
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/client/GetClientListForTopDropDown",
      method: "POST",
      data: ObjData,
    });
    rows1.then((result) => {
      if (result.data.StatusMessage == "SUCCESS") {
        const ClientData = result.data.Data;
        const option = ClientData.map((d) => ({
          _id: d.ClientID,
          Name: d.Name,
        }));
        if (result.data.Data.length != 0) {
          this.setState({ ClientList: option });
        }
        document.getElementById("hideloding").style.display = "none";
      }
    });
  }

  // Get Action Status Drop Down
  GetActionStatusDropDown() {
    Axios({
      url: CommonConstants.MOL_APIURL + "/action_items/ActionItemStatusGet",
      method: "GET",
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        const data = res.data.Data;
        const option = data.map((d) => ({
          _id: d.FieldID,
          FieldName: d.FieldName,
        }));
        this.setState({ ActionItmesList: option });
        this.setState({ WeeklyMeetingStatusId: option[0]._id });
      } else {
        toast.error(res.data.Message);
      }
    });
  }

  FormValidation() {
    let formIsValid = true;
    let ClientIDs = document.getElementById("Clients").value;
    let ActionItemDate = document.getElementById("ActionItemDate").value;
    let errors = {};
    if (ClientIDs.length <= 0) {
      formIsValid = false;
      errors["Client"] = "Please select Client";
    }
    if (ActionItemDate.length <= 0) {
      formIsValid = false;
      errors["ActionDate"] = "Please select action item date";
    }
    this.setState({ errors: errors });

    return formIsValid;
  }

  ClientsHandler(e) {
    let Errors = this.state.errors;
    this.setState({ SelectedClientID: e.target.value });
    if (e.target.value <= 0) {
      Errors["Client"] = "Please select Client";
    } else {
      Errors["Client"] = null;
    }
    this.setState({ errors: Errors });
  }

  ActionItemDateHandler(e) {
    let Errors = this.state.errors;

    if (e.target.value <= 0) {
      Errors["ActionDate"] = "Please select action item date";
    } else {
      Errors["ActionDate"] = null;
    }
    this.setState({ errors: Errors });
  }

  backbtn() {
    history.push("/clientactionitems");
  }
  savebtn() {
    this.setState({ DisableBtn: true });
    if (this.FormValidation()) {
      let ClientIDs = document.getElementById("Clients").value;
      let ActionItemDate = document.getElementById("ActionItemDate").value;
      let ActionItemStatus = document.getElementById("ActionItemStatus").value;
      let DueDate = document.getElementById("DueDate").value;
      let Notes = document.getElementById("Notes").value;
      let ToDoItems = document.getElementById("ToDoItems").value;
      var InputParameter = {
        ID: this.props.location.state,
        UserID: this.state.ParentUserID,
        ClientID: ClientIDs,
        MeetingDate: ActionItemDate,
        WeeklyMeetingStatusId: ActionItemStatus,
        Notes: Notes,
        ToDoItems: ToDoItems,
        LastUpdatedDate: new Date(),
        LastUpdatedBy: this.state.UserID,
        InternalNotes: "",
        DueDate: DueDate,
        Role: this.state.Role,
      };

      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/client_action_items/ClientActionItemsUpdate",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Client Action Items updated
              <br />
              Client action item updated successfully.
            </div>
          );
          history.push("/clientactionitems");
        } else {
          this.setState({ DisableBtn: false });

          toast.error(res.data.Message);
        }
      });
    } else {
      this.setState({ DisableBtn: false });
    }
  }

  render() {
    return (
      <>
      <div id="hideloding" className="loding-display">
      <img src={loadingicon} />
    </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome"> 
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad lg-headertitle float-left">
                  Edit Client Action Item{" "}
                </h4>
              </div>
            </div>

            <div class="bg-white p-3">
              <div className="row pt-2">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-3 view-lable-meet">Client</label>
                    <div class="col-lg-7">
                      <select
                        className="form-control"
                        name="ContactSource"
                        value={this.state.SelectedClientID}
                        onChange={(e) => {
                          this.ClientsHandler(e);
                        }}
                        id="Clients"
                      >
                        <option value="">--Select--</option>
                        {this.state.ClientList.map((Clients) => (
                          <option value={Clients._id}>{Clients.Name}</option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["Client"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group"></div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-3 view-lable-meet">
                      Action Item Date
                    </label>
                    <div class="col-lg-7">
                      <input
                        type="date"
                        class="form-control"
                        id="ActionItemDate"
                        onChange={(e) => {
                          this.ActionItemDateHandler(e);
                        }}
                        placeholder=" "
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["ActionDate"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-3 view-lable-meet">
                      Action Item Status
                    </label>
                    <div class="col-lg-7">
                      <select
                        className="form-control "
                        name="ContactSource"
                        id="ActionItemStatus"
                      >
                        {this.state.ActionItmesList.map((StatusId) => (
                          <option value={StatusId._id}>
                            {StatusId.FieldName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-3 view-lable-meet">Due Date</label>
                    <div class="col-lg-7">
                      <input
                        type="date"
                        class="form-control"
                        id="DueDate"
                        placeholder=" "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 w-100">
                  <h4 className="headertitlepad px-3 xs-headertitle w-100 border-top border-bottom my-4">
                    Notes{" "}
                  </h4>
                  <div className="px-3">
                  <textarea
                    class="form-control"
                    id="Notes"
                    placeholder=""
                  ></textarea>
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-xs-12 w-100">
                  <h4 className="headertitlepad px-3 xs-headertitle w-100 border-top border-bottom my-4">
                    To Do Items{" "}
                  </h4>
                  <div className="px-3">
                  <textarea
                    class="form-control"
                    id="ToDoItems"
                    placeholder=""
                  ></textarea>
                  </div>
                </div>
              </div>
            </div>

              <div class="row mb-4 pt-4">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    disabled={this.state.DisableBtn}
                    onClick={this.savebtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="usersperclient"
                    onClick={this.backbtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditClientActionItemsPage = connect(
  mapState,
  actionCreators
)(EditClientActionItemsPage);
export { connectedEditClientActionItemsPage as EditClientActionItemsPage };
