import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Pagination from "@material-ui/lab/Pagination";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ReactFusioncharts from "react-fusioncharts";
import ReactApexchart from "react-apexcharts";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import SwapVertIcon from "@material-ui/icons/SwapVert";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import { GetClientDetails } from "../../../_helpers/Utility";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function Contactsdetailstable({
  FromChildDatesEmail,
  ToChildDatesEmail,
}) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("MsgDateTime");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [ResponseCategory, SetResponseCategory] = React.useState([]);
  const [ClientName, SetClientName] = React.useState("");
  const [ExportData, SetExportData] = React.useState([]);
  const [ResponseCategoryUpdated, SetResponseCategoryUpdated] = React.useState(
    []
  );
  const [open, setOpen] = React.useState(false);
  const [BodyDataPlain, setBodyDataPlain] = React.useState([]);
  const [PieChartData, SetPieChartData] = React.useState([]);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);

  const [TotalSentEmail, SetTotalSentEmail] = React.useState(0);
  const [TotalReceivedEmail, SetTotalReceivedEmail] = React.useState(0);
  const [TotalMeeting, SetTotalMeeting] = React.useState(0);
  const [ResponsePer, SetResponsePer] = React.useState(0);
  const [ResponsetoMeetingPer, SetResponsetoMeetingPer] = React.useState(0);
  const [GraphContactSentList, SetGraphContactSentList] = React.useState([]);
  const [GraphResponsesList, SetGraphResponsesList] = React.useState([]);
  const [WeekDay, SetWeekDay] = React.useState([]);
  const [selectedResponseCategory, setSelectedResponseCategory] =
    React.useState(null);
  const [responseCategories, setResponseCategories] = React.useState([]);
  const [sortedColumn, setSortedColumn] = React.useState(null);
  const [IncludeAllResponse, setIncludeAllResponse] = React.useState(false);

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    GetKpiEmail(
      Details.ClientID,
      Details.ParentUserID,
      FromChildDatesEmail,
      ToChildDatesEmail
    );
    // ExportMethod(
    //   Details.ClientID,
    //   Details.ParentUserID,
    //   FromChildDatesEmail,
    //   ToChildDatesEmail
    // );
    GetClientDetails(Details.ClientID).then((result) => {
      SetClientName(result[0].Name);
    });
  }, [Search, Page, RowsPerPage, FromChildDatesEmail, ToChildDatesEmail,IncludeAllResponse]);

  useEffect(() => {
    var Details = GetUserDetails();
    GetEmailKeyMatric(
      Details.ClientID,
      Details.ParentUserID,
      Details.Role,
      FromChildDatesEmail,
      ToChildDatesEmail
    );
  }, [FromChildDatesEmail, ToChildDatesEmail,IncludeAllResponse]);

  // Get Clients list
  const GetKpiEmail = (CID, UID, FromDate, ToDate) => {
    SetIsLoading(true);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: Role,
      Fromdate: FromDate,
      Todate: ToDate,
      IncludeAllResponse:IncludeAllResponse

    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/kpis_email/KpisEmailGet",
      method: "POST",
      data: InputParameter,
    });
    rows1.then((result) => {
      if (result.data.StatusMessage == "SUCCESS") {
        SetData(result.data.PageData);
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
        SetResponseCategory(result.data.ResponseCategory);
        SetIsLoading(false);
      } else {
        SetData([]);
        SetRows([]);
        SetRlen(0);
        SetFlen(0);
        SetCountPage(0);
        SetResponseCategory([]);
        SetIsLoading(false);
      }
    });
  };

  const stripHtmlTags = (text) => {
    const doc = new DOMParser().parseFromString(text, "text/html");
    if (doc.body.innerHTML === text) {
      // If the input text is plain text, return it as is
      return text;
    } else {
      // If the input text is HTML, strip HTML tags
      return doc.body.textContent || "";
    }
  };
  function convertToCSV(data) {
    const headers = Object.keys(data[0]);

    const csvRows = [];
    csvRows.push(headers.join(','));

    data.forEach(item => {
        const values = headers.map(header => {
            // Check if the value is a string before attempting to replace characters
            const value = typeof item[header] === 'string' ? item[header] : String(item[header]);
            const escapedValue = value.replace(/"/g, '""'); // Escape double quotes
            return `"${escapedValue}"`; // Enclose value in double quotes
        });
        csvRows.push(values.join(','));
    });

    return csvRows.join('\n');
}
  const ExportMethod = async() => {
    SetIsLoading(true);
    var data = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: "",
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
      Fromdate:FromChildDatesEmail,
      Todate: ToChildDatesEmail,
      IsPagingRequired: false,
      IncludeAllResponse:IncludeAllResponse

    };
   let Res=await Axios({url: CommonConstants.MOL_APIURL + "/kpis_email/KpisExport",method: "POST",data: data,})
      
      if (Res.data.StatusMessage == "SUCCESS") {
        var ExportData = [];
        if (Res.data.PageData.length > 0) {
          for (var i = 0; i < Res.data.PageData.length; i++) {
            var Result = Res.data.PageData[i];
            var data = {
              From: Result.EmailAdd || "",
              "Contact Name": Result.Name || "",
              "Account Name": Result.EmailOfAccount || "",
              "Email Account": Result.AccountName || "",
              "Campaign Name": Result.CampaignName || "",
              "Step Number": Result.StepNumber + "-" + String.fromCharCode(64 + Result.TemplateNumber) || "",
              Subject: Result.Subject || "",
              "Response date":
                moment(Result.MsgDateTime).format("MM/DD/YYYY") || "",
              "Response Category": Result.Status || "",
              Snippet: stripHtmlTags(Result.Snippet) || "",
            };
            ExportData.push(data);
            
          }
          const downloader = document.createElement("a");
          const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(convertToCSV(ExportData));
          downloader.setAttribute("href", csvContent);
          downloader.setAttribute("download", ClientName + "-ResponsesDetail.csv");
          downloader.click();
          SetIsLoading(false);
          toast.success(<div>Data Exported successfully.</div>);
          SetExportData(ExportData);
        } else {
          var data = {
            From: "",
            "Contact Name": "",
            "Account Name": "",
            "Email Account": "",
            "Campaign Name": "",
            "Step Number": "",
            Subject: "",
            "Response date": "",
            "Response Category": "",
            Snippet: "",
          };
          SetExportData(data);
          SetIsLoading(false);

          toast.error("No data available!");

        }
      } else {
        SetIsLoading(false);

        toast.error("Data exported Failed!");
      }
    
  };

  const GetEmailKeyMatric = (CID, UID, URole, FromDate, ToDate) => {
    SetIsLoading(true);
    var InputParameter = {
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      Fromdate: FromDate,
      Todate: ToDate,
      IncludeAllResponse:IncludeAllResponse

    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/kpis_email/EmailKeyMatricGet",
      method: "POST",
      data: InputParameter,
    });
    rows1.then((Res) => {
      if (Res.data.StatusMessage == "SUCCESS") {
        if (Res.data.KpiEmailKeymatric.length > 0) {
          var lstTotalSent = [];
          var lstTotalResponse = [];
          var lstWeekDay = [];
          var objtotalmeeting = 0;
          var objTotalContact = 0;
          var objtotalReceivedEmail = 0;
          for (var i = 0; i < Res.data.KpiEmailKeymatric.length; i++) {
            var totalcontactcount = Res.data.KpiEmailKeymatric[i].TotalContact;
            objTotalContact = objTotalContact + totalcontactcount;

            var totalReceivedEmail =
              Res.data.KpiEmailKeymatric[i]?.ReceivedEmail;
            objtotalReceivedEmail = objtotalReceivedEmail + totalReceivedEmail;

            var totalmeeting = Res.data.KpiEmailKeymatric[i].TotalEvents;
            objtotalmeeting = objtotalmeeting + totalmeeting;

            var objWeekDay = Res.data.KpiEmailKeymatric[i].WeekDay;

            lstTotalSent.push(totalcontactcount);
            lstTotalResponse.push(totalReceivedEmail);
            lstWeekDay.push(objWeekDay);
          }
          SetTotalSentEmail(objTotalContact);
          SetTotalReceivedEmail(objtotalReceivedEmail);
          SetTotalMeeting(objtotalmeeting);
          SetGraphContactSentList(lstTotalSent);
          SetGraphResponsesList(lstTotalResponse);
          SetWeekDay(lstWeekDay);

          if (objTotalContact > 0 && objtotalReceivedEmail > 0) {
            SetResponsePer(
              Math.round((objtotalReceivedEmail * 100) / objTotalContact)
            );
          }

          if (objtotalReceivedEmail > 0 && objtotalmeeting > 0) {
            SetResponsetoMeetingPer(
              Math.round((objtotalmeeting * 100) / objtotalReceivedEmail)
            );
          }

          var dataStatus = {
            chart: {
              //caption: "Response Status",
              //subcaption: "For a net-worth of $1M",
              theme: "fusion",
              subCaption: " ",
              formatnumberscale: "0",
              showLegend: "1",
              showLabels: "0",
              showValues: "1",
              valuePosition: "inside",
              numbersuffix: "%",
              plothighlighteffect: "false",
              legendcaptionbold: "1",
              legendcaptionfontsize: "15",
              legendCaptionFontColor: "#000",
              showPercentValues: "0",
              showPercentInToolTip: "0",
              enableSlicing: "1",
              legendPosition: "bottom",
              legendBgColor: "#FFF",
              legendBorderColor: "#ffF",
              legendShadow: "0",
              legendItemFontSize: "14",
              legendWidth: "300",
            },
            data: Res.data.GraphResponseCategory,
          };

          SetPieChartData(dataStatus);
          SetIsLoading(false);
        } else {
          SetTotalSentEmail(0);
          SetTotalReceivedEmail(0);
          SetTotalMeeting(0);
          SetResponsePer(0);
          SetResponsetoMeetingPer(0);
          SetGraphContactSentList([]);
          SetGraphResponsesList([]);
          SetWeekDay([]);
          SetIsLoading(false);
        }
      } else {
        SetTotalSentEmail(0);
        SetTotalReceivedEmail(0);
        SetTotalMeeting(0);
        SetResponsePer(0);
        SetResponsetoMeetingPer(0);
        SetGraphContactSentList([]);
        SetGraphResponsesList([]);
        SetWeekDay([]);
        SetIsLoading(false);
      }

      //
    });
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  // pagination scroll to top page
   const scrollToTop = () => {
    SetIsLoading(true);

    const windowBody =  document.querySelector('body')
    const tableBorderElement = document.querySelector('.table-bordered')  
    windowBody.scrollTo({
      top: tableBorderElement.offsetTop + 500,
      behavior: 'smooth'
  });
  };
  // change page
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
    scrollToTop();
  };
  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true);

    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Search: SearchedVal,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        Fromdate: FromChildDatesEmail,
        Todate: ToChildDatesEmail,
        IncludeAllResponse:IncludeAllResponse

      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/kpis_email/KpisEmailGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetResponseCategory(Result.data.ResponseCategory);
        SetIsLoading(false);
      });
    } else {
      SetIsLoading(true);

      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        Fromdate: FromChildDatesEmail,
        Todate: ToChildDatesEmail,
        IncludeAllResponse:IncludeAllResponse

      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/kpis_email/KpisEmailGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetResponseCategory(Result.data.ResponseCategory);
        SetIsLoading(false);
      });
    }
  };

  // Function to update response category for a specific row
  const handleResponseCategoryChange = (event, rowIndex, ID, Domain, EmailOfAccount) => {
    debugger
    const { value, selectedIndex } = event.target;
    const updatedResponseCategories = [...responseCategories];
    updatedResponseCategories[rowIndex] = value;
    setResponseCategories(updatedResponseCategories);

    // Update ResponseCategoryUpdated state for the specific row
    const updatedResponseCategoryChanges = {
      MessageID: ID,
      Domain: Domain,
      EmailAdd: EmailOfAccount,
      StatusVal: value,
      StatusText: event.target.options[selectedIndex].innerHTML,
      UserID: UserID,
      ClientID: ClientID,
      LastUpdatedBy: CUserID,
      LastUpdatedDate: new Date(),
    };
    const updatedResponseCategoryArr = [...ResponseCategoryUpdated];
    updatedResponseCategoryArr[rowIndex] = updatedResponseCategoryChanges;
    // Assuming updatedResponseCategoryArr is your array
let filteredArray = updatedResponseCategoryArr.filter(item => item !== null);
    SetResponseCategoryUpdated(filteredArray);
  };

  const SaveAll = () => {
    const InputParameter = {
      ValueObject: ResponseCategoryUpdated,
      Role: Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis_email/ResponseCategoryUpdate",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (ResponseCategoryUpdated.length > 0) {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success("Response category changed successfully.");
          GetKpiEmail(ClientID, UserID, FromChildDatesEmail, ToChildDatesEmail);
          // ExportMethod(ClientID,UserID,FromChildDatesEmail,ToChildDatesEmail);

        } else {
          toast.error(res.data.message);
        }
      } else {
        toast.error("Please change at least one Response category from list.");
      }
    });
  };

  const ExportCsv = () => {
    if (ExportData.length > 0) {
      toast.success("Data exported successfully.");
    } else {
      toast.error("No data available!");
    }
  };
  const EmailStatusBarChart = {
    series: [
      {
        name: "Sent",
        color: "#360947",
        data: GraphContactSentList,
      },
      {
        name: "Responses",
        color: "#F8BF58",
        data: GraphResponsesList,
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
        toolbar: {
            show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2, 2, 2],
        curve: 'smooth'
      },
      xaxis: {
        categories: WeekDay,
        title: {
          text: "Weeks",
        },
      },
      yaxis: {
        title: {
          text: "Count",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      legend: {
        position: "right",
        horizontalAlign: "bottom",
        offsetX: -10,
      },
      markers: {
        size: 4,
        strokeWidth: 1.5,
        hover: {
          size: 6 // Size of the marker when hovered
        }
      }
    },
  };

  // delete responses details
  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to permanently delete this email from your email account?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var Inputparameters = {
          MessageID: ID,
          LastUpdatedDt: new Date(),
          LastUpdatedBy: CUserID,
          Role: Role,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/kpis_email/KpisDelete",
          method: "POST",
          data: Inputparameters,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Response Message delete successfully.",
                "success"
              );
              GetKpiEmail(ClientID,UserID,FromChildDatesEmail,ToChildDatesEmail);
              // ExportMethod(ClientID,UserID,FromChildDatesEmail,ToChildDatesEmail);

            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //Update Attention status
  const UpdateAttention = (MessageID, Status) => {
    const InputParameter = {
      MessageID: MessageID,
      IsAttention: Status,
      LastUpdatedDt: new Date(),
      LastUpdatedBy: CUserID,
      Role: Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis_email/IsAttentionUpdate",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if ((res.data.StatusMessage = "SUCCESS")) {
        toast.success(
          "Message attention status changed successfully.",
          "Emails"
        );
        GetKpiEmail(ClientID, UserID, FromChildDatesEmail, ToChildDatesEmail);
        // ExportMethod(ClientID,UserID,FromChildDatesEmail,ToChildDatesEmail);

      } else {
        toast.error(res.data.Message);
      }
    });
  };

  //Get response message
  const GetResponseMessage = (MessageID) => {
    SetIsLoading(true);
    const InputParameter = {
      MessageID: MessageID,
      Role: Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis_email/ResponseMessageGet",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if ((res.data.StatusMessage = "SUCCESS")) {
        setBodyDataPlain(res.data.Data);
        SetIsLoading(false);
      } else {
        toast.error(res.data.Message);
        SetIsLoading(false);
      }
    });
  };
  function removeBlockquotes(inputString) {
    // Create a DOM parser to parse the input string
    var parser = new DOMParser();
    var doc = parser.parseFromString(inputString, 'text/html');
  
    // Get all blockquote elements
    var blockquotes = doc.getElementsByTagName('blockquote');
  
    // Loop through and remove each blockquote element
    while (blockquotes.length > 0) {
      blockquotes[0].parentNode.removeChild(blockquotes[0]);
    }
  
    // Return the modified HTML as a string
    return doc.body.innerHTML;
  }

  const EditContactHandle = (id, Email) => {
    if (id !== undefined && id !== null && id !== "000000000000000000000000") {
      history.push({
        pathname: "/viewcontacts",
        state: { data: id, EmailData: Email, Pagename: "kpiEmailResponder" },
      });
    }
  };
  const HandlechangeResponse=async(e)=>{
    const isChecked = e.target.checked;
    console.log(isChecked);
    
    SetPage(1);
    setIncludeAllResponse(isChecked)
  }

  return (
    <>
      {IsLoading ? (
        <>
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="row colfive px-2">
        <div className="col-xl-2">
          <div class="bg-white boxcardcounter h-100 d-flex align-items-center d-flex align-items-center w-100">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{TotalSentEmail}</span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Sent</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <div class="bg-white boxcardcounter h-100 d-flex align-items-center d-flex align-items-center w-100">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{ResponsePer}%</span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Response %</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <div class="bg-white boxcardcounter h-100 d-flex align-items-center d-flex align-items-center w-100">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">
                    {TotalReceivedEmail}
                  </span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Responses</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <div class="bg-white boxcardcounter h-100 d-flex align-items-center d-flex align-items-center w-100">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">
                    {ResponsetoMeetingPer}%
                  </span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Response-to-Meeting %</string>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-2">
          <div class="bg-white boxcardcounter h-100 d-flex align-items-center d-flex align-items-center w-100">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{TotalMeeting}</span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Meetings</string>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row pt-3 px-3">
        <div class="col-xl-12 bg-white p-3">
          <div id="chart chart-max450">
            <ReactApexchart
              options={EmailStatusBarChart.options}
              series={EmailStatusBarChart.series}
              type="line"
              height={450}
            />
          </div>
        </div>
      </div>

      <div className="row pt-3 px-3">
        <div className="col px-0">
          <div className="bg-white p-3 col border-y">
                <h6 className='chartheading'>Response Status</h6>
            <div className="row">
              <div className="col minheight">
                <div className="chartboxsmet">
                  <ReactFusioncharts
                    key={JSON.stringify(PieChartData)}
                    type="pie3d"
                    width="100%"
                    height="100%"
                    dataFormat="JSON"
                    dataSource={PieChartData}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class='mx-3 my-3'>
          <div className="row px-2 py-2 bg-white">
          <div className="row w-100 mx-1 d-flex align-items-center justify-content-between">
            <div className=" ">
              <h4 className="headertitlenop xs-headertitle float-left mb-0 pl-0">
                Response Details
              </h4>
            </div>
              <div className="listing-li float-right d-flex align-items-center ml-auto ">
                <ul className="m-0">
                <li>
                  <label class="check_bx nowrap mt-1">
              <input type="checkbox" id="includeAllStatus" onChange={HandlechangeResponse}/><span className="checkmark"></span>
              Include All Responses
            </label>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        SaveAll();
                      }}
                      className="btn btngroup btn-lightgreen"
                    >
                      <i class="la la-icon-font-size-13 la la-save"></i>
                      <span>Save All Changes</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => ExportMethod()}
                      className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                    >
                      <i class="la la-icon-font-size-13 la-download"></i>Export
                      {/* <span>
                        <CSVDownloader
                          className="px-0"
                          data={ExportData}
                          filename={`${ClientName}-ResponsesDetail`}
                          bom={true}
                        >
                          Export
                        </CSVDownloader>
                      </span> */}
                    </a>
                  </li>
                </ul>
              </div>
          </div>

          <div className="mt-3 mx-1 w-100 "> 
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    onChange={ChangeRowSelected}
                    aria-controls="tbl_meeting"
                    class="form-control form-control-sm"
                    value={RowsPerPage}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right mr-0">
                <label class="textlabelte pr-3">
                  Search:
                  <input
                    type="Search"
                    id="search"
                    onKeyPress={RequestSearch}
                    class="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        {" "}
                        <a
                          onClick={() => {
                            SortData("EmailAdd");
                            setSortedColumn("EmailAdd")
                          }}
                        >
                        <span className="shorting">
                            <ArrowUpward className={SortedBy === 1 && sortedColumn === "EmailAdd" ? "active" : null}/>
                            <ArrowDownward className={SortedBy === -1 && sortedColumn === "EmailAdd" ? "active" : null}/>
                          </span>
                          From
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortData("Name");
                            setSortedColumn("Name")
                          }}
                        >
                                                <span className="shorting">
                            <ArrowUpward className={SortedBy === 1 && sortedColumn === "Name" ? "active" : null}/>
                            <ArrowDownward className={SortedBy === -1 && sortedColumn === "Name" ? "active" : null}/>
                          </span>
                          Contact Name
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortData("EmailOfAccount");
                            setSortedColumn("EmailOfAccount")
                          }}
                        >
                              <span className="shorting">
                            <ArrowUpward className={SortedBy === 1 && sortedColumn === "EmailOfAccount" ? "active" : null}/>
                            <ArrowDownward className={SortedBy === -1 && sortedColumn === "EmailOfAccount" ? "active" : null}/>
                          </span>
                          Email Account
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortData("AccountName");
                            setSortedColumn("AccountName")
                          }}
                        >
                         <span className="shorting">
                            <ArrowUpward className={SortedBy === 1 && sortedColumn === "AccountName" ? "active" : null}/>
                            <ArrowDownward className={SortedBy === -1 && sortedColumn === "AccountName" ? "active" : null}/>
                          </span>
                          Account Name
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortData("CampaignName");
                            setSortedColumn("CampaignName")
                          }}
                        >
                           <span className="shorting">
                            <ArrowUpward className={SortedBy === 1 && sortedColumn === "CampaignName" ? "active" : null}/>
                            <ArrowDownward className={SortedBy === -1 && sortedColumn === "CampaignName" ? "active" : null}/>
                          </span>
                          Campaign Name
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortData("StepNumber");
                            setSortedColumn("StepNumber")
                          }}
                        >
                           <span className="shorting">
                            <ArrowUpward className={SortedBy === 1 && sortedColumn === "StepNumber" ? "active" : null}/>
                            <ArrowDownward className={SortedBy === -1 && sortedColumn === "StepNumber" ? "active" : null}/>
                          </span>
                          Step Number
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortData("Subject");
                            setSortedColumn("Subject")
                          }}
                        >
                             <span className="shorting">
                            <ArrowUpward className={SortedBy === 1 && sortedColumn === "Subject" ? "active" : null}/>
                            <ArrowDownward className={SortedBy === -1 && sortedColumn === "Subject" ? "active" : null}/>
                          </span>
                          Subject
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortData("MsgDateTime");
                            setSortedColumn("MsgDateTime")
                          }}
                        >
                              <span className="shorting">
                            <ArrowUpward className={SortedBy === 1 && sortedColumn === "MsgDateTime" ? "active" : null}/>
                            <ArrowDownward className={SortedBy === -1 && sortedColumn === "MsgDateTime" ? "active" : null}/>
                          </span>
                          Response date
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortData("ResponseCategoryID");
                            setSortedColumn("ResponseCategoryID")
                          }}
                        >
                          <span className="shorting">
                            <ArrowUpward className={SortedBy === 1 && sortedColumn === "ResponseCategoryID" ? "active" : null}/>
                            <ArrowDownward className={SortedBy === -1 && sortedColumn === "ResponseCategoryID" ? "active" : null}/>
                          </span>
                          Response Category
                        </a>
                      </TableCell>
                      <TableCell className="audio-clear"><a
                          onClick={() => {
                            SortData("Snippet");
                            setSortedColumn("Snippet")
                          }}
                        >
                          <span className="shorting">
                            <ArrowUpward className={SortedBy === 1 && sortedColumn === "Snippet" ? "active" : null}/>
                            <ArrowDownward className={SortedBy === -1 && sortedColumn === "Snippet" ? "active" : null}/>
                          </span>
                          Snippet
                        </a></TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Rows.length>0 ?Rows.map((row,index) => (
                      <>
                        <TableRow key={row.MessageID} row={row}>
                          <TableCell align="center">
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => {
                                setOpen((prev) => ({
                                  ...Object.fromEntries(
                                    Object.keys(prev).map((key) => [
                                      key,
                                      key === row.MessageID
                                        ? !prev[key]
                                        : false,
                                    ])
                                  ), // Close all rows except the clicked one
                                  [row.MessageID]: !prev[row.MessageID], // Toggle the clicked row
                                }));
                                GetResponseMessage(row.MessageID); // Fetch response message for the clicked row
                              }}
                            >
                              {open[row.MessageID] ? (
                                <i
                                  className="fa fa-minus-circle minusl"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-plus-circle plusbl"
                                  aria-hidden="true"
                                ></i>
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            {row.IsAttention ? (
                              <a
                                onClick={() => {
                                  UpdateAttention(row.MessageID, false);
                                }}
                              >
                                <StarIcon color="secondary" />
                              </a>
                            ) : (
                              <a
                                onClick={() => {
                                  UpdateAttention(row.MessageID, true);
                                }}
                              >
                                <StarBorderIcon color="primary" />
                              </a>
                            )}
                          </TableCell>
                          <TableCell>{row.EmailAdd}</TableCell>
                          <TableCell>
                            {" "}
                            <a
                              href={`/viewcontacts?id=${row?.ProspectID}&email=${encodeURIComponent(row?.EmailAdd)}&pagename=kpiEmailResponder`}
                              onClick={(e) => {
                                  e.preventDefault();
                                  EditContactHandle(row?.ProspectID, row?.EmailAdd);
                              }}
                            >
                              <u>{row?.Name}</u>
                            </a>
                          </TableCell>
                          <TableCell>{row.EmailOfAccount}</TableCell>
                          <TableCell>{row.AccountName}</TableCell>
                          <TableCell>{row.CampaignName}</TableCell>
                          <TableCell>{row.StepNumber+ "-" + String.fromCharCode(64 + row.TemplateNumber)}</TableCell>
                          <TableCell>{row.Subject}</TableCell>
                          <TableCell>
                            {moment(row.MsgDateTime
                            ).format("MM/DD/YYYY")}
                          </TableCell>
                          <TableCell className="labeltds" scope="row">
                            <select
                              onChange={(e) =>
                                handleResponseCategoryChange(
                                  e,
                                  index,
                                  row.MessageID,
                                  row.Domain,
                                  row.EmailOfAccount
                                )
                              }
                              className="form-control m-input"
                              id={`ResponseCategory-${index}`}
                              name={`ResponseCategory-${index}`}
                              autoComplete="off"
                              value={
                                responseCategories[index] ||
                                row.ResponseCategoryID
                              }
                            >
                              {ResponseCategory.map((value) => (
                                <option key={value._id} value={value._id}>
                                  {value.Name}
                                </option>
                              ))}
                            </select>
                          </TableCell>
                          <TableCell className="audio-clear">
                           
                            {/* {row?.Snippet 
                            === "" || null ? /<[a-z][\s\S]*>/i.test(removeBlockquotes(row?.Reply[1]?.BodyDataPlain))? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: removeBlockquotes(row?.Reply[1]?.BodyDataPlain),
                                }}
                              />
                            ) : (
                              <span>{removeBlockquotes(row?.Reply[1]?.BodyDataPlain)}</span>
                            ) : /<[a-z][\s\S]*>/i.test(row?.Snippet) ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: row?.Snippet,
                                }}
                              />
                            ) : ( */}
                              <span>{row?.Snippet}</span>
                            {/* )} */}
                          </TableCell>
                          <TableCell>
                            {" "}
                            <a
                              onClick={() => {
                                DeleteBtn(row.MessageID);
                              }}
                              className="btn-eyesicon"
                            >
                              <i class="la flaticon-delete-1 delete-icon"></i>
                            </a>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={8}
                          >
                            <Collapse
                              in={open[row.MessageID]}
                              timeout="auto"
                              unmountOnExit
                            >
                              {open[row.MessageID] && (
                                <Box margin={1} className="innertables">
                                  <Table size="small" aria-label="purchases">
                                    <TableHead></TableHead>
                                    {BodyDataPlain?.map((val) =>
                                      val?._id == row?.MessageID ? (
                                        <TableRow>
                                          {/* <TableCell>{note.Title}</TableCell> */}
                                          <TableCell style={{'verticalAlign':'text-top'}}>Response</TableCell>
                                          <TableCell className="notes-wrap" scope="row">

                                          <div
                                                dangerouslySetInnerHTML={{
                                                  __html: val.SalesReply_Payload[1]?.BodyDataPlain || val.BodyDataPlain,
                                                }}
                                              ></div>
                                          </TableCell>
                                        </TableRow>
                                      ) : null
                                    )}

                                    {!BodyDataPlain?.some(
                                      (val) => val?._id == row?.MessageID
                                    ) && (
                                      <TableRow>
                                        <TableCell colSpan={4}>
                                          No data available
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </Table>
                                </Box>
                              )}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    )):
                    <p className="text-center"> No data available in table</p>
                  }
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {Sflag ? (
              <div class="row">
                <Pagination
                  component="div"
                  count={CountPage}
                  onChange={HandleChangePage}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                    {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                    {Rlen} entries (filtered from {Flen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                    {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                    {Rlen} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={CountPage}
                    onChange={HandleChangePage}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div>

          <div className="row mt-3 mx-1">
            <div className="col py-3 d-flex align-items-center justify-content-end px-0">
               
              <div className="listing-li float-right ">
                <ul className="m-0">
                  <li>
                    <a
                      onClick={() => {
                        SaveAll();
                      }}
                      className="btn btngroup btn-lightgreen"
                    >
                      <i class="la la-icon-font-size-13 la la-save"></i>
                      <span>Save All Changes</span>
                    </a>
                  </li> 
                </ul>
              </div>
            </div>
          </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}
