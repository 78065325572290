import React,{useEffect} from 'react';
import Axios from "axios";
const moment=require('moment');
import Pagination from "@material-ui/lab/Pagination";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
let CryptoJS = require("crypto-js");
toast.configure();
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Avatar } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Userimg from '../../../images/avatar/1.jpg';
import Popupone from "reactjs-popup";

const EmailTemplete = require("../../../_helpers/email-templete");
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

const SortingIcons = ({SortedBy,sortedColumn,ColumnName}) => {
  return (
    <span className="shorting">
        <ArrowUpward className={SortedBy === 1 && sortedColumn === ColumnName? "active": null}/>
        <ArrowDownward className={SortedBy === -1 && sortedColumn === ColumnName? "active": null}/>
    </span>
  )
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});


export default function AccountAllTable({listupdate}) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Email");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [PopUpVal, SetPopUpVal] = React.useState(true);
  const [InvitePopup, SetInvitePopup] = React.useState(false);
  const [Email, SetEmail] = React.useState("")
  const [ReinviteID,SetReinviteID] = React.useState(null)
  const [UserIDby, SetUserIDby] = React.useState(0);
  const [sortedColumn, setSortedColumn] = React.useState("Email");
  const [IsLoading, SetIsLoading] = React.useState(true)

  const imgstyle = {
    height: "40px",
    width: "40px",
    borderradius: "50%",
  };


  useEffect(() => {
    let Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetRole(Details.Role);
      SetUserIDby(Details.ChildUserID);
    }
    UsersPerClientInvitation(Details.ClientID,Details.ParentUserID,Details.Role);
  }, [Search, Page, RowsPerPage,listupdate]);
 

  // User per client Get list
  const UsersPerClientInvitation = (CID,UID,URole) => {
    SetIsLoading(true)
    let InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const ApiResponse = Axios({
      url: CommonConstants.MOL_APIURL + "/users_per_client/UsersPerClientInvitationGet",
      method: "POST",
      data: InputParameter,
    });
    ApiResponse.then((result) => {
      
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetIsLoading(false)

    });
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

 // change page
 const HandleChangePage = (Event, NewPage) => {
  SetPage(NewPage);
  
};
   //get sort field data
const SortData = (Field) => {
  setSortedColumn(Field);
  SetIsLoading(true);
  const SearchedVal = document.getElementById("search").value?.trim();
  const isNewSortField = Field !== SortField;
  let SortBy = SortedBy === 1 ? -1 : 1;

  if (isNewSortField) {
    SetSortedBy(1);
    SortBy = 1;
  } else {
    SetSortedBy(SortBy);
  }

  SetSortField(Field);
  SetSflag(SearchedVal !== "");

  let RequestBody = {
    Page: Page,
    RowsPerPage: RowsPerPage,
    Search: SearchedVal,
    Sort: true,
    Field: Field,
    SortBy: SortBy,
    Type: "User",
    ClientID: ClientID,
    UserID: UserID,
    Role: Role,
  };
  const ApiResponse = Axios({
    url:CommonConstants.MOL_APIURL +"/users_per_client/UsersPerClientInvitationGet",
    method: "POST",
    data: RequestBody,
  });
  ApiResponse.then((Result) => {
    SetRows(Result.data.PageData);
    SetRlen(Result.data.TotalCount);
    SetFlen(Result.data.TotalCount);
    SetCountPage(Result.data.PageCount);
    SetPage(1)
    SetIsLoading(false)
  });

    // let SearchedVal = document.getElementById("search").value;
    // // SetPage(1);
    // // SetRowsPerPage(10);
    // let SerchBox;
    // if (SearchedVal == "") {
    //   SerchBox = false;
    //   SetSflag(false);
    //   let SortField = Field;
    //   let SortBy;
    //   if (SortedBy == 1) {
    //     SortBy = -1;
    //     SetSortedBy(-1);
    //   } else {
    //     SortBy = 1;
    //     SetSortedBy(1);
    //   }
    //   SetSortField(Field);
    //   let InputParameter = {
    //     Page: Page,
    //     RowsPerPage: RowsPerPage,
    //     SerchBox: false,
    //     Archive: false,
    //     Sort: true,
    //     Field: SortField,
    //     SortBy: SortBy,
    //     Type: "User",
    //     ClientID: ClientID,
    //     UserID: UserID,
    //     Role: Role,
    //   };
    //   const AccountCategoriesList = Axios({
    //     url:CommonConstants.MOL_APIURL +"/users_per_client/UsersPerClientInvitationGet",
    //     method: "POST",
    //     data: InputParameter,
    //   });
    //   AccountCategoriesList.then((Result) => {
    //     SetRows(Result.data.PageData);
    //     SetRlen(Result.data.TotalCount);
    //     SetFlen(Result.data.TotalCount);
    //     SetCountPage(Result.data.PageCount);
    //   document.getElementById("hideloding").style.display = "none";

    //   });
    // } else {
    //   SerchBox = true;
    //   SetSflag(true);
    //   let SortField = Field;
    //   let SortBy;
    //   if (SortedBy == 1) {
    //     SortBy = -1;
    //     SetSortedBy(-1);
    //   } else {
    //     SortBy = 1;
    //     SetSortedBy(1);
    //   }
    //   SetSortField(Field);
    //   let InputParameter = {
    //     Page: Page,
    //     RowsPerPage: RowsPerPage,
    //     search: SearchedVal,
    //     SerchBox: SerchBox,
    //     Archive: false,
    //     Sort: true,
    //     Field: SortField,
    //     SortBy: SortBy,
    //     Type: "User",
    //     ClientID: ClientID,
    //     UserID: UserID,
    //     Role: Role,
    //   };
    //   const AccountCategoriesList = Axios({
    //     url:CommonConstants.MOL_APIURL +"/users_per_client/UsersPerClientInvitationGet",
    //     method: "POST",
    //     data: InputParameter,
    //   });
    //   AccountCategoriesList.then((Result) => {
    //     SetRows(Result.data.PageData);
    //     SetRlen(Result.data.TotalCount);
    //     SetFlen(Result.data.TotalCount);
    //     SetCountPage(Result.data.PageCount);
    //   document.getElementById("hideloding").style.display = "none";

    //   });
    // }
};

//delete User per client Invitation
 const DeleteBtn = (ID) => {
  Swal.fire({
    title: "Are you sure?",
    text: "you want to delete a client users invitation.",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#34bfa3",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
    cancelButtonText:"No,cancel!",
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      let data = {
        ID: ID,
        ClientID:ClientID,
        LastUpdatedBy: UserIDby,
        LastUpdatedDate: new Date(),
        Role:Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/users_per_client/UsersPerClientInvitationDelete",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res) {
          if ((res.statusText = "Ok")) {
            Swal.fire(
              "Deleted!",
              "Client users invitation deleted successfully.",
              "success"
            );
            UsersPerClientInvitation(ClientID,UserID,Role);
            SetPage(1);
                    SetSearch("");
                    document.getElementById("search").value = "";
          } else {
          }
        }
      });
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled", "Your data is safe :)", "error");
    }
  });
};

const OpenInvitePopup=(Email,ID) =>{
  SetInvitePopup(true);
  SetEmail(Email);
  SetReinviteID(ID);
}
const CloseInvitePopup=()=>{

  SetInvitePopup(false)
}
//Set email address in popupbox
const SendInvite = () => {
  SetIsLoading(true);
  let Email = document.getElementById("Emails").value;

  const RequestBody = {
    Email: Email?.toLowerCase(),
    ID: ReinviteID,
    CreatedDate: new Date(),
    CreatedBy: UserID,
    Role: Role,
    Navigation: CommonConstants.usersperclient_invitation,
    UserID: UserID,
    ClientID: ClientID,
  };
  let re =
    /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  let EmailFlg = re.test(Email);
  if (EmailFlg == true) {
    Axios({
      url: CommonConstants.MOL_APIURL + "/users_per_client/InviteResend",
      method: "POST",
      data: RequestBody,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success(
          <div className="toastsize">
            Client users
            <br />
            Invitation mail sent successfully.
          </div>
        );
        SetInvitePopup(false);
        SetIsLoading(false);
      } else {
        toast.error(res.data.Message);
        SetIsLoading(false);
        SetInvitePopup(false);
      }
    });
  } else {
    SetIsLoading(false);
  }
};
// const SendInvite=()=>{
  
//   let Email=document.getElementById('Emails').value;
//         let VerificationCodeChar = (Math.random() + 1)
//                     .toString(36)
//                     .substring(7);
        
//           let re =
//             /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
//           let EmailFlg = re.test(Email);
//           if (EmailFlg == true) {
            
//             let FinalEmail = CryptoJS.AES.encrypt(
//               JSON.stringify(Email),
//               "my-secret-key@123"
//             ).toString();
//             let UID = CryptoJS.AES.encrypt(
//               JSON.stringify(UserID),
//               "my-secret-key@123"
//             ).toString();
//             let CID = CryptoJS.AES.encrypt(
//               JSON.stringify(ClientID),
//               "my-secret-key@123"
//             ).toString();
            
//             let EmailData = EmailTemplete.userEmailInvite(
//               FinalEmail,
//               CID,
//               UID,
//               CommonConstants.usersperclient_invitation
//             );
           
  
//                 let str_mail = {
//                   Email: Email,
//                   html: EmailData,
//                   subject: "SalesHive User Account",
//                 };
//                 Axios({
//                   url: CommonConstants.MOL_APIURL + "/user/profileemail",
//                   method: "POST",
//                   data: str_mail,
//                 }).then((res) => {
                  
//                     let InputParameters={
//                       ID:ReinviteID,
//                       CreatedDate:new Date(),
//                       CreatedBy:UserID,
//                       VerificationCode:VerificationCodeChar,
//                       Role:Role
//                   }
//                    Axios({
//             url: CommonConstants.MOL_APIURL + "/users_per_client/InviteResend",
//             method: "POST",
//             data: InputParameters,
//                   }).then((res) => {
                      
//                     if (res.data.StatusMessage == "SUCCESS") {
//                         toast.success(<div className="toastsize">Client users <br/>Invitation mail sent successfully.</div>);
//                         SetInvitePopup(false);
//                       } else {
//                         toast.error(res.data.Message);
//                       }
                      
//                   })
//                   SetInvitePopup(false);
//                 });
              
//           } else {
//           }
// //  let Email= document.getElementById('Emails').value
// //  let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
// //  let AlphaCode = ""
// //     let charactersLength = characters.length;

// //     for ( let i = 0; i < 6 ; i++ ) {
// //         AlphaCode += characters.charAt(Math.floor(Math.random() * charactersLength));
// //     }
// //     AlphaCode.toUpperCase()


// //     let ScreteTokens = CryptoJS.MD5(AlphaCode).toString();
// //     
// //     let emailData = EmailTemplete.userEmailChange(
// //         ScreteTokens,
// //         Email,
// //         CommonConstants.new_sales_strategists_url
// //       );
// //       let str_mail = {
// //         email: Email,
// //         html: emailData,
// //         subject: "SalesHive User Account",
// //       };
// //       Axios({
// //         url: CommonConstants.MOL_APIURL + "/AdminUser/EmailSend",
// //         method: "POST",
// //         data: str_mail,
// //       }).then((res) => {
// //         toast.success("Invitation mail sent successfully.", "Users Per Client");
// //         SetInvitePopup(false);
// //       });
// }
  return (
    <>
    {
      IsLoading ? <div id="hideloding" className="loding-display">
      <img src={loadingicon} />
    </div> :<></>
    }
     
    <Popupone open={InvitePopup} modal>
          <div className="modal-black"></div>
          <div className="filterPopup bigpopupcontent">
            <div className="paddingboxTerms">
              <div className="modal-header">
                <h5>Send Mail</h5>
                <a className="close-ion" onClick={()=>{CloseInvitePopup()}}>
                  <span aria-hidden="true" className="la la-remove"></span>
                </a>
              </div>
              <div className="modal-content bordernone">
                <div className="row max-cols">
                  <label className="col-lg-4 view-lable-meet">Email</label>
                  <div className="col-lg-8">
                    <input
                      className="form-control m-input"
                      name="Email"
                      placeholder="Email"
                      id="Emails"
                      type="text"
                      value={Email}
                      readOnly={true}

                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={()=>{CloseInvitePopup()}}
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <a
                  id="m_emailInvite_submit"
                  href="javascript:void(0);"
                  onClick={()=>{SendInvite()}}
                  className="btn btn-primary btnColor"
                >
                  Send{" "}
                </a>
              </div>
            </div>
          </div>
                </Popupone>
        <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
      <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow> 
          <TableCell  onClick={() => {SortData("Email");}}>Email<SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Email"} /></TableCell>     
            <TableCell>Action</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
        {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
          Rows.map((row) => (
            <TableRow key={row._id} row={row} >
              <TableCell>{row.Email}</TableCell>
                    <TableCell>
                    <a className="btn btn-sm btn-primary btnColor m-btn " onClick={() => {
                                      OpenInvitePopup(row.Email,row._id)
                                    }}>
                                      
                                      <span>Reinvite</span>
                                  </a>
                 
        
                                <a
                      onClick={() => {
                        DeleteBtn(row._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i className="la flaticon-delete-1 delete-icon"></i>
                    </a>
            </TableCell>
              </TableRow>
          )))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
      {/* {Sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : ( */}
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      {/* )} */}
    </>

  );
}
