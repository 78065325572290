import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

import { userActions } from "../../../_actions";
import { history } from "../../../_helpers";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import { CommonConstants, Client_Limits } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
const Fileupload = require("../../../_helpers/fileupload");
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
import ProfileImageUpload from '../../../_components/ProfileUpload';
import loadingicon from "../../../images/loading.gif";
const imgstyle = { width: "100px", height: "100px" };

class AddClientsPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);
    this.childRef = React.createRef();
    this.state = {
      ClientID: Details.ClientID,
      UserID: Details.ChildUserID,
      ParentUserID: Details.ParentUserID,
     
      FormData: {
        ClientName: "",
        ResponderName: [],
        SalesStrategistName: [],
        ColdCallersName: [],
        ClientStatus: "",
        ClientStartDate: "",
        ClientEndDate: "",
        TargetAccountsperQtr: "1000",
        DefaultContractValue: "",
        Website: "",
        ReasonLost: "",
        LogoApproval: "",
        TimeZone: "",
        TrackDomain: "react.saleshive.com",
        ClosedBy: "",
        BCCEmail: "",
        SlackChannelInternel: "",
        SlackChannelExternal: "",
        DoNotAddMeetingsToBlacklist: false,
        IsSentMeetingCreateNotification: false,
        IsSentMeetingRescheduleNotification: false,
        Channels: [],
        ServerNumber: "",
        ProfileImage: "",
        ClientBillingMethod: "",
        ClientBillingContact: "",
        ClientBillingEmail: "",
        ClientBillingAddress: "",
        ClientBillingDate: "",
        ClientBillingStatus: "",
        ClientBillingAmount: "",
        ClientBillingPeriod: "",
        AvgMonthlyRevenue: "",
        PPMEstMonths: "",
        InternelChannelID:"",
        ExternalChannelID:"",
        LimitEmailsPerMonthPerClient:Client_Limits.LIMIT_EMAIL_SENT_PER_MONTH_PER_CLIENT,
        LimitEmailsEmodPerMonthPerClient:Client_Limits.LIMIT_EMAILS_EMOD_PER_MONTH_PER_CLIENT,
        LimitUsersPerClient:Client_Limits.LIMIT_USERS_PER_CLIENT,
        ZoomInfoContactLimitType:Client_Limits.LIMIT_TYPE_ZOOM_INFO_CONTACT,
        ZoomInfoContactLimit:Client_Limits.LIMIT_ZOOM_INFO_CONTACT,
        LimitTotalContactsPerClient:Client_Limits.LIMIT_TOTAL_CONCTACTS_PER_CLIENT,
        OpenAI_API_KEY:"",
        IsAllowEmod:true,
        IsSeoReporting:false
      },
      ResponderList: [],
      ColdCallerLists: [],
      SalesStrategistList: [],
      ClientStatusList: [],
      ReasonLostList: [],
      LogoApprovalList: [],
      TimezoneList: [],
      ClientBillingMethodList: [],
      ClientBillingStatusList: [],
      ChannelList: [],
      ClosedByList: [],
      ClientBillingPeriodList: [],
      errors: {},
      ResponderArr: [],
      SalesStrategistArr: [],
      ColdCallersArr: [],
      FinalImg: "",
      FileImg: "",
      ImgFlag: false,
      SalesOption: [],
      ColdCallerOption: [],
      ChannelArr: "",
      IsButtonDisabled: false,
      IsLoading:false,
      ClientNameExists:false,
      
    };

    if (Details != null) {
      this.setState({ ClientID: Details.ClientID });
      this.setState({ UserID: Details.ChildUserID });
      this.setState({ ParentUserID: Details.ParentUserID });
    }
    this.AllDropDownFetching(Details.ClientID, Details.ParentUserID);

    this.BackBtn = this.BackBtn.bind(this);
  }
  componentDidMount() {
    
    document.title = "Add Client | SalesHive";
    document.getElementById("TrackDomain").value = "dev.saleshive.com";
  }

  //Fetching all drop down data
  AllDropDownFetching(CID, UID) {
    const InputParameters = {
      UserID: UID,
      ClientID: CID,
    };
    //Responder Drop down
    Axios({
      url: CommonConstants.MOL_APIURL + "/clients/ResponderDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ ResponderList: res.data.Data });
    });

    //sales strategist Drop down
    Axios({
      url: CommonConstants.MOL_APIURL + "/clients/SalesHackerDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ SalesStrategistList: res.data.Data });
      let SalesStrategistOption = this.state.SalesStrategistList.map((val) => {
        return {
          value: val._id,
          label: `${val.SalesHackerName} ${val.LastName}`,
        };
      });
      this.setState({ SalesOption: SalesStrategistOption });
    });

    //Cold caller drop down
    Axios({
      url: CommonConstants.MOL_APIURL + "/clients/ColdCallersDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ ColdCallerLists: res.data.Data });
      let ColdCallerOptionss = this.state.ColdCallerLists?.map((val) => {
        return {
          value: val?._id,
          label: `${val?.ColdCallersName} ${val?.LastName}`,
        };
      });
      this.setState({ ColdCallerOption: ColdCallerOptionss });
    });

    //Client Status drop down
    Axios({
      url: CommonConstants.MOL_APIURL + "/clients/ClientStatusDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ ClientStatusList: res.data.Data });
    });

    //Reason lost drop down
    Axios({
      url: CommonConstants.MOL_APIURL + "/clients/ReasonLostDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ ReasonLostList: res.data.Data });
    });

    //Logo Approval drop down
    Axios({
      url: CommonConstants.MOL_APIURL + "/clients/LogoApprovalDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ LogoApprovalList: res.data.Data });
    });

    //Time zone drop down
    Axios({
      url: CommonConstants.MOL_APIURL + "/clients/TimeZoneDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ TimezoneList: res.data.Data });
    });

    //Client billing method drop down
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/clients/ClientBillingMethodDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ ClientBillingMethodList: res.data.Data });
    });

    //Client billing status drop down
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/clients/ClientBillingStatusDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ ClientBillingStatusList: res.data.Data });
    });

    //channels drop down
    Axios({
      url: CommonConstants.MOL_APIURL + "/clients/ChannelsDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ ChannelList: res.data.Data });
    });

    //closedby drop down
    Axios({
      url: CommonConstants.MOL_APIURL + "/clients/ClosedByDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ ClosedByList: res.data.Data });
    });

    //client billing method period drop down
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/clients/ClientBillingPeriodDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ ClientBillingPeriodList: res.data.Data });
    });
  }

  //Responder drop down handler
  ResponderDropDownHandler(val) {
    
    this.setState((prevState) => ({
      FormData: {
        ...prevState.FormData,
        ResponderName: val,
      },
    }));
  }

  //Sales strategist drop down handler
  SalesStrategistHandler(val) {
    this.setState((prevState) => ({
      FormData: {
        ...prevState.FormData,
        SalesStrategistName: val,
      },
    }));
  }

  //cold callers drop down handler
  ColdCallersHandler(val) {
    this.setState((prevState) => ({
      FormData: {
        ...prevState.FormData,
        ColdCallersName: val,
      },
    }));
  }

  //Channel drop down handler
  ChannelDropDownHandler(val) {
    this.setState((prevState) => ({
      FormData: {
        ...prevState.FormData,
        Channels: val,
      },
    }));
  }
  //Back Btn
  BackBtn() {
    history.push("/clients");
  }

  //upload
  HandleUpload(field, e) {
    e.preventDefault();
    if (
      Fileupload.checkMimeType(e.target.files[0]) &&
      Fileupload.checkFileSize(e.target.files[0])
    ) {
      var temp = URL.createObjectURL(e.target.files[0]);

      this.setState({
        FinalImg: e.target.files[0],
      });
      this.setState({ ImgFlag: true });
      this.setState({ FileImg: temp });
    } else {
    }
  }

  //calculate amount
  CalulateAmount = () =>{
    
    let ClientBillingPeriod = document.getElementById('ClientBillingPeriod').value;
    let ClientBillingAmount = this.state.FormData.ClientBillingAmount;
    let AvgMonthlyRevenue = this.state.FormData.AvgMonthlyRevenue;
    let PPMEstMonths = this.state.FormData.PPMEstMonths;
    
    if (ClientBillingAmount == "" || ClientBillingAmount == null) {
      this.setState((prevState) => ({
        FormData: {
          ...prevState.FormData,
          ClientBillingAmount: 0,
        },
      }));
    }
    if (PPMEstMonths == "" || PPMEstMonths == null) {
      PPMEstMonths = 0;
    }
    if (ClientBillingPeriod == 1) {
      AvgMonthlyRevenue = ClientBillingAmount;
      PPMEstMonths = 0;
    } else if (ClientBillingPeriod == 2) {
      AvgMonthlyRevenue = ClientBillingAmount / 3;
    } else if (ClientBillingPeriod == 3) {
      AvgMonthlyRevenue = (ClientBillingAmount * 26) / 12;
    } else if (ClientBillingPeriod == 4) {
      
      if (PPMEstMonths !== "") {
        AvgMonthlyRevenue = ClientBillingAmount / PPMEstMonths;
      }else{
        AvgMonthlyRevenue = 0.00
      }
    }else{
      AvgMonthlyRevenue = 0.00
    }
    if (Number.isNaN(AvgMonthlyRevenue) || AvgMonthlyRevenue === Infinity || AvgMonthlyRevenue === -Infinity) {
      AvgMonthlyRevenue = 0.00
    } 
    this.setState((prevState) => ({
      FormData: {
        ...prevState.FormData,
        AvgMonthlyRevenue: parseInt(AvgMonthlyRevenue).toFixed(2),
      },
    }));
  }
      

  // //check client exists or not
  CheckClient = (name) => {
    let existFlag = false;
    const inputParameters = {
      UserID: this.state.ParentUserID,
      ClientName: name,
    };
  
    return Axios({
      url: CommonConstants.MOL_APIURL + "/clients/ClientCheckExists",
      method: "POST",
      data: inputParameters,
    })
      .then((response) => {
        if (response.data.Data.length > 0) {
          this.setState((prevState) => ({
            errors: {
              ...prevState.errors,
              ["ClientName"]: "Client name already exist.",
            },
          }));
          this.setState({ClientNameExists:true})
          existFlag = true;
        } else {
          this.setState((prevState) => ({
            errors: {
              ...prevState.errors,
              ["ClientName"]: "",
            },
          }));
          this.setState({ClientNameExists:false})
          existFlag = false;
        }
        return existFlag;
      })
      .catch((error) => {
        console.error(error);
        return existFlag; // or handle the error accordingly
      });
  };
  

  validateField = (fieldName, value) => {
    let error = "";

    if (fieldName === "ClientName" && !value) {
      error = "Please enter client name.";
    }
    if(fieldName === "ClientName" && value.trim() != ""){
      this.CheckClient(value.trim())
      .then((exists) => {
        console.log(exists)
        if (exists) {
          error = "Client name already exist.";
        }
      })
      console.log(error)
    }

    if (
      fieldName === "ResponderName" &&
      (!this.state.FormData.ResponderName || this.state.FormData.ResponderName.length === 0)
    ) {
      error = "Please select responder.";
    }

    if (
      fieldName === "SalesStrategistName" &&
      (!this.state.FormData.SalesStrategistName || this.state.FormData.SalesStrategistName.length === 0)
    ) {
      error = "Please select sales strategist.";
    }

    // if (
    //   fieldName === "ColdCallersName" &&
    //   (!this.state.FormData.ColdCallersName || this.state.FormData.ColdCallersName.length === 0)
    // ) {
    //   error = "Please select cold callers.";
    // }

    if (fieldName === "ClientStatus" && !value) {
      error = "Please select Client Status.";
    }

    if (fieldName === "ClientStartDate" && !value) {
      error = "Please select client start date.";
    }

    if (fieldName === "Website" && !value) {
      error = "Please enter website.";
    }

    if (fieldName === "LimitEmailsPerMonthPerClient") {
      if (value === undefined || value === "") {
        error = "Limit email sent per month per client cannot be blank. It must be at least -1";
      } else if (value < -1) {
        error = "Limit email sent per month per client cannot be less than -1.";
      }
    }
    if (fieldName === "LimitEmailsEmodPerMonthPerClient") {
      if (value === undefined || value === "") {
        error = "Limit emails emod per month per client cannot be blank. It must be at least -1";
      } else if (value < -1) {
        error = "Limit emails emod per month per client cannot be less than -1.";
      }
    }
    if (fieldName === "LimitUsersPerClient") {
      if (value === undefined || value === "") {
        error = "Limit users per client cannot be blank. It must be at least -1";
      } else if (value < -1) {
        error = "Limit users per client cannot be less than -1.";
      }
    }
    if (fieldName === "ZoomInfoContactLimit") {
      if (value === undefined || value === "") {
        error = "Zoom info contact limit cannot be blank. It must be at least -1";
      } else if (value < -1) {
        error = "Zoom info contact limit cannot be less than -1.";
      }
    }
    if (fieldName === "LimitTotalContactsPerClient") {
      if (value === undefined || value === "") {
        error = "Limit total contacts per client cannot be blank. It must be at least -1";
      } else if (value < -1) {
        error = "Limit total contacts per client cannot be less than -1.";
      }
    }
    

    if (fieldName === "TimeZone" && !value) {
      error = "Please select timezone.";
    }

    if (fieldName === "ClosedBy" && !value) {
      error = "Please select closed by.";
    }

    if (fieldName === "ServerNumber" && !value) {
      error = "Please select server number.";
    }

    if (fieldName === "ClientBillingPeriod" && !value) {
      error = "Please enter client billing period.";
    }

    if (fieldName === "ClientBillingAmount" && !value) {
      error = "Please enter client billing amount.";
    }else if (fieldName === 'ClientBillingAmount' && value){
      this.CalulateAmount();
    }

    if (fieldName === "BCCEmail" && value) {
      
      if (!emailRegex.test(value)) {
        error = "Invalid email.";
      }
    }
    if (fieldName === "ClientBillingEmail" && value) {
      if (!emailRegex.test(value)) {
        error = "Invalid email.";
      }
    }
    return error;
  };

  //Getting Slack ChannelID and name
  SlackChannelMethod = (name,value) => {
    this.setState({IsLoading:true})
    console.log(name)
    const InputParameters = {
      ChannelName:value
    }
    Axios({
      url: CommonConstants.MOL_APIURL + "/Utility/CheckSlackChannelExists",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      if(res.data.IsChannelExists === false && name === "SlackChannelInternel"){
        toast.error(<div className="toastsize">Client<br/>Please enter valid slack channel internal</div>)
      }else if(res.data.IsChannelExists === false && name === "SlackChannelExternal"){
        toast.error(<div className="toastsize">Client<br/>Please enter valid slack channel external</div>)
      }else{
        this.setState((prevState) => {
          const updatedFormData = {
            ...prevState.FormData
          };
        
          // Adding conditions here
          if (name === 'SlackChannelInternel') {
            updatedFormData.InternelChannelID = res.data.ChannelID
          }else if(name === 'SlackChannelExternal'){
            updatedFormData.ExternalChannelID = res.data.ChannelID
          }
          return {
            FormData: updatedFormData
          };
        });
      }
      
    this.setState({IsLoading:false})
      console.log(this.state.FormData)
    });
  }

  //Handle Change
  HandleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;
    // const error = this.validateField(name, fieldValue);

    this.setState((prevState) => ({
      FormData: {
        ...prevState.FormData,
        [name]: fieldValue,
      },
      // errors: {
      //   ...prevState.errors,
      //   [name]: error,
      // },
    }));
    if (name === 'ClientBillingPeriod' && value != -1 ) {
      this.CalulateAmount();
    }
    if (name === 'PPMEstMonths') {
      this.CalulateAmount();
    }
    
  };

  //Handle Blur
  HandleBlur = (e) => {
    const { name, value } = e.target;
    if((name === "SlackChannelInternel" || name === 'SlackChannelExternal') && value.trim() !== ""){
      this.SlackChannelMethod(name,value)
    }
    // if(name === "ClientName" && value.trim() != ""){
    //   let data = await this.CheckClient(value.trim());
    //   console.log(data)
    // }
    const error =  this.validateField(name, value);
    console.log(error)
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [name]: error,
      },
    }));
     
  };

  //submit btn
  handleSubmit = async(e) => {
    this.setState({IsLoading:true});
    // console.log(this.state.ClientNameExists)
    e.preventDefault();
    const { FormData } = this.state;
    const errors = {};
    this.setState({ IsButtonDisabled: true });
    Object.keys(FormData).forEach((fieldName) => {
      const error =  this.validateField(fieldName, FormData[fieldName]);
      if(fieldName === "ClientName" && FormData[fieldName].trim() !== ""){
        this.CheckClient(FormData[fieldName].trim())
        .then((exists) => {
          if (exists) {
            errors[fieldName] = "Client name already exist.";
          }
        })
      }
      if (error) {
        errors[fieldName] = error;
      }
      //this.setState({IsLoading:false});

    });

    if (Object.keys(errors).length === 0 && this.state.ClientNameExists === false) {
      if (new Date(this.state.FormData.ClientEndDate) < new Date(this.state.FormData.ClientStartDate)) {
        // ClientEndDate is less than ClientStartDate
        // Your code for handling this condition goes here
        toast.error("End date must be equal or greater than started date.")
        this.setState({IsLoading:false});
        this.setState({ IsButtonDisabled: false });
      } else {
        // ClientEndDate is greater than or equal to ClientStartDate
        // Your code for the other condition goes here
        let Image = await this.childRef.current.SubmitData();
        let ImageName = Image.FileName;
        this.setState({ FinalImg: ImageName, ButtonDisabled: true });
          var InputParameters;
          if (this.state.ImgFlag == false ) {
            InputParameters = {
              Name: this.state.FormData.ClientName,
              ResponderID: this.state.FormData.ResponderName,
              SalesStrategistID: this.state.FormData.SalesStrategistName,
              ColdsCallerID: this.state.FormData.ColdCallersName || [],
              ClientStatusID: this.state.FormData.ClientStatus,
              ClientStartDate: this.state.FormData.ClientStartDate,
              ClientEndDate: this.state.FormData.ClientEndDate,
              TargetAccountsperQtr: this.state.FormData.TargetAccountsperQtr,
              DefaultContractValue:this.state.FormData.DefaultContractValue,
              Website: this.state.FormData.Website,
              ReasonLostID: this.state.FormData.ReasonLost,
              LogoApprovalID: this.state.FormData.LogoApproval,
              TimeZoneID: this.state.FormData.TimeZone,
              TrackDomain: this.state.FormData.TrackDomain,
              ClosedBy:this.state.FormData.ClosedBy,
              BCCEmail: this.state.FormData.BCCEmail,
              SlackChannelInternal: this.state.FormData.SlackChannelInternel,
              SlackChannelExternal: this.state.FormData.SlackChannelExternal,
              InternelChannelID: this.state.FormData.InternelChannelID,
              ExternalChannelID: this.state.FormData.ExternalChannelID,
              ISAddMeetingsToBlacklist: this.state.FormData.DoNotAddMeetingsToBlacklist,
              IsSentMeetingCreateNotification: this.state.FormData.IsSentMeetingCreateNotification,
              IsSentMeetingRescheduleNotification: this.state.FormData.IsSentMeetingRescheduleNotification,
              ChannelsIDs: this.state.FormData.Channels,
              ServerNumber: this.state.FormData.ServerNumber,
              ClientBillingMethodID: this.state.FormData.ClientBillingMethod,
              BillingContact: this.state.FormData.ClientBillingContact,
              BillingEmail: this.state.FormData.ClientBillingEmail,
              BillingAddress: this.state.FormData.ClientBillingAddress,
              BillingDate: this.state.FormData.ClientBillingDate,
              BillingStatusID: this.state.FormData.ClientBillingStatus,
              BillingAmount: this.state.FormData.ClientBillingAmount,
              BillingPeriodID: this.state.FormData.ClientBillingPeriod,
              AvgMonthlyRevenue: this.state.FormData.AvgMonthlyRevenue,
              PPMEstMonths: this.state.FormData.PPMEstMonths,
              IsAllowEmod: this.state.FormData.IsAllowEmod,
              IsSeoReporting: this.state.FormData.IsSeoReporting,
              ParentUserID: this.state.ParentUserID,
              ClientID: this.state.ClientID,
              ProfileImage: ImageName,
              UserID: this.state.UserID,
              CreatedDt:new Date(),
              IsDeleted:false,
              LimitEmailsPerMonthPerClient:this.state.FormData.LimitEmailsPerMonthPerClient,
              LimitEmailsEmodPerMonthPerClient:this.state.FormData.LimitEmailsEmodPerMonthPerClient,
              LimitUsersPerClient:this.state.FormData.LimitUsersPerClient,
              ZoomInfoContactLimitType:this.state.FormData.ZoomInfoContactLimitType,
              ZoomInfoContactLimit:this.state.FormData.ZoomInfoContactLimit,
              LimitTotalContactsPerClient:this.state.FormData.LimitTotalContactsPerClient,
              OpenAI_API_KEY:this.state.FormData.OpenAI_API_KEY
            };
          } else {
            InputParameters = {
              Name: this.state.FormData.ClientName,
              ResponderID: this.state.FormData.ResponderName,
              SalesStrategistID: this.state.FormData.SalesStrategistName,
              ColdsCallerID: this.state.FormData.ColdCallersName || [],
              ClientStatusID: this.state.FormData.ClientStatus,
              ClientStartDate: this.state.FormData.ClientStartDate,
              ClientEndDate: this.state.FormData.ClientEndDate,
              TargetAccountsperQtr: this.state.FormData.TargetAccountsperQtr,
              DefaultContractValue:this.state.FormData.DefaultContractValue,
              Website: this.state.FormData.Website,
              ReasonLostID: this.state.FormData.ReasonLost,
              LogoApprovalID: this.state.FormData.LogoApproval,
              TimeZoneID: this.state.FormData.TimeZone,
              TrackDomain: this.state.FormData.TrackDomain,
              ClosedBy:this.state.FormData.ClosedBy,
              BCCEmail: this.state.FormData.BCCEmail,
              SlackChannelInternal: this.state.FormData.SlackChannelInternel,
              SlackChannelExternal: this.state.FormData.SlackChannelExternal,
              InternelChannelID: this.state.FormData.InternelChannelID,
              ExternalChannelID: this.state.FormData.ExternalChannelID,
              ISAddMeetingsToBlacklist: this.state.FormData.DoNotAddMeetingsToBlacklist,
              IsSentMeetingCreateNotification: this.state.FormData.IsSentMeetingCreateNotification,
              IsSentMeetingRescheduleNotification: this.state.FormData.IsSentMeetingRescheduleNotification,
              ChannelsIDs: this.state.FormData.Channels,
              ServerNumber: this.state.FormData.ServerNumber,
              ClientBillingMethodID: this.state.FormData.ClientBillingMethod,
              BillingContact: this.state.FormData.ClientBillingContact,
              BillingEmail: this.state.FormData.ClientBillingEmail,
              BillingAddress: this.state.FormData.ClientBillingAddress,
              BillingDate: this.state.FormData.ClientBillingDate,
              BillingStatusID: this.state.FormData.ClientBillingStatus,
              BillingAmount: this.state.FormData.ClientBillingAmount,
              BillingPeriodID: this.state.FormData.ClientBillingPeriod,
              AvgMonthlyRevenue: this.state.FormData.AvgMonthlyRevenue,
              PPMEstMonths: this.state.FormData.PPMEstMonths,
              IsAllowEmod: this.state.FormData.IsAllowEmod,
              IsSeoReporting: this.state.FormData.IsSeoReporting,
              ProfileImage: ImageName,
              ParentUserID: this.state.ParentUserID,
              ClientID: this.state.ClientID,
              UserID: this.state.UserID,
              CreatedDt:new Date(),
              IsDeleted:false,
              LimitEmailsPerMonthPerClient:this.state.FormData.LimitEmailsPerMonthPerClient,
              LimitEmailsEmodPerMonthPerClient:this.state.FormData.LimitEmailsEmodPerMonthPerClient,
              LimitUsersPerClient:this.state.FormData.LimitUsersPerClient,
              ZoomInfoContactLimitType:this.state.FormData.ZoomInfoContactLimitType,
              ZoomInfoContactLimit:this.state.FormData.ZoomInfoContactLimit,
              LimitTotalContactsPerClient:this.state.FormData.LimitTotalContactsPerClient,
              OpenAI_API_KEY:this.state.FormData.OpenAI_API_KEY
            };
          }
      
          Axios({
            url: CommonConstants.MOL_APIURL + "/clients/ClientsAdd",
            method: "POST",
            data: InputParameters,
          }).then(async(res) => {
            
            if (res.data.StatusMessage == "SUCCESS") {
              let ClientId = res.data.Data._id;
              toast.success(
                <div>
                  Client Add
                  <br />
                  Client added successfully.
                </div>
              );
              this.setState({ IsButtonDisabled: false });
              this.setState({IsLoading:false});
              history.push("/clients");
              await this.SaveDefaultValues(ClientId,this.state.ParentUserID,this.state.UserID,new Date());
            } else {
              toast.error(res.data.Message);
              this.setState({IsLoading:false});
              this.setState({ IsButtonDisabled: false });
            }
          });
      }

      
    } else {
      toast.error("Please fill all the required fields");
      this.setState({IsLoading:false});
      this.setState({ IsButtonDisabled: false });
      this.setState({ errors });
    }
  };

  //Save Default client default values

  SaveDefaultValues = async (ClientID,ParentUserID,UserID,CreatedDt) => {
    try{
      const InputParameters = {
        ClientID,
        ParentUserID,
        UserID,
        CreatedDt
      }
      Axios({
        url: CommonConstants.MOL_APIURL + "/clients/SaveDefaultClientDetails",
        method: "POST",
        data: InputParameters,
      }).then((res) => {
        
        if (res.data.StatusMessage == "SUCCESS") {
        } else {
          toast.error(res.data.Message);
        }
      });
    }catch(err){

    }
  }

  render() {
    let ResponderOption = this.state.ResponderList.map((val) => {
      return {
        value: val._id,
        label: `${val.ResponderName}  ${val.LastName}`,
      };
    });

    let ChannelsOption = this.state.ChannelList.map((val) => {
      return { value: val.FieldID, label: val.FieldName };
    });

    return (
      <>
      {
        this.state.IsLoading === true ?
        <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>:<></>
      }
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad lg-headertitle  float-left">Add Client </h4>
                </div>
              </div>

            <div className="bg-white"> 
              <div className="row mx-0 border-bottom">
                <div className="col">
                  <h4 className="headertitlepad xs-headertitle float-left">General</h4>
                </div>
              </div>

              <div className="row pt-2 mx-0">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Name</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Client Name"
                        value={this.state.FormData.ClientName}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                        type="text"
                        id="ClientName"
                        name="ClientName"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClientName"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Responder</label>
                    <div className="col-lg-8">
                      <Select
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        options={ResponderOption}
                        id="ResponderTag"
                        placeholder={"--Select--"}
                        isMulti
                        name="ResponderName"
                        // onChange={this.HandleChange}
                        //  onBlur={this.HandleBlur}
                        onChange={(val) => this.ResponderDropDownHandler(val)}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["ResponderName"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Sales Strategist</label>
                    <div className="col-lg-8">
                      <Select
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        options={this.state.SalesOption}
                        id="SalesStrategistTag"
                        placeholder={"--Select--"}
                        isMulti
                        onChange={(val) => this.SalesStrategistHandler(val)}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["SalesStrategistName"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Cold Callers</label>
                    <div className="col-lg-8">
                      <Select
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        options={this.state.ColdCallerOption}
                        id="ColdCallersTag"
                        placeholder={"--Select--"}
                        isMulti
                        onChange={(val) => this.ColdCallersHandler(val)}
                      />
                       {/* <span style={{ color: "red" }}>
                        {this.state.errors["ColdCallersName"]}
                      </span> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Client Status
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ClientStatus"
                        name="ClientStatus"
                        autoComplete="off"
                        value={this.state.FormData.ClientStatus}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      >
                        {<option value="">--Select--</option>}
                        {this.state.ClientStatusList.map((value) => (
                          <option key={value._id} value={value._id}>
                            {value.ClientStatusName}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClientStatus"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet"> Client Start Date</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder=""
                        type="date"
                        value={this.state.FormData.ClientStartDate}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                        id="ClientStartDate"
                        name="ClientStartDate"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClientStartDate"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Client End Date</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder=""
                        type="date"
                        id="ClientEndDate"
                        name="ClientEndDate"
                        value={this.state.FormData.ClientEndDate}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Target Accounts per Qtr</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder=""
                        value={this.state.FormData.TargetAccountsperQtr}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                        type="number"
                        id="TargetAccountsperQtr"
                        name="TargetAccountsperQtr"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Default Contract Value</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter meeting value"
                        value={this.state.FormData.DefaultContractValue}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                        type="number"
                        id="DefaultContractValue"
                        name="DefaultContractValue"
                        min={1}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Enter Website</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Website"
                        type="text"
                        name="Website"
                        id="Website"
                        value={this.state.FormData.Website}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Website"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Reason Lost </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ReasonLost"
                        name="ReasonLost"
                        autoComplete="off"
                        value={this.state.FormData.ReasonLost}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      >
                        {<option value="">--Select--</option>}
                        {this.state.ReasonLostList.map((value) => (
                          <option key={value.FieldID} value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ReasonLost"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Logo Approval</label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="LogoApproval"
                        name="LogoApproval"
                        autoComplete="off"
                        value={this.state.FormData.LogoApproval}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      >
                        {<option value="">--Select--</option>}
                        {this.state.LogoApprovalList.map((value) => (
                          <option key={value.FieldID} value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Time Zone </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="Timezone"
                        name="TimeZone"
                        autoComplete="off"
                        value={this.state.FormData.TimeZone}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      >
                        {<option value="">--Select--</option>}
                        {this.state.TimezoneList.map((value) => (
                          <option key={value.FieldID} value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["TimeZone"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Track Domain</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Track Domain"
                        type="text"
                        value={this.state.FormData.TrackDomain}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                        id="TrackDomain"
                        name="TrackDomain"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Closed By</label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ClosedBy"
                        name="ClosedBy"
                        autoComplete="off"
                        value={this.state.FormData.ClosedBy}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      >
                        {<option value="">--Select--</option>}
                        {this.state.ClosedByList.map((value) => (
                          <option key={value._id} value={value._id}>{value.Name}</option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClosedBy"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">BCC Email</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter BCC Email"
                        type="text"
                        name="BCCEmail"
                        id="BCCEmail"
                        value={this.state.FormData.BCCEmail}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                       <span style={{ color: "red" }}>
                        {this.state.errors["BCCEmail"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Slack Channel Internal </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Slack Channel Internal"
                        type="text"
                        name="SlackChannelInternel"
                        id="SlackChannelInternel"
                        value={this.state.FormData.SlackChannelInternel}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet"> Slack Channel External</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Slack Channel External"
                        type="text"
                        name="SlackChannelExternal"
                        id="SlackChannelExternal"
                        value={this.state.FormData.SlackChannelExternal}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet"> Limit Email Sent Per Month Per Client</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Limit Email Sent Per Client"
                        type="number"
                        min={-1}
                        name="LimitEmailsPerMonthPerClient"
                        id="LimitEmailsPerMonthPerClient"
                        value={this.state.FormData.LimitEmailsPerMonthPerClient}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                       <span style={{ color: "red" }}>
                        {this.state.errors["LimitEmailsPerMonthPerClient"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet"> Limit Emails Emod Per Month Per Client</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Limit Emails Emod Per Month Per Client"
                        type="number"
                        min={-1}
                        name="LimitEmailsEmodPerMonthPerClient"
                        id="LimitEmailsEmodPerMonthPerClient"
                        value={this.state.FormData.LimitEmailsEmodPerMonthPerClient}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["LimitEmailsEmodPerMonthPerClient"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet"> Limit Users Per Client</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Limit Users Per Client"
                        type="number"
                        min={-1}
                        name="LimitUsersPerClient"
                        id="LimitUsersPerClient"
                        value={this.state.FormData.LimitUsersPerClient}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["LimitUsersPerClient"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet"> Limit Total Contacts Per Client</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Limit Total Contacts Per Client"
                        type="number"
                        min={-1}
                        name="LimitTotalContactsPerClient"
                        id="LimitTotalContactsPerClient"
                        value={this.state.FormData.LimitTotalContactsPerClient}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["LimitTotalContactsPerClient"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Zoom Info Contact Limit Type</label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ZoomInfoContactLimitType"
                        name="ZoomInfoContactLimitType"
                        autoComplete="off"
                        value={this.state.FormData.ZoomInfoContactLimitType}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      >
                        <option value="">--Select--</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Yearly">Yearly</option>
                       
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet"> Zoom Info Contact Limit</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Zoom Info Contact Limit"
                        type="number"
                        min={-1}
                        name="ZoomInfoContactLimit"
                        id="ZoomInfoContactLimit"
                        value={this.state.FormData.ZoomInfoContactLimit}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                       <span style={{ color: "red" }}>
                        {this.state.errors["ZoomInfoContactLimit"]}
                      </span>
                    </div>
                  </div>
                </div>

               

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet"> Open AI API Key</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Open AI API Key"
                        type="text"
                        name="OpenAI_API_KEY"
                        id="OpenAI_API_KEY"
                        value={this.state.FormData.OpenAI_API_KEY}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group"></div>
                <div className="col-lg-6 boxsinput_group">
                  <label className="check_bx floatrighter mr-3">
                    <input
                      id="DoNotAddMeetingToBlackList"
                      name="DoNotAddMeetingsToBlacklist"
                      type="checkbox"
                      checked={this.state.FormData.DoNotAddMeetingsToBlacklist}
                      onChange={this.HandleChange}
                      onBlur={this.HandleBlur}
                    />
                    <span className="checkmark"></span>
                    Do Not Add Meetings To Blacklist?
                  </label>
                </div>
                <div className="col-lg-6"></div>

                <div className="col-lg-6 boxsinput_group">
                  <label className="check_bx floatrighter mr-3">
                    <input
                      id="IsSentMeetingCreateNotif"
                      type="checkbox"
                      checked={
                        this.state.FormData.IsSentMeetingCreateNotification
                      }
                      onChange={this.HandleChange}
                      onBlur={this.HandleBlur}
                      name="IsSentMeetingCreateNotification"
                    />
                    <span className="checkmark"></span>
                    Is Sent Meeting Create Notification
                  </label>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <label className="check_bx floatrighter mr-3">
                    <input
                      id="IsSentMeetingReschedulesNotif"
                      type="checkbox"
                      name="IsSentMeetingRescheduleNotification"
                      checked={
                        this.state.FormData.IsSentMeetingRescheduleNotification
                      }
                      onChange={this.HandleChange}
                      onBlur={this.HandleBlur}
                    />
                    <span className="checkmark"></span>
                    Is Sent Meeting Reschedule Notification
                  </label>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Channels</label>
                    <div className="col-lg-8">
                     
                      <Select
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        options={ChannelsOption}
                        id="Channels"
                        placeholder={"--Select--"}
                        isMulti
                        onChange={(val) => this.ChannelDropDownHandler(val)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Server Number
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control"
                        name="ServerNumber"
                        id="ServerNumber"
                        value={this.state.FormData.ServerNumber}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      >
                        <option value="">--Select--</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ServerNumber"]}
                      </span>
                    </div>
                  </div>
                </div>

               
                 <ProfileImageUpload
                profileImage={this.state.FinalImg}
                ref={this.childRef}
                NewForm={true}
              />
              <div className="col-lg-4 boxsinput_group mb-4"></div>
                  <div className="col-lg-4 boxsinput_group mb-4">
                    <div className="row">
                    <label className="col-lg-6 view-lable-meet"> eMod </label>

                      <div className="toggleswich mt-2">
                        <input type="checkbox" className="checktogle" id="eMod" name="IsAllowEmod" checked={this.state.FormData.IsAllowEmod}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}/>
                        <b className="switch">
                          <span className="checkion"></span>
                          <span className="uncheckion"></span>
                        </b>
                        <b className="track"></b>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-4 boxsinput_group mb-4">
                    <div className="row">
                    <label className="col-lg-6 view-lable-meet"> SEO </label>

                      <div className="toggleswich mt-2">
                        <input type="checkbox" className="checktogle" id="SEO" name="IsSeoReporting" checked={this.state.FormData.IsSeoReporting}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}/>
                        <b className="switch">
                          <span className="checkion"></span>
                          <span className="uncheckion"></span>
                        </b>
                        <b className="track"></b>
                      </div>

                    </div>
                  </div>
              </div>
            </div>

            <div className="bg-white mt-3">
              <div className="row borderbtnm mx-0">
                <div className="col">
                  <h4 className="headertitlepad xs-headertitle  float-left">Billing</h4>
                </div>
              </div>

              <div className="row py-2 mx-0">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Client Billing Method
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ClientBillingMethod"
                        name="ClientBillingMethod"
                        autoComplete="off"
                        value={this.state.FormData.ClientBillingMethod}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      >
                        {<option value="">--Select--</option>}
                        {this.state.ClientBillingMethodList.map((value) => (
                          <option key={value.FieldID} value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Client Billing Contact
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Client Billing Contact"
                        type="text"
                        name="ClientBillingContact"
                        id="ClientBillingContact"
                        value={this.state.FormData.ClientBillingContact}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Client Billing Email
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Client Billing Email"
                        type="text"
                        name="ClientBillingEmail"
                        id="ClientBillingEmail"
                        value={this.state.FormData.ClientBillingEmail}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClientBillingEmail"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Client Billing Address
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Client Billing Address"
                        type="text"
                        name="ClientBillingAddress"
                        id="ClientBillingAddress"
                        value={this.state.FormData.ClientBillingAddress}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Client Billing Date
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Client Billing Email"
                        type="date"
                        name="ClientBillingDate"
                        id="ClientBillingDate"
                        value={this.state.FormData.ClientBillingDate}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Client Billing Status
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ClientBillingStatus"
                        name="ClientBillingStatus"
                        autoComplete="off"
                        value={this.state.FormData.ClientBillingStatus}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      >
                        {<option value="">--Select--</option>}
                        {this.state.ClientBillingStatusList.map((value) => (
                          <option key={value.FieldID} value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Client Billing Amount
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Client Billing Amount"
                        type="text"
                        id="ClientBillingAmount"
                        name="ClientBillingAmount"
                        value={this.state.FormData.ClientBillingAmount}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClientBillingAmount"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Client Billing Period
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ClientBillingPeriod"
                        name="ClientBillingPeriod"
                        autoComplete="off"
                        // onChange={()=>{()}}
                        value={this.state.FormData.ClientBillingPeriod}
                        onChange={(e) => {
                          this.HandleChange(e);
                        }}
                        onBlur={this.HandleBlur}
                      >
                        {<option value="-1">--Select--</option>}
                        {this.state.ClientBillingPeriodList.map((value) => (
                          <option key={value.FieldID} value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClientBillingPeriod"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Avg. Monthly Revenue
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter avg monthly revenue"
                        type="text"
                        name="AvgMonthlyRevenue"
                        id="AvgMonthlyRevenue"
                        value={this.state.FormData.AvgMonthlyRevenue}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      PPM Est. Months
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter avg ppm est months"
                        type="text"
                        id="PPMEstMonths"
                        name="PPMEstMonths"
                        value={this.state.FormData.PPMEstMonths}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div className="row py-4">
                <div className="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.handleSubmit}
                    className="btn btn-primary btn-lightgreen mr-1"
                    disabled={this.state.IsButtonDisabled}
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddClientsPage = connect(
  mapState,
  actionCreators
)(AddClientsPage);
export { connectedAddClientsPage as AddClientsPage };
