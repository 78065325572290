import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
var CryptoJS = require("crypto-js");

import logo from "../../../images/SalesHive.png";
import logobgs from "../../../images/Loginvector.svg";

import { history } from "../../../_helpers";
const Uvalidation = require("../../../_helpers/user-validation");
const EmailTemplete = require("../../../_helpers/email-templete");
const Fileupload = require("../../../_helpers/fileupload");
import { CommonConstants } from "../../../_constants/common.constants";

import ProfileImageUpload from '../../../_components/ProfileUpload';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
const REACT_APP_ENCRYPTION_SALT = process.env.REACT_APP_ENCRYPTION_SALT

class AddColdCallersPage extends React.Component {
  constructor(props) {
    super(props);
    this.childRef = React.createRef();

    this.state = {
      Email: "",
      Open: false,
      FileObjects: [],
      FinalImg: "",
      LogoImageName:"",
      UserID:null,
      VerificationCode:null,
      ColdCallerTeams:[],
      showPassword: false,
      FormData:{
        Email:"",
        FirstName:"",
        LastName:"",
        Password:"",
        SlackAccount:"",
        ColdCallerTeamID:"",
        IsTwoStepVerification:false
      },
      errors:{},
      ButtonDisabled:false,
      IsLoading:false,
      DisplayAlreadyExistsMessage:false,
      DisplayUserAddedMessage:false
    };
    // this.SubmitData = this.SubmitData.bind(this);
    this.BackButton = this.BackButton.bind(this);
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  DecryptorMethod = (data) => {
    var FinalTmp = decodeURIComponent(data).split(" ").join("+");
    var bytes = CryptoJS.AES.decrypt(FinalTmp, REACT_APP_ENCRYPTION_SALT);
    var EncryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return EncryptedData;
  }

  componentDidMount() {
    document.title = "Add Sales Development Reps | SalesHive"
    const QueryParams = new URLSearchParams(window.location.search);
    const Tmp = QueryParams.get("email");
    const UserID = QueryParams.get("UID");
    const VCs = QueryParams.get("VC");

    const Email = this.DecryptorMethod(Tmp)
    const UID = this.DecryptorMethod(UserID)
    const VC = this.DecryptorMethod(VCs)
    this.setState((prevState) => ({
      FormData: {
        ...prevState.FormData,
        Email: Email,
        UserID: UID,
        RoleId: 5,
        IsActive: true,
        IsDeleted: false,
        CreatedDate: new Date(),
        LastUpdatedBy: null,
        LastUpdatedDate: null,
        
      },
    }));      

    this.setState({ Email: Email });
    this.setState({ UserID: UID });
    this.setState({ VerificationCode: VC });
    this.ColdCallerTeam(UID)

    const InputParameters = {
      Email:Email,
      Page:"ColdCaller"
    }
        Axios({
                url: CommonConstants.MOL_APIURL + "/AdminUser/ValidationAddUser",
                method: "POST",
                data: InputParameters,
        }).then((res) => {
         if (res.data.StatusMessage == "SUCCESS") {
            if(res.data.Data.length > 0){
                 this.setState({AlreadyExists:true})
            }else{
                  this.setState({AlreadyExists:false})

            }
          }
        });
   
  }

   //Get Cold caller team Dropdown
   ColdCallerTeam(UID){
    var InputParameter = {
      UserID: UID,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/cold_callers_profile/ColdCallersTeamDropDown",
      method: "POST",
      data: InputParameter,
    }).then((Result) => {
      this.setState({ColdCallerTeams:Result.data.Data})
    
    });
  }



//back button
  BackButton() {
    history.push("/coldcallers");
  }

  // Function to validate individual form field
  ValidateField = (fieldName, value) => {
    var Flag = true;
    this.setState((prevState) => {
      const newErrors = { ...prevState.errors };
  
      if (fieldName === "FirstName") {
        if (value.trim() === "") {
          newErrors.FirstName = "Please enter first name.";
          Flag = false;
        } else {
          newErrors.FirstName = "";
          Flag = true;
        }
      } else if (fieldName === "LastName") {
        if (value.trim() === "") {
          Flag = false;
          newErrors.LastName = "Please enter last name.";
        } else {
          Flag = true;

          newErrors.LastName = "";
        }
      } else if (fieldName === "Password") {
        newErrors.Password = "";
        if (value.trim() === "") {
          Flag = false;

          newErrors.Password = "Please enter password.";
        } else if (value.length < 6 || value.length > 20) {
          Flag = false;

          newErrors.Password = "Password must be 6 to 20 chars long.";
        }else{
          Flag = true;

        }
      }else if(fieldName === "SlackAccount") {
        newErrors.SlackAccount = "";
        if (value.trim() === "") {
          newErrors.SlackAccount = "Please enter slack account.";
        }else{
          Flag = true;

        }
      }
      return { errors: newErrors };
    });
    
    return Flag;
  };
  
  //Update OnChange Handler
  HandleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    this.setState((prevState) => {
      let updatedFormData = { ...prevState.FormData };
    // Check if the input is a checkbox
    if (type === "checkbox") {
      // Convert "on" and "off" to true and false
      updatedFormData[name] = checked;
  }else {
      updatedFormData[name] = value;
  }
      this.ValidateField(name, value);
      return {
        FormData: updatedFormData,
      };
    });
  };

  //Update OnBlur Handler
  HandleBlur = (e) => {
    const { name, value } = e.target;
    this.ValidateField(name, value);
  };

  //Validation field method
  ValidateMethod = (fieldName,value) => {
    let Flag = true;
    if (fieldName === "FirstName") {
      if (value.trim() === "") {
        Flag = false;
      } else {
        Flag = true;
      }
    } else if (fieldName === "LastName") {
      if (value.trim() === "") {
        Flag = false;
      } else {
        Flag = true;
      }
    } else if (fieldName === "Password") {
      if (value.trim() === "") {
        Flag = false;
      } else if (value.length < 6 || value.length > 20) {
        Flag = false;
      }else{
        Flag = true;

      }
    } else if (fieldName === "SlackAccount") {
      if (value.trim() === "") {
        Flag = false;
      } else {
        Flag = true;
      }
    }
    return Flag;
  }
  
  //Update User Profile Data
  HandleSubmit = async (e) => {
    e.preventDefault();
  
    this.setState({ButtonDisabled: true });
  
    const { FormData } = this.state;
   
    const fieldNames = Object.keys(FormData);
    let arr = []
    // Iterate through fields and validate
    fieldNames.forEach(async(fieldName) => {
      this.ValidateField(fieldName, FormData[fieldName]);
      arr.push(this.ValidateMethod(fieldName, FormData[fieldName]))
    });
    let isValid = arr.includes(false)
    
    
    // Check if isValid is still true
    if (!isValid && !this.state.AlreadyExists) {
      let Image = await this.childRef.current.SubmitData();
      let ImageName = Image.FileName;
      this.setState({ FinalImg: ImageName});
      FormData['PassWord'] = CryptoJS.AES.encrypt(FormData.Password,REACT_APP_ENCRYPTION_SALT).toString();
      FormData['ProfileImage'] = ImageName;
      FormData['ColdCallerTeamID'] = FormData.ColdCallerTeamID ==="" ? null : FormData.ColdCallerTeamID;
      this.setState({DisplayAlreadyExistsMessage:false})

      Axios({
        url:CommonConstants.MOL_APIURL +"/cold_callers_profile/ColdCallersAdd",
        method: "POST",
        data: FormData,
      }).then((res) => {
        if (res) {
          this.setState({DisplayUserAddedMessage:false})
          this.setState({ ButtonDisabled: false });
          localStorage.removeItem("LoginData");
          localStorage.removeItem("id");
          history.push('/');

        }
      });
    } else if(isValid){
       this.setState({DisplayAlreadyExistsMessage:false})
      this.setState({ ButtonDisabled: false });
    }else{
      this.setState({DisplayAlreadyExistsMessage:true});
      this.setState({ ButtonDisabled: false });
    }
  };
  handleFocus = (e) => {
    // Remove readOnly when the input is focused
    e.target.removeAttribute('readonly');
  };
  
  render() {
    const { FormData, errors } = this.state;

    const { showTemplate, showSaveAlert } = this.state;
    const { file, UserName, PassWord, submitted, radio, SelectedLang } =
      this.state;
    const customStylesLeg = {
      option: (provided, state) => ({
        ...provided,
        paddingLeft: 20,
        color: state.isSelected ? "#5c0f8b" : "#5c0f8b",
        fontWeight: state.isSelected ? "900" : "400",
      }),
    };
    return (
      <div className="login-main">
        <div className="row h-100">
          <div className="col-xs-12 col-md-6 logo-bgbod padding-btn">
            <img src={logobgs} className="login-vector" />

            <div className="m-login__logo">
              <a href="/">
                <img src={logo} className="main-logo" />
              </a>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 d-flex">
            <div className="full-hegiht">
              <div id="login" className="centerheigh ">
                <div className="heading">
                  <h1 className="lefter_title">Add ColdCallers</h1>
                </div>
                {
                  this.state.DisplayAlreadyExistsMessage ?  <p id="UserExists_e" className="errorinput">
                  {" "}User already exists{" "}
                </p> : <></>
                }

                {
                  this.state.DisplayAlreadyExistsMessage ?  <p id="UserAdd_e" className="font-success">
                  {" "}Sales Strategists added successfully{" "}
                </p> : <></>
                }
                   
                <form name="login" className="form" autoComplete="off">
                  <div className="input-control">
                    <label htmlFor="FirstName" className="input-label" hidden>
                      First Name
                    </label>
                    <input
                      type="text"
                      id="FirstName"
                      name="FirstName"
                      className="input-field"
                      placeholder="First Name"
                      autoComplete = "off"
                      value={FormData.FirstName}
                      onChange={this.HandleInputChange}
                      onBlur={this.HandleBlur}
                    />
                      {errors.FirstName && (
                          <div className="errorinput">{errors.FirstName}</div>
                        )}
                  </div>
                  <div className="input-control">
                    <label htmlFor="LastName" className="input-label" hidden>
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="LastName"
                      name="LastName"
                      className="input-field"
                      placeholder="Last Name"
                      autoComplete="off"
                      readOnly
                      value={FormData.LastName}
                      onChange={this.HandleInputChange}
                      onBlur={this.HandleBlur}
                      onFocus={this.handleFocus}
                    />
                     
                    
                  </div>
                  <div className="input-control">
                    <label htmlFor="Email" className="input-label" hidden>
                      Email Address
                    </label>
                    <input
                      type="Email"
                      id="Email"
                      name="Email"
                      className="input-field"
                      placeholder="Email Address"
                      disabled
                      value={FormData.Email}
                      onChange={this.HandleInputChange}
                      onBlur={this.HandleBlur}
                    />
                      
                  </div>
                  <div className="input-control">
                    <label htmlFor="PassWord" className="input-label" hidden>
                      PassWord
                    </label>
                    <div className='position-relative d-flex align-items-center'>
                    <input
                      type={this.state.showPassword ? 'text' : 'password'}
                      id="PassWord"
                      name="Password"
                      className="input-field"
                      placeholder="Password"
                      autoComplete = "off"
                      value={FormData.Password}
                      onChange={this.HandleInputChange}
                      onBlur={this.HandleBlur}
                    />
                         <IconButton
                      disableRipple
                      onClick={this.handleClickShowPassword}
                      className='position-absolute rigterpin'
                    >
                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    </div>
                      {errors.Password && (
                          <div className="errorinput">{errors.Password}</div>
                        )}
                  </div>
                 
                  <div className="input-control">
                    <label htmlFor="PassWord" className="input-label" hidden>
                      Cold callers
                    </label>
                    <select
                className="form-control  m-input"
                id="ColdCallerTeamID"
                name="ColdCallerTeamID"
                autoComplete = "off"
                value={FormData.ColdCallerTeamID}
                onChange={this.HandleInputChange}
                onBlur={this.HandleBlur}
              >
                <option value="">--Select--</option>
                {this.state.ColdCallerTeams.map((value) => (
                  <option key={value._id} value={value._id}>
                    {value.ColdCallerTeamName}
                  </option>
                ))}
              </select>
                   
                  </div>

                   <div className="input-control">
                    <label htmlFor="PassWord" className="input-label" hidden>
                      Slack Account
                    </label>
                    <input
                      type="text"
                      id="SlackAccount"
                      name="SlackAccount"
                      className="input-field"
                      placeholder="Slack Account"
                      value={FormData.SlackAccount}
                      onChange={this.HandleInputChange}
                      onBlur={this.HandleBlur}
                    />
                      {errors.SlackAccount && (
                          <div className="errorinput">{errors.SlackAccount}</div>
                        )}
                  </div>
                  <div className="col-lg-6 boxsinput_group d-flex">
                  <div className="toggleswich mr-2">
                    <input type="checkbox" className="checktogle" id="IsTwoStepVerification" name="IsTwoStepVerification" checked={FormData.IsTwoStepVerification}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}/>
                    <b className="switch"> 
                      <span className="checkion"></span>
                      <span className="uncheckion"></span>
                    </b>
                    <b className="track"></b>
                  </div>
                  <label>Is Two Step Verification</label> 
                </div>
                  <ProfileImageUpload
                    profileImage={this.state.FinalImg}
                    ref={this.childRef}
                    NewForm={true}
                  />

                  <div className="input-control btn-center">
                    <button
                      type="button"
                      name="submit"
                      id="submit"
                      className="input-submit"
                      value="Save"
                      disabled={this.state.ButtonDisabled}
                      onClick={this.HandleSubmit}
                    >
                      <i className="la la-save"></i> Save
                    </button>
                    <a
                      type="button"
                      name="submit"
                      className="input-submit"
                      value="Save"
                      onClick={() => {
                        this.BackButton();
                      }}
                    >
                      <i className="la la-arrow-circle-left"></i> Back
                    </a>
                  
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  return state;
}

const actionCreators = {};

const connectedAddColdCallersPage = connect(
  mapState,
  actionCreators
)(AddColdCallersPage);
export { connectedAddColdCallersPage as AddColdCallersPage };
