import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { GetUserDetails } from "../_helpers/Utility";
import { history } from '../_helpers';
import { userRoutes, clientRoutes, commonRoutes } from '../route';
import ScrollToTop from '../_helpers/ScrollToTop';
import ChatbotComponent from "../_components/common/ChatbotComponent.js"
import Cookies from 'js-cookie';
class App extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        window.addEventListener('focus', this.handleFocus);
        const pathname = window.location.pathname;
        if (pathname == "/") {
            var Details = GetUserDetails();
            if (Details != null) {
                history.push("/kpis");
            }
            else {
                history.push("/");
            }
        }
        if (pathname !== "/addresponder" && pathname !== "/addcoldcallers" && pathname !== "/addadmin" &&
            pathname !== "/addsalesstrategists" && pathname !== "/addclientusers" && pathname !== "/addseo" && pathname !== "/resetpassword" &&
            pathname !== "/updateemailresponder" && pathname !== "/updateemailcoldcallers" && pathname !== "/updateemailstrategists" &&
            pathname !== "/updateemailadmin" && pathname !== "/updateusersperclient" && pathname !== "/updateemailseo") {
            this.checkLoginStatus()
        }
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
    }
    // checkLoginStatus = () => {
    //     const loginTimestamp = localStorage.getItem('loginTimestamp');
    //     const pathname = window.location.pathname;
    //     if (!loginTimestamp) {
    //         // No login timestamp found, redirect to login page
    //         this.redirectToLogin();
    //         return;
    //     }

    //     const loginDate = new Date(loginTimestamp);
    //     const now = new Date();

    //     // Compare the stored date with the current date
    //     if (this.isDifferentDay(loginDate, now)) {
    //         // It's a new day, redirect to login page
    //         this.redirectToLogin();
    //     } else {
    //         // User is logged in for the current day
            
    //     }
    // };

    // isDifferentDay = (date1, date2) => {
    //     return date1.getFullYear() !== date2.getFullYear() ||
    //            date1.getMonth() !== date2.getMonth() ||
    //            date1.getDate() !== date2.getDate();
    // };

    checkLoginStatus = () => {
        const loginTimestamp = localStorage.getItem('loginTimestamp');
        if (!loginTimestamp) {
            // No login timestamp found, redirect to login page
            this.redirectToLogin();
            return;
        }
    
        const loginDate = new Date(loginTimestamp);
        const now = new Date();

        // Reset the time part to midnight (00:00:00) for accurate date-only comparison
        loginDate.setHours(0, 0, 0, 0);
        now.setHours(0, 0, 0, 0);

        // Calculate the difference in time (in milliseconds) and convert to days
        const timeDifference = loginDate-now ; // This will be a positive or zero value
        const daysDifference = timeDifference / (1000 * 3600 * 24);
        if (daysDifference > 7) {
            // More than seven days have passed, clear the localStorage and redirect to login page
            this.redirectToLogin();
            return;
        }
    };
    
  
    redirectToLogin = () => {
        // Clear the stored login timestamp
        localStorage.removeItem('loginTimestamp');
        localStorage.removeItem('LoginData');

        // Redirect to the login page
        history.push('/login');
    };

    handleFocus = () => {
        if (Cookies.get('dialSession') === 'Started') {
            Cookies.remove('dialSession', { path: '/' });
            setTimeout(() => {
                window.location.reload();
            }, 5000); // 5000 milliseconds = 5 seconds
        }
    };

    handleVisibilityChange = () => {

        if (document.visibilityState === 'visible') {
            var Details = GetUserDetails();
            if (window.location.pathname !== "/twostepverification" && Details == null) {
                history.push("/");
            }
            else if (Details == null) {
                history.push("/");
            }
        }
    };

    render() {
        const { alert } = this.props;
        var Chatbot = <ChatbotComponent />
        return (
            <div className="jumbotron table-set">
                <Router history={history}>
                    {Chatbot}
                    <ScrollToTop>
                        <Switch>
                            {userRoutes.map((route, index) => (
                                <Route key={index} exact path={route.path} component={route.component} />
                            ))}
                            {clientRoutes.map((route, index) => (
                                <Route key={index} exact path={route.path} component={route.component} />
                            ))}
                            {commonRoutes.map((route, index) => (
                                <Route key={index} exact path={route.path} component={route.component} />
                            ))}

                            <Redirect from="*" to="/" />
                        </Switch>
                    </ScrollToTop>
                </Router>
            </div>
        );
    }
}

export default App;