
import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  IconButton,
  Collapse,
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

const SortingIcons = ({SortedBy,sortedColumn,ColumnName}) => {
  return (
    <span className="shorting">
           <ArrowUpward className={SortedBy === 1 && sortedColumn === ColumnName? "active": null}/>
           <ArrowDownward className={SortedBy === -1 && sortedColumn === ColumnName? "active": null}/>
    </span>
  )
}

function PotentialMeeting({UserID,ClientID,Role,AccountID,Domain}) {

    const [listData, setListData] = useState([]);
  const [Rows, setRows] = useState([]);
  const [Rlen, setRlen] = useState(0);
  const [Flen, setFlen] = useState(0);
  const [CountPage, setCountPage] = useState(0);
  const [Page, setPage] = useState(1);
  const [RowsPerPage, setRowsPerPage] = useState(100);
  const [Search, setSearch] = useState("");
  const [SortField, setSortField] = useState("CreatedDate");
  const [SortedBy, setSortedBy] = useState(-1);
  const [SflagP, setSflagP] = useState(false);
  const [Open, setOpen] = useState(false);
  const [PotentialNotes, setPotentialNotes] = useState([]);
  const [sortedColumn, setSortedColumn] = React.useState('CreatedDate');

  useEffect(() => {
    PotentialListsGet(Search, Page, RowsPerPage);
  }, [Search, Page, RowsPerPage, SortField, SortedBy,Domain,AccountID,ClientID]);

  const PotentialListsGet = (search, page, rowsPerPage) => {
    if(Domain?.trim() != "" && AccountID?.trim() != "" && ClientID?.trim() != ""){
        const inputParameters = {
            ClientID: ClientID,
            Domain: Domain,
            Page: page,
            RowsPerPage: rowsPerPage,
            Sort: true,
            Field: SortField,
            Role: Role,
            SortBy: SortedBy,
            Search: search,
            Type: 'User',
          };
      
          Axios({
            url: CommonConstants.MOL_APIURL + '/accounts/PotentialMeetingGet',
            method: 'POST',
            data: inputParameters,
          }).then((PListResult) => {
            setListData(PListResult.data?.PageData);
            setRows(PListResult.data?.PageData);
            setRlen(PListResult.data?.TotalCount);
            setFlen(PListResult.data?.TotalCount);
            setCountPage(PListResult.data?.PageCount);
            setPotentialNotes(PListResult.data?.PotentialNotes);
          });
    }
    
  };

  const PotentialSorting = (Field) => {
    setSortField(Field)
    setSortedColumn(Field);
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      setSortedBy(1);
      SortBy = 1;
    } else {
      setSortedBy(SortBy);
    }
  
    setSortField(Field);

    PotentialListsGet(SearchedVal, Page, RowsPerPage);
  };

  const RequestSearchListP = (Event) => {
    if (Event.key === 'Enter') {
      const SearchedVal = Event.target.value;
      setSearch(SearchedVal);

      PotentialListsGet(SearchedVal, Page, RowsPerPage);
    }
  };

  // change display rows
 const ChangeRowSelectedListP = (Event) => {
    setRowsPerPage(Number(Event.target.value))
    setPage(Number(1))
    var SearchedVal = document.getElementById("PotentialMeetingSearch").value;
    setSearch(SearchedVal)
    var InputParameter = {
      ClientID: ClientID,
      Domain: Domain,
      Page: 1,
      RowsPerPage: Number(Event.target.value),
      Sort: true,
      Field: SortField,
      Role: Role,
      SortBy: SortedBy,
      Search: SearchedVal,
      Type: "User",
    };
    const AccountContactList = Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/PotentialMeetingGet",
      method: "POST",
      data: InputParameter,
    });
    AccountContactList.then((PListResult) => {
      if(PListResult.data.StatusMessage === "SUCCESS"){
        setListData(PListResult.data?.PageData);
        setRows(PListResult.data?.PageData);
        setRlen(PListResult.data?.TotalCount);
        setFlen(PListResult.data?.TotalCount);
        setCountPage(PListResult.data?.PageCount);
        setPotentialNotes(PListResult.data?.PotentialNotes);
      }else{
        toast.error(PListResult.data.Message)
      }
     
    });
  };

  // change page
 const HandleChangePageListP = (Event, NewPage) => {
    if (NewPage == Page) {
      setPage(NewPage)
    } else {
      setPage(NewPage)
      var SearchedVal = document.getElementById("PotentialMeetingSearch").value;
      var InputParameter = {
        ClientID: ClientID,
        Domain: Domain,
        Page: NewPage,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        Role: Role,
        SortBy: SortedBy,
        Search: SearchedVal,
        Type: "User",
      };
      const AccountContactList = Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/PotentialMeetingGet",
        method: "POST",
        data: InputParameter,
      });
      AccountContactList.then((PListResult) => {
        if(PListResult.data.StatusMessage === "SUCCESS"){
          setListData(PListResult.data?.PageData);
          setRows(PListResult.data?.PageData);
          setRlen(PListResult.data?.TotalCount);
          setFlen(PListResult.data?.TotalCount);
          setCountPage(PListResult.data?.PageCount);
          setPotentialNotes(PListResult.data?.PotentialNotes);
        }else{
          toast.error(PListResult.data.Message)
        }
      });
    }
  };

  // edit contact page
 const PotenialEditPages = (id) => {
    history.push({
      pathname: "/viewpotentialmeeting",
      state: {
        data: id,
        Pagename: "AccountContact",
        AccountData: AccountID,
      },
    });
  };

  // add contact page
 const PotentialAdd = () => {
    history.push({
      pathname: "/addpotentialmeeting",
      state: { data: AccountID, Pagename: "AccountPotential" },
    });
  };

  const ViewPotentialMeeting = (id) => {
    history.push({
        pathname: "/viewpotentialmeeting",
        state: {
            data: id,
            Pagename: 'AccountContact',
            AccountData: AccountID,
          },
      });
  }


  return (
    <>
            <div className="row">
                <div className="col">
                  <h3 className="xs-headertitle py-3 mb-0">
                    Potential meeting
                  </h3>
                </div>
                <div className="col py-3 text-right">
                  <button
                    onClick={() => {
                      PotentialAdd();
                    }}
                    className="btn btngroup m-btn "
                  >
                    <i className="la la-plus la-icon-font-size-13"></i>
                    <span className="Mobile_button">Add</span>
                  </button>
                </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={ChangeRowSelectedListP}
                    value={RowsPerPage}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value} key={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="PotentialMeetingSearch"
                    onKeyPress={(event) => RequestSearchListP(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell onClick={() => {PotentialSorting("ContactName");}}>
                          Contact Name
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Title"} />
                      </TableCell>
                      <TableCell  onClick={() => {PotentialSorting("Email");}}>
                          Email
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Email"} />
                      </TableCell>

                      <TableCell onClick={() => {PotentialSorting("Company");}}>
                          Company
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Company"} />
                      </TableCell>
                      <TableCell  onClick={() => {PotentialSorting("Title");}}>
                          Title
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Title"} />
                      </TableCell>
                      <TableCell onClick={() => {PotentialSorting("EngagamentStatus");}}>
                      Engagement Status
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"EngagamentStatus"} />
                      </TableCell>
                      <TableCell onClick={() => {ContactSorting("MeetingSourceName"); }}>
                          Meeting Source Name
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"MeetingSourceName"} />
                      </TableCell>
                      <TableCell onClick={() => {PotentialSorting("MeetingOwnerName");}}>
                          Owner Name
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"MeetingOwnerName"} />
                      </TableCell>
                      <TableCell onClick={() => {PotentialSorting("ReminderEmailAccount");}}>
                          Reminder Email Account
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"ReminderEmailAccount"} />
                      </TableCell>
                      <TableCell  onClick={() => {PotentialSorting("CreatedDate");}}>
                          Created Date
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"CreatedDate"} />
                      </TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Rows?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      Rows?.map((row) => (
                        <React.Fragment key={row._id}>
                          <TableRow>
                            <TableCell align="center">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() =>
                                    setOpen((prev) => ({
                                        ...prev,
                                        [row._id]: !prev[row._id],
                                      }))
                                }
                              >
                                {Open[row._id] ? (
                                  <i
                                    className="fa fa-minus-circle minusl"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-plus-circle plusbl"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell> <a onClick={()=>{ViewPotentialMeeting(row._id)}}><u>{row?.ContactName}</u></a></TableCell>
                            <TableCell>{row.Email}</TableCell>
                            <TableCell>{row.Company}</TableCell>
                            <TableCell>{row.Title}</TableCell>
                            <TableCell>{row?.EngagamentStatus}</TableCell>
                            <TableCell>{row?.MeetingSourceName}</TableCell>
                            <TableCell>{row?.MeetingOwnerName}</TableCell>
                            <TableCell>{row?.ReminderEmailAccount}</TableCell>
                            <TableCell>
                              {row.CreatedDate
                                ? moment(
                                    new Date(row.CreatedDate).toDateString()
                                  ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>

                            <TableCell>
                              <a
                                onClick={() => {
                                  PotenialEditPages(row._id);
                                }}
                                className="btn-eyesicon"
                              >
                                <i className="la flaticon-edit-1 edit-icon"></i>
                              </a>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={6}
                            >
                              <Collapse
                                in={Open[row._id]}
                                timeout="auto"
                                unmountOnExit
                              >
                                {Open[row._id] && (
                                  <Box margin={1} className="innertables">
                                    <Table size="small" aria-label="purchases">
                                      <TableHead></TableHead>
                                      {PotentialNotes?.map((note) =>
                                        note.PotentialMeetingID.toString() ==
                                        row._id.toString() ? (
                                          <TableRow>
                                            <TableCell><b>{note?.Title}</b></TableCell>
                                            <TableCell><b> {note.CreatedDate
                                ? moment(
                                    new Date(note.CreatedDate).toDateString()
                                  ).format("MM/DD/YYYY")
                                : ""}</b></TableCell>
                                            <TableCell scope="row">
                                              {note?.Note}
                                            </TableCell>
                                            
                                          </TableRow>
                                        ) : null
                                      )}
                                      {!PotentialNotes?.some(
                                        (note) =>
                                          note.PotentialMeetingID.toString() ==
                                          row._id.toString()
                                      ) && (
                                        <TableRow>
                                          <TableCell colSpan={4}>
                                            No data available
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </Table>
                                  </Box>
                                )}
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
           
              <div className="row">
                <div className="col dataTables_info">
                  <p>
                    Showing{" "}
                    {Rlen == 0
                      ? 0
                      : (Page - 1) * RowsPerPage +
                        1}{" "}
                    to{" "}
                    {Page * RowsPerPage >
                    Rlen
                      ? Rlen
                      : Page * RowsPerPage}{" "}
                    of {Rlen} entries
                  </p>
                </div>
                <div className="col pageright">
                  <Pagination
                    component="div"
                    count={CountPage}
                    page={Page}
                    onChange={HandleChangePageListP}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
           
    </>
  )
}

export default PotentialMeeting