

import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import Twilio from "../../_components/user/Twilio/Twilio";

import Popupone from "reactjs-popup";

class TwilioPage extends React.Component {
    constructor(props) {
        super(props);


    }



    render() {
        return (
            <>
                <div className="adminmain mheight">
                    <Sidebar className="" />
                    <div className="bodyhome">
                        <Twilio />

                    </div>
                </div>


                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
};

const connectedTwilioPage = connect(
    mapState,
    actionCreators
)(TwilioPage);
export { connectedTwilioPage as TwilioPage };
