import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import LinkedInFollowUp from "../../../_components/user/LinkedInFollowup/LinkedInFollowup";

class LinkedInFollowUpPage extends React.Component {
  render() {
    return (
      <>
        <div className="adminmain mheight">
            <Sidebar className="" />
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlebd lg-headertitle float-left py-4 mt-0">
                    LinkedIn Follow Up
                  </h4>
                </div>
              </div>
              <div class="bg-white">
              <div className="row">
                <div className="col">
                    <LinkedInFollowUp />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedLinkedInFollowUpPage = connect(
  mapState,
  actionCreators
)(LinkedInFollowUpPage);
export { connectedLinkedInFollowUpPage as LinkedInFollowUpPage };
