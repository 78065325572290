import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Pagination from "@material-ui/lab/Pagination";
import Swal from "sweetalert2";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import StyleHeader from "../StickyHeader/StickyHeader";

const SortingIcons = ({SortedBy,sortedColumn,ColumnName}) => {
  return (
    <span className="shorting">
          <ArrowUpward className={SortedBy === 1 && sortedColumn === ColumnName? "active": null}/>
          <ArrowDownward className={SortedBy === -1 && sortedColumn === ColumnName? "active": null}/>
    </span>
  )
}

export default function AccountAllTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("ClientName");
  const [sortedColumn, setSortedColumn] = React.useState("ClientName");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [UpdateArray, SetUpdateArray] = React.useState([]);
  const [CUserID, SetCUserID] = React.useState(0);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    EmailWarmUpReceiver(Details.ClientID, Details.ParentUserID, Details.Role);
  }, [Search, Page, RowsPerPage]);

  // Email Warm up Receiver
  const EmailWarmUpReceiver = (CID, UID, URole) => {
    SetIsLoading(true);
    var InpurtData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/email_warm_up/EmailWarmUpReceiverGet",
      method: "POST",
      data: InpurtData,
    });
    rows1.then((result) => {
      if (result.data.StatusMessage == "SUCCESS") {
        SetData(result.data.PageData);
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
        SetIsLoading(false);
        setIsDataLoaded(true);

      } else {
        SetData([]);
        SetRows([]);
        SetRlen(0);
        SetFlen(0);
        SetCountPage(0);
        SetIsLoading(false);
        setIsDataLoaded(true);

      }
    });
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //get sort field data
  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");
  
    const InputParameter = {
      Page,
      RowsPerPage,
      SerchBox: SearchedVal !== "",
      Sort: true,
      Search: SearchedVal,
      Field,
      SortBy,
      Type: "User",
      ClientID,
      UserID,
      Role,
    };
  
    Axios({
      url: `${CommonConstants.MOL_APIURL}/email_warm_up/EmailWarmUpReceiverGet`,
      method: "POST",
      data: InputParameter,
    }).then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetPage(1)
    });
    // SetIsLoading(true);

    // var SearchedVal = document.getElementById("search").value;
    // // SetPage(1);
    // // SetRowsPerPage(10);
    // var SerchBox;
    // if (SearchedVal == "") {
    //   SerchBox = false;
    //   SetSflag(false);
    //   var SortField = Field;
    //   var SortBy;
    //   if (SortedBy == 1) {
    //     SortBy = -1;
    //     SetSortedBy(-1);
    //   } else {
    //     SortBy = 1;
    //     SetSortedBy(1);
    //   }
    //   SetSortField(Field);
    //   var InputParameter = {
    //     Page: Page,
    //     RowsPerPage: RowsPerPage,
    //     SerchBox: false,
    //     Archive: false,
    //     Sort: true,
    //     Field: SortField,
    //     Search: SearchedVal,
    //     SortBy: SortBy,
    //     Type: "User",
    //     ClientID: ClientID,
    //     UserID: UserID,
    //     Role: Role,
    //   };

    //   const AccountCategoriesList = Axios({
    //     url:
    //       CommonConstants.MOL_APIURL + "/email_warm_up/EmailWarmUpReceiverGet",
    //     method: "POST",
    //     data: InputParameter,
    //   });
    //   AccountCategoriesList.then((Result) => {
    //     SetRows(Result.data.PageData);
    //     SetRlen(Result.data.TotalCount);
    //     SetFlen(Result.data.TotalCount);
    //     SetCountPage(Result.data.PageCount);
    //     SetIsLoading(false);
    //   });
    // } else {
    //   SerchBox = true;
    //   SetSflag(true);
    //   var SortField = Field;
    //   var SortBy;
    //   if (SortedBy == 1) {
    //     SortBy = -1;
    //     SetSortedBy(-1);
    //   } else {
    //     SortBy = 1;
    //     SetSortedBy(1);
    //   }
    //   SetSortField(Field);
    //   var InputParameter = {
    //     Page: Page,
    //     RowsPerPage: RowsPerPage,
    //     search: SearchedVal,
    //     SerchBox: SerchBox,
    //     Archive: false,
    //     Sort: true,
    //     Field: SortField,
    //     Search: SearchedVal,
    //     SortBy: SortBy,
    //     Type: "User",
    //     ClientID: ClientID,
    //     UserID: UserID,
    //     Role: Role,
    //   };

    //   const AccountCategoriesList = Axios({
    //     url:
    //       CommonConstants.MOL_APIURL + "/email_warm_up/EmailWarmUpReceiverGet",
    //     method: "POST",
    //     data: InputParameter,
    //   });
    //   AccountCategoriesList.then((Result) => {
    //     SetRows(Result.data.PageData);
    //     SetRlen(Result.data.TotalCount);
    //     SetFlen(Result.data.TotalCount);
    //     SetCountPage(Result.data.PageCount);
    //     SetIsLoading(false);
    //   });
    // }
  };

  // when check box update data
  const CheckBoxClick = (e, row) => {
    if (e.target.checked) {
      var temArray = UpdateArray;
      temArray.push(row);
      SetUpdateArray(temArray);
    } else if (!e.target.checked) {
      UpdateArray.splice(row, 1);
    }
  };

  //Check Box Update
  const IsIncludeWarmUpCheck = (ID, checkedstatus) => {
    let CheckBoxs = document.getElementById("CheckBoxs").checked;

    if (!checkedstatus) {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to Add warm up.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No, cancel!",
      }).then((Result) => {
        if (Result.isConfirmed) {
          var InputData = {
            AccountID: ID,
            LastUpdatedBy: CUserID,
            LastUpdatedDate: new Date(),
            IsIncludeWarmUp: !checkedstatus,
            Destination: "Receiver",
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/email_warm_up/IsIncludeWarmUpUpdate",
            method: "POST",
            data: InputData,
          }).then((Res) => {
            if (Res) {
              if ((Res.statusText = "Ok")) {
                Swal.fire("Added !", "Add warm up successfully.", "success");
                EmailWarmUpReceiver(ClientID, UserID, Role);
              } else {
              }
            }
          });
        } else if (Result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to Remove warm up.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No, cancel!",
      }).then((Result) => {
        if (Result.isConfirmed) {
          var InputData = {
            AccountID: ID,
            LastUpdatedBy: CUserID,
            LastUpdatedDate: new Date(),
            IsIncludeWarmUp: !checkedstatus,
            Destination: "Receiver",
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/email_warm_up/IsIncludeWarmUpUpdate",
            method: "POST",
            data: InputData,
          }).then((Res) => {
            if (Res) {
              if ((Res.statusText = "Ok")) {
                Swal.fire("Remove!", "warm up remove successfully.", "success");
                EmailWarmUpReceiver(ClientID, UserID, Role);
              } else {
              }
            }
          });
        } else if (Result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    }
  };
  return (
    <>
      {IsLoading === true ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div>
        <div className="row">
          <div className="col padd-0"></div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <label className="textlabelte">
              Show
              <select
                name="tbl_meeting_length"
                onChange={ChangeRowSelected}
                aria-controls="tbl_meeting"
                class="form-control form-control-sm"
                value={RowsPerPage}
              >
                {CommonConstants.show_rows.map((value) => (
                  <option value={value} key={value}>{value}</option>
                ))}
              </select>
              entries
            </label>
          </div>
          <div className="col-sm-12 col-md-6 full-right">
            <label class="textlabelte">
              Search:{" "}
              <input
                type="search"
                id="search"
                onKeyPress={RequestSearch}
                class="form-control form-control-sm ml-2"
                placeholder=""
                aria-controls="tbl_meeting"
              />
            </label>
          </div>
        </div>
        <div className="table-bordered">
          <TableContainer component={Paper}>
          <StyleHeader isDataLoaded={isDataLoaded} />
            <Table className="table-ref" aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell  
                  // onClick={() => { SortData("IsIncludeReply");}}
                  >
                      Is Include Reply
                      {/* <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"IsIncludeReply"} /> */}
                   
                  </TableCell>
                  <TableCell  onClick={() => {SortData("ClientName");}}>
                      Client
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"ClientName"} />
                  </TableCell>
                  <TableCell onClick={() => { SortData("AccountName");}}>
                      Account Name
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"AccountName"} />
                  </TableCell>
                  <TableCell onClick={() => {SortData("SMTPFromEmail");}}>
                      Email
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"SMTPFromEmail"} />
                  </TableCell>
                  <TableCell onClick={() => { SortData("TodayReceiveMail");}}>
                      Today Mail Received
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"TodayReceiveMail"} />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Rows.length == 0 ? (
                  <p className="text-center">No data available in table</p>
                ) : (
                  Rows.map((row) => (
                    <TableRow key={row._id} row={row}>
                      <TableCell>
                        <input
                          type="checkbox"
                          id="CheckBoxs"
                          onClick={() =>
                            IsIncludeWarmUpCheck(
                              row.AccountID,
                              row.IsEmailWarmingReceiving
                            )
                          }
                          onChange={(e) => CheckBoxClick(e, row)}
                          checked={row.IsEmailWarmingReceiving}
                        />{" "}
                      </TableCell>
                      <TableCell>{row.ClientName}</TableCell>
                      <TableCell>{row.AccountName}</TableCell>
                      <TableCell>{row.Email}</TableCell>
                      <TableCell>{row.TodayReceiveMail}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      
          <div class="row">
            <div class="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                {Rlen} entries
              </p>
            </div>
            <div class="col pageright">
              <Pagination
                component="div"
                count={CountPage}
                page={Page}
                onChange={HandleChangePage}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
      </div>
    </>
  );
}
