import React from "react";
import { connect } from "react-redux";

import { userActions } from "../../../_actions";
import { history } from "../../../_helpers/history";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import AccountClientStatus from "../../../_components/user/AccountClientStatus/AccountClientStatus/AccountClientStatus";

class AccountClientStatusPage extends React.Component {
  AddAccountClientStatus() {
    history.push("/addaccountclientstatus");
  }
  SetOrder() {
    history.push("accountclientstatusorder");
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row align-items-center py-3">
              <div className="col">
                <h4 className="headertitlenop lg-headertitle float-left">
                  Account Client Status
                </h4>
              </div> 
              <div className="col">
                <div className="listing-li float-right">
                  <ul class='my-0'>
                    <li className="px-0">
                      <a onClick={this.SetOrder.bind(this)}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 flaticon-settings"></i>
                        <span>Set Order</span>
                      </a>
                    </li>
                    <li className="px-1">
                      <a
                        onClick={this.AddAccountClientStatus.bind(this)}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="whiterow px-3">
              <div className="row">
                <div className="col">
                  <AccountClientStatus />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAccountClientStatusPage = connect(
  mapState,
  actionCreators
)(AccountClientStatusPage);
export { connectedAccountClientStatusPage as AccountClientStatusPage };
