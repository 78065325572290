import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import Axios from "axios";


import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class EditGlobalBlacklistPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      fields: {},
      errors: {},
      ClientID: Details.ClientID,
      UserID: Details.ChildUserID,
      PUserID: Details.ParentUserID,
      IsExist: false,
      PrevDomain:null,
      IsValid:true,
      IsLoading:false,
      BtnDisabled:false
    };
    this.GetBlackListDomain();
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {}

  GetBlackListDomain() {
    this.setState({IsLoading:true})
    const InputParameters={
      ID: this.props.location.state,
    }
    Axios({
      url: CommonConstants.MOL_APIURL + "/globaldomainblacklist/GlobalDomainBlacklistGetByID",
      method: "POST",
      data:InputParameters
    }).then((res) => {
     
     document.getElementById('Domain').value=res.data.Data.Domain
     this.setState({PrevDomain:res.data.Data.Domain})
    this.setState({IsLoading:false})
      
    });
  }

  //on blur method for form validation
  async BlurValidation(){
    let formIsValid = true;
      let errors = {};
      var Domain = document.getElementById("Domain").value.trim();
      let IsValid = await this.DomainCheck(Domain);
      let IsExists = await this.CheckExistDomain(Domain);
      if (Domain.length == 0) {
        formIsValid = false;
        errors["Domain"] = "Please enter domain";
      }else if (IsExists) {
        formIsValid = false;
        errors["Domain"] = "Domain already exist.";
      }else if(!IsValid){
        formIsValid = false;
        errors["Domain"] = "Invalid email domain";
      }
      this.setState({ errors: errors });
  }

 async FormValidation() {
    let formIsValid = true;
      let errors = {};
      var Domain = document.getElementById("Domain").value.trim();
      let IsValid = await this.DomainCheck(Domain);
      let IsExists = await this.CheckExistDomain(Domain);
      if (Domain.length ==0) {
        formIsValid = false;
        errors["Domain"] = "Please enter domain";
      }else if (IsExists) {
        formIsValid = false;
        errors["Domain"] = "Domain already exist.";
      }else if(!IsValid){
        formIsValid = false;
        errors["Domain"] = "Invalid email domain";
      }
      
      
     
      this.setState({ errors: errors });
    
    return formIsValid;
  }
  //Domain Check 
 async DomainCheck(Domain){
  let regexp = /^(?!:\/\/)([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}$/;
     if (regexp.test(Domain))
       {
         return true;
       }
     else
       {
         return false;
       }
   }
  // check exit Domain
 async CheckExistDomain() {
    var Domain = document.getElementById("Domain").value;
    var InputParameter = {
      Domain: Domain,
      UserID: this.state.PUserID
    };
   let resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/globaldomainblacklist/GlobalDomainBlacklistExists",
      method: "POST",
      data: InputParameter,
    })
      let errors = {};
      
      if (resdata.data.Data > 0 && this.state.PrevDomain != Domain) {
        let fields = this.state.fields;
        if (!fields["Domain"]) {
                errors["Domain"] = "Domain already exist.";
        }
        this.setState({ IsExist: true });
        this.setState({ errors: errors }); 
        return true;
      }else{
        this.setState({ IsExist: false });
        this.setState({ errors: errors }); 
        return false;
      }
   
  }
    
  backbtn() {
    history.push({pathname:"/globalblacklist",state:{page:"domain"}});
  }

 async savebtn() {
  this.setState({IsLoading:true,BtnDisabled:true})
  
    var Domain = document.getElementById("Domain").value;
    let Formvalid = await this.FormValidation();
    if (Formvalid == true && this.state.IsExist ==false){
      var data = {
        ID:this.props.location.state,
        Domain:Domain,
        UserID: this.state.PUserID,
        LastUpdatedBy: this.state.UserID,
        LastUpdatedDt: new Date(),
      };
      
     let res = await Axios({
        url: CommonConstants.MOL_APIURL + "/globaldomainblacklist/GlobalDomainBlacklistUpdate",
        method: "POST",
        data: data,
      })
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize">Global Domain Blacklist<br/>Global domain blacklist updated successfully.</div>);
          
          history.push({pathname:"/globalblacklist",state:{page:"domain"}});
       
        } else {
          this.setState({IsLoading:false,BtnDisabled:false})

          toast.error(res.data.Message);
        }
    }else{
  this.setState({IsLoading:false,BtnDisabled:false})

    }
  }

  render() {
    return (
      <>
      
      {
        this.state.IsLoading ?<div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>

      }
      
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad lg-headertitle float-left pl-0">Edit</h4>
                </div>
              </div>

            <div className="bg-white p-3">
              <div className="row">  
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Domain</label>
                    <div className="col-lg-8">
                      <input className="form-control m-input"onBlur={()=>{this.BlurValidation()}} id="Domain" placeholder="Enter Domain" type="text" />
                    <span style={{ color: "red" }}>{this.state.errors["Domain"]}</span>

                    </div>
                  </div>
                </div>  
              </div>
            </div>

              <div className="row my-4">
                <div className="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.savebtn}
                    disabled={this.state.BtnDisabled}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.backbtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>

          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditGlobalBlacklistPage = connect(
  mapState,
  actionCreators
)(EditGlobalBlacklistPage);
export { connectedEditGlobalBlacklistPage as EditGlobalBlacklistPage };
