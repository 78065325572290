import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import CampaignSummary from '../../_components/user/Tools/CampaignSummaryTable';



class CampaignSummaryPage extends React.Component {
    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row align-items-center py-4">
                        <div className="col">
                            <h4 className="headertitlenop lg-headertitle float-left">Campaign - Summary Total</h4>
                        </div>
                    </div>
                    <div class="whiterow px-3">
                        <div className="row">
                            <div className="col">
                                <CampaignSummary />
                            </div>
                        </div>      
                    </div>

                </div> 
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCampaignSummaryPage = connect(mapState, actionCreators)(CampaignSummaryPage);
export { connectedCampaignSummaryPage as CampaignSummaryPage };