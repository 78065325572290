import React, { useEffect } from "react";
import Axios from "axios";
import Popup from "reactjs-popup";
const Fileupload = require("../../../_helpers/fileupload");

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import DomainTable from "./DomainTable";
import ContactTable from "./ContactTable";
import RemovedTable from "./RemovedTable";
import WhitelistTable from "./WhitelistTable";
import PhoneNumberTable from "./PhoneNumberTable";
import PropTypes from "prop-types";

import { CSVReader, CSVDownloader } from "react-papaparse";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { GetClientDetails } from "../../../_helpers/Utility";
import { toast } from "react-toastify";
import $ from "jquery";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

import loadingicon from "../../../images/loading.gif";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [popupval, setpopup] = React.useState(false);
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [PUserID, setPUserID] = React.useState(0);
  const [BtnDisabled, SetBtnDisabled] = React.useState(false);
  const [BtnDisabled1, SetBtnDisabled1] = React.useState(false);
  const [BtnDisabled2, SetBtnDisabled2] = React.useState(false);
  const [BtnDisabled3, SetBtnDisabled3] = React.useState(false);
  const [phonenumberBtn, SetphonenumberBtn] = React.useState(false);

  const [Fields, SetFields] = React.useState({});
  const [Errors, SetErrors] = React.useState({});
  const [DomainNameAvaiable, SetDomainNameAvaiable] = React.useState(null);
  const [Exportdata, SetExportData] = React.useState([]);
  const [ContactDomainExportData, SetContactDomainExport] = React.useState([]);
  const [RemovedExportData, SetRemovedExportData] = React.useState([]);
  const [WhiteListExportData, SetWhiteListExportData] = React.useState([]);

  const [Reset, SetReset] = React.useState(false);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [DropboxData, SetDropboxData] = React.useState([]);
  const [filename, setfilename] = React.useState("");
  const [csvData, setcsvData] = React.useState([]);
  const [files, setfiles] = React.useState([]);
  const [CName, SetCName] = React.useState("");
  const [UpdateKey, SetUpdateKey] = React.useState(0);
  // contact
  const [Total, SetTotal] = React.useState(0);
  const [TotalSucess, SetTotalSucess] = React.useState(0);
  const [TotalError, SetTotalError] = React.useState(0);
  const [TotalDuplicate, SetTotalDuplicate] = React.useState(0);
  const [TotalInvalid, SetTotalInvalid] = React.useState(0);

  // remove
  const [RemovedTotal, SetRemovedTotal] = React.useState(0);
  const [RemovedTotalSucess, SetRemovedTotalSucess] = React.useState(0);
  const [RemovedTotalError, SetRemovedTotalError] = React.useState(0);
  const [RemovedTotalDuplicate, SetRemovedTotalDuplicate] = React.useState(0);
  const [RemovedTotalUpdated, SetRemovedTotalUpdated] = React.useState(0);

  // whitelist
  const [TotalWhiteList, SetTotalWhiteList] = React.useState(0);
  const [TotalWhiteListSucess, SetTotalWhiteListSucess] = React.useState(0);
  const [TotalWhiteListError, SetTotalWhiteListError] = React.useState(0);
  const [TotalWhiteListDuplicate, SetTotalWhiteListDuplicate] =
    React.useState(0);

  const [DParentChild, SetDParentChild] = React.useState(false);
  const [CParentChild, SetCParentChild] = React.useState(false);
  const [RParentChild, SetRParentChild] = React.useState(false);
  const [WParentChild, SetWParentChild] = React.useState(false);
  const [PhoneNumberParentChild, SetPhoneNumberParentChild] = React.useState(false);
  const [Flag, SetFlag] = React.useState(false);
  // Domain tab
  const [IsExist, SetIsExist] = React.useState(false);
  const [ShowPopupDomainImport, SetShowPopupDomainImport] =
    React.useState(false);
  const [IsValid, SetIsValid] = React.useState(false);
  const [UserEmail, SetUserEmail] = React.useState("");
  const [Domain, SetDomain] = React.useState(null);
  const [CreatedDate, SetCreatedDate] = React.useState(null);

  // update parent to chaild
  const [DomainChaild, SetDomainChaild] = React.useState(false);
  const [ContactChaild, SetContactChaild] = React.useState(false);
  const [RemovedChaild, SetRemovedChaild] = React.useState(false);
  const [WhiteListChaild, SetWhiteListChaild] = React.useState(false);
  const [PhoneNumberChaild, SetPhoneNumberChaild] = React.useState(false);

  //Display states

  const [ImportBox, SetImportBox] = React.useState(false);
  const [ImportMapBox, SetImportMapBox] = React.useState(false);
  const [DeleteBox, SetDeleteBox] = React.useState(false);
  const [DeleteMapBox, SetDeleteMapBox] = React.useState(false);
  const [AddBox, SetAddBox] = React.useState(false);
  const [CountBox, SetCountBox] = React.useState(false);
  const [BtnPopDisabled, SetBtnPopDisabled] = React.useState(false);
  const [DisabledFields,SetDisabledFields] = React.useState(false);
  const [Contact,SetContact] = React.useState(null);
  const [Removed,SetRemoved] = React.useState(null);
  const [Whitelist,SetWhitelist] = React.useState(null);
  const [PhoneNumber, SetPhoneNumber] = React.useState(null);
  const [OldPhoneNumberData, SetOldPhoneNumberData] = React.useState("");
  const [OpenImportPhoneNumber, SetOpenImportPhoneNumber] = React.useState(false);
  const [ImportPhoneNumberMapBox, SetImportPhoneNumberMapBox] = React.useState(false);
  const [PhoneNumberCountBox, SetPhoneNumberCountBox] = React.useState(false);
  const [PhoneNumberImportCount, SetPhoneNumberImportCount] = React.useState({
    Total:0,
    Success:0,
    Duplicate:0,
    Error:0,
    Invalid:0
  });
  const [DisplayPhoneCount, SetDisplayPhoneCount] = React.useState(false)
  const [PhoneNumberList, SetPhoneNumberList] = React.useState(null)

  const [OpenBulkDeletePhone, SetOpenBulkDeletePhone] = React.useState(false)
  const [BulkPhoneNumberMapBox, SetBulkPhoneNumberMapBox] = React.useState(false);

  
  useEffect(() => {
    document.title = "Blacklists | SalesHive";
    var Details = GetUserDetails();

    if (Details != null) {
      setClientID(Details.ClientID);
      setUserID(Details.ParentUserID);
      SetUserEmail(Details.Username);
      setPUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    GetClientDetails(Details.ClientID).then((result) => {
      SetCName(result[0].Name);
    });

    // For export csv file blacklisted domain
    var str_in = {
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      Field: "Domain",
      SortBy: 1,
      Role: Details.Role,
    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/blacklisteddomain/BlackListedExport",
      method: "POST",
      data: str_in,
    }).then((res) => {
      SetExportData(res.data);
    });

    // For export csv file blacklisted contact
    var str_in = {
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      Field: "Domain",
      SortBy: 1,
      Role: Details.Role,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/blacklistedcontact/BlacklistContactExport",
      method: "POST",
      data: str_in,
    }).then((res) => {
      SetContactDomainExport(res.data);
    });

    // For export csv file otp outs
    var str_in = {
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      Role: Details.Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/Otpoutsdomain/RemovedsExport",
      method: "POST",
      data: str_in,
    }).then((res) => {
      SetRemovedExportData(res.data);
    });

    // For export csv file whitelist
    Axios({
      url: CommonConstants.MOL_APIURL + "/whitelistedcontact/WhiteListedExport",
      method: "POST",
      data: str_in,
    }).then((res) => {
      SetWhiteListExportData(res.data);
    });
  }, [
    DParentChild,
    CParentChild,
    RParentChild,
    WParentChild,
    Flag,
    UserEmail,
    UpdateKey,
  ]);

  //***  Domain Table code started ***///

  // Show and hide div add
  const ShowBlackListDomain = () => {
    SetAddBox(true);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    SetReset(true);
    SetBtnDisabled(false);
  };

  // Show import btn in domain
  const ImportBlackListCsv = () => {
    SetAddBox(false);
    SetImportBox(true);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    setcsvData([]);
    SetReset(true);
    SetBtnPopDisabled(false)
  };

  // show bluk delete btn in domain
  const BlackListBulkDelete = () => {
    SetAddBox(false);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(true);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    setcsvData([]);
    SetReset(!Reset);
  };

  // show  export csv in domain
  const ExportBlackListCsv = () => {
    toast.success(
      <div>
        Domain
        <br />
        Data exported successfully.
      </div>
    );
  };

  const HandleOnDrop = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");

    if (sep[sep.length-1]!= "csv") {
      SetReset(true);
      resetcsv();
    } else {
      var csvfile = sep.slice(0, -1).join(".") + new Date().getTime() + "." + sep[sep.length-1];
      setfilename(csvfile);
      setcsvData(data);
      SetDropboxData(data[0]?.data);
    }
  };

  const HandleOnError = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFile = (data) => {
    // setcsvData([])
  };

  //Domain maping method
  const BlacklistDomainMapCsv = () => {
    resetcsv();
    if (csvData.length != 0) {
      SetAddBox(false);
      SetImportBox(true);
      SetImportMapBox(true);
      SetDeleteBox(false);
      SetDeleteMapBox(false);
      SetShowPopupDomainImport(false);
      SetCountBox(false);
      SetBtnPopDisabled(false)
      // document.getElementById("Domain").disabled = false;
      // document.getElementById("ImportDomainAddBtn").disabled = false;
      // document.getElementById("map_attribute").style.cursor = "default";
      // document.getElementById("ImportDomainAddBtn").style.cursor = "default";

      resetcsv();
      DropboxData.map((dddd, index) => {});
    } else {
      resetcsv();
      setcsvData([]);
      toast.error(
        <div className="toastsize">
          Domain
          <br />
          Please select file
        </div>
      );
      SetAddBox(false);
      SetImportBox(true);
      SetImportMapBox(false);
      SetDeleteBox(false);
      SetDeleteMapBox(false);
      SetShowPopupDomainImport(false);
      SetCountBox(false);
    }
    SetBtnPopDisabled(false)

    hideCSVHeaderOptions();
  };

  // Csv cancel method for domain
  const BlacklistDomainImportCancelBtn = () => {
    resetcsv();
    setcsvData([]);
    SetAddBox(false);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
  };

  // Domain  csv save method
  const OpenDomainPopupBtn = () => {
    let Domain = document.getElementById("Domain").value;
    if (Domain != "Not Mapped") {
      SetShowPopupDomainImport(true);
    } else {
      toast.error(
        <div className="toastsize">
          Domain
          <br />
          Domain not mapped!
        </div>
      );
    }
  };

  //Domain delete csv uploader method
  const BlacklistDomainBulkDeleteUpload = () => {
  
    if (csvData.length != 0) {
      SetDeleteMapBox(true);

      DropboxData.map((dddd, index) => {});
    } else {
      toast.error(
        <div className="toastsize">
          Domain
          <br />
          Please select file
        </div>
      );

      SetDeleteMapBox(false);
    }
    hideCSVHeaderOptions()
  };

  // Bulk delete blacklist csv
  const BlacklistDomainBulkDelete = () => {
    SetIsLoading(true);
    var Domain = document.getElementById("Domain1").value;
    if (Domain != "Not Mapped") {
      var CsvData = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var BulkDeleteBlackListData = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/UserBlacklistDomain",
          File: _base64,
          ImageName: filename,
          FileData: CsvData,
          Domain: Domain,
          Role: Role,
          ClientID: ClientID,
          UserID: UserID,
          CreatedBy: UserID,
          
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/blacklisteddomain/BlacklistDomainBulkDelete",
          method: "POST",
          data: BulkDeleteBlackListData,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success("Domain deleted successfully");
            SetUpdateKey(UpdateKey + 1);
            SetDeleteMapBox(false);
            SetDeleteBox(false);
            setcsvData([]);
            SetDomainChaild(true);
            SetIsLoading(false);
          } else {
            SetIsLoading(false);
            toast.error(res.data.Message);
            SetDomainChaild(false);
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsize">
          Domain
          <br />
          Domain not mapped!
        </div>
      );
      SetIsLoading(false);
    }
  };

  //Domain add validation
  const DomainOnBlurValidation = async () => {
    let formIsValid = true;
    let errors = {};
    var Domain = document.getElementById("DomainFor").value;
    let IsExist = await BlacklistDomainNameCheckExist(Domain, false);
    let FLAGISVALID = await DomainCheck(Domain);

    if (Domain == "" && Domain.indexOf(" ") != "") {
      formIsValid = false;
      errors["DomainFor"] = "Please enter domain";
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    if (FLAGISVALID == true && Domain.length > 0) {
      formIsValid = false;
      errors["DomainFor"] = "Invalid domain";
    }
    SetErrors(errors);
    return formIsValid;
  };

  // formvalidation for domain tab
  const FormValidation = async () => {
    let formIsValid = true;
    let errors = {};
    var Domain = document.getElementById("DomainFor").value;
    let IsExist = await BlacklistDomainNameCheckExist(Domain);
    let FLAGISVALID = await DomainCheck(Domain);

    if (Domain == "" && Domain.indexOf(" ") != "") {
      formIsValid = false;
      errors["DomainFor"] = "Please enter domain";
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    if (FLAGISVALID == true && Domain.length > 0) {
      formIsValid = false;
      errors["DomainFor"] = "Invalid domain";
    }
    SetErrors(errors);
    return formIsValid;
  };

  //Domain Check for domain
  const DomainCheck = async (Domain) => {
    // var Domain = document.getElementById("DomainFor").value;
    let regexp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;

    if (!regexp.test(Domain)) {
      SetIsValid(true);
      return true;
    } else {
      SetIsValid(false);
      return false;
    }
  };

  // cheack exists for domain tab
  const BlacklistDomainNameCheckExist = async (Domain, defaults = true) => {
    var Domain = document.getElementById("DomainFor").value.trim();
    var str_in = {
      ClientID: ClientID,
      Domain: Domain,
      Role: Role,
    };
    let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/blacklisteddomain/BlackListDomainCheckExits",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (defaults == true) {
          toast.error(
            <div className="toastsize">
              Domain <br />
              {Domain} already exists
            </div>
          );
        }
        SetIsExist(true);
        return true;
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };

  // blacklist domain save for domain
  const BlackListDomainAdd = async (e) => {
    SetBtnDisabled(true);
    e.preventDefault();
    var Final_flag = await FormValidation();
    var Domain = document.getElementById("DomainFor").value.trim();
    if (Final_flag == true) {
      SetIsLoading(true);
      var BlckListDomainData = {
        ClientID: ClientID,
        Domain: Domain,
        IsDeleted: false,
        CreatedDate: new Date(),
        CreatedBy: PUserID,
        UserID: UserID,
        Role: Role,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/blacklisteddomain/BlacklistedDomainAdd",
        method: "POST",
        data: BlckListDomainData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          SetDomainChaild(true);
          SetUpdateKey(UpdateKey + 1);
          toast.success(
            <div className="toastsize">
              Domain
              <br />
              Saleshive blacklisted domain will be added soon..
            </div>
          );
          SetDomainChaild(false);
          document.getElementById("DomainFor").value = "";
          document.getElementById("Dsubmit").disabled = false;
          setcsvData([]);
          DomaintabFor();
          SetBtnDisabled(false);
          SetIsLoading(false);
        } else {
          toast.error(res.data.Message);
          SetBtnDisabled(false);
          SetIsLoading(false);
        }
      });
    } else {
      document.getElementById("Dsubmit").disabled = false;
      SetBtnDisabled(false);
      SetIsLoading(false);
    }

  };

  // import black list csv
  const DomainUploadCsv = () => {
    SetBtnPopDisabled(true)
    var Email = document.getElementById("Username").value;
    // var name = filename.name;
    var filetype = filename.split(".");
    var file_Name;

    var file_Name;
    if (/\s/g.test(filetype[0])) {
      file_Name =
        // filetype[0].replaceAll(/\s/g, "") +
        new Date().getTime() + "." + filetype[1] + "";
    }

    var OriginalFileName = files.name;

    var saveAllContacts = [];
    var Domain = parseInt(document.getElementById("Domain").value);
    if (Domain >= 0) {
      var ObjDomain = {
        FieldAlias: "Domain",
        Fieldname: "Domain",
        Customfield: null,
        index: Domain,
      };
      saveAllContacts.push(ObjDomain);
    }
    var csvdata = csvData;
    Fileupload.getBase64(files, (result) => {
      var rs = result;
      var splirs = rs.split("base64,");
      var _base64 = splirs[1];

      var DomainImportData = {
        Fields: "Coverimg",
        Path: "./Content/UploadedFiles/UserBlacklistDomain",
        File: _base64,
        ImageName: filename,
        Email: Email,
        MappingFiled: JSON.stringify(saveAllContacts),
        FileData: csvdata,
        ClientID: ClientID,
        OrignalFileName: OriginalFileName,
        UserID: UserID,
        IsCompleted: false,
        InProgress: false,
        CreatedDate: new Date(),
        CreatedBy: UserID,
        LastUpdatedDate: null,
        LastUpdatedBy: null,
        FileName: file_Name,
        Role: Role,
      };

      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/blacklisteddomain/BlackListDomainUpload",
        method: "POST",
        data: DomainImportData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div>
              Domain <br />
              You will get file soon on Submitted Email.
            </div>
          );
          SetUpdateKey(UpdateKey + 1);
          // history.push("/blacklists");
          SetReset(!Reset);
          SetShowPopupDomainImport(false);
          document.getElementById("Domain").disabled = true;
          document.getElementById("map_attribute").style.cursor = "not-allowed";
          document.getElementById("ImportDomainAddBtn").disabled = true;
          document.getElementById("ImportDomainAddBtn").style.cursor = "not-allowed";
          setcsvData([]);
        } else {
          toast.error(res.data.Message);
          SetBtnPopDisabled(false)
        }
      });
    });
  };

  // hid div add
  const DomaintabFor = () => {
    SetErrors({});
    document.getElementById("DomainFor").value = "";
    SetAddBox(false);
    SetBtnDisabled(false);
  };

  //** Contact code start from Here **/

  const ContactDomainImportBtn = () => {
    SetAddBox(false);
    SetImportBox(true);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    setcsvData([]);
    resetcsv();
  };

  // contact domain export
  const ContactDomainExport = () => {
    toast.success(
      <div className="toastsize">
        Contact
        <br />
        Data exported successfully.
      </div>
    );
  };

  const ContactDomainBulkDleteBtn = () => {
    SetAddBox(false);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(true);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    resetcsv();
    setcsvData([]);
  };

  const ContactDomainAddBtn = () => {
    SetAddBox(true);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    setcsvData([]);
    resetcsv();
    SetBtnDisabled1(false);
  };
  // for contact import csv
  const ContactDomainMapCsvBtn = () => {
    SetCountBox(false);

    if (csvData.length != 0) {
      SetAddBox(false);
      SetImportBox(true);
      SetImportMapBox(true);
      SetDeleteBox(false);
      SetDeleteMapBox(false);
      SetShowPopupDomainImport(false);
      SetCountBox(false);
      SetDisabledFields(false)
      SetDisabledFields(false);
      // document.getElementById("Show_csv_details_contact").style.cursor ="default";
      resetcsv();
      DropboxData.map((dddd, index) => {});
      
      resetcsv();
    } else {
      toast.error(
        <div className="toastsize">
          Contact
          <br />
          Please select file
        </div>
      );
      SetImportMapBox(false);
    }
    hideCSVHeaderOptions();
  };
  const ContactDomainCancleBtn = () => {
    resetcsv();
    SetAddBox(false);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    SetDisabledFields(false)
  };

  // for contact import csv
  const ContactDomainSaveBtn = () => {
    SetIsLoading(true);

    var ContactEmail = document.getElementById("ContactEmail").value;
    if (ContactEmail != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var ContactCsvData = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/UserBlacklistContact",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          ContactEmail: ContactEmail,
          ClientID: ClientID,
          Role: Role,
          CreatedBy: UserID,
          UserID:UserID,
          CreatedDate:new Date()
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/blacklistedcontact/BlacklistContactImport",
          method: "POST",
          data: ContactCsvData,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            SetUpdateKey(UpdateKey + 1);
            SetTotal(res.data.Total);
            SetTotalSucess(res.data.TotalsSuccess);
            SetTotalError(res.data.TotalError);
            SetTotalDuplicate(res.data.TotalDuplication);
            SetTotalInvalid(res.data.TotalInvalid);
            SetAddBox(false);
            SetImportBox(true);
            SetImportMapBox(true);
            SetDeleteBox(false);
            SetDeleteMapBox(false);
            SetShowPopupDomainImport(false);
            SetCountBox(true);
            SetDisabledFields(true)
            SetCParentChild(true)
            document.getElementById("Show_csv_details_contact").style.cursor =
              "no-drop";
            // document.getElementById("ContactEmail").disabled = true;
            // document.getElementById("CSVSaveBtn").disabled = true;
            setcsvData([]);
            SetContactChaild(true);
            SetIsLoading(false);
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsize">
          Contact <br /> Contact not mapped!
        </div>
      );
      SetIsLoading(false);
      SetDisabledFields(false)
    }
  };

  // for contact tab
  const ContactDomainBulkDeleteBtn = () => {
    if (csvData.length != 0) {
      SetAddBox(false);
      SetImportBox(false);
      SetImportMapBox(false);
      SetDeleteBox(true);
      SetDeleteMapBox(true);
      SetShowPopupDomainImport(false);
      SetCountBox(false);

      DropboxData.map((dddd, index) => {});
    } else {
      toast.error(
        <div className="toastsize">
          Contact
          <br />
          Please select file
        </div>
      );
      SetAddBox(false);
      SetImportBox(false);
      SetImportMapBox(false);
      SetDeleteBox(true);
      SetDeleteMapBox(false);
      SetShowPopupDomainImport(false);
      SetCountBox(false);
      setcsvData([]);
    }
    hideCSVHeaderOptions()
  };

  const ContactDomainBulkCancleBtn = () => {
    resetcsv();
    SetAddBox(false);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
  };

  // for contact tab
  const ContactDomainBulkDeleteSaveBtn = () => {
    var ContactEmail = document.getElementById("ContactEmail1").value;
    if (ContactEmail != "not mapped") {
      SetIsLoading(true);
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var ContactBulkDeleteData = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/UserBlacklistContact",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          ContactEmail: ContactEmail,
          Role: Role,
          ClientID: ClientID,
          UserID: UserID,
          CreatedBy: UserID,

        };

        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/blacklistedcontact/BlacklistContactBulkDelete",
          method: "POST",
          data: ContactBulkDeleteData,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            resetcsv();
            SetAddBox(false);
            SetImportBox(false);
            SetImportMapBox(false);
            SetDeleteBox(false);
            SetDeleteMapBox(false);
            SetShowPopupDomainImport(false);
            SetCountBox(false);
            SetUpdateKey(UpdateKey + 1);
            toast.success(
              <div>
                Contact
                <br />
                Contact deleted successfully.
              </div>
            );
            SetContactChaild(true);
            SetIsLoading(false);
          } else {
            toast.error(res.data.msg);
            SetIsLoading(false);
          }
        });
      });
    } else {
      toast.error(
        <div>
          Contact
          <br />
          Contact not mapped!
        </div>
      );
    }
  };

  const ContactTabOnBlur = async () => {
    let formIsValid = true;
    let errors = {};
    var ContactEmail = document.getElementById("ContactEmailAdd").value.trim();
    let IsExist = await ContactEmailCheckExist(ContactEmail, false);
    let FLAGISVALID = await ContactTabEmailCheck(ContactEmail);

    if (ContactEmail == "" && ContactEmail.indexOf(" ") != "") {
      formIsValid = false;
      errors["ContactEmailAdd"] = "Please enter value";
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    if (FLAGISVALID == true && ContactEmail.length > 0) {
      formIsValid = false;
      errors["ContactEmailAdd"] = "Invalid email";
    }
    SetErrors(errors);
    return formIsValid;
  };
  // formvalidation for domain tab
  const ContactTabFormValidation = async () => {
    let formIsValid = true;
    let errors = {};
    var ContactEmail = document.getElementById("ContactEmailAdd").value.trim();
    let IsExist = await ContactEmailCheckExist(ContactEmail);
    // let regexp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
    let FLAGISVALID = await ContactTabEmailCheck(ContactEmail);

    if (ContactEmail == "" && ContactEmail.indexOf(" ") != "") {
      formIsValid = false;
      errors["ContactEmailAdd"] = "Please enter value";
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    if (FLAGISVALID == true && ContactEmail.length > 0) {
      formIsValid = false;
      errors["ContactEmailAdd"] = "Invalid email";
    }
    SetErrors(errors);
    return formIsValid;
  };

  //Email Check for domain
  const ContactTabEmailCheck = async (ContactEmail) => {
    var ContactEmail = document.getElementById("ContactEmailAdd").value.trim();
    var regexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!regexp.test(ContactEmail)) {
      SetIsValid(true);
      return true;
    } else {
      SetIsValid(false);
      return false;
    }
  };

  // cheack exists for contact email tab
  const ContactEmailCheckExist = async (ContactEmail, defaults = true) => {
    var ContactEmail = document.getElementById("ContactEmailAdd").value.trim();
    var str_in = {
      ClientID: ClientID,
      UserID: UserID,
      ContactEmail: ContactEmail,
      Role: Role,
    };
    let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/blacklistedcontact/BlacklistContactCheckExits",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (defaults == true) {
          toast.error(
            <div className="toastsize">
              Contact <br /> {ContactEmail} already exists.
            </div>
          );
        }
        SetIsExist(true);
        return true;
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };

  //  contact email add
  const ContactTabEmailBtn = async () => {
    SetBtnDisabled1(true);
    //e.preventDefault();
    SetIsLoading(true);
    var Final_flag = await ContactTabFormValidation();
    var ContactEmail = document.getElementById("ContactEmailAdd").value.trim();
    if (Final_flag == true) {
      var ContactDomainData = {
        ClientID: ClientID,
        ContactEmail: ContactEmail,
        IsDeleted: false,
        CreatedDate: new Date(),
        CreatedBy: UserID,
        UserID: UserID,
        Role: Role,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/blacklistedcontact/BlacklistContactAdd",
        method: "POST",
        data: ContactDomainData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          SetContactChaild(true);

          toast.success(
            <div>
              Contact
              <br />
              Contact added successfully.
            </div>
          );
          SetContactChaild(false);
          SetCParentChild(true)
          document.getElementById("ContactEmailAdd").value = "";
          // document.getElementById("Csubmit").disabled = false;
          HideContactEmail();
          SetIsLoading(false);
          SetUpdateKey(UpdateKey + 1);

        } else {
          toast.error(res.data.Message);
          SetBtnDisabled1(false);
          SetIsLoading(false);
        }
      });
    } else {
      // document.getElementById("Csubmit").disabled = false;
      SetBtnDisabled1(false);
      SetIsLoading(false);
    }
  };

  const HideContactEmail = () => {
    SetErrors({});
    document.getElementById("ContactEmailAdd").value = "";
    SetAddBox(false);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    // document.getElementById("addblacklistcontacview").style.display = "none";
    SetBtnDisabled1(false);
  };

  //** Removed Tab code start's from here *///

  // for removed import map
  const RemovedImportBtn = () => {
    SetAddBox(false);
    SetImportBox(true);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    setcsvData([]);
    resetcsv();
    SetDisabledFields(false)
  };

  const removedExportBtn = () => {
    toast.success(
      <div>
        Removed
        <br />
        Data exported successfully.
      </div>
    );
  };

  const RemovedDeleteCsvBtn = () => {
    SetAddBox(false);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(true);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    setcsvData([]);
    resetcsv();
  };

  // Show and hide div add
  const RemovedAddBtn = () => {
    SetAddBox(true);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    SetBtnDisabled2(false);
  };

  // for removed import map
  const RemovedImportMapCavBtn = () => {
    SetCountBox(false);

    SetDisabledFields(false)
    if (csvData.length != 0) {
      SetAddBox(false);
      SetImportBox(true);
      SetImportMapBox(true);
      SetDeleteBox(false);
      SetDeleteMapBox(false);
      SetShowPopupDomainImport(false);
      SetCountBox(false);
      // document.getElementById("RemovedcsvImport").disabled = false;
      // document.getElementById("Name").disabled = false;
      DropboxData.map((dddd, index) => {});
      resetcsv();
    } else {
      toast.error(
        <div className="toastsize">
          Removed
          <br />
          Please select file
        </div>
      );
      SetAddBox(false);
      SetImportBox(true);
      SetImportMapBox(false);
      SetDeleteBox(false);
      SetDeleteMapBox(false);
      SetShowPopupDomainImport(false);
      SetCountBox(false);
      setcsvData([]);
    }
    hideCSVHeaderOptions();
  };

  // for removed cancel csv
  const RemovedCancleCsvBtn = () => {
    resetcsv();
    SetAddBox(false);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    SetDisabledFields(false)
  };

  // for import csv removed
  const RemovedSaveCsvBtn = () => {
    SetIsLoading(true);
    var Name = document.getElementById("Name").value;
    if (Name != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var RemovedImportData = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/Userremoved",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          ClientID: ClientID,
          Name: Name,
          Role: Role,
          CreatedDate: new Date(),
          CreatedBy: UserID,
          UserID: UserID,

        };

        Axios({
          url: CommonConstants.MOL_APIURL + "/Otpoutsdomain/RemovedsImport",
          method: "POST",
          data: RemovedImportData,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            SetRemovedTotal(res.data.Total);
            SetRemovedTotalSucess(res.data.TotalSuccess);
            SetRemovedTotalError(res.data.TotalError);
            SetRemovedTotalDuplicate(res.data.TotalDuplicate);
            SetTotalInvalid(res.data.TotalInvalid);
            SetAddBox(false);
            SetImportBox(true);
            SetImportMapBox(true);
            SetDeleteBox(false);
            SetDeleteMapBox(false);
            SetShowPopupDomainImport(false);
            SetCountBox(true);
            SetDisabledFields(true)
            // document.getElementById("RemovedcsvImport").disabled = true;
            // document.getElementById("Name").disabled = true;
            setcsvData([]);
            SetIsLoading(false);
            SetRemovedChaild(true);
          } else {
            SetIsLoading(false);
            SetDisabledFields(false)
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsize">
          Opt outs <br />
          Opt outs not mapped!
        </div>
      );
      SetIsLoading(false);
      SetDisabledFields(false)
    }
  };

  // for otpous
  const RemovedBulkDeleteMapCsv = () => {
    if (csvData.length != 0) {
      SetAddBox(false);
      SetImportBox(false);
      SetImportMapBox(false);
      SetDeleteBox(true);
      SetDeleteMapBox(true);
      SetShowPopupDomainImport(false);
      SetCountBox(false);
      DropboxData.map((dddd, index) => {});
    } else {
      toast.error(
        <div className="toastsize">
          Removed
          <br />
          Please select file
        </div>
      );
      SetAddBox(false);
      SetImportBox(false);
      SetImportMapBox(false);
      SetDeleteBox(true);
      SetDeleteMapBox(false);
      SetShowPopupDomainImport(false);
      SetCountBox(false);
      setcsvData([]);
    }
    hideCSVHeaderOptions()
  };

  // for removed
  const RemovedBulkDeleteCancelCsv = () => {
    resetcsv();
    SetAddBox(false);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    setcsvData([]);
  };

  // for removed bulk delete
  const RemovedBulkDeleteCsvSaveBtn = () => {
    SetIsLoading(true);
    var Name = document.getElementById("Name1").value;
    if (Name != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var myresult = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/Userremoved",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Name: Name,
          Role: Role,
          ClientID: ClientID,
          UserID: UserID,
          LastUpdatedBy: UserID,

        };

        Axios({
          url: CommonConstants.MOL_APIURL + "/Otpoutsdomain/RemovedsBulkDelete",
          method: "POST",
          data: myresult,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            setcsvData([]);
            toast.success(
              <div className="toastsize">
                Removed <br />
                Removed deleted successfully.
              </div>
            );
            SetAddBox(false);
            SetImportBox(false);
            SetImportMapBox(false);
            SetDeleteBox(false);
            SetDeleteMapBox(false);
            SetShowPopupDomainImport(false);
            SetCountBox(false);
            SetWhiteListChaild(true);
            SetIsLoading(false);
            SetUpdateKey(UpdateKey + 1);

          } else {
            toast.error(res.data.message);
            SetIsLoading(false);
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsize">
          Opt outs
          <br />
          Opt outs not mapped!{" "}
        </div>
      );
      SetIsLoading(false);
    }
  };

  const RemovedOnBlur = async () => {
    let formIsValid = true;
    let errors = {};
    var RemovedEmail = document.getElementById("RemovedEmail").value.trim();
    let IsExist = await RemovedEmailCheckExist(RemovedEmail, false);
    let FLAGISVALID = await RemovedTabEmailCheck(RemovedEmail);

    if (RemovedEmail == "" && RemovedEmail.indexOf(" ") != "") {
      formIsValid = false;
      errors["RemovedEmail"] = "Please enter value";
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    if (FLAGISVALID == true && RemovedEmail != "") {
      formIsValid = false;
      errors["RemovedEmail"] = "Invalid email";
    }
    SetErrors(errors);
    return formIsValid;
  };
  // formvalidation for removed tab
  const RemovedTabFormValidation = async () => {
    let formIsValid = true;
    let errors = {};
    var RemovedEmail = document.getElementById("RemovedEmail").value.trim();
    let IsExist = await RemovedEmailCheckExist(RemovedEmail);
    let FLAGISVALID = await RemovedTabEmailCheck(RemovedEmail);

    if (RemovedEmail == "" && RemovedEmail.indexOf(" ") != "") {
      formIsValid = false;
      errors["RemovedEmail"] = "Please enter value";
      SetIsLoading(false);
    }
    if (IsExist == true) {
      formIsValid = false;
      SetIsLoading(false);
    }

    if (FLAGISVALID == true && RemovedEmail != "") {
      formIsValid = false;
      errors["RemovedEmail"] = "Invalid email";
      SetIsLoading(false);
    }
    SetErrors(errors);
    return formIsValid;
  };

  // cheack exists for  removed email tab
  const RemovedEmailCheckExist = async (RemovedEmail, defaults = true) => {
    var RemovedEmail = document.getElementById("RemovedEmail").value.trim();
    var str_in = {
      ClientID: ClientID,
      UserID: UserID,
      Name: RemovedEmail,
      Role: Role,
    };
    let resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/Otpoutsdomain/RemovedsCheckExists",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (defaults == true) {
          toast.error(
            <div className="toastsize">
              Removed
              <br /> {RemovedEmail} already exists.
            </div>
          );
        }
        SetIsExist(true);
        SetIsLoading(false);
        return true;
      } else {
        SetIsExist(false);
        SetIsLoading(false);
        return false;
      }
    }
  };
  //Email Check for removed tab
  const RemovedTabEmailCheck = async (RemovedEmail) => {
    var RemovedEmail = document.getElementById("RemovedEmail").value.trim();
    var regexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!regexp.test(RemovedEmail)) {
      SetIsValid(true);
      SetIsLoading(false);
      return true;
    } else {
      SetIsValid(false);
      SetIsLoading(false);
      return false;
    }
  };
  // remove tab
  const RemovedEmailSaveBtn = async () => {
    SetBtnDisabled2(true);
    var Final_flag = await RemovedTabFormValidation();
    var Name = document.getElementById("RemovedEmail").value.trim();
    if (Final_flag == true) {
      SetIsLoading(true);
      var data = {
        ClientID: ClientID,
        Name: Name,
        CreatedDate: new Date(),
        CreatedBy: UserID,
        UserID: UserID,
        Role: Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/Otpoutsdomain/RemovedsAdd",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          SetRemovedChaild(true);
          toast.success("Added Removed Successfully");
          document.getElementById("RemovedEmail").value = "";
          RemovedHideBtn();
          SetRemovedChaild(false);
          SetIsLoading(false);
          SetWhiteListChaild(true);
          SetUpdateKey(UpdateKey + 1);

        } else {
          toast.error(res.data.Message);
          SetBtnDisabled2(false);
          SetIsLoading(false);
        }
      });
    } else {
      document.getElementById("Rsubmit").disabled = false;
      SetBtnDisabled2(false);
      SetIsLoading(false);
    }
  };

  const RemovedHideBtn = () => {
    SetErrors({});
    SetAddBox(false);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    // document.getElementById("RemovedEmail").value = "";
    SetBtnDisabled2(false);
  };

  //*** Whitelist tab code starts from here *///

  const WhiteListImportBtn = () => {
    SetAddBox(false);
    SetImportBox(true);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    setcsvData([]);
    resetcsv();
    SetDisabledFields(false)
  };

  const WhiteListExportCsvBtn = () => {
    toast.success(
      <div>
        Whitelist
        <br />
        Data exported successfully.
      </div>
    );
  };

  // for whitelist delete
  const WhiteListDeleteBtn = () => {
    SetAddBox(false);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(true);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
  };

  const WhiteListShowBtn = () => {
    SetAddBox(true);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    SetBtnDisabled3(false);
    SetOpenBulkDeletePhone(false)
    SetBulkPhoneNumberMapBox(false)
    SetOpenImportPhoneNumber(false)
    SetImportPhoneNumberMapBox(false);
  };

  // for white list import csv
  const WhiteListImportMapCsv = () => {
    SetDisabledFields(false)
    SetCountBox(false);

    if (csvData.length != 0) {
      SetAddBox(false);
      SetImportBox(true);
      SetImportMapBox(true);
      SetDeleteBox(false);
      SetDeleteMapBox(false);
      SetShowPopupDomainImport(false);
      SetCountBox(false);
      DropboxData.map((dddd, index) => {});
      resetcsv();
    } else {
      toast.error(
        <div className="toastsize">
          Whitelist
          <br />
          Please select file
        </div>
      );
      SetAddBox(false);
      SetImportBox(true);
      SetImportMapBox(false);
      SetDeleteBox(false);
      SetDeleteMapBox(false);
      SetShowPopupDomainImport(false);
      SetCountBox(false);

      resetcsv();
    }
    hideCSVHeaderOptions();
  };

  // for whitelist csv
  const WhiteListCancelCsv = () => {
    resetcsv();
    SetAddBox(false);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    SetDisabledFields(false)

  };

  // for whitelist csv
  const WhiteListSaveCsvBtn = () => {
    SetDisabledFields(true)

    SetIsLoading(true);
    var Whitelist = document.getElementById("Whitelist").value;
    if (Whitelist != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var myresult = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/UserWhitelistDomain",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          ContactEmail: Whitelist,
          ClientID: ClientID,
          Role: Role,
          UserID: UserID,
          CreatedBy: UserID,
          CreatedDate: new Date(),
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/whitelistedcontact/WhiteListedImportFile",
          method: "POST",
          data: myresult,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            SetTotalWhiteList(res.data.Total);
            SetTotalWhiteListSucess(res.data.TotalsSuccess);
            SetTotalWhiteListError(res.data.TotalError);
            SetTotalWhiteListDuplicate(res.data.TotalDuplication);
            SetTotalInvalid(res.data.TotalInvalid);
            SetAddBox(false);
            SetImportBox(true);
            SetImportMapBox(true);
            SetDeleteBox(false);
            SetDeleteMapBox(false);
            SetShowPopupDomainImport(false);
            SetCountBox(true);
            document.getElementById("WhiteCSVImport").disabled = true;
            document.getElementById("Whitelist").disabled = true;
            document.getElementById("Whitelist").disabled = true;

            setcsvData([]);
            SetIsLoading(false);
            SetWhiteListChaild(true);
          } else {
            toast.error(res.data.message);
            SetIsLoading(false);
    SetDisabledFields(false)

          }
        });
      });
    } else {
      toast.error(
        <div className="toastsize">
          Whitelist <br /> Whitelist not mapped!
        </div>
      );
      SetIsLoading(false);
    SetDisabledFields(true)
      

    }
  };
  // for whitelist tav
  const WhiteListBDeleteMapCsv = () => {
    
    if (csvData.length != 0) {
      SetAddBox(false);
      SetImportBox(false);
      SetImportMapBox(false);
      SetDeleteBox(true);
      SetDeleteMapBox(true);
      SetShowPopupDomainImport(false);
      SetCountBox(false);
      resetcsv();
      DropboxData.map((dddd, index) => {});
    } else {
      resetcsv();
      setcsvData([]);
      toast.error(
      <div className="toastsize">
        Whitelist
        <br />
        Please select file
      </div>
            );
      SetAddBox(false);
      SetImportBox(false);
      SetImportMapBox(false);
      SetDeleteBox(true);
      SetDeleteMapBox(false);
      SetShowPopupDomainImport(false);
      SetCountBox(false);
    
    }
    hideCSVHeaderOptions()
  };

  // for whitelist cancel csv
  const WhiteListBDeleteCancelCsv = () => {
    resetcsv();
    SetAddBox(false);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    setcsvData([]);
  };

  // for whitelist contact csv
  const WhiteListBulkDeleteBtn = () => {
    var ContactEmail = document.getElementById("WhitelistName1").value;
    if (ContactEmail != "not mapped") {
      SetIsLoading(true);
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var myresult = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/UserWhitelistDomain",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          ContactEmail: ContactEmail,
          Role: Role,
          UserID:UserID,
          ClientID:ClientID
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/whitelistedcontact/WhitelistBluckDelete",
          method: "POST",
          data: myresult,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            SetIsLoading(false);
            SetAddBox(false);
            SetImportBox(false);
            SetImportMapBox(false);
            SetDeleteBox(false);
            SetDeleteMapBox(false);
            SetShowPopupDomainImport(false);
            SetCountBox(false);
            toast.success(
              <div className="toastsize">
                Whitelist <br /> Whitelist deleted successfully.
              </div>
            );
            SetUpdateKey(UpdateKey + 1);

          } else {
            toast.error(res.data.Message);
            SetIsLoading(false);
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsize">
          Whitelist <br /> Whitelist not mapped!
        </div>
      );
      SetIsLoading(false);
    }
  };

  //Email Check for domain
  const WhiteTabEmailCheck = async (ContactEmail) => {
    var ContactEmail = document
      .getElementById("ContactEmailWhitelist")
      .value.trim();
    var regexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!regexp.test(ContactEmail)) {
      SetIsValid(true);
      return true;
    } else {
      SetIsValid(false);
      return false;
    }
  };

  // cheack exists for contact email tab
  const WhiteEmailCheckExist = async (ContactEmail, defaults = true) => {
    var ContactEmail = document
      .getElementById("ContactEmailWhitelist")
      .value.trim();
    var str_in = {
      ClientID: ClientID,
      UserID: UserID,
      ContactEmail: ContactEmail,
      Role: Role,
    };
    let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/whitelistedcontact/WhiteListedCheackExists",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (defaults == true) {
          toast.error(
            <div className="toastsize">
              Whitelist <br /> {ContactEmail} already exists
            </div>
          );
        }
        SetIsExist(true);
        return true;
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };

  const WhitelistEmailOnBlur = async () => {
    let formIsValid = true;
    let errors = {};
    var ContactEmail = document
      .getElementById("ContactEmailWhitelist")
      .value.trim();
    let IsExist = await WhiteEmailCheckExist(ContactEmail, false);
    let FLAGISVALID = await WhiteTabEmailCheck(ContactEmail);

    if (ContactEmail == "" && ContactEmail.indexOf(" ") != "") {
      formIsValid = false;
      errors["ContactEmailWhitelist"] = "Please enter value";
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    if (FLAGISVALID == true && ContactEmail.length > 0) {
      formIsValid = false;
      errors["ContactEmailWhitelist"] = "Invalid email";
    }
    SetErrors(errors);
    return formIsValid;
  };

  // formvalidation for white tab
  const WhiteTabFormValidation = async () => {
    let formIsValid = true;
    let errors = {};
    var ContactEmail = document
      .getElementById("ContactEmailWhitelist")
      .value.trim();
    let IsExist = await WhiteEmailCheckExist(ContactEmail);
    let FLAGISVALID = await WhiteTabEmailCheck(ContactEmail);

    if (ContactEmail == "" && ContactEmail.indexOf(" ") != "") {
      formIsValid = false;
      errors["ContactEmailWhitelist"] = "Please enter value";
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    if (FLAGISVALID == true && ContactEmail.length > 0) {
      formIsValid = false;
      errors["ContactEmailWhitelist"] = "Invalid email";
    }
    SetErrors(errors);
    return formIsValid;
  };

  // Add Whitelist
  const WhiteListAddBtn = async () => {
    SetBtnDisabled3(true);

    var Final_flag = await WhiteTabFormValidation();
    var ContactEmail = document
      .getElementById("ContactEmailWhitelist")
      .value.trim();
    if (Final_flag == true) {
      var WhiteListAddData = {
        ClientID: ClientID,
        ContactEmail: ContactEmail,
        CreatedDate: new Date(),
        CreatedBy: UserID,
        UserID: UserID,
        LastUpdatedBy: UserID,
        Role: Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/whitelistedcontact/WhiteListedAdd",
        method: "POST",
        data: WhiteListAddData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          SetWhiteListChaild(true);
          toast.success(
            <div className="toastsize">
              WhiteList
              <br />
              WhiteList added successfully.
            </div>
          );
          document.getElementById("ContactEmailWhitelist").value = "";

          SetWhiteListChaild(false);
          SetWParentChild(true)
          CloseBtnForWhieBtn();
          SetUpdateKey(UpdateKey + 1);
        } else {
          toast.error(res.data.Message);
          SetBtnDisabled3(false);
        }
      });
    } else {

      SetBtnDisabled3(false);
    }
  };

  const CloseBtnForWhieBtn = () => {
    SetErrors({});
    SetAddBox(false);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    SetBtnDisabled3(false);
  };

  //** other helper methods **//
  const handleChange = (event, newValue) => {
    setValue(newValue);
    SetBtnDisabled(false);
    SetBtnDisabled1(false);
    SetBtnDisabled2(false);
    SetBtnDisabled3(false);
    SetAddBox(false);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    SetErrors({});
    SetOpenImportPhoneNumber(false)
    SetImportPhoneNumberMapBox(false);
    SetDropboxData([]);
    setcsvData([]);
    SetOpenBulkDeletePhone(false)
    SetPhoneNumberList(null)
    SetDisplayPhoneCount(false)
    SetPhoneNumberCountBox(false)
    SetBulkPhoneNumberMapBox(false)
    resetcsv();
  };

  const FromValidation = () => {
    let formIsValid = true;
    if (Object.entries(Fields).length > 0) {
      let Fields = Fields;
      let Errors = {};

      if (!Fields["Domain"]) {
        formIsValid = false;
        Errors["Domain"] = "Please enter Domain";
      }
      if (DomainNameAvaiable != null) {
        formIsValid = false;
      }
      // this.setState({ Errors: Errors });
      SetErrors(Errors);
    }
    return formIsValid;
  };
  const UpdateFromChild = (value, page) => {
    if (value == true) {
      if (page === "Domain") {
        SetDParentChild(true);
        SetUpdateKey(UpdateKey + 1);
      } else if (page === "Contact") {
        SetCParentChild(true);
        SetUpdateKey(UpdateKey + 1);
      } else if (page === "Removed") {
        SetRParentChild(true);
        SetUpdateKey(UpdateKey + 1);
      } else {
        SetWParentChild(true);
        SetUpdateKey(UpdateKey + 1);
      }
    }
  };

  // handle change blacklist domain
  const HandleChangeDomain = (field) => {
    var Domain = document.getElementById("DomainFor").value;
    document.getElementById("Domain").style.display = "none";
    SetFields(Domain);
    if (Fields.Domain != "") {
      Errors.Domain = null;
      var Domain = document.getElementById("Domain").value;

      BlacklistDomainNameCheckExist(Domain);
    } else {
      DomainNameAvaiable = null;
      Errors.Domain = null;
    }
  };

  // For Chekexits name
  const HandleInputContactChange = () => {
    var ContactEmail = document.getElementById("ContactEmail").value;
    document.getElementById("validioncontactdomain").style.display = "none";

    SetFields(ContactEmail);
    if (Fields.ContactEmail != "") {
      Errors.ContactEmail = null;
    } else {
      DomainNameAvaiable = null;
      Errors.ContactEmail = null;
    }
  };

  // for white listed contact
  const HandleInputWhiteListChange = () => {
    var ContactEmailWhitelist = document.getElementById(
      "ContactEmailWhitelist"
    ).value;

    SetFields(ContactEmailWhitelist);
    if (Fields.ContactEmailWhitelist != "") {
      Errors.ContactEmailWhitelist = null;
      // DomainNameAvaiable = null;
      // var Domain = document.getElementById("ContactEmail").value;

      // BlackListDomainCheckExits(Domain)
    } else {
      // DomainNameAvaiable = null;
      // Errors.ContactEmail = null;
    }
  };

  document.addEventListener("mouseup", function (e) {
    if (popupval) {
      setpopup(false);
    }
  });

  // for white listed
  const hidewhitecontactdomain = () => {
    document.getElementById("whitelistcontacview").style.display = "none";
  };

  // Import csv file

  const resetcsv = () => {
    SetReset(!Reset);
  };

  // for contact tab

  // csv hide header
  const hideCSVHeaderOptions = () => {
    var $select = $(".csvcontent");
    $select.find("option").show();
    $select.each(function () {
      var $this = $(this);
      var value = $this.val();
      var $options = $this
        .parents("#divContactSelection")
        .find(".csvcontent")
        .not(this)
        .find("option");
      var $option = $options.filter('[value="' + value + '"]');
      if (value) {
        $option.hide();
      }
    });
  };
  // csv default select
  $("select").on("change", function () {
    var $this = $(this);
    var value = $this.val();
    var Name = $(this).attr("id");
    console.log(value);
    if (Name == "Domain") {
      SetDomain(value);
    } else if (Name == "CreatedDate") {
      SetCreatedDate(value);
    }else if (Name == "Contact") {
      SetContact(value);
    }else if (Name == "Removed") {
      SetRemoved(value);
    }else if (Name == "Whitelist") {
      SetWhitelist(value);
    }else if (Name == "PhoneBlacklist") {
      SetPhoneNumberList(value);
    }

    hideCSVHeaderOptions();
  });

  // for remove tab
  const HandleChangeRemoved = (field) => {
    var Name = document.getElementById("Name").value;
    document.getElementById("validotpput").style.display = "none";

    SetFields(Name);
    if (Fields.Name != "") {
      Errors.Name = null;
    } else {
    }
  };

  //////// Phone Number

  const PhoneNumberAdd = async () => {
    SetphonenumberBtn(true);
    var IsValid = await PhoneNumberOnBlur();
    if (IsValid) {
      var Phonenumber = document.getElementById("phonenumber").value.trim();

      var PhonenumberAddData = {
        ClientID: ClientID,
        PhoneNumber: Phonenumber,
        UserID: UserID,
        CreatedBy: PUserID,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/phonenumberblacklist/phonenumberadd",
        method: "POST",
        data: PhonenumberAddData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          SetPhoneNumberChaild(true);
          toast.success(<div className="toastsize">Phone Number<br />Phone Number added successfully.</div>);
          document.getElementById("phonenumber").value = "";

          SetPhoneNumberChaild(false);
          SetPhoneNumberParentChild(true)
          PhoneNumberClose();
          SetUpdateKey(UpdateKey + 1);
        } else {
          toast.error("Internal server error");
          SetphonenumberBtn(false);
        }
      });
    } else {
      SetphonenumberBtn(false);
    }
  };

  const PhoneNumberClose = () => {
    SetErrors({});
    SetAddBox(false);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    SetphonenumberBtn(false);
  };
  const PhoneNumberOnBlur = async () => {
    let formIsValid = true;
    let errors = {};
    var PhoneNumber = document.getElementById("phonenumber").value.trim();
    let IsExist = await PhoneNumberCheckExist(PhoneNumber);

    if (PhoneNumber == "" && PhoneNumber.indexOf(" ") != "") {
      formIsValid = false;
      errors["PhoneNumberlist"] = "Please enter phone number";
    }else if(!/^[0-9]+$/.test(PhoneNumber?.trim())){
      formIsValid = false;
      errors["PhoneNumberlist"] = "Invalid phone number";
    }
    if (IsExist == true) {
      formIsValid = false;
    }
    SetErrors(errors);
    return formIsValid;
  };

  // cheack exists for contact email tab
  const PhoneNumberCheckExist = async (phonenumber) => {
    var str_in = {
      ClientID: ClientID,
      UserID: UserID,
      PhoneNumber: phonenumber,

    };
    let resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/phonenumberblacklist/phonenumberCheackExists",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.PhoneNumberData.length > 0) {
        if (OldPhoneNumberData == phonenumber) {
          SetIsExist(false);
          return false;
        } else {
          toast.error("Phone number is already exists!");
          SetIsExist(true);
          SetIsLoading(false)
          return true;
        }
      } else {
        SetIsExist(false);
        SetIsLoading(false)
        return false;
      }
    }
  };

  const ExportBacklinks = (ClientID) => {
    SetIsLoading(true)
    var requestData = {
      ClientID: ClientID
   };

    Axios({
      url: CommonConstants.MOL_APIURL + "/phonenumberblacklist/phonenumberblacklistExport",
      method: "POST",
      data: requestData,
    }).then((Result) => {
      if (Result.data.StatusMessage = "SUCCESS") {
        var url = Result.data.PageData;
        const a = document.createElement('a');
        a.href = url;
        a.click();
        URL.revokeObjectURL(url);

       
        toast.success(<div className="toastsize">Phone number blacklist  <br />  Data exported successfully.</div>);
        SetIsLoading(false)
      }
      else {
        SetIsLoading(false)
      }

    }).catch(error => {
      console.error('Error fetching export Phone number blacklist', error);
      SetIsLoading(false)
    });
  }

    // Open Import phone number div
    const OpenImportPhoneNumberDiv = () => {
      SetOpenImportPhoneNumber(true)
      SetImportPhoneNumberMapBox(false);
      SetDropboxData([]);
      setcsvData([]);
      SetAddBox(false)
      SetOpenBulkDeletePhone(false)
      SetBulkPhoneNumberMapBox(false)
    }
  
    // Upload CSV file in phone number 
    const UploadCSVInPhoneNumber = () => {
      SetDisabledFields(false)
      SetCountBox(false);
  
      if (csvData.length != 0) {
        SetImportPhoneNumberMapBox(true);
        SetPhoneNumberCountBox(false)
        SetAddBox(false);
        SetImportBox(true);
        SetImportMapBox(true);
        SetDeleteBox(false);
        SetDeleteMapBox(false);
        SetShowPopupDomainImport(false);
        SetCountBox(false);
        DropboxData.map((dddd, index) => { });
        resetcsv();
      } else {
        toast.error(
          <div className="toastsize">
            Phone number
            <br />
            Please select file
          </div>
        );
        SetAddBox(false);
        SetImportPhoneNumberMapBox(false);
        SetDeleteBox(false);
        SetDeleteMapBox(false);
        SetShowPopupDomainImport(false);
        SetCountBox(false);
  
        resetcsv();
      }
      hideCSVHeaderOptions();
    }

   //Save Csv data into DB
   const SavePhoneNumberCSVDetails = () => {
    SetDisabledFields(true)

    SetIsLoading(true);
    var PhoneNumberIndex = document.getElementById("PhoneNumber").value;
    if (PhoneNumberList != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var myresult = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/PhoneNumberBlacklist",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          PhoneNumberIndex: PhoneNumberIndex,
          ClientID: ClientID,
          Role: Role,
          UserID: UserID,
          CreatedBy: UserID,
          CreatedDate: new Date(),
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL +"/phonenumberblacklist/BulkImportPhoneNumber",
          method: "POST",
          data: myresult,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            SetPhoneNumberImportCount({
              Total:res.data.Data?.Total,
              Success:res.data.Data?.Success,
              Duplicate:res.data.Data?.Duplicate,
              Error:res.data.Data?.Error,
              Invalid:res.data.Data?.Invalid,
            });
            resetcsv();
            SetDisplayPhoneCount(true)
            // SetAddBox(false);
            // SetImportBox(true);
            // SetImportMapBox(true);
            // SetDeleteBox(false);
            // SetDeleteMapBox(false);
            // SetShowPopupDomainImport(false);
            // SetCountBox(true);
            // document.getElementById("WhiteCSVImport").disabled = true;
            // document.getElementById("Whitelist").disabled = true;
            // document.getElementById("Whitelist").disabled = true;
            SetUpdateKey(UpdateKey + 1);
            setcsvData([]);
            SetIsLoading(false);
            SetWhiteListChaild(true);
          } else {
            toast.error(res.data.message);
            SetIsLoading(false);
            SetDisabledFields(false)

          }
        });
      });
    } else {
      toast.error(
        <div className="toastsize">
          Whitelist <br /> Whitelist not mapped!
        </div>
      );
      SetIsLoading(false);
      SetDisabledFields(true)


    }
  }

    //Cancel to upload csv file in phone number
    const CancelToUploadPhoneNumberCSV = () => {
      resetcsv();
      SetOpenImportPhoneNumber(false)
      SetImportPhoneNumberMapBox(false);
      SetPhoneNumberCountBox(false)
      SetDisplayPhoneCount(false)
  
      SetAddBox(false);
      SetImportBox(false);
      SetDeleteBox(false);
      SetDeleteMapBox(false);
      SetCountBox(false);
      SetDisabledFields(false)
    }

   //Open bulk delete model
   const OpenBulkDeleteModel = () => {
    SetOpenBulkDeletePhone(true)
    SetBulkPhoneNumberMapBox(false)
    SetOpenImportPhoneNumber(false)
    SetImportPhoneNumberMapBox(false);

    resetcsv();
    setcsvData([]);
    SetAddBox(false)

  }

   // Mapping bulk delete phone number
   const MappingBulkDeletePhone = () => {
    if (csvData.length != 0) {
      SetBulkPhoneNumberMapBox(true)
      SetAddBox(false);
      SetImportBox(false);
      SetImportMapBox(false);
      SetDeleteBox(true);
      SetDeleteMapBox(true);
      SetShowPopupDomainImport(false);
      SetCountBox(false);
      resetcsv();
    } else {
      resetcsv();
      setcsvData([]);
      toast.error(
        <div className="toastsize">
          Phone number
          <br />
          Please select file
        </div>
      );
      SetAddBox(false);
      SetImportBox(false);
      SetImportMapBox(false);
      SetDeleteBox(true);
      SetDeleteMapBox(false);
      SetShowPopupDomainImport(false);
      SetCountBox(false);

    }
    hideCSVHeaderOptions()
  }
    // Bulk deleting phone number
    const BulkDeletingPhoneNumber = () => {
      var PhoneNumberIndex = document.getElementById("WhitelistName1").value;
      if (PhoneNumberIndex != "not mapped") {
        SetIsLoading(true);
        var csvdata = csvData;
        Fileupload.getBase64(files, (result) => {
          var rs = result;
          var splirs = rs.split("base64,");
          var _base64 = splirs[1];
  
          var myresult = {
            Fields: "Coverimg",
            Path: "./Content/UploadedFiles/PhoneNumberBlacklist",
            File: _base64,
            ImageName: filename,
            FileData: csvdata,
            PhoneNumberIndex: PhoneNumberIndex,
            Role: Role,
            UserID: UserID,
            ClientID: ClientID,
            CreatedBy: UserID,
            CreatedDate: new Date(),
          };
  
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/phonenumberblacklist/BulkDeletePhoneNumber",
            method: "POST",
            data: myresult,
          }).then((res) => {
            if (res.data.StatusMessage == "SUCCESS") {
              SetPhoneNumberList(null)
              CloseBulkDeleteModel()
              SetIsLoading(false);
              SetAddBox(false);
              SetImportBox(false);
              SetImportMapBox(false);
              SetDeleteBox(false);
              SetDeleteMapBox(false);
              SetShowPopupDomainImport(false);
              SetCountBox(false);
              toast.success(
                <div className="toastsize">
                  Phone number <br /> Phone number deleted successfully.
                </div>
              );
              SetUpdateKey(UpdateKey + 1);
  
            } else {
              toast.error(res.data.Message);
              SetIsLoading(false);
            }
          });
        });
      } else {
        toast.error(
          <div className="toastsize">
            Phone number <br /> Phone  number not mapped!
          </div>
        );
        SetIsLoading(false);
      }
    }

    //Close bulk delete model
    const CloseBulkDeleteModel = () => {
      SetOpenBulkDeletePhone(false);
      SetBulkPhoneNumberMapBox(false)
      setcsvData([]);
  
    }

  return (
    <div className="tabdes">
      {IsLoading == true ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}

      <div>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            aria-label="simple tabs example"
          >
            <Tab label="Domain" {...a11yProps(0)}>
              Overviewasdasd
            </Tab>
            <Tab label="Contact" {...a11yProps(1)} />
            <Tab label="Removed" {...a11yProps(2)} />
            <Tab label="Whitelist" {...a11yProps(3)} />
            <Tab label="Phone Number" {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone bg-white px-2" index={0}>
          <Popup open={ShowPopupDomainImport}>
            <div>
              <div className="modal-black"></div>
              <div className="filterPopup largerPopup">
                <div className="paddingboxTerms">
                  <div className="modal-header py-4 px-3">
                    <div className="w-100 d-flex px-3">
                      <h5 className="mb-0">Email</h5>
                      <button
                        className="close"
                        onClick={() => {
                          SetShowPopupDomainImport(false);
                        }}
                      >
                        <span
                          aria-hidden="true"
                          className="la la-remove"
                        ></span>
                      </button>
                    </div>
                  </div>
                  <div className="p-3">
                    <div className="modal-body">
                      <div className="col-sm-6 mb-5 px-0">
                        <div className="row mb-3">
                          <label className="col-lg-5 col-form-label text-left">
                            Email
                          </label>
                          <div className="col-lg-7">
                            <input
                              className="form-control m-input"
                              id="Username"
                              data-val="true"
                              data-val-remote="Title already exist."
                              data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                              data-val-required="Please enter Email "
                              name="Username"
                              defaultValue={UserEmail}
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-top modal-content text-right">
                      <div className="row">
                        <div className="col-lg-12 pull-right">
                          <a
                            id="backtolist"
                            className="btn btn-secondary mr-2"
                            onClick={() => {
                              SetShowPopupDomainImport(false);
                            }}
                          >
                            cancel
                          </a>
                          <button
                            id="submit"
                            onClick={() => DomainUploadCsv()}
                            disabled={BtnPopDisabled}
                            className="btn btn-primary btn-lightgreen mr-1"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Popup>
          <div className="row">
            <div className="col border-bottom pl-0">
              <h4 className="headertitle float-left pl-0">Domain</h4>
            </div>
            <div className="col border-bottom">
              <div className="listing-li float-right padb-15 pr-1">
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        ShowBlackListDomain();
                      }}
                      className="btn btngroup m-btn"
                    >
                      <i className="la la-icon-font-size-13 la-plus"></i>
                      <span>Add</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={ImportBlackListCsv}
                      className="btn btngroup m-btn"
                    >
                      <i className="la la-icon-font-size-13 la-upload"></i>
                      <span>Import</span>
                    </a>
                  </li>
                  <li className="m-portlet__nav-item">
                    <a
                      onClick={BlackListBulkDelete}
                      className="btn btngroup m-btn"
                    >
                      <span>
                        <i className="la flaticon-delete-1 la-icon-font-size-13 pl-0"></i>
                        <span> Bulk Delete</span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <CSVDownloader
                      className="px-0"
                      data={Exportdata}
                      filename={CName + "-Saleshive_Page_Blacklist_Domain"}
                      bom={true}
                    >
                      <a
                        onClick={ExportBlackListCsv}
                        className="btn btngroup m-btn"
                      >
                        <i className="la la-icon-font-size-13 la-download"></i>
                        <span> Export</span>
                      </a>
                    </CSVDownloader>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Import Csv for Black list Domain*/}
          {ImportBox === true ? (
            <div className="portletbody" id="import_csv">
              <div className="row">
                <div className="col-xl-8 offset-xl-2">
                  <h3 className="uploadheadingtitle">
                    Import Blacklist Domain
                  </h3>
                  <div className="uplodfilesbox">
                    <CSVReader
                      accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                      onDrop={HandleOnDrop}
                      onError={HandleOnError}
                      addRemoveButton
                      removeButtonColor="#659cef"
                      onRemoveFile={() => {
                        HandleOnRemoveFile();
                      }}
                      isReset={Reset}
                    >
                      <span>Select .CSV file</span>
                      <p>Add domain data from csv file to import.</p>
                    </CSVReader>
                  </div>
                </div>
                <div className="col-xl-8 offset-xl-2 border-top pt-2 mt-4">
                  <div className="alignright">
                    <a
                      onClick={BlacklistDomainMapCsv}
                      className="btn btn-primary btnColor"
                    >
                      <i className="la la-upload"></i> Upload
                    </a>
                    <a
                      onClick={BlacklistDomainImportCancelBtn}
                      className="btn btn-secondary"
                    >
                      <i className="la flaticon-cancel"></i> Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Mapped div */}
          {ImportMapBox === true ? (
            <div className="portletbody portletshadow" id="map_attribute">
              <div className="row col-xs-12">
                <h4 className="headertitle float-left">Map attributes</h4>
              </div>
              <div className="col-xs-12" id="divContactSelection">
                <div className="form-group m-form__group d-flex">
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        id="dbColumn1"
                        disabled="disabled"
                      >
                        <option selected="" value="Domain">
                          Domain
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        className="csvcontent form-control  m-input"
                        id="Domain"
                        name="Domain"
                        autocomplete="off"
                        value={
                          Domain == null
                            ? DropboxData.findIndex((item) => item.trim().toLowerCase() === "domain")
                            : Domain
                        }
                        onChange={(e) => {
                          SetDomain(e.target.value);
                        }}
                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <button
                    id="ImportDomainAddBtn"
                    onClick={() => {
                      OpenDomainPopupBtn();
                    }}
                    className="btn btn-primary btn-lightgreen"
                  >
                    <i className="la la-save"></i>Delete
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Delete for csv */}
          {/* Bulk Csv for */}
          {DeleteBox ? (
            <div className="portletbody" id="delete_csv">
              <div className="row">
                <div className="col-xl-8 offset-xl-2">
                  <h3 className="uploadheadingtitle">
                    Bulk deleted Blacklist Domain
                  </h3>
                  <div className="uplodfilesbox">
                    <CSVReader
                      accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                      onDrop={HandleOnDrop}
                      onError={HandleOnError}
                      addRemoveButton
                      removeButtonColor="#659cef"
                      onRemoveFile={HandleOnRemoveFile}
                      isReset={Reset}
                    >
                      <span>Select .CSV file</span>
                      <p>Add domain data from csv file to bulk delete.</p>
                    </CSVReader>
                  </div>
                </div>
                <div className="col-xl-8 offset-xl-2 border-top pt-2 mt-4">
                  <div className="alignright">
                    <a
                      onClick={BlacklistDomainBulkDeleteUpload}
                      className="btn btn-primary btnColor"
                    >
                      <i className="la la-upload"></i> Upload
                    </a>
                    <a
                      onClick={BlacklistDomainImportCancelBtn}
                      className="btn btn-secondary"
                    >
                      <i className="la flaticon-cancel"></i> Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Mapped div */}
          {DeleteMapBox ? (
            <div className="portletbody portletshadow" id="map_attribute_domain_blacklist">
              <div className="row col-xs-12 px-2">
                <h4 className="headertitle float-left pl-4">Map attributes</h4>
              </div>
              <div className="col-xs-12">
                <div className="form-group m-form__group d-flex">
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                      >
                        <option selected="" value="Domain">
                          Domain
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        id="Domain1"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        value={
                          Domain == null
                            ? DropboxData.findIndex((item) => item.trim().toLowerCase() === "domain")
                            : Domain
                        }
                        onChange={(e) => {
                          SetDomain(e.target.value);
                        }}
                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
                <div className="col-lg-12">
                  <a
                    onClick={() => {
                      BlacklistDomainBulkDelete();SetUpdateKey(UpdateKey + 1);
                    }}
                    className="btn btn-primary btn-lightgreen"
                  >
                    <i className="la la-save"></i>Delete
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {AddBox ? (
            <div className="addkeyword row my-4" id="addblacklistview">
              <div className="col-sm-12 col-md-6 row px-0">
                <label className="col-lg-3 view-lable-meet">Domain</label>
                <div className="col-lg-7">
                  <input
                    className="form-control m-input"
                    data-val="true"
                    data-val-required="Please enter Domain name"
                    id="DomainFor"
                    onBlur={(e) => DomainOnBlurValidation(e, "DomainFor")}
                    name="DomainFor"
                    placeholder="Enter Domain"
                    type="text"
                  />
                  <span className="texthelp">
                    Enter domain without "@", For e.g. google.com
                  </span>
                  <br />
                  <span style={{ color: "red" }} id="validation_error">
                    {Errors["DomainFor"]}
                  </span>
                </div>
              </div>
              <div className="col-lg-12 px-4 full-right border-top pt-3 mt-3">
                <button
                  disabled={BtnDisabled}
                  id="Dsubmit"
                  className="btn btn-primary btn-lightgreen mr-1"
                  onClick={BlackListDomainAdd}
                >
                  <i className="la la-save"></i> Save
                </button>
                <a
                  className="btn btn-secondary"
                  onClick={() => {
                    DomaintabFor();
                  }}
                >
                  <i className="la flaticon-cancel"></i> Cancel
                </a>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="row">
            <div className="col px-0">
              <DomainTable
                addDomainlistupdate={DomainChaild}
                updateFromChild={UpdateFromChild}
                Key={UpdateKey}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone bg-white px-2" index={1}>
          <div className="row">
            <div className="col border-bottom pl-0">
              <h4 className="headertitle float-left pl-0">Contact</h4>
            </div>
            <div className="col border-bottom">
              <div className="listing-li float-right padb-15 pr-1">
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        ContactDomainAddBtn();
                      }}
                      className="btn btngroup m-btn"
                    >
                      <i className="la la-icon-font-size-13 la-plus"></i>
                      <span>Add</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={ContactDomainImportBtn}
                      className="btn btngroup m-btn"
                    >
                      <i className="la la-icon-font-size-13 la-upload"></i>
                      <span>Import</span>
                    </a>
                  </li>
                  <li className="m-portlet__nav-item">
                    <a
                      onClick={ContactDomainBulkDleteBtn}
                      className="btn btngroup m-btn"
                    >
                      <span>
                        <i className="la flaticon-delete-1 la-icon-font-size-13"></i>
                        <span> Bulk Delete</span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={ContactDomainExport}
                      className="btn btngroup m-btn"
                    >
                      <i className="la la-icon-font-size-13 la-download"></i>
                      <span>
                        {" "}
                        <CSVDownloader
                          className="px-0"
                          data={ContactDomainExportData}
                          filename={CName + "-Saleshive_Page_Blacklist_Contacts"}
                          bom={true}
                        >
                          Export
                        </CSVDownloader>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Import Csv for */}
          {ImportBox ? (
            <div className="portletbody" id="import_csv_contact">
              <div className="row">
                <div className="col-xl-8 offset-xl-2">
                  <h3 className="uploadheadingtitle">Import Contact</h3>
                  <div className="uplodfilesbox">
                    <CSVReader
                      accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                      onDrop={HandleOnDrop}
                      onError={HandleOnError}
                      addRemoveButton
                      removeButtonColor="#659cef"
                      onRemoveFile={HandleOnRemoveFile}
                      isReset={Reset}
                    >
                      <span>Select .CSV file</span>
                      <p>Add contact data from csv file to import.</p>
                    </CSVReader>
                  </div>
                </div>
                <div className="col-xl-8 offset-xl-2 border-top pt-2 mt-4">
                  <div className="alignright">
                    <a
                      onClick={ContactDomainMapCsvBtn}
                      className="btn btn-primary btnColor"
                    >
                      <i className="la la-upload"></i> Upload
                    </a>
                    <a
                      onClick={ContactDomainCancleBtn}
                      className="btn btn-secondary"
                    >
                      <i className="la flaticon-cancel"></i> Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Mapped div */}
          {ImportMapBox ? (
            <div className="portletbody portletshadow" id="map_attribute_contact">
              <div className="row col-xs-12">
                <h4 className="headertitle float-left">Map attributes</h4>
              </div>
              <div className="col-xs-12">
                <div className="form-group m-form__group d-flex">
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                      >
                        <option selected="" value="ContactEmail">
                        Contact
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        id="ContactEmail"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                   
                        value={
                          Contact == null
                            ? DropboxData.findIndex((item) => item.trim().toLowerCase() === "contact")
                            : Contact
                        }
                        onChange={(e) => {
                          SetContact(e.target.value);
                        }}
                      >
                        <option value="not mapped">Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
                <div className="col-lg-12">
                  <button
                    id="CSVSaveBtn"
                    disabled = {DisabledFields}

                    onClick={()=>{ContactDomainSaveBtn();SetUpdateKey(UpdateKey + 1);}}
                    className="btn btn-primary btn-lightgreen"
                  >
                    <i className="la la-save"></i>Save
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Delete for csv */}
          {/* Bulk Csv for */}
          {DeleteBox ? (
            <div className="portletbody" id="contactdelete_csv">
              <div className="row">
                <div className="col-xl-8 offset-xl-2">
                  <h3 className="uploadheadingtitle">
                    Bulk deleted contact Domain
                  </h3>
                  <div className="uplodfilesbox">
                    <CSVReader
                      accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                      onDrop={HandleOnDrop}
                      onError={HandleOnError}
                      addRemoveButton
                      removeButtonColor="#659cef"
                      onRemoveFile={HandleOnRemoveFile}
                      isReset={Reset}
                    >
                      <span>Select .CSV file</span>
                      <p>Add contact data from csv file to bulk delete.</p>
                    </CSVReader>
                  </div>
                </div>
                <div className="col-xl-8 offset-xl-2 border-top pt-2 mt-4">
                  <div className="alignright">
                    <a
                      onClick={ContactDomainBulkDeleteBtn}
                      className="btn btn-primary btnColor"
                    >
                      <i className="la la-upload"></i> Upload
                    </a>
                    <a
                      onClick={ContactDomainBulkCancleBtn}
                      className="btn btn-secondary"
                    >
                      <i className="la flaticon-cancel"></i> Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Mapped div */}
          {DeleteMapBox ? (
            <div className="portletbody portletshadow" id="map_attributecontact">
              <div className="row col-xs-12">
                <h4 className="headertitle float-left">Map attributes</h4>
              </div>
              <div className="col-xs-12">
                <div className="form-group m-form__group d-flex">
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                      >
                        Contact
                        <option selected="" value="ContactEmail">
                        Contact
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        id="ContactEmail1"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                
                        value={
                          Contact == null
                            ? DropboxData.findIndex((item) => item.trim().toLowerCase() === "contact")
                            : Contact
                        }
                        onChange={(e) => {
                          SetContact(e.target.value);
                        }}
                      >
                        <option value="not mapped">Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
                <div className="col-lg-12">
                  <button
                    id="ContactCSVBtn"
                    onClick={()=>{ContactDomainBulkDeleteSaveBtn();SetUpdateKey(UpdateKey + 1);}}
                    className="btn btn-primary btn-lightgreen"
                  >
                    <i className="la la-save"></i>Delete
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Total Details for csv */}
          {CountBox ? (
            <div className="portletbody px-4" id="Show_csv_details_contact">
              <div className="row col-xs-12">
                <h4 className="headertitle float-left">Results</h4>
              </div>

              <div className="col-xs-12">
                <div className="row colfive">
                  <div className="col-md-3">
                    <div className="shadowcard boxcardcounter">
                      <div className="d-flex align-center">
                        <div className="contectboxes w-100">
                          <div className="line-title mb-2">
                            <span className="box-main-title font-primary">
                              {Total}
                            </span>
                          </div>
                          <div className="box-inner-title">
                            <string className="clr-sky">Total</string>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="shadowcard boxcardcounter">
                      <div className="d-flex align-center">
                        <div className="contectboxes w-100">
                          <div className="line-title mb-2">
                            <span className="box-main-title font-success">
                              {TotalSucess}
                            </span>
                          </div>
                          <div className="box-inner-title">
                            <string className="clr-sky">Total success</string>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="shadowcard boxcardcounter">
                      <div className="d-flex align-center">
                        <div className="contectboxes w-100">
                          <div className="line-title mb-2">
                            <span className="box-main-title font-danger">
                              {TotalError}
                            </span>
                          </div>
                          <div className="box-inner-title">
                            <string className="clr-sky">Total error</string>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="shadowcard boxcardcounter">
                      <div className="d-flex align-center">
                        <div className="contectboxes w-100">
                          <div className="line-title mb-2">
                            <span className="box-main-title font-warning">
                              {TotalDuplicate}
                            </span>
                          </div>
                          <div className="box-inner-title">
                            <string className="clr-sky">Total Duplicate</string>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="shadowcard boxcardcounter">
                      <div className="d-flex align-center">
                        <div className="contectboxes w-100">
                          <div className="line-title mb-2">
                            <span className="box-main-title font-warning">
                              {TotalInvalid}
                            </span>
                          </div>
                          <div className="box-inner-title">
                            <string className="clr-sky">Total Invalid</string>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {AddBox ? (
            <div className="addkeyword row my-4" id="addblacklistcontacview">
              <div className="col-sm-12 col-md-6 row px-0">
                <label className="col-lg-3 view-lable-meet">Contact</label>
                <div className="col-lg-7">
                  <input
                    className="form-control m-input"
                    data-val="true"
                    data-val-required="Please enter Contact name"
                    id="ContactEmailAdd"
                    onBlur={() => ContactTabOnBlur()}
                    name="ContactEmailAdd"
                    placeholder="Enter email"
                    type="text"
                  />

                  <span style={{ color: "red" }} id="validation_email">
                    {Errors["ContactEmailAdd"]}
                  </span>
                </div>
              </div>
              <div className="col-lg-12 px-4 full-right border-top pt-3 mt-3">
                <button
                  disabled={BtnDisabled1}
                  // id="Csubmit"
                  className="btn btn-primary btn-lightgreen mr-1"
                  onClick={()=>{ContactTabEmailBtn();SetUpdateKey(UpdateKey + 1);}}
                >
                  <i className="la la-save"></i> Save
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    HideContactEmail();
                  }}
                >
                  <i className="la flaticon-cancel"></i> Cancel
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="row">
            <div className="col px-0">
              <ContactTable
                addlistupdate={ContactChaild}
                updateFromChild={UpdateFromChild}
                Key={UpdateKey}
              />
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="tagpaddnone bg-white px-2" index={2}>
          <div className="row">
            <div className="col border-bottom pl-0">
              <h4 className="headertitle float-left pl-0">Removed</h4>
            </div>
            <div className="col border-bottom">
              <div className="listing-li float-right padb-15 pr-1">
                <ul>
                  <li>
                    <a onClick={RemovedAddBtn} className="btn btngroup m-btn">
                      <i className="la la-icon-font-size-13 la-plus"></i>
                      <span>Add</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={RemovedImportBtn}
                      className="btn btngroup m-btn"
                    >
                      <i className="la la-icon-font-size-13 la-upload"></i>
                      <span>Import</span>
                    </a>
                  </li>

                  <li className="m-portlet__nav-item">
                    <a
                      onClick={RemovedDeleteCsvBtn}
                      className="btn btngroup m-btn"
                    >
                      <span>
                        <i className="la flaticon-delete-1 la-icon-font-size-13"></i>
                        <span> Bulk Delete</span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={removedExportBtn}
                      className="btn btngroup m-btn"
                    >
                      <i className="la la-icon-font-size-13 la-download"></i>
                      <span>
                        {" "}
                        <CSVDownloader
                          className="px-0"
                          data={RemovedExportData}
                          filename={CName + "-Saleshive_Page_Blacklist_Removed"}
                          bom={true}
                        >
                          Export
                        </CSVDownloader>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Import Csv for */}
          {ImportBox ? (
            <div className="portletbody" id="import_csv_Removed">
              <div className="row">
                <div className="col-xl-8 offset-xl-2">
                  <h3 className="uploadheadingtitle">Import removed Domain</h3>
                  <div className="uplodfilesbox">
                    <CSVReader
                      accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                      onDrop={HandleOnDrop}
                      onError={HandleOnError}
                      addRemoveButton
                      removeButtonColor="#659cef"
                      onRemoveFile={HandleOnRemoveFile}
                      isReset={Reset}
                    >
                      <span>Select .CSV file</span>
                      <p>Add removed data from csv file to import.</p>
                    </CSVReader>
                  </div>
                </div>
                <div className="col-xl-8 offset-xl-2 border-top pt-2 mt-4">
                  <div className="alignright">
                    <a
                      onClick={RemovedImportMapCavBtn}
                      className="btn btn-primary btnColor"
                    >
                      <i className="la la-upload"></i> Upload
                    </a>
                    <a
                      onClick={RemovedCancleCsvBtn}
                      className="btn btn-secondary"
                    >
                      <i className="la flaticon-cancel"></i> Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Mapped div */}
          {ImportMapBox ? (
            <div className="portletbody portletshadow" id="map_attribute_removed1">
              <div className="row col-xs-12">
                <h4 className="headertitle float-left">Map attributes</h4>
              </div>
              <div className="col-xs-12">
                <div className="form-group m-form__group d-flex">
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                      >
                        <option selected="" value="Name">
                          Removed
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        id="Name"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
              
                        value={
                          Removed == null
                            ? DropboxData.findIndex((item) => item.trim().toLowerCase() === "removed")
                            : Removed
                        }
                        onChange={(e) => {
                          SetRemoved(e.target.value);
                        }}

                      >
                        <option value="not mapped">Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
                <div className="col-lg-12">
                  <button
                    id="RemovedcsvImport"
                    disabled = {DisabledFields}

                    onClick={()=>{RemovedSaveCsvBtn();SetUpdateKey(UpdateKey + 1);}}
                    className="btn btn-primary btn-lightgreen"
                  >
                    <i className="la la-save"></i>Save
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Total Details for csv */}
          {CountBox ? (
            <div className="portletbody px-4" id="Show_csv_details_removed">
              <div className="row col-xs-12">
                <h4 className="headertitle float-left">Results</h4>
              </div>

              <div className="col-xs-12">
                <div className="row colfive">
                  <div className="col-md-3">
                    <div className="shadowcard boxcardcounter">
                      <div className="d-flex align-center">
                        <div className="contectboxes w-100">
                          <div className="line-title mb-2">
                            <span className="box-main-title font-primary">
                              {RemovedTotal}
                            </span>
                          </div>
                          <div className="box-inner-title">
                            <string className="clr-sky">Total</string>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="shadowcard boxcardcounter">
                      <div className="d-flex align-center">
                        <div className="contectboxes w-100">
                          <div className="line-title mb-2">
                            <span className="box-main-title font-success">
                              {RemovedTotalSucess}
                            </span>
                          </div>
                          <div className="box-inner-title">
                            <string className="clr-sky">Total success</string>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="shadowcard boxcardcounter">
                      <div className="d-flex align-center">
                        <div className="contectboxes w-100">
                          <div className="line-title mb-2">
                            <span className="box-main-title font-danger">
                              {RemovedTotalError}
                            </span>
                          </div>
                          <div className="box-inner-title">
                            <string className="clr-sky">Total error</string>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="shadowcard boxcardcounter">
                      <div className="d-flex align-center">
                        <div className="contectboxes w-100">
                          <div className="line-title mb-2">
                            <span className="box-main-title font-warning">
                              {RemovedTotalDuplicate}
                            </span>
                          </div>
                          <div className="box-inner-title">
                            <string className="clr-sky">Total Duplicate</string>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="shadowcard boxcardcounter">
                      <div className="d-flex align-center">
                        <div className="contectboxes w-100">
                          <div className="line-title mb-2">
                            <span className="box-main-title font-warning">
                              {TotalInvalid}
                            </span>
                          </div>
                          <div className="box-inner-title">
                            <string className="clr-sky">Total Invalid</string>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Delete for csv */}
          {/* Bulk Csv for */}
          {DeleteBox ? (
            <div className="portletbody" id="delete_csv_removed">
              <div className="row">
                <div className="col-xl-8 offset-xl-2">
                  <h3 className="uploadheadingtitle">Bulk deleted removed</h3>
                  <div className="uplodfilesbox">
                    <CSVReader
                      accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                      onDrop={HandleOnDrop}
                      onError={HandleOnError}
                      addRemoveButton
                      removeButtonColor="#659cef"
                      onRemoveFile={HandleOnRemoveFile}
                      isReset={Reset}
                    >
                      <span>Select .CSV file</span>
                      <p>Add removed data from csv file to bulk delete.</p>
                    </CSVReader>
                  </div>
                </div>
                <div className="col-xl-8 offset-xl-2 border-top pt-2 mt-4">
                  <div className="alignright">
                    <a
                      onClick={RemovedBulkDeleteMapCsv}
                      className="btn btn-primary btnColor"
                    >
                      <i className="la la-upload"></i> Upload
                    </a>
                    <a
                      onClick={RemovedBulkDeleteCancelCsv}
                      className="btn btn-secondary"
                    >
                      <i className="la flaticon-cancel"></i> Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Mapped div */}
          {DeleteMapBox ? (
            <div className="portletbody portletshadow" id="map_attribute_removed">
              <div className="row col-xs-12">
                <h4 className="headertitle float-left">Map attributes</h4>
              </div>
              <div className="col-xs-12">
                <div className="form-group m-form__group d-flex">
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                      >
                        Removed
                        <option selected="" value="Name">
                        Removed
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        id="Name1"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        value={
                          Removed == null
                            ? DropboxData.findIndex((item) => item.trim().toLowerCase() === "removed")
                            : Removed
                        }
                        onChange={(e) => {
                          SetRemoved(e.target.value);
                        }}
                      >
                        <option value="not mapped">Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
                <div className="col-lg-12">
                  <a
                    onClick={()=>{RemovedBulkDeleteCsvSaveBtn();SetUpdateKey(UpdateKey + 1);}}
                    className="btn btn-primary btn-lightgreen"
                  >
                    <i className="la la-save"></i>Delete
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {AddBox ? (
            <div className="addkeyword row my-4" id="removedview">
              <div className="col-sm-12 col-md-6 row px-0">
                <label className="col-lg-3 view-lable-meet">Remove</label>
                <div className="col-lg-9">
                  <input
                    className="form-control m-input"
                    data-val="true"
                    data-val-required="Please enter Email "
                    id="RemovedEmail"
                    onBlur={() => RemovedOnBlur()}
                    name="RemovedEmail"
                    placeholder="Enter email"
                    type="text"
                  />
                  <span style={{ color: "red" }}>{Errors["RemovedEmail"]}</span>
                </div>
              </div>
              <div className="col-lg-12 px-4 full-right border-top pt-3 mt-3">
                <button
                  disabled={BtnDisabled2}
                  // id="Rsubmit"
                  className="btn btn-primary btn-lightgreen mr-1"
                  onClick={()=>{RemovedEmailSaveBtn();SetUpdateKey(UpdateKey + 1);}}
                >
                  <i className="la la-save"></i> Save
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    RemovedHideBtn();
                  }}
                >
                  <i className="la flaticon-cancel"></i> Cancel
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="row">
            <div className="col px-0">
              <RemovedTable
                addRemovedListed={RemovedChaild}
                updateFromChild={UpdateFromChild}
                key = {UpdateKey}
              />
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="tagpaddnone bg-white px-2" index={3}>
          <div className="row">
            <div className="col border-bottom  pl-0">
              <h4 className="headertitle float-left  pl-0">Whitelist</h4>
            </div>
            <div className="col border-bottom">
              <div className="listing-li float-right padb-15 pr-1">
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        WhiteListShowBtn();
                      }}
                      className="btn btngroup m-btn"
                    >
                      <i className="la la-icon-font-size-13 la-plus"></i>
                      <span>Add</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={WhiteListImportBtn}
                      className="btn btngroup m-btn"
                    >
                      <i className="la la-icon-font-size-13 la-upload"></i>
                      <span>Import</span>
                    </a>
                  </li>
                  <li className="m-portlet__nav-item">
                    <a
                      onClick={WhiteListDeleteBtn}
                      className="btn btngroup m-btn"
                    >
                      <span>
                        <i className="la flaticon-delete-1 la-icon-font-size-13"></i>
                        <span> Bulk Delete</span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={WhiteListExportCsvBtn}
                      className="btn btngroup m-btn"
                    >
                      <i className="la la-icon-font-size-13 la-download"></i>
                      <span>
                        {" "}
                        <CSVDownloader
                          className="px-0"
                          data={WhiteListExportData}
                          filename={CName + "-Saleshive_Page_Blacklist_Whitelist"}
                          bom={true}
                        >
                          Export
                        </CSVDownloader>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Import Csv for */}
          {ImportBox ? (
            <div className="portletbody" id="import_csv_whitelist">
              <div className="row">
                <div className="col-xl-8 offset-xl-2">
                  <h3 className="uploadheadingtitle">Import Whitelist</h3>
                  <div className="uplodfilesbox">
                    <CSVReader
                      accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                      onDrop={HandleOnDrop}
                      onError={HandleOnError}
                      addRemoveButton
                      removeButtonColor="#659cef"
                      onRemoveFile={HandleOnRemoveFile}
                      isReset={Reset}
                    >
                      <span>Select .CSV file</span>
                      <p>Add whitelist data from csv file to import.</p>
                    </CSVReader>
                  </div>
                </div>
                <div className="col-xl-8 offset-xl-2 border-top pt-2 mt-4">
                  <div className="alignright">
                    <a
                      onClick={WhiteListImportMapCsv}
                      className="btn btn-primary btnColor"
                    >
                      <i className="la la-upload"></i> Upload
                    </a>
                    <a
                      onClick={WhiteListCancelCsv}
                      className="btn btn-secondary"
                    >
                      <i className="la flaticon-cancel"></i> Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Mapped div */}
          {ImportMapBox ? (
            <div className="portletbody portletshadow" id="map_attribute_whitelist">
              <div className="row col-xs-12">
                <h4 className="headertitle float-left">Map attributes</h4>
              </div>
              <div className="col-xs-12">
                <div className="form-group m-form__group d-flex">
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                      >
                        <option selected="" value="Whitelist">
                        Whitelist
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        id="Whitelist"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled = {DisabledFields}
                        value={
                          Whitelist == null
                            ? DropboxData.findIndex((item) => item.trim().toLowerCase() === "whitelist")
                            : Whitelist
                        }
                       
                        onChange={(e) => {
                          SetWhitelist(e.target.value);
                        }}

                      >
                        <option value="not mapped">Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
                <div className="col-lg-12">
                  <button
                    id="WhiteCSVImport"
                    disabled = {DisabledFields}

                    onClick={()=>{WhiteListSaveCsvBtn();SetUpdateKey(UpdateKey + 1);}}
                    className="btn btn-primary btn-lightgreen"
                  >
                    <i className="la la-save"></i>Save
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Total Details for csv */}
          {CountBox ? (
            <div className="portletbody px-4" id="Show_csv_details_whitelist">
              <div className="row col-xs-12">
                <h4 className="headertitle float-left">Results</h4>
              </div>

              <div className="col-xs-12">
                <div className="row colfive">
                  <div className="col-md-3">
                    <div className="shadowcard boxcardcounter">
                      <div className="d-flex align-center">
                        <div className="contectboxes w-100">
                          <div className="line-title mb-2">
                            <span className="box-main-title font-primary">
                              {TotalWhiteList}
                            </span>
                          </div>
                          <div className="box-inner-title">
                            <string className="clr-sky">Total</string>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="shadowcard boxcardcounter">
                      <div className="d-flex align-center">
                        <div className="contectboxes w-100">
                          <div className="line-title mb-2">
                            <span className="box-main-title font-success">
                              {TotalWhiteListSucess}
                            </span>
                          </div>
                          <div className="box-inner-title">
                            <string className="clr-sky">Total success</string>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="shadowcard boxcardcounter">
                      <div className="d-flex align-center">
                        <div className="contectboxes w-100">
                          <div className="line-title mb-2">
                            <span className="box-main-title font-danger">
                              {TotalWhiteListError}
                            </span>
                          </div>
                          <div className="box-inner-title">
                            <string className="clr-sky">Total error</string>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="shadowcard boxcardcounter">
                      <div className="d-flex align-center">
                        <div className="contectboxes w-100">
                          <div className="line-title mb-2">
                            <span className="box-main-title font-warning">
                              {TotalWhiteListDuplicate}
                            </span>
                          </div>
                          <div className="box-inner-title">
                            <string className="clr-sky">Total Duplicate</string>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="shadowcard boxcardcounter">
                      <div className="d-flex align-center">
                        <div className="contectboxes w-100">
                          <div className="line-title mb-2">
                            <span className="box-main-title font-warning">
                              {TotalInvalid}
                            </span>
                          </div>
                          <div className="box-inner-title">
                            <string className="clr-sky">Total Invalid</string>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Delete for csv */}
          {/* Bulk Csv for */}
          {DeleteBox ? (
            <div className="portletbody" id="delete_csv_whitelist">
              <div className="row">
                <div className="col-xl-8 offset-xl-2">
                  <h3 className="uploadheadingtitle">
                    Bulk deleted whitelist Domain
                  </h3>
                  <div className="uplodfilesbox">
                    <CSVReader
                      accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                      onDrop={HandleOnDrop}
                      onError={HandleOnError}
                      addRemoveButton
                      removeButtonColor="#659cef"
                      onRemoveFile={HandleOnRemoveFile}
                      isReset={Reset}
                    >
                      <span>Select .CSV file</span>
                      <p>Add Whitelist data from csv file to bulk delete.</p>
                    </CSVReader>
                  </div>
                </div>
                <div className="col-xl-8 offset-xl-2 border-top pt-2 mt-4 border-top pt-2 mt-4 border-top pt-2 mt-4">
                  <div className="alignright">
                    <a
                      onClick={WhiteListBDeleteMapCsv}
                      className="btn btn-primary btnColor"
                    >
                      <i className="la la-upload"></i> Upload
                    </a>
                    <a
                      onClick={WhiteListBDeleteCancelCsv}
                      className="btn btn-secondary"
                    >
                      <i className="la flaticon-cancel"></i> Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Mapped div */}
          {DeleteMapBox ? (
            <div className="portletbody portletshadow" id="map_attribute_whitelistdelete">
              <div className="row col-xs-12">
                <h4 className="headertitle float-left">Map attributes</h4>
              </div>
              <div className="col-xs-12">
                <div className="form-group m-form__group d-flex">
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                      >
                        <option selected="" value="WhitelistName2">
                        Whitelist
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        id="WhitelistName1"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        value={
                          Whitelist == null
                            ? DropboxData.findIndex((item) => item.trim().toLowerCase() === "whitelist")
                            : Whitelist
                        }
                        onChange={(e) => {
                          SetWhitelist(e.target.value);
                        }}
                      >
                        <option value="not mapped">Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
                <div className="col-lg-12">
                  <a
                    onClick={()=>{WhiteListBulkDeleteBtn();SetUpdateKey(UpdateKey + 1);}}
                    className="btn btn-primary btn-lightgreen"
                  >
                    <i className="la la-save"></i>Delete
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {AddBox ? (
            <div className="addkeyword row my-4" id="whitelistcontacview">
              <div className="col-sm-12 col-md-6 row px-0">
                <label className="col-lg-3 view-lable-meet">WhiteList</label>
                <div className="col-lg-9">
                  <input
                    className="form-control m-input"
                    data-val="true"
                    data-val-required="Please enter Contact name"
                    id="ContactEmailWhitelist"
                    onBlur={() => WhitelistEmailOnBlur()}
                    name="Contact"
                    placeholder="Enter email"
                    type="text"
                  />
                  <span style={{ color: "red" }} id="validation_email">
                    {Errors["ContactEmailWhitelist"]}
                  </span>
                </div>
              </div>
              <div className="col-lg-12 px-4 full-right border-top pt-3 mt-3">
                <button
                  disabled={BtnDisabled3}
                  className="btn btn-primary btn-lightgreen mr-1"
                  onClick={()=>{WhiteListAddBtn();SetUpdateKey(UpdateKey + 1);}}
                >
                  <i className="la la-save"></i> Save
                </button>
                <a
                  className="btn btn-secondary"
                  onClick={() => {
                    CloseBtnForWhieBtn();
                  }}
                >
                  <i className="la flaticon-cancel"></i> Cancel
                </a>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="row">
            <div className="col px-0">
              <WhitelistTable
                addWhiteListed={WhiteListChaild}
                updateFromChild={UpdateFromChild}
                key={UpdateKey}
              />
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="tagpaddnone bg-white px-2" index={4}>
          <div className="row px-2">
            <div className="col border-bottom">
              <h4 className="headertitle float-left  pl-0">Phone Number</h4>
            </div>
            <div className="col border-bottom">
              <div className="listing-li float-right padb-15 pr-1">
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        WhiteListShowBtn();
                      }}
                      className="btn btngroup m-btn"
                    >
                      <i className="la la-icon-font-size-13 la-plus"></i>
                      <span>Add</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={OpenImportPhoneNumberDiv}
                      className="btn btngroup m-btn"
                    >
                      <i className="la la-icon-font-size-13 la-upload"></i>
                      <span>Import</span>
                    </a>
                  </li>
                  <li className="m-portlet__nav-item">
                    <a
                      onClick={OpenBulkDeleteModel}
                      className="btn btngroup m-btn"
                    >
                      <span>
                        <i className="la flaticon-delete-1 la-icon-font-size-13"></i>
                        <span> Bulk Delete</span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a onClick={() => ExportBacklinks(ClientID)} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                      <i class="la la-icon-font-size-13 la-download"></i>Export
                    </a>

                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Import Csv for */}
          {OpenImportPhoneNumber ? (
            <div className="portletbody" id="import_csv_whitelist">
              <div className="row">
                <div className="col-xl-8 offset-xl-2">
                  <h3 className="uploadheadingtitle">Import Phone Number</h3>
                  <div className="uplodfilesbox">
                    <CSVReader
                      accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                      onDrop={HandleOnDrop}
                      onError={HandleOnError}
                      addRemoveButton
                      removeButtonColor="#659cef"
                      onRemoveFile={HandleOnRemoveFile}
                      isReset={Reset}
                    >
                      <span>Select .CSV file</span>
                      <p>Add phone number data from csv file to import.</p>
                    </CSVReader>
                  </div>
                </div>
                <div className="col-xl-8 offset-xl-2 border-top pt-2 mt-4">
                  <div className="alignright">
                    <a
                      onClick={UploadCSVInPhoneNumber}
                      className="btn btn-primary btnColor"
                    >
                      <i className="la la-upload"></i> Upload
                    </a>
                    <a
                      onClick={CancelToUploadPhoneNumberCSV}
                      className="btn btn-secondary"
                    >
                      <i className="la flaticon-cancel"></i> Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Mapped div */}
          {ImportPhoneNumberMapBox ? (
            <div className="portletbody portletshadow" id="map_attribute_whitelist">
              <div className="row col-xs-12">
                <h4 className="headertitle float-left">Map attributes</h4>
              </div>
              <div className="col-xs-12">
                <div className="form-group m-form__group d-flex">
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                      >
                        <option selected="" value="Whitelist">
                          Phone Number
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-2 nopadding">
                    <div>
                    <select
                        id="PhoneNumber"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={DisabledFields}
                        value={
                          PhoneNumberList == null
                            ? DropboxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "phone number"
                            )
                            : PhoneNumberList
                        }
                        onChange={(e) => {
                          SetPhoneNumberList(e.target.value);
                        }}

                      >
                        <option value="not mapped">Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
                <div className="col-lg-12">
                  <button
                    id="WhiteCSVImport"
                    disabled={DisabledFields}
                    onClick={SavePhoneNumberCSVDetails}
                    // onClick={() => { WhiteListSaveCsvBtn(); SetUpdateKey(UpdateKey + 1); }}
                    className="btn btn-primary btn-lightgreen"
                  >
                    <i className="la la-save"></i>Save
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Total Details for csv */}
          {DisplayPhoneCount ? (
            <CountsDisplay Total={PhoneNumberImportCount?.Total} Success={PhoneNumberImportCount?.Success} Duplicate={PhoneNumberImportCount?.Duplicate} Error={PhoneNumberImportCount?.Error} Invalid={PhoneNumberImportCount?.Invalid}/>
          ) : (
            <></>
          )}

          {/* Delete for csv */}
          {/* Bulk Csv for */}
          {OpenBulkDeletePhone ? (
            <div className="portletbody" id="delete_csv_whitelist">
              <div className="row">
                <div className="col-xl-8 offset-xl-2">
                  <h3 className="uploadheadingtitle">
                  Bulk delete phone number
                  </h3>
                  <div className="uplodfilesbox">
                    <CSVReader
                      accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                      onDrop={HandleOnDrop}
                      onError={HandleOnError}
                      addRemoveButton
                      removeButtonColor="#659cef"
                      onRemoveFile={HandleOnRemoveFile}
                      isReset={Reset}
                    >
                      <span>Select .CSV file</span>
                      <p>Add phone number data from csv file to bulk delete.</p>
                    </CSVReader>
                  </div>
                </div>
                <div className="col-xl-8 offset-xl-2 border-top pt-2 mt-4 border-top pt-2 mt-4 border-top pt-2 mt-4">
                  <div className="alignright">
                    <a
                      onClick={MappingBulkDeletePhone}
                      className="btn btn-primary btnColor"
                    >
                      <i className="la la-upload"></i> Upload
                    </a>
                    <a
                      onClick={CloseBulkDeleteModel}
                      className="btn btn-secondary"
                    >
                      <i className="la flaticon-cancel"></i> Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Mapped div */}
          {BulkPhoneNumberMapBox ? (
            <div className="portletbody portletshadow" id="map_attribute_whitelistdelete">
              <div className="row col-xs-12">
                <h4 className="headertitle float-left">Map attributes</h4>
              </div>
              <div className="col-xs-12">
                <div className="form-group m-form__group d-flex">
                  <div className="col-lg-2 nopadding">
                    <div>
                      
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                      >
                        <option selected="" value="WhitelistName2">
                          Phone Number
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-2 nopadding">
                    <div>
                    <select
                        id="WhitelistName1"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        value={
                          PhoneNumberList == null
                            ? DropboxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "phone number"
                            )
                            : PhoneNumberList
                        }
                        onChange={(e) => {
                          SetPhoneNumberList(e.target.value);
                        }}
                      >
                        <option value="not mapped">Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
                <div className="col-lg-12">
                  <a
                    // onClick={() => { WhiteListBulkDeleteBtn(); SetUpdateKey(UpdateKey + 1); }}
                    className="btn btn-primary btn-lightgreen"
                    onClick={BulkDeletingPhoneNumber}
                  >
                    <i className="la la-save"></i>Delete
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {AddBox ? (
            <div className="addkeyword row my-4" id="whitelistcontacview">
              <div className="col-sm-12 col-md-6 row px-0">
                <label className="col-lg-3 view-lable-meet">Phone Number</label>
                <div className="col-lg-9">
                  <input
                    className="form-control m-input"
                    data-val="true"
                    data-val-required="Please enter Phone number"
                    id="phonenumber"
                    onBlur={() => PhoneNumberOnBlur()}
                    name="PhoneNumber"
                    placeholder="Enter phone number"
                    type="text"
                  />
                  <span style={{ color: "red" }} id="validation_email">
                    {Errors["PhoneNumberlist"]}
                  </span>
                </div>
              </div>
              <div className="col-lg-12 px-4 full-right border-top pt-3 mt-3">
                <button
                  disabled={phonenumberBtn}
                  className="btn btn-primary btn-lightgreen mr-1"
                  onClick={() => { PhoneNumberAdd(); SetUpdateKey(UpdateKey + 1); }}
                >
                  <i className="la la-save"></i> Save
                </button>
                <a
                  className="btn btn-secondary"
                  onClick={() => {
                    PhoneNumberClose();
                  }}
                >
                  <i className="la flaticon-cancel"></i> Cancel
                </a>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="row">
            <div className="col px-0">
              <PhoneNumberTable
                addWhiteListed={PhoneNumberChaild}
                updateFromChild={UpdateFromChild}
                key={UpdateKey}
              />
            </div>
          </div>

        </TabPanel>
      </div>
    </div>
  );
}

const CountsDisplay = ({Total,Success,Duplicate,Error,Invalid}) => {
  return(
    <div className="portletbody px-4" id="Show_csv_details_whitelist">
    <div className="row col-xs-12">
      <h4 className="headertitle float-left">Results</h4>
    </div>

    <div className="col-xs-12">
      <div className="row colfive">
        <div className="col-md-3">
          <div className="shadowcard boxcardcounter">
            <div className="d-flex align-center">
              <div className="contectboxes w-100">
                <div className="line-title mb-2">
                  <span className="box-main-title font-primary">
                    {Total}
                  </span>
                </div>
                <div className="box-inner-title">
                  <string className="clr-sky">Total</string>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="shadowcard boxcardcounter">
            <div className="d-flex align-center">
              <div className="contectboxes w-100">
                <div className="line-title mb-2">
                  <span className="box-main-title font-success">
                    {Success}
                  </span>
                </div>
                <div className="box-inner-title">
                  <string className="clr-sky">Total success</string>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="shadowcard boxcardcounter">
            <div className="d-flex align-center">
              <div className="contectboxes w-100">
                <div className="line-title mb-2">
                  <span className="box-main-title font-danger">
                    {Error}
                  </span>
                </div>
                <div className="box-inner-title">
                  <string className="clr-sky">Total error</string>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="shadowcard boxcardcounter">
            <div className="d-flex align-center">
              <div className="contectboxes w-100">
                <div className="line-title mb-2">
                  <span className="box-main-title font-warning">
                    {Duplicate}
                  </span>
                </div>
                <div className="box-inner-title">
                  <string className="clr-sky">Total Duplicate</string>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="shadowcard boxcardcounter">
            <div className="d-flex align-center">
              <div className="contectboxes w-100">
                <div className="line-title mb-2">
                  <span className="box-main-title font-warning">
                    {Invalid}
                  </span>
                </div>
                <div className="box-inner-title">
                  <string className="clr-sky">Total Invalid</string>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}