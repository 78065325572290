import React, { useEffect } from "react";
import Axios from "axios";
import Popup from "reactjs-popup";
import Swal from "sweetalert2";
const moment = require("moment");

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

//import ContactList from "./ContactList";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import StyleHeader from "../StickyHeader/StickyHeader";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function Emodprofile() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Profile");
  const [sortedColumn, setSortedColumn] = React.useState("Profile");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    var GetUserData = GetUserDetails();
    document.title = `eMod Profile | SalesHive`;
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    eModListGet(GetUserData.ParentUserID, GetUserData.Role);
  }, [Search, Page, RowsPerPage]);

  //get eMod list
  const eModListGet = (UID, Role) => {
    SetIsLoading(true);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      UserID: UID,
      Role: Role,
    };
    const listDatas = Axios({
      url: CommonConstants.MOL_APIURL + "/eMod/eModGet",
      method: "POST",
      data: InputParameter,
    });
    listDatas.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false);
      setIsDataLoaded(true);

    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  //delete new code
  const DeleteBtn = async (id, Name) => {
    SetIsLoading(true);
    var InputParameters = {
      ID: id,
      UserID: UserID,
      Field: SortField,
      SortBy: SortedBy,
    };
    const eModCampaign = await Axios({
      url: CommonConstants.MOL_APIURL + "/eMod/eModCheckCampaign",
      method: "POST",
      data: InputParameters,
    });

    if (
      eModCampaign.data.DataCampaign.length > 0 ||
      eModCampaign.data.DataCampaigntemplete.length > 0
    ) {
      SetIsLoading(false);
      var InputParameters = {
        ID: id,
        UserID: UserID,
        Field: SortField,
        SortBy: SortedBy,
      };
      const eModStatus = await Axios({
        url: CommonConstants.MOL_APIURL + "/eMod/eModDeleteDropDown",
        method: "POST",
        data: InputParameters,
      });

      let Datas = eModStatus.data.Data.filter((obj) => obj._id !== id);
      var eModDropDown = [];
      Datas?.map((val) => {
        eModDropDown[val._id] = `${val.Profile}`;
      });

      Swal.fire({
        title: "Replace Profile with new one for linked campaigns",
        text: "Profile",
        confirmButtonColor: "#360947",
        cancelButtonColor: "#34bfa3",
        confirmButtonText: "Save",
        cancelButtonText: "Cancel",
        position: "top",
        reverseButtons: true,
        input: "select",
        inputOptions: eModDropDown,
        inputPlaceholder: eModDropDown[0],
        showCancelButton: true,
        showCloseButton: true,
        customClass: {
          title: "titelleft",
          popup: "container-500",
        },
      }).then((Success) => {
        if (Success.isConfirmed) {
          var UpdateId = Success.value;
          SetIsLoading(true);
          var UpdatingParameters = {
            id: id,
            NeweModID: UpdateId,
            LastUpdatedBy: CUserID,
            LastUpdatedDt: new Date(),
            Role: Role,
          };
          Axios({
            url: CommonConstants.MOL_APIURL + "/eMod/eModDeleteUpdate",
            method: "POST",
            data: UpdatingParameters,
          }).then(async (res) => {
            if (res) {
              SetIsLoading(false);
              if ((res.statusText = "Ok")) {
                Swal.fire("Deleted!", "eMod deleted successfully.", "success");
                eModListGet(UserID, Role);
                SetPage(1);
                SetSearch("");
                document.getElementById("search").value = "";
              } else {
              }
            }
          });
        } else if (Success.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      SetIsLoading(false);
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete a profile.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var data = {
            id: id,
            LastUpdatedBy: CUserID,
            LastUpdatedDate: new Date(),
            Role: Role,
          };
          Axios({
            url: CommonConstants.MOL_APIURL + "/eMod/eModDelete",
            method: "POST",
            data: data,
          }).then((res) => {
            if (res) {
              if ((res.statusText = "Ok")) {
                Swal.fire("Deleted!", "eMod deleted successfully.", "success");
                eModListGet(UserID, Role);
                SetPage(1);
                SetSearch("");
                document.getElementById("search").value = "";
              } else {
              }
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    }
  };

  //edit page
  const EditBtn = (ID) => {
    history.push("/editemodprofile", ID);
  };

  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true);
    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Search: SearchedVal,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        UserID: UserID,
        Role: Role,
      };
      const eModListGet = Axios({
        url: CommonConstants.MOL_APIURL + "/eMod/eModGet",
        method: "POST",
        data: InputParameter,
      });
      eModListGet.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        UserID: UserID,
        Role: Role,
      };

      const eModListGet = Axios({
        url: CommonConstants.MOL_APIURL + "/eMod/eModGet",
        method: "POST",
        data: InputParameter,
      });
      eModListGet.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      });
    }
  };

  return (
    <div>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}

      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="bg-white p-3">
      <div className="row pt-2">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              Type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered white-space-none">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
        <Table className="table-ref"  aria-label="collapsible table">          
            <TableHead>
              <TableRow>
                <TableCell
                  onClick={() => {
                    SortData("Profile");
                    setSortedColumn("Profile");
                  }}
                >
                  <b>Profile</b>
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "Profile"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "Profile"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("SubjectPrompt");
                    setSortedColumn("SubjectPrompt");
                  }}
                >
                  <b>Subject Prompt</b>
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "SubjectPrompt"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "SubjectPrompt"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("Prompt");
                    setSortedColumn("Prompt");
                  }}
                >
                  <b>Prompt</b>
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "Prompt"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "Prompt"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("CreatedDate");
                    setSortedColumn("CreatedDate");
                  }}
                >
                  <b>Created Date</b>
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "CreatedDate"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "CreatedDate"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell>
                  <b>Action</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow>
                    <TableCell>{row?.Profile}</TableCell>
                    <TableCell>{row?.SubjectPrompt}</TableCell>
                    <TableCell>{row?.Prompt}</TableCell>
                    <TableCell>
                      {moment(new Date(row?.CreatedDate).toDateString()).format(
                        "MM/DD/YYYY"
                      )}
                    </TableCell>
                    <TableCell>
                      <div className="d-flex">
                        <a
                          onClick={() => {
                            EditBtn(row._id);
                          }}
                          className="btn-eyesicon"
                          title="Edit"
                        >
                          <i class="la flaticon-edit-1 edit-icon"></i>
                        </a>
                        <a
                          onClick={() => {
                            DeleteBtn(row._id, row.Profile);
                          }}
                        >
                          <i class="la flaticon-delete-1 delete-icon"></i>
                        </a>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
      </div>
    </div>
  );
}
