import React from "react";
import Axios from "axios";
import DatePicker from "react-datepicker";
const moment = require("moment");

import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";

toast.configure();

class CAddMeetingGoalPage extends React.Component {
  constructor(props) {
    let Details = GetUserDetails();
    super(props);

    this.state = {
      SelectDate: "",
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      Role: Details.Role,
      UserIDby: Details.ChildUserID,
      Fields: {},
      Errors: {},
      IsButtonDisabled:false,
      WeekStatus:false,
      GoalStatus:false,
      IsLoading: false,
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.SelectedDate = this.SelectedDate.bind(this);
  }
  componentDidMount() {
    document.title = `Add Meeting Goal | SalesHive`;
    // document.getElementById("date").style.display = "none";
    // document.getElementById("goal_err").style.display = "none";
  }

  handleDateOnChange = async () => {
    let Errors = { ...this.state.Errors };
    if (this.state.SelectDate === "") {
      Errors['Week'] = "Please select a week";
      this.setState({ WeekStatus: false });
    } else {
      let IsExist = await this.CheckExistGoalName(this.state.SelectDate);
      if (IsExist) {
        Errors['Week'] = "Week already exists"; // Set a message for week existence
        this.setState({ WeekStatus: false });
      } else {
        this.setState({ WeekStatus: true });
        Errors['Week'] = ""; // Clear the error message
      }
    }
    this.setState({ Errors: Errors });
  }

  handleDateBlur = async () => {
    let Errors = { ...this.state.Errors };
    if (this.state.SelectDate === "") {
      Errors['Week'] = "Please select a week";
      this.setState({ WeekStatus: false });
    } else {
      let IsExist = await this.CheckExistGoalName(this.state.SelectDate);
      if (IsExist) {
        Errors['Week'] = "Week already exists"; // Set a message for week existence
        this.setState({ WeekStatus: false });
      } else {
        this.setState({ WeekStatus: true });
        Errors['Week'] = ""; // Clear the error message
      }
    }
    this.setState({ Errors: Errors });
  };

  HandleChangeBlur = () => {
    let Goal = document.getElementById("Goal").value.trim();
    let Errors = { ...this.state.Errors };
    if (Goal === "") {
      Errors["Goal"] = "Please enter a goal";
      this.setState({ GoalStatus: false });
    } else {
      // Replace this logic with your desired validation criteria
      // Example: checking if it's a valid number
      if (isNaN(Goal) || parseInt(Goal) < 0) {
        Errors["Goal"] = "Please enter a valid positive number";
        this.setState({ GoalStatus: false });
      } else {
        this.setState({ GoalStatus: true });
        Errors["Goal"] = "";
      }
    }
    this.setState({ Errors: Errors });
  };

  // back button
  BackBtn() {
    history.push("/CMeetinggoal");
  }

  // form validation
  async FromValidation() {
    document.getElementById("date").style.display = "none";
    let FormIsValid = true;
    let Errors = {};
    let Goal = document.getElementById("Goal").value.trim();
    let IsExist = await this.CheckExistGoalName(this.state.SelectDate)
    if (Goal == "") {
      FormIsValid = false;
      Errors["Goal"] = "Please Enter Goal";
    }
    if (this.state.SelectDate == "") {
      FormIsValid = false;
      document.getElementById("date").style.display = "block";
    }
    if(IsExist == true){
      FormIsValid = false;
    }

    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  //check exist name
  async CheckExistGoalName(WeekData) {
    this.setState({IsLoading:true})
    let WeekDates=moment(new Date(WeekData)).format("MM/DD/YYYY");
    const dateFromWeekDates = moment(WeekDates);
    const formattedWeekDates = dateFromWeekDates.format('YYYY-MM-DD');
    let RequestBody = {
      ClientID: this.state.ClientID,
      Week: formattedWeekDates,
      Role: this.state.Role,
    };
    let ApiResponses = await Axios({
      url: CommonConstants.MOL_APIURL +"/meetinggoal/CheckExistWeek",
      method: "POST",
      data: RequestBody,
    })
  
      if (ApiResponses.data.StatusMessage == "SUCCESS") {
        if (ApiResponses.data.Data.length > 0) {
          let Errors = {...this.state.Errors};
          this.setState({ SelectDate: "" });
          toast.error(<div className="toastsize">Add Meeting Goal<br/>Goal already exist in selected week,select other week.</div>);
          Errors['Week'] ="";
          this.setState({Errors:Errors,WeekStatus:false})
          this.setState({IsLoading:false})
          return true;
        } else {
          this.setState({IsLoading:false})
          return false;
        }
      }else{
        this.setState({IsLoading:false})
      }
   
  }

  // meeting goal save
  async SaveBtn(e) {
    let todayweekday = document.getElementById("Week").value
    this.setState({IsButtonDisabled:true});
    // let Final_flag = await this.FromValidation();
    // if (Final_flag == true) {
      if (this.state.WeekStatus === true && this.state.GoalStatus === true && todayweekday?.trim() !=""){
      let Goal = document.getElementById("Goal").value.trim();
      let data = {
        Goal: Goal,
        Week: todayweekday,
        type: "User",
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
        CreatedBy: this.state.UserIDby,
        CreatedDt: new Date(),
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/meetinggoal/MeetingGoalAdd",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div>Add meeting goal<br/>
          Meeting goal added successfully.</div>);
          history.push("/CMeetinggoal");
        } else {
          toast.error(res.data.Message);
          this.setState({IsButtonDisabled:false});
        }
      });
    } else {
      let Errors = {...this.state.Errors};
        if(this.state.WeekStatus === false){
          Errors['Week'] = "Please select a week";
        }
        if(this.state.GoalStatus === false){
          Errors["Goal"] = "Please enter a goal";

        }
     this.setState({Errors:Errors})
    this.setState({IsButtonDisabled:false});
    }
  }

  // select date
  SelectedDate=async(date)=>{
    await this.setState({ SelectDate: date,WeekStatus:true });
    await this.CheckExistGoalName(date)
    let Errors = { ...this.state.Errors };
    if (this.state.SelectDate === "") {
      Errors['Week'] = "Please select a week";
      this.setState({ WeekStatus: false });
    } else {
      let IsExist = await this.CheckExistGoalName(this.state.SelectDate);
      if (IsExist) {
        Errors['Week'] = "Week already exists"; // Set a message for week existence
        this.setState({ WeekStatus: false });
      } else {
        this.setState({ WeekStatus: true });
        Errors['Week'] = ""; // Clear the error message
      }
    }
    this.setState({ Errors: Errors });
  }

  render() {
    return (
      <>
      {this.state.IsLoading ? (
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div>
        ) : (
          <></>
        )}
        <div className="adminmain mheight"> 
            <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left pl-0 mb-0">
                    Add Meeting Goals
                  </h4>
                </div>
              </div>

            <div className="paddcols px-3">
              <div className="row py-2">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-3 view-lable-meet">Week</label>
                    <div className="col-lg-8 datepickerfull">
                      <DatePicker
                        selected={
                          this.state.SelectDate == null
                            ? ""
                            : this.state.SelectDate
                        }
                        onChange={(date) => this.SelectedDate(date)}
                        onBlur={this.handleDateBlur}
                        name="startDate"
                        filterDate={(date) => date.getDay() === 1}
                        className="form-control m-input w-100"
                        id="Week"
                      />
                      <span style={{ color: "red" }}>{this.state.Errors["Week"]}</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-3 view-lable-meet">Goal</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input arrow-none"
                        id="Goal"
                        onBlur={this.HandleChangeBlur}
                        name="LastName"
                        placeholder="Enter goal"
                        type="number"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Goal"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div className="row my-3">
                <div className="col-lg-12 pull-left">
                  <a
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.IsButtonDisabled}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </a>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};


const connectedCAddMeetingGoalPage = connect(
  mapState,
  actionCreators
)(CAddMeetingGoalPage);
export { connectedCAddMeetingGoalPage as CAddMeetingGoalPage };
