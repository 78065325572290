import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import {
    IconButton,
    Collapse,
    Box,
    Paper,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    
  } from '@material-ui/core';
  import moment from 'moment';
  import Pagination from "@material-ui/lab/Pagination";
  import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

const SortingIcons = ({SortedBy,sortedColumn,ColumnName}) => {
  return (
    <span className="shorting">
           <ArrowUpward className={SortedBy === 1 && sortedColumn === ColumnName? "active": null}/>
           <ArrowDownward className={SortedBy === -1 && sortedColumn === ColumnName? "active": null}/>
    </span>
  )
}

function Contacts({ClientID,UserID,Role,AccountID,ContactDomain}) {
    const [Data, setData] = useState([]);
    const [Rows, setRows] = useState([]);
    const [Rlen, setRlen] = useState(0);
    const [Flen, setFlen] = useState(0);
    const [CountPage, setCountPage] = useState(0);
    const [NotesData, setNotesData] = useState('');
    const [SortField, setSortField] = useState('CreatedDate');
    const [SortedBy, setSortedBy] = useState(-1);
    const [Page, setPage] = useState(1);
    const [RowsPerPage, setRowsPerPage] = useState(100);
    const [Search, setSearch] = useState('');
    const [Sflag, setSflag] = useState(false);
    const [Open,setOpen] = useState(false)
    const [sortedColumn, setSortedColumn] = React.useState('CreatedDate');

  
    useEffect(() => {
      // Fetch initial data
      get('SalesReplyAccountContactGet', {
        Domain: ContactDomain,
        Search: Search,
        Page: Page,
        RowsPerPage: RowsPerPage,
        AccountID: AccountID,
        ClientID: ClientID,
        UserID: UserID,
      });
    }, [ContactDomain, Search, Page, RowsPerPage,AccountID,ClientID]);

    
  const handleApiResponse = (result) => {
    if(result.data.StatusMessage === "SUCCESS"){
      setData(result.data?.PageData);
      setRows(result.data?.PageData);
      setRlen(result.data?.TotalCount);
      setFlen(result.data?.TotalCount);
      setCountPage(result.data?.PageCount);
      setNotesData(result.data?.ProspectNote);
    }else{
      toast.error(result.data.Message)
    }
    
  };

  const get = (endpoint, params) => {
    if(params?.Domain?.trim() !== "" && params?.ClientID?.trim() !== "" && params?.AccountID?.trim() !== "" && params?.UserID?.trim() !== ""){
        const inputParameters = {
            ClientID,
            Sort: true,
            Field: SortField,
            Sortby: SortedBy,
            Role,
            Type: 'User',
            ...params,
          };
      
          Axios({
            url: CommonConstants.MOL_APIURL + '/accounts/' + endpoint,
            method: 'POST',
            data: inputParameters,
          }).then(handleApiResponse);
    }
   
  };

  const ContactSorting = (field) => {
    const searchedVal = document.getElementById('ContactSearch').value.trim();
    const searchBox = searchedVal !== '';
    setSflag(searchBox);
    console.log(field)
    const isNewSortField = field !== SortField;
    let sortBy = SortedBy === 1 ? -1 : 1;
    setSortedColumn(field);
  
    if (isNewSortField) {
      setSortedBy(1);
      sortBy = 1;
    } else {
      setSortedBy(sortBy);
    }
  
    setSortField(field);
  
    get('SalesReplyAccountContactGet', {
      Domain: ContactDomain,
      Search: Search,
      Page: Page,
      RowsPerPage: RowsPerPage,
      AccountID: AccountID,
      ClientID: ClientID,
      UserID: UserID,
    });
  };
  

  // const ContactSorting = (field) => {
  //   const searchedVal = document.getElementById('ContactSearch').value.trim();
  //   const serchBox = searchedVal === '' ? false : true;
  //   setSflag(serchBox);

  //   const sortBy = SortedBy === 1 ? -1 : 1;
  //   setSortedBy(sortBy);
  //   setSortField(field);

  //   get('SalesReplyAccountContactGet', {
  //     Domain: ContactDomain,
  //     Search: Search,
  //     Page: Page,
  //     RowsPerPage: RowsPerPage,
  //     AccountID: AccountID,
  //     ClientID: ClientID,
  //     UserID: UserID,
  //   });
  // };

  const ChangeRowSelectedContact = (event) => {
    const rowsPerPage = Number(event.target.value);
    setRowsPerPage(rowsPerPage);
    setPage(1);

    const searchedVal = document.getElementById('ContactSearch').value.trim();
    get('SalesReplyAccountContactGet', {
      Domain: ContactDomain,
      Search: searchedVal,
      Page: 1,
      RowsPerPage: rowsPerPage,
      AccountID: AccountID,
      ClientID: ClientID,
      UserID: UserID,
    });
  };

  const RequestSearchContact = (event) => {
    if (event.key === 'Enter') {
      const searchedVal = document.getElementById('ContactSearch').value.trim();
      setSearch(searchedVal);
      setPage(1);

      get('SalesReplyAccountContactGet', {
        Domain: ContactDomain,
        Search: searchedVal,
        Page: Page,
        RowsPerPage: RowsPerPage,
        AccountID: AccountID,
        ClientID: ClientID,
        UserID: UserID,
      });
    }
  };

  const HandleChangePageContact = (event, newPage) => {
    if (newPage !== Page) {
      setPage(newPage);
      const searchedVal = document.getElementById('ContactSearch').value.trim();
      get('SalesReplyAccountContactGet', {
        Domain: ContactDomain,
        Search: searchedVal,
        Page: newPage,
        RowsPerPage: RowsPerPage,
        AccountID: AccountID,
        ClientID: ClientID,
        UserID: UserID,
      });
    }
  };


  const ContactEdit = (id) => {
      history.push({
        pathname: '/viewcontacts',
        state: {
          data: id,
          Pagename: 'AccountContact',
          AccountData: AccountID,
        },
      });
  };

      // Add contact Page
 const ContactAdd = () => {
    history.push({
      pathname: "/addcontacts",
      state: { data: AccountID, Pagename: "AccountContact" },
    });
  };

  const ViewContactPage = (id) => {
    history.push({
      pathname: '/viewcontacts',
      state: {
        data: id,
        Pagename: 'AccountContact',
        AccountData: AccountID,
      },
    });
  }

  return (
    <>
         <div className="row">
              <div className="col">
                <h3 className="xs-headertitle py-4 mb-0">
                    Contacts
                  </h3>
                </div>
                <div className="col py-3 text-right">
                  <button
                    onClick={ContactAdd}
                    className="btn btngroup m-btn "
                  >
                    <i className="la la-plus la-icon-font-size-13"></i>
                    <span className="Mobile_button">Add</span>
                  </button>
                </div> 
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={ChangeRowSelectedContact}
                    value={RowsPerPage}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value} key={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="ContactSearch"
                    onKeyPress={(event) => RequestSearchContact(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell  onClick={() => { ContactSorting("FirstName");}}>
                          Contact Name
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"FirstName"} />
                      </TableCell>
                      <TableCell onClick={() => {ContactSorting("Email"); }}>
                          Email
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Email"} />
                      </TableCell>
                      <TableCell onClick={() => {ContactSorting("Company");}}>
                          Company
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Company"} />
                      </TableCell>
                      <TableCell onClick={() => {ContactSorting("Title"); }}>
                          Title
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Title"} />
                        
                      </TableCell>
                      <TableCell onClick={() => { ContactSorting("ContactStatus");}}>
                          Contact status
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"ContactStatus"} />
                        
                      </TableCell>
                      <TableCell onClick={() => { ContactSorting("CreatedDate");}}>
                          Last Activity date
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"CreatedDate"} />
                      </TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Rows?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      Rows?.map((row) => (
                        <>
                          <TableRow key={row?._id}>
                            <TableCell align="center">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() =>
                                    setOpen((prev) => ({
                                        ...prev,
                                        [row._id]: !prev[row._id],
                                      }))
                                }
                              >
                                {Open[row._id] ? (
                                  <i
                                    className="fa fa-minus-circle minusl"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-plus-circle plusbl"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell><a onClick={()=>{ViewContactPage(row._id)}}><u>{row?.FirstName} {row?.LastName}</u></a></TableCell>
                            <TableCell>{row?.Email}</TableCell>
                            <TableCell>{row?.Company}</TableCell>
                            <TableCell>{row?.Title}</TableCell>
                            <TableCell>{row?.ContactStatus}</TableCell>
                            <TableCell>
                              {row.CreatedDate
                                ? moment(
                                    new Date(row.CreatedDate).toDateString()
                                  ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>
                            <TableCell>
                              <a
                                onClick={() => {
                                  ContactEdit(row._id);
                                }}
                              >
                                <i className="la flaticon-edit-1 edit-icon"></i>
                              </a>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={6}
                            >
                              <Collapse
                                in={Open[row._id]}
                                timeout="auto"
                                unmountOnExit
                              >
                                {Open[row._id] && (
                                  <Box margin={1} className="innertables">
                                    <Table size="small" aria-label="purchases">
                                      <TableHead></TableHead>
                                      {NotesData.map((note) =>
                                        note.ProspectID.toString() ==
                                        row._id.toString() ? (
                                          <TableRow>
                                            <TableCell><b>{note.Title}</b></TableCell>
                                            <TableCell><b>{note.CreatedDate? moment(new Date(note.CreatedDate).toDateString()).format("MM/DD/YYYY"): ""}</b> </TableCell>
                                            <TableCell scope="row">
                                              {note.Note}
                                            </TableCell>
                                          
                                          </TableRow>
                                        ) : null
                                      )}

                                      {!NotesData.some(
                                        (note) =>
                                          note?.ProspectID.toString() ==
                                          row?._id.toString()
                                      ) && (
                                        <TableRow>
                                          <TableCell colSpan={4}>
                                            No data available
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </Table>
                                  </Box>
                                )}
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          
              <div className="row">
                <div className="col dataTables_info">
                  <p>
                    Showing{" "}
                    {Rlen == 0
                      ? 0
                      : (Page - 1) *
                          RowsPerPage +
                        1}{" "}
                    to{" "}
                    {Page * RowsPerPage >
                    Rlen
                      ? Rlen
                      : Page *
                        RowsPerPage}{" "}
                    of {Rlen} entries
                  </p>
                </div>
                <div className="col pageright">
                  <Pagination
                    component="div"
                    count={CountPage}
                    onChange={HandleChangePageContact}
                    page={Page}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
    </>
  )
}

export default Contacts