import React from "react";
import { connect } from "react-redux";
import Popupone from "reactjs-popup";
import Axios from "axios";
var CryptoJS = require("crypto-js");
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../../_actions";
const EmailTemplete = require("../../../_helpers/email-templete");
import { CommonConstants } from "../../../_constants/common.constants";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
const Fileupload = require("../../../_helpers/fileupload");
import { history } from "../../../_helpers";
import Footer from "../../../_components/user/footer/footer";
import loadingicon from "../../../images/loading.gif";

toast.configure();
import ProfileImageUpload from '../../../_components/ProfileUpload';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const REACT_APP_ENCRYPTION_SALT = process.env.REACT_APP_ENCRYPTION_SALT


const imgstyle = { width: "100px", height: "100px" };

class EditResponderPage extends React.Component {
  constructor(props) {
    super(props);
    this.childRef = React.createRef();

    this.state = {
      Code: "",
      Profile: [],
      UserProfile: [],
      PopUpVal: true,
      InvitePopUp: false,
      FinalImg: "",
      FileImg: "default.png",
      ImgFlag: false,
      ProfileImage:null,
      ResponderID:null,
      Email:null,
      ResponderName:null,
      LastName:null,
      Password:null,
      Message:"",
      IsLoading:false,
      showPassword: false,
      FormData:{
        FirstName: "",
        LastName: "",
        Email: "",
        ProfileImage: "",
        id: "",
        IsTwoStepVerification:"",
        UserID:"",
        SlackAccount:"",
        Password:"",
        ResponderID:""
      },
      errors:{},
      ButtonDisabled:false,
      IsLoading:false,
    };
    
    this.ResetPassword = this.ResetPassword.bind(this);
    this.OpenInvitePopUp = this.OpenInvitePopUp.bind(this);
    this.CloseInvitePopUp = this.CloseInvitePopUp.bind(this);
    this.SubmitInvitePopUp = this.SubmitInvitePopUp.bind(this);
    this.BackButton = this.BackButton.bind(this);
  }
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  decryptPassword(encryptedPassword) {
    try {
      const decryptedPasswordBytes = CryptoJS.AES.decrypt(encryptedPassword.replace(/ /g, '+'), "my-secret-key@123");
      return decryptedPasswordBytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error('Error during password decryption:', error);
      return ''; // Return an empty string as a default value
    }
  }
  componentDidMount() {
    document.title = "Edit Responder | SalesHive"
    const data = this.props.location.state;
    this.setState({ Code: data });
    
    var InputParameters = {
      id: data,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/responder_Profile/ResponderGetById",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {

      // let Pass = JSON.parse((CryptoJS.AES.decrypt(((Res.data.data[0]?.Password).split(" ").join("+")), REACT_APP_ENCRYPTION_SALT)).toString(CryptoJS.enc.Utf8));
      let Pass = this.decryptPassword(Res.data.data[0]?.Password);
      this.setState({ Profile: Res.data.data[0] });
     
      this.setState({FinalImg:Res.data.data[0].ProfileImage});
      let Form = {
        FirstName: Res.data.data[0]?.ResponderName,
        LastName: Res.data.data[0]?.LastName,
        Email: Res.data.data[0]?.U?.Username,
        ProfileImage: Res.data.data[0]?.ProfileImage,
        id: this.props.location.state,
        IsTwoStepVerification:Res.data.data[0]?.IsTwoStepVerification,
        UserID:Res.data.data[0]?.ResponderUserID,
        SlackAccount:Res.data.data[0]?.SlackAccount,
        Password:Pass,
        ResponderID:Res.data.data[0].ResponderUserID
      }
      
      this.setState({FormData:Form})
      this.setState({IsLoading:false})
    
    });
  }

  //Invite PopUp
  OpenInvitePopUp() {
    this.setState({ InvitePopUp: true });
  }

  //Invite PopUp
  CloseInvitePopUp() {
    this.setState({ InvitePopUp: false });
    this.setState({ Message: "" });
  }
  async  CheckUpdateEmail(Email){
    let Emails = document.getElementById("change_mail").value;
      var InputParameters = {
        ParentUserID: "",
        // UserID: this.state.UserID,
        Email: Emails,
      };
    let resdata = await  Axios({
        url: CommonConstants.MOL_APIURL + "/AdminUser/UpdateEmailValidation",
        method: "POST",
        data: InputParameters,
      });
      
        if (resdata.data.StatusMessage == "SUCCESS") {
            if(resdata.data.Flag){
                this.setState({Message:resdata.data.Message});
              return false;
            }else{
              this.setState({Message:""});
              return true;
            }
            
        }
     
  }

     // Whole form validation
     async FormValidation() {
      let formIsValid = true;
      let Email = document.getElementById("change_mail").value;
      let IsExists = await this.CheckUpdateEmail(Email);
  
      if(IsExists){
        formIsValid = true;
      }else{
        formIsValid = false;
      }
      if(Email.trim().length <=0 ){
        formIsValid=false;
        this.setState({Message:"Please enter email."})
      }
    
      return formIsValid;
    }

  //Email change
 async SubmitInvitePopUp(e) {
  e.preventDefault();
    e.currentTarget.disabled = true;
    var Final_flag =  await this.FormValidation()
    if(Final_flag==true){
    var SendEmail = document.getElementById("change_mail").value;
    var Tmp_Token =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    var email_token = CryptoJS.AES.encrypt(JSON.stringify(Tmp_Token),REACT_APP_ENCRYPTION_SALT).toString();

    var uid = this.state.ResponderID;
    var InputParameters = {
      uID: uid,
      Email_Token: email_token,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/user/changeemail",
      method: "POST",
      data: InputParameters,
    }).then(async(res) => {
      if (res.data != "") {
        var Username = SendEmail;
        var email = CryptoJS.AES.encrypt(JSON.stringify(SendEmail),REACT_APP_ENCRYPTION_SALT).toString();
        var emailData = EmailTemplete.userEmailChange(
          email_token,
          email,
          CommonConstants.change_Email_responder
        );

        try {
          // Use await to wait for the SendEmail function to complete
          let IsMailSend = await this.SendEmail(Username, emailData, "Please Confirm Your Email");
          if (IsMailSend) {
            toast.success(<div className="toastsize">Responder <br/>Mail sent successfully</div>)
          } else {
              toast.error(<div className="toastsize">Responder Edit<br/>Something went wrong while sending the update email.</div>);
          }
        } catch (error) {
            console.error("Error sending email:", error);
            toast.error(<div className="toastsize">Responder Edit<br/>Error sending the update email.</div>);
        }
      }
    });
    this.setState({ InvitePopUp: false });
  }else{
    document.getElementById("m_emailInvite_submit").disabled = false;
  }
  }


ResetPassword(){
 
  var tmp_token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  var forgot_token = CryptoJS.AES.encrypt(JSON.stringify(tmp_token),REACT_APP_ENCRYPTION_SALT).toString();
 
      var InputParameters = {
          "UserName": this.state.FormData.Email,
          "Forgot_Token":forgot_token
      };
      Axios({
          url: CommonConstants.MOL_APIURL + "/login/forgotpassword",
          method: "POST",
          data: InputParameters
      }).then(async(resdata) => {
        if (resdata.data.data?.length > 0) {
          var Username = resdata.data.data[0]?.Username;
          var emailData = EmailTemplete.userForgotpassword(forgot_token, Username);

          try {
            // Use await to wait for the SendEmail function to complete
            let IsMailSend = await this.SendEmail(Username, emailData, "Reset Password");
            if (IsMailSend) {
                toast.success(<div className="toastsize">Responder Edit<br/> Thank you, Please check your email for password reset link.</div>);
            } else {
                toast.error(<div className="toastsize">Responder Edit<br/>Something went wrong while sending the reset password email.</div>);
            }
        } catch (error) {
            console.error("Error sending email:", error);
            toast.error(<div className="toastsize">Responder Edit<br/>Error sending the reset password email.</div>);
        }

        }
      
      });
  
}


  //back button
  BackButton() {
    history.push("/responder");
  }

  //Email Send method
  SendEmail = (Username, EmailTemplate, Subject) => {
      return new Promise((resolve, reject) => {
          const InputParameters = {
              email: Username,
              html: EmailTemplate,
              subject: Subject,
          };
          Axios({
              url: CommonConstants.MOL_APIURL + "/user/emailsend",
              method: "POST",
              data: InputParameters,
          })
          .then((res) => { 
              if (res.data.StatusMessage === "SUCCESS") {
                  resolve(true); // Resolve the promise with true
              } else {
                  resolve(false); // Resolve the promise with false
              }
          })
          .catch((error) => {
              console.error("Error sending email:", error);
              reject(error); // Reject the promise in case of an error
          });
      });
  }


    // Function to validate individual form field
    ValidateField(fieldName, value) {
      // Define your validation rules here
      const newErrors = { ...this.state.errors };
      switch (fieldName) {
        case "FirstName":
          // Check if the FirstName field is empty
          newErrors.FirstName = (value?.trim() === "" || value === null || value === undefined) ? "Please enter responder name." : "";
          break;
        case "SlackAccount":
          newErrors.SlackAccount = (value?.trim() === "" || value === null || value === undefined) ? "Please enter slack account." : "";
          break;
        default:
          break;
      }
      console.log(newErrors)
      this.setState({ errors: newErrors });
    }
  
    //Update OnChange Handler
    HandleInputChange = (e) => {
      const { name, value, type, checked } = e.target;
        console.log(name)
      this.setState((prevState) => {
        let updatedFormData = { ...prevState.FormData };
  
        if (type === "checkbox") {
          // For the checkbox, store the boolean value directly
          updatedFormData[name] = checked;
        } else {
          // For other fields, store their values
          updatedFormData[name] = value;
        }
        return {
          FormData: updatedFormData,
        };
      });
    };
  
    //Update OnBlur Handler
    HandleBlur = (e) => {
      const { name, value } = e.target;
      console.log(name)
      console.log(value)
      this.ValidateField(name, value);
    };
  
    //Update Sales Strategists
    HandleSubmit = async (e) => {
      e.preventDefault();
      this.setState({ ButtonDisabled: true });
      this.setState({ IsLoading: true });
      let Image = await this.childRef.current.SubmitData();
      let ImageName = Image.FileName;
      this.setState({ FinalImg: ImageName, ButtonDisabled: true });
      this.setState((prevState) => ({
        FormData: {
          ...prevState.FormData,
          ProfileImage: ImageName,
        },
      }));
      const { FormData } = this.state;
      const fieldNames = Object.keys(FormData);
      
      let isValid = true;
  
      fieldNames.forEach((fieldName) => {
        this.ValidateField(fieldName, FormData[fieldName]);
        if (this.state.errors[fieldName]) {
          isValid = false;
        }
      });
  
      
  
      if (isValid) {
        Axios({
          url: CommonConstants.MOL_APIURL + "/responder_Profile/ResponderUpdate",
          method: "POST",
          data: FormData,
        }).then((res) => {
          if (res) {
            toast.success(<div className="toastsize">
            Responder Edit <br/>
            Responder updated successfully.
           
          </div>
          );
          this.setState({ IsLoading: false });
            history.push("/responder");
          }
          }).catch((error) => {
            this.setState({ IsLoading: false });
          });
  
        // You can submit the form data to your server or perform other actions here
      } else {
        this.setState({ ButtonDisabled: false });
        this.setState({ IsLoading: true });
      }
    };
  
    //Remove Quotes from password
    RemoveQuotes = (str) => {
      // Check if the string starts and ends with double quotes
      if (str.startsWith('"') && str.endsWith('"')) {
          // If yes, remove the first and last character
          return str.slice(1, -1);
      } else {
          // If not, return the string as it is
          return str;
      }
    }

  render() {
  const {FormData,errors} = this.state;

    return (
      <>
      {this.state.IsLoading ? (
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div>
        ) : (
          <></>
        )}
        <Popupone open={this.state.InvitePopUp} modal>
          <div className="modal-black"></div>
          <div className="filterPopup bigpopupcontent">
            <div className="paddingboxTerms">
              <div className="modal-header">
                <h5>Send Mail</h5>
                <a className="close-ion" onClick={this.CloseInvitePopUp}>
                  <span aria-hidden="true" class="la la-remove"></span>
                </a>
              </div>
              <div className="modal-content bordernone">
                <div class="row max-cols">
                  <label class="col-lg-4 view-lable-meet">Email</label>
                  <div class="col-lg-8">
                    <input
                      class="form-control m-input"
                      name="Email"
                      placeholder="Email"
                      id="change_mail"
                      type="text"
                      onBlur={this.CheckUpdateEmail.bind(this, "Email")}
                      />
                       <span style={{ color: "red" }}>
                          {this.state.Message}
                        </span>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  onClick={this.CloseInvitePopUp}
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <a
                  id="m_emailInvite_submit"
                  href="javascript:void(0);"
                  onClick={this.SubmitInvitePopUp}
                  class="btn btn-primary btnColor"
                >
                  Send{" "}
                </a>
              </div>
            </div>
          </div>
        </Popupone>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad lg-headertitle float-left pl-0">Edit Responder</h4>
                </div>
              </div>

            <div class="bg-white p-3">
              <div className="row pt-2">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">First Name</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        id="FirstName"
                        name="FirstName"
                        placeholder="First name"
                        type="text"
                        value={FormData.FirstName}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                      />
                      {errors.FirstName && (
                        <div className="errorinput">{errors.FirstName}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Last Name</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        id="LastName"
                        name="LastName"
                        placeholder="Last name"
                        value={FormData.LastName}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                      />
                     
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Email</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid email."
                        data-val-remote="Email already exist."
                        data-val-remote-additionalfields="*.Email,*.PageName,*.CampaignID,*.ClientID,*.UserID"
                        data-val-remote-url="/Utility/IsProspectEmailExistsAndBlacklist"
                        data-val-required="Please enter email."
                        id="Email"
                        name="Email"
                        placeholder="Email"
                        type="text"
                        value={FormData.Email}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                      
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <a
                    id="lnkUpdateEmail"
                    href="javascript:void(0);"
                    onClick={this.OpenInvitePopUp}
                    class="btn btn-primary btngroup btnlightgroup"
                  >
                    Update Email
                  </a>
                </div>
              </div>

              <div className="row">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Password</label>
                    <div class="col-lg-8 position-relative d-flex align-items-center">
                      <input
                        class="form-control m-input"
                        id="Password"
                        name="Title"
                        placeholder="Password"
                        type={this.state.showPassword ? 'text' : 'password'}
                        value={this.RemoveQuotes(FormData.Password)}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                        disabled
                      />
                           <IconButton
                      disableRipple
                      onClick={this.handleClickShowPassword}
                      className='position-absolute rigterpin d-flex mr-2'
                    >
                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <a
                    id="lnkUpdatepassword"
                    href="javascript:void(0);"
                    onClick={this.ResetPassword}
                    class="btn btn-primary btngroup btnlightgroup"
                  >
                    Reset Password
                  </a>
                </div>
              </div>
              <div className="row pt-4">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Slack Account</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input" 
                        placeholder="Slack Account"
                        type="text"
                        id="SlackAccount"
                        name="SlackAccount"
                        value={FormData.SlackAccount}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                      /> 
                      {errors.SlackAccount && (
                        <div className="errorinput">{errors.SlackAccount}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-4 px-3">
              <ProfileImageUpload
                profileImage={this.state.FinalImg}
                ref={this.childRef}
              />  
               </div>

              {/* <div className="row">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      <input id="ismanager" type="checkbox" />
                      <span className="checkmark"></span> Is Manager{" "}
                    </label>
                    <div class="col-lg-8"></div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group"></div>
              </div> */}

              {/* <div className="row">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Upload Profile Image
                    </label>
                    <div class="col-lg-8">
                      <input
                        type="file"
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                        name="avatar"
                        onChange={this.HandleUpload.bind(this, "coverimage")}
                      />
                    </div>
                    <label class="col-lg-4 view-lable-meet"></label>
                    <div className="col-lg-8">
                      <div>
                      {/* <label>
                          {this.state.ImgFlag == true
                            ? this.state.FinalImg.name
                            : this.state.ProfileImage}
                        </label> */}
                      {/* </div>
                      <div id="imagePreview">
                      <img
                          style={imgstyle}
                          className="avatar-view container2"
                          src={
                            this.state.ImgFlag == true
                              ? this.state.FileImg
                              : CommonConstants.Image_url +
                                this.state.ProfileImage
                          }
                          id="avtar-preview"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              
              <div className="row pt-4">
                <div class="col-lg-6 boxsinput_group d-flex">
                  <div class="toggleswich mr-2">
                    <input type="checkbox" class="checktogle" id="IsTwoStepVerification" name="IsTwoStepVerification"  checked={FormData.IsTwoStepVerification}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}/>
                    <b class="switch"> 
                      <span class="checkion"></span>
                      <span class="uncheckion"></span>
                    </b>
                    <b class="track"></b>
                  </div>
                  <label>Is Two Step Verification</label> 
                </div>
              </div> 
            </div>

              <div class="row mt-4 mb-5">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.HandleSubmit}
                    disabled={this.state.ButtonDisabled}
                    className="btn btn-primary btn-lightgreen"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackButton}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditResponderPage = connect(
  mapState,
  actionCreators
)(EditResponderPage);
export { connectedEditResponderPage as EditResponderPage };
