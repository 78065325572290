import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { toast } from "react-toastify";
import Popupone from "reactjs-popup";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import loadingicon from "../../../images/loading.gif";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import StyleHeader from "../StickyHeader/StickyHeader";

const SortingIcons = ({SortedBy,sortedColumn,ColumnName}) => {
  return (
    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === ColumnName? "active": null}/>
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === ColumnName? "active": null}/>
                  </span>
  )
}

export default function AccountAllTable({ ToDate, FromDate, updateFromChild }) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("MsgDateTimeByTimeZone");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [PUserID, SetPUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [FromDates, SetFromDates] = React.useState(FromDate);
  const [ToDates, SetToDates] = React.useState(ToDate);
  const [ExportData, SetExportData] = React.useState([]);
  const [ResponderCategoryList, SetResponderCategoryList] = React.useState([]);
  const [OpenNotes, SetOpenNotes] = React.useState(false);
  const [NoteDetail, SetNoteDetail] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(true);
  const [sortedColumn, setSortedColumn] = React.useState("MsgDateTimeByTimeZone");
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);


  useEffect(() => {
    SetIsLoading(true);
    document.title = "All Responses | SalesHive";
    let Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ChildUserID);
      SetPUserID(Details.ParentUserID);
      SetRole(Details.Role);
    }
    SetFromDates(FromDate);
    SetToDates(ToDate);
    AllResponsesGet(
      Details.ClientID,
      Details.ParentUserID,
      Details.Role,
      FromDate,
      ToDate
    );
  }, [Search, Page, RowsPerPage, ToDate, FromDate]);

  useEffect(()=>{
    let Details = GetUserDetails();
    if (Details != null) {
    ResponseCategories(Details.ParentUserID, Details.ClientID, Details.Role);
    }
  },[])


  //Export Data
  const ExportDataMethod = async(ClientID, ParentUserID, From, To) => {
    const InputParameters = {
      UserID: ParentUserID,
      ClientID: ClientID,
      ToDate: To,
      FromDate: From,
    };

   let Res=await  Axios({
      url: CommonConstants.MOL_APIURL + "/all_responses/AllResponsesExport",
      method: "POST",
      data: InputParameters,
    })
    
    if (Res.data.StatusMessage === "SUCCESS") {

      SetExportData(Res.data.Data);
      return Res.data
    
    
    }else{
      SetExportData([]);
      return Res.data

    }

  };

  const ExportCsv = async() => {
    try {
     SetIsLoading(true)
     const result = await ExportDataMethod(ClientID, PUserID,FromDate,ToDate);
      if (result.StatusMessage === "SUCCESS") {
        const data = result.Data;
        if (data.length > 0) {
          const downloader = document.createElement("a");
          const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(convertToCSV(data));
          downloader.setAttribute("href", csvContent);
          downloader.setAttribute("download", "AllClients-CampaignResponsesDetail.csv");
          downloader.click();
          toast.success(<div>Data exported successfully.</div>);
         SetIsLoading(false)
        } else {
          toast.error("No data available for export.");
         SetIsLoading(false)
        }
      } else {
        toast.error("Failed to export data. Please try again.");
       SetIsLoading(false)
      }
    } catch (error) {
      SetIsLoading(false)
      toast.error("An error occurred while exporting data.");
    }

  };

    
  function convertToCSV(data) {
    const headers = Object.keys(data[0]);

    const csvRows = [];
    csvRows.push(headers.join(','));

    data.forEach(item => {
        const values = headers.map(header => {
            // Check if the value is a string before attempting to replace characters
            const value = typeof item[header] === 'string' ? item[header] : String(item[header]);
            const escapedValue = value.replace(/"/g, '""'); // Escape double quotes
            return `"${escapedValue}"`; // Enclose value in double quotes
        });
        csvRows.push(values.join(','));
    });

    return csvRows.join('\n');
}
  // All Responses get list
  const AllResponsesGet = (CID, UID, URole, From, To) => {
    SetIsLoading(true);

    let InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      ToDate: To,
      FromDate: From,
    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/all_responses/AllResponsesGet",
      method: "POST",
      data: InputParameters,
    }).then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false);
      setIsDataLoaded(true);

    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  // All Responses delete
  const DeleteBtn = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete Email Response?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          ID: id,
          LastUpdatedDt: new Date(),
          LastUpdatedBy: UserID,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/all_responses/AllResponsesDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Response Message delete successfully.",
                "success"
              );
              AllResponsesGet(ClientID, PUserID, Role, FromDate, ToDate);
              updateFromChild(true);
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //get sort field data
  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");
  
    const InputParameter = {
      Page,
      RowsPerPage,
      SerchBox: SearchedVal !== "",
      Sort: true,
      Search: SearchedVal,
      Field,
      SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: PUserID,
      Role: Role,
      ToDate: ToDates,
      FromDate: FromDates,
    };
  
    Axios({
      url: CommonConstants.MOL_APIURL + "/all_responses/AllResponsesGet",
      method: "POST",
      data: InputParameter,
    }).then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetPage(1)
      SetIsLoading(false);
    });
  };

  //Get Response categories details
  const ResponseCategories = (UID, CID, ROLE) => {
    let data = {
      UserID: UID,
      ClientID: CID,
      Role: ROLE,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/all_responses/GetResponseCategoryDetails",
      method: "POST",
      data: data,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        SetResponderCategoryList(res.data.Data);
      } else {
        toast.error(res.data.Message);
      }
    });
  };

  //Update Responder
  const ResponderCategorySelect = (event, id) => {
    let data = {
      _id: id,
      ResponseCategoryID: event.target.value,
      LastUpdatedBy: UserID,
      LastUpdatedDate: new Date(),
      Role: Role,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/salesreply_message/UpdateSalesReplyCategoryDropDown",
      method: "POST",
      data: data,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        // window.location.reload(true);
        AllResponsesGet(ClientID, PUserID, Role, FromDate, ToDate);
        toast.success(
          <div className="toastsize">
            Response Category <br /> Response category updated successfully.
          </div>
        );
      } else {
        toast.error(res.data.Message);
      }
    });
  };

  //Open Notes
  const OpenNotesModel = (ID) => {
    let data = {
      ID: ID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/all_responses/NoteByMessageID",
      method: "POST",
      data: data,
    }).then((res) => {
      SetNoteDetail(res.data?.Data.BodyDataPlain);
      SetOpenNotes(true);
    });
  };

  //Close Notes
  const CloseNotes = () => {
    SetOpenNotes(false);
    SetNoteDetail("");
  };

  return (
    <>
      {IsLoading === true ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <Popupone open={OpenNotes} modal>
        <div className="modal-black"></div>
        <div className="filterPopup bigpopupcontent">
          <div className="paddingboxTerms">
            <div className="modal-header">
              <h5>Notes</h5>
              <a
                className="close-ion"
                onClick={() => {
                  CloseNotes();
                }}
              >
                <span aria-hidden="true" class="la la-remove"></span>
              </a>
            </div>
            <div className="modal-content bordernone">
              <div class="row max-cols">
                <h6>Body</h6>
                <br />
                <p>
                  <div dangerouslySetInnerHTML={{ __html: NoteDetail }} />
                </p>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                onClick={() => {
                  CloseNotes();
                }}
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Popupone>

      <div className="row border-bottom mx-0">
        <div className="col">
          <div className="listing-li float-right padb-15">
            <ul>
              <li>
             

              <a onClick={ExportCsv} className="btn btngroup m-btn m-btn--custom">
                <i className="la la-icon-font-size-13 la-download"></i> Export
              </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div class='px-3'>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (<option value={value} key={value}>{value}</option>))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>

      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
        <Table className="table-ref"  aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {/* <TableCell onClick={() => {SortData("ClientName");}}>Client Name</TableCell> */}
                <TableCell>Client Name</TableCell>
                <TableCell onClick={() => {SortData("From");}}>From <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"From"} /></TableCell>
                <TableCell >Account Name</TableCell>
                <TableCell onClick={() => {SortData("Subject");}}>Subject<SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Subject"} /></TableCell>
                <TableCell onClick={() => {SortData("Snippet");}}>Snippet<SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Snippet"} /></TableCell>
                <TableCell onClick={() => {SortData("MsgDateTimeByTimeZone");}}>Response Date<SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"MsgDateTimeByTimeZone"} /></TableCell>
                <TableCell >Campaign</TableCell>
                <TableCell >Step Number</TableCell>
                <TableCell >Response Category</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow key={row._id} row={row}>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          OpenNotesModel(row._id);
                        }}
                        aria-label="expand row"
                        size="small"
                      >
                        <i
                          class="fa fa-plus-circle plusbl"
                          aria-hidden="true"
                        ></i>
                      </IconButton>
                    </TableCell>
                    <TableCell>{row.ClientName}</TableCell>
                    <TableCell>{row.From}</TableCell>
                    <TableCell>{row.Name}</TableCell>
                    <TableCell>{row.Subject}</TableCell>
                    <TableCell>
                      {/<[a-z][\s\S]*>/i.test(row?.Snippet) ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: row?.Snippet.slice(0, 30) + " ...",
                          }}
                        />
                      ) : (
                        <span>{row?.Snippet.slice(0, 30)} ...</span>
                      )}
                    </TableCell>
                    <TableCell>{row.MsgDateTime}</TableCell>
                    <TableCell>{row.Campaign}</TableCell>
                    <TableCell>
                      {row?.StepNumber +
                        "-" +
                        String.fromCharCode(64 + row.TemplateNumber)}
                    </TableCell>

                    <TableCell>
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field Status must be a number."
                        id="ResponderCategoryId"
                        name="Status"
                        value={row?.ResponseCategoryID}
                        onChange={(e) => {
                          ResponderCategorySelect(e, row?._id);
                        }}
                      >
                        {row?.ResponceCategoryList?.map((value) => (
                          <option key={value?._id} value={value?._id}>
                            {value?.Name}
                          </option>
                        ))}
                      </select>
                    </TableCell>
                    <TableCell>
                      <a
                        onClick={() => {
                          DeleteBtn(row._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i class="la flaticon-delete-1 delete-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="row">
        <div className="col dataTables_info">
          <p>
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries
          </p>
        </div>
        <div className="col pageright">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
      </div>
    </>
  );
}
