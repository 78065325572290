import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Axios from "axios";
import { CommonConstants } from "../../_constants/common.constants";
import { arrayMoveImmutable } from "array-move";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { history } from "../../_helpers";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../_helpers/Utility";
import "react-toastify/dist/ReactToastify.css";
import { event } from "jquery";
import loadingicon from "../../images/loading.gif";
import Swal from "sweetalert2";
import Footer from "../../_components/user/footer/footer";

toast.configure();

const SortableItem = SortableElement(({ value }) => (
  <li className="boxcorder">{value}</li>
));


const SortableList = SortableContainer(({ newItems }) => {
  return (
    <ul>
      {newItems.map((value, Index) =>
        value.IsStatic == true ? (
          <SortableItem
            id={Index}
            key={`item-${Index}`}
            index={Index}
            value={value.ColumnName}
          />
        ) : (
          <SortableItem
            id={Index}
            key={`item-${Index}`}
            index={Index}
            value={value.ColumnName}
          />
        )
      )}
    </ul>
  );
});

class BookedMeetingCustomColumnPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editItems: [],
      newItems: [],
      items: [],
      page: {},
      isChecked: true,
      ClientID: null,
      UserID: null,
      CUserID: null,
    };
    this.backbtn = this.backbtn.bind(this);
  }

  componentDidMount() {
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
    }
    document.title = `Meeting Custom Column | SalesHive`;
    this.GetBookedMeetingCustomColumnList();
  }

  
  GetBookedMeetingCustomColumnList() {
    var str_in = {
      Page: 1,
      RowsPerPage: 100,
      TableName: "MeetingPage",
      Field: "OrderBy",
      Sortby: 1,
      Type: "User",
      ClientID: this.state.ClientID,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/bookedmeetingcustomcolumn/BookedMeetingCustomColumnlistGet",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      const includeTrueArray = [];
      const includeFalseArray = [];
  
      result.data.pagedata.forEach((value) => {
        if (value.IsInclude) {
          includeTrueArray.push(value);
        } else {
          includeFalseArray.push(value);
        }
      });
  
      const sortedIncludeTrueArray = includeTrueArray
        .slice()
        .sort((a, b) => a.OrderBy - b.OrderBy);
  
      this.setState({
        items: sortedIncludeTrueArray.concat(includeFalseArray),
        editItems: result.data.pagedata,
        newItems: sortedIncludeTrueArray,
      });
  
      document.getElementById("hideloding").style.display = "none";
    });
  }
  
  
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      newItems: arrayMoveImmutable(this.state.newItems, oldIndex, newIndex),
    });
  };

  saveOrder() {
    let OrderBy = 0;
    for (let i = 0; i < this.state.newItems.length; i++) {
      this.state.newItems[i].OrderBy = OrderBy + 1;
      OrderBy++;
    }
    this.savedata();
  }
 

  savedata() {
    this.state.newItems.map((changeitem) => {
      this.state.editItems.map((edititems) => {
        if (changeitem._id == edititems._id) {
          edititems.OrderBy = changeitem.OrderBy;
        }
      });
    });

    Swal.fire({
      title: "Are you sure?",
      text: "you want to save meeting custom columns.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
         document.getElementById("hideloding").style.display = "block";
        let str_in = {
          BookedmeetingcustomcolumnList: this.state.editItems,
        };
        const rows1 = Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/bookedmeetingcustomcolumn/OrderBookedMeetingCustomColumnUpdate",
          method: "POST",
          data: str_in,
        });
        rows1.then((result) => {
          if (result.data.StatusMessage == "SUCCESS") {
            toast.success(
              "Booked meeting custom column order updated successfully.",
              "Booked meeting custom column order"
            );
            document.getElementById("hideloding").style.display = "none";
            if (this.props.location.state["Pagename"] == "CBookedMeeting") {
              history.push("/cbookedmeetings");
            } else {
              history.push("/bookedmeetings");
            }
          } else {
            toast.error(result.data.Message);
          }
          // this.GetBookedMeetingCustomColumnList();
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
        document.getElementById("hideloding").style.display = "none";
      }
    });
  }
 
  updateBookedmeetingcustomList(e, value) {
    const isChecked = e.target.checked;
  
    this.setState((prevState) => {
      const updatedEditItems = prevState.editItems.map((updateItems) => {
        if (value._id === updateItems._id) {
          return { ...updateItems, IsInclude: isChecked };
        }
        return updateItems;
      });
  
      let newItemsWithChange = prevState.newItems.slice(); 
  
      if (isChecked) {
        if (!newItemsWithChange.includes(value)) {
          newItemsWithChange.push(value);
        }
      } else {
        newItemsWithChange = newItemsWithChange.filter(
          (item) => item._id !== value._id
        );
      }
  
      const sortedIncludeTrueArray = newItemsWithChange
        .filter((newfilterarray) => newfilterarray.IsInclude === true)
        .sort((a, b) => a.OrderBy - b.OrderBy);
        console.log(newItemsWithChange)
        const includeFalseArray = newItemsWithChange.filter(
          (newfilterarray) => newfilterarray.IsInclude === false
        );
        
        const originalarray = this.state.items;
        
        const reordereddata = originalarray.filter((ele) =>
          includeFalseArray.some((ele2) => ele._id === ele2._id)
        );
          console.log(this.state.items,includeFalseArray,reordereddata)
  
      const finalSortedItems = sortedIncludeTrueArray.concat(reordereddata);
  
      return {
        newItems: finalSortedItems,
        editItems: updatedEditItems,
      };
    });
  }
  
  
  

  backbtn() {
    if (this.props.location.state["Pagename"] == "CBookedMeeting") {
      history.push("/cbookedmeetings");
    } else {
      history.push("/bookedmeetings");
    }
  }

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  render() {
    return (
      <>
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            
              <div className="row">
                <div className="col padd-0 px-3 pt-2">
                  <h4 className="headertitlepad lg-headertitle float-left px-1 mb-0">
                    Meeting Custom Column
                  </h4>
                </div>
                <div class="pull-left py-2 mt-1 mx-1 pt-3">
                  <button
                    id="submit"
                    onClick={() => {
                      
                      this.saveOrder();
                    }}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save order
                    </button>
                  <a
                    id="backtolist"
                    onClick={this.backbtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
            <div class="bg-white">
              <div className="row borderbtnm px-2 mx-0">
                <div className="col padd-0 px-3">
                  <h4 className="headertitlepad float-left">All Column</h4>
                </div>

                <div className="col padd-0">
                  <h4 className="headertitlepad float-left px-2">
                    Column Ordering
                  </h4>
                </div>
              </div>

              <div className="row px-4">
                <div class="col-lg-6 boxsinput_group px-3 border-right px-3">
                  <div class="m-section">
                    <h3 class="m-section__heading">Fixed Column</h3>
                    <ul class="ddlist">
                      {this.state.items
                        .filter(
                          (accountcolumn) => accountcolumn.IsStatic == true
                        )
                        .map((value, Index) => (
                          <li>
                            <div class="col-lg-6 row">
                              <label class="">
                                <input
                                  type="checkbox"
                                  id={Index}
                                  checked
                                  key={`item-${Index}`}
                                  index={Index}
                                />
                                {value.ColumnName}
                              </label>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>

                  <div class="m-section">
                    <h3 class="m-section__heading">Custom Column</h3>
                    <ul class="ddlist">
                    {this.state.items
                        .filter((value) => value.IsStatic !== true) // Exclude fixed columns
                        .map((value, Index) => (
                          <li key={`item-${Index}`}>
                            <div class="col-lg-6 row">
                              <label class="">
                                <input
                                  type="checkbox"
                                  id={Index}
                                  defaultChecked={value.IsInclude}
                                  onChange={(e) =>
                                    this.updateBookedmeetingcustomList(e, value)
                                  }
                                />
                                {value.ColumnName}
                              </label>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group px-4">
                
                  <SortableList
                    newItems={this.state.newItems}
                    onSortEnd={this.onSortEnd}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedBookedMeetingCustomColumnPage = connect(
  mapState,
  actionCreators
)(BookedMeetingCustomColumnPage);
export { connectedBookedMeetingCustomColumnPage as BookedMeetingCustomColumnPage };
