import React from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import debounce from 'lodash/debounce';
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails,DomainValidation,URLValidation } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";


class AddAccountPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      fields: {},
      errors: {},
      pager: {},
      pageOfItems: [],
      files: [],
      list: [],
      columns: [],
      reset: false,
      csvData: [],
      MeetingOwner: [],
      AccountStatus: [],
      SalesreplyAccountType: [],
      SalesreplyAccountCategory: [],
      SalesreplyAccountStatus: [],
      ReasonUnqualified: [],
      AccountCustomField: [],
      fileds: {},
      errors: {},
      statusAvailable: null,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      UserIDby: Details.ChildUserID,
      Role: Details.Role,
      InvalidDomain:false,
      IsButtonDisabled:false,
      AccountStatusID:"",

      FormData:{
        AccountName:"",
        AccountNameLong:"",
        EmailDomain:"",
        Website:"",
        AccountOwnerID:"",
        AccountCategoryID:"",
        AccountStatusID:"",
        Industry:"",
        Address1: "",
        Address2: "",
        City:"",
        State:"",
        Country:"",
        Zip:"",
        ReasonUnqualifiedID:"",
        AccountTypeID: this.props.location.state?.AccountType == null ? "1" : this.props.location.state?.AccountType,
        AccountClientStatusID:"",
        CompanyPhone:"",
        CompanyRevenue:"",
        EmployeeCount:"",
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        UserIDby: Details.ChildUserID,
        CreatedBy: Details.ChildUserID,
        Role: Details.Role,
        LastUpdatedBy:null,
        CreatedDate:new Date(),
        LastUpdatedDate: null,
        IsAccountdetailsUpdate: false,
        SalesReplyAccountID: null,


      },
      IsLoading:false,
      DomainExists:true,
      GenericDomainExists:true
    };
    // this.GetAccountStatus();
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
     // Create debounced versions of the event handlers
     this.debouncedInputChange = debounce(this.HandleInputChange, 300); // Adjust the debounce time as needed
     this.debouncedBlur = debounce(this.HandleBlur, 300); // Adjust the debounce time as needed
  }

  componentWillMount(){
    this.GetAccountStatus();
  }

  componentDidMount() {
    document.title = "Add | SalesHive";
  }

  GetAccountStatus() {
    this.setState({IsLoading:true})
    let data = {
      ClientID: this.state.ClientID,
      UserID:this.state.UserID,
      Role:this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/GetAllStatus",
      method: "POST",
      data: data,
    }).then((res) => {
      if(res.data.StatusMessage === "SUCCESS"){
        this.setState({
          MeetingOwner: res.data.MeetingOwner,
          AccountStatus: res.data.AccountStatus,
          SalesreplyAccountType: res.data.SalesreplyAccountType,
          SalesreplyAccountCategory: res.data.SalesreplyAccountCategory,
          SalesreplyAccountStatus: res.data.SalesreplyAccountStatus,
          ReasonUnqualified: res.data.ReasonUnqualified,
          AccountCustomField: res.data.AccountCustomField,
        });
        this.setState({AccountStatusID:res.data.AccountStatus[0]?._id})
      }else{
        this.setState({
          MeetingOwner: [],
          AccountStatus: [],
          SalesreplyAccountType: [],
          SalesreplyAccountCategory: [],
          SalesreplyAccountStatus: [],
          ReasonUnqualified: [],
          AccountCustomField: [],
        });
        this.setState({AccountStatusID:null});
        toast.error(res.data.Message)
      }
      this.setState({IsLoading:false})
    });
  }

  // async FromValidation() {
  //   this.setState({ InvalidDomain: false });
  //   let formIsValid = true;
  //   let fields = this.state.fields;
  //   let errors = {};

  //   var Domain = document.getElementById("Domain").value.trim();
  //   var IsExist = await this.CheckDomain(Domain)
  //   if (Domain == "") {
  //     formIsValid = false;
  //     errors["Domain"] = "Please enter email domain.";
  //   }else{
  //     if(this.state.InvalidDomain==true){
  //       formIsValid = false;
  //       errors["Domain"] = "Invalid email domain";
  //     }else{
  //       try {
  //         const domainExists = await this.CheckDomainExists(Domain);
    
  //         if (domainExists > 0) {
  //           errors["Domain"] = "Email Already Exists";
  //           this.setState({ errors: errors });
  //           return true;
  //         } else {
  //           errors["Domain"] = "";
  //           this.setState({ errors: errors });
  //           return false;
  //         }
  //       } catch (error) {
  //         console.error("Error checking domain existence:", error);
  //         // Handle the error as needed
  //         return false;
  //       }
  //     }
  //   }
  //   if(IsExist == true){
  //     formIsValid = false;
  //   }
  //   if(this.state.InvalidDomain==true){
  //     formIsValid = false;
  //     errors["Domain"] = "Invalid email domain";
  //   }
  //   this.setState({ errors: errors });
  //   return formIsValid;
  // }

  //Check domain
  
//   async FromValidation() {
//     this.setState({ InvalidDomain: false });
//     let formIsValid = true;
//     let errors = {};
//     const Domain = document.getElementById("Domain").value.trim();
  
//     if (Domain === "") {
//       formIsValid = false;
//       errors["Domain"] = "Please enter an email domain.";
//     } else {
//       const domainValid = await this.CheckDomain(Domain);
  
//       if (domainValid) {
//         formIsValid = false;
//       } else {
//         try {
//           const domainExists = await this.CheckDomainExists(Domain);
  
//           if (domainExists > 0) {
//             formIsValid = false;
//             errors["Domain"] = "Email Already Exists";
//           }
//         } catch (error) {
//           console.error("Error checking domain existence:", error);
//           // Handle the error as needed
//           return false;
//         }
//       }
//     }
  
//     if (this.state.InvalidDomain) {
//       formIsValid = false;
//       errors["Domain"] = "Invalid email domain";
//     }
  
//     this.setState({ errors });
//     return formIsValid;
//   }
  
  
//   async CheckDomain(Domain) {
//     let regexp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
//     let Errors = {};
  
//     if (!regexp.test(Domain) && Domain !== "") {
//       Errors["Domain"] = "Invalid email domain";
//       this.setState({ errors: Errors });
//       this.setState({ InvalidDomain: true });
//       return true;
//     } else {
//       try {
//         const domainExists = await this.CheckDomainExists(Domain);
  
//         if (domainExists > 0) {
//           Errors["Domain"] = "Email Already Exists";
//           this.setState({ errors: Errors });
//           this.setState({ InvalidDomain: true });
//           return true;
//         } else {
//           Errors["Domain"] = "";
//           this.setState({ errors: Errors });
//           this.setState({ InvalidDomain: false });
//           return false;
//         }
//       } catch (error) {
//         console.error("Error checking domain existence:", error);
//         // Handle the error as needed
//         return false;
//       }
//     }
//   }
  

// async  handleChange(field, e) {
//     let fields = this.state.fields;
//     fields[field] = e.target.value;
//     let errors= {}
//     this.setState({ fields });
//     var Domain = document.getElementById("Domain").value.trim();
//     if (fields.Domain != "") {
//       this.state.errors.Domain = null;
//       // this.state.statusAvailable = null;
//       this.CheckDomain(Domain);
//       try {
//         const domainExists = await this.CheckDomainExists(Domain);
//         if (domainExists !== 0) {
//           errors["Domain"] = "Email Already Exists";
//           this.setState({errors:errors})
//         }else{
//           errors["Domain"] = "";
//           this.setState({errors:errors})
//         }
//         
//       } catch (error) {
//         console.error("Error checking domain existence:", error);
//         // Handle the error as needed
//       }
//     } else {
//       // this.state.statusAvailable = null;
//       this.state.errors.Domain = null;
//     }
//   }

async FromValidation(Domain,fromSave = false) {
  this.setState({ InvalidDomain: false });
  let domain = fromSave === false ? Domain : this.state.Domain
  let formIsValid = true;
  let errors = {};
  // if(fromSave === false)
  // const { Domain } = this.state; // Assuming Domain is a state property 
  // }

  if (domain === "") {
    formIsValid = false;
    errors["Domain"] = "Please enter an email domain.";
  } else {
    const domainValid = await this.CheckDomain(domain);

    if (domainValid) {
      formIsValid = false;
    } else {
      try {
        const domainExists = await this.CheckDomainExists(domain);

        if (domainExists > 0) {
          formIsValid = false;
          errors["Domain"] = "Email Already Exists";
        }
      } catch (error) {
        console.error("Error checking domain existence:", error);
        // Handle the error as needed
        return false;
      }
    }
  }

  if (this.state.InvalidDomain) {
    formIsValid = false;
    errors["Domain"] = "Invalid email domain";
  }

  this.setState({ errors });
  return formIsValid;
}

async CheckDomain(Domain) {
  let regexp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
  let Errors = {};

  if (!regexp.test(Domain) && Domain !== "") {
    Errors["Domain"] = "Invalid email domain";
    this.setState({ errors: Errors });
    this.setState({ InvalidDomain: true });
    return true;
  } else {
    try {
      const domainExists = await this.CheckDomainExists(Domain);

      if (domainExists > 0) {
        Errors["Domain"] = "Email Already Exists";
        this.setState({ errors: Errors });
        this.setState({ InvalidDomain: true });
        return true;
      } else {
        Errors["Domain"] = "";
        this.setState({ errors: Errors });
        this.setState({ InvalidDomain: false });
        return false;
      }
    } catch (error) {
      console.error("Error checking domain existence:", error);
      // Handle the error as needed
      return false;
    }
  }
}

handleChange(field, e) {
  let fields = this.state.fields;
  fields[field] = e.target.value;
  this.setState({ fields });

  const Domain = fields.Domain.trim(); // Assuming Domain is part of the state

  if (Domain !== "") {
    this.setState({ errors: { Domain: null } }); // Reset the error for Domain
    this.CheckDomain(Domain)
      .then(async (domainValid) => {
        if (domainValid) {
          return;
        }
        try {
          const domainExists = await this.CheckDomainExists(Domain);
          const errors = {};

          if (domainExists > 0) {
            errors["Domain"] = "Email Already Exists";
          } else {
            errors["Domain"] = "";
          }
          this.setState({ errors });
        } catch (error) {
          console.error("Error checking domain existence:", error);
          // Handle the error as needed
        }
      });
  } else {
    this.setState({ errors: { Domain: null } });
  }
}


  CheckExistAccountClientName(AccountName) {
    var str_in = {
      ClientID: this.state.ClientID,
      Role:this.state.Role,
      Name: AccountName,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/AccountStatusExists",
      method: "POST",
      data: str_in,
    }).then((resdata) => {
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.data.length > 0) {
          this.setState({ statusAvailable: resdata.data.Data });
        }
      }else{
          toast.error(resdata.data.Message)
      }
    });
  }

  backbtn() {
    history.push("/accounts");
  }

  //save email
  async savebtn(e) {
  
    this.setState({IsButtonDisabled:true});
    var EmailDomins = document.getElementById("Domain").value;
    var FormValidationFlag = await this.FromValidation()
    if (FormValidationFlag === true) {
    var AccountCategoryID = document.getElementById("AccountCategoryID").value;
    var AccountClientStatusID = document.getElementById(
      "AccountClientStatusID"
    ).value;
    
    var AccountName = document.getElementById("AccountName").value;
    var AccountNameLong = document.getElementById("AccountNameLong").value;
    var AccountOwnerID = document.getElementById("AccountOwnerID").value;
    var AccountStatusID = document.getElementById("AccountStatusID").value;
    if(AccountStatusID !== ""){
      var parsedOption = JSON.parse(AccountStatusID);
    }
    var Address1 = document.getElementById("Address1").value;
    var Address2 = document.getElementById("Address2").value;
    var City = document.getElementById("City").value;
    var EmployeeCount = document.getElementById(
      "EmployeeCount"
    ).value;
    var CompanyPhone = document.getElementById("CompanyPhone").value;
    var CompanyRevenue = document.getElementById("CompanyRevenue").value;
    var Country = document.getElementById("Country").value;
    var EmailDomins = document.getElementById("Domain").value;
    // var FileName = document.getElementById("FileName").value;
    var Industry = document.getElementById("Industry").value;
    var ReasonUnqualifiedID = document.getElementById(
      "ReasonUnqualifiedID"
    ).value;
    var AccountTypeID = document.getElementById("AccountTypeID").value;
    // var StateteStandardized = document.getElementById(
    //   "StateteStandardized"
    // ).value;
    // var Unqualified = document.getElementById("Unqualified").value;
    var Website = document.getElementById("Website").value;
    var Zip = document.getElementById("Zip").value;
    // var IsAccountdetailsUpdate = document.getElementById(
    //   "IsAccountdetailsUpdate"
    // ).value;
    var State = document.getElementById("State").value;

// 
    var AccountCustomFieldValueArray = []
    
    this.state.AccountCustomField.map((value) => {
   let customval = document.getElementById(value._id)?.value;
   let customvalid= value._id;
  
      if(customval.length !== 0 ){
        AccountCustomFieldValueArray.push({
          customval,
          customvalid
        })
      }
    })


      var data = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        AccountCategoryID: AccountCategoryID,
        AccountClientStatusID: AccountClientStatusID,
        AccountName: AccountName,
        AccountNameLong: AccountNameLong,
        AccountOwnerID: AccountOwnerID,
        AccountStatusID:AccountStatusID===""?"": parsedOption.SalesReplyAccountStatusID,
        AccountTypeID: AccountTypeID,
        Address1: Address1,
        Address2: Address2,
        City: City,
        EmployeeCount: EmployeeCount,
        CompanyPhone: CompanyPhone,
        CompanyRevenue: CompanyRevenue,
        Country: Country,
        EmailDomins: EmailDomins,
        // FileName: FileName,
        Industry: Industry,
        IsDeleted: false,
        ReasonUnqualifiedID: ReasonUnqualifiedID,
        // SalesReplyAccountID: SalesReplyAccountID,
        // StateteStandardized: StateteStandardized,
        // TotalCallCount: TotalCallCount,
        // Unqualified: Unqualified,
        Website: Website,
        Zip: Zip,
        IsAccountdetailsUpdate: false,
        SalesReplyAccountID: null,
        State: State,
        CreatedBy: this.state.UserIDby,
        CreatedDate: new Date(),
        LastUpdatedBy: null,
        Role:this.state.Role,
        LastUpdatedDate: null,
        AccountCustomDetails:AccountCustomFieldValueArray,
        Status:AccountStatusID===""?"":parsedOption.Status
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/AddAccount",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div>
              Add Accounts<br/>
              Account added successfully.
            </div>
          );
          history.push("/accounts");
        } else {
          toast.error(res.data.Message);
          this.setState({IsButtonDisabled:false});
        }
      });
    }else{
      this.setState({IsButtonDisabled:false});
      //document.getElementById("submit").disabled = false;
    }
  }

  //Check Domain Exists 
  CheckDomainExists = async (Domain) => {
    const Inputparameters = {
      UserID: this.state.UserID,
      ClientID: this.state.ClientID,
      Domain: Domain,
      Role: this.state.Role,
    };
  
    try {
      const response = await Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/CheckExistEmailDomain",
        method: "POST",
        data: Inputparameters,
      });
  
      if (response.data.StatusMessage === "SUCCESS") {
        if(response.data.Data?.length > 0){
            // this.setState({DomainExists:false})
          return response.data.Data?.length;
        }else{
          return response.data.Data?.length;

        }
      }else{
        toast.error(response.data.Message)
      }
    } catch (error) {
      // Handle any errors that may occur during the request, e.g., network issues or server errors.
      console.error("Error in CheckDomainExists:", error);
      return 0; // Return an appropriate default value or handle the error as needed.
    }
  };
  
  // Check Generic Email Domain
  CheckGenericDomainExists = async (Domain) => {
    const Inputparameters = {
      ClientID: this.state.ClientID,
      GenericEmailDomain: Domain,
    };
  
    try {
      const response = await Axios({
        url: CommonConstants.MOL_APIURL + "/genericemaildomain/GenericEmailDomainExists",
        method: "POST",
        data: Inputparameters,
      });
  
      if (response.data.StatusMessage === "SUCCESS") {
        if(response.data.Data?.length > 0){
            // this.setState({DomainExists:false})
          return response.data.Data?.length;
        }else{
          return response.data.Data?.length;

        }
      }
    } catch (error) {
      // Handle any errors that may occur during the request, e.g., network issues or server errors.
      console.error("Error in CheckDomainExists:", error);
      return 0; // Return an appropriate default value or handle the error as needed.
    }
  };

  // Function to validate individual form field

ValidateField = async (fieldName, value) => {
  this.setState({ IsLoading: true });

  return new Promise(async (resolve) => {
    let Flag = true;
    this.setState((prevState) => {
      const newErrors = { ...prevState.errors };

      if (fieldName === "EmailDomain") {
        if (value.trim() === "") {
          newErrors.EmailDomain = "Please enter email domain.";
          Flag = false;
        } else {
          this.CheckDomainExists(value.trim()).then((result) => {
            
            if (!DomainValidation(value.trim())) {
              newErrors.EmailDomain = "Invalid email domain";
              Flag = false;
              this.setState({ DomainExists: false });
            } else if (result > 0) {
              newErrors.EmailDomain = "Email Already Exists";
              this.setState({ DomainExists: true });
              Flag = false;
            } else {
              // newErrors.EmailDomain = "";
              // this.setState({ DomainExists: false });
              // Flag = true;

              // Generict Emails domain 
          this.CheckGenericDomainExists(value.trim()).then((result) => {
            
            if (result > 0) {
              newErrors.EmailDomain = "Domain is Generic Email Domain.";
              this.setState({ GenericDomainExists: true });
              Flag = false;
            } else {
              newErrors.EmailDomain = "";
              this.setState({ GenericDomainExists: false });
              this.setState({ DomainExists: false });
              Flag = true;
            }

            resolve(Flag);
          });
            }

            resolve(Flag);
          });

        }
      } else if (fieldName === "Website") {
        if (value.trim() !== "") {
          if (!DomainValidation(value.trim())) {
            newErrors.Website = "Invalid Website";
            Flag = false;
          } else {
            newErrors.Website = "";
            Flag = true;
          }
        }

        resolve(Flag);
      }
      this.setState({ errors: newErrors, IsLoading: false });
      return { errors: newErrors };
    });
  });
};


    //Update OnChange Handler
    HandleInputChange = (e) => {
      const { name, value} = e.target;
      // console.log(e.target)
      // console.log(value)
      // if(name === "AccountStatusID" && value.trim() !== ""){
      //   console.log(value)
      //     let Obj = JSON.parse(value);
      //     console.log(Obj)
      //     this.setState((prevState) => {
      //       let updatedFormData = { ...prevState.FormData };
      //         updatedFormData['AccountStatusID'] = Obj._id;
      //         updatedFormData['Status'] = Obj.Status;
      //       return {
      //         FormData: updatedFormData,
      //       };
      //     });
      // }
      this.setState((prevState) => {
        // 
        // 
        let updatedFormData = { ...prevState.FormData };
          updatedFormData[name] = value;
        return {
          FormData: updatedFormData,
        };
      });

    };
  
    //Update OnBlur Handler
    HandleBlur = (e) => {
      const { name, value } = e.target;
      
      this.ValidateField(name, value);
    };
  
    //Validation field method
    ValidateMethod = (fieldName,value) => {
      let Flag = true;
      if (fieldName === "EmailDomain") {
        if (value.trim() === "") {
          Flag = false;
        } else {
          Flag = true;
        }
      } else if (fieldName === "Website") {
        if (value.trim() !== "") {
          if (!DomainValidation(value.trim())) {
            Flag = false;
          } else {
            Flag = true;
          }
        }
      } 
      return Flag;
    }

 
    HandleSubmit = async (e) => {
      
      this.setState({ IsButtonDisabled: true, IsLoading: true });
    
      e.preventDefault();
      const { FormData } = this.state;
    
      const fieldNames = Object.keys(FormData);
      const arr = [];
    
      fieldNames.forEach(fieldName => {
        this.ValidateField(fieldName, FormData[fieldName]);
        arr.push(this.ValidateMethod(fieldName, FormData[fieldName]));
      });
      const AccountCustomFieldValueArray = [];
    
      this.state.AccountCustomField.forEach((value) => {
        const customval = document.getElementById(value._id)?.value;
        const customvalid = value._id;
    
        if (customval.length !== 0) {
          AccountCustomFieldValueArray.push({
            customval,
            customvalid,
          });
        }
      });
    
      
      FormData['AccountCustomDetails'] = AccountCustomFieldValueArray;
      // FormData['Status'] = FormData.AccountStatusID === "" ? "" : parsedOption.Status;
      FormData['EmailDomins'] = FormData.EmailDomain;
      FormData['EmployeeCount'] = FormData.EmployeeCount;
      
      
         // Check if the trimmed value is not empty and is a valid number
            if (FormData.EmployeeCount !== "" && !isNaN(FormData.EmployeeCount)) {
              // If the value is a decimal, assign 0
              FormData.EmployeeCount = FormData.EmployeeCount?.includes(".") ? 0 : parseFloat(FormData.EmployeeCount);
            }

              //     // Check if the trimmed value is not empty and is a valid number
              //     if (FormData.CompanyEmployeeCount !== "" && !isNaN(FormData.CompanyEmployeeCount)) {
              //       // If the value is a decimal, assign 0
              //       FormData.CompanyEmployeeCount = FormData.CompanyEmployeeCount.includes(".") ? 0 : parseFloat(FormData.CompanyEmployeeCount);
              // }
  

      let revenueParts = FormData.CompanyRevenue.split("."); // Split the string into integer and decimal parts
      
      if (revenueParts.length === 2) { // Ensure there's a decimal part
          let decimalPart = revenueParts[1]; // Get the decimal part
          if (decimalPart.length >= 3) { // Ensure there are at least three digits after the decimal point
              let thirdDigit = parseInt(decimalPart[2]); // Get the third digit after the decimal point
              let secondDigit = parseInt(decimalPart[1]); // Get the second digit after the decimal point
      
              if (thirdDigit >= 5) { // If the third digit is greater than or equal to 5
                  secondDigit += 1; // Increment the second digit
              }
      
              // Reconstruct the revenue string with the adjusted second digit
              FormData.CompanyRevenue = revenueParts[0] + "." + decimalPart[0] + secondDigit;
          }
      }
      let checkdomain;
  
      // Email domain validation
      if (FormData.EmailDomain.trim() !== "") {
        if (DomainValidation(FormData.EmailDomain) === false) {
          this.setState(prevState => ({
            errors: {
              ...prevState.errors,
              EmailDomain: "Invalid email domain"
            },
            IsLoading: false,
            IsButtonDisabled: false
          }));
          return; // Stop execution if domain is invalid
        } else if (await this.CheckDomainExists(FormData.EmailDomain) > 0) {
            this.setState((prevState) => {
              const newErrors = { ...prevState.errors };
              newErrors.EmailDomain = "Email Already Exists";
              return { errors: newErrors };
            });
            this.setState({ IsLoading: false,IsButtonDisabled: false });
            return; // Stop execution if email already exists
        }else if (await this.CheckGenericDomainExists(FormData.EmailDomain) > 0) {
          this.setState((prevState) => {
            const newErrors = { ...prevState.errors };
            newErrors.EmailDomain = "Domain is Generic Email Domain.";
            return { errors: newErrors };
          });
          this.setState({ IsLoading: false,IsButtonDisabled: false });
          return; // Stop execution if generic 
      }  else {
            this.setState((prevState) => {
              const newErrors = { ...prevState.errors };
              newErrors.EmailDomain = "";
              return { errors: newErrors };
            });
        }
        
      }
      
      const isValid = !arr.includes(false);
     
            // Handle validation errors or other actions
         
      if (isValid && this.state.DomainExists === false) {
        // Form submission code (uncomment and complete this part)
        FormData.AccountStatusID = FormData.AccountStatusID !== "" ? FormData.AccountStatusID : this.state.AccountStatusID;
        

        Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/AddAccount",
          method: "POST",
          data: FormData,
        }).then((res) => {
          if (res.data.StatusMessage === "SUCCESS") {
            toast.success(
              <div>
                Add Accounts<br />
                Account added successfully.
              </div>
            );
            history.push("/accounts");
          } else {
            toast.error(res.data.Message);
            this.setState({ IsButtonDisabled: false });
            this.setState({ IsLoading: false });
          }
        });
      } else {
        // Handle validation errors or other actions
        this.setState({ IsButtonDisabled: false });
        this.setState({ IsLoading: false });
      }
    };
    
  

 
  render() {
    const { FormData, errors } = this.state;
    
    return (
      <>
      {
        this.state.IsLoading ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>
      }
         
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div className="bodyhome"> 
              <div className="row">
                <div className="col">
                  <h4 className="lg-headertitle py-4 mb-0">Add Account</h4>
                </div>
              </div>

              <div className="bg-white mb-3">
                <div className="row pt-3 pb-2 px-3">
                  <div className="col-lg-6 pb-3">
                    <div className="row max-cols pl-2">
                      <label className="col-lg-3 view-lable-meet ">
                        {" "}
                        Account Name
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter first name"
                          id="AccountName"
                          name="AccountName"
                          placeholder="Account Name"
                          type="text"
                          value={FormData.AccountName}
                          onChange={this.HandleInputChange}
                          onBlur={this.HandleBlur}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 pb-3">
                    <div className="row max-cols">
                      <label className="col-lg-3 view-lable-meet">
                        Account Name Long
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter first name"
                          id="AccountNameLong"
                          name="AccountNameLong"
                          placeholder="Account Name Long"
                          type="text"
                          value={FormData.AccountNameLong}
                          onChange={this.HandleInputChange}
                          onBlur={this.HandleBlur}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 pb-3">
                    <div className="row max-cols pl-2">
                      <label className="col-lg-3 view-lable-meet">
                        Email Domains
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter first name"
                          id="Domain"
                          name="EmailDomain"
                          placeholder="Email domains"
                          type="text"
                          // onBlur={this.handleChange.bind(this, "Domain")}
                          value={FormData.EmailDomain}
                          onChange={this.HandleInputChange}
                          onBlur={this.HandleBlur}
                        />
                        <span style={{ color: "red" }}>
                          {errors["EmailDomain"]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 pb-3">
                    <div className="row max-cols">
                      <label className="col-lg-3 view-lable-meet">Website</label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter first name"
                          id="Website"
                          name="Website"
                          placeholder="Website "
                          type="text"
                          value={FormData.Website}
                          onChange={this.HandleInputChange}
                          onBlur={this.HandleBlur}
                        />
                        <span style={{ color: "red" }}>
                          {errors["Website"]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 pb-3">
                    <div className="row max-cols d-flex align-items-center pl-2">
                      <label className="col-lg-3 view-lable-meet">
                        Account Owner
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          id="AccountOwnerID"
                          name="AccountOwnerID"
                          value={FormData.AccountOwnerID}
                          onChange={this.HandleInputChange}
                          onBlur={this.HandleBlur}
                        >
                          <option value="">--select--</option>
                          {this.state.MeetingOwner.map((val) => (
                            <option key={val._id} value={val._id}>{val.Name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 pb-3">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-3 view-lable-meet">
                        Account Category
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          id="AccountCategoryID"
                          name="AccountCategoryID"
                          value={FormData.AccountCategoryID}
                          onChange={this.HandleInputChange}
                          onBlur={this.HandleBlur}
                        >
                          <option value="">--select--</option>
                          {this.state.SalesreplyAccountCategory.map((val) => (
                            <option key={val._id} value={val._id}>
                              {val.Name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 pb-3">
                    <div className="row max-cols d-flex align-items-center pl-2">
                      <label className="col-lg-3 view-lable-meet">
                        Account Status
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          id="AccountStatusID"
                          name="AccountStatusID"
                          value={FormData.AccountStatusID}
                          onChange={this.HandleInputChange}
                          // onBlur={this.HandleBlur}
                        >
                          <option value="">--select--</option>
                          {this.state.AccountStatus.map((val) => (
                            <option key={val._id} defaultValue={val._id} value={val._id}>
                              {val.Status}
                            </option>
                            // <option key={val._id} defaultValue={val._id} value={JSON.stringify(val)}>
                            //   {val.Status}
                            // </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 pb-3">
                    <div className="row max-cols">
                      <label className="col-lg-3 view-lable-meet">Industry</label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter first name"
                          id="Industry"
                          name="Industry"
                          placeholder="Industry"
                          type="text"
                          value={FormData.Industry}
                          onChange={this.HandleInputChange}
                          onBlur={this.HandleBlur}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 pb-3">
                    <div className="row max-cols pl-2">
                      <label className="col-lg-3 view-lable-meet">Address 1</label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter first name"
                          id="Address1"
                          name="Address1"
                          placeholder="Address 1"
                          type="text"
                          value={FormData.Address1}
                          onChange={this.HandleInputChange}
                          onBlur={this.HandleBlur}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 pb-3">
                    <div className="row max-cols">
                      <label className="col-lg-3 view-lable-meet">Address 2</label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter first name"
                          id="Address2"
                          name="Address2"
                          placeholder="Address 2"
                          type="text"
                          value={FormData.Address2}
                          onChange={this.HandleInputChange}
                          onBlur={this.HandleBlur}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 pb-3">
                    <div className="row max-cols pl-2">
                      <label className="col-lg-3 view-lable-meet">City</label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter first name"
                          id="City"
                          name="City"
                          placeholder="City"
                          type="text"
                          value={FormData.City}
                          onChange={this.HandleInputChange}
                          onBlur={this.HandleBlur}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 pb-3">
                    <div className="row max-cols">
                      <label className="col-lg-3 view-lable-meet">State</label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter first name"
                          id="State"
                          name="State"
                          placeholder="State"
                          type="text"
                          value={FormData.State}
                          onChange={this.HandleInputChange}
                          onBlur={this.HandleBlur}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 pb-3">
                    <div className="row max-cols pl-2">
                      <label className="col-lg-3 view-lable-meet">Country</label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter first name"
                          id="Country"
                          name="Country"
                          placeholder="Country"
                          type="text"
                          value={FormData.Country}
                          onChange={this.HandleInputChange}
                          onBlur={this.HandleBlur}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 pb-3">
                    <div className="row max-cols">
                      <label className="col-lg-3 view-lable-meet">Zip</label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter first name"
                          id="Zip"
                          name="Zip"
                          placeholder="Zip"
                          type="text"
                          value={FormData.Zip}
                          onChange={this.HandleInputChange}
                          onBlur={this.HandleBlur}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 pb-3">
                    <div className="row max-cols d-flex align-items-center pl-2">
                      <label className="col-lg-3 view-lable-meet">
                        Reason Unqualified
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          id="ReasonUnqualifiedID"
                          name="ReasonUnqualifiedID"
                          value={FormData.ReasonUnqualifiedID}
                          onChange={this.HandleInputChange}
                          onBlur={this.HandleBlur}
                        >
                          <option value="">--select--</option>
                          {this.state.ReasonUnqualified.map((val) => (
                            <option key={val._id} value={val._id}>
                              {val.Reason}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 pb-3">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-3 view-lable-meet">Account Type</label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          id="AccountTypeID"
                          name="AccountTypeID"
                          value={FormData.AccountTypeID}
                          onChange={this.HandleInputChange}
                          onBlur={this.HandleBlur}
                        >
                          <option value="">--select--</option>
                          {this.state.SalesreplyAccountType.map((val) => (
                            <option key={val._id} value={val.FieldID}>
                              {val.FieldName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 pb-3">
                    <div className="row max-cols d-flex align-items-center pl-2">
                      <label className="col-lg-3 view-lable-meet">
                        Account Client Status
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          id="AccountClientStatusID"
                          name="AccountClientStatusID"
                          value={FormData.AccountClientStatusID}
                          onChange={this.HandleInputChange}
                          onBlur={this.HandleBlur}
                        >
                          <option value="">--select--</option>
                          {this.state.SalesreplyAccountStatus.map((val) => (
                            <option key={val._id} value={val._id}>{val.Name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 pb-3">
                    <div className="row max-cols">
                      <label className="col-lg-3 view-lable-meet">
                        Company Phone
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input n-isnun"
                          data-val="true"
                          data-val-required="Please enter first name"
                          id="CompanyPhone"
                          name="CompanyPhone"
                          placeholder="Company Phone"
                          type="number"
                          value={FormData.CompanyPhone}
                          onChange={this.HandleInputChange}
                          onBlur={this.HandleBlur}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 pb-3">
                    <div className="row max-cols pl-2">
                      <label className="col-lg-3 view-lable-meet">
                        Company Revenue
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter first name"
                          id="CompanyRevenue"
                          name="CompanyRevenue"
                          placeholder="Company Revenue"
                          type="number"
                          value={FormData.CompanyRevenue}
                          onChange={this.HandleInputChange}
                          onBlur={this.HandleBlur}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 pb-3">
                    <div className="row max-cols">
                      <label className="col-lg-3 view-lable-meet">
                        Employee Count
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter first name"
                          id="EmployeeCount"
                          name="EmployeeCount"
                          placeholder="Employee Count"
                          type="number"
                          value={FormData.EmployeeCount}
                          onChange={this.HandleInputChange}
                          onBlur={this.HandleBlur}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-lg-6 pb-3">
                              <div className="row max-cols">
                                  <label className="col-lg-4 view-lable-meet">Last Name</label>
                                  <div className="col-lg-8">
                                      <input className="form-control m-input" id="LastName" name="LastName" placeholder="Last name" type="text" value="" />
                                  </div>
                              </div>
                          </div> */}
                </div>

              </div>
              {/* <div className="row borderbtnm mt-5">
                <div className="col">
                  <h4 className="headertitlepad nonhed">
                    Custom Contact Field
                  </h4>
                </div>
              </div>
              <div className="row borderbtnm pb-5">
                <div className="col-lg-6 pb-3">
                  <div className="row max-cols">
                    {this.state.AccountCustomField?.map((value, index) => {
                      return (
                        <div className="col-lg-6 pb-3">
                          <div
                            className="row max-cols d-flex align-items-center"
                            key={index}
                          >
                            <label className="col-lg-4">
                              {value.FieldName}
                            </label>
                            <div className="col-lg-7">
                              <input
                                className="form-control m-input"
                                id={value._id}
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div> */}
              {
                this.state.AccountCustomField?.length !=0 ? <>
                <div className="bg-white p-3 mb-3">
                  <div className="row">
                    <div className="col-lg-12 col-sm-4">
                      <h3 className="xs-headertitle py-3">
                        Custom Contact Field
                      </h3>
                    </div>
                  </div> 
              <div className="row border-top py-4">
              {this.state.AccountCustomField?.map((value, index) => {
                return (
                  <div className="col-lg-6 pb-3" key={index}>
                    <div
                      className="row max-cols d-flex align-items-center"
                      key={index}
                    >
                      <label className="col-lg-4">{value.FieldName}</label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id={value._id}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              </div>
              <div className="row"></div>
              </div>
                </>:<></>
              }
              
 
              <div className="row py-3 mb-3">
                <div className="col-lg-12">
                  <button
                    id="submit"
                    onClick={this.HandleSubmit}
                    disabled={this.state.IsButtonDisabled}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    onClick={this.backbtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div> 

          </div>
          <Footer />
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddAccountPage = connect(
  mapState,
  actionCreators
)(AddAccountPage);
export { connectedAddAccountPage as AddAccountPage };
