import React, { useEffect } from "react";
import Axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@mui/material/TableFooter';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../StickyHeader/StickyHeader";

import Paper from '@material-ui/core/Paper';

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";

import loadingicon from "../../../images/loading.gif";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export default function AccountAllTable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CampaignName");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [TotalAll, SetTotalAll] = React.useState({});
  const [MaxStep, SetMaxStep] = React.useState([]);
  const [SumAll, SetSumAll] = React.useState(0);
  const [SumAllSend, SetSumAllSend] = React.useState(0);

  const [IsLoading,SetIsLoading] = React.useState(false);
  const [CUserID,SetCUserID] = React.useState(0)
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    document.title = "Campaign Summary | SalesHive"
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
   
    CampaignSummaryGet(Details.ClientID,Details.ParentUserID,Details.Role);
  }, [Search, Page, RowsPerPage]);

  // Client login
  const CampaignSummaryGet = (CID,UID,URole) => {
    SetIsLoading(true)
    var InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole

    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/campaign_summary/demoSummary",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetTotalAll(Result.data.TotalAll);
      SetMaxStep(Result.data.MaxStep);
      SetSumAll(Result.data.Total);
      SetSumAllSend(Result.data.TotalSend)
      SetIsLoading(false)
      setIsDataLoaded(true);

    });
  };

 

 //search for record
 const RequestSearch = (Event) => {
  if (Event.key === "Enter") {
    var SearchedVal = document.getElementById("search").value;
    SetSearch(SearchedVal);
    SetPage(1);
  }
};

//change Page
const HandleChangePage = (Event, NewPage) => {
  SetPage(NewPage);
};

//change display rows
const ChangeRowSelected = (Event) => {
  SetRowsPerPage(Event.target.value);
  SetPage(1);
};

//get sort field data
const SortData = (Field) => {
  SetIsLoading(true)

  var SearchedVal = document.getElementById("search").value;
  var SerchBox;
  if (SearchedVal == "") {
    SerchBox = false;
    SetSflag(false);
    var SortField = Field;
    var SortBy;
    if (SortedBy == 1) {
      SortBy = -1;
      SetSortedBy(-1);
    } else {
      SortBy = 1;
      SetSortedBy(1);
    }
    SetSortField(Field);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      SerchBox: false,
      Archive: false,
      Sort: true,
      Field: SortField,
      Search: SearchedVal,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role
    };
    const AccountCategoriesList = Axios({
      url:CommonConstants.MOL_APIURL +"/campaign_summary/demoSummary",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetTotalAll(Result.data.TotalAll);
      SetMaxStep(Result.data.MaxStep);
      SetSumAll(Result.data.Total);
      SetSumAllSend(Result.data.TotalSend)
      SetIsLoading(false)

    });
  } else {
    SerchBox = true;
    SetSflag(true);
    var SortField = Field;
    var SortBy;
    if (SortedBy == 1) {
      SortBy = -1;
      SetSortedBy(-1);
    } else {
      SortBy = 1;
      SetSortedBy(1);
    }
    SetSortField(Field);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Search: SearchedVal,
      SerchBox: SerchBox,
      Archive: false,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role
    };
    const AccountCategoriesList = Axios({
      url:CommonConstants.MOL_APIURL +"/campaign_summary/demoSummary",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetTotalAll(Result.data.TotalAll);
      SetMaxStep(Result.data.MaxStep);
      SetSumAll(Result.data.Total);
      SetSumAllSend(Result.data.TotalSend)
      SetIsLoading(false)

    });
  }
};

  return (
    <>
        {IsLoading ? <><div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div></>:<></>}
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
    <TableContainer component={Paper}>
    <StyleHeader isDataLoaded={isDataLoaded} />
      <Table className="table-ref" aria-label="collapsible table">
        <TableHead>
          <TableRow> 
            <TableCell onClick={() => {SortData("CampaignName");}}>
            Campaign<span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "CampaignName" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "CampaignName" ? "active" : null} />
                    </span>
          </TableCell> 
            <TableCell onClick={() => {SortData("Uncontacted");}}>
            Uncontacted<span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "Uncontacted" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "Uncontacted" ? "active" : null} />
                    </span>
           </TableCell>
            {MaxStep.map((item)=>{
              return (<TableCell>Step {item}</TableCell>)
            })}
            {/* <TableCell>Step 1</TableCell>
            <TableCell>Step 2</TableCell>   */}
            <TableCell onClick={() => {SortData("Replies");}}>
            Replies<span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "Replies" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "Replies" ? "active" : null} />
                    </span>
             </TableCell> 
            <TableCell onClick={() => {SortData("Bounce");}}>
            Bounce<span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "Bounce" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "Bounce" ? "active" : null} />
                    </span>
            </TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
          {Rows.map((row) => (
            <TableRow key={row._id} row={row}>
            <TableCell>{row.CampaignName}</TableCell> 
            <TableCell>{row.Uncontacted !== undefined && row.Uncontacted !== null?row.Uncontacted:0}</TableCell> 
            {MaxStep.map((item)=>{
              return (<TableCell>{row["Step"+item] !== undefined?row["Step"+item]:0}</TableCell>)
            })}
            <TableCell>{row.Replies !== undefined && row.Replies !== null?row.Replies:0}</TableCell> 
            <TableCell>{row.Bounce !== undefined && row.Bounce !== null?row.Bounce:0}</TableCell> 

              </TableRow> 
          ))}
        </TableBody>
        <TableFooter>
        <TableRow> 
            <TableCell>Total</TableCell>
            <TableCell>{TotalAll.Uncontacted !== undefined && TotalAll.Uncontacted !== null?TotalAll.Uncontacted:0}</TableCell>
            {MaxStep.map((item)=>{
              return (<TableCell>{TotalAll["Step"+item] !== undefined?TotalAll["Step"+item]:0}</TableCell>)
            })}
            <TableCell>{TotalAll.Reply !== undefined && TotalAll.Reply !== null?TotalAll.Reply:0}</TableCell>
            <TableCell>{TotalAll.Bounce !== undefined && TotalAll.Bounce !== null ?TotalAll.Bounce:0}</TableCell>
          </TableRow>
          <TableRow> 
            <TableCell>%</TableCell> 
            <TableCell>{SumAll === 0? "0%":(Math.round(((TotalAll.Uncontacted)/SumAll)*100))+ "%"}</TableCell>
            {MaxStep.map((item)=>{
              return (<TableCell>{SumAllSend === 0? "0%":(Math.ceil(((TotalAll["Step"+item])/SumAllSend)*100))+ "%"}</TableCell>)
            })}
            <TableCell>{SumAllSend === 0? "0%":(Math.ceil((TotalAll.Reply/SumAllSend)*100))+ "%"}</TableCell> 
            <TableCell>{SumAllSend === 0? "0%":(Math.ceil((TotalAll.Bounce/SumAllSend)*100))+ "%"}</TableCell> 
          </TableRow>
          </TableFooter>
      </Table>
    </TableContainer>
    </div>

      <div class="row pb-2">
        <div class="col dataTables_info">
          <p>
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries
          </p>
        </div>
        <div class="col pageright">
          <Pagination
            component="div"
            count={CountPage}
            page={Page}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </>
  );
}
