import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import { CSVReader, CSVDownloader } from "react-papaparse";

import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ReactApexchart from "react-apexcharts";
//chart
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import ReactApexChart from "react-apexcharts";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { GetClientDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
toast.configure();
import loadingicon from "../../../images/loading.gif";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable({ FromDateAccount, ToDateAccount }) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDt");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [Notes, SetNotes] = React.useState([]);
  const [ResponderData, SetResponderData] = React.useState([]);
  const [OldClients, SetOldClients] = React.useState(false);
  const [ResponderID, SetResponderID] = React.useState("");
  const [ExportData, SetExportData] = React.useState([]);
  const [AddArray, SetAddArray] = React.useState([]);
  const [TotalSentDomain, SetTotalSentDomain] = React.useState(0);
  const [TotalReceivedDomain, SetTotalReceivedDomain] = React.useState(0);
  const [TotalMeeting, SetTotalMeeting] = React.useState(0);
  const [ResponsePer, SetResponsePer] = React.useState(0);
  const [ResponsetoMeetingPer, SetResponsetoMeetingPer] = React.useState(0);
  const [TotalSent, SetTotalSent] = React.useState([]);
  const [TotalResponse, SetTotalResponse] = React.useState([]);
  const [WeekDay, SetWeekDay] = React.useState([]);
  const [TotalMeetingList, SetTotalMeetingList] = React.useState([]);
  const [CName, SetCName] = React.useState("");

  const [IsLoading,SetIsLoading] = React.useState(false);
  const [CUserID,SetCUserID] = React.useState(0)

  const classes = useRowStyles();

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
      
    }
    GetClientDetails(Details.ClientID).then((result) => {
      SetCName(result[0].Name);
    });
    AccountExportHandler(Details.ClientID, FromDateAccount, ToDateAccount,Details.Role);
    KpiAccountGet(
      Details.ClientID,
      Details.ParentUserID,
      Details.Role,
      FromDateAccount,
      ToDateAccount
    );
    MeetingKeyMatricGet(Details.ClientID, FromDateAccount, ToDateAccount,Details.Role)
  }, [Search, Page, RowsPerPage, FromDateAccount, ToDateAccount, ResponderID, OldClients]);

  charts(FusionCharts);

  const MeetingKeyMatricGet = (CID, FromDates, ToDates,role) => {
   // SetIsLoading(true)
    const InputParameters = {
      ClientID: CID,
      ToDate: ToDates,
      FromDate: FromDates,
      Role:role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiAccountKeyMatric",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      
      if (Res.data.StatusMessage == "SUCCESS") {
        if (Res.data.Data.length > 0) {
          var lstTotalSent = [];
          var lstTotalResponse = [];
          var lstTotalMeeting = [];
          var lstWeekDay = [];
          var objtotalmeeting = 0;
          var objtotalSentDomain = 0;
          var objtotalReceivedDomain = 0;
          for (var i = 0; i < Res.data.Data.length; i++) {
            var totdaldomain = Res.data.Data[i].TotalAccount;
            objtotalSentDomain = objtotalSentDomain + totdaldomain;

            var totalReceivedDomain = Res.data.Data[i].ReceivedDomain;
            objtotalReceivedDomain = objtotalReceivedDomain + totalReceivedDomain;

            var totalmeeting = Res.data.Data[i].TotalEvents;
            objtotalmeeting = objtotalmeeting + totalmeeting;

            var objWeekDay = Res.data.Data[i].WeekDay;
            lstTotalSent.push(totdaldomain);
            lstTotalResponse.push(totalReceivedDomain)
            lstTotalMeeting.push(totalmeeting)
            lstWeekDay.push(objWeekDay)
          }
          SetTotalSentDomain(objtotalSentDomain)
          SetTotalReceivedDomain(objtotalReceivedDomain)
          SetTotalMeeting(objtotalmeeting);
          SetTotalMeetingList(lstTotalMeeting)
          SetTotalSent(lstTotalSent)
          SetTotalResponse(lstTotalResponse)
          SetWeekDay(lstWeekDay)
          //SetIsLoading(false)

          if (objtotalSentDomain > 0 && objtotalReceivedDomain > 0) {
            SetResponsePer(Math.round(objtotalReceivedDomain * 100 / objtotalSentDomain))
          }

          if (objtotalReceivedDomain > 0 && objtotalmeeting > 0) {
            SetResponsetoMeetingPer(Math.round(objtotalmeeting * 100 / objtotalReceivedDomain));
        }

        } else {
          SetTotalSentDomain(0)
          SetTotalReceivedDomain(0)
          SetTotalMeeting([])
          SetTotalSent([])
          SetTotalResponse([])
          SetWeekDay([])
          SetIsLoading(false)

        }
      }
      else {
        SetTotalSentDomain(0)
        SetTotalReceivedDomain(0)
        SetTotalMeeting([])
        SetTotalSent([])
        SetTotalResponse([])
        SetWeekDay([])
       // SetIsLoading(false)

      }
    });
  };
  const AccountState = {
    series: [
      {
        name: "Sent",
        color: "#360947",
        data: TotalSent,
      },
      {
        name: "Responses",
        color: "#F8BF58",
        data: TotalResponse,
      },
      {
        name: "Meetings",
        color: "#20BF55",
        data: TotalMeetingList,
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
        toolbar: {
            show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2, 2, 2],
        curve: 'smooth'
      },
      xaxis: {
        categories: WeekDay,
        title: {
          text: "Weeks",
        },
      },
      yaxis: {
        title: {
          text: "Count",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return  val ;
          },
        },
      },
      legend: {
        position: "right",
      },
      markers: {
        size: 4,
        strokeWidth: 1.5,
        hover: {
          size: 6 // Size of the marker when hovered
        }
      }
    },
  };

  // kpiMeeting get list
  const KpiAccountGet = (CID, UID, URole, FromDates, ToDates) => {
    SetIsLoading(true)

    var KipsAccountData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      ToDate: ToDates,
      FromDate: FromDates,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiAccountListGet",
      method: "POST",
      data: KipsAccountData,
    });
    rows1.then((result) => {
      if(result.data.StatusMessage ="SUCCESS")
      {
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
        SetIsLoading(false)
      }
      else
      {

        SetRows([]);
        SetRlen(0);
        SetFlen(0);
        SetCountPage(0);
        SetIsLoading(false)
      }
      

    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };



  //View Meeting
  const ViewPage = (id) => {
    history.push("/viewbookedmeeting", id);
  };

  // all check handler
  const CheckHandler = (e) => {
    {
      Rows?.map((val) => {
        if (e.target.checked) {
          document.getElementById(val?.SalesReplyAccountID).checked = true;
        } else if (!e.target.checked) {
          document.getElementById(val?.SalesReplyAccountID).checked = false;
        }
      });
    }
  };

  //Export Account
  const AccountExportHandler = (CID, FromDates, ToDates,role) => {
    let InputParameter = {
      ClientID: CID,
      ToDate: ToDates,
      FromDate: FromDates,
      Role:role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpisAccountExport",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      
      SetExportData(res.data.Data);
    });
  };


  //Check box update data
  const CheckBoxClick = (e, row) => {
    if (e.target.checked) {
      var temArray = AddArray;
      temArray.push(row);
      SetAddArray(temArray);
    } else if (!e.target.checked) {
      AddArray.splice(row, 1);
    }
  };

  // Account add to blacklist Domain
  const AccountAddToBlackList = () => {
    SetIsLoading(true)
    var AccountParamData = {
      ClientID: ClientID,
      LastUpdatedBy: UserID,
      AccountBlackListArray: AddArray,
      Role: Role,
      UserID:UserID
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/AccountToAddToBlackList",
      method: "POST",
      data: AccountParamData,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        SetIsLoading(false)
        SetAddArray([])
        toast.success("Add BlackList successfully.BlackListed!");
        {
          Rows?.map((val) => {
              document.getElementById(val?.SalesReplyAccountID).checked = false;
          });
        }
      } else {
        toast.error("Accounts already blacklisted.BlackListed!");
        {
          Rows?.map((val) => {
              document.getElementById(val?.SalesReplyAccountID).checked = false;
          });
        }
        SetIsLoading(false)
        SetAddArray([])
      }
    });
  };

  const ExportCSV = () => {

  }
  return (
    <>
    {IsLoading ? <><div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div></>:<></>}
      <div className="row colfive ">
        <div className="col-xl-2">
          <div class="bg-white boxcardcounter align-center">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{TotalSentDomain}</span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Sent</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <div class="bg-white boxcardcounter align-center">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{ResponsePer}%</span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Response %</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <div class="bg-white boxcardcounter align-center">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{TotalReceivedDomain}</span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Responses</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <div class="bg-white boxcardcounter align-center">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{ResponsetoMeetingPer}%</span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Response-to-Meeting %</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <div class="bg-white boxcardcounter align-center">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{TotalMeeting}</span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Meetings</string>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row pt-3 px-2"> 
        <div class="col-xl-12 bg-white p-3">
          <div id="chart chart-max450">
            <ReactApexchart
              options={AccountState.options}
              series={AccountState.series}
              type="line"
              height={450}
            />
          </div>
        </div> 
      </div> 
      <div className='bg-white p-3 my-2'>
      <div className="row align-items-center mx-0 py-3">
        <div className="col">
          <h4 className="headertitlenop float-left pl-0">Company Details</h4>
          {Rows.length === 0 ? (
            <></>
          ) : (
            <div className="listing-li float-right padb-15">
              <ul>
                <li>
                  <a
                    onClick={AccountAddToBlackList}
                    className="btn btngroup m-btn activebloack"
                  >
                    <i class="la la-icon-font-size-13 la-ban"></i>
                    <span>Blacklist All</span>
                  </a>
                </li>
                <li>
                <CSVDownloader className="px-0"
                   data={ExportData}
                   filename={CName +"-CompanyDetail"}
                   bom={true}>
                  <a
                  onClick={()=>{ExportCSV();}}
                    className="btn btngroup"
                  >
                    
                    <i class="la la-icon-font-size-13 la-download"></i> <span>Export</span>

                  
                  </a>
                  </CSVDownloader>
                </li>
              </ul>
            </div>
          )}

        </div>
      </div>
      <div className="row mx-0">
      <div className="w-100">
        <div className="row mx-0">
          <div className="col-sm-12 col-md-6">
            <label className="textlabelte">
              Show
              <select
                name="tbl_meeting_length"
                onChange={ChangeRowSelected}
                aria-controls="tbl_meeting"
                class="form-control form-control-sm"
                value={RowsPerPage}
              >
                {CommonConstants.show_rows.map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
              entries
            </label>
          </div>
          <div className="col-sm-12 col-md-6 full-right">
            <label class="textlabelte">
              Search:
              <input
                type="Search"
                id="search"
                onKeyPress={RequestSearch}
                class="form-control form-control-sm ml-2"
                placeholder=""
                aria-controls="tbl_meeting"
              />
            </label>
          </div>
        </div>
        <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <input type="checkbox" className="mx-auto"
                    onChange={(e) => CheckHandler(e)}
                  />
                </TableCell>
                <TableCell>  Domain </TableCell>
                <TableCell>  Account Name </TableCell>
                <TableCell>  Date Contacted </TableCell>
                <TableCell>  Date Responded</TableCell>
                <TableCell>  Emails Sent </TableCell>
                <TableCell>  Contacts Contacted </TableCell>
                <TableCell>  Meetings </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows.map((row) => (
                  <>
                    <TableRow key={row.SalesReplyAccountID}>
                      <TableCell align="center">
                        <input
                          id={row.SalesReplyAccountID}
                          type="checkbox"
                          onChange={(e) => CheckBoxClick(e, row)}
                        />
                      </TableCell>
                      <TableCell> <a
                                href={`http://${row?.Domain}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  textDecoration: "underline",
                                  color: "inherit",
                                }}
                              >
                                <u>
                                  {" "}
                                  {row?.Domain}
                                </u>
                              </a></TableCell>
                      <TableCell>{row.AccountName}</TableCell>
                      <TableCell>
                        {" "}
                        {moment(new Date(row.ContactedDate).toDateString()).format("MM/DD/YYYY") != "Invalid date" ? moment(new Date(row.ContactedDate).toDateString()).format("MM/DD/YYYY"): ""}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {moment(new Date(row.RespondedDate).toDateString()).format("MM/DD/YYYY") != "Invalid date"? moment(new Date(row.RespondedDate).toDateString()).format("MM/DD/YYYY"): ""}
                      </TableCell>
                      <TableCell>{row.SentDomain}</TableCell>
                      <TableCell>{row.TotalContactsContacted}</TableCell>
                      <TableCell>{row.TotalEvents}</TableCell>
                    </TableRow>
                  </>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        </div>
        {Sflag ? (
            <div class="row mx-0">
              <Pagination
                component="div"
                count={CountPage}
                onChange={HandleChangePage}
                showFirstButton
                showLastButton
              />
              <div class="col dataTables_info">
                <p>
                  Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                  {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
                  entries (filtered from {Flen} total entries)
                </p>
              </div>
            </div>
          ) : (
            <div class="row mx-0">
              <div class="col dataTables_info">
                <p>
                  Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                  {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
                  entries
                </p>
              </div>
              <div class="col pageright">
                <Pagination
                  component="div"
                  count={CountPage}
                  onChange={HandleChangePage}
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          )}
      </div>
      </div>
      </div>
    </>
  );
}
