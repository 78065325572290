import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import UserPerClientsTable from '../../_components/user/UserPerClients/UserPerClientsTable';
//import CollapsibleTable from '../_components/usertable';



class UserPerClientsPage extends React.Component {
   

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                    <Sidebar className=""/> 
                    <div className="bodyhome">  
                        <div class="">  
                            <div className="row">
                                <div className="col">
                                    <UserPerClientsTable /> 
                                </div>
                            </div>     
                        </div>

                    </div>  
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedUserPerClientsPage = connect(mapState, actionCreators)(UserPerClientsPage);
export { connectedUserPerClientsPage as UserPerClientsPage };