import React, { useEffect, useState, useRef } from 'react';
import Axios from "axios";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Device } from '@twilio/voice-sdk';


toast.configure();


export default function DialSessionSetting1({ windowObject, DialSessionData, checkeddata }) {
    const [TimeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [CurrentTime, setCurrentTime] = useState(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }));

    const [clientID, SetClientID] = React.useState("");
    const [userID, SetUserID] = React.useState("");
    const [coldCallerUserID, SetColdCallerUserID] = React.useState("");

    const [ContactList, SetContactList] = React.useState([]);
    const [TotalRecord, SetTotalRecord] = React.useState(0);
    const [StartCallingCount, SetStartCallingCount] = React.useState(0);


    const [Step1, setStep1] = useState(true);
    const [Step2, setStep2] = useState(false);
    const [Step3, setStep3] = useState(false);
    const [Step4, setStep4] = useState(false);
    const [Step5, setStep5] = useState(false);
    const [LastStep, setLastStep] = useState(false);
    const [IsMiceAccess, setIsMiceAccess] = useState(false);



    const [StartDialignbtn, setStartDialignbtn] = useState(false);
    const [StartRecordingbtn, setStartRecordingbtn] = useState(false);
    const [Recordingbtn, setRecordingbtn] = useState(false);
    const [PauseDialingbtn, setPauseDialingbtn] = useState(false);
    const [EndDialingbtn, setEndDialing] = useState(false);
    const [CallingInfoPanel, setCallingInfoPanel] = useState(false);



    const [DirectPhone, setDirectPhone] = useState("");
    const [MobilePhone, setMobilePhone] = useState("");
    const [CompanyPhone, setCompanyPhone] = useState("");
    const [OtherPhone1, setOtherPhone1] = useState("");
    const [OtherPhone2, setOtherPhone2] = useState("");



    const [ID, setID] = useState("");
    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [Email, setEmail] = useState("");
    const [Address1, setAddress1] = useState("");
    const [Address2, setAddress2] = useState("");
    const [City, setCity] = useState("");
    const [State, setState] = useState("");
    const [Zip, setZip] = useState("");
    const [Country, setCountry] = useState("");
    const [Title, setTitle] = useState("");
    const [Company, setCompany] = useState("");
    const [Website, setWebsite] = useState("");
    const [Industry, setIndustry] = useState("");
    const [LinkedIn, setLinkedIn] = useState("");
    const [SalesRep, setSalesRep] = useState("");
    const [DirectPhoneExt, setDirectPhoneExt] = useState("");
    const [HoneyScore, setHoneyScore] = useState("");
    const [CallNotes, setCallNotes] = useState("");

    const [IsStepOpen2, setIsStepOpen2] = useState(true);
    const [CallerID, SetCallerID] = React.useState("");
    const [token, SetToken] = React.useState("");
    const [device, setDevice] = useState(null);
    const [DeviceReady, setDeviceReady] = useState(false);
    const [Called, setCalled] = useState(null);

    const [IsLoderDirectPhone, setIsLoderDirectPhone] = useState(false);
    const [IsLoderMobilePhone, setIsLoderMobilePhone] = useState(false);
    const [IsLoderCompanyPhone, setIsLoderCompanyPhone] = useState(false);
    const [IsLoderOtherPhone1, setIsLoderOtherPhone1] = useState(false);
    const [IsLoderOtherPhone2, setIsLoderOtherPhone2] = useState(false);

    const [IsGreenTickDirectPhone, setIsGreenTickDirectPhone] = useState(false);
    const [IsGreenTickLoderMobilePhone, setIsGreenTickMobilePhone] = useState(false);
    const [IsGreenTickLoderCompanyPhone, setIsGreenTickCompanyPhone] = useState(false);
    const [IsGreenTickLoderOtherPhone1, setIsGreenTickOtherPhone1] = useState(false);
    const [IsGreenTickLoderOtherPhone2, setIsGreenTickOtherPhone2] = useState(false);

    const [IsContactLoopBreak, setIsContactLoopBreak] = useState(false);
    const IsContactLoopBreakRef = useRef(IsContactLoopBreak);
    const [IsDialcolorbtn, setIsDialcolorbtn] = useState(false);
    const [IsCallnextcontact, setIsCallnextcontact] = useState(false);
    const [IsCallcontact, setIsCallcontact] = useState(false);
    const contactIdRef = useRef(null);

    const [visibleDivs, setVisibleDivs] = useState({});

    useEffect(() => {
        // Hide all divs if clicking anywhere outside
        document.addEventListener('click', () => {
            setVisibleDivs({});
        });

        // Prevent closing when clicking inside any content div
        const contentDivs = document.querySelectorAll('.content-div');
        contentDivs.forEach((div) => {
            div.addEventListener('click', (event) => {
                event.stopPropagation(); // Prevent the click from closing the div
            });
        });
    }, []);

    const toggleDiv = (divId) => {
        setVisibleDivs((prevVisibleDivs) => ({
            ...prevVisibleDivs,
            [divId]: !prevVisibleDivs[divId],
        }));
    };

    useEffect(() => {

        SetClientID(DialSessionData.ClientID)
        SetUserID(DialSessionData.UserID)
        SetColdCallerUserID(DialSessionData.ColdCallerUserID)

        SetContactList(DialSessionData.lstContactData)
      
        SetTotalRecord(DialSessionData.lstContactData.length)
        SetCallerID(DialSessionData.CallerID)
        SetToken(DialSessionData.token)
debugger;
        var index = DialSessionData.lstContactData.findIndex(obj => obj.ID === "2");
        if (index !== -1) {
          let removed = DialSessionData.lstContactData.splice(index, 1);
        }


    }, [])

    useEffect(() => {
        const device = new Device(DialSessionData.token, { debug: true, allowIncomingWhileBusy: true });
        device.register();
        setDevice(device);
        device.addListener("connect", (device) => {
            console.log("Connect event listener added .....");
            return device;
        });
        device.on("registered", () => {
            setIsStepOpen2(true)
            console.log("Agent registered");
            // setUserState(USER_STATE.READY);
        });
        device.on("connect", (connection) => {
            console.log("Call connect");
        });
        device.on("disconnect", () => {
            console.log("Disconnect event");
            // setUserState(USER_STATE.READY);
            // setConnection(null);
        });
        return () => {
            device.destroy();
            setDevice(null);
            // setState(states.OFFLINE);
        };
    }, [token]);

    useEffect(() => {

    }, [IsStepOpen2, DeviceReady, CallerID, StartCallingCount, device])

    useEffect(() => {
        if(IsCallcontact){
            debugger;
            ContactCall(device)
            setIsCallcontact(false)
        }
        
    }, [ContactList,IsCallcontact])

    const ContinueBtn = async () => {
        setStep1(false)
        setCallingInfoPanel(true)
        setStep2(true)

    }
    const handleMicPermission = () => {
        // Request microphone permission from the new window context
        windowObject.navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                console.log('Microphone access granted in the new window');
                if (IsStepOpen2) {
                    setDeviceReady(true)
                    setStep2(false)
                    setStartDialignbtn(true)
                    setStep3(true)
                    setIsMiceAccess(true)
                }
            })
            .catch((error) => {
                console.log('Microphone access denied in the new window', error);
                setIsMiceAccess(false)

            });
    };

    const handleStartDialignBtn = () => {
        if (!DeviceReady || !device) {
            console.error('Device is not ready for calls');
            return;
        }

        setStep3(false)
        setStep4(true)
        setStartDialignbtn(false)
        setEndDialing(true)
        setPauseDialingbtn(true)

        ContactCall(device)

    }
    async function ContactCall(device) {
        if (ContactList.length > 0) {
            for (var i = 0; i < ContactList.length; i++) {
                var item = ContactList[i];
                var lstPhone = [];
                if (checkeddata.IsDirectPhone && item?.DirectPhone && item.DirectPhone.trim() !== "") {
                    setDirectPhone(item.DirectPhone)
                    var obj = {
                        "Name": "DirectPhone",
                        "Phone": item?.DirectPhone,
                    }
                    lstPhone.push(obj)

                }
                if (checkeddata.IsMobilePhone && item?.MobilePhone && item.MobilePhone.trim() !== "") {
                    setMobilePhone(item.MobilePhone)
                    var obj = {
                        "Name": "MobilePhone",
                        "Phone": item?.MobilePhone,
                    }
                    lstPhone.push(obj)

                }
                if (checkeddata.IsCompanyPhone && item?.CompanyPhone && item.CompanyPhone.trim() !== "") {
                    setCompanyPhone(item.CompanyPhone)
                    var obj = {
                        "Name": "CompanyPhone",
                        "Phone": item?.CompanyPhone,
                    }
                    lstPhone.push(obj)

                }
                if (checkeddata.IsOtherPhone1 && item?.OtherPhone1 && item.OtherPhone1.trim() !== "") {
                    setOtherPhone1(item.OtherPhone1)

                    var obj = {
                        "Name": "OtherPhone1",
                        "Phone": item?.OtherPhone1,
                    }
                    lstPhone.push(obj)


                }
                if (checkeddata.IsOtherPhone2 && item?.OtherPhone2 && item.OtherPhone2.trim() !== "") {
                    setOtherPhone2(item.OtherPhone2)
                    var obj = {
                        "Name": "OtherPhone2",
                        "Phone": item?.OtherPhone2,
                    }
                    lstPhone.push(obj)

                }
                setFirstName(item?.Name)
                setID(item?.ID)
                setLastName(item?.LastName)
                setEmail(item?.Email)
                setAddress1(item?.Address1)
                setAddress2(item?.Address2)
                setCity(item?.City)
                setState(item?.State)
                setZip(item?.Zip)
                setCountry(item?.Country)
                setTitle(item?.Title)
                setCompany(item?.Company)
                setWebsite(item?.Website)
                setIndustry(item?.Industry)
                setLinkedIn(item?.LinkedIn)
                setSalesRep(item?.SalesRep)
                setDirectPhoneExt(item?.DirectPhoneExt)
                setHoneyScore(item?.HoneyScore)
                debugger;
                let result = '';
                const strlist = item?.CallNotes.split('--')?.filter(Boolean);
                strlist.forEach(datanote => {
                    if (datanote?.trim() !== "") {
                        let Objdatematch = "";
                        let Objstringmatch = "";
                        const Isdatevalid = checkDate(datanote?.trim());

                        if (!Isdatevalid) {
                            Objstringmatch = "--" + datanote;
                        } else {
                            Objdatematch = "<br>--" + datanote;
                        }
                        result += Objdatematch + Objstringmatch;
                    }
                })
                setCallNotes(result)
                //console.log()
                if (lstPhone.length > 0) {
                    for (var j = 0; j < lstPhone.length; j++) {
                        if (IsContactLoopBreakRef.current) {
                            break;
                        }
                        await DialbtnDisable(lstPhone[j].Name)
                        var CallOutput = await StartDail(device, lstPhone[j].Phone)
                        await GreentickDisplay(lstPhone[j].Name)

                        //  await enqueueOutgoingCalls(CallOutput);
                    }
                }
                SetStartCallingCount(StartCallingCount + 1);
            }
           // setLastStep(true)

        }
        else
        {
            // setLastStep(true)
        }
    }
    function checkDate(stDatetime) {
        let hasDate = false;

        try {
            stDatetime = stDatetime.split(" ")[0];
            const inputText = stDatetime.split(" ");

            inputText.forEach(text => {
                if (!isNaN(Date.parse(text))) {
                    hasDate = true;
                }
            });
        } catch (err) {
        }

        return hasDate;
    }

    async function StartDail(device, phonenumber) {
        return new Promise(async (resolve, reject) => {
            try {

                setIsContactLoopBreak(false)
                IsContactLoopBreakRef.current = false;
                 //const params = { To: phonenumber,ClientID:"123",UserID:"654",ColdCallerUserID:"1010",ProspectID:"777",CallerID:CallerID };
                setStep5(true)
                // device.connect({
                //     params,
                //     rtcConstraints: {
                //         audio: true,
                //     },
                // }).then((call) => {
                //     setCalled(call)
                //     call.on('accept', () => {
                //         console.log('Call accepted');
                //     });
                //     call.on("cancel", () => {
                //         console.log("Call canceled.");
                //         resolve(call);
                //     });
                //     call.on('disconnect', () => {
                //         debugger;
                //         console.log('The call has been disconnected.');
                //         resolve(call);
                //     });
                //     call.on("error", () => {
                //         console.log("Call errored.");
                //         resolve(call);
                //     });
                //     call.on('reject', () => {
                //         console.log('The call was rejected.');
                //         resolve(call);
                //     });
                // }).catch((error) => {
                //     console.log('Error connecting the call:', error);
                //     reject("error");
                // });
                setTimeout(() => {
                    console.log("sucess")
                    resolve("sucess")
                }, 5000);

            } catch (error) {
                console.log("Error making outgoing call:", error);
                reject(error);
            }
        });
    }

    async function enqueueOutgoingCalls(calldata) {
        try {
            let InputParameter = {
                UserID: userID,
                ClientID: clientID,
                ColdCallerUserID: coldCallerUserID,
                CallData: calldata

            };
            console.log(calldata)
            // const response = Axios({
            //     url: CommonConstants.MOL_APIURL + "/twilio/callend",
            //     method: "POST",
            //     data: InputParameter,
            //   });
            //   response.then((Result) => {

            //     if (Result.data.StatusMessage == "SUCCESS") {
            //       return("SUCCESS")
            //     }
            //   });


        } catch (error) {
            console.error("Error enqueueing calls:", error);
        }
    }
    async function handleEndDialignBtn() {
       // Called.disconnect();
    }
    async function ManualDailBtn(phonenumber, Name) {
        debugger;
        setIsDialcolorbtn(false)
        await DialbtnDisable(Name)
        var CallOutput = await StartDail(device, phonenumber)
        await GreentickDisplay(Name)
    }

    async function handleStatusBtn(status) {
        debugger
        setCallNotes(status)
        //Called.disconnect();
        if (status == "Not Available") {
            setIsLoderCompanyPhone(false)
            setIsLoderCompanyPhone(false)
            setIsLoderMobilePhone(false)
            setIsLoderDirectPhone(false)
            setIsLoderOtherPhone2(false)
            setIsLoderOtherPhone1(false)
            setIsDialcolorbtn(true)
            setIsContactLoopBreak(true)
            IsContactLoopBreakRef.current = true;
            setIsCallnextcontact(true)
        }
        if (status == "Call next contact") {
            debugger;
            const ID = contactIdRef.current.value;
           // var ID = document.getElementById("hdncontactid").value
            var index = ContactList.findIndex(obj => obj.ID === ID);
            if (index !== -1) {
              let removed = ContactList.splice(index, 1);
            }
            setIsCallnextcontact(false)
            setIsCallcontact(true)
        }


    }

    async function GreentickDisplay(Name) {
        if (Name == "DirectPhone") {
            setIsGreenTickDirectPhone(true)
        }
        else if (Name == "MobilePhone") {
            setIsGreenTickMobilePhone(true)
        }
        else if (Name == "CompanyPhone") {
            setIsGreenTickCompanyPhone(true)

        }
        else if (Name == "OtherPhone1") {
            setIsGreenTickOtherPhone1(true)
        }
        else if (Name == "OtherPhone2") {
            setIsGreenTickOtherPhone2(true)
        }
    }
    async function DialbtnDisable(Name) {
        if (Name == "DirectPhone") {
            setIsLoderDirectPhone(true)
            setIsLoderOtherPhone2(false)
            setIsLoderOtherPhone1(false)
            setIsLoderCompanyPhone(false)
            setIsLoderMobilePhone(false)
        }
        else if (Name == "MobilePhone") {
            setIsLoderMobilePhone(true)
            setIsLoderDirectPhone(false)
            setIsLoderOtherPhone2(false)
            setIsLoderOtherPhone1(false)
            setIsLoderCompanyPhone(false)
        }
        else if (Name == "CompanyPhone") {
            setIsLoderCompanyPhone(true)
            setIsLoderMobilePhone(false)
            setIsLoderDirectPhone(false)
            setIsLoderOtherPhone2(false)
            setIsLoderOtherPhone1(false)
        }
        else if (Name == "OtherPhone1") {
            setIsLoderOtherPhone1(true)
            setIsLoderCompanyPhone(false)
            setIsLoderMobilePhone(false)
            setIsLoderDirectPhone(false)
            setIsLoderOtherPhone2(false)
        }
        else if (Name == "OtherPhone2") {
            setIsLoderOtherPhone2(true)
            setIsLoderOtherPhone1(false)
            setIsLoderCompanyPhone(false)
            setIsLoderMobilePhone(false)
            setIsLoderDirectPhone(false)
        }
    }

    return (
        <>

            {/* start main header */}
            <header>
                <div className="container content-space-between">
                    <div className="logo">
                        <img src="https://appv2.saleshive.com/static/media/SalesHive.a8f9582b.png" alt="logo" />
                    </div>
                </div>
            </header>
            {/* end main header */}

            {/* start first header */}
            {Step1 ? (
                <div className='steped step-one'>
                    <h1>Dial session settings</h1>
                    <p>There are {TotalRecord} contacts to be called.</p>
                    <div className="container border-all mt-1 p-2">
                        <ul className="settings-list">
                            <li className="settings-item">
                                <label className="settings-label" for="voicemail-recording">Voicemail recording
                                    <button id="toggleButton1" onClick={() => toggleDiv('contentDiv1')} class="settings-info">
                                        <svg width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" fill="#1C274C"></path>
                                        </svg>
                                    </button>
                                    <div id="contentDiv1" class="content-div hover-tip" style={{ display: visibleDivs.contentDiv1 ? 'block' : 'none' }}>
                                        <p>The selected voicemail will only  cted.</p>
                                    </div>
                                </label>
                                <div className="w-100">
                                    <select className="settings-input" id="voicemail-recording">
                                        <option value="Reactivation Promo">Yes</option>
                                        <option value="Reactivation Promo">No</option>
                                    </select>
                                </div>
                            </li>
                            <li className="settings-item">
                                <label className="settings-label" for="caller-id">Caller ID
                                    <button id="toggleButton1" onClick={() => toggleDiv('contentDiv2')} class="settings-info">
                                        <svg width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" fill="#1C274C"></path>
                                        </svg>
                                    </button>
                                    <div id="contentDiv2" class="content-div hover-tip" style={{ display: visibleDivs.contentDiv2 ? 'block' : 'none' }}>
                                        <p>The selected voicemail will only  cted.</p>
                                    </div>
                                </label>
                                <div className="w-100">
                                    {CallerID}
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="container mt-1">
                        <button className="button w-auto" onClick={() => { ContinueBtn({ token }) }}>Continue</button>
                    </div>
                    <p className='preline-tes'>By using this dialer to make calls and/or leave messages, I agree that I will follow all applicable local, state, national and international laws, rules, and regulations, including those governing call recording and telemarketing. I also agree to abide by the <a href="">terms of service</a>.</p>
                </div>
            ) : (
                <></>
            )}

            {/* end first header */}

            {/* start info header */}
            {Step2 || Step3 || Step4 ? (<>
                <div className='info-nav'>

                    {CallingInfoPanel ? (
                        <div className="info">
                            <div className="info-item">
                                <div className="info-label">Caller ID</div>
                                <div className="info-value">{StartDialignbtn ? CallerID : "Not Connected"}</div>
                            </div>
                            <div className="info-item">
                                <div className="info-label">Duration</div>
                                <div className="info-value">0</div>
                            </div>
                            <div className="info-item">
                                <div className="info-label">Contacts</div>
                                <div className="info-value">{StartCallingCount} of {TotalRecord}</div>
                            </div>
                            <div className="info-item">
                                <div className="info-label">Calls</div>
                                <div className="info-value">0</div>
                            </div>
                            <div className="info-item">
                                <div className="info-label">Talks</div>
                                <div className="info-value">0</div>
                            </div>
                            <div className="info-item">
                                <div className="info-label">E-mails</div>
                                <div className="info-value">0</div>
                            </div>
                            <div className="info-item">
                                <div className="info-label">Voicemails</div>
                                <div className="info-value">0</div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                <div className="info-navtwo">
                    <div className="container">
                        <div className="d-flex flex-center mt-1 mb-1 flex-wrap">
                            {StartRecordingbtn ? (
                                <button className="recording startrecording">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={15}
                                        height={15}
                                        viewBox="-3 0 28 28"
                                    >
                                        <title>{"play"}</title>
                                        <path
                                            fill="#000"
                                            fillRule="evenodd"
                                            d="M21.415 12.554 2.418.311C1.291-.296 0-.233 0 1.946v24.108c0 1.992 1.385 2.306 2.418 1.635l18.997-12.243a2.076 2.076 0 0 0 0-2.892"
                                        />
                                    </svg>
                                    Start Recording
                                </button>
                            ) : (
                                <></>
                            )}

                            {Recordingbtn ? (
                                <button className="recording mr-2">
                                    <svg fill="#fff" width="15px" height="15px" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="127" cy="129" r="81" fill-rule="evenodd" />
                                    </svg>
                                    Recording
                                </button>
                            ) : (
                                <></>
                            )}

                            {PauseDialingbtn ? (
                                <button className="dia-btn btn-left-rd mr-2">
                                    <svg width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.163 3.819C5 4.139 5 4.559 5 5.4v13.2c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.581.164h.2c.84 0 1.26 0 1.581-.163a1.5 1.5 0 0 0 .656-.656c.163-.32.163-.74.163-1.581V5.4c0-.84 0-1.26-.163-1.581a1.5 1.5 0 0 0-.656-.656C8.861 3 8.441 3 7.6 3h-.2c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656zm9 0C14 4.139 14 4.559 14 5.4v13.2c0 .84 0 1.26.164 1.581a1.5 1.5 0 0 0 .655.655c.32.164.74.164 1.581.164h.2c.84 0 1.26 0 1.581-.163a1.5 1.5 0 0 0 .655-.656c.164-.32.164-.74.164-1.581V5.4c0-.84 0-1.26-.163-1.581a1.5 1.5 0 0 0-.656-.656C17.861 3 17.441 3 16.6 3h-.2c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.655.656z" /></svg>
                                    Pause Dialign</button>
                            ) : (
                                <></>
                            )}
                            {EndDialingbtn ? (
                                <button class="dia-btn end-dia" onClick={() => { handleEndDialignBtn() }}>

                                    <svg width="15px" height="15px" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="1" y="1" width="14" height="14" />
                                    </svg>
                                    End Dialign
                                </button>
                            ) : (
                                <></>
                            )}
                            {StartDialignbtn && IsMiceAccess ? (
                                <button className="recording startrecording" onClick={() => { handleStartDialignBtn() }}>
                                    {/* <div className="recording startrecording mr-2"> */}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={15}
                                        height={15}
                                        viewBox="-3 0 28 28"
                                    >
                                        <title>{"play"}</title>
                                        <path
                                            fill="#000"
                                            fillRule="evenodd"
                                            d="M21.415 12.554 2.418.311C1.291-.296 0-.233 0 1.946v24.108c0 1.992 1.385 2.306 2.418 1.635l18.997-12.243a2.076 2.076 0 0 0 0-2.892"
                                        />
                                    </svg>
                                    Start Dialing
                                    {/* </div> */}
                                </button>
                            ) : (
                                <></>
                            )}

                        </div>
                    </div>
                </div>
            </>
            ) : (
                <></>
            )}
            {/* end info header */}

            {/* start step two header */}
            {Step2 ? (
                <div className='steped step-two'>
                    <div className="container">
                        <h3 className='mb-1'>Connect to your dial session</h3>
                        <div className="connect-section">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={80}
                                height={80}
                                fill="#360947"
                                className="icon glyph"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M20 18h-.07a1 1 0 0 1-.93-1.07l.36-5A7.38 7.38 0 0 0 6.6 6.35a7.31 7.31 0 0 0-2 5.56l.36 5a1 1 0 0 1-2 .14l-.36-5a9.38 9.38 0 1 1 18.72 0l-.36 5A1 1 0 0 1 20 18Z"
                                    style={{
                                        fill: "#360947",
                                    }}
                                />
                                <path
                                    d="M17 11h-1a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h1a4 4 0 0 0 4-4v-3a4 4 0 0 0-4-4ZM8 11H7a4 4 0 0 0-4 4v3a4 4 0 0 0 4 4h1a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2Z"
                                    style={{
                                        fill: "#360947",
                                    }}
                                />
                            </svg>
                            <h3>Use your browser's headset and microphone to connect</h3>
                            <p className="connect-text">The dialer needs access to your microphone to continue. Click to grant access and start calling <b> Paul Meekeren.</b></p>
                            <button className="button w-auto mt-1" onClick={() => { handleMicPermission(token) }}>Grant Mic Access</button>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}

            {/* end step two header */}

            {/* start step three header */}
            {Step3 ? (
                <div className='steped step-three'>
                    <div className="container">
                        <div className="connect-section">
                            {!IsMiceAccess ? (
                                <>
                                    <div className='card-error'>
                                        <h3 className='mb-1'>We were unable to initialize your session.</h3>
                                        <p>Please Contact support and provided them the error code below.</p>
                                        <a>See this article for a possible solution.</a>
                                        <p>Error #0</p>
                                    </div>
                                </>
                            )
                                : (
                                    <>
                                        <img className='gif-minus' src='/assets/images/check-animation.gif' width="140px" alt="Connected" />
                                        <h3 className='mb-1'>Connected and ready to begin Dialing.</h3>
                                        <label>Click <b>"Start Dialing"</b> above to begin.</label><br /><br />
                                        <small>As the dialer calls each person, their contact information will be displayed. <br /> The first contact that will be called is <b>Paul Meekeren.</b></small>
                                    </>
                                )}
                            {/* <svg width="80px" height="80px" fill='#7ac142' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12.5 2.2a10.3 10.3 0 1 0 10.3 10.3A10.299 10.299 0 0 0 12.5 2.2zm-1.998 15.077l-3.79-3.585.92-.918 2.865 2.676 7.027-6.874.92.92z" /><path fill="none" d="M0 0h24v24H0z" /></svg> */}
                            {/* <img className='gif-minus' src='/assets/images/check-animation.gif' width="140px" />
                            <h3 className='mb-1'>Connected and ready to begin Dialing.</h3>
                            <label>Click <b>"Start Dialing"</b> above to begin.</label><br /><br />
                            <small>As the dialer calls each person, their contact information will be displayed. <br /> The first contact that will be called is <b>Paul Meekeren.</b></small> */}
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}

            {/* end step three header */}

            {/* start step fore header */}
            {Step4 ? (
                <div className='steped step-fore'>
                    <div className="container flex-form">
                        <div className='d-flex mb-3'>
                            <div className='col w-100 px-0'>
                                <div className='cardboxwhite align-items'>

                                    {/* <div>
                                        <div className="select-option">
                                            <select>
                                                <option>Email Contact Details</option>
                                                <option>Google Search</option>
                                            </select>
                                        </div>
                                    </div> */}

                                    <div className="d-flex">
                                        <div className="phone-list">
                                            {DirectPhone != "" ? (
                                                <div className="phone-item">
                                                    <span className="phone-label">{IsGreenTickDirectPhone ? <img src='/assets/images/check-green.svg' width="15px" /> :""} Direct Phone</span>
                                                    <div className='phone-number'>
                                                        <input type='text' value={DirectPhone} />
                                                        <button className={IsDialcolorbtn ? "phone-status light-action-btn" : "phone-status"} onClick={IsDialcolorbtn ? () => ManualDailBtn(DirectPhone, "DirectPhone") : null}>
                                                            {IsLoderDirectPhone ? "" : "Dial"}
                                                            {IsLoderDirectPhone ? <img className='gif-minus' src='/assets/images/three-dots.svg' width="22px" /> : ""}
                                                        </button>

                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                            {MobilePhone != "" ? (
                                                <div className="phone-item">
                                                    <span className="phone-label">{IsGreenTickLoderMobilePhone ? <img src='/assets/images/check-green.svg' width="15px" /> :""} Mobile Phone</span>
                                                    <div className='phone-number'>
                                                        <input type='text' value={MobilePhone} />
                                                        <button className={IsDialcolorbtn ? "phone-status light-action-btn" : "phone-status"} onClick={IsDialcolorbtn ? () => ManualDailBtn(MobilePhone, "MobilePhone") : null}>
                                                            {IsLoderMobilePhone ? "" : "Dial"}
                                                            {IsLoderMobilePhone ? <img className='gif-minus' src='/assets/images/three-dots.svg' width="22px" /> : ""}
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                            {CompanyPhone != "" ? (
                                                <div className="phone-item">
                                                    <span className="phone-label">{IsGreenTickLoderCompanyPhone ? <img src='/assets/images/check-green.svg' width="15px" /> :""} Company Phone</span>
                                                    <div className='phone-number'>
                                                        <input type='text' value={CompanyPhone} />
                                                        <button className={IsDialcolorbtn ? "phone-status light-action-btn" : "phone-status"} onClick={IsDialcolorbtn ? () => ManualDailBtn(CompanyPhone, "CompanyPhone") : null}>{IsLoderCompanyPhone ? "" : "Dial"}
                                                            {IsLoderCompanyPhone ? <img className='gif-minus' src='/assets/images/three-dots.svg' width="22px" /> : ""}

                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                            {OtherPhone1 != "" ? (
                                                <div className="phone-item">
                                                    <span className="phone-label">{IsGreenTickLoderOtherPhone1 ? <img src='/assets/images/check-green.svg' width="15px" /> :""} Other Phone 1</span>
                                                    <div className='phone-number'>
                                                        <input type='text' value={OtherPhone1} />
                                                        <button className={IsDialcolorbtn ? "phone-status light-action-btn" : "phone-status"} onClick={IsDialcolorbtn ? () => ManualDailBtn(OtherPhone1, "OtherPhone1") : null}>{IsLoderOtherPhone1 ? "" : "Dial"}
                                                            {IsLoderOtherPhone1 ? <img className='gif-minus' src='/assets/images/three-dots.svg' width="22px" /> : ""}

                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                            {OtherPhone2 != "" ? (
                                                <div className="phone-item">
                                                    <span className="phone-label">{IsGreenTickLoderOtherPhone2 ? <img src='/assets/images/check-green.svg' width="15px" /> :""} Other Phone 2</span>
                                                    <div className='phone-number'>
                                                        <input type='text' value={OtherPhone2} />
                                                        <button className={IsDialcolorbtn ? "phone-status light-action-btn" : "phone-status"} onClick={IsDialcolorbtn ? () => ManualDailBtn(OtherPhone2, "OtherPhone2") : null}>{IsLoderOtherPhone2 ? "" : "Dial"}
                                                            {IsLoderOtherPhone2 ? <img className='gif-minus' src='/assets/images/three-dots.svg' width="22px" /> : ""}
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>


                                        {/* <div>
                                            <button className="add-item"> + </button>
                                        </div> */}
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className='fore-row d-flex mb-3'>
                            <div className='col'>
                                <div className='cardboxwhite'>


                                    <div className="location-header">
                                        <svg width="12px" height="12px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m 8 0 c -3.3125 0 -6 2.6875 -6 6 c 0.007812 0.710938 0.136719 1.414062 0.386719 2.078125 l -0.015625 -0.003906 c 0.636718 1.988281 3.78125 5.082031 5.625 6.929687 h 0.003906 v -0.003906 c 1.507812 -1.507812 3.878906 -3.925781 5.046875 -5.753906 c 0.261719 -0.414063 0.46875 -0.808594 0.585937 -1.171875 l -0.019531 0.003906 c 0.25 -0.664063 0.382813 -1.367187 0.386719 -2.078125 c 0 -3.3125 -2.683594 -6 -6 -6 z m 0 3.691406 c 1.273438 0 2.308594 1.035156 2.308594 2.308594 s -1.035156 2.308594 -2.308594 2.308594 c -1.273438 -0.003906 -2.304688 -1.035156 -2.304688 -2.308594 c -0.003906 -1.273438 1.03125 -2.304688 2.304688 -2.308594 z m 0 0" fill="#2e3436" />
                                        </svg> Located in <span>{TimeZone}</span> where the local time is <span>{CurrentTime}</span>
                                    </div>
                                    <input type="hidden" id='hdncontactid' ref={contactIdRef} value={ID} />
                                    <div className="form-group">
                                        <input type="text" value={FirstName} />
                                        <input type="text" value={LastName} />
                                    </div>
                                    <div className="form-group">
                                        <input type="email" value={Email} className="full-width" />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" value={Address1} className="full-width" />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" value={City} />
                                        <input type="text" value={State} />
                                    </div>
                                    <div className="form-group">
                                        <select>
                                            <option>Bad Data</option>
                                            <option>Meeting Set</option>
                                            <option>DNC</option>
                                        </select>
                                        <input type="text" value={Zip} />
                                    </div>
                                    {/* <div className="form-group">
                                        <textarea placeholder="Description here"></textarea>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" placeholder="Click to add tags" className="full-width" />
                                    </div> */}
                                    <div className="note-section">
                                        <textarea placeholder="Add a note" ></textarea>
                                        <div className="note">
                                            <div className="note-header">
                                                <svg width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11.8107L14.4697 13.5303C14.7626 13.8232 15.2374 13.8232 15.5303 13.5303C15.8232 13.2374 15.8232 12.7626 15.5303 12.4697L12.5303 9.46967C12.3897 9.32902 12.1989 9.25 12 9.25C11.8011 9.25 11.6103 9.32902 11.4697 9.46967L8.46967 12.4697C8.17678 12.7626 8.17678 13.2374 8.46967 13.5303C8.76256 13.8232 9.23744 13.8232 9.53033 13.5303L11.25 11.8107V17C11.25 17.4142 11.5858 17.75 12 17.75ZM8 7.75C7.58579 7.75 7.25 7.41421 7.25 7C7.25 6.58579 7.58579 6.25 8 6.25H16C16.4142 6.25 16.75 6.58579 16.75 7C16.75 7.41421 16.4142 7.75 16 7.75H8Z" fill="#000" />
                                                </svg>
                                            </div>
                                            <div className="note-content">
                                                <div dangerouslySetInnerHTML={{ __html: CallNotes }} />
                                            </div>
                                        </div>



                                        {/* <div className="note">
                                            <div className="note-header">
                                                <svg width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11.8107L14.4697 13.5303C14.7626 13.8232 15.2374 13.8232 15.5303 13.5303C15.8232 13.2374 15.8232 12.7626 15.5303 12.4697L12.5303 9.46967C12.3897 9.32902 12.1989 9.25 12 9.25C11.8011 9.25 11.6103 9.32902 11.4697 9.46967L8.46967 12.4697C8.17678 12.7626 8.17678 13.2374 8.46967 13.5303C8.76256 13.8232 9.23744 13.8232 9.53033 13.5303L11.25 11.8107V17C11.25 17.4142 11.5858 17.75 12 17.75ZM8 7.75C7.58579 7.75 7.25 7.41421 7.25 7C7.25 6.58579 7.58579 6.25 8 6.25H16C16.4142 6.25 16.75 6.58579 16.75 7C16.75 7.41421 16.4142 7.75 16 7.75H8Z" fill="#000" />
                                                </svg>
                                            </div>
                                            <div className="note-content">
                                                <h3>TESTING T. <span>Aug 30, 2024, 4:36 AM</span></h3>
                                                -- 08/27/2024 @ 3:34 AM MDT by TESTING TESTING -- (601) 401-8752 -- Bad Data<br />
                                                -- 08/27/2024 @ 3:33 AM MDT by TESTING TESTING -- Hi Paiul
                                            </div>
                                        </div> */}
                                    </div>

                                </div>
                            </div>
                            <div className='col'>
                                <div className=''>

                                    <div className="warpper">
                                        <input className="radio" id="one" name="group" type="radio" checked />
                                        <input className="radio" id="two" name="group" type="radio" />


                                        <div className="tabs">
                                            <label className="tab" id="one-tab" for="one">Custom Fields</label>
                                            <label className="tab" id="two-tab" for="two">Activity</label>
                                        </div>

                                        <div className="panels">
                                            <div className="panel" id="one-panel">
                                                <div className="form-group">
                                                    <label for="address1">Address 1</label>
                                                    <input type="text" id="address1" value={Address1} />
                                                </div>
                                                <div className="form-group">
                                                    <label for="address2">Address 2</label>
                                                    <input type="text" id="address2" value={Address2} />
                                                </div>
                                                <div className="form-group">
                                                    <label for="city">City</label>
                                                    <input type="text" id="city" value={City} />
                                                </div>
                                                <div className="form-group">
                                                    <label for="state">State</label>
                                                    <input type="text" id="state" value={State} />
                                                </div>
                                                <div className="form-group">
                                                    <label for="zip">Zip</label>
                                                    <input type="text" id="zip" value={Zip} />
                                                </div>
                                                <div className="form-group">
                                                    <label for="country">Country</label>
                                                    <input type="text" id="country" value={Country} />
                                                </div>
                                                <div className="form-group">
                                                    <label for="ext">Ext.</label>
                                                    <input type="text" id="ext" value={DirectPhoneExt} />
                                                </div>
                                                <div className="form-group">
                                                    <label for="company">Company</label>
                                                    <input type="text" id="company" value={Company} />
                                                </div>
                                                <div className="form-group">
                                                    <label for="website">Website</label>
                                                    <input type="text" id="website" value={Website} />
                                                </div>
                                                <div className="form-group">
                                                    <label for="title">Title</label>
                                                    <input type="text" id="title" value={Title} />
                                                </div>
                                                {/* <div className="form-group">
                                                    <label for="phoneext">Phone Ext. (Home)</label>
                                                    <input type="text" id="phoneext" value="Phone Ext. (Home)" />
                                                </div> */}
                                                <div className="form-group">
                                                    <label for="industry">Industry</label>
                                                    <input type="text" id="industry" value={Industry} />
                                                </div>
                                                <div className="form-group">
                                                    <label for="linkedin">LinkedIn</label>
                                                    <input type="text" id="linkedin" value={LinkedIn} />
                                                </div>
                                                <div className="form-group">
                                                    <label for="clientrep">Client Rep</label>
                                                    <input type="text" id="clientrep" value={SalesRep} />
                                                </div>
                                                <div className="form-group">
                                                    <label for="phoneext">Honey Score</label>
                                                    <input type="text" id="phoneext" value={HoneyScore} />
                                                </div>

                                            </div>





                                            <div className="panel" id="two-panel">




                                                <div className="filter">
                                                    <select>
                                                        <option>Last 7 days</option>
                                                        <option>Last 7 days</option>
                                                    </select>
                                                </div>
                                                <div className="filter">
                                                    <select>
                                                        <option>Log Activity one</option>
                                                        <option>Log Activity one</option>
                                                    </select>

                                                    <button className="log-activity">+ Log Activity</button>
                                                </div>


                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Activity</th>
                                                            <th>Date/Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Using PhoneBurner, you <a href="#" className="activity">called</a> Kyle Mayers</td>
                                                            <td>Sep 02, 2024 11:58 pm</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Using PhoneBurner, you <a href="#" className="activity">called</a> Kyle Mayers</td>
                                                            <td>Sep 02, 2024 11:58 pm</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Using PhoneBurner, you <a href="#" className="activity">called</a> Kyle Mayers</td>
                                                            <td>Sep 02, 2024 11:58 pm</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Using PhoneBurner, you <a href="#" className="activity">called</a> Kyle Mayers</td>
                                                            <td>Sep 02, 2024 11:58 pm</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}

            {/* end step fore header */}
            {Step5 ? (
                <div className='foter-fix'>
                    <div className='button-group'>
                        {!IsCallnextcontact ? (
                            <>
                                <button class="green-btn" onClick={() => { handleStatusBtn("Live Answer") }}>Live Answer</button>
                                <button class="green-btn" onClick={() => { handleStatusBtn("Meeting Set") }}>Meeting Set</button>
                                <button onClick={() => { handleStatusBtn("Voiceemail 2") }}>Voiceemail 2</button>
                                <button onClick={() => { handleStatusBtn("Not Qualified") }}>Not Qualified</button>
                                <button onClick={() => { handleStatusBtn("Not Available") }}>Not Available</button>
                                <button onClick={() => { handleStatusBtn("Leave No Message / Email") }}>Leave No Message / Email</button>
                                <button className='red-btn' onClick={() => { handleStatusBtn("Busy Signal") }}>Busy Signal</button>
                                <button className='red-btn' onClick={() => { handleStatusBtn("DNC") }}>DNC</button>
                            </>
                        ) : (<button onClick={() => { handleStatusBtn("Call next contact") }}>Call next contact</button>)}



                    </div>
                </div>
            ) : (<></>)}

            {LastStep ? (
                <div className='steped step-six'>
                    <div className="container">
                        <div class="timeboxmain">
                            <h3>Way to go!</h3>
                            <img className='maintime' src="/assets/images/timeicon.svg" width="66px" />
                            <p>You saved an estimated</p>
                            <h4>62 Minutes</h4>

                            <div class="d-flex time-flex">
                                <div class="time-item">
                                    <img src="/assets/images/duration.svg" width="30px" />
                                    <span class="light">Duration</span>
                                    <h5>27 min</h5>
                                </div>
                                <div class="time-item">
                                    <img src="/assets/images/user-icons.svg" width="30px" />
                                    <span class="light">Contacts</span>
                                    <h5>24</h5>
                                </div>
                                <div class="time-item">
                                    <img src="/assets/images/telephone-icon.svg" width="28px" />
                                    <span class="light">Calls</span>
                                    <h5>20</h5>
                                </div>
                                <div class="time-item">
                                    <img src="/assets/images/Talks.svg" width="30px" />
                                    <span class="light">Talks</span>
                                    <h5>27</h5>
                                </div><div class="time-item">
                                    <img src="/assets/images/E-mails.svg" width="30px" />
                                    <span class="light">E-mails</span>
                                    <h5>0</h5>
                                </div>
                                <div class="time-item">
                                    <img src="/assets/images/Voicemalls.svg" width="30px" />
                                    <span class="light">Voicemalls</span>
                                    <h5>2</h5>
                                </div>


                            </div>
                        </div>

                    </div>
                    <div className="container mt-1">
                        <button className="button-border w-auto float-left">View Details</button>
                        <button className="button w-auto float-right">Done</button>
                    </div>
                </div>
            ) : (<></>)}
        </>
    );
}