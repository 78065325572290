import React, { useEffect } from 'react';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
//import { makeStyles } from '@material-ui/core/styles';
import {
  createTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import { toast } from "react-toastify";
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from "@material-ui/lab/Pagination";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Avatar } from '@material-ui/core';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';


import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Userimg from '../../../images/avatar/1.jpg';
import loadingicon from "../../../images/loading.gif";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "axios";
import { CommonConstants } from "../../../_constants/common.constants";
const moment = require("moment");
import { GetUserDetails } from "../../../_helpers/Utility";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { history } from "../../../_helpers";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";
import Popover from '@mui/material/Popover';
import StyleHeader from "../../user/StickyHeader/StickyHeader";

// const useRowStyles = makeStyles({
//   root: {
//     '& > *': {
//       borderBottom: 'unset',
//     },
//   },
// });
const defaultTheme = createTheme();
const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "2em",
        color: "yellow",
        backgroundColor: "red"
      }
    }
  }
});

export default function AccountAllTable({ props, updateChild }) {
  const [data, setdata] = React.useState([]);
  const [flen, setflen] = React.useState(0);
  const [SFlag, setSFlag] = React.useState(false);
  const [Rlen, setRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [search, setSearch] = React.useState("");
  const [searched, setSearched] = React.useState("");
  const [AnchorEl, SetAnchorEl] = React.useState(null);
  const [AnchorElf, SetfAnchorEl] = React.useState(null);
  const [checkS, setcheckS] = React.useState(false);
  const [orignaldata, setorignaldata] = React.useState([]);
  const [SortField, setSortField] = React.useState("Name");
  const [SortedBy, setSortedBy] = React.useState(1);

  const [cancelarchivepopupval, setcancelarchivepopupval] =
    React.useState(false);
  const [ArchivedPopup, setArchivedPopup] = React.useState(false);
  const [question, setquestion] = React.useState("");
  const [msg, setmsg] = React.useState("");
  const [Type, SetType] = React.useState("");
  const [popupbutton, setpopupbutton] = React.useState("");
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [Role, setRole] = React.useState("");
  const [AID, SetAID] = React.useState("");
  const [Archive, SetArchive] = React.useState(false);
  const [TemplatePopup, SetTemplatePopup] = React.useState(false)
  const [Name, SetName] = React.useState("");
  const [ExportData, SetExportData] = React.useState([]);
  const [ClientName, SetClientName] = React.useState("")
  const [CUserID, SetCUserID] = React.useState(0)
  const [Errors, SetErrors] = React.useState({});
  const [CampaignTemplateNameAvailable, SetCampaignTemplateNameAvailable] = React.useState(null);
  const [IsExist, SetIsExits] = React.useState(false);
  const [BtnDisabled, SetBtnDisabled] = React.useState(false);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    var getaccountcatelist = GetUserDetails();
    if (getaccountcatelist != null) {
      setClientID(getaccountcatelist.ClientID);
      setUserID(getaccountcatelist.ParentUserID);
      SetCUserID(getaccountcatelist.ChildUserID);
      setRole(getaccountcatelist.Role);
    }
  }, [ClientID, Role]);

  useEffect(() => {
    var getaccountcatelist = GetUserDetails();
    GetCampaignsList(getaccountcatelist.ClientID, getaccountcatelist.ParentUserID, getaccountcatelist.Role);
  }, [search, Page, RowsPerPage, Archive, AnchorEl])

  const GetCampaignsList = async (CID, UID, URole) => {
    setIsDataLoaded(true);
    // document.getElementById("hideloding").style.display = "block";
    var str_in = {
      page: Page,
      rowsPerPage: RowsPerPage,
      sort: true,
      field: SortField,
      sortby: SortedBy,
      search: search,
      type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      Archive: Archive
    };
    try {
      const rows1 = await Axios({
        url: CommonConstants.MOL_APIURL + "/campaign/CampaignGet",
        method: "POST",
        data: str_in,
      });
      if (rows1.data.status === "SUCCESS") {
        setdata(rows1.data.pagedata);
        setRows(rows1.data.pagedata);
        setRlen(rows1.data.totalcount);
        setflen(rows1.data.totalcount);
        SetCountPage(rows1.data.pagecount);
        // document.getElementById("hideloding").style.display = "none";
        setIsDataLoaded(false);
      } else {
        //document.getElementById("hideloding").style.display = "none";
        setIsDataLoaded(false);
      }
    } catch (error) {
      // Handle the error here
      //console.error("Error fetching campaigns:", error);
      // document.getElementById("hideloding").style.display = "none";
      setIsDataLoaded(false);
    }
  };


  const CampaignActiveUpdate = async (CampaignID, Flag) => {

    var Str_in = {
      IsActive: !Flag,
      CampaignID: CampaignID,
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
    };
    const rows1 = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/campaign/CampaignActiveUpdate",
      method: "POST",
      data: Str_in,
    });
    if (rows1.data.StatusMessage == "SUCCESS") {

      GetCampaignsList(ClientID, UserID, Role)
    }
    else if (rows1.data.StatusMessage == "EmailSignatureError") {
      toast.error(<div>Campaign<br />Email signature is required</div>);

    } else {
      toast.error(<div>Campaign<br />Please Add Account, Prospect and Step at least one.</div>);
    }
  }

  //push to edit page
  const EditCampaign = async (CampaignID) => {
    history.push({ pathname: "/ceditcampaigns", state: { data: CampaignID, PageName: "Campaign" } })
  }

  //Handle click
  const HandleClick = (event, id) => {

    SetAID(id);
    SetAnchorEl(event.currentTarget);
  };

  //Handle close one
  const HandleClickOne = (event, id) => {
    SetAID(id);
    SetfAnchorEl(event.currentTarget);
  };
  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      //document.getElementById("hideloding").style.display = "block";
    }
  };

  //Handle close
  const HandleClose = () => {
    SetAnchorEl(null);
  };

  //Handle close Second
  const HandleCloseSecond = () => {
    SetfAnchorEl(false);
  };
  //handle change anchrEl
  const HandleCloseOne = () => {
    SetfAnchorEl(null);
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    //document.getElementById("hideloding").style.display = "block";
  };

  //Search archive
  const HandleSearch = async () => {

    var ArchiveGet = document.getElementById("include_archive").checked
    SetArchive(ArchiveGet)
    updateChild(ArchiveGet, search);
  }
  //campaign archive and unarchive
  const HandleUnArchive = async (CampaignID, Type) => {
    HandleClose();
    HandleCloseOne();
    Swal.fire({
      title: "Are you sure?",
      text: "you want to " + Type + " a campaign.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, " + Type + " it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then(async (Result) => {
      if (Result.isConfirmed) {

        var StrIn = {
          CampaignID: AID,
          Type: Type,
          UserID: UserID
        }
        var Result = await Axios({
          url: CommonConstants.MOL_APIURL + "/campaign/CampaignArchive",
          method: "POST",
          data: StrIn,
        });
        console.log("Campaign details --->", Result);
        if (Result.data.StatusMessage === "SUCCESS") {
          GetCampaignsList(ClientID, UserID, Role)
          Swal.fire(
            "" + Type + "d" + "!",
            "Campaign " + Type + " successfully.",
            "success"
          );
        }
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });

  }

  //Sorting 
  const SortData = (Field) => {
    // SetIsLoading(true)
    document.getElementById("hideloding").style.display = "block";
    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      setSFlag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        setSortedBy(-1);
      } else {
        SortBy = 1;
        setSortedBy(1);
      }
      setSortField(Field);
      var str_in = {
        page: Page,
        rowsPerPage: RowsPerPage,
        sort: true,
        field: SortField,
        sortby: SortedBy,
        search: SearchedVal,
        type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        Archive: Archive
      };
      const rows1 = Axios({
        url:
          CommonConstants.MOL_APIURL + "/campaign/CampaignGet",
        method: "POST",
        data: str_in,
      });
      rows1.then((result) => {

        setdata(result.data.pagedata);
        setRows(result.data.pagedata);
        setRlen(result.data.totalcount);
        setflen(result.data.totalcount);
        SetCountPage(result.data.pagecount);
        document.getElementById("hideloding").style.display = "none";
      }).catch((error) => {
        // Handle the error here
        // console.error("An error occurred:", error);
        document.getElementById("hideloding").style.display = "none";
      });
    } else {
      SerchBox = true;
      setSFlag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        setSortedBy(-1);
      } else {
        SortBy = 1;
        setSortedBy(1);
      }
      setSortField(Field);
      var str_in = {
        page: Page,
        rowsPerPage: RowsPerPage,
        sort: true,
        field: SortField,
        sortby: SortedBy,
        search: SearchedVal,
        type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        Archive: Archive
      };
      const rows1 = Axios({
        url:
          CommonConstants.MOL_APIURL + "/campaign/CampaignGet",
        method: "POST",
        data: str_in,
      });
      rows1.then((result) => {
        setdata(result.data.pagedata);
        setRows(result.data.pagedata);
        setRlen(result.data.totalcount);
        setflen(result.data.totalcount);
        SetCountPage(result.data.pagecount);
        document.getElementById("hideloding").style.display = "none";
      }).catch((error) => {
        // Handle the error here
        // console.error("An error occurred:", error);
        document.getElementById("hideloding").style.display = "none";
      });
    }
  };

  //campaign delte
  const HandleDelete = async (CampaignID) => {
    HandleClose();
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a campaign.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then(async (Result) => {
      if (Result.isConfirmed) {
        var StrIn = {
          CampaignID: AID,
          UserID: UserID
        }
        var Result = await Axios({
          url: CommonConstants.MOL_APIURL + "/campaign/CampaignDelete",
          method: "POST",
          data: StrIn,
        });
        // console.log("Campaign details --->",Result);

        if (Result.data.StatusMessage === "SUCCESS") {

          //GetCampaignsList(ClientID,UserID,Role)
          Swal.fire(
            "Deleted!",
            "Campaign deleted successfully.",
            "success"
          );
        }
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    })
  }

  const SaveAsGlobal = async (e, Type) => {

    SetType(Type);
    HandleClose();
    HandleCloseOne();
    HandleTemplatePopup()

    var StrIn = {
      CampaignID: AID,
    };
    var Result = await Axios({
      url: CommonConstants.MOL_APIURL + "/campaign/GetCampaignByID",
      method: "POST",
      data: StrIn,
    });
    console.log("Campaign details --->", Result);
    if (Result.data.StatusMessage === "SUCCESS") {
      document.getElementById("NameTemplate").value = Result.data.Data[0].Name + " - Template";
      document.getElementById("NotesTemplate").value = Result.data.Data[0].Notes;
    }

    // console.log(Type,"global - add")
    // var Str_In;
    // if(Type === "Global"){
    //   Str_In = {
    //     CampaignID:AID,
    //     ClientID:0,
    //     UserID:UserID,
    //     Role:Role
    //   }
    // }else{
    //   Str_In = {
    //     CampaignID:AID,
    //     ClientID:ClientID,
    //     UserID:UserID,
    //     Role:Role
    //   }
    // }

    // var AddGlobalTemplate = await Axios({
    //   url:
    //     CommonConstants.MOL_APIURL + "/campaigntemplate/AddCampaignTemplate",
    //   method: "POST",
    //   data: Str_In,
    // });
    // if(AddGlobalTemplate.data.StatusMessage == "SUCCESS"){
    //   GetCampaignsList(ClientID,UserID,Role)
    // }
  }

  //search for record
  const RequestSearch = (Event) => {

    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == search) {
        updateChild(Archive, SearchedVal)
        setSearch(SearchedVal);
        SetPage(1);
      } else {
        updateChild(Archive, SearchedVal)
        setSearch(SearchedVal);
        SetPage(1);
        //document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  // Deplicate campaign
  const DuplicateCampaign = (Event) => {
    HandleClose();
    HandleCloseOne();
    Swal.fire({
      title: "Are you sure?",
      text: "you want to Duplicate a campaign.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Duplicate it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then(async (Result) => {
      if (Result.isConfirmed) {

        var StrIn = {
          CampaignID: AID,
          UserID: UserID,
          ClientID: ClientID,
          Role: Role
        }
        var Result = await Axios({
          url: CommonConstants.MOL_APIURL + "/campaign/CampaignDuplicateAdd",
          method: "POST",
          data: StrIn,
        });
        if (Result.data.StatusMessage === "SUCCESS") {
          GetCampaignsList(ClientID, UserID, Role)
          Swal.fire(
            "Duplicate!",
            "Campaign duplicate successfully.",
            "success"
          );
        }

      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  }

  //Import contact
  const ImportContact = (Event) => {
    history.push({ pathname: "/ceditcampaigns", state: { data: AID, PageName: "ViewContact" } })
  }
  const HandleTemplatePopup = async () => {
    SetErrors({})
    if (TemplatePopup === false) {
      SetTemplatePopup(true)
    } else {
      SetTemplatePopup(false);
    }
  }

  // validation campaign template
  const FromValidation = async () => {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("NameTemplate").value.trim();
    var IsExists = await CampaignTemplateCheckExists(Name); // Change 'Country' to 'Name'

    if (IsExists == "BlankData" && Name === "") { // Check for an empty string or a space in the name
      FormIsValid = false;
      Errors["NameTemplate"] = "Please enter Name"; // Modify the error message
      document.getElementById("hideloding").style.display = "none";


    }

    if (IsExists === "ExistTemplate") {
      FormIsValid = false;
      toast.error(<div>Campaign Global Template <br />
        Campaign global template name already exist!</div>)// Change 'Country' to 'Name'
      document.getElementById("hideloding").style.display = "none";

    }

    SetErrors(Errors)
    return FormIsValid;
  }
  // check exists campaign template name
  const CampaignTemplateCheckExists = async (Name) => {
    if (Name !== "") {
      var CampaignTemplateData
      if (Type === "Global") {
        CampaignTemplateData = {
          ClientID: ClientID,
          UserID: UserID,
          Role: Role,
          Name: Name,
          TemplateType: true
        };
      } else {
        CampaignTemplateData = {
          ClientID: ClientID,
          UserID: UserID,
          Role: Role,
          Name: Name,
          TemplateType: false
        };
      }

      var resdata = await Axios({
        url: CommonConstants.MOL_APIURL + "/campaigntemplate/CampaignCheckExitsByNameTemplate",
        method: "POST",
        data: CampaignTemplateData,
      })
      // if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        SetCampaignTemplateNameAvailable(resdata.data.Data[0]?.Name)

        return "ExistTemplate";
      } else {
        return "AllDone";
      }
      // }
    } else {
      return "BlankData";
    }
  }

  const HandleChange = async (field, e) => {
    let Errors = {}
    const value = e.target.value.trim();
    if (field === "NameTemplate") {

      if (value === "") {
        Errors.NameTemplate = "Please enter name";
      } else {
        Errors.NameTemplate = null;
      }
    }

    SetErrors(Errors)
  }
  // add campaign template 
  const SaveTeplate = async () => {
    document.getElementById("hideloding").style.display = "block";
    var Final_flag = await FromValidation();
    if (Final_flag == true) {
      var Name = document.getElementById("NameTemplate").value
      SetName(Name)
      var Notes = document.getElementById("NotesTemplate").value
      var Str_In;
      if (Type === "Global") {
        Str_In = {
          CampaignID: AID,
          ClientID: ClientID,
          UserID: UserID,
          Role: Role,
          Name: Name,
          Notes: Notes,
          TemplateType: true
        }
      } else {
        Str_In = {
          CampaignID: AID,
          ClientID: ClientID,
          UserID: UserID,
          Role: Role,
          Name: Name,
          Notes: Notes,
          TemplateType: false
        }
      }

      var AddGlobalTemplate = await Axios({
        url:
          CommonConstants.MOL_APIURL + "/campaigntemplate/AddCampaignTemplate",
        method: "POST",
        data: Str_In,
      });
      if (AddGlobalTemplate.data.StatusMessage == "SUCCESS") {
        SetErrors({})
        GetCampaignsList(ClientID, UserID, Role)
        HandleTemplatePopup()
        toast.success(
          <div className="toastsize">
            Campaign Template
            <br />
            Campaign template "{Name}" saved successfully.
          </div>
        );
        document.getElementById("hideloding").style.display = "none";
      } else {
        document.getElementById("hideloding").style.display = "none";
        SetBtnDisabled(false)
      }
    } else {
      SetBtnDisabled(false)
      document.getElementById("hideloding").style.display = "none";
    }

  }

  return (
    <MuiThemeProvider theme={defaultTheme}>
      {
        isDataLoaded === true ? <div id="hideloding" className="loadingchart">
          <img src={loadingicon} />
        </div> : null
      }
      <div className=''>
        <div className="row">
          <div className="col padd-0">
          </div>
          <div className="col padright flexdisplayer py-0 pr-3">
            <div className="fulleriger">
              <label class="check_bx floatrighter mr-3 mt-1">
                <input id="include_archive" type="checkbox" /><span className="checkmark"></span>
                Include archived campaigns
              </label>
              <button class="btn btn-primary btnColor m-btn m-btn--icon m-btn--air" onClick={HandleSearch}>
                <span>
                  <i class="la flaticon-search"></i><span>Search</span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div className="row padt-25">
          <div className="col-sm-12 col-md-6">
            <label className="textlabelte">Show
              <select
                name="tbl_meeting_length"
                onChange={ChangeRowSelected}
                aria-controls="tbl_meeting"
                className="form-control form-control-sm"
                defaultValue={RowsPerPage}
              >
                {CommonConstants.show_rows.map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
              entries</label>
          </div>
          <div className="col-sm-12 col-md-6 full-right">
            <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" onKeyPress={RequestSearch} id="search" /></label>
          </div>
        </div>
        <div className="table-bordered">
          <TableContainer className='table-left-sticky' component={Paper}>
            <StyleHeader isDataLoaded={isDataLoaded} stickyIndex='2' />
            <Table className="table-ref" aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell><a onClick={() => { SortData("Name"); }}>Name<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "Name" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "Name" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell><a onClick={() => { SortData("TotalContacts"); }}>Total Contacts<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "TotalContacts" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "TotalContacts" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell><a onClick={() => { SortData("Sent"); }}>Sent<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "Sent" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "Sent" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell><a onClick={() => { SortData("OpenPercentage"); }}>Open %<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "OpenPercentage" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "OpenPercentage" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell><a onClick={() => { SortData("OpenPercentageL1"); }}>Open % L1<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "OpenPercentageL1" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "OpenPercentageL1" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell><a onClick={() => { SortData("OpenPercentageL7"); }}>Open % L7<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "OpenPercentageL7" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "OpenPercentageL7" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell><a onClick={() => { SortData("OpenPercentageL30"); }}>Open % L30<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "OpenPercentageL30" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "OpenPercentageL30" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell><a onClick={() => { SortData("ReplyPercentage"); }}>Reply %<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "ReplyPercentage" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "ReplyPercentage" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell><a onClick={() => { SortData("BouncePercentage"); }}>Bounce %<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "BouncePercentage" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "BouncePercentage" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell><a onClick={() => { SortData("TotalSendL1"); }}>Sent L1<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "TotalSendL1" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "TotalSendL1" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell><a onClick={() => { SortData("TotalSendL7"); }}>Sent L7<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "TotalSendL7" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "TotalSendL7" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell><a onClick={() => { SortData("ActiveContacts"); }}>Active Contacts<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "ActiveContacts" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "ActiveContacts" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell><a onClick={() => { SortData("ReplyBehaviors"); }}>Reply Behavior<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "ReplyBehaviors" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "ReplyBehaviors" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell><a onClick={() => { SortData("IsIncludeBlacklistedContacts"); }}>Ignore Blacklisted<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "IsIncludeBlacklistedContacts" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "IsIncludeBlacklistedContacts" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell><a onClick={() => { SortData("EmailOpenTrackingData"); }}>Email Open Tracking<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "EmailOpenTrackingData" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "EmailOpenTrackingData" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell><a onClick={() => { SortData("IsSendonWeekends"); }}>Send on Weekends<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "IsSendonWeekends" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "IsSendonWeekends" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell><a onClick={() => { SortData("Priority"); }}>Priority<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "Priority" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "Priority" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell><a onClick={() => { SortData("IsTrackClick"); }}>Track Click<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "IsTrackClick" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "IsTrackClick" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell><a onClick={() => { SortData("TotalTrackClick"); }}>Total Track Click<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "TotalTrackClick" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "TotalTrackClick" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length === 0 ? (
                  <p className="text-center">No data available in table</p>
                ) : (
                  rows?.map((row) => (
                    <React.Fragment key={row?._id}>
                      <TableRow>
                        <TableCell><div class="toggleswich">
                          <input type="checkbox" class="checktogle" onChange={() => { CampaignActiveUpdate(row._id, row.IsActive) }} checked={row?.IsActive} />
                          <b class="switch">
                            <span class="checkion"></span>
                            <span class="uncheckion"></span>
                          </b>
                          <b class="track"></b>
                        </div>
                        </TableCell>
                        <TableCell className='w-250'><a href="" onClick={() => { EditCampaign(row._id) }}><u>{row.Name}</u></a></TableCell>
                        <TableCell>{row.TotalContacts === undefined ? 0 : row.TotalContacts}</TableCell>
                        <TableCell>{row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory.TotalSend}</TableCell>
                        <TableCell><Tooltip title={<>
                          <span>Opens: {row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalOpen}</span><br />
                          <span>Tracked: {row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalTracked}</span><br />
                          <span>Open %: {row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalTracked === 0 ? 0 + "%" : parseInt((row.CampaignStepHistory?.TotalOpen / row.CampaignStepHistory?.TotalTracked) * 100) + "%"}</span>
                        </>} placement="right">
                          <span>{row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalTracked === 0 ? 0 + "%" : parseInt((row.CampaignStepHistory?.TotalOpen / row.CampaignStepHistory?.TotalTracked) * 100) + "%"}</span>
                        </Tooltip></TableCell>
                        <TableCell><Tooltip title={<>
                          <span>Opens:{row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalOpenL1}</span><br />
                          <span>Tracked:{row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalTrackedL1}</span><br />
                          <span>Open %:{row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalTrackedL1 === 0 ? 0 + " %" : parseInt((row.CampaignStepHistory?.TotalOpenL1 / row.CampaignStepHistory?.TotalTrackedL1) * 100) + " %"}</span>
                        </>} placement="right">
                          <span>{row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalTrackedL1 === 0 ? 0 + " %" : parseInt((row.CampaignStepHistory?.TotalOpenL1 / row.CampaignStepHistory?.TotalTrackedL1) * 100) + " %"}</span>
                        </Tooltip></TableCell>
                        <TableCell><Tooltip title={<>
                          <span>Opens: {row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalOpenL7}</span><br />
                          <span>Tracked: {row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalTrackedL7}</span><br />
                          <span>Open %: {row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalTrackedL7 === 0 ? 0 + "%" : parseInt((row.CampaignStepHistory?.TotalOpenL7 / row.CampaignStepHistory?.TotalTrackedL7) * 100) + "%"}</span>
                        </>} placement="right">
                          <span>{row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalTrackedL7 === 0 ? 0 + "%" : parseInt((row.CampaignStepHistory?.TotalOpenL7 / row.CampaignStepHistory?.TotalTrackedL7) * 100) + "%"}</span>
                        </Tooltip></TableCell>
                        <TableCell><Tooltip title={<>
                          <span>Opens: {row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalOpenL30}</span><br />
                          <span>Tracked: {row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalTrackedL30}</span><br />
                          <span>Open %: {row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalTrackedL30 === 0 ? 0 + "%" : parseInt((row.CampaignStepHistory?.TotalOpenL30 / row.CampaignStepHistory?.TotalTrackedL30) * 100) + "%"}</span>
                        </>} placement="right">
                          <span>{row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalTrackedL30 === 0 ? 0 + "%" : parseInt((row.CampaignStepHistory?.TotalOpenL30 / row.CampaignStepHistory?.TotalTrackedL30) * 100) + "%"}</span>
                        </Tooltip></TableCell>
                        <TableCell><Tooltip title={<>
                          <span>Replies: {row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalReply}</span><br />
                          <span>Sent: {row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalSend}</span><br />
                          <span>Reply %: {row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalSend === 0 ? 0 + "%" : parseInt((row.CampaignStepHistory?.TotalReply / row.CampaignStepHistory?.TotalSend) * 100) + "%"}</span>
                        </>} placement="right">
                          <span>{row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalSend === 0 ? 0 + "%" : parseInt((row.CampaignStepHistory?.TotalReply / row.CampaignStepHistory?.TotalSend) * 100) + "%"}</span>
                        </Tooltip></TableCell>
                        <TableCell><Tooltip title={<>
                          <span>Soft Bounce: {row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalSoftBounce}</span><br />
                          <span>Hard Bounce: {row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalHardBounce}</span><br />
                          <span>Total Bounce: {row.CampaignStepHistory === undefined ? 0 : (row.CampaignStepHistory?.TotalSoftBounce + row.CampaignStepHistory?.TotalHardBounce)}</span><br />
                          <span>Sent: {row.CampaignStepHistory?.TotalSend}</span><br />
                          <span>Bounce %: {row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalSend === 0 ? 0 + "%" : Math.ceil(((row.CampaignStepHistory?.TotalSoftBounce + row.CampaignStepHistory?.TotalHardBounce) / row.CampaignStepHistory?.TotalSend) * 100) + "%"}</span>
                        </>} placement="right">
                          <span>{row.CampaignStepHistory === undefined ? 0 + "%" : row.CampaignStepHistory?.TotalSend === 0 ? 0 + "%" : Math.ceil(((row.CampaignStepHistory?.TotalSoftBounce + row.CampaignStepHistory?.TotalHardBounce) / row.CampaignStepHistory?.TotalSend) * 100) + "%"}</span>
                        </Tooltip></TableCell>
                        <TableCell>{row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalSendL1}</TableCell>
                        <TableCell>{row.CampaignStepHistory === undefined ? 0 : row.CampaignStepHistory?.TotalSendL7}</TableCell>
                        <TableCell><Tooltip title={<>
                          <span>TotalContacts:{row.TotalContacts === undefined ? 0 : row.TotalContacts}</span><br />
                          <span>Uncontacted:{row.ActiveCounts === undefined ? 0 : row.ActiveCounts?.unconnected}</span><br />
                          <span>Delete Contact:{row.ActiveCounts === undefined ? 0 : row.ActiveCounts?.DeletedContact}</span><br />
                          <span>Blacklisted:{row.ActiveCounts === undefined ? 0 : row.ActiveCounts?.BlackedListed}</span><br />
                          <span>Paused:{row.ActiveCounts === undefined ? 0 : row.ActiveCounts?.paused}</span><br />
                          <span>Replies:{row.ActiveCounts === undefined ? 0 : row.ActiveCounts?.Replies}</span><br />
                          <span>Bounce:{row.ActiveCounts === undefined ? 0 : row.ActiveCounts?.Bounces}</span><br />
                          <span>Active Contact:{row.ActiveCounts === undefined ? 0 : row.ActiveCounts?.activecontact}</span>
                        </>} placement="right">
                          <span><i className="la flaticon-plus delete-icon"></i></span>
                        </Tooltip></TableCell>
                        <TableCell>{row.ReplyBehavior.length > 0 ? row.ReplyBehavior[0].FieldName : ""}</TableCell>
                        <TableCell>{row.IsIncludeBlacklistedContacts == true ? "Yes" : "No"}</TableCell>
                        <TableCell>{row.EmailOpenTracking.length > 0 ? row.EmailOpenTracking[0].FieldName : ""}</TableCell>
                        <TableCell>{row.IsSendonWeekends == true ? "Yes" : "No"}</TableCell>
                        <TableCell>{row.CampaignPriority.length > 0 ? row.CampaignPriority[0].FieldName : ""}</TableCell>
                        <TableCell>{row.IsTrackClick == true ? "Yes" : "No"}</TableCell>
                        <TableCell>{row.TotalTrackClick == 0 ? "" : row.TotalTrackClick}</TableCell>
                        <TableCell>{row?.IsArchive ? (
                          <a className="btn-eyesicon dopdownwe">
                            <Button
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              onClick={(e) => {
                                HandleClick(e, row?._id);
                              }}
                            >
                              <i className="la la-ellipsis-h add-icon"></i>
                            </Button>
                            <Menu
                              id={row._id}
                              anchorEl={AnchorEl}
                              keepMounted
                              open={Boolean(AnchorEl)}
                              onClose={HandleClose}
                            >
                              <MenuItem
                                style={{ color: "#575962", placeContent: "center", fontSize: "small", padding: "0.3rem" }}
                                onClick={() => {
                                  HandleDelete(row._id);
                                }}
                              >
                                <i className="la flaticon-delete-1 mr-3"></i> Delete
                              </MenuItem>
                              <MenuItem
                                style={{ color: "#575962", placeContent: "center", fontSize: "small", padding: "0.3rem" }}
                                onClick={() => {
                                  HandleUnArchive(row._id, "UnArchive");
                                }}
                              >

                                Unrchive
                              </MenuItem>
                            </Menu>
                          </a>
                        ) : (
                          <div>
                            <a
                              onClick={() => { EditCampaign(row._id) }}
                              className="btn-eyesicon" title='Edit'
                            >
                              <i className="la flaticon-edit-1 edit-icon"></i>
                            </a>
                            <a className="btn-eyesicon dopdownwe">
                              <Button
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={(e) => {
                                  HandleClickOne(e, row?._id);
                                }}
                              >
                                <i className="la la-ellipsis-h add-icon"></i>
                              </Button>
                              <Menu className='blackselect'
                                id={row._id}
                                anchorEl={AnchorElf}
                                keepMounted
                                open={Boolean(AnchorElf)}
                                onClose={HandleCloseOne}
                              >
                                <MenuItem href={`/editcampaigns?data=${row._id}&PageName=ViewContact`} ><i className="la la-file mr-3"></i>Import Contacts</MenuItem>
                                <MenuItem onClick={(e) => { SaveAsGlobal(e, "Client") }}><i className="fa fa-columns mr-3"></i>Save As Template</MenuItem>
                                <MenuItem onClick={(e) => { SaveAsGlobal(e, "Global") }}><i className="fa fa-columns mr-3"></i>Save As Global Template</MenuItem>
                                <MenuItem onClick={(e) => { DuplicateCampaign() }}><i className="la flaticon-add mr-3"></i>Duplicate</MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    HandleUnArchive(row._id, "Archive");
                                  }}
                                >
                                  <i className="la flaticon-download mr-3"></i> Archive
                                </MenuItem>
                              </Menu>
                            </a>
                          </div>
                        )}</TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
                )}

              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Popup open={TemplatePopup}>
          <div>
            <div className="modal-black"></div>
            <div className="filterPopup largerPopup1000">
              <div className="paddingboxTerms">
                <div className="modal-header py-4 px-3">
                  <div className="w-100 d-flex px-3">
                    <h5 className="mb-0">Save campaign as {Type} template</h5>
                    <button
                      className="close"
                      onClick={HandleTemplatePopup}
                    >
                      <span aria-hidden="true" class="la la-remove"></span>
                    </button>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="px-4">
                    <div class="row mb-4">
                      <label class="col-lg-3 col-form-label textright-vmb">
                        Name
                      </label>
                      <div class="col-lg-9 d-flex">
                        <input
                          class="form-control m-input"
                          type="text"
                          id="NameTemplate"
                          onBlur={(e) => HandleChange("NameTemplate", e)}

                        />
                        <span style={{ color: "red" }}>
                          {Errors["NameTemplate"]}
                        </span>
                      </div>
                    </div>

                    <div class="row mb-4">
                      <label class="col-lg-3 col-form-label textright-vmb">
                        Notes
                      </label>
                      <div class="col-lg-9">
                        <input
                          class="form-control m-input"
                          type="text"
                          id="NotesTemplate"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={HandleTemplatePopup}
                  >
                    Cancel
                  </button>
                  <button
                    id="SubmitStepTemplate"
                    onClick={() => {
                      SaveTeplate();
                    }}
                    class="btn btn-primary btnColor"
                  >
                    <i class="la la-save mr-2"></i> Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Popup>
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      </div>
    </MuiThemeProvider>
  );
}
