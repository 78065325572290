import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

class CAddContactSourcepage extends React.Component {
  constructor(props) {
    let Details = GetUserDetails();
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      ContactSourceNameAvailble: null,
      ClientID: Details.ClientID,
      UserIDby: Details.ChildUserID,
      UserID: Details.ParentUserID,
      Role: Details.Role,
      BtnDisabled:false
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = 'Add | SalesHive'
  }

  // form validation
 async FromValidation() {
    let formIsValid = true;
    let Fields = this.state.Fields;
    let Errors = {};
    let Name = document.getElementById("Name").value.trim();
    let IsExists = await this.ContactSourceNameCheckExist(Name)
    if (Name=="") {
      formIsValid = false;
      Errors["Name"] = "Please enter name";
    }
    if(IsExists==true){
      formIsValid = false;
    }
    if (this.state.ContactSourceNameAvailble != null) {
      formIsValid = false;
    }
    this.setState({ Errors: Errors });
    return formIsValid;
  }

  // handle change
  HandleChange(field, e) {
    let Fields = this.state.Fields;
    Fields[field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Name != "") {
      this.state.Errors.Name = null;
      this.state.ContactSourceNameAvailble = null;
      this.ContactSourceNameCheckExist(e.target.value);
    } else {
      this.state.ContactSourceNameAvailble = null;
      this.state.Errors.Name = null;
    }
  }

  // contact source check exits
async  ContactSourceNameCheckExist(Name) {
    let str_in = {
      ClientID: this.state.ClientID,
      Name: Name,
      Role:this.state.Role
    };
   let resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/contactsource/ContactSourceExists",
      method: "POST",
      data: str_in,
    })
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          this.setState({
            ContactSourceNameAvailble: resdata.data.Data[0].Name
          });
          return true;
        }else{
          return false;

        }
      }
    
  }

  // back button
  BackBtn() {
    history.push("/CContactSource");
  }

  // save button
 async SaveBtn() {
  this.setState({BtnDisabled:true})
  let Final_flag =  await this.FromValidation()
    if (Final_flag == true) {
      let Name = document.getElementById("Name").value.trim();
      let data = {
        Name: Name,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
        CreatedBy: this.state.UserIDby,
        CreatedDt: new Date().toISOString(),
        ContactSourceID: 1234,
        type: "User",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contactsource/ContactSourceAdd",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize" >Contact source<br/>Contact Source added successfully.</div>);
          history.push("/CContactSource");
        } else {
          toast.error(res.data.Message);
          this.setState({BtnDisabled:false})
        }
      });
    }else{
      this.setState({BtnDisabled:false})
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad pl-0 mb-0">
                  Add Contact Source
                  </h4>
                </div>
              </div>

            <div className="paddcols">
              <div className="row">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Name</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter field name"
                        id="Name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        name="Name"
                        placeholder="Enter name"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                      {this.state.ContactSourceNameAvailble && (
                        <span style={{ color: "red" }}>
                          Name already exist.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div className="row mb-3 mt-3">
                <div className="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.BtnDisabled}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer/>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCAddContactSourcepage = connect(
  mapState,
  actionCreators
)(CAddContactSourcepage);
export { connectedCAddContactSourcepage as CAddContactSourcepage };
