import React from "react";
import Axios from "axios";

import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";


import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.css';
import 'froala-editor/js/plugins.pkgd.min.js';

import FroalaEditor from "react-froala-wysiwyg";
import Froala from "froala-editor";

import loadingicon from "../../../src/images/loading.gif";

import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { CommonConstants } from "../../_constants/common.constants";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../../src/_helpers/Utility";

var EmailSignaturerVariable = [{ "name": "FromName" },
  { "name": "AliasTitle" },
  { "name": "FromEmail" },
  { "name": "Unsubscribe" },
 // { "name": "SenderTitle" }
  ];
  var Vari = {};
  if (EmailSignaturerVariable.length > 0) {
    for (var i = 0; i < EmailSignaturerVariable.length; i++) {
      if(EmailSignaturerVariable[i].name === "Title"){
        Vari["{Alias" + EmailSignaturerVariable[i].name + "}"] = EmailSignaturerVariable[i].name;
      }else{
        Vari["{" + EmailSignaturerVariable[i].name + "}"] = EmailSignaturerVariable[i].name;
      }
    }
  }

class EmailSignaturePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Signature: null,
      EmailSignatureID: null,
      ClientID: null,
      UserID: null,
      ParentUserID:null,
      PreviewText:"",
      Role:null,
      Errors:{}
    };

    this.EmailSignatureModelChange = this.EmailSignatureModelChange.bind(this);
    this.SaveEmailSignature = this.SaveEmailSignature.bind(this);

  }
  componentDidMount() {
    document.title="Email Signature | SalesHive";
    Froala.RegisterCommand("Variable", {
      title: "Advanced options",
      type: "dropdown",
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      options: Vari,
      callback: function (cmd, val) {
        var editorInstance = this;
        editorInstance.html.insert(val);
      },
    });

    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ChildUserID;
      this.state.ParentUserID = Details.ParentUserID;
      this.state.Role = Details.Role;

    }
    this.EmailSignatureGet();
  }

  // email signature get
  EmailSignatureGet() {
    var str_in = {
      ClientID: this.state.ClientID,
      UserID: this.state.ParentUserID,
      Role:this.state.Role

    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/emailsignature/EmailSignatureList",
      method: "POST",
      data: str_in,
    }).then((resdata) => {

      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          this.setState({ EmailSignatureID: resdata.data.Data[0]._id });
          document.getElementById("unsubscribe").value = resdata.data.Data[0].UnsubscribeText;
          this.setState({ Signature: resdata.data.Data[0].Signature });
          this.EmailSignaturePerview(resdata.data.Data[0]._id)
        }
        else
        {
          document.getElementById("hideloding").style.display = "none";
        }
      }
      else
      {
        toast.error(<div className="toastsize">Email Signature<br />Error Email Signature.</div>);
        document.getElementById("hideloding").style.display = "none";
      }
    });
  }

  // handle change model
  EmailSignatureModelChange(model) {

    this.setState({
      Signature: model,
    });
  }
   config = {
    placeholderText: 'Edit Your Content Here!',
    charCounterCount: false,
    toolbarButtons: ['bold', 'italic', 'underline', 'insertLink', 'insertImage', 'html', 'Variable'],
    //shortcutsEnabled: ["insertTemplateButton"],
    imageUploadURL: CommonConstants.MOL_APIURL + "/meetingremindersetting/upload_image",
    imageUploadRemoteUrls: false,
    enter: Froala.ENTER_BR,
    key:CommonConstants.EditorValidationKey,
    height: 300,
    pastePlain: true,
    attribution: false
  }
  //Form Validation 
  FormValidation(){
    let IsValid = true;
    var UnsubscribeText= document.getElementById("unsubscribe").value.trim()
    let errors={}
    if(UnsubscribeText ==""){
      errors['Unsubscribe'] ="Please enter unsubscribe text";
      this.setState({Errors:errors});
      IsValid = false;
    }else{
      errors['Unsubscribe'] ="";
      this.setState({Errors:errors});
    }
    if (this.state.Signature == null || this.state.Signature.replace(/<br>/g, '').replace(/&nbsp;/g, '').trim() === "") {
      toast.error(<div className="toastsize">Edit Email Signature<br />Signature text is empty, fill it!</div>);
      IsValid = false;
    }
    return IsValid;
  }

  //OnBlur Handler
  HandlerValid(e){
    var UnsubscribeText= document.getElementById("unsubscribe").value.trim()
    let errors={}
    if(UnsubscribeText ==""){
      errors['Unsubscribe'] ="Please enter unsubscribe text";
      this.setState({Errors:errors});
    }else{
      errors['Unsubscribe'] ="";
      this.setState({Errors:errors});
    }
  }

  // save email signature
  SaveEmailSignature(e) {
    if(this.FormValidation()){
      document.getElementById("hideloding").style.display = "block";
    var UnsubscribeText= document.getElementById("unsubscribe").value.trim()
      e.preventDefault();
      var data = {
        EmailSignatureID: this.state.EmailSignatureID,
        ClientID: this.state.ClientID,
        UserID: this.state.ParentUserID,
        Signature: this.state.Signature.replace(/<[\/]{0,1}(p)[^><]*>/ig, ''),
        UnsubscribeText:UnsubscribeText,
        Role:this.state.Role
      };
  
      Axios({
        url: CommonConstants.MOL_APIURL + "/emailsignature/EmailSignatureAdd",
        method: "POST",
        data: data,
      }).then((res) => {
  
        if (res.data.StatusMessage == "SUCCESS") {
          this.state.EmailSignatureID == res.data.Data._id;
          this.EmailSignaturePerview(res.data.Data._id)
          toast.success(<div className="toastsize">Edit Email Signature Setting<br/>Email signature updated successfully!</div>);
          this.componentDidMount()
  
        } else {
          toast.error("Error while save signature detail, Please try again!");
          document.getElementById("hideloding").style.display = "none";
          this.setState({BtnDisabled:false})
        }
      });
    }
  }

   EmailSignaturePerview=(EmailSignatureID)=>{
    var data = {
      EmailSignatureID:EmailSignatureID,
      ClientID: this.state.ClientID,
      UserID: this.state.ParentUserID,
      Role:this.state.Role

    }
    Axios({
      url: CommonConstants.MOL_APIURL + "/emailsignature/EmailSignaturePreview",
      method: "POST",
      data: data
    }).then(res => {
      if (res.data.StatusMessage = "SUCCESS") {
        this.setState({ PreviewText: res.data.Data });
        document.getElementById("hideloding").style.display = "none";
      }
      else {
        toast.error(<div className="toastsize">Email Signature<br />Error Email Signature.</div>);
        document.getElementById("hideloding").style.display = "none";
      }
  });
  }
  render() {
    return (
      <>
      <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div class='bg-white px-3 my-4'>
              <div className="row border-bottom align-items-center justify-content-between">
                <div className="col">
                  <h4 className="headertitlebd xs-headertitle float-left">Email Signature</h4>
                </div>
                <div className="col">
                <button 
                    id="submit" 
                    onClick={this.SaveEmailSignature} 
                    disabled={this.state.BtnDisabled}
                    class="btn btn-primary btn-lightgreen d-flex ml-auto align-items-center" 
                    >
                      <i class="la la-save mr-1"></i> Save
                    </button>
                </div>
              </div>
              
            <div class="">
              <div className="row borderbtnm">
                <div class="col-lg-12 boxsinput_group mt-4">
                  <div class="row">
                    <label class="col-lg-3 mb-0 align-right">
                      Email Signature Text
                    </label>
                    <div class="col-lg-9">
                      <FroalaEditor model={this.state.Signature} onModelChange={this.EmailSignatureModelChange} config={this.config} id="signature"/>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 boxsinput_group mb-2">
                  <div class="row">
                    <label class="col-lg-3 mb-0 align-right">
                      Unsubscribe Text
                    </label>
                    <div class="col-lg-9">
                      <input type="text" class="form-control m-input" onBlur={this.HandlerValid.bind(this)} id="unsubscribe" name="unsubscribe" placeholder="Enter unsubscribe text" />
                      <span style={{ color: "red" }}>
                      
                          {this.state.Errors['Unsubscribe']}
                        </span>
                    </div>
                  </div>
                </div>

              </div>

              <div className="row borderbtnm px-0 mb-3">
                <div className="col">
                  <h4 className="headertitlepad lg-headertitle float-left mb-0">Preview</h4>


                </div>
                    {/* {this.state.PreviewText} */}
              </div>
              <div className="row px-4">
                <div className="col">
                  <div dangerouslySetInnerHTML={{__html: this.state.PreviewText}}/>
                </div>
              </div>

            </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEmailSignaturePage = connect(
  mapState,
  actionCreators
)(EmailSignaturePage);
export { connectedEmailSignaturePage as EmailSignaturePage };
