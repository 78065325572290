import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from '../../../_components/user/footer/footer';
import Axios from "axios";
import { history } from "../../../_helpers";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loadingicon from "../../../images/loading.gif";

toast.configure();

class AddGlobalBlacklistPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      fields: {},
      errors: {},
      ClientID: Details.ClientID,
      UserID: Details.ChildUserID,
      PUserID:Details.ParentUserID,
      IsExist: false,
      IsValid:true,
      IsDisabled:false,
      IsLoading:false
    };
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {}

  //Onblur form validation method
  async BlurValidtion(){
    let formIsValid = true;
    let errors = {};
  var Domain = document.getElementById("Domain").value.trim();
  var Exist = await this.CheckExistDomain(Domain);
  var IsValid = await this.DomainCheck(Domain);
     if (Domain.length == 0) {
      formIsValid = false;
      errors["Domain"] = "Please enter domain";
     }else  if (Exist) {
      formIsValid = false;
      errors["Domain"] = "Domain already exist.";
    }else if(!IsValid){
      formIsValid = false;
      errors["Domain"] = "Invalid email domain";
    }
    this.setState({ errors: errors });
  }
 async FormValidation() {
    let formIsValid = true;
      let errors = {};
    var Domain = document.getElementById("Domain").value.trim();
    var Exist = await this.CheckExistDomain(Domain);
    var IsValid = await this.DomainCheck(Domain);
       if (Domain.length == 0) {
        formIsValid = false;
        errors["Domain"] = "Please enter domain";
       }else  if (Exist) {
        formIsValid = false;
        errors["Domain"] = "Domain already exist.";
      }else if(!IsValid){
        formIsValid = false;
        errors["Domain"] = "Invalid email domain";
      }
     
      
      this.setState({ errors: errors });
    
    return formIsValid;
  }

  //Domain Check 
 async DomainCheck(Domain){
  
  let regexp = /^(?!:\/\/)([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}$/;
  
    if (regexp.test(Domain))
      {
        this.setState({IsValid:true})
        return true;
      }
    else
      {
        this.setState({IsValid:false})
        return false;
      }
  }

  // check exit domain
 async CheckExistDomain() {
    var Domain = document.getElementById("Domain").value;
    var InputParameter = {
      Domain: Domain,
      UserID: this.state.PUserID
    };
   let resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/globaldomainblacklist/GlobalDomainBlacklistExists",
      method: "POST",
      data: InputParameter,
    })
      let errors = {};
      if (resdata.data.Data > 0) {
        let fields = this.state.fields;
        if (!fields["Domain"]) {
                errors["Domain"] = "Domain already exist.";
        }
        this.setState({ IsExist: true });
        this.setState({ errors: errors }); 
         this.setState({ errors: errors }); 

        return true;
      }else{
        errors["Domain"] = "";
        this.setState({ IsExist: false });
        this.setState({ errors: errors }); 

        return false;

      }
  }

  backbtn() {
    history.push({pathname:"/globalblacklist",state:{page:"domain"}});
  }
  async savebtn(e) {

    e.preventDefault();
    e.currentTarget.disabled = true;
    this.setState({IsDisabled:true})

    this.state.errors.Domain = null;
    let FromValid = await this.FormValidation();
    if(FromValid == true && this.state.IsExist == false){
      this.setState({IsLoading:true})
      var Domain = document.getElementById("Domain").value;

      var data = {
        Domain: Domain,
        UserID: this.state.PUserID,
        CreatedBy: this.state.UserID,
        CreatedDt: new Date(),
      };
      
      Axios({
        url: CommonConstants.MOL_APIURL + "/globaldomainblacklist/GlobalDomainBlacklistAdd",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize">Global Domain Blacklist<br/>Global domain blacklist added successfully.</div>);
          history.push({pathname:"/globalblacklist",state:{page:"domain"}});
          this.setState({IsLoading:false})
          
        } else {
          toast.error(res.data.Message);
          this.setState({IsDisabled:false})
          this.setState({IsLoading:false})

        }
      });
    
    }else{
      this.setState({IsDisabled:false})
      this.setState({IsLoading:false})
    }
  }

  render() {
    return (
      <>
      {this.state.IsLoading ?  <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : null}
     
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad lg-headertitle float-left pl-0">Add</h4>
                </div>
              </div>

            <div className="bg-white p-3">
              <div className="row">  
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Domain</label>
                    <div className="col-lg-8">
                      <input className="form-control m-input" 
                      onBlur={()=>{this.BlurValidtion()}} 
                      id="Domain" placeholder="Enter Domain" type="text" />
                    <span style={{ color: "red" }}>{this.state.errors["Domain"]}</span>

                    </div>
                  </div>
                </div>  
              </div>
            </div>

            <div className="row my-4">
              <div className="col-lg-12 pull-left">
                <button
                  id="submit"
                  onClick={this.savebtn}
                  className="btn btn-primary btn-lightgreen mr-1"
                  disabled={this.state.IsDisabled}
                >
                  <i className="la la-save"></i> Save
                </button>
                <a
                  id="backtolist"
                  onClick={this.backbtn}
                  className="btn btn-secondary"
                >
                  <i className="la la-arrow-circle-left"></i> Back
                </a>
              </div>
            </div> 
            
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddGlobalBlacklistPage = connect(
  mapState,
  actionCreators
)(AddGlobalBlacklistPage);
export { connectedAddGlobalBlacklistPage as AddGlobalBlacklistPage };
