import React, { useEffect } from "react";
import Swal from "sweetalert2";
import Axios from "axios";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../../_constants/common.constants";
import { GetUserDetails } from "../../../../_helpers/Utility";
import { history } from "../../../../_helpers";
import loadingicon from "../../../../images/loading.gif";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../../StickyHeader/StickyHeader";

export default function ContactReasonUnqualified() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("OrderBy");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [DeleteDataDropdown,SetDeleteDataDropdown] = React.useState([]);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [CUserID,SetCUserID] = React.useState(0);
  const [sortedColumn, setSortedColumn] = React.useState("OrderBy");
  const [TotalContactReason, SetTotalContactReason] = React.useState(0)
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    let Details = GetUserDetails();
    document.title = 'Contact Reason Unqualified | SalesHive';

    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    ContactReasonUnquailifiedListGet(
      Details.ClientID,
      Details.ParentUserID,
      Details.Role
    );
  }, [Search, Page, RowsPerPage]);

  // contact reason unqualified get
  const ContactReasonUnquailifiedListGet = (CID, UID, URole) => {
    SetIsLoading(true)
    let ContactReasonUnqualifiedData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const ApiResponse = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/contactreasonunqualified/ContactReasonUnqualifiedGet",
      method: "POST",
      data: ContactReasonUnqualifiedData,
    });
    ApiResponse.then((result) => {
      
      if(result.data?.StatusMessage === "SUCCESS"){
        SetData(result.data.PageData);
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
        SetDeleteDataDropdown(result.data.DeleteDropDownData);
        SetTotalContactReason(result.data.TotalContactReason);
        SetIsLoading(false)
        setIsDataLoaded(true);

      }else{
        const errorMessage =
        result?.data && result.data?.Message ? result.data?.Message : "Unknown error";
        toast.error(errorMessage);
      }
    });
  };

  // changeRow selected
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if(NewPage==Page){
      SetPage(NewPage);
    }else{
      SetPage(NewPage);
    }
  };

  //get sort field data
  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");
    let InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Search: SearchedVal,
      Sort: true,
      Field: Field,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
    };
    
    const ApiResponse = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/contactreasonunqualified/ContactReasonUnqualifiedGet",
      method: "POST",
      data: InputParameter,
    });
    ApiResponse.then((Result) => {
      if(Result.data?.StatusMessage === "SUCCESS"){
        SetData(Result.data.PageData);
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetDeleteDataDropdown(Result.data.DeleteDropDownData);
        SetTotalContactReason(Result.data.TotalContactReason);
        SetPage(1)
        SetIsLoading(false)
      }else{
        const errorMessage =
        Result?.data && Result.data?.Message ? Result.data?.Message : "Unknown error";
        toast.error(errorMessage);
      }

    });


    // let SearchedVal = document.getElementById("search").value;
    // let SerchBox;
    // if (SearchedVal == "") {
    //   SetIsLoading(true)
    //   SerchBox = false;
    //   SetSflag(false);
    //   let SortField = Field;
    //   let SortBy;
    //   if (SortedBy == 1) {
    //     SortBy = -1;
    //     SetSortedBy(-1);
    //   } else {
    //     SortBy = 1;
    //     SetSortedBy(1);
    //   }
    //   SetSortField(Field);
    //   let InputParameter = {
    //     Page: Page,
    //     RowsPerPage: RowsPerPage,
    //     SerchBox: false,
    //     Archive: false,
    //     Sort: true,
    //     Field: SortField,
    //     SortBy: SortBy,
    //     Type: "User",
    //     ClientID: ClientID,
    //     UserID: UserID,
    //     Role: Role,
    //   };
    //   const ApiResponse = Axios({
    //     url:
    //       CommonConstants.MOL_APIURL +
    //       "/contactreasonunqualified/ContactReasonUnqualifiedGet",
    //     method: "POST",
    //     data: InputParameter,
    //   });
    //   ApiResponse.then((Result) => {
        
    //     if(Result.data?.StatusMessage === "SUCCESS"){
    //       SetData(Result.data.PageData);
    //       SetRows(Result.data.PageData);
    //       SetRlen(Result.data.TotalCount);
    //       SetFlen(Result.data.TotalCount);
    //       SetCountPage(Result.data.PageCount);
    //       SetDeleteDataDropdown(Result.data.DeleteDropDownData);
    //       SetIsLoading(false)
    //     }else{
    //       const errorMessage =
    //       Result?.data && Result.data?.Message ? Result.data?.Message : "Unknown error";
    //       toast.error(errorMessage);
    //     }
    //   });
    // } else {
    //   SetIsLoading(true)
    //   SerchBox = true;
    //   SetSflag(true);
    //   let SortField = Field;
    //   let SortBy;
    //   if (SortedBy == 1) {
    //     SortBy = -1;
    //     SetSortedBy(-1);
    //   } else {
    //     SortBy = 1;
    //     SetSortedBy(1);
    //   }
    //   SetSortField(Field);
    //   let InputParameter = {
    //     Page: Page,
    //     RowsPerPage: RowsPerPage,
    //     search: SearchedVal,
    //     SerchBox: SerchBox,
    //     Archive: false,
    //     Sort: true,
    //     Field: SortField,
    //     SortBy: SortBy,
    //     Type: "User",
    //     ClientID: ClientID,
    //     UserID: UserID,
    //     Role: Role,
    //   };
      
    //   const ApiResponse = Axios({
    //     url:
    //       CommonConstants.MOL_APIURL +
    //       "/contactreasonunqualified/ContactReasonUnqualifiedGet",
    //     method: "POST",
    //     data: InputParameter,
    //   });
    //   ApiResponse.then((Result) => {
    //     if(Result.data?.StatusMessage === "SUCCESS"){
    //       SetData(Result.data.PageData);
    //       SetRows(Result.data.PageData);
    //       SetRlen(Result.data.TotalCount);
    //       SetFlen(Result.data.TotalCount);
    //       SetCountPage(Result.data.PageCount);
    //       SetDeleteDataDropdown(Result.data.DeleteDropDownData);
    //       SetIsLoading(false)
    //     }else{
    //       const errorMessage =
    //       Result?.data && Result.data?.Message ? Result.data?.Message : "Unknown error";
    //       toast.error(errorMessage);
    //     }

    //   });
    // }
  };

  const DeleteBtn = async (id, CSName) => {
     let Datas = DeleteDataDropdown.filter(obj => obj._id !== id);
    let ContactReasonUnqualifiedData = [];
    Datas.map((source) => {
      ContactReasonUnqualifiedData[source._id] = `${source.Reason} `;
    });

    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a contact reason unqualified.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!", 
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Replace Account Reason Unqualified",
          text: "Which value replace with '" + CSName + "' contact reason unqualified?",
          confirmButtonColor: "#34bfa3",
          cancelButtonColor: "#34bfa3",
          confirmButtonText: "Save",
          cancelButtonText: "Cancel",
          reverseButtons: true,
          showCancelButton: true,
          input: "select",
          inputOptions: ContactReasonUnqualifiedData,
          inputPlaceholder: ContactReasonUnqualifiedData[0],
          position: 'top',
          showCloseButton: true,
          customClass: { 
            popup: 'replaceaccountpopup', 
            confirmButton: 'btn btn-primary btnColor',
          }
        }).then((Succes) => {
          if (Succes.isConfirmed) {
            let UpdateId = Succes.value;
            let UpdatingParameters = {
              _id: id,
              NewID: UpdateId,
              LastUpdatedBy: CUserID,
              LastUpdatedDate: new Date(),
              Role: Role,
              ContactReasonUnquaStatus:ContactReasonUnqualifiedData[Succes.value],
              ClientID:ClientID
            };
            Axios({
              url:
                CommonConstants.MOL_APIURL +"/contactreasonunqualified/ContactReasonUnqualifiedDelete",
              method: "POST",
              data: UpdatingParameters,
            }).then(async (res) => {
              
              if (res) {
                if ((res.statusText = "Ok")) {
                  if (res.data.StatusMessage == "SUCCESS") {
                    Swal.fire(
                      "Deleted!",
                      "Contact reason unqualified deleted successfully.",
                      "success"
                    );
                      ContactReasonUnquailifiedListGet(ClientID, UserID, Role);
                    history.push("/contactreasonunqualified");
                    SetPage(1);
                    SetSearch("");
                    document.getElementById("search").value = "";
                  }
                }
              }
            });
          } else if (Succes.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelled", "Your data is safe :)", "error");
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };
  // Edit page
  const EditBtn = (id) => {
    history.push({
      pathname: "/editcontactreasonunqualified",
      state: { data: id },
    });
  };

  return (
    <div>
      {
        IsLoading === true ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>
      }
      <div className='p-3 bg-white'>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row pt-3">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />

<Table className="table-ref"  aria-label="collapsible table">            <TableHead>
              <TableRow>
                <TableCell   onClick={() => {
                      SortData("Reason");
                      setSortedColumn("Reason");
                    }}>
     
           
                    Name
                 
                  <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "Reason" ? "active" : null} />
                         <ArrowDownward className={SortedBy === -1 && sortedColumn === "Reason" ? "active" : null} />
                          </span>
                 
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell style={{  overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}>{row.Reason}</TableCell>
                    <TableCell>
                      <a
                        title="Edit"
                        onClick={() => {
                          EditBtn(row._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i className="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      {
                         
                         TotalContactReason <= 1 ? <></> :<a
                          title="Delete"
                          onClick={() => {
                            DeleteBtn(row._id,row.Reason);
                          }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-delete-1 delete-icon"></i>
                        </a>
                      }
                      
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
      
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
        </div>
    </div>
  );
}
