import React, { useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
const moment = require("moment");

import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../StickyHeader/StickyHeader";

export default function ClientMeeting(props) {
  const [Open, SetOpen] = React.useState(false);
  const [data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("EventCreatedDt");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [Notes, SetNotes] = React.useState([]);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [CheckSearch, SetCheckSearch] = React.useState(true);
  const [Role, SetRole] = React.useState(0);
  const [matchingNoteFound, setMatchingNoteFound] = React.useState(false);
  const [IsLoading,SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0)
  const [sortedColumn, setSortedColumn] = React.useState("EventCreatedDt");
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

    // You can access props directly or use destructuring.
    const { updateFromChild, FromDates, ToDates } = props;

  useEffect(() => {
    document.title = `Client Meetings | SalesHive`;
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    GetClientMeetingList(
      GetUserData.ClientID,
      GetUserData.ParentUserID,
      GetUserData.Role,
      FromDates,
      ToDates,
      CheckSearch
    );
  }, [Search, Page, RowsPerPage, CheckSearch,FromDates,ToDates]);

  //get client meeting
  const GetClientMeetingList = (CID, UID, URole,FromDateMeeting, ToDateMeeting,CheckSearchlist) => {
    SetIsLoading(true)
    var str_in = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      CheckedSearch: CheckSearchlist,
      ToDate: ToDateMeeting,
      FromDate: FromDateMeeting,
    };
    const Rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/clientmeeting/ClientMeetingGet",
      method: "POST",
      data: str_in,
    });
    Rows1.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetNotes(result.data.MeetingNotes);
      SetIsLoading(false)
      setIsDataLoaded(true);


    });
  };

  //Search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  //delete Client meeting
  const DeleteClientName = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete an Event.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          ID: id,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL + "/clientmeeting/ClientMeetingDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Client meeting deleted successfully.",
                "success"
              );
              props.updateFromChild(true);
              // GetClientMeetingList(ClientID, UserID, Role,FromDates,ToDates);
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //change display Rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  //render on view Page
  const ViewPage = (id) => {
    // history.push("/viewbookedmeeting", id);
    history.push({
      pathname: "/viewbookedmeeting",
      state: { data: id, Pagename: "ClientMeeting" },
    });
  };

  //Reset view
  const ResetView = () => {
    SetCheckSearch(true);
    document.getElementById("resetview").style.display = "none";
    props.updateFromChildOldClient(true);
  };

  //Search Box
  const SearchData = () => {
    let IsCheckedSearch = document.getElementById("includeSourceCheck").checked;
    SetCheckSearch(IsCheckedSearch);
    props.updateFromChildOldClient(IsCheckedSearch);
  };

  // Reset filter
  const ResetFilter = () => {
    SetCheckSearch(true);
    document.getElementById("includeSourceCheck").checked=true
  };

  //get sort field data
  const SortData = (FieldName) => {
    let Field 
    if(FieldName=="Title"){
      Field="JobTitle"
    }else if(FieldName=="MeetingSetDate"){
      Field="EventCreatedDt"
    }else if(FieldName=="MeetingDate"){
      Field="EventStartDt"
    } else if(FieldName=="CreatedDate"){
      Field="CreatedDt"
     } else if(FieldName=="MeetingStatus"){
      Field="Status"
     } else if(FieldName=="Owner"){
      Field="MeetingOwner"
     } else if(FieldName=="SendReminder"){
      Field="IsSendMeetingReminder"
     }else if(FieldName=="QualifiedDate"){
      Field="QulifiedDate"
     } else if(FieldName=="MeetingSetBy"){
      Field="ResponserName"
     }else{
      Field=FieldName
     }
    var SearchedVal = document.getElementById("search").value;
    SetIsLoading(true)

    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: Search,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        CheckedSearch: CheckSearch,
        ToDate: ToDates,
        FromDate: FromDates,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/clientmeeting/ClientMeetingGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false)

      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: Search,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        CheckedSearch: CheckSearch,
        ToDate: ToDates,
        FromDate: FromDates,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/clientmeeting/ClientMeetingGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false)

      });
    }
  };

  const EditContactHandle = (id, Email) => {
    if (id !== undefined && id !== null && id !== "000000000000000000000000") {
      history.push({
        pathname: "/viewcontacts",
        state: { data: id, EmailData: Email, Pagename: "ClientBookedMeeting" },
      });
    }
  };

  return (
    <div>
      {
        IsLoading ?  <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>
      }
     <div class='bg-white'>
      <div className="row py-4 whiterow border-0 mx-0">
        <div className="col col-lg-5">
          <a onClick={SearchData} class="btn text-white btnColor">
            <i class="la la-search"></i>Search
          </a>
          <a onClick={ResetFilter} class="ml-3 btn btn-secondary">
            <i class="la la-close"></i> Reset
          </a>
        </div>
        <div className="col col-lg-3"></div>
        <div className="col col-lg-4">
          <label class="check_bx float-right">
            <input
              id="includeSourceCheck"
              type="checkbox"
              defaultChecked={true}
            />
            <span className="checkmark"></span>
            Include Old Clients
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row pt-2 px-3">
        <div className="col-sm-12" id="resetview" style={{ display: "none" }}>
          <a
            onClick={() => {
              ResetView();
            }}
          >
            Reset
          </a>
        </div>
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>

        <div className="col-sm-12 col-md-6 full-right border-bttom">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered border-0 px-3">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
          <Table className="table-ref"  aria-label="collapsible table">
                        <TableHead>
            <TableRow>
            <TableCell>Notes</TableCell>
            <TableCell>Action</TableCell>
            <TableCell className="w-250" onClick={() => { SortData("Status"); setSortedColumn("Status"); }}>
              Meeting Status
              <span className="shorting">
                <ArrowUpward className={SortedBy === 1 && sortedColumn === "Status" ? "active" : null} />
                <ArrowDownward className={SortedBy === -1 && sortedColumn === "Status" ? "active" : null} />
              </span>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("ClientName"); setSortedColumn("ClientName"); }}>
                Client Name
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "ClientName" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "ClientName" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("ContactName"); setSortedColumn("ContactName"); }}>
                Contact Name
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "ContactName" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "ContactName" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("Company"); setSortedColumn("Company"); }}>
                Company
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Company" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Company" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("JobTitle"); setSortedColumn("JobTitle"); }}>
                Title
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "JobTitle" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "JobTitle" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("Email"); setSortedColumn("Email"); }}>
                Email
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Email" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Email" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("Domain"); setSortedColumn("Domain"); }}>
                Domain
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Domain" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Domain" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("MeetingSource"); setSortedColumn("MeetingSource"); }}>
                Meeting Source
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "MeetingSource" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "MeetingSource" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("MeetingSourceTy"); setSortedColumn("MeetingSourceTy"); }}>
                Meeting Source Type
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "MeetingSourceTy" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "MeetingSourceTy" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("MeetingOwner"); setSortedColumn("MeetingOwner"); }}>
                Owner
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "MeetingOwner" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "MeetingOwner" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("ResponserName"); setSortedColumn("ResponserName"); }}>
                Meeting Set By
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "ResponserName" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "ResponserName" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("EventStartDt"); setSortedColumn("EventStartDt"); }}>
                Meeting Date
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "EventStartDt" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "EventStartDt" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("EventCreatedDt"); setSortedColumn("EventCreatedDt"); }}>
                Meeting Set Date
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "EventCreatedDt" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "EventCreatedDt" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("IsSendMeetingReminder"); setSortedColumn("IsSendMeetingReminder"); }}>
                Send Reminder
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "IsSendMeetingReminder" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "IsSendMeetingReminder" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("LinkedInUrl"); setSortedColumn("LinkedInUrl"); }}>
                LinkedIn Url
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "LinkedInUrl" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "LinkedInUrl" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("TimeZoneName"); setSortedColumn("TimeZoneName"); }}>
                Time Zone
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "TimeZoneName" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "TimeZoneName" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("ContractValue"); setSortedColumn("ContractValue"); }}>
                Contract Value
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "ContractValue" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "ContractValue" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("Industry"); setSortedColumn("Industry"); }}>
                Industry
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Industry" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Industry" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("DirectPhone"); setSortedColumn("DirectPhone"); }}>
                Direct Phone
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "DirectPhone" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "DirectPhone" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("CompanyPhone"); setSortedColumn("CompanyPhone"); }}>
                Company Phone
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "CompanyPhone" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "CompanyPhone" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("DaysInStage"); setSortedColumn("DaysInStage"); }}>
                Days In Stage
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "DaysInStage" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "DaysInStage" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("LastStatusChangeDate"); setSortedColumn("LastStatusChangeDate"); }}>
                Last Status Change Date
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "LastStatusChangeDate" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "LastStatusChangeDate" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("CreatedDt"); setSortedColumn("CreatedDt"); }}>
                Created Date
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "CreatedDt" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "CreatedDt" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("QulifiedDate"); setSortedColumn("QulifiedDate"); }}>
                Qualified Date
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "QulifiedDate" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "QulifiedDate" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("OpportunityDate"); setSortedColumn("OpportunityDate"); }}>
                Opportunity Date
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "OpportunityDate" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "OpportunityDate" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("ClosedWonDate"); setSortedColumn("ClosedWonDate"); }}>
                Closed Won Date
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "ClosedWonDate" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "ClosedWonDate" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("ClosedLostDate"); setSortedColumn("ClosedLostDate"); }}>
                Closed Lost Date
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "ClosedLostDate" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "ClosedLostDate" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("UnqualifiedDate"); setSortedColumn("UnqualifiedDate"); }}>
                Unqualified Date
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "UnqualifiedDate" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "UnqualifiedDate" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("MeetingStatusType"); setSortedColumn("MeetingStatusType"); }}>
                Meeting Status Type
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "MeetingStatusType" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "MeetingStatusType" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>
              <a onClick={() => { SortData("Seniority"); setSortedColumn("Seniority"); }}>
                Seniority
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Seniority" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Seniority" ? "active" : null} />
                </span>
              </a>
            </TableCell>
            <TableCell>Action</TableCell>
          </TableRow>

            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <>
                    <TableRow key={row._id}>
                      <TableCell align="center">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() =>
                            SetOpen((prev) => ({
                              // ...prev,
                              [row._id]: !prev[row._id],
                            }))
                          }
                        >
                          {Open[row._id] ? (
                            <i
                              className="fa fa-minus-circle minusl"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className="fa fa-plus-circle plusbl"
                              aria-hidden="true"
                            ></i>
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <a
                         href={`/viewbookedmeeting?ID=${row?._id}&pagename=ClientMeeting`}
                         onClick={(e) => {
                           e.preventDefault();
                           ViewPage(row?._id);
                         }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-eye edit-icon"></i>
                        </a>
                      </TableCell>
                      <TableCell>{row.Status}</TableCell>
                      <TableCell>{row.ClientName}</TableCell>
                      <TableCell>  <a
                                href={`/viewcontacts?id=${row?.Prospectid}&email=${encodeURIComponent(row?.Email)}&pagename=ClientBookedMeeting`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    EditContactHandle(row?.Prospectid, row?.Email);
                                }}
                              >
                                <u>{row?.ContactName}</u>
                              </a></TableCell>
                      <TableCell>{row.Company}</TableCell>
                      <TableCell>{row.Title}</TableCell>
                      <TableCell>{row.Email}</TableCell>
                      <TableCell>
                              <a
                                href={`http://${row?.Email?.substring(
                                  row?.Email?.lastIndexOf("@") + 1
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  textDecoration: "underline",
                                  color: "inherit",
                                }}
                              >
                                <u>
                                  {" "}
                                  {row?.Email?.substring(
                                    row?.Email?.lastIndexOf("@") + 1
                                  )}
                                </u>
                              </a>
                            </TableCell>
                      <TableCell>{row.MeetingSource}</TableCell>
                      <TableCell>{row.MeetingSourceTy}</TableCell>
                      <TableCell>{row.MeetingOwner}</TableCell>
                      <TableCell>
                        {row?.ResponserName}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {row?.EventStartDt != null && row?.EventStartDt !== "Invalid date"
                                ? row?.EventStartDt
                                : ""}
                      </TableCell>
                      <TableCell>
                      {row?.EventCreatedDt != null
                                ? row?.EventCreatedDt
                                : ""}
                      </TableCell>

                      <TableCell>{row?.IsSendMeetingReminder == true?"Yes":"No"}</TableCell>
                      <TableCell>{row?.LinkedInUrl}</TableCell>
                      <TableCell>{row?.TimeZoneName}</TableCell>
                      <TableCell>{row?.ContractValue}</TableCell>
                      <TableCell>{row?.Industry}</TableCell>
                      <TableCell>{row?.DirectPhone}</TableCell>
                      <TableCell>{row?.CompanyPhone}</TableCell>
                      <TableCell> {row?.DaysInStage &&
                                Math.floor(row.DaysInStage)}</TableCell>
                      <TableCell>
                      {row?.LastStatusChangeDate != null
                                ? moment(
                                    row?.LastStatusChangeDate
                                  ).format("MM/DD/YYYY")
                                : ""}
                      </TableCell>
                      <TableCell>
                        {row?.CreatedDt != null
                          ? moment(row?.CreatedDt).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </TableCell>
                      <TableCell>
                      {row?.QulifiedDate != null
                                ? moment(row?.QulifiedDate).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                      </TableCell>
                      <TableCell>
                      {row?.OpportunityDate != null
                                ? moment(
                                    row?.OpportunityDate
                                  ).format("MM/DD/YYYY")
                                : ""}
                      </TableCell>
                      <TableCell>
                      {row?.ClosedWonDate != null
                                ? moment(
                                    row?.ClosedWonDate
                                  ).format("MM/DD/YYYY")
                                : ""}
                      </TableCell>
                      <TableCell>
                      {row?.ClosedLostDate != null
                                ? moment(
                                    row?.ClosedLostDate
                                  ).format("MM/DD/YYYY")
                                : ""}
                      </TableCell>
                      <TableCell>
                      {row?.UnqualifiedDate != null
                                ? moment(
                                    row?.UnqualifiedDate
                                  ).format("MM/DD/YYYY")
                                : ""}
                      </TableCell>
                      <TableCell>{row.MeetingStatusType}</TableCell>

                      <TableCell>{row.Seniority}</TableCell>
                      <TableCell>
                        <a
                         href={`/viewbookedmeeting?ID=${row?._id}&pagename=ClientMeeting`}
                         onClick={(e) => {
                           e.preventDefault();
                           ViewPage(row?._id);
                         }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-eye edit-icon"></i>
                        </a>
                        <a
                          onClick={() => {
                            DeleteClientName(row._id);
                          }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-delete-1 delete-icon"></i>
                        </a>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                         <Collapse
                          in={Open[row?._id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {Open[row?._id] && (
                            <Box margin={1} className="innertables">
                              <Table size="small" aria-label="purchases">
                                <TableHead></TableHead>
                                {Notes.map((note) =>
                                  note?.MeetingID ==
                                  row?._id ? (
                                    <TableRow key={note._id}>
                                      <TableCell className="font-weight-bold">
                                        {note?.Title}
                                      </TableCell>
                                      <TableCell className="labeltds font-weight-bold">
                                        {moment(note?.CreatedDate).format("MM/DD/YYYY")}
                                      </TableCell>
                                      <TableCell scope="row">
                                        {note?.Note}
                                      </TableCell>
                                    </TableRow>
                                  ) : null
                                )}

                                {!Notes.some(
                                  (note) =>
                                    note?.MeetingID == row?._id
                                ) && (
                                  <TableRow>
                                    <TableCell colSpan={4}>
                                      No data available
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Table>
                            </Box>
                          )}
                        </Collapse>
                        {/* <Collapse
                          in={open[row._id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {open[row._id] && (
                            <Box margin={1} className="innertables">
                              <Table size="small" aria-label="purchases">
                                <TableHead></TableHead>

                                {Notes.map((note) =>
                                  note.MeetingID ===
                                  row._id? (
                                    <TableRow key={note._id}>
                                      <TableCell>{note.Title}</TableCell>
                                      <TableCell scope="row">
                                        {note.Note}
                                      </TableCell>
                                      <TableCell className="labeltds"></TableCell>
                                      <TableCell className="labeltds"></TableCell>
                                    </TableRow>
                                  ) : null
                                )}

                                {!Notes.some(
                                  (note) =>
                                  note.MeetingID ===
                                  row._id
                                ) && (
                                  <TableRow>
                                    <TableCell colSpan={4}>
                                      No data available
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Table>
                            </Box>
                          )}
                        </Collapse> */}
                      </TableCell>
                    </TableRow>
                  </>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div className="row pb-2 px-3">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row pb-2 px-3">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
     </div>
    </div>
  );
}
