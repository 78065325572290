import React from "react";
import Axios from "axios";
import Popupone from "reactjs-popup";
import { connect } from "react-redux";
import { toast } from "react-toastify";
var CryptoJS = require("crypto-js");

import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import ResponderTabing from "../../../_components/user/Responder/ResponderTabing.js";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
const EmailTemplete = require("../../../_helpers/email-templete");
import{GetUserDetails} from '../../../_helpers/Utility'
import loadingicon from "../../../images/loading.gif";

const REACT_APP_ENCRYPTION_SALT =  process.env.REACT_APP_ENCRYPTION_SALT
class ResponderPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      PopUpval: true,
      InvitePopup: false,
      CheckEmail: null,
      UserID:null,
      ParentUserID:null,
      ClientID:null,
      Message:"",
      ListUpdate:false,
      FormData:{
        Email:""
      },
      errors:{},
      IsLoading:false,
      IsSendingBtn: false,
    };
    this.sendButtonRef = React.createRef();
    this.OpenInvitePopup = this.OpenInvitePopup.bind(this);
    this.CloseInvitePopup = this.CloseInvitePopup.bind(this);
  }

  componentDidMount() {
    document.title = "Responders | SalesHive"
    var Details = GetUserDetails();
    if(Details != null)
    {
        this.state.UserID= (Details.ChildUserID);
        this.state.ParentUserID=(Details.ParentUserID);
        this.state.ClientID=(Details.ClientID);
    }
   
  }


OpenInvitePopup() {
  this.setState({ InvitePopup: true });
  this.setState({errors:{},FormData:{Email:""}});
}

CloseInvitePopup() {
  this.setState({ InvitePopup: false });
  this.setState({errors:{},FormData:{Email:""}});
}

async  CheckAdminInvite(Email){
  this.setState({IsLoading:true})
    var InputParameters = {
      ParentUserID: this.state.ParentUserID,
      UserID: this.state.UserID,
      Email: Email,
      ClientID:this.state.ClientID

    };
  let resdata = await  Axios({
      url: CommonConstants.MOL_APIURL + "/AdminUser/InviteValidation",
      method: "POST",
      data: InputParameters,
    });
    console.log(resdata.data)
      if (resdata.data.StatusMessage == "SUCCESS") {
          if(resdata.data.Flag){
              this.setState({Message:resdata.data.Message});
              this.setState({IsLoading:false})
            return false;

          }else{
            this.setState({Message:""});
            this.setState({IsLoading:false})

            return true;
          }

          this.setState({IsLoading:false}) 
      }
   
}

// Function to validate individual form field
async ValidateField(fieldName, value) {
const newErrors = { ...this.state.errors };

switch (fieldName) {
  case "Email":
    const trimmedValue = value.trim();
    newErrors.Email = !trimmedValue ? "Please enter email." : /^\S+@\S+\.\S+$/.test(trimmedValue) ? "" : "Invalid email.";

    // Check if the email already exists
    if (newErrors.Email === "") {
      const emailExists = await this.CheckAdminInvite(trimmedValue);
      if (!emailExists) {
        newErrors.Email = this.state.Message;
      }
    }

    break;

  default:
    break;
}

this.setState({ errors: newErrors });
}

//Update OnChange Handler
HandleInputChange = (e) => {
const { name, value, type, checked } = e.target;
this.setState((prevState) => {
  let updatedFormData = { ...prevState.FormData };

  if (type === "checkbox") {
    // For the checkbox, store the boolean value directly
    updatedFormData[name] = checked;
  } else {
    // For other fields, store their values
    updatedFormData[name] = value;
  }

  return {
    FormData: updatedFormData,
  };
});
};

//Update OnBlur Handler
// HandleBlur = (e) => {
// const { name, value } = e.target;

// this.ValidateField(name, value);
// };
HandleBlur = (e) => {
  if (this.sendButtonRef.current && this.sendButtonRef.current.contains(e.relatedTarget)) {
    return;
  }

  const { name, value } = e.target;
  this.ValidateField(name, value);
};

//Send Invite
HandleSubmit = async (e) => {
e.preventDefault();
this.setState({IsSendingBtn: true, ButtonDisabled: true,IsLoading:false });

const { FormData } = this.state;
const fieldNames = Object.keys(FormData);
let isValid = true;

// fieldNames.forEach((fieldName) => {
//   this.ValidateField(fieldName, FormData[fieldName]);
//   if (this.state.errors[fieldName]) {
//     isValid = false;
//   }
// });
  // Validate each field manually
  for (let fieldName of fieldNames) {
    const value = FormData[fieldName];
    await this.ValidateField(fieldName, value);
    if (this.state.errors[fieldName]) {
      isValid = false;
    }
  }

if (isValid && this.state.FormData.Email?.trim() !== "") {

let Email = this.state.FormData.Email;
var FinalEmail = CryptoJS.AES.encrypt(
  JSON.stringify(Email),
  REACT_APP_ENCRYPTION_SALT
).toString();
let VerificationCodeChar = (Math.random() + 1)
.toString(36)
.substring(7);
var UID = CryptoJS.AES.encrypt(
  JSON.stringify(this.state.ParentUserID),
  REACT_APP_ENCRYPTION_SALT
).toString();

var VC = CryptoJS.AES.encrypt(
  JSON.stringify(VerificationCodeChar),
  REACT_APP_ENCRYPTION_SALT
).toString();
var EmailData = EmailTemplete.ResponderUserEmailInvite(
  FinalEmail,
  UID,
  UID,
  VC,
  CommonConstants.responder_Invitation
);
console.log(EmailData)
var EmailCheck = {
  Email: Email,
};

Axios({
  url:
    CommonConstants.MOL_APIURL + "/responder_profile/FindInviteUser",
  method: "POST",
  data: EmailCheck,
}).then((ResFindUser) => {
  if (ResFindUser.data != "") {
    this.setState({ CheckEmail: ResFindUser.data });
  } else {
    var Inputparameters = {
      Email: Email,
      html: EmailData,
      subject: "SalesHive User Account",
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/user/profileEmail",
      method: "POST",
      data: Inputparameters,
    }).then((res) => {
      let VerificationCodeChar = (Math.random() + 1)
        .toString(36)
        .substring(7);
      var ResPara = {
        UserID: this.state.ParentUserID,
        Email: Email,
        IsRegistered: false,
        IsActive: true,
        IsDeleted: false,
        CreatedBy: this.state.UserID,
        CreatedDate: new Date(),
        LastUpdatedBy: null,
        LastUpdatedDate: null,
        VerificationCode: VerificationCodeChar,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/responder_profile/ClientProfileAdd",
        method: "POST",
        data: ResPara,
      }).then((res) => {
        if (res) {
          this.setState({ ButtonDisabled: false,IsLoading:false });
          this.setState({ IsSendingBtn: false });
          toast.success(<div className="toastsize">Responder <br/>
            Invitation mail sent successfully.</div>)
          this.setState({ListUpdate:!this.state.ListUpdate})
         

          history.push("/responder");
        }
      });
      this.setState({ InvitePopup: false });
    });
  }
});
                     
                    
  
  // You can submit the form data to your server or perform other actions here
} else {
  this.setState({ ButtonDisabled: false,IsLoading:false });
  this.setState({ IsSendingBtn: false });
}

};

  render() {
    const { FormData, errors } = this.state;

    return (
      <>
        <Popupone open={this.state.InvitePopup} modal>
          <div className="modal-black"></div>
          <div className="filterPopup bigpopupcontent">
            <div className="paddingboxTerms">
              <div className="modal-header">
                <h5>Send Mail</h5>
                <a className="close-ion" onClick={this.CloseInvitePopup}>
                  <span aria-hidden="true" className="la la-remove"></span>
                </a>
              </div>
              <div className="modal-content bordernone p-4">
                <div className="row max-cols">
                  <label className="col-lg-4 view-lable-meet">Email</label>
                  <div className="col-lg-8">
                    <input
                      className="form-control m-input"
                      id="EmailInvite"
                      name="Email"
                      placeholder="Enter Email"
                      type="text"
                      value={FormData.Email}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                      />
                      {errors.Email && (
                        <div className="errorinput">{errors.Email}</div>
                      )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.CloseInvitePopup}
                  data-dismiss="modal">
                  Cancel
                </button>
                <button
                  id="m_emailInvite_submit"
                  ref={this.sendButtonRef}
                  onClick={this.HandleSubmit}
                  disabled={this.state.ButtonDisabled}
                  className="btn btn-primary btnColor"
                >
                  Send{" "}
                </button>
              </div>
            </div>
          </div>
        </Popupone>
        {
          this.state.IsLoading ? <div id="hidelod" className="loding-display">
          <img src={loadingicon} />
        </div> : <></>
        }
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome"> 
            <div className="row">
              <div className="col">
                <h4 className="headertitlebd lg-headertitle ">Responders</h4>
              </div>
              <div className="col">
                <div className="listing-li float-right padb-15">
                  <ul>
                    <li>
                      <a onClick={this.OpenInvitePopup} className="btn btngroup">
                        <i className="la la-icon-font-size-13 la-plus"></i>
                        <span>Invite</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>  
          

          <div className="row">
            <div className="col">
              <ResponderTabing listupdate={this.state.ListUpdate}/>
            </div>
          </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedResponderPage = connect(mapState, actionCreators)(ResponderPage);
export { connectedResponderPage as ResponderPage };
