import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import Objections from '../../_components/user/Objections/Objections';
//import CollapsibleTable from '../_components/usertable';

import { history } from "../../_helpers";


class ObjectionsPage extends React.Component {

   //Add button 
    AddBtn() {
        history.push("/addobjections");
    }

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row align-items-center">
                        <div className="col px-3">
                            <h4 className="headertitlenop float-left lg-headertitle py-4 mb-0 ">Objections</h4> 
                        </div>
                   
                        <div className="col"> 
                            <div className="listing-li float-right padb-15 ">
                                <ul> 
                                    <li>
                                    <a onClick={this.AddBtn.bind(this)} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                        <i class="la la-icon-font-size-13 la-plus"></i>
                                        <span>Add</span>
                                    </a>
                                    </li>
                                </ul>
                        </div> 
                    </div>
                    </div>

                    <div class="whiterow px-3 pb-3"> 
                        <div className="row">
                            <div className="col"> 
                                <Objections /> 
                            </div>
                        </div>       
                    </div> 
                </div> 
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedObjectionsPage = connect(mapState, actionCreators)(ObjectionsPage);
export { connectedObjectionsPage as ObjectionsPage };