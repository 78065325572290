import React from "react";
const moment = require("moment");
import Axios from "axios";

import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import ClientResponses from "../../_components/user/ClientResponses/ClientResponses";
import $ from "jquery";
import { GetUserDetails } from "../../_helpers/Utility";
import { history } from "../../_helpers";
import { CommonConstants } from "../../_constants/common.constants";

class ClientResponsesPage extends React.Component {
  constructor(props) {
    super(props);
    const date13 = new Date(moment().subtract(14, "day"));
    // Extract the year, month, and day from the Date object
    const year13 = date13.getFullYear();
    const month13 = String(date13.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
    const day13 = String(date13.getDate()).padStart(2, "0");
    const date = new Date(moment());
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
    const day = String(date.getDate()).padStart(2, "0");
    this.state = {
      FromDate: `${year13}-${month13}-${day13}`,
      ToDate: `${year}-${month}-${day}`,
      ClientID: null,
      UserID: null,
      Role: null,
    };
    this.SaveBtn = this.SaveBtn.bind(this);
    this.daterefect = React.createRef();
    this.daterefect2 = React.createRef();
  }

  componentDidMount() {
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
    // Get today's date
    var today = new Date();

    // Subtract 14 days from today's date
    var twoWeeksBefore = new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000);

    // Set the value of the "fromdate" variable to two weeks before
    var fromdate = twoWeeksBefore;

    // Convert to UTC date components
    const utcYear = fromdate.getUTCFullYear();
    const utcMonth = String(fromdate.getUTCMonth() + 1).padStart(2, "0");
    const utcDay = String(fromdate.getUTCDate()).padStart(2, "0");

    // Format the date
    const formattedDate = `${utcMonth}/${utcDay}/${utcYear}`;

    // Set the value of the "from-date" input field
    document.getElementById("from-date").value = formattedDate;

    const $datepickerElement = $(this.daterefect.current);
    $datepickerElement.datetimepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      container: "#app",
      todayBtn: true,
      todayHighlight: true,
      minView: "2",
    });
    document.getElementById("to-date").value = new Date().toLocaleDateString(
      "en-US",
      { month: "2-digit", day: "2-digit", year: "numeric" }
    );
    const $datepickerElement2 = $(this.daterefect2.current);
    $datepickerElement2.datetimepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      container: "#app",
      todayBtn: true,
      todayHighlight: true,
      minView: "2",
    });

    var FromDate = moment
      .utc(document.getElementById("from-date").value)
      .format("YYYY-MM-DD");
    var ToDate = moment
      .utc(document.getElementById("to-date").value)
      .format("YYYY-MM-DD");
    this.setState({ FromDate: FromDate, ToDate: ToDate });
  }

  //Reload page while delete perform
  UpdateFromChild = (value) => {
    // if(value == true){
    //   this.componentDidMount()
    // }
  };
  SaveBtn() {
    var FromDate = moment
      .utc(document.getElementById("from-date").value)
      .format("YYYY-MM-DD");
    var ToDate = moment
      .utc(document.getElementById("to-date").value)
      .format("YYYY-MM-DD");
    this.setState({ FromDate: FromDate, ToDate: ToDate });
  }

  render() {
    return (
      <>
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="headertitlepad lg-headertitle  float-left mb-0">
                  All Responses
                </h4>
              </div>
              <div className="col padright">
                <div className="btn-group datepicker-hed">
                  <div className="col-lg-6 timedatepikker date-input">
                    <input
                      className="form-control m-input"
                      type="text"
                      id="from-date"
                      ref={this.daterefect}
                    />
                  </div>
                  <div className="col-lg-6 timedatepikker date-input">
                    <input
                      className="form-control m-input"
                      type="text"
                      id="to-date"
                      ref={this.daterefect2}
                    />
                  </div>
                  <button
                    onClick={this.SaveBtn}
                    id="aCompanyState"
                    class="btn btn-primary btnColor m-btn brrb72"
                    autocomplete="off"
                  >
                    <i class="la flaticon-diagram"></i>
                    <span> Analyze</span>
                  </button>
                </div>
              </div>
              {/* <div className="col pt-3">
                <div className="btn-group">
                  <input id="fromtab" name="fromtab" type="hidden" value="" />
                  <input
                    class="form-control datepicker"
                    type="date"
                    autocomplete="off"
                    id="from-date"
                  />
                  <input
                    class="form-control datepicker"
                    type="date"
                    autocomplete="off"
                    id="to-date"
                  />
                  <button
                    id="aCompanyState"
                    onClick={this.SaveBtn}
                    class="btn btn-primary btnColor btn-radius"
                    autocomplete="off"
                  >
                    <i class="la flaticon-diagram"></i>
                    <span>Analyze</span>
                  </button>
                </div>
              </div> */}
            </div>

            <div class="paddcols pb-3 pt-1 bg-white">
              <div className="row">
                <div className="col">
                  <ClientResponses
                    ToDate={this.state.ToDate}
                    FromDate={this.state.FromDate}
                    updateFromChild={this.UpdateFromChild}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedClientResponsesPage = connect(
  mapState,
  actionCreators
)(ClientResponsesPage);
export { connectedClientResponsesPage as ClientResponsesPage };
