import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

toast.configure();

class AddAccountClientStatusPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);
    this.state = {
      Fields: {},
      Errors: {},
      TitleNotNull: false,
      IsExist: false,
      StatusAvailable: null,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      Role: Details.Role,
      IsSubmitting:false
    };
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title= "Account Client Status | SalesHive";
  }
   // form validation
   async FromValidation() {
    let FormIsValid = true;
    var Name = document.getElementById("Name").value.trim();
    var IsExist = await this.CheckExistAccountClientName(Name)
    let Errors = {};
    if (Name == "" && Name.indexOf(" ") != "") {
      FormIsValid = false;
      Errors["Name"] = "Please enter name";
    }
    if(IsExist == true){
      FormIsValid = false;
    }
 
    this.setState({ Errors: Errors });
    return FormIsValid;
}
    //check exist Status
    async CheckExistAccountClientName(Name) {
      var str_in = {
        ClientID: this.state.ClientID,
        Name: Name,
        Role:this.state.Role
      };
     let resdata= await Axios({
        url: CommonConstants.MOL_APIURL +"/account_client_status/AccountClientStatusExists",
        method: "POST",
        data: str_in,
      })
        if (resdata.data.StatusMessage == "SUCCESS") {
          if (resdata.data.Data.length > 0) {
            this.setState({ StatusAvailable: resdata.data.Data[0]?.Name });
            this.setState({ IsExist: true });
            return true;
          } else {
            this.setState({ IsExist: false });
            return false;
          }
        }
      
    }


    // handle change
    HandleChange(field, e) {
      let Fields = this.state.Fields;
      Fields[field] = e.target.value;
      this.setState({ Fields });
      if (Fields.Name != "" && Fields.Name.indexOf(" ") != "") {
        this.state.Errors.Name = null;
        this.state.StatusAvailable = null;
        this.CheckExistAccountClientName(e.target.value);
      } else {
        this.state.StatusAvailable = null;
        this.state.Errors.Name = null;
      }
    }



 

//add account status
  async SaveBtn(e) {
    e.preventDefault();
    this.setState({IsSubmitting:true})
    // this.state.Errors.Name = null;
    // this.state.StatusAvailable = null;
    var Final_flag =  await this.FromValidation()
    if (Final_flag == true) {
      var Name = document.getElementById("Name").value.trim();
      var AddField = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
        Name: Name,
        CreatedDate: new Date(),
        CreatedBy: this.state.CUserID,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/account_client_status/AccountClientStatusAdd",
        method: "POST",
        data: AddField,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize" >Account Client Status<br/>Account Client Status added successfully.</div>);
          history.push("/accountclientstatus");
        } else {
          toast.error(res.data.Message);
          this.setState({IsSubmitting:false})
        }
      });
    }else{
      this.setState({IsSubmitting:false})
    }
  }

  BackBtn() {
    history.push("/accountclientstatus");
  }
  
  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome"> 

            <div className="row pt-4 pb-3">
              <div className="col px-3">
                <h4 className="headertitlenop lg-headertitle float-left px-1">
                  Add
                </h4>
              </div>
            </div>
            
            <div class="whiterow">
              <div className="row py-3  px-3">
                <div class="col-lg-6 boxsinput_group px-3">
                  <div class="row max-cols px-2">
                    <label class="col-lg-3 view-lable-meet pl-0 px-1">Name</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        id="Name"
                        name="Name"
                        placeholder="Enter Name"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                      {this.state.StatusAvailable && (
                        <span style={{ color: "red" }}>
                          Name already exist.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
                
              <div class="row py-4">
                <div class="col-lg-12 pull-left px-3">
                  <button
                    id="submit"
                    disabled={this.state.IsSubmitting}
                    onClick={this.SaveBtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddAccountClientStatusPage = connect(
  mapState,
  actionCreators
)(AddAccountClientStatusPage);
export { connectedAddAccountClientStatusPage as AddAccountClientStatusPage };
