import React, { useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination";
import Axios from "axios";
import { CommonConstants } from "../../../_constants/common.constants";
import {
  GetUserDetails,
} from "../../../_helpers/Utility";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import loadingicon from "../../../images/loading.gif";

const PhoneReporting = ({ FromDate, ToDate, TagID, ColdCallerID }) => {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);

  const [PageData, SetPageData] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [activeButton, SetActiveButton] = React.useState("Week");

  useEffect(() => {
    const Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);

      if (FromDate != "" && ToDate != "") {
        GetPhoneReportingDetails(
          Details.ClientID,
          Details.ParentUserID,
          FromDate,
          ToDate,
          activeButton
        );
      }
    }
  }, [Page, RowsPerPage, activeButton, FromDate, ToDate, TagID, ColdCallerID]);

  //Getting Phone Reporting Details
  const GetPhoneReportingDetails = (
    ClientID,
    UserID,
    FromDate,
    ToDate,
    DisplayBy
  ) => {
    SetIsLoading(true);
    let requestData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Field: SortField,
      SortBy: SortedBy,
      ClientID: ClientID,
      UserID: UserID,
      FromDate: FromDate,
      ToDate: ToDate,
      DisplayBy: DisplayBy,
      TagID:TagID,
      ColdCallerID:ColdCallerID
    };
    
    const ApiResponse = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/cold_calling_reporting/GetPhoneReportingDetails",
      method: "POST",
      data: requestData,
    });
    ApiResponse.then((response) => {
      if ((response.data.StatusMessage = "SUCCESS")) {
        if (response.data.PageData.length > 0) {
          SetPageData(response.data.PageData);
          SetRlen(response.data.TotalCount);
          SetFlen(response.data.TotalCount);
          SetCountPage(response.data.PageCount);
          SetIsLoading(false);
        } else {
          SetPageData([]);
          SetRlen(0);
          SetFlen(0);
          SetCountPage(0);
          SetIsLoading(false);
        }
      } else {
        SetPageData([]);
        SetRlen(0);
        SetFlen(0);
        SetCountPage(0);
        SetIsLoading(false);
      }
    });
  };

  //Method for Change Page
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };

  //Method for Change Selected Rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  //Method code change Data grouping [Daily,Weekly,Monthky]
  const handleGroupingDate = (name) => {
    SetActiveButton(name);
  };
  return (
    <>
      <div className="row mb-2 px-3">
        <div class="col-md-6">
          <h4 class="smheadtext xs-headertitle pl-0 mt-0">Phone Reporting </h4>
        </div>

        <div className="col-md-6">
          <div class="listing-li float-right dailyfilter mt-3">
            <ul class="mt-0">
              <li className="px-0">
                <a
                  className={`btn btngroup m-btn ${
                    activeButton === "Day" ? "active" : ""
                  }`}
                  onClick={() => handleGroupingDate("Day")}
                >
                  <span>Daily</span>
                </a>
              </li>
              <li className="px-0">
                <a
                  className={`btn btngroup m-btn ${
                    activeButton === "Week" ? "active" : ""
                  }`}
                  onClick={() => handleGroupingDate("Week")}
                >
                  <span>Weekly</span>
                </a>
              </li>
              <li className="px-0">
                <a
                  className={`btn btngroup m-btn ${
                    activeButton === "Month" ? "active" : ""
                  }`}
                  onClick={() => handleGroupingDate("Month")}
                >
                  <span>Monthly</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row px-3">
        <div className="col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </select>
            entries
          </label>
        </div>
      </div>

      <div className="table-bordered pt-0 mx-3">
        <TableContainer component={Paper} className="position-relative">
          {IsLoading === true ? (
            <div id="hideloding" className="loadingchart">
              <img src={loadingicon} />
            </div>
          ) : (
            <></>
          )}
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Dials</TableCell>
                <TableCell>Connections</TableCell>
                <TableCell>Connections % </TableCell>
                <TableCell>Phone Meetings</TableCell>
                <TableCell>Connections-to-Meetings %</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {PageData?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                PageData.map((row, i) => (
                  <React.Fragment key={i}>
                    <TableRow row={row}>
                      <TableCell>{row.Date}</TableCell>
                      <TableCell>{row.Dials}</TableCell>
                      <TableCell>{row.Connections}</TableCell>
                      <TableCell>{row.ConnectionRate}</TableCell>
                      <TableCell>{row.Meetings}</TableCell>
                      <TableCell>{row.PhoneToMeetingRate}</TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className="row px-3 pb-3">
        <div class="col dataTables_info">
          <p>
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries
          </p>
        </div>
        <div class="col pageright">
          <Pagination
            component="div"
            count={CountPage}
            page={Page}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </>
  );
};

export default PhoneReporting;
