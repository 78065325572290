import React, { useEffect } from "react";
import Axios from "axios";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import StyleHeader from "../StickyHeader/StickyHeader";

export default function AllClientApiKeysTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("ClientName");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID, SetCUserID] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [sortedColumn, setSortedColumn] = React.useState("ClientName");
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    document.title = `API Keys | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    ApiKeylistGet(Details.ClientID, Details.ParentUserID, Details.Role);
  }, [Search, Page, RowsPerPage]);

  //Get Api key list
  const ApiKeylistGet = (CID, UID, Role) => {
    SetIsLoading(true);
    var str_in = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: Role,
    };
    const Rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/apikey/ApikeyList",
      method: "POST",
      data: str_in,
    });
    Rows1.then((Result) => {
      SetData(Result.data?.PageData);
      SetRows(Result.data?.PageData);
      SetRlen(Result.data?.TotalCount);
      SetFlen(Result.data?.TotalCount);
      SetCountPage(Result.data?.PageCount);
      SetIsLoading(false);
      setIsDataLoaded(true);

    }).catch((error) => {
      SetIsLoading(false);
      setIsDataLoaded(true);

    });
  };

  //Search table list
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      var SearchedVal = document.getElementById("Search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  //change page
  const HandleChangePage = (event, newPage) => {
    if (newPage == Page) {
      SetPage(newPage);
    } else {
      SetPage(newPage);
    }
  };

  //change row
  const ChangeRowSelected = (event) => {
    SetRowsPerPage(Number(event.target.value));
    SetPage(1);
  };

  //edit page
  const EditPage = (id) => {
    history.push("/editapiKey", id);
  };

  //view page
  const ViewPage = (id) => {
    history.push("/viewapiKey", id);
  };

  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true);
    var SearchedVal = document.getElementById("Search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Sort: true,
        Field: SortField,
        Search: SearchedVal,
        SortBy: SortBy,
        Type: "User",
        UserID: UserID,
        Role: Role,
      };
      const ApikeyListData = Axios({
        url: CommonConstants.MOL_APIURL + "/apikey/ApikeyList",
        method: "POST",
        data: InputParameter,
      });
      ApikeyListData.then((Result) => {
        SetData(Result.data?.PageData);
        SetRows(Result.data?.PageData);
        SetRlen(Result.data?.TotalCount);
        SetFlen(Result.data?.TotalCount);
        SetCountPage(Result.data?.PageCount);
        SetIsLoading(false);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: SerchBox,
        Sort: true,
        Search: SearchedVal,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        UserID: UserID,
        Role: Role,
      };
      const ApikeyListData = Axios({
        url: CommonConstants.MOL_APIURL + "/apikey/ApikeyList",
        method: "POST",
        data: InputParameter,
      });
      ApikeyListData.then((Result) => {
        SetData(Result.data?.PageData);
        SetRows(Result.data?.PageData);
        SetRlen(Result.data?.TotalCount);
        SetFlen(Result.data?.TotalCount);
        SetCountPage(Result.data?.PageCount);
        SetIsLoading(false);
      });
    }
  };

  return (
    <div>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div class='bg-white p-3'>
      <div className="row pt-2">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="Search"
              id="Search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
        <Table className="table-ref" aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell
                  onClick={() => {
                    SortData("ClientName");
                    setSortedColumn("ClientName");
                  }}
                >
                  Client Name
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "ClientName"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "ClientName"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("ApiKey");
                    setSortedColumn("ApiKey");
                  }}
                >
                  API Key
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "ApiKey"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "ApiKey"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>{row?.p1?.Name}</TableCell>
                    <TableCell>{row?.ApiKey}</TableCell>
                    <TableCell>
                      <a
                        title="View"
                        onClick={() => {
                          ViewPage(row._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i class="la flaticon-eye edit-icon"></i>
                      </a>
                      <a
                        title="Edit"
                        onClick={() => {
                          EditPage(row._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i class="la flaticon-edit-1 edit-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div className="row ">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row ">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
      </div>
    </div>
  );
}
