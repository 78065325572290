import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import ClientKpisRespondersTable from "./ClientKpisRespondersTable";
import ClientKpisStrategistTable from "./ClientKpisStrategistTable";
import ClientKpisColdCallersTable from "./ClientKpisColdCallersTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs({ FromDates, ToDates, IsIncludeOldClient }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [FromDate, SetFromDate] = React.useState(FromDates);
  const [ToDate, SetToDate] = React.useState(ToDates);
  const [IsIncludeOldClients, SetIsIncludeOldClients] = React.useState(false);
  useEffect(() => {
    //  SetFromDate(FromDates)
    //  SetToDate(ToDates)
  }, [FromDates, ToDates, IsIncludeOldClient]);

  const handleChange = (event, newValue) => {
    
    SetToDate(ToDates)
    SetFromDate(FromDates)
    SetIsIncludeOldClients(IsIncludeOldClient)
    setValue(newValue);
  };

  //const openTermsConditionPopup = false;

  return (
    <div className="tabdes">
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange} variant="scrollable"
            aria-label="simple tabs example"
          >
            <Tab label="Responders" {...a11yProps(0)}>
              Overviewasdasd
            </Tab>
            <Tab label="Strategist" {...a11yProps(1)} />
            <Tab label="Sales Development Reps" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone" index={0}>
          <div className="row">
            <div className="col px-0">
              <ClientKpisRespondersTable
                FromDates={FromDate}
                ToDates={ToDate}
                IsIncludeOldClients={IsIncludeOldClients}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone" index={1}>
          <div className="row">
            <div className="col">
              <ClientKpisStrategistTable
                FromDate={FromDate}
                ToDate={ToDate}
                IsIncludeOldClients={IsIncludeOldClients}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone"index={2}>
          <div className="row">
            <div className="col">
                <ClientKpisColdCallersTable
                  FromDate={FromDate}
                  ToDate={ToDate}
                  IsIncludeOldClients={IsIncludeOldClients}
                /> 
            </div>
          </div>
        </TabPanel>
      </div>
    </div>
  );
}
