import React from 'react'
const moment = require('moment')
import { connect } from 'react-redux'
import { userActions } from '../../_actions'
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar'
import Footer from '../../_components/user/footer/footer'
import ClientCampaigns from '../../_components/user/ClientCampaigns/ClientCampaigns'
import loadingi from '../../images/loading.gif'
import 'react-toastify/dist/ReactToastify.css'
import $ from 'jquery'
import {  getFirstDayOfQuarter,getCurrentDayOfQuarter} from '../../_helpers/Utility'
 
class ClientCampaignsPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      IsAnalyze: false,
      FromDate: (getFirstDayOfQuarter()),
      ToDate: new Date()
    }
    this.Analyze = this.Analyze.bind(this);
    this.daterefect = React.createRef()
    this.daterefect2 = React.createRef()
  }
  componentDidMount () {
    var fromdate = getFirstDayOfQuarter()

    const previousMonthDate = new Date(fromdate)

    // Convert to UTC date components
    const utcYear = previousMonthDate.getUTCFullYear()
    const utcMonth = String(previousMonthDate.getUTCMonth() + 1).padStart(2,'0')
    const utcDay = String(previousMonthDate.getUTCDate()).padStart(2, '0')

    const formattedDate = `${utcMonth}/${utcDay}/${utcYear}`

    document.getElementById('from-date').value = formattedDate
    const $datepickerElement = $(this.daterefect.current)
    $datepickerElement.datetimepicker({
      format: 'mm/dd/yyyy',
      autoclose: true,
      container: '#app',
      todayBtn: true,
      todayHighlight: true,
      minView: '2'
    })
    document.getElementById('to-date').value = new Date().toLocaleDateString('en-US',{ month: '2-digit', day: '2-digit', year: 'numeric' })
    const $datepickerElement2 = $(this.daterefect2.current)
    $datepickerElement2.datetimepicker({
      format: 'mm/dd/yyyy',
      autoclose: true,
      container: '#app',
      todayBtn: true,
      todayHighlight: true,
      minView: '2'
    })
      var FromDate = moment.utc(document.getElementById('from-date').value).format('YYYY-MM-DD')
      var ToDate = moment.utc(document.getElementById('to-date').value).format('YYYY-MM-DD')
      this.setState({ FromDate: FromDate, ToDate: ToDate })
  
  }
  Analyze() {
    this.setState({IsAnalyze :true, FromDate: document.getElementById("from-date").value,ToDate: document.getElementById("to-date").value})
}


 

  render () {
    return (
      <>
        {this.state.IsLoading ? (
          <div id='hidelod' className='loding-display'>
            <img src={loadingi} />
          </div>
        ) : (
          <></>
        )}
        <div className='adminmain mheight'>
          <Sidebar className='' />
          <div className='bodyhome'>
            <div className='row align-items-center'>
              <div className='col'>
                <h4 className='headertitle lg-headertitle  float-left pl-0'> All Campaigns </h4>
              </div>
              <div className='col px-4'>
                <div className='btn-group datepicker-hed my-3'>
                  <div className='col-lg-6 timedatepikker date-input'>
                    <input
                      className='form-control m-input'
                      type='text'
                      id='from-date'
                      ref={this.daterefect}
                    />
                  </div>
                  <div className='col-lg-6 timedatepikker date-input'>
                    <input
                      className='form-control m-input'
                      type='text'
                      id='to-date'
                      ref={this.daterefect2}
                    />
                  </div>
                  <button
                   onClick={this.Analyze}
                    id='aCompanyState'
                    class='btn btn-primary btnColor m-btn brrb72'
                    autocomplete='off'
                  >
                    <i class='la flaticon-diagram'></i>
                    <span> Analyze</span>
                  </button>
                </div>
        </div>
            </div>

            <div class='paddcols px-3 bg-white'>
              <div className='row'>
                <div className='col'>
                  <ClientCampaigns  ObjClientCampaigns={this.state}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

function mapState (state) {
  const { loggingIn } = state.authentication
  return { loggingIn }
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout
}

const connectedClientCampaignsPage = connect(
  mapState,
  actionCreators
)(ClientCampaignsPage)
export { connectedClientCampaignsPage as ClientCampaignsPage }
