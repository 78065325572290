import React, { useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
const moment = require("moment");
import { CSVReader, CSVDownloader } from "react-papaparse";

import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import { Tooltip } from "@material-ui/core";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
// import { GetClientDetails } from "../../../_helpers/Utility";
import StyleHeader from "../StickyHeader/StickyHeader";

export default function ClientAccount({
  AccountFieldType,
  AccountHeading,
  updateFromChild,
}) {
  const [Open, SetOpen] = React.useState(false);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Data, SetData] = React.useState([]);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortValue, SetSortValue] = React.useState(1);
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [Role, SetRole] = React.useState("");
  const [AccountCount, SetAccountCount] = React.useState([]);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [ExportData, SetExportData] = React.useState([]);
  const [Cname, SetCname] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);
  const [SubTabData, SetSubTabData] = React.useState([]);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    document.title = `All Client Account | SalesHive`;
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
    }
    // GetClientDetails(GetUserData.ClientID).then((result) => {
    //   SetCname(result[0].Name);
    // });
    GetClientAccounlist(GetUserData.ParentUserID);
    ExportClientAccountdetails(GetUserData.ParentUserID);
  }, [Search, Page, RowsPerPage]);

  //get client Account
  const GetClientAccounlist = (UID) => {
    SetIsLoading(true);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      UserID: UID,
      AccountTypeID: AccountFieldType,
    };
    const GetAllAccountList = Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/ClientAccountlistGet",
      method: "POST",
      data: InputParameter,
    });
    GetAllAccountList.then((Result) => {
      SetData(Result.data.PageData);
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetAccountCount(Result.data.AccountAllCount);
      SetIsLoading(false);
      setIsDataLoaded(true);

      //document.getElementById("hideloding").style.display = "none";
    });
  };

  //Get counts details in sub table
  const GetCountDetailsInSubTable = (AccountID) => {
    SetIsLoading(true);
    let arr = [...SubTabData];
    const InputParameter = {
      SalesReplyAccountID: AccountID,
      //  ClientID: ClientID,
      UserID: UserID,
      Role: Role,
    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/GetCountDetailsForTableAllClient",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage === "SUCCESS") {
        const newData = res.data.Data[0];
        const existingIndex = arr.findIndex((item) => item._id === newData._id);
        if (existingIndex !== -1) {
          arr.splice(existingIndex, 1);
        }
        arr.push(newData);
        SetSubTabData(arr);
        SetIsLoading(false);
      } else {
        SetIsLoading(false);
      }
    });
  };

  //Search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("Search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
        //document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  //Delete document meeting
  const DeleteClientName = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete an account.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputParameter = {
          _id: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/DeleteAccountList",
          method: "POST",
          data: InputParameter,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire("Deleted!", "Account deleted successfully.", "success");
              // history.push("/clientaccount");
              SetPage(1);
              SetSearch("");
              document.getElementById("Search").value = "";
              // document.getElementById("hideloding").style.display = "block";
              GetClientAccounlist(UserID);
              // updateFromChild(true);
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //change Page
  const HandleChangePage = (event, newPage) => {
    if (newPage == Page) {
      SetPage(newPage);
    } else {
      SetPage(newPage);
      //document.getElementById("hideloding").style.display = "block";
    }
  };

  //change display Rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    // document.getElementById("hideloding").style.display = "block";
  };

  const ViewPage = (id) => {
    history.push({
      pathname: "/viewaccount",
      state: { data: id, Pagename: "ClientAccount" },
    });
  };

  // //render on view Page
  //   const ViewPage = (id) => {
  //     history.push({pathname: "/viewbookedmeeting", state:{data:"clientresearch",ID:id}});
  //   };

  //Reset view
  const ResetView = () => {
    GetClientAccounlist(UserID);
    document.getElementById("resetview").style.display = "none";
  };
  //Export Button
  const ExportBtn = () => {
    toast.success(<div>Data exported successfully.</div>);
  };
  // Export details
  const ExportClientAccountdetails = (UID) => {
    let InputParameter = {
      UserID: UID,
      AccountTypeID: AccountFieldType,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/ExportClientAccount",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      SetExportData(res.data);
    });
  };

  //Get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("Search").value;
    SetIsLoading(true);
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var Sort = SortedBy;
      if (Sort == 1) {
        Sort = -1;
        SetSortedBy(-1);
      } else {
        Sort = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: Sort,
        Type: "User",
        UserID: UserID,
        AccountTypeID: AccountFieldType,
      };
      const ResearchGet = Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/ClientAccountlistGet",
        method: "POST",
        data: InputParameter,
      });
      ResearchGet.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
        //document.getElementById("hideloding").style.display = "none";
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var Sorted;
      if (Sorted == 1) {
        Sorted = -1;
        SetSortedBy(-1);
      } else {
        Sorted = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: Sort,
        Type: "User",
        UserID: UserID,
        AccountTypeID: AccountFieldType,
      };
      const ResearchGet = Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/ClientAccountlistGet",
        method: "POST",
        data: InputParameter,
      });
      ResearchGet.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
        //document.getElementById("hideloding").style.display = "none";
      });
    }
  };

  return (
    <div>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row align-item-center">
        <div className="col border-bottom">
          <h4 className="headertitle xs-headertitle  float-left pl-2">{AccountHeading}</h4>
          <div className="listing-li float-right padb-15">
            <ul>
              <Tooltip title="Export">
                <li>
                  <CSVDownloader
                    className="px-0"
                    data={ExportData}
                    filename={"AllClients-Account-Csv"}
                    bom={true}
                  >
                    <a
                      // onClick={ExportClientAccountdetails}
                      onClick={ExportBtn}
                      className="btn btngroup m-btn m-btn--custom"
                    >
                      <i class="la la-icon-font-size-13 la-download"></i>
                      <span>Export</span>
                    </a>
                  </CSVDownloader>
                </li>
              </Tooltip>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25 px-2">
        <div className="col-sm-12" id="resetview" style={{ display: "none" }}>
          <a
            onClick={() => {
              ResetView();
            }}
          >
            Reset
          </a>
        </div>
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>

        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="Search"
              id="Search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
        <Table className="table-ref"  aria-label="collapsible table">
                      <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ClientName");
                    }}
                  >
                    Client Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("AccountName");
                    }}
                  >
                    Account Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Domain");
                    }}
                  >
                    Email Domain
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Website");
                    }}
                  >
                    Website
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Address1");
                    }}
                  >
                    Address 1
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Address2");
                    }}
                  >
                    Address 2
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("City");
                    }}
                  >
                    City
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("State");
                    }}
                  >
                    State
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Zip");
                    }}
                  >
                    Zip
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Country");
                    }}
                  >
                    Country
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("SalesAccountCategory");
                    }}
                  >
                    Account Category
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Industry");
                    }}
                  >
                    Industry
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("AccountOwnerName");
                    }}
                  >
                    Account Owner
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ReasonUnqualifiedName");
                    }}
                  >
                    Reason Unqualified
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("CompanyPhone");
                    }}
                  >
                    Company Phone
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("AccountClientStatusName");
                    }}
                  >
                    Account Client Status
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("AccountType");
                    }}
                  >
                    AccountType
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("CreatedDate");
                    }}
                  >
                    Created Date
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("CompanyRevenue");
                    }}
                  >
                    Company Revenue
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("CompanyEmployeeCount");
                    }}
                  >
                    Employee Count
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("TotalCallCount");
                    }}
                  >
                    Total Call Count
                  </a>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <>
                    <TableRow key={row._id}>
                      <TableCell align="center">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            SetOpen((prev) => ({
                              ...prev,
                              [row._id]: !prev[row._id],
                            }));
                            GetCountDetailsInSubTable(row._id);
                          }}
                        >
                          {Open[row._id] ? (
                            <i
                              className="fa fa-minus-circle minusl"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className="fa fa-plus-circle plusbl"
                              aria-hidden="true"
                            ></i>
                          )}
                        </IconButton>
                      </TableCell>

                      <TableCell className="labeltds" scope="row">
                        {row?.ClientName}
                      </TableCell>
                      <TableCell className="labeltds" scope="row">
                        {row.AccountName}
                      </TableCell>
                      <TableCell>
                        {row?.Salesreply_Accountdetails?.Domain}
                      </TableCell>
                      <TableCell>{row?.Website}</TableCell>
                      <TableCell>{row?.Address1}</TableCell>
                      <TableCell>{row?.Address2}</TableCell>
                      <TableCell>{row?.City}</TableCell>
                      <TableCell>{row?.State}</TableCell>
                      <TableCell>{row?.Zip}</TableCell>
                      <TableCell>{row?.Country}</TableCell>
                      <TableCell>{row?.SalesAccountCategory}</TableCell>
                      <TableCell>{row?.Industry}</TableCell>
                      <TableCell>{row?.AccountOwnerName}</TableCell>
                      <TableCell>{row?.ReasonUnqualifiedName}</TableCell>
                      <TableCell>{row?.CompanyPhone}</TableCell>
                      <TableCell>{row?.AccountClientStatusName}</TableCell>
                      <TableCell>{row?.AccountType}</TableCell>
                      <TableCell>
                        {moment(
                          new Date(row?.CreatedDate).toDateString()
                        ).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell>{row?.CompanyRevenue}</TableCell>
                      <TableCell>{row?.CompanyRevenue}</TableCell>
                      <TableCell>{row?.TotalCallCount}</TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            ViewPage(row._id);
                          }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-eye edit-icon"></i>
                        </a>
                        <a
                          onClick={() => {
                            DeleteClientName(row._id);
                          }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-delete-1 delete-icon"></i>
                        </a>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse
                          in={Open[row._id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {Open[row._id] && (
                            <Box margin={1} className="innertables">
                              <Table size="small" aria-label="purchases">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Account Status</TableCell>
                                    <TableCell>Last Send Date</TableCell>
                                    <TableCell>Last Response Date</TableCell>
                                    <TableCell>Sent Count</TableCell>
                                    <TableCell>Open Count</TableCell>
                                    <TableCell>Reply Count</TableCell>
                                    <TableCell>Soft Bounce Count</TableCell>
                                    <TableCell>Hard Bounces Count</TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableRow>
                                  {SubTabData?.map((Value) =>
                                    Value._id.toString() ===
                                    row._id.toString() ? (
                                      <>
                                        <TableCell>
                                          {Value?.AccountStatus}
                                        </TableCell>
                                        <TableCell>
                                          {Value?.LastSendDate}
                                        </TableCell>
                                        <TableCell>
                                          {Value?.LastResponsesDate}
                                        </TableCell>
                                        <TableCell>
                                          {Value?.SentCount}
                                        </TableCell>
                                        <TableCell>
                                          {Value?.OpenCount}
                                        </TableCell>
                                        <TableCell>
                                          {Value?.ReplyCount}
                                        </TableCell>
                                        <TableCell>
                                          {Value?.SoftBounceCount}
                                        </TableCell>
                                        <TableCell>
                                          {Value?.HardBouncesCount}
                                        </TableCell>
                                      </>
                                    ) : (
                                      <React.Fragment key={Value.someUniqueKey}>
                                        {" "}
                                      </React.Fragment>
                                    )
                                  )}
                                </TableRow>
                              </Table>
                            </Box>
                          )}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div className="row pb-2">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row pb-2">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
}
