import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { toast } from "react-toastify";

import Box from "@material-ui/core/Box";
import { GetClientDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import AccountCustomField from "../../../_components/user/AccountClientStatus/CustomAccountsField/CustomAccountsField";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

class Accountcustomfield extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      FileName: "",
      ExportData: [],
      ClientName: "",
      Role: null,
    };
    this.AddAccountCustomField = this.AddAccountCustomField.bind(this);
  }

  componentDidMount() {
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
    var InputParameter = {
      ClientID: this.state.ClientID,
      Role: this.state.Role,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/customaccounntlist/CustomAccountExport",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      this.setState({ ExportData: res.data.Data });
      this.setState({ ClientName: res.data.ClientName });
    });
    GetClientDetails(Details.ClientID).then((result) => {
      this.setState({ ClientName: result[0].Name });
    });
  }

  componentDidUpdate() {
    //this.loadPage();
  }

  //add function
  AddAccountCustomField() {
    history.push("/addcustoaccount");
  }

  //Reload page while delete perform
  UpdateFromChild = (value) => {
    if (value == true) {
      this.componentDidMount();
    }
  };

  ExportCsv() {
    toast.success("Data exported successfully.");
  }

  render() {
    const mainTtile = CommonConstants.Title;
    document.title = "Custom Account Fields" + mainTtile;
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row align-items-center py-3">
              <div className="col">
                <h4 className="headertitlenop mb-0 my-0 float-left lg-headertitle">Custom Account Fields</h4>
              </div> 
              <div className="col">
                <div className="listing-li float-right">
                  <ul className='my-0'>
                    <li>
                      <CSVDownloader
                        className="px-0"
                        data={this.state.ExportData}
                        filename={`${this.state.ClientName}-CustomAccountField`}
                        bom={true}
                      >
                        <a
                          onClick={this.ExportCsv}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i className="la la-icon-font-size-13 la-download"></i>
                          <span>Export</span>
                        </a>
                      </CSVDownloader>
                    </li>
                    <li>
                      <a
                        onClick={this.AddAccountCustomField}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i className="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                  </div>
              </div> 
            </div>
            <div className="paddcols">
              <Box> 
                <div className="row">
                  <div className="col">
                    <AccountCustomField
                      updateFromChild={this.UpdateFromChild}
                    />
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAccountcustomfield = connect(
  mapState,
  actionCreators
)(Accountcustomfield);
export { connectedAccountcustomfield as Accountcustomfield };
