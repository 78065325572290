import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
var CryptoJS = require("crypto-js");
import Popupone from "reactjs-popup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from '@mui/material/Switch';

import { userActions } from "../../../_actions";
import { history } from "../../../_helpers";
const EmailTemplete = require("../../../_helpers/email-templete");
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
const Fileupload = require("../../../_helpers/fileupload");
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
toast.configure();

import loadingicon from "../../../images/loading.gif";
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const imgstyle = { width: "100px", height: "100px" };

class CEditUsersPerClientPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      Profile: [],
      UserProfile: [],
      FinalImg: "",
      FileImg: "",
      PopUpVal: true,
      InvitePopup: false,
      ImgFlag: false,
      fields: {},
      errors: {},
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      Role:Details.Role,
      UserIDby: Details.ChildUserID,
      Message:"",
      Email:"",
      showPassword: false,
      IsLoading:false
    };
    this.GetUserPerClient();
    this.ResetPassword = this.ResetPassword.bind(this);
    this.OpenInvitePopup = this.OpenInvitePopup.bind(this);
    this.CloseInvitePopup = this.CloseInvitePopup.bind(this);
    this.SubmitInvitePopup = this.SubmitInvitePopup.bind(this);
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {}

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  GetUserPerClient() {
    const Inputparameters={
      ID:this.props.location.state,
      Role:this.state.Role
    }
    Axios({
      url: CommonConstants.MOL_APIURL + "/users_per_client/UserPerClientGetByID",
      method: "POST",
      data:Inputparameters
    }).then((res) => {
      let DecryptPass = JSON.parse((CryptoJS.AES.decrypt(((res.data.Data.Password).split(" ").join("+")), "my-secret-key@123")).toString(CryptoJS.enc.Utf8));
      this.setState({UserProfile:res.data.Data})
      document.getElementById('FirstName').value=res.data.Data.UsersPerClientName;
      document.getElementById('LastName').value=res.data.Data.LastName;
      document.getElementById('Email').value=res.data.Data.Email;
      document.getElementById('Password').value=DecryptPass;
      document.getElementById('IsSentMeetingNotification').checked=res.data.Data.IsSentMeetingNotification;
      document.getElementById('IsSentPotentialMeetingNotification').checked=res.data.Data.IsSentPotentialMeetingNotification;
      document.getElementById('IsSentWeeklyEmailNotification').checked=res.data.Data.IsSentWeeklyEmailNotification;
      document.getElementById('IsTwoStepVerification').checked=res.data.Data.IsTwoStepVerification;
      document.getElementById('OldImage').src=`${CommonConstants.Image_url + res.data.Data.ProfileImage}`;
      document.getElementById('ImageTitle').innerHTML= res.data.Data.ProfileImage;
      this.setState({Email:res.data.Data.Email})
      
    });
  }

  FormValidation() {
    let formIsValid = true;
      let errors = {};
    var FirstName = document.getElementById("FirstName").value;
      if (FirstName.length<=0) {
        formIsValid = false;
        errors["FirstName"] = "Please enter name.";
      }
      this.setState({ errors: errors });
    
    return formIsValid;
  }

  backbtn() {
    history.push("/CUsersPerClient");
  }

 //upload
 HandleUpload(field, e) {
  
  e.preventDefault();
  if (
    Fileupload.checkMimeType(e.target.files[0]) &&
    Fileupload.checkFileSize(e.target.files[0])
  ) {
    var temp = URL.createObjectURL(e.target.files[0]);
    this.setState({
      FinalImg: e.target.files[0],
    });
    document.getElementById("OldImage").src = temp
    this.setState({ ImgFlag: true });
    this.setState({ FileImg: temp });
 
  } else {
   
  }
}

  savebtn() {
    if(this.FormValidation()){
      let FirstName =  document.getElementById('FirstName').value;
      let LastName = document.getElementById('LastName').value;
      let IsTwoStepVerification=document.getElementById('IsTwoStepVerification').checked;
      let IsSentMeetingNotification =  document.getElementById('IsSentMeetingNotification').checked;
      let IsSentPotentialMeetingNotification =  document.getElementById('IsSentPotentialMeetingNotification').checked;
      let IsSentWeeklyEmailNotification =  document.getElementById('IsSentWeeklyEmailNotification').checked;
  

      var InputParameter;

      if(this.state.ImgFlag==false){
         InputParameter = {
          ID:this.props.location.state,
          FirstName:FirstName,
          LastName:LastName,
          // Email:Email,
          IsTwoStepVerification:IsTwoStepVerification,
          IsSentMeetingNotification:IsSentMeetingNotification,
          IsSentPotentialMeetingNotification:IsSentPotentialMeetingNotification,
          IsSentWeeklyEmailNotification:IsSentWeeklyEmailNotification,
          LastUpdatedDate:new Date(),
          LastUpdatedBy:this.state.UserIDby,
        };
      }else{
        
        var name = this.state.FinalImg.name;
        var filetype = name.split(".");
        
        var file_Name;
        if (/\s/g.test(filetype[0])) {
          file_Name =
            filetype[0].replaceAll(/\s/g, "") +
            new Date().getTime() +
            "." +
            filetype[1] +
            "";
        } else {
          file_Name = filetype[0] + new Date().getTime() + "." + filetype[1] + "";
        }
        Fileupload.getBase64(this.state.FinalImg, (result) => {
          var rs = result;
          var splirs = rs.split("base64,");
          var _base64 = splirs[1];
          var myresult = {
            fields: "Coverimg",
            path: "./Content/UploadedFiles/UserProfilePic",
            file: _base64,
            imageName: file_Name,
          };
          Axios({
            url:CommonConstants.MOL_APIURL +"/responder_profile/ImageSave",
            method: "POST",
            data: myresult,
          }).then((res) => {
           });

        })
        InputParameter = {
          ID:this.props.location.state,
          FirstName:FirstName,
          LastName:LastName,
          IsTwoStepVerification:IsTwoStepVerification,
          IsSentMeetingNotification:IsSentMeetingNotification,
          IsSentPotentialMeetingNotification:IsSentPotentialMeetingNotification,
          IsSentWeeklyEmailNotification:IsSentWeeklyEmailNotification,
          LastUpdatedDate:new Date(),
          LastUpdatedBy:this.state.UserIDby,
          ProfileImage:file_Name,
          Role:this.state.Role

        };
      }
      Axios({
        url: CommonConstants.MOL_APIURL + "/users_per_client/UserPerClientUpdate",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize">Users per client<br/>Users per client updated successfully.</div>);
          history.push("/CUsersPerClient");
        } else {
          toast.error(res.data.Message);
        }
      });
    }


   
    
  }

  OpenInvitePopup() {
    
    this.setState({ InvitePopup: true });
  }
  CloseInvitePopup() {
    this.setState({ InvitePopup: false });
    this.setState({ Message: "" });
  }
  async  CheckUpdateEmail(Email){
    let Emails = document.getElementById("change_mail").value;
      var InputParameters = {
        ParentUserID: "",
        // UserID: this.state.UserID,
        Email: Emails,
      };
    let resdata = await  Axios({
        url: CommonConstants.MOL_APIURL + "/AdminUser/UpdateEmailValidation",
        method: "POST",
        data: InputParameters,
      });
        if (resdata.data.StatusMessage == "SUCCESS") {
            if(resdata.data.Flag){
                this.setState({Message:resdata.data.Message});
              return false;
            }else{
              this.setState({Message:""});
              return true;
            }
            
        }
     
  }
     // Whole form validation
  async FormValidation1() {
      let formIsValid = true;
      let Email = document.getElementById("change_mail").value;
      let IsExists = await this.CheckUpdateEmail(Email);
  
      if(IsExists){
        formIsValid = true;
      }else{
        formIsValid = false;
      }
      if(Email.trim().length <=0 ){
        formIsValid=false;
        this.setState({Message:"Please enter email."})
      }
    
      return formIsValid;
  }

//Email change
  async SubmitInvitePopup(e) {
    this.setState({IsLoading:true})
    e.preventDefault();
    e.currentTarget.disabled = true;
    let Final_flag =  await this.FormValidation1()
    if(Final_flag==true){
      let sendEmail = document.getElementById("change_mail").value;
      let RequestBody = {
        ClientID: this.state.ClientID,
        UserID:this.state.UserID,
        LastUpdatedBy:this.state.UserProfile.ClientUserID,
        LastUpdatedDate: new Date(),
        Email:sendEmail,
        Navigation: CommonConstants.change_Email_usersperclient
      }
      Axios({
        url: CommonConstants.MOL_APIURL + "/users_per_client/SentMailForUpdateEmail",
        method: "POST",
        data:RequestBody
      }).then((Res) => {
        let {StatusMessage,Message,Data} = Res?.data;
        if(StatusMessage == 'SUCCESS'){
            if(Data?.MailSended){
              toast.success(Message);
              this.setState({ InvitePopup: false });
            }else{
              toast.error(Message);
            }
        }else{
          toast.error(Message)
        }
        this.setState({IsLoading:false})
      })
    }else{
    this.setState({IsLoading:false})
    e.currentTarget.disabled = false;
    }
  // document.getElementById('hideloding').style.display="block";
  //   e.preventDefault();
  //   e.currentTarget.disabled = true;
  //   var Final_flag =  await this.FormValidation1()
  //   if(Final_flag==true){
  //   var sendEmail = document.getElementById("change_mail").value;
  //   var tmp_token =
  //     Math.random().toString(36).substring(2, 15) +
  //     Math.random().toString(36).substring(2, 15);
  //   // var email_token = CryptoJS.MD5(tmp_token).toString();
  //   var email_token = CryptoJS.AES.encrypt(JSON.stringify(tmp_token),"my-secret-key@123").toString();
  //   var uid = this.state.UserProfile.ClientUserID;
  //   var str_in = {
  //     uID: uid,
  //     Email_Token: email_token,
  //   };
   
  //   Axios({
  //     url: CommonConstants.MOL_APIURL + "/user/changeemail",
  //     method: "POST",
  //     data: str_in,
  //   }).then((res) => {
     
  //     if (res.data != "") {
       
  //       var Username = sendEmail;
  //       var email = CryptoJS.AES.encrypt(
  //         JSON.stringify(sendEmail),
  //         "my-secret-key@123"
  //       ).toString();
  //       var emailData = EmailTemplete.userEmailChange(
  //         email_token,
  //         email,
  //         CommonConstants.change_Email_usersperclient
  //       );
  //       var str_mail = {
  //         email: Username,
  //         html: emailData,
  //         subject: "Please Confirm Your Email",
  //       };
  //       Axios({
  //         url: CommonConstants.MOL_APIURL + "/AdminUser/EmailSend",
  //         method: "POST",
  //         data: str_mail,
  //       }).then((res) => {
  //       toast.success("Mail sent successfully.");
          
  //       });
  //     }
  //   });
  //   this.setState({ InvitePopup: false });
  // }else{
  //   document.getElementById("m_emailInvite_submit").disabled = false;
  // }
  // document.getElementById('hideloding').style.display="none";

  }
  
 //Reset password
 ResetPassword() {
  this.setState({IsLoading:true})

  let RequestBody = {
    "UserName": this.state.Email,
    "LastUpdatedDate": new Date(),
    "LastUpdatedBy": this.state.UserID
  }
  Axios({
    url: CommonConstants.MOL_APIURL + "/user/ForgetPasswordMailSent",
    method: "POST",
    data: RequestBody,
  }).then((res) => {
    if(res.data.StatusMessage == 'SUCCESS'){
      toast.success(<div className="toastsize">Users per client edit <br/>Thank you, Please check your email for password reset link.</div>);
    this.setState({IsLoading:false})
    }else{
      toast.error(res.data.Message);
      this.setState({IsLoading:false})
    }
  });
}
//  ResetPassword() {
//   document.getElementById('hideloding').style.display="block";
//   var tmp_token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
//   // var forgot_token = CryptoJS.MD5(tmp_token).toString();
//   var forgot_token = CryptoJS.AES.encrypt(JSON.stringify(tmp_token),"my-secret-key@123").toString();
 
//       var InputParameters = {
//           "UserName": this.state.Email,
//           "Forgot_Token":forgot_token
//       };
//       Axios({
//           url: CommonConstants.MOL_APIURL + "/login/forgotpassword",
//           method: "POST",
//           data: InputParameters
//       }).then(resdata => {
//         if (resdata.data != "") {
//           var Username = resdata.data.Username;
//           var emailData = EmailTemplete.userForgotpassword(forgot_token);
//           var InputParameters = {
//             email: Username,
//             html: emailData,
//             subject: "Reset Password",
//           };
//           Axios({
//             url: CommonConstants.MOL_APIURL + "/user/emailsend",
//             method: "POST",
//             data: InputParameters,
//           }).then((res) => {
//         toast.success(<div className="toastsize">Client user edit <br/>Thank you, Please check your email for password reset link.</div>);
//       });
//       }
//     document.getElementById('hideloding').style.display="none";

//   });
// }
  render() {
    return (
      <>
      {
        this.state.IsLoading ?  <div id="hideloding" className="loding-display" style={{display:"none"}}>
        <img src={loadingicon} />
      </div>:<></>
      }
     
      <Popupone open={this.state.InvitePopup} modal>
          <div className="modal-black"></div>
          <div className="filterPopup bigpopupcontent">
            <div className="paddingboxTerms">
              <div className="modal-header">
                <h5>Send Mail</h5>
                <a className="close-ion" onClick={this.CloseInvitePopup}>
                  <span aria-hidden="true" className="la la-remove"></span>
                </a>
              </div>
              <div className="modal-content bordernone">
                <div className="row max-cols">
                  <label className="col-lg-4 view-lable-meet">Email</label>
                  <div className="col-lg-8">
                    <input
                      className="form-control m-input"
                      name="Email"
                      placeholder="Email"
                      id="change_mail"
                      
                      type="text"
                      onBlur={this.CheckUpdateEmail.bind(this, "Email")}
                      />
                       <span style={{ color: "red" }}>
                          {this.state.Message}
                        </span>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.CloseInvitePopup}
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <a
                  id="m_emailInvite_submit"
                  href="javascript:void(0);"
                  onClick={this.SubmitInvitePopup}
                  className="btn btn-primary btnColor"
                >
                  Send{" "}
                </a>
              </div>
            </div>
          </div>
        </Popupone>
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad mb-0">Edit Users Per Client </h4>
                </div>
              </div>

            <div className="paddcols">
              <div className="row pt-4">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">First Name </label>
                    <div className="col-lg-8">
                    <input type="text" className="form-control" id="FirstName" placeholder="Enter first name" />
                    <span style={{ color: "red" }}>{this.state.errors["FirstName"]}</span>
                    
                    </div>
                  </div>
                </div>
                <div className="col-lg-6"></div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Last Name</label>
                    <div className="col-lg-8">
                    <input type="text" className="form-control" id="LastName" placeholder="Enter first name" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6"></div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Email</label>
                    <div className="col-lg-8">
                    <input type="Email" className="form-control" id="Email" readOnly placeholder="Email" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <a href="javascript:void(0);"
                                      id="lnkUpdateEmail"
                                      onClick={this.OpenInvitePopup}
                  
                    className="btn btn-primary btngroup">Update Email</a>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Password</label>
                    <div className="col-lg-8 position-relative d-flex align-items-center">
                    <input type={this.state.showPassword ? 'text' : 'password'} id="Password" className="form-control" readOnly placeholder="Password" />
                    <IconButton
                      disableRipple
                      onClick={this.handleClickShowPassword}
                      className='position-absolute rigterpin d-flex mr-2'
                    >
                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                     
                  <button id="ResetPasswordBtn" onClick={this.ResetPassword} className="btn btn-primary btngroup">Reset Password</button>
                </div>
                <div className="col-lg-6 my-3">
                  <div className="m-checkbox-inline">
                    <label className="check_bx">
                        <input type="checkbox" id="IsSentMeetingNotification"/><span className="checkmark"></span>
                        Is Sent Meeting Notification
                    </label> 
                  </div>
                </div>
                <div className="col-lg-6"></div>
                <div className="col-lg-6 my-3">
                  <div className="m-checkbox-inline">
                    <label className="check_bx">
                        <input type="checkbox" id="IsSentPotentialMeetingNotification"/><span className="checkmark"></span>
                        Is Sent Potential Meeting Notification
                    </label> 
                  </div>
                </div>
                <div className="col-lg-6"></div>
                <div className="col-lg-6 my-3">
                  <div className="m-checkbox-inline">
                    <label className="check_bx">
                        <input type="checkbox" id="IsSentWeeklyEmailNotification"/><span className="checkmark"></span>
                        Is Sent Weekly Email Notification
                    </label> 
                  </div>
                </div>
                <div className="col-lg-6"></div>
                <div className="col-lg-6 my-3">
                  <div className="m-checkbox-inline">
                    <label className="check_bx">
                        <input type="checkbox" id="IsTwoStepVerification"/><span className="checkmark"></span>
                        Is Two Step Verification
                    </label> 
                  </div>
                </div>
                <div className="col-lg-6"></div>
                
                <div className="col-lg-6 boxsinput_group mb-5">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Upload Profile Image </label>
                    <div className="col-lg-8">
                    <input type="file"
                     id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                        name="avatar"
                        onChange={this.HandleUpload.bind(this, "coverimage")}/>
                    <span id="ImageTitle"></span>
                    <img src="" style={imgstyle} id="OldImage"/>
                    </div>
                  </div>
                </div> 
              </div>
            </div>

              <div className="row py-4">
                <div className="col-lg-12 pull-left">
                  <a
                    id="submit"
                    onClick={this.savebtn}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </a>
                  <a
                    id="usersperclient"
                    onClick={this.backbtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
      <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCEditUsersPerClientPage = connect(
  mapState,
  actionCreators
)(CEditUsersPerClientPage);
export { connectedCEditUsersPerClientPage as CEditUsersPerClientPage };
