import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import "react-toastify/dist/ReactToastify.css";

import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

class AddDefaultCountryBlacklistPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      CountryNameAvailable: false,
      ClientID: null,
      UserID: null,
      CUserID: null,
      IsExist: false,
      Role: null,
      BtnDisabled: false,
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
  }
  componentDidMount() {
    document.title = "Add | SalesHive";

    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
  }

  // form validation
  async FromValidation() {
    let FormIsValid = true;
    let Errors = {};
    var Country = document.getElementById("Country").value.trim();
    var IsExists = await this.CoutryNameCheckExist(Country);
    if (IsExists == "BlankData" && Country == "") {
      FormIsValid = false;
      Errors["Country"] = "Please enter country";
    }
    if (IsExists == "ExistCountry") {
      FormIsValid = false;
      Errors["Country"] = "Country already exist.";
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  async HandleChangeBlur() {
    let Errors = { ...this.state.Errors };
    var Country = document.getElementById("Country").value.trim();

    let IsExists = await this.CoutryNameCheckExist(Country);
    if (IsExists == "BlankData" && Country == "") {
      
      Errors["Country"] = "Please enter country";
    }
    if (IsExists == "ExistCountry") {
     
      Errors["Country"] = "Country already exist.";
    }

    if (IsExists == "AllDone") {
      Errors["Country"] = null;
    }

    this.setState({ Errors: Errors });
  }

  // handle change
  HandleChange(field, e) {
    let Fields = this.state.Fields;
    Fields[field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Country != "") {
      this.state.Errors.Country = null;
      this.CoutryNameCheckExist(e.target.value);
    } else {
      this.state.Errors.Country = null;
    }
  }

  // check exit name
  async CoutryNameCheckExist(Country) {
    if (Country != "") {
      var CheckExitsData = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Country: Country,
        Role: this.state.Role,
      };
      let resdata = await Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/defaultcountryblacklist/DefaultCountryBlacklistExists",
        method: "POST",
        data: CheckExitsData,
      });
      if (resdata.data.Data.length > 0) {
        this.setState({ CountryNameAvailable: resdata.data.Data });
        return "ExistCountry";
      } else {
        return "AllDone";
      }
    } else {
      return "BlankData";
    }
  }

  // back button
  BackBtn() {
    history.push("/Defaultcountryblacklist");
  }

  // defualt country black list add
  async SaveBtn(e) {
    // document.getElementById("submit").disabled = true;
 e.preventDefault();

    this.setState({ BtnDisabled: true });
    var Final_flag = await this.FromValidation();
    if (Final_flag == true) {
      var Country = document.getElementById("Country").value;
      var Abbreviation = document.getElementById("Abbreviation").value;
      var DefualtCountryBlackListData = {
        UserID: this.state.UserID,
        Country: Country,
        Abbreviation: Abbreviation,
        CreatedDate: new Date(),
        CreatedBy: this.state.CUserID,
        Role: this.state.Role,
        ClientID:this.state.ClientID
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/defaultcountryblacklist/DefaultCountryBlacklistAdd",
        method: "POST",
        data: DefualtCountryBlackListData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div>Country blacklist will be added soon.</div>);
          history.push("/Defaultcountryblacklist");
        } else {
          toast.error(res.data.Message);
          // document.getElementById("submit").disabled = false;
          this.setState({ BtnDisabled: false });
        }
      });
    } else {
      // document.getElementById("submit").disabled = false;
      this.setState({ BtnDisabled: false });
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad lg-headertitle  float-left">
                  Add
                </h4>
              </div>
            </div>

            <div class="bg-white p-3">
              <div className="row">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols d-flex align-items-baseline">
                    <label class="col-lg-4 view-lable-meet">Country </label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter category Country"
                        id="Country"
                        onBlur={
                          this.HandleChangeBlur}
                        // onChange={(e)=>{this.CoutryNameCheckExist1(e.target.value)}}
                        name="Country"
                        placeholder="Enter country"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Country"]}
                      </span>
                      {/* {this.state.CountryNameAvailable && (
                        <span style={{ color: "red" }}>
                          Country already exist.
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols d-flex align-items-baseline">
                    <label class="col-lg-4 view-lable-meet">
                      Abbreviation{" "}
                    </label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter abbreviation"
                        id="Abbreviation"
                        name="Abbreviation"
                        placeholder="Enter abbreviation"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Abbreviation"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row my-4">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    disabled={this.state.BtnDisabled}
                    onClick={this.SaveBtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
            
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddDefaultCountryBlacklistPage = connect(
  mapState,
  actionCreators
)(AddDefaultCountryBlacklistPage);
export { connectedAddDefaultCountryBlacklistPage as AddDefaultCountryBlacklistPage };
