import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { toast } from "react-toastify";
import ReactApexchart from "react-apexcharts";
import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
//import CallHistory from '../_components/coldcalling/callhistory';
//import CollapsibleTable from '../_components/usertable';
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

toast.configure();

class EditDefaultTrackingDomainPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      fields: {},
      errors: {},
      NameAvailable: null,
      ClientID: Details.ClientID,
      UserID: Details.ChildUserID,
      PUserID: Details.ParentUserID,
      IsExist: false,
      IsValid:true,
      PrevDomain:null,
      BtnDisabled:false
    };
    this.GetDefaultEmailTrackingDomainData();
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {}

  
  
  GetDefaultEmailTrackingDomainData(){
    var InputParameter = {
      ID: this.props.location.state,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/default_tracking_domain/DefaultEmailTrackingDomainGetByID",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res?.data?.StatusMessage == "SUCCESS") {
        document.getElementById('Domain').value=res?.data?.Data?.Domain
        this.setState({PrevDomain:res?.data?.Data?.Domain})
      }
    })
  }
 
  async FormValidation() {
    let formIsValid = true;
      let errors = {};
    var Domain = document.getElementById("Domain").value.trim();
    var IsExist = await this.CheckExistDomain();
      if (Domain.length<=0) {
        formIsValid = false;
        errors["Domain"] = "Please enter Domain";
      }
      if (this.state.IsExist) {
        formIsValid = false;
        errors["Domain"] = "Domain is already exists!";
      }
      if(!this.state.IsValid){
        formIsValid = false;
        errors["Domain"] = "Invalid domain";
      }
      if(IsExist == true){
        formIsValid = false;
      }
      this.setState({ errors: errors });
    
    return formIsValid;
  }

  //Domain Check 
  DomainCheck(Domain){
   let regexp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
  
    if (regexp.test(Domain))
      {
        return true;
      }
    else
      {
        return false;
      }
  }

  // check exit domain
  async CheckExistDomain() {
    var Domain = document.getElementById("Domain").value;
    var InputParameter = {
      Domain: Domain,
      UserID:this.state.PUserID
    };
    var resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/default_tracking_domain/CheckDomainExist",
      method: "POST",
      data: InputParameter,
    })
      let errors = {};
      if (resdata.data.Data > 0 && this.state.PrevDomain != Domain) {
        let fields = this.state.fields;
        if (!fields["Domain"]) {
                errors["Domain"] = "Domain is already exists!";
        }
        this.setState({ IsExist: true });
        this.setState({ errors: errors }); 
      }
      // this.setState({ IsExist: false });
      this.setState({ errors: errors }); 
  
    if(Domain.length>0){
      if(!this.DomainCheck(Domain)){
        let errors = {};
        let fields = this.state.fields;
        if (!fields["Domain"]) {
                errors["Domain"] = "Invalid domain";
                this.setState({ IsValid: false });
                this.setState({ errors: errors }); 
        }else{
          this.setState({ IsValid: true });
        }
      }else{
        this.setState({ IsValid: true });
      }
    }
    
  }

  backbtn() {
    history.push("/defaulttrackingdomain");
  }
  async savebtn(e) {
    this.state.errors.Domain = null;
    this.state.NameAvailable = null;
    // e.preventDefault();
    // e.currentTarget.disabled = true;
    this.setState({BtnDisabled:true})
    var FinalValidation = await this.FormValidation()
    if (FinalValidation == true) {
    var Domain = document.getElementById("Domain").value.trim();
      var data = {
        ID:this.props.location.state,
        Domain:Domain,
        IsWorking:true,
        LastUpdatedBy: this.state.UserID,
        LastUpdatedDate: new Date(),
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/default_tracking_domain/DefaultEmailTrackingDomainUpdate",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize" >Default Tracking Domains Edit<br/>Default Tracking Domains edited successfully.</div>);
          history.push("/defaulttrackingdomain");
          // console.log("successfull data status", res.data);
          this.setState({BtnDisabled:false})
        } else {
          toast.error(res.data.Message);
        }
      });
    }else{
      // document.getElementById("submit").disabled = false;
      this.setState({BtnDisabled:false})
    }
    
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad lg-headertitle float-left pl-0">Edit Default Tracking Domain</h4>
                </div>
              </div>

            <div class="bg-white p-3">
              <div className="row">  
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet pt-0">Title</label>
                    <div class="col-lg-8">
                      <input class="form-control m-input" onBlur={this.CheckExistDomain.bind(this,'Domain')} id="Domain" placeholder="Enter Domain" type="text" />
                    <span style={{ color: "red" }}>{this.state.errors["Domain"]}</span>

                    </div>
                  </div>
                </div>  
              </div>

            </div>
              <div class="row my-4">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.savebtn}
                    disabled={this.state.BtnDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.backbtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>

          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditDefaultTrackingDomainPage = connect(
  mapState,
  actionCreators
)(EditDefaultTrackingDomainPage);
export { connectedEditDefaultTrackingDomainPage as EditDefaultTrackingDomainPage };
