import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import ClientKPIsTabing from "../../_components/user/ClientKpis/ClientKpisTabing";
const moment = require("moment");
import $ from "jquery";
import {
  getFirstDayOfQuarter,
  getCurrentDayOfQuarter,
} from "../../_helpers/Utility";

class ClientKPIsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      FromDate: "",
      ToDate: "",
      IsIncludeOldClient: false,
    };
    this.Analyze = this.Analyze.bind(this);
    this.daterefect = React.createRef();
    this.daterefect2 = React.createRef();
  }
  componentDidMount() {
    document.title = "Client KPIs | SalesHive";
    var fromdate = getFirstDayOfQuarter();

        const previousMonthDate = new Date(fromdate);
    
        // Convert to UTC date components
        const utcYear = previousMonthDate.getUTCFullYear();
        const utcMonth = String(previousMonthDate.getUTCMonth() + 1).padStart(2, '0');
        const utcDay = String(previousMonthDate.getUTCDate()).padStart(2, '0');
    
        // Format the date
        const formattedDate = `${utcMonth}/${utcDay}/${utcYear}`;
        
        // const formattedDate = previousMonthDate.toLocaleDateString('en-US', {
        //     month: '2-digit',
        //     day: '2-digit',
        //     year: 'numeric'
        // });
        
        // Set the value of the "from-date" input field
        document.getElementById("from-date").value = formattedDate;
            const $datepickerElement = $(this.daterefect.current); 
        $datepickerElement.datetimepicker({
            format: 'mm/dd/yyyy',
            autoclose: true,
            container: '#app',
            todayBtn: true,
            todayHighlight: true,
            minView: '2',
        });
        document.getElementById("to-date").value = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
        const $datepickerElement2 = $(this.daterefect2.current); 
        $datepickerElement2.datetimepicker({
            format: 'mm/dd/yyyy',
            autoclose: true,
            container: '#app',
            todayBtn: true,
            todayHighlight: true,
            minView: '2',
        });
    
        var FromDate = moment.utc(document.getElementById("from-date").value).format("YYYY-MM-DD");
        var ToDate = moment.utc(document.getElementById("to-date").value).format("YYYY-MM-DD");
        var IsIncludeOldClient=document.getElementById("IsIncludeOldClient").checked;
        this.setState({
          FromDate: FromDate,
          ToDate: ToDate,
          IsIncludeOldClient:IsIncludeOldClient
        });
      }
    
    Analyze() {
        var FromDate = moment.utc(document.getElementById("from-date").value).format("YYYY-MM-DD");
        var ToDate = moment.utc(document.getElementById("to-date").value).format("YYYY-MM-DD");
        var IsIncludeOldClient=document.getElementById("IsIncludeOldClient").checked;
        this.setState({ FromDate: FromDate, ToDate: ToDate,IsIncludeOldClient:IsIncludeOldClient });
      }
    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col padd-0">
                            <h4 className="headertitlebd clientkpis lg-headertitle">Client KPIs</h4>
                        </div>
                        <div className="col padright">
                             
                        </div>
                    </div>  

                    <div className="mb-2">
                      <div className="row justify-content-end ml-auto align-items-center">
                   
                          <div className="pt-3 padright">
                  <div className="btn-group datepicker-hed">
                    <div className="px-2">
                  <label class="check_bx mb-0">
                                  <input type="checkbox" id='IsIncludeOldClient'/><span className="checkmark"></span>
                                  Include Old Clients
                              </label> 
                    </div>
                  <div className="timedatepikker date-input mx-2">
                                  <input className="form-control m-input"
                                type='text'
                                id='from-date'
                                ref={this.daterefect}
                              /> 
                              
                  </div>
                  <div className="timedatepikker date-input mx-2">
                  <input className="form-control m-input"
                    type='text'
                    id='to-date'
                    ref={this.daterefect2}
                  /> 
                  </div>
                    <button
                    onClick={this.Analyze}
                      id="aCompanyState"
                      class="btn btn-primary btnColor m-btn brrb72 mx-2"
                      autocomplete="off"
                    >
                      <i class="la flaticon-diagram"></i>
                      <span>Compare</span>
                    </button>
                  </div>
                          </div>
                      </div>
                    </div> 

            <div className="">
              <ClientKPIsTabing
                FromDates={this.state.FromDate}
                ToDates={this.state.ToDate}
                IsIncludeOldClient={this.state.IsIncludeOldClient}
              />
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedClientKPIsPage = connect(
  mapState,
  actionCreators
)(ClientKPIsPage);
export { connectedClientKPIsPage as ClientKPIsPage };
