import React from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { CSVDownloader } from "react-papaparse";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import CustomVariable from "../../../_components/user/CustomVariable/CustomVariable";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { GetClientDetails } from "../../../_helpers/Utility";

import "react-toastify/dist/ReactToastify.css";
toast.configure();


class CustomVariablePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ClientID: null,
      UserID: null,
      exportdata: [],
      CName: "",
    };
  }

  ExportBtn() {
    toast.success(<div>Data exported successfully.</div>);
  }
  componentDidMount() {
    
    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ClientID:Details.ClientID});
      this.setState({UserID:Details.ParentUserID});
    }
    GetClientDetails(Details.ClientID).then((result) => {
      this.setState({ CName: result[0].Name });
    });
    this.ExportList(Details.ClientID,Details.ParentUserID);
  }

  addac() {
    history.push("/addcustomvariable");
  }

  UpdateFromChild = (value) => {
    if (value == true) {
      this.componentDidMount();
    }
  };

  async ExportList(CID,UID) {
    
    var str_in = {
      ClientID: CID,
      UserID: UID,
    };
    var result = await Axios({
      url: CommonConstants.MOL_APIURL + "/customvariable/ExportCustomVariable",
      method: "POST",
      data: str_in,
    })
    if(result.statusText === "OK" && result.status === 200){
      this.setState({ exportdata: result.data })
    }
  }
  render() {
    return (
      <>
        <div className="adminmain mheight"> 
            <Sidebar className="" />
          <div className="bodyhome">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="headertitlebd lg-headertitle mb-0 float-left">
                  Custom Variables
                </h4>
              </div>
              <div className="col">
                <div className="listing-li float-right pt-0">
                  <ul class='my-0'>
                    <li className="px-0">
                      <CSVDownloader
                        className="px-0"
                        data={this.state.exportdata}
                        filename={this.state.CName +"-Custom-Variable"}
                        bom={true}
                      >
                        <a
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          onClick={this.ExportBtn}
                        >
                          <i class="la la-icon-font-size-13 la-download"></i>
                          <span>Export</span>
                        </a>
                      </CSVDownloader>
                    </li>
                    <li className="px-1">
                      <a
                        onClick={this.addac}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="whiterow px-3">
              <div className="row">
                <div className="col">
                  <CustomVariable updateFromChild={this.UpdateFromChild} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCustomVariablePage = connect(
  mapState,
  actionCreators
)(CustomVariablePage);
export { connectedCustomVariablePage as CustomVariablePage };
