import Axios from "axios";
import { CommonConstants } from "../../src/_constants/common.constants";
import { history } from '../_helpers';

export function GetUserDetails() {
    let ObjLoginData = JSON.parse(localStorage.getItem('LoginData'));

    if (ObjLoginData && ObjLoginData != null) {
        return ObjLoginData;
    } else if(window.location.pathname == "/twostepverification"){
        return false

    }else {
        history.push("/");
        return  false;
    }
}

export function UpdateUserDetails(ClientID) {
    
    let ObjLoginData = JSON.parse(localStorage.getItem('LoginData'));
    if (ObjLoginData && ObjLoginData != null) {
        ObjLoginData.ClientID = ClientID;
        localStorage.setItem("LoginData", JSON.stringify(ObjLoginData));
    
        return ObjLoginData;
    } else {
        return  null;
    }
}

export async function GetClientDetails(ClientID) {
    var Str_In = {
        ClientID:ClientID
    } 
    var ClientDetails = await Axios({
        url: CommonConstants.MOL_APIURL + "/client/ClientGetWithID",
        method: "POST",
        data: Str_In,
      });
    
    return ClientDetails.data.Data;
}

export const EmailValidation = (Email) => {
      const EmailRegex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;;
      return EmailRegex.test(Email);
}

export const URLValidation = (Url) => {
        const UrlPatterns = /^(https?|ftp):\/\/[A-Za-z0-9-._~:/?#[\]@!$&'()*+,;=]+$/;
        return UrlPatterns.test(Url);
}

export const DomainValidation = (Domain) => {
  let DomainRegex = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
 return DomainRegex.test(Domain)
}

export const getFirstDayOfQuarter = () => {
    const now = new Date();
    const month = now.getMonth(); // getMonth() returns 0-11
    let startMonth;
  
    if (month < 3) {
      startMonth = 0; // January
    } else if (month < 6) {
      startMonth = 3; // April
    } else if (month < 9) {
      startMonth = 6; // July
    } else {
      startMonth = 9; // October
    }
  
    // Create the first date of the quarter in local time
    const firstDateOfQuarter = new Date(now.getFullYear(), startMonth, 1);
  
    // Adjust the first date to UTC time zone
    const firstDateOfQuarterUTC = new Date(Date.UTC(firstDateOfQuarter.getFullYear(), firstDateOfQuarter.getMonth(), firstDateOfQuarter.getDate()));
  
    return firstDateOfQuarterUTC;
  };

export const getFirstDayOfQuarter1 = () => {
    // const currentDate = new Date();
    // const quarterNumber = Math.floor((currentDate.getMonth() - 1) / 3) + 1;
    // const firstDayOfQuarter = new Date(currentDate.getFullYear(), (quarterNumber - 1) * 3, 1);
    // return firstDayOfQuarter;

        // const currentDate = new Date();
        // const quarterNumber = Math.floor(currentDate.getMonth() / 3) + 1;
        // const firstDayOfQuarter = new Date(currentDate.getFullYear(), (quarterNumber - 1) * 3, 1);
        // return firstDayOfQuarter;

        const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const quarterStartMonth = Math.floor(currentMonth / 3) * 3; 
    const firstDateOfQuarter = new Date(currentYear, quarterStartMonth, 1);
    return firstDateOfQuarter;
  }

  export const getLastDayOfQuarter = () => {
    const currentDate = new Date();
    const quarterNumber = Math.floor((currentDate.getMonth() - 1) / 3) + 1;
    const firstDayOfQuarter = new Date(currentDate.getFullYear(), (quarterNumber - 1) * 3, 1);
    return firstDayOfQuarter;
  }
  export const getCurrentDayOfQuarter = () =>   
  {
        const currentDate = new Date();
      return currentDate;
  }


  export function getFormattedFirstDayOfQuarterUTC() {
    const fromDate = getFirstDayOfQuarter(); // Assuming this is defined somewhere
    const previousMonthDate = new Date(fromDate);

    // Convert to UTC date components
    const utcYear = previousMonthDate.getUTCFullYear();
    const utcMonth = String(previousMonthDate.getUTCMonth() + 1).padStart(2, '0');
    const utcDay = String(previousMonthDate.getUTCDate()).padStart(2, '0');

    // Format the date as mm/dd/yyyy
    return `${utcMonth}/${utcDay}/${utcYear}`;
}