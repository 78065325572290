import React from "react";
import Axios from "axios";
let CryptoJS = require("crypto-js");
import { connect } from "react-redux";

import logobgs from "../../../images/Loginvector.svg";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
const Uvalidation = require("../../../_helpers/user-validation");
const EmailTemplete = require("../../../_helpers/email-templete");

class UpdateUsersPerClientPage extends React.Component {
  constructor(props) {
    // let Details = GetUserDetails();
    super(props);
    this.state = {
      Email: "",
      id: "",
      OldEmail: "",
      // Role:Details.Role,
      Role:"Admin",
      BtnDisabled:false
    };

    this.SubmittedData = this.SubmittedData.bind(this);
    this.BackButton = this.BackButton.bind(this);
  }
  componentDidMount() {
      document.title="Update Email | SalesHive"
    const QueryParams = new URLSearchParams(window.location.search);
    const code = QueryParams.get("code");
    const Tmp = QueryParams.get("email");

    let FinalTmp = Tmp.split(" ").join("+");
    let bytes = CryptoJS.AES.decrypt(FinalTmp, "my-secret-key@123");

    let Email = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    document.getElementById("Email").value = Email;
    this.setState({ Email: Email });
    let InputParameters = {
      EmailToken: code.split(' ').join('+'),
      Email:Email
    };
    
    Axios({
      url: CommonConstants.MOL_APIURL + "/user/finduserEmailcode",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
        if(res.data.StatusMessage == 'SUCCESS'){
            if(res.data.Data?.length > 0){
              this.setState({ id: res.data.Data[0]._id });
              this.setState({ OldEmail: res.data.Data[0].Username });
            }else{
              history.push("/");
            }
        }else{
          this.setState({ id: "" });
          this.setState({ OldEmail: "" });
          this.setState({BtnDisabled:true})

        }
      
    });
  }
 
//Update email
  SubmittedData() {
    this.setState({BtnDisabled:true})
    let Email = document.getElementById("Email").value;
    if (this.state.Email == Email && this.state.id && this.state.id !="") {
    const RequestBody = {
      Email:Email,
      UserID:this.state.id,
      OldEmail:this.state.OldEmail,
      LastUpdatedDate:new Date(),
      Role:this.state.Role
    }
    Axios({
      url: CommonConstants.MOL_APIURL +"/users_per_client/UpdateEmail",
      method: "POST",
      data: RequestBody,
    }).then((res) => {
        if(res.data.StatusMessage == 'SUCCESS'){
          history.push("/UsersPerClient");
        }else {
          this.setState({BtnDisabled:false})
        }
    });
  }
    // this.setState({BtnDisabled:true})
    // if (this.state.Email == Email) {
    //   let InputParameters = {
    //     Email: Email,
    //     uID: this.state.id,
    //   };
    //   Axios({
    //     url: CommonConstants.MOL_APIURL + "/user/updateEmail",
    //     method: "POST",
    //     data: InputParameters,
    //   }).then((res) => {
    //     if (res.data != "") {
    //       let InputParameters = {
    //         Email: Email,
    //         OldEmail: this.state.OldEmail,
    //         Role:this.state.Role
    //       };
    //       Axios({
    //         url:
    //           CommonConstants.MOL_APIURL +
    //           "/users_per_client/UpdateEmail",
    //         method: "POST",
    //         data: InputParameters,
    //       }).then((res) => {
            
    //         history.push("/UsersPerClient");
    //       });
    //       //resetpass_u.style.display = "block";
    //       // this.setState({
    //       //     open: true
    //       // });
    //       //window.location = CommonConstants.home;
    //     }else{
    //       this.setState({BtnDisabled:false})
    //     }
    //   });
    // }
  }

//back
  BackButton() {
    window.location = CommonConstants.home;
  }
  
  render() {
    return (
      <div className="login-main">
        <div className="row h-100">
          <div className="col-xs-12 col-md-6 logo-bgbod padding-btn">
            <img src={logobgs} className="login-vector" />

            <div className="m-login__logo">
              <a href="/">
                <img src={""} className="main-logo" />
              </a>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 d-flex">
            {/* Login Form in sign in page*/}
            <div className="full-hegiht">
              <div id="login" className="centerheigh ">
                <div className="heading">
                  <h1 className="lefter_title">Update Email</h1>
                </div>
                <form name="login" className="form">
                  <div className="input-control">
                    <label for="Email" className="input-label" hidden>
                      Email Address
                    </label>
                    <input
                      type="Email"
                      id="Email"
                      name="Email"
                      className="input-field"
                      placeholder="Email Address"
                      readOnly={true}
                    />
                  </div>

                  <div className="input-control btn-center">
                    <button
                      type="button"
                      name="submit"
                      className="input-submit"
                      value="Save"
                      disabled={this.state.BtnDisabled}
                      onClick={() => this.SubmittedData()}
                    >
                      <i className="la la-save"></i> Save
                    </button>
                    <a
                      type="button"
                      name="submit"
                      className="input-submit"
                      value="Save"
                      onClick={() => this.BackButton()}
                    >
                      <i className="la la-arrow-circle-left"></i> Back
                    </a>
                  </div>
                </form>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  return state;
}

const actionCreators = {};

const connectedUpdateUsersPerClientPage = connect(
  mapState,
  actionCreators
)(UpdateUsersPerClientPage);
export { connectedUpdateUsersPerClientPage as UpdateUsersPerClientPage };
