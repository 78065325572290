import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import CallHistory from '../../_components/clients/coldcalling/callhistory';
import { getFormattedFirstDayOfQuarterUTC } from "../../_helpers/Utility";
import $ from "jquery";
const moment=require('moment');



class CCallHistoryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            FromDate:"",
            ToDate:""
        };
        
        this.SaveBtn = this.SaveBtn.bind(this);
        this.daterefect = React.createRef();
        this.daterefect2 = React.createRef();
    }
    
    componentDidMount() {
        // Format the date
        const formattedDate = getFormattedFirstDayOfQuarterUTC()
        
        // Set the value of the "from-date" input field
        document.getElementById("from-date").value = formattedDate;
            const $datepickerElement = $(this.daterefect.current); 
        $datepickerElement.datetimepicker({
            format: 'mm/dd/yyyy',
            autoclose: true,
            container: '#app',
            todayBtn: true,
            todayHighlight: true,
            minView: '2',
        });
        document.getElementById("to-date").value = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
        const $datepickerElement2 = $(this.daterefect2.current); 
        $datepickerElement2.datetimepicker({
            format: 'mm/dd/yyyy',
            autoclose: true,
            container: '#app',
            todayBtn: true,
            todayHighlight: true,
            minView: '2',
        });
    
        var FromDate = moment.utc(document.getElementById("from-date").value).format("YYYY-MM-DD");
        var ToDate = moment.utc(document.getElementById("to-date").value).format("YYYY-MM-DD");
        this.setState({FromDate: FromDate,ToDate: ToDate});
      }
    
      SaveBtn() {
        var FromDate = moment.utc(document.getElementById("from-date").value).format("YYYY-MM-DD");
        var ToDate = moment.utc(document.getElementById("to-date").value).format("YYYY-MM-DD");
        
        this.setState({ FromDate: FromDate, ToDate: ToDate});
      }


    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                    <Sidebar className=""/> 
                    <div className="bodyhome">

                  
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitlepad float-left mb-0 py-4">Call History</h4>
                        </div>
                        <div className="col pt-3">
                                <div className="btn-group datepicker-hed">
                                    <input id="fromtab" name="fromtab" type="hidden" value="" />
                                    <div className="col-lg-6 timedatepikker date-input">
                                        <input className="form-control datepicker" id="from-date" name="from-date" type="text" ref={this.daterefect} autoComplete="off" />
                                    </div>
                                    <div className="col-lg-6 timedatepikker date-input">
                                        <input className="form-control datepicker" id="to-date" name="to-date" type="text" ref={this.daterefect2} autoComplete="off" />
                                    </div>
                                    <button id="aCompanyState" className="btn btn-primary btnColor btn-radius" onClick={this.SaveBtn} autoComplete="off">
                                        <i className="la flaticon-diagram"></i>
                                        <span>Analyze</span>
                                    </button>
                                </div>
                            </div>
                    </div> 
                        <div className="paddcols">  
                            <div className="row">
                                <div className="col">
                                    <CallHistory ToDate={this.state.ToDate} FromDate={this.state.FromDate}/> 
                                </div>
                            </div>    
                        </div>
                    </div> 
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCCallHistoryPage = connect(mapState, actionCreators)(CCallHistoryPage);
export { connectedCCallHistoryPage as CCallHistoryPage };