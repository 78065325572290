import React, { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { makeStyles } from "@material-ui/core/styles";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";

import loadingicon from "../../../images/loading.gif";
import StyleHeader from "../StickyHeader/StickyHeader";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unSet",
    },
  },
});

export default function MeertingOwnerTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Name");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID,SetCUserID] = React.useState(0)
  const [IsLoading,SetIsLoading] = React.useState(true)
  const [sortedColumn, setSortedColumn] = React.useState("Name");
  const [TotalOwner, SetTotalOwners] = React.useState(0);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    document.title = `Meeting Owners | SalesHive`;
    let Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    MeetingOwnerGet(Details.ClientID, Details.ParentUserID, Details.Role);
  }, [Search, Page, RowsPerPage]);

  const imgstyle = {
    height: "40px",
    width: "40px",
    borderradius: "50%",
  };

  // meeting owner list
  const MeetingOwnerGet = (CID, UID, URole) => {
    SetIsLoading(true)
    let MeetingOwnerData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };

    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/meetingowner/MeetingOwnerGet",
      method: "POST",
      data: MeetingOwnerData,
    });
    rows1.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetTotalOwners(result.data.TotalOwners);
      SetIsLoading(false)
      setIsDataLoaded(true);

    });
  };

  // delete btn
  const DeleteBtn = async (id, CSName) => {
    let str_in = {
      ID: id,
      Type: "User",
      ClientID: ClientID,
      Role: Role
    };
    const ContactSource = await Axios({
      url: CommonConstants.MOL_APIURL + "/meetingowner/MeetingOwnerGetDropDown",
      method: "POST",
      data: str_in,
    });
    
      let Datas = await ContactSource.data.Data.filter(obj => obj._id !== id);
      let MeetingOwnerDropDown = [];
      Datas.map((source) => {
        MeetingOwnerDropDown[source._id] = `${source.Name}`;
      });

    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a Meeting Owner.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Replace Owner",
          text: "Which value replace with '" + CSName + "' meeting owner?",
          confirmButtonColor: "#34bfa3",
          cancelButtonColor: "#34bfa3",
          confirmButtonText: "Save",
          cancelButtonText: "Cancel",
          reverseButtons: true,
          input: "select",
          inputOptions: MeetingOwnerDropDown,
         // inputPlaceholder: "Select meeting owner",
          showCancelButton: true,
        }).then((Suecces) => {
          if (Suecces.isConfirmed) {
            let UpdateId = Suecces.value;
            let UpdatingParameters = {
              ClientID:ClientID,
              MeetingOwnerID: id,
              NewMeetingOwnerID: UpdateId,
              OwnerStatus:MeetingOwnerDropDown[Suecces.value],
              LastUpdatedBy: CUserID,
              LastUpdatedDt: new Date(),
              Role: Role
            };
            Axios({
              url:
                CommonConstants.MOL_APIURL +
                "/meetingowner/MeetingOwnerReplace",
              method: "POST",
              data: UpdatingParameters,
            }).then(async (res) => {
              
              if (res) {
                if ((res.statusText = "Ok")) {
                  if (res.data.StatusMessage == "SUCCESS") {
                    Swal.fire(
                      "Deleted!",
                      "Meeting owner deleted successfully.",
                      "success"
                    );
                    MeetingOwnerGet(ClientID, UserID, Role);
                    SetPage(1);
                    SetSearch("");
                    document.getElementById("search").value = "";
                  }
                }
              }
            });
          } else if (Suecces.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelled", "Your data is safe :)", "error");
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };
  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
       
      }
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      SetPage(1)
     
    }
  };

  // meeting owner page
  const MeetingOwnerEditPage = (id) => {
    history.push({ pathname: "/editmeetingowner", state: { data: id } });
  };

  //get sort field data
  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");

    let InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Search: SearchedVal,
      Archive: false,
      Sort: true,
      Field: Field,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
    };
    const ApiResponse = Axios({
      url: CommonConstants.MOL_APIURL + "/meetingowner/MeetingOwnerGet",
      method: "POST",
      data: InputParameter,
    });
    ApiResponse.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetTotalOwners(Result.data.TotalOwners);
      SetIsLoading(false)
    });


    // let SearchedVal = document.getElementById("search").value;
    // SetIsLoading(true)
    // let SerchBox;
    // if (SearchedVal == "") {
    //   SerchBox = false;
    //   SetSflag(false);
    //   let SortField = Field;
    //   let SortBy;
    //   if (SortedBy == 1) {
    //     SortBy = -1;
    //     SetSortedBy(-1);
    //   } else {
    //     SortBy = 1;
    //     SetSortedBy(1);
    //   }
    //   SetSortField(Field);
    //   let InputParameter = {
    //     Page: Page,
    //     RowsPerPage: RowsPerPage,
    //     SerchBox: false,
    //     Search: SearchedVal,
    //     Archive: false,
    //     Sort: true,
    //     Field: SortField,
    //     SortBy: SortBy,
    //     Type: "User",
    //     ClientID: ClientID,
    //     UserID: UserID,
    //     Role: Role,
    //   };
    //   const ApiResponse = Axios({
    //     url: CommonConstants.MOL_APIURL + "/meetingowner/MeetingOwnerGet",
    //     method: "POST",
    //     data: InputParameter,
    //   });
    //   ApiResponse.then((Result) => {
    //     SetRows(Result.data.PageData);
    //     SetRlen(Result.data.TotalCount);
    //     SetFlen(Result.data.TotalCount);
    //     SetCountPage(Result.data.PageCount);
    //     SetIsLoading(false)
    //   });
    // } else {
    //   SerchBox = true;
    //   SetSflag(true);
    //   let SortField = Field;
    //   let SortBy;
    //   if (SortedBy == 1) {
    //     SortBy = -1;
    //     SetSortedBy(-1);
    //   } else {
    //     SortBy = 1;
    //     SetSortedBy(1);
    //   }
    //   SetSortField(Field);
    //   let InputParameter = {
    //     Page: Page,
    //     RowsPerPage: RowsPerPage,
    //     Search: SearchedVal,
    //     SerchBox: SerchBox,
    //     Archive: false,
    //     Sort: true,
    //     Field: SortField,
    //     SortBy: SortBy,
    //     Type: "User",
    //     ClientID: ClientID,
    //     UserID: UserID,
    //     Role: Role,
    //   };
    //   const ApiResponse = Axios({
    //     url: CommonConstants.MOL_APIURL + "/meetingowner/MeetingOwnerGet",
    //     method: "POST",
    //     data: InputParameter,
    //   });
    //   ApiResponse.then((Result) => {
    //     SetRows(Result.data.PageData);
    //     SetRlen(Result.data.TotalCount);
    //     SetFlen(Result.data.TotalCount);
    //     SetCountPage(Result.data.PageCount);
    //     SetIsLoading(false)
    //   });
    // }
  };

  return (
    <div>
{
      IsLoading ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : <></>
    }
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row pt-3">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
        <Table className="table-ref" aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell  onClick={() => {
                      SortData("Name"); setSortedColumn("Name");
                    }}>
                    Name
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Name" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Name" ? "active" : null} />
                </span>
                </TableCell>
                <TableCell  onClick={() => {
                      SortData("Email");
                      setSortedColumn("Email");
                    }}>
                  {" "}
              
                    Email
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Email" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Email" ? "active" : null} />
                </span>
               
                </TableCell>
                <TableCell      onClick={() => {
                      SortData("ConferenceLineDialIn");
                      setSortedColumn("ConferenceLineDialIn");
                    }}>
                  

                  
                    Conference Line Dial In
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "ConferenceLineDialIn" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "ConferenceLineDialIn" ? "active" : null} />
                </span>
                
                </TableCell>
                <TableCell      onClick={() => {
                      SortData("MeetingPlatformName");
                      setSortedColumn("MeetingPlatformName");
                    }}>
                  

                  
                  Meeting Platform Name 
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "MeetingPlatformName" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "MeetingPlatformName" ? "active" : null} />
                </span>
                
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow key={row?._id}>
                    <TableCell>{row?.Name}</TableCell>
                    <TableCell>{row?.Email}</TableCell>
                    <TableCell>{row?.ConferenceLineDialIn}</TableCell>
                    <TableCell>{row?.MeetingPlatformName??""}</TableCell>
                    <TableCell>
                      <a
                        onClick={() => {
                          MeetingOwnerEditPage(row._id);
                        }}
                        className="btn-eyesicon"
                        title="Edit"
                      >
                        <i class="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      {
                        TotalOwner <= 1 ? <></> : <a
                        onClick={() => {
                          DeleteBtn(row._id, row.Name);
                        }}
                        className="btn-eyesicon"
                        title="Delete"
                      >
                        <i class="la flaticon-delete-1 delete-icon"></i>
                      </a>
                      }
                      
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
      
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
    </div>
  );
}