import React from "react";
import Axios from "axios";
const Moment = require("moment");
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import { CSVDownloader } from "react-papaparse";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import { CommonConstants } from "../../../_constants/common.constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { history } from "../../../_helpers";
import StyleHeader from "../StickyHeader/StickyHeader";

toast.configure();
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable({
  FromDates,
  ToDates,
  IsIncludeOldClient,
}) {
  const classes = useRowStyles();
  const [Flen, SetFlen] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Strategist");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [IsOldClient, SetIsOldClient] = React.useState(false);
  const [IsManger, SetIsManger] = React.useState(false);
  const [sortedColumn, setSortedColumn] = React.useState("Strategist");
  const [FromDate, SetFromDate] = React.useState();
  const [ToDate, SetToDate] = React.useState();
  const [ClientID, SetClientID] = React.useState("");
  const [UserID, SetUserID] = React.useState("");
  const [Role, SetRole] = React.useState("");
  const [LWMeetingSet, SetLWMeetingSet] = React.useState(0);
  const [TotalMeetingSet, SetTotalMeetingSet] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(true);
  const [ResponderList, SetResponderList] = React.useState([]);
  const [StrategistList, SetStrategistList] = React.useState([]);
  const [ResponderID, SetResponderID] = React.useState(null);
  const [SalesHackerID, SetSalesHackerID] = React.useState(null);
  const [IsResponderID, SetIsResponderID] = React.useState(false);
  const [IsSalesHackerID, SetIsSalesHackerID] = React.useState(false);
  const [ExportData, SetExportData] = React.useState([]);
  const [CUserID, SetCUserID] = React.useState(0);
  const [PotentialToggleData, SetPotentialToggleData] = React.useState([]);
  const [MeetingToggleData, SetMeetingToggleData] = React.useState([]);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  React.useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    SetFromDate(FromDates);
    SetToDate(ToDates);
    SetIsOldClient(IsIncludeOldClient);
    ResponderListMethod(Details.ParentUserID, Details.Role);
    StrategistListMethod(Details.ParentUserID, Details.Role);
    AllClientDetails(
      Details.ParentUserID,
      Details.Role,
      FromDates,
      ToDates,
      ResponderID,
      SalesHackerID,
      IsManger,
      IsIncludeOldClient
    );
    CompareKeyMetricsStatisticsByClient(
      Details.ParentUserID,
      IsIncludeOldClient,
      Details.Role,
      FromDates,
      ToDates
    );
  }, [
    FromDates,
    ToDates,
    IsIncludeOldClient,
    Search,
    Page,
    RowsPerPage,
    IsResponderID,
    IsSalesHackerID,
    IsManger,
  ]);

  const CompareKeyMetricsStatisticsByClient = (
    UID,
    IsOldClients,
    URole,
    FDate,
    TDate
  ) => {
    SetIsLoading(true);
    var InputParameter = {
      UserID: UID,
      Role: URole,
      IsIncludeOldClient: IsOldClients,
      FromDate: FDate,
      ToDate: TDate,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/allclientkpi/AllClientsKpiKeymatricStatistics",
      method: "POST",
      data: InputParameter,
    });
    rows1
      .then((result) => {
        if (result.data.StatusMessage == "SUCCESS") {
          SetLWMeetingSet(result.data.TotalMeetingLast7Day);
          SetTotalMeetingSet(result.data.TotalMeeting);
        } else {
        }
      })
      .catch((error) => {
        toast.error("Error fetching data: ", error);
      });
  };

  // Responder List
  const ResponderListMethod = (UID, ROLE) => {
    var InputParameter = {
      UserID: UID,
      Role: ROLE,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/allclientkpi/ResponderList",
      method: "POST",
      data: InputParameter,
    });
    rows1
      .then((result) => {
        if (result.data.StatusMessage == "SUCCESS") {
          SetResponderList(result.data.Data);
        } else {
        }
      })
      .catch((error) => {
        toast.error("Error fetching data: ", error);
      });
  };

  // Strategist List
  const StrategistListMethod = (UID, ROLE) => {
    var InputParameter = {
      UserID: UID,
      Role: ROLE,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/allclientkpi/StrategistList",
      method: "POST",
      data: InputParameter,
    });
    rows1
      .then((result) => {
        if (result.data.StatusMessage == "SUCCESS") {
          SetStrategistList(result.data.Data);
        } else {
        }
      })
      .catch((error) => {
        toast.error("Error fetching data: ", error);
      });
  };


    //Export details
    const Exportdetails = async(UID,ROLE,FDate,TDate,RID,SID,IManger,IOld) => {
      var str_in = {
        Role: ROLE,
        FromDate: FDate,
        ToDate: TDate,
        ResponderID: RID,
        SalesHackerID: SID,
        IncludeOldClient: IOld,
        IsManager: IManger,
        IsSetByPipestry: false,
        UserID: UID,
      };

    const rows1 = await Axios({
      url: CommonConstants.MOL_APIURL + "/allclientkpi/AllClientsKpiExport",
      method: "POST",
      data: str_in,
    });
    if(rows1.data.StatusMessage === "SUCCESS"){
      return rows1.data;
    }else{
      return []
    }
  };


  const exportcsv = async() => {
    try {
      SetIsLoading(true)
      const result = await Exportdetails(UserID,Role,FromDates,ToDates,ResponderID,SalesHackerID,IsManger,IsIncludeOldClient);
      if (result.StatusMessage === "SUCCESS") {
        const data = result.Data;
        if (data.length > 0) {
          SetExportData(data);
          const downloader = document.createElement("a");
          const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(convertToCSV(data));
          downloader.setAttribute("href", csvContent);
          downloader.setAttribute("download", "AllClients-LastWeekResponsesStats");
          downloader.click();
          toast.success(<div>Data exported successfully.</div>);
          SetIsLoading(false);
        } else {
          toast.error("No data available for export.");
          SetIsLoading(false);
        }
      } else {
        toast.error("Failed to export data. Please try again.");
        SetIsLoading(false);
      }
    } catch (error) {
      toast.error("An error occurred while exporting data.");
      SetIsLoading(false);
    }
  };


  function convertToCSV(data) {
    const headers = Object.keys(data[0]);

    const csvRows = [];
    csvRows.push(headers.join(','));

    data.forEach(item => {
        const values = headers.map(header => {
            // Check if the value is a string before attempting to replace characters
            const value = typeof item[header] === 'string' ? item[header] : String(item[header]);
            const escapedValue = value.replace(/"/g, '""'); // Escape double quotes
            return `"${escapedValue}"`; // Enclose value in double quotes
        });
        csvRows.push(values.join(','));
    });

    return csvRows.join('\n');
  }

  const AllClientDetails = (
    UID,
    ROLE,
    FDate,
    TDate,
    RID,
    SID,
    IManger,
    IOld
  ) => {
    try {
      SetIsLoading(true);
      var ResponderGetData = {
        Role: ROLE,
        FromDate: FDate,
        ToDate: TDate,
        ResponderID: RID,
        SalesHackerID: SID,
        IncludeOldClient: IOld,
        IsManager: IManger,
        ClientIDs: [],
        IsSetByPipestry: false,
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: Search,
        UserID: UID,
        // IsResponderID:IsResponderID,
        // IsSalesHackerID:IsSalesHackerID
      };
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/allclientkpi/AllClientsKpi",
        method: "POST",
        data: ResponderGetData,
      });
      rows1
        .then((Result) => {
          if (Result.data.StatusMessage == "SUCCESS") {
            SetRows(Result.data.PageData);
            SetRlen(Result.data.TotalCount);
            SetFlen(Result.data.TotalCount);
            SetCountPage(Result.data.PageCount);
            SetIsLoading(false);
            setIsDataLoaded(true);
          }
        })
        .catch((error) => {
          toast.error("Error fetching data: ", error);
          SetIsLoading(false);
          setIsDataLoaded(true);
        });
    } catch (error) {
      toast.error("An unexpected error occurred: ", error);
      SetIsLoading(false);
    }
  };

  //Get counts details in sub table
  const GetMeetingAndPotentialDetailsInSubTable = (CID) => {
    SetIsLoading(true);
    const InputParameter = {
      ClientID:CID,
      UserID: UserID,
      Role: Role,
    };

    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/allclientkpi/AllClientKpiStatisticMeetingAndPotentialLast7Days",
      method: "POST",
      data: InputParameter,
    })
      .then((res) => {
        if (res.data.StatusMessage === "SUCCESS") {
          SetPotentialToggleData(res.data.PotentialData)
          SetMeetingToggleData(res.data.BookedMeetingData);
          SetIsLoading(false);
        } else {
          SetIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Error fetching data: ", error);
        SetIsLoading(false);
      });
  };

  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true);
    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameters = {
        Role: Role,
        FromDate: FromDate,
        ToDate: ToDate,
        ResponderID: ResponderID,
        SalesHackerID: SalesHackerID,
        IncludeOldClient: IsOldClient,
        IsManager: IsManger,
        ClientIDs: [],
        IsSetByPipestry: false,
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: Search,
        UserID: UserID,
        // IsResponderID: IsResponderID,
        // IsSalesHackerID: IsSalesHackerID,
      };
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/allclientkpi/AllClientsKpi",
        method: "POST",
        data: InputParameters,
      });
      rows1
        .then((Result) => {
          if (Result.data.StatusMessage == "SUCCESS") {
            SetRows(Result.data.PageData);
            SetRlen(Result.data.TotalCount);
            SetFlen(Result.data.TotalCount);
            SetCountPage(Result.data.PageCount);
            SetIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
          SetIsLoading(false);
        });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameters = {
        Role: Role,
        FromDate: FromDate,
        ToDate: ToDate,
        ResponderID: ResponderID,
        SalesHackerID: SalesHackerID,
        IncludeOldClient: IsOldClient,
        IsManager: IsManger,
        ClientIDs: [],
        IsSetByPipestry: false,
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: Search,
        UserID: UserID,
        // IsResponderID: IsResponderID,
        // IsSalesHackerID: IsSalesHackerID,
      };
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/allclientkpi/AllClientsKpi",
        method: "POST",
        data: InputParameters,
      });
      rows1
        .then((Result) => {
          if (Result.data.StatusMessage == "SUCCESS") {
            SetRows(Result.data.PageData);
            SetRlen(Result.data.TotalCount);
            SetFlen(Result.data.TotalCount);
            SetCountPage(Result.data.PageCount);
            SetIsLoading(false);
          }
        })
        .catch((error) => {
          toast.error("Error fetching data: ", error);
          SetIsLoading(false);
        });
    }
  };

  //Advance search
  const AdvSearch = () => {
    let ResponderId = document.getElementById("ResponderID").value;
    let SalesHackerId = document.getElementById("SalesHackerID").value;
    let IsManagers = document.getElementById("IsManager").checked;
    if (ResponderId != 0) {
      SetResponderID(ResponderId);
      SetIsResponderID(true);
    }
    if (SalesHackerId != 0) {
      SetSalesHackerID(SalesHackerId);
      SetIsSalesHackerID(true);
    }
    SetIsManger(IsManagers);
  };

  //Reset search
  const Reset = () => {
    document.getElementById("ResponderID").value = 0;
    document.getElementById("SalesHackerID").value = 0;
    document.getElementById("IsManager").checked = false;
    SetResponderID(null);
    SetSalesHackerID(null);
    SetIsManger(false);
    SetIsResponderID(false);
    SetIsSalesHackerID(false);
  };

  const ExportCsv = () => {
    toast.success(<div>Data exported successfully.</div>);
  };

  const EditPage = (id) => {
    if (id !== undefined) {
      // history.push("/editclients", id);
      history.push({
        pathname: "/editclients",
        state: { data: id, Pagename: "AllClientkpi" },
      });
    }
  };

  return (
    <div>
      {IsLoading === true ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div>
        <div className="row">
          <div className="col">
            <div className="bg-light-sky boxcardcounter align-center">
              <div class="line-title text-center d-block">
                <span class="clr-sky box-main-title">{TotalMeetingSet}</span>
              </div>
              <p class="clr-sky">Meetings Set</p>
            </div>
          </div>
          <div className="col">
            <div className="bg-light-sky boxcardcounter align-center">
              <div class="line-title text-center d-block">
                <span class="clr-sky box-main-title">{LWMeetingSet}</span>
              </div>
              <p class="clr-sky">Meetings Set L7</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 whiterow p-3 mt-4">
      
      <div className="row">
        <div className="col border-bottom">
          <h4 className="headertitle xs-headertitle  float-left pl-0">All Clients </h4>
          <div className="listing-li float-right padb-15">
            <ul>
              <li>
                <a className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air" onClick={exportcsv}>
                    <i className="la la-icon-font-size-13 la-download"></i>
                    <span>
                      Export
                    </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row my-4 d-flex justify-content-between">
        <div class='d-flex'>
        <div className="col col-lg-3">
          <div class="form-group mr-10 d-flex">
            <label className="labeltexts mt-1 mr-1">Responder:</label>
            <select
              className="w-100 form-control  m-input"
              id="ResponderID"
              name="ResponderID"
              onChange={(e) => {
                SetResponderID(e.target.value);
              }}
              value={ResponderID}
            >
              <option value="0">--Select--</option>
              {ResponderList.map((elem) => (
                <option value={elem._id}>
                  {elem.ResponderName} {elem.LastName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col col-lg-3">
          <div class="form-group mr-10 d-flex">
            <label className="labeltexts mt-1 mr-1"> Strategist:</label>
            <select
              className="w-100 form-control  m-input"
              id="SalesHackerID"
              name="SalesHackerID"
              onChange={(e) => {
                SetSalesHackerID(e.target.value);
              }}
              value={SalesHackerID}
            >
              <option value="0">--Select--</option>
              {StrategistList.map((elem) => (
                <option value={elem._id}>
                  {elem.SalesHackerName} {elem.LastName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col col-lg-2">
          <div className="boxborders">
            <label class="check_bx">
              <input type="checkbox" id="IsManager" />
              <span className="checkmark"></span>
              Is Manager
            </label>
          </div>
        </div>
        </div>

        <div className="col-auto">
          <a
            href="javascript:void(0);"
            class="btn btn-primary btnColor"
            onClick={() => {
              AdvSearch();
            }}
          >
            Search
          </a>
          <a
            href="javascript:void(0);"
            class="ml-3 btn btn-secondary"
            onClick={() => {
              Reset();
            }}
          >
            <i class="la la-close"></i> Reset
          </a>
        </div>
      </div>
      <div className="row  pt-2">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="w-100 form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
              onKeyPress={RequestSearch}
            />
          </label>
        </div>
      </div>

      <div className="table-bordered border-0 mb-0">
      <TableContainer component={Paper}> 
        <StyleHeader isDataLoaded={isDataLoaded} />
        <Table className="table-ref" aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Action</TableCell>
              <TableCell className="w-250" onClick={() => { SortData("CrushRate"); setSortedColumn("CrushRate"); }}>
                Crush %
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "CrushRate" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "CrushRate" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => { SortData("ClientName"); setSortedColumn("ClientName"); }}>
                Client
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "ClientName" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "ClientName" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => { SortData("MeetingL7"); setSortedColumn("MeetingL7"); }}>
                Meetings L7
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "MeetingL7" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "MeetingL7" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => { SortData("Meeting"); setSortedColumn("Meeting"); }}>
                Meetings
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Meeting" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Meeting" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => { SortData("PotentialMeetingsL7"); setSortedColumn("PotentialMeetingsL7"); }}>
                P Meetings L7
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "PotentialMeetingsL7" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "PotentialMeetingsL7" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => { SortData("PotentialMeeting"); setSortedColumn("PotentialMeeting"); }}>
                P Meetings
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "PotentialMeeting" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "PotentialMeeting" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => { SortData("Goal"); setSortedColumn("Goal"); }}>
                Goal
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Goal" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Goal" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => { SortData("QtrGoal"); setSortedColumn("QtrGoal"); }}>
                Qtr Goal
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "QtrGoal" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "QtrGoal" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => { SortData("Strategist"); setSortedColumn("Strategist"); }}>
                Strategist
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Strategist" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Strategist" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => { SortData("Responder"); setSortedColumn("Responder"); }}>
                Responder
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Responder" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Responder" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => { SortData("SentL7"); setSortedColumn("SentL7"); }}>
                Sent L7
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "SentL7" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "SentL7" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => { SortData("ResponsesLW"); setSortedColumn("ResponsesLW"); }}>
                Responses LW
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "ResponsesLW" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "ResponsesLW" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => { SortData("OpenRateLastWeek"); setSortedColumn("OpenRateLastWeek"); }}>
                Open % L7
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "OpenRateLastWeek" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "OpenRateLastWeek" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => { SortData("OpenRateLastMonth"); setSortedColumn("OpenRateLastMonth"); }}>
                Open % L30
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "OpenRateLastMonth" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "OpenRateLastMonth" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => { SortData("DifferenceL7L30"); setSortedColumn("DifferenceL7L30"); }}>
                Difference (L7 - L30)
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "DifferenceL7L30" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "DifferenceL7L30" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => { SortData("OpenReplyRateLastWeek"); setSortedColumn("OpenReplyRateLastWeek"); }}>
                O2R % L7
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "OpenReplyRateLastWeek" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "OpenReplyRateLastWeek" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => { SortData("ReSearchCost"); setSortedColumn("ReSearchCost"); }}>
                Research Cost
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "ReSearchCost" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "ReSearchCost" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => { SortData("DirectMailCost"); setSortedColumn("DirectMailCost"); }}>
                Direct Mail Cost
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "DirectMailCost" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "DirectMailCost" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => { SortData("Uncontacted"); setSortedColumn("Uncontacted"); }}>
                Uncontacted
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Uncontacted" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Uncontacted" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => { SortData("ClientStatus"); setSortedColumn("ClientStatus"); }}>
                Client Status
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "ClientStatus" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "ClientStatus" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => { SortData("SlackChannelInternal"); setSortedColumn("SlackChannelInternal"); }}>
                Slack Channel Internal
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "SlackChannelInternal" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "SlackChannelInternal" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => { SortData("SlackChannelExternal"); setSortedColumn("SlackChannelExternal"); }}>
                Slack Channel External
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "SlackChannelExternal" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "SlackChannelExternal" ? "active" : null} />
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
            <TableBody>
              {Rows.length == 0 ? (
                <p className="text-center">No data in Available</p>
              ) : (
                Rows.map((row) => (
                  <>
                    <TableRow key={row._id}>
                      <TableCell align="center">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            setOpen((prev) => ({
                              // ...prev,
                              [row._id]: !prev[row._id],
                            }));
                            GetMeetingAndPotentialDetailsInSubTable(
                              row.ClientID
                            );
                          }}
                        >
                          {open[row._id] ? (
                            <i
                              className="fa fa-minus-circle minusl"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className="fa fa-plus-circle plusbl"
                              aria-hidden="true"
                            ></i>
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => EditPage(row?.ClientID)}
                          className="btn-eyesicon"
                        >
                          <i class="la flaticon-edit-1 edit-icon"></i>
                        </a>
                      </TableCell>
                      <TableCell>{row?.CrushRate}%</TableCell>
                      <TableCell>{row?.ClientName}</TableCell>
                      <TableCell>{row?.MeetingL7}</TableCell>
                      <TableCell>{row?.Meeting}</TableCell>
                      <TableCell>{row?.PotentialMeetingsL7}</TableCell>
                      <TableCell>{row?.PotentialMeeting}</TableCell>
                      <TableCell>{row?.Goal}</TableCell>
                      <TableCell>{row?.QtrGoal}</TableCell>
                      <TableCell>{row?.Strategist}</TableCell>
                      <TableCell>{row?.Responder}</TableCell>
                      <TableCell>{row?.SentL7}</TableCell>
                      <TableCell>{row?.ResponsesLW}</TableCell>
                      <TableCell>{row?.OpenRateLastWeek}</TableCell>
                      <TableCell>{row?.OpenRateLastMonth}</TableCell>
                      <TableCell>{row?.DifferenceL7L30}</TableCell>
                      <TableCell>{row?.OpenReplyRateLastWeek}</TableCell>
                      <TableCell>{row?.ReSearchCost}</TableCell>
                      <TableCell>{row?.DirectMailCost}</TableCell>
                      <TableCell>{row?.Uncontacted}</TableCell>
                      <TableCell>{row?.ClientStatus}</TableCell>
                      <TableCell>{row?.SlackChannelInternal}</TableCell>
                      <TableCell>{row?.SlackChannelExternal}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={23}
                      >
                        <Collapse
                          in={open[row._id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {open[row._id] && (
                            <Box margin={1} className="innertables">
                              <Table size="small" aria-label="purchases">
                                {
                                  MeetingToggleData.length !== 0 &&( 
                                    <TableHead>
                                      <TableCell>Meeting Status</TableCell>
                                      <TableCell>Company</TableCell>
                                      <TableCell>Title</TableCell>
                                      <TableCell>First Name</TableCell>
                                      <TableCell>Last Name</TableCell>
                                      <TableCell>Email</TableCell>
                                      <TableCell>Domain</TableCell>
                                      <TableCell>Meeting Source</TableCell>
                                      <TableCell>Owner</TableCell>
                                      <TableCell>Meeting Date</TableCell>
                                      <TableCell>Meeting Set Date</TableCell>
                                      <TableCell> Send Reminder</TableCell>
                                      <TableCell>LinkedIn Url</TableCell>
                                      <TableCell>Contract Value</TableCell>
                                      <TableCell>Industry</TableCell>
                                    </TableHead> 
                                  )
                                }

                                {MeetingToggleData.map((Meetingrow) => (
                                  <TableRow>
                                    <TableCell>{Meetingrow?.Status}</TableCell>
                                    <TableCell>{Meetingrow?.Company}</TableCell>
                                    <TableCell>
                                      {Meetingrow?.JobTitle}
                                    </TableCell>
                                    <TableCell>
                                      {Meetingrow?.FirstName}
                                    </TableCell>
                                    <TableCell>
                                      {Meetingrow?.LastName}
                                    </TableCell>
                                    <TableCell>{Meetingrow?.Email}</TableCell>
                                    <TableCell>{Meetingrow?.Domain}</TableCell>
                                    <TableCell>
                                      {Meetingrow?.MeetingSource}
                                    </TableCell>
                                    <TableCell>
                                      {Meetingrow?.MeetingOwner}
                                    </TableCell>
                                    <TableCell>
                                      {Meetingrow?.EventStartDt1}
                                    </TableCell>
                                    <TableCell>
                                      {Meetingrow?.EventCreatedDt != null
                                        ? Moment(
                                            new Date(Meetingrow?.EventCreatedDt)
                                          ).format("MM/DD/YYYY")
                                        : ""}
                                    </TableCell>
                                    <TableCell>
                                      {Meetingrow?.IsSendMeetingReminder
                                        ? "Yes"
                                        : "No"}
                                    </TableCell>
                                    <TableCell>{Meetingrow?.LinkedInUrl}</TableCell>
                                    <TableCell>
                                      {Meetingrow?.ContractValue}
                                    </TableCell>
                                    <TableCell>
                                      {Meetingrow?.Industry}
                                    </TableCell>
                                  </TableRow>
                                ))}

                                {
                                  MeetingToggleData.length === 0 || PotentialToggleData.length===0 ? (
                                  <TableRow>
                                    <TableCell colSpan={4}>
                                      No data available
                                    </TableCell>
                                  </TableRow>):(<></>)
                                }

                                {
                                  PotentialToggleData.length !== 0 &&(
                                    <TableHead> 
                                      <TableCell>Engagement Status</TableCell>
                                      <TableCell>Company</TableCell>
                                      <TableCell>First Name</TableCell>
                                      <TableCell>Last Name</TableCell>
                                      <TableCell>Email</TableCell>
                                      <TableCell>Domain</TableCell>
                                      <TableCell>Title</TableCell>
                                      <TableCell>LinkedIn Url</TableCell>
                                      <TableCell>Meeting Source Name</TableCell>
                                      <TableCell>Owner Name</TableCell>
                                      <TableCell>Reminder Email Account</TableCell>
                                      <TableCell>Created Date</TableCell>
                                      <TableCell>Industry</TableCell> 
                                  </TableHead>
                                  )
                                }

                              {PotentialToggleData.map((PMeetingrow) => (
                                <TableBody>
                                  <TableRow>
                                    <TableCell>{PMeetingrow?.EngagamentStatus}</TableCell>
                                    <TableCell>{PMeetingrow?.Company}</TableCell>
                                    <TableCell>
                                      {PMeetingrow?.FirstName}
                                    </TableCell>
                                    <TableCell>
                                      {PMeetingrow?.LastName}
                                    </TableCell>
                                    <TableCell>{PMeetingrow?.Email}</TableCell>
                                    <TableCell>{PMeetingrow?.Domain}</TableCell>
                                    <TableCell>{PMeetingrow?.Title}</TableCell>
                                    <TableCell>{PMeetingrow?.LinkedInUrl}</TableCell>
                                    <TableCell>{PMeetingrow?.MeetingSourceName}</TableCell>
                                    <TableCell>{PMeetingrow?.MeetingOwnerName}</TableCell>
                                    <TableCell>{PMeetingrow?.AccountDetails[0]?.SMTPFromEmail !== undefined ? PMeetingrow.AccountDetails[0]?.SMTPFromEmail : ''}</TableCell>
                                    <TableCell>{PMeetingrow?.CreatedDate != null
                                        ? Moment(
                                            new Date(PMeetingrow?.CreatedDate)
                                          ).format("MM/DD/YYYY")
                                        : ""}</TableCell>
                                     <TableCell>
                                      {PMeetingrow?.Industry}
                                    </TableCell>
                                  </TableRow>
                                  </TableBody>
                                ))}


                              </Table>
                            </Box>
                          )}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="row pt-3">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}
