import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LastdaysTabing from "../Kpis/LastdaysTabing";

import HardBounceTable from "../../../_components/user/GlobalBounce/HardBounceTable";
import SoftBounceTable from "../../../_components/user/GlobalBounce/SoftBounceTable";

import ImageUploading from "react-images-uploading";
import Popup from "reactjs-popup";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [popupval, setpopup] = React.useState(false);

  useEffect(() => {
    document.title = `Global Bounce | SalesHive`;
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };
  const openTermsConditionPopup = () => {
    //
    setpopup(true);
  };

  const closeTermsConditionModal = () => {
    //
    setpopup(false);
  };

  const SubmitTermsConditionModal = () => {
    setpopup(false);
  };

  document.addEventListener("mouseup", function (e) {
    //
    if (popupval) {
      setpopup(false);
    }
  });
  //const openTermsConditionPopup = false;

  return (
    <div className="tabdes">
      <div>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange} variant="scrollable"
            aria-label="simple tabs example"
          >
            <Tab label="Soft Bounce" {...a11yProps(0)}>
              Overviewasdasd
            </Tab>
            <Tab label="Hard Bounce" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone bg-white" index={0}>
          <div className="row px-2">
            <div className="col px-3">
              <h4 className="headertitlenop xs-headertitle float-left p-2 py-3">Soft Bounce</h4>
            </div>
            <div className="col px-5"></div>
          </div>
          <div className="row">
            <div className="col">
              <div className="border-top">
                <SoftBounceTable />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone bg-white" index={1}>
          <div className="row px-2 ">
            <div className="col px-3 ">
              <h4 className="headertitlenop float-left p-2 py-3">Hard Bounce</h4>
            </div>
            <div className="col px-5"></div>
          </div>
          <div className="row">
            <div className="col">
              <div className="border-top">
                <HardBounceTable />
              </div>
            </div>
          </div>
        </TabPanel>
      </div>
    </div>
  );
}
