import React, { useEffect } from "react";
const moment = require("moment");
import Swal from "sweetalert2";
import Axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";

import { toast } from "react-toastify";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import StyleHeader from "../StickyHeader/StickyHeader";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable({ listupdate,updateFromChild}) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDt");
  const [SortValue, SetSortValue] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [EditData, SetEditData] = React.useState([]);
  const [OldPhasesData, SetOldPhasesData] = React.useState("");
  const [Fields, SetFields] = React.useState({});
  const [Errors, SetErrors] = React.useState({});
  const [IsExist, SetIsExist] = React.useState(false);
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [CUserID,SetCUserID] = React.useState(0)
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    var ResearchPageUserInfo = GetUserDetails();
    if (ResearchPageUserInfo != null) {
      SetClientID(ResearchPageUserInfo.ClientID);
      SetUserID(ResearchPageUserInfo.ParentUserID);
      SetCUserID(ResearchPageUserInfo.ChildUserID);
      SetRole(ResearchPageUserInfo.Role);
    }
    GetTriggerPhrase(
      ResearchPageUserInfo.ClientID,
      ResearchPageUserInfo.ParentUserID,
      ResearchPageUserInfo.Role
    );
  }, [Search, Page, RowsPerPage,listupdate]);

  //get Trigger Phrase list
  const GetTriggerPhrase = (CID, UID, Role) => {
    SetIsLoading(true)
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortValue,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: Role,
      PhraseFor: "removed",
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseGet",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
      setIsDataLoaded(true);


    });
  };

  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true)

    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var Sort = SortValue;
      if (Sort == 1) {
        Sort = -1;
        SetSortValue(-1);
      } else {
        Sort = 1;
        SetSortValue(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: Sort,
        Type: "User",
        PhraseFor: "removed",
      };
      const ResearchGet = Axios({
        url: CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseGet",
        method: "POST",
        data: InputParameter,
      });
      ResearchGet.then((Result) => {
        SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)

      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var Sorted;
      if (Sorted == 1) {
        Sorted = -1;
        SetSortValue(-1);
      } else {
        Sorted = 1;
        SetSortValue(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortValue,
        Type: "User",
        PhraseFor: "removed",
      };
      const ResearchGet = Axios({
        url: CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseGet",
        method: "POST",
        data: InputParameter,
      });
      ResearchGet.then((Result) => {
        SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)

      });
    }
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  //delete
  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a Removed .",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputData = {
          ID: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseDelete",
          method: "POST",
          data: InputData,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire("Deleted!", "Removed deleted successfully.", "success");
              // SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
              GetTriggerPhrase(ClientID, UserID, Role);
              updateFromChild(true);

            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //Check Exist Name
  const FormValidation = async (id) => {
    let Errors = {};
    let FormValid = true;
    let PhasesField = document.getElementById(id + "text").value.trim();
    var IsExist = await CheckExistTriggerAPhrasesName(PhasesField);
    if (PhasesField == "") {
      FormValid = false;
      Errors["removed"] = "Please enter phrase";
      toast.error("Please enter phrase");
    }
    if (IsExist == true) {
      FormValid = false;
    }

    SetErrors(Errors);
    return FormValid;
  };

  // handle change--not need to use
  // const HandleAPhrasesList = (field) => {
  //   var Name = document.getElementById("removed").value;
  //   SetFields(Name);
  //   if (Fields.Name != "") {
  //     Errors.Name = null;
  //     var Name = document.getElementById("removed").value;
  //     CheckExistTriggerAPhrasesName(Name);
  //   } else {
  //     NameAvaiable = null;
  //     Errors.Domain = null;
  //   }
  // };

  const CheckExistTriggerAPhrasesName = async (Name) => {
    var str_in = {
      PhraseFor: "removed",
      Phrase: Name,
      Role: Role,
    };
    var resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseExists",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (OldPhasesData == Name) {
          SetIsExist(false);
          return false;
        } else {
          toast.error(
            "Removed " + resdata.data.Data[0]?.Phrase + " already exists"
          );
          SetIsExist(true);
          return true;
        }
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };

  // Edit desing change
  const EditPhasesBtn = (TriggerPhraseID) => {
    document.getElementById(TriggerPhraseID + "labelbox").style.display =
      "none";
    document.getElementById(TriggerPhraseID + "text").style.display = "block";
    document.getElementById(TriggerPhraseID + "editicon1").style.display =
      "none";
    document.getElementById(TriggerPhraseID + "saveicon1").style.display =
      "block";
    document.getElementById(TriggerPhraseID + "editicon2").style.display =
      "none";
    document.getElementById(TriggerPhraseID + "saveicon2").style.display =
      "block";

    // document.getElementById(id).style.display = "block";

    var str_in = {
      ID: TriggerPhraseID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseGetByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById(TriggerPhraseID + "text").value =
          res.data.Data[0]?.Phrase;
        SetOldPhasesData(res.data.Data[0]?.Phrase);
        SetEditData(res.data.Data);
      } else {
        toast.error(res.data.Message);
      }
    });
  };

  // Update domain data
  const UpdatePhasesBtn = async (e, id) => {
    SetIsLoading(true)

    e.preventDefault();
    e.currentTarget.disabled = true;
    var FinalValidation = await FormValidation(id);
    if (FinalValidation == true) {
      var Phrase = document.getElementById(id + "text").value;
      var PhasesTriggerUpdate = {
        ID: id,
        Phrase: Phrase,
        LastUpdatedBy: CUserID,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/trigger_phrase/TriggerPhraseUpdate",
        method: "POST",
        data: PhasesTriggerUpdate,
      }).then((result) => {
        if (result.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Removed
              <br />
              Removed updated successfully.
            </div>
          );
          GetTriggerPhrase(ClientID, UserID, Role);
          updateFromChild(true);
          HideEditBtn(id);
            SetIsLoading(false)


        } else {
          toast.error(result.data.Message);
            SetIsLoading(false)


        }
      });
    } else {
      document.getElementById(id + "saveicon1").disabled = false;
      SetIsLoading(false)

    }
  };

  const HideEditBtn = (TriggerPhraseID) => {
    document.getElementById(TriggerPhraseID + "labelbox").style.display =
      "block";
    document.getElementById(TriggerPhraseID + "text").style.display = "none";
    document.getElementById(TriggerPhraseID + "editicon1").style.display =
      "block";
    document.getElementById(TriggerPhraseID + "saveicon1").style.display =
      "none";
    document.getElementById(TriggerPhraseID + "editicon2").style.display =
      "block";
    document.getElementById(TriggerPhraseID + "saveicon2").style.display =
      "none";
  };

  return (
    <>
    {
      IsLoading ? <div id="hideloding" className="loding-display">
      <img src={loadingicon} />
    </div> : <></>
    }
      
      <div className="row pb-2">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <TableContainer component={Paper}>
      <StyleHeader isDataLoaded={isDataLoaded} />

<Table className="table-ref"  aria-label="collapsible table">
            <TableHead>
            <TableRow>
              <TableCell>
                {" "}
                <a
                  onClick={() => {
                    SortData("Phrase");
                  }}
                >
                  Phrase
                </a>
              </TableCell>
              <TableCell>
                {" "}
                <a
                  onClick={() => {
                    SortData("ReplyContentCount");
                  }}
                >
                  Reply Contain
                </a>
              </TableCell>
              <TableCell>
                {" "}
                <a
                  onClick={() => {
                    SortData("CreatedDt");
                  }}
                >
                  Created Date
                </a>
              </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Rows?.length === 0 ? (
              <p className="text-center">No data available in table</p>
            ) : (
              Rows?.map((row) => (
                <TableRow key={row._id} row={row}>
                  <TableCell style={{  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}}>
                    <label id={row._id + "labelbox"}>
                      {row.Phrase}{" "}
                    </label>{" "}
                    <input
                      type="textbox"
                      id={row._id + "text"}
                      class="form-control m-input"
                      style={{ display: "none" }}
                      // value={Fields["Domain"]}
                      autocomplete="off"
                    />
                  </TableCell>
                  <TableCell>{row.ReplyContentCount == null ? 0 : row.ReplyContentCount}</TableCell>
                  <TableCell>
                    {moment(new Date(row.CreatedDate).toDateString()).format(
                      "MM/DD/YYYY"
                    )}
                  </TableCell>
                  <TableCell className="d-flex save-flex">
                    <a
                      onClick={() => {
                        EditPhasesBtn(row._id);
                      }}
                      id={row._id + "editicon1"}
                      className="btn-eyesicon" title="Edit"
                    >
                      <i class="la flaticon-edit-1 edit-icon"></i>
                    </a>
                    <a
                      onClick={() => {
                        DeleteBtn(row._id);
                      }}
                      id={row._id + "editicon2"}
                      className="btn-eyesicon"  title="Delete"
                    >
                      <i className="la flaticon-delete-1 delete-icon"></i>
                    </a>
                    <a
                      onClick={(e) => {
                        UpdatePhasesBtn(e, row._id);
                      }}
                      style={{ display: "none" }}
                      id={row._id + "saveicon1"}
                      className="btn-eyesicon" title="Save"
                    >
                      <i className="la la-save"></i>
                    </a>
                    <a
                      onClick={() => {
                        HideEditBtn(row._id);
                      }}
                      style={{ display: "none" }}
                      id={row._id + "saveicon2"}
                      className="btn-eyesicon"  title="Cancel"
                    >
                      <i className="la flaticon-cancel"></i>
                    </a>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
