import React, { useEffect } from 'react';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from "@material-ui/lab/Pagination";
import Paper from '@material-ui/core/Paper';
import { CSVDownloader } from "react-papaparse";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import loadingicon from "../../../images/loading.gif";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StyleHeader from "../StickyHeader/StickyHeader";

toast.configure();

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});
const imgstyle = {
  height: "40px",
  width: "40px",
  borderradius: "50%",
};


export default function AllClientKpiLeaderStrategist({ FromDate, ToDate, IsIncludeOldClients }) {

  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("SalesHackerName");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [UserID, SetUserID] = React.useState(0);
  const [ToDates, SetToDates] = React.useState(ToDate);
  const [FromDates, SetFromDates] = React.useState(FromDate);
  const [StrategistExportData, SetStrategistExportData] = React.useState([]);
  const [IsOldClient,SetIsOldClient] = React.useState(false);
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetUserID(Details.ParentUserID);
    }
    SetToDates(ToDate)
    SetFromDates(FromDate);
    SetIsOldClient(IsIncludeOldClients)
    ExportStrategist(Details.ParentUserID, FromDate, ToDate, IsIncludeOldClients)
    StraregistGet(Details.ParentUserID, FromDate, ToDate, IsIncludeOldClients);
  }, [FromDate, ToDate, IsIncludeOldClients, Search, Page, RowsPerPage]);


  const StraregistGet = (UserID, FromDates, ToDates, IsIncludeOldClient) => {
    SetIsLoading(true)
    var ResponderGetData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      UserID: UserID,
      FromDate: FromDates,
      ToDate: ToDates,
      IncludeOldClient: IsIncludeOldClient
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/allclientkpi/AllClientKpiLeaderStrategist",
      method: "POST",
      data: ResponderGetData,
    });
    rows1.then((Result) => {
      if (Result.data.StatusMessage == "SUCCESS") {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
    SetIsLoading(false)
    setIsDataLoaded(true);


      }else{
    SetIsLoading(false)
    setIsDataLoaded(true);


      }
    });
  };
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      document.getElementById("hideloding").style.display = "block";
    }
  };

  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    document.getElementById("hideloding").style.display = "block";
  };

  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
        document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  const ExportStrategist = (UserID, FromDate, ToDate, IsIncludeOldClient) => {
    
    var data = {
      UserID: UserID,
      FromDate: FromDate,
      ToDate: ToDate,
      IncludeOldClient: IsIncludeOldClient
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/allclientkpi/AllClientKpiLeaderStrategistExports",
      method: "POST",
      data: data,
    }).then((Res) => {
     if (Res.data.StatusMessage == "SUCCESS") {
         SetStrategistExportData(Res.data.Data);
      }
     
    });
  };

   //get sort field data
   const SortData = (Field) => {
    SetIsLoading(true)
    

    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var ResponderGetData = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
        UserID: UserID,
        FromDate: FromDates,
        ToDate: ToDates,
        IncludeOldClient: IsOldClient
      };
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/allclientkpi/AllClientKpiLeaderStrategist",
        method: "POST",
        data: ResponderGetData,
      });
      rows1.then((Result) => {
        if (Result.data.StatusMessage == "SUCCESS") {
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
    SetIsLoading(false)

        }else{
    SetIsLoading(false)

        }

      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var ResponderGetData = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
        UserID: UserID,
        FromDate: FromDates,
        ToDate: ToDates,
        IncludeOldClient: IsOldClient
      };
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/allclientkpi/AllClientKpiLeaderStrategist",
        method: "POST",
        data: ResponderGetData,
      });
      rows1.then((Result) => {
        if (Result.data.StatusMessage == "SUCCESS") {
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
    SetIsLoading(false)

        }

      });
    }
    };
  
  const ExportCsv =()=> {
    toast.success(
      <div>
        Data exported successfully.
      </div>
    )
  }
  return (
    <>

      {IsLoading === true ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>:<></>}
      <div className='bg-white'>
      <div className="row mx-0 border-bottom align-items-center">
        <div className="col ">
          <h4 className="headertitle xs-headertitle float-left pl-0">Strategist</h4>
          <div className="listing-li float-right padb-15 pr-0">
            <ul>
              <li>
              <CSVDownloader data={StrategistExportData} filename={"AllClients-SalesStrategistCrush"} bom={true}>
                  <a onClick={() => { ExportCsv(); }} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                    <i class="la la-icon-font-size-13 la-download"></i> Export
                  </a>
                </CSVDownloader>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row padt-25 px-3">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">Search: <input type="search" id='search' class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" onKeyPress={RequestSearch} /></label>
        </div>
      </div>
      <div className='px-3'>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
          <Table className="table-ref"  aria-label="collapsible table">     
                   <TableHead>
              <TableRow>
                <TableCell><a onClick={() => {
                    SortData("SalesHackerName");
                  }} >Strategist</a></TableCell>
                <TableCell><a onClick={() => {
                    SortData("Meeting");
                  }} >Meetings</a></TableCell>
                <TableCell><a onClick={() => {
                    SortData("CrushRate");
                  }} >Avg Crush %</a></TableCell>
                <TableCell><a onClick={() => {
                    SortData("ClientCount");
                  }} >Total Clients</a></TableCell>
                <TableCell><a onClick={() => {
                    SortData("LastWeekMeeting");
                  }} >Meetings LW</a></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length == 0 ? <p className="text-center">No data in Available</p> : Rows.map((row) => (
                <TableRow>
                  <TableCell>
                    <img
                      style={imgstyle}
                      src={
                        row?.ProfileImage == ""
                          ? CommonConstants.Image_url + "default.png"
                          : CommonConstants.Image_url + row?.ProfileImage
                      }
                    ></img>
                    {row?.SalesHackerName}
                  </TableCell>
                  <TableCell>{row?.Meeting}</TableCell>
                  <TableCell>{row?.CrushRate}%</TableCell>
                  <TableCell>{row?.ClientCount}</TableCell>
                  <TableCell>{row?.LastWeekMeeting}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      </div>
      <div className="row px-3 pb-2">
        <div className="col dataTables_info">
          <p>
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries
          </p>
        </div>
        <div className="col pageright">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
      </div>
    </>
  );
}
