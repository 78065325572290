import React, { useEffect } from "react";
const Moment = require("moment");
import Axios from "axios";
import Swal from "sweetalert2";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { GetClientDetails } from "../../../_helpers/Utility";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import StyleHeader from "../StickyHeader/StickyHeader";

export default function ClientResearchTable(props) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("ProjectStatus");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID,SetCUserID] = React.useState(0)
  const [IsLoading, SetIsLoading] = React.useState(false);
  //console.log("UserID--Research",UserID)
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    document.title = `Client Researchs | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    ClientResearchGet(Details.ParentUserID);
    // GetClientDetails(Details.ClientID).then((result) => {
    //   SetCName(result[0].Name);
    // });
  }, [Search, Page, RowsPerPage]);

  // Client research get
  const ClientResearchGet = (UID) => {
    SetIsLoading(true)
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      UserID: UID,
    };
    const ClientResearch = Axios({
      url: CommonConstants.MOL_APIURL + "/research/AllClientResearchGet",
      method: "POST",
      data: InputParameter,
    });
    ClientResearch.then((Result) => {
      SetRows(Result.data?.PageData);
      SetRlen(Result.data?.TotalCount);
      SetFlen(Result.data?.TotalCount);
      SetCountPage(Result.data?.PageCount);
      SetIsLoading(false)
      setIsDataLoaded(true);

    });
  };

  //edit page
  const EditBtn = (ID) => {
    history.push("/editclientresearch", ID);
  };

  //search for record
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
       // document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
     // document.getElementById("hideloding").style.display = "block";
    }
  };

  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
   // document.getElementById("hideloding").style.display = "block";
  };
  //delete  client research
  const DeleteBtn = (ResearchID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a client research.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputData = {
          ResearchID: ResearchID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/research/ClientResearchDelete",
          method: "POST",
          data: InputData,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Client Research deleted successfully.",
                "success"
              );
              SetPage(1);
              SetSearch("");
             // document.getElementById("hideloding").style.display = "block";
              document.getElementById("search").value = "";
              ClientResearchGet(UserID);
              history.push("/clientresearch");
              props.updateFromChild(true);
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true)
    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: SearchedVal,
        Type: "User",
        UserID: UserID,
      };
      const ResearchGet = Axios({
        url: CommonConstants.MOL_APIURL + "/research/AllClientResearchGet",
        method: "POST",
        data: InputParameter,
      });
      ResearchGet.then((Result) => {
        SetRows(Result.data?.PageData);
        SetRlen(Result.data?.TotalCount);
        SetFlen(Result.data?.TotalCount);
        SetCountPage(Result.data?.PageCount);
        SetIsLoading(false)
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: SearchedVal,
        Type: "User",
        UserID: UserID,
      };
      const ResearchGet = Axios({
        url: CommonConstants.MOL_APIURL + "/research/AllClientResearchGet",
        method: "POST",
        data: InputParameter,
      });
      ResearchGet.then((Result) => {
        SetRows(Result.data?.PageData);
        SetRlen(Result.data?.TotalCount);
        SetFlen(Result.data?.TotalCount);
        SetCountPage(Result.data?.PageCount);
        SetIsLoading(false)
      });
    }
  };

  return (
    <>
    {IsLoading ? (
      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
         ) : (
          <></>
        )}
      <div>
        <div className="row">
          <div className="col padd-0"></div>
        </div>
        <div className='bg-white p-3'>
        <div className="row pt-2">
          <div className="col-sm-12 col-md-6">
            <label className="textlabelte">
              Show
              <select
                name="tbl_meeting_length"
                onChange={ChangeRowSelected}
                aria-controls="tbl_meeting"
                className="form-control form-control-sm"
                value={RowsPerPage}
              >
                {CommonConstants.show_rows.map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
              entries
            </label>
          </div>
          <div className="col-sm-12 col-md-6 full-right">
            <label className="textlabelte">
              Search:{" "}
              <input
                Type="search"
                id="search"
                onKeyPress={RequestSearch}
                className="form-control form-control-sm ml-2"
                placeholder=""
                aria-controls="tbl_meeting"
              />
            </label>
          </div>
        </div>
        <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />

<Table className="table-ref"  aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ProjectStatus");
                    }}
                  >
                    Project Status
                  </a>{" "}
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("rName");
                    }}
                  >
                    Client Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ProjectName");
                    }}
                  >
                    Research Project Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("CompletedBy");
                    }}
                  >
                    Needs to be Completed By
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("RequestedDate");
                    }}
                  >
                    Date Requested
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("FinishedDate");
                    }}
                  >
                    Date Finished
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Hours");
                    }}
                  >
                    Hours
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("CostPerHour");
                    }}
                  >
                    Cost per hour{" "}
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("TotalCost");
                    }}
                  >
                    Total Cost
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ContactsFound");
                    }}
                  >
                    Contacts Found
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("CostPerContact");
                    }}
                  >
                    Cost per Contact
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Notes");
                    }}
                  >
                    Notes
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ResearchTeam");
                    }}
                  >
                    Research Team
                  </a>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow>
                    <TableCell>{row?.ProjectStatus}</TableCell>
                    <TableCell>{row.rName}</TableCell>
                    <TableCell>{row?.ProjectName}</TableCell>
                    <TableCell>
                      {" "}
                      {row?.CompletedBy == null
                        ? ""
                        : Moment(
                            new Date(row?.CompletedBy).toDateString()
                          ).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {row?.RequestedDate == null
                        ? ""
                        : Moment(
                            new Date(row?.RequestedDate).toDateString()
                          ).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {row?.FinishedDate == null
                        ? ""
                        : Moment(
                            new Date(row?.FinishedDate).toDateString()
                          ).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell>{row?.Hours}</TableCell>
                    <TableCell>{row?.CostPerHour}</TableCell>
                    <TableCell>{row?.TotalCost}</TableCell>
                    <TableCell>{row?.ContactsFound}</TableCell>
                    <TableCell>{row?.CostPerContact}</TableCell>
                    <TableCell
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {row?.Notes}
                    </TableCell>
                    <TableCell>{row?.ResearchTeam}</TableCell>
                    <TableCell>
                      <a
                        onClick={() => {
                          EditBtn(row._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i className="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      <a
                        onClick={() => {
                          DeleteBtn(row._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i className="la flaticon-delete-1 delete-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      
      {Sflag ? (
        <div className="row ">
          <Pagination
            component="div"
            count={CountPage}
            page={Page}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row " >
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
        </div>
   
      </div>
    </>
  );
}
